<template>
    <div
        ref="wrapper"
        :class="[
            className,
            'f-scroll-pane',
            `--mode-${mode}`,
            (hasScroll ? '--has-scroll' : null),
            (!noScrollPadding ? '--scroll-padding' : null),
            (dragging ? '--dragging' : null),
        ]"
        :style="{ height }"
    >
        <div
            ref="content"
            v-drag-scroll="drag && !isTouch"
            @dragScrollStart="dragScrollStart"
            @dragScrollStartDelayed="dragScrollStartDelayed"
            @dragScrollEnd="dragScrollEnd"
            :class="[
                'f-scroll-pane__content',
                scrollClass,
                (showScrollBarOnlyOnHover ? null : '--always-show-scrollbar'),
            ]"
        >
            <div class="f-scroll-pane__content__padder">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import {ScrollPaneMode, ScrollPaneVariant} from './config';
import {isTouchDevice} from '../../../utils/helpers';
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-scroll-pane',

    mixins: [
        ClassNameMixin,
    ],

    props: {
        height: String,
        maxHeight: String,
        applyMask: Boolean,
        hideScrollBar: Boolean,
        drag: Boolean,
        showScrollBarOnlyOnHover: {
            type: Boolean,
            default: true,
        },
        noScrollToOnActivity: {
            type: Boolean,
            default: true,
        },
        emitBusScroll: {
            type: Boolean,
            default: true,
        },
        inactiveAfter: {
            type: Number,
            default: 15,
        },
        variant: {
            type: String,
            default: ScrollPaneVariant.THEMED,
        },
        noScrollPadding: Boolean,
        mode: {
            type: String,
            default: ScrollPaneMode.VERTICAL,
        },
    },

    watch: {
        maskBottomPercentage() {
            this.applyScrollStyles('bottom');
        },
        maskTopPercentage() {
            this.applyScrollStyles('top');
        },
        maxHeight() {
            this.applyScrollStyles();
        },
        applyMask() {
            this.$nextTick(() => {
                this.initialize();
                this.applyScrollStyles();
            });
        },
    },

    computed: {
        scrollClass() {
            if (this.hideScrollBar) {
                return `--scrollable-no-bar-${this.mode}`;
            }
            return `--scrollable-${this.variant}-${this.mode}`;
        },
    },

    data() {
        return {
            hasScroll: false,
            maskTopPercentage: 0,
            maskBottomPercentage: 0,
            maxMaskPercentage: 15,
            wrapperElement: null,
            contentElement: null,
            inactivityTimeout: null,
            activity: false,
            dragging: false,
            isTouch: isTouchDevice(),
        }
    },

    mounted: function () {
        this.initialize();
        EventBus.$on(GlobalEvents.WINDOW_RESIZE, this.initialize);
    },

    beforeDestroy() {
        if (this.inactivityTimeout) {
            window.clearTimeout(this.inactivityTimeout);
        }
        this.getScrollElement().removeEventListener('scroll', this.onScroll);
        EventBus.$off(GlobalEvents.WINDOW_RESIZE, this.initialize);
    },

    methods: {
        initialize() {
            // console.log('ScrollPane.initialize', this.getScrollElement());

            this.getScrollElement().style.maxHeight = this.maxHeight;
            this.getScrollElement().addEventListener('scroll', this.onScroll);
            this.$nextTick(() => this.invalidate());

            if (this.applyMask) {
                this.maskBottomPercentage = this.fixPercentage(100 - this.maxMaskPercentage);
            }
        },

        invalidate() {
            this.hasScroll = ([ScrollPaneMode.VERTICAL, ScrollPaneMode.BOTH].includes(this.mode) && this.getScrollElement().clientHeight < this.getScrollElement().scrollHeight) ||
                ([ScrollPaneMode.HORIZONTAL, ScrollPaneMode.BOTH].includes(this.mode) && this.getScrollElement().clientWidth < this.getScrollElement().scrollWidth);
        },

        fixPercentage(percentage) {
            if (percentage < .1) {
                percentage = .1;
            } else if (percentage >= 99.9) {
                percentage = 99.9;
            }

            return percentage;
        },

        onScroll(e) {
            if (!this.getScrollElement()) {
                return;
            }

            this.onActivity();

            this.$emit('scroll', e);
            if (this.emitBusScroll) {
                EventBus.$emit(GlobalEvents.OVERFLOW_SCROLL);
            }

            if (this.applyMask) {
                this.calculateMask();
            }
        },

        calculateMask() {
            const scrollTop = this.getScrollElement().scrollTop,
                scrollPosition = scrollTop + this.getScrollElement().clientHeight,
                scrollHeight = this.getScrollElement().scrollHeight,

                scrollBorderBottom = scrollHeight - ((scrollHeight / 100) * this.maxMaskPercentage),
                scrollBorderBottomCrossed = scrollPosition - scrollBorderBottom,

                scrollTopBorder = ((scrollHeight / 100) * this.maxMaskPercentage);

            // Scroll bottom
            if (scrollPosition > scrollBorderBottom) {
                const visibilityPercentage = ((scrollBorderBottomCrossed / (scrollHeight - scrollBorderBottom)) * 100),
                    p = ((this.maxMaskPercentage / 100) * visibilityPercentage);

                this.maskBottomPercentage = this.fixPercentage(100 - this.maxMaskPercentage + p);
            } else {
                this.maskBottomPercentage = this.fixPercentage(100 - this.maxMaskPercentage);
            }

            // Scroll top
            if (scrollTop > 0) {
                if (scrollTop > scrollTopBorder) {
                    this.maskTopPercentage = this.fixPercentage(this.maxMaskPercentage);
                } else {
                    const visibilityPercentage = ((scrollTop / scrollTopBorder) * 100);
                    this.maskTopPercentage = this.fixPercentage(((this.maxMaskPercentage / 100) * visibilityPercentage));
                }
            } else {
                this.maskTopPercentage = this.fixPercentage(0);
            }
        },

        applyScrollStyles(type) {
            if (!this.hasScroll || !this.applyMask) {
                this.$refs.wrapper.setAttribute('style', '');
                this.getScrollElement().setAttribute('style', `max-height: ${this.maxHeight};`);
                return;
            }

            if (type === 'top') {
                this.$refs.wrapper.setAttribute('style',
                    `-webkit-mask-image: -webkit-gradient(linear, left ${this.maskTopPercentage}%, left top, from(black), to(transparent));`);
            } else if (!type || type === 'bottom') {
                this.getScrollElement().setAttribute('style',
                    `max-height: ${this.maxHeight}; -webkit-mask-image: -webkit-gradient(linear, left ${this.maskBottomPercentage}%, left bottom, from(black), to(transparent));`);
            }
        },

        resetActivity() {
            if (this.inactivityTimeout) {
                window.clearTimeout(this.inactivityTimeout);
            }
            this.activity = false;
        },

        scrollToBottom() {
            this.scrollTo(this.getScrollElement().scrollHeight);
        },

        scrollToRight() {
            this.getScrollElement().scrollLeft = this.getScrollElement().scrollWidth;
        },

        scrollToTop() {
            this.scrollTo(0);
        },

        getScrollHeight() {
            return this.getScrollElement().scrollHeight;
        },

        getScrollElement() {
            return this.$refs.content;
        },

        onActivity() {
            if (this.noScrollToOnActivity) {
                if (this.inactivityTimeout) {
                    window.clearTimeout(this.inactivityTimeout);
                }

                this.activity = true;
                this.inactivityTimeout = window.setTimeout(() => this.activity = false, this.inactiveAfter * 1000);
            }
        },

        scrollTo(i, forceScroll) {
            if (forceScroll) {
                this.getScrollElement().scrollTop = i;
                this.resetActivity();
            } else {
                if (this.activity && this.noScrollToOnActivity) {
                    // console.log('ScrollTo cancelled because of recent activity');
                } else {
                    this.getScrollElement().scrollTop = i;
                }
            }
        },

        dragScrollStart(e) {
            this.$emit('dragScrollStart', e);
        },

        dragScrollStartDelayed(e) {
            this.dragging = true;
            this.$emit('dragScrollStartDelayed', e);
        },

        dragScrollEnd(e) {
            this.dragging = false;
            this.$emit('dragScrollEnd', e);
        },
    },
}
</script>

<style lang="scss">
.f-scroll-pane {
    &__content {
        width: 100%;
    }

    &__content__padder {
        width: 100%;
    }

    &.--dragging {
        cursor: grabbing;

        .f-scroll-pane__content__padder {
            pointer-events: none;
        }
    }

    &.--has-scroll.--scroll-padding {
        &.--mode-horizontal, &.--mode-both {
            .f-scroll-pane__content__padder {
                padding-bottom: 10px;
            }
        }

        &.--mode-vertical, &.--mode-both {
            .f-scroll-pane__content__padder {
                padding-right: 10px;
            }
        }
    }
}
</style>
