import moment from 'moment-timezone';
import EventBus from '../services/EventBus';
import {GlobalEvents} from '../config/Events';

export default {
    data() {
        return {
            minuteTimeout: null,
        }
    },

    mounted() {
        const now = moment();
        this.minuteTimeout = window.setTimeout(() => this.onNewMinute(), (60 - now.seconds()) * 1000);
    },

    methods: {
        onNewMinute() {
            const now = moment();

            // Clear minute event
            window.clearTimeout(this.minuteTimeout);

            // Fire bus event
            EventBus.$emit(GlobalEvents.MINUTE_CHANGE);

            // Start new minute event timer
            this.minuteTimeout = window.setTimeout(() => this.onNewMinute(), (60 - now.seconds()) * 1000);
        },
    },
};
