var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['setting-select', '--active']},[_c('div',{staticClass:"setting-select__label --no-select"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.help)?_c('f-help-label',{attrs:{"text":_vm.help}}):_vm._e()],1),_c('div',[_c('f-dropdown',{attrs:{"options":_vm.options,"searchable":_vm.searchable,"placeholder":_vm.placeholder,"popup-width":"130px","popup-variant":"light"},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"selectedOption",fn:function(selectedOptionProps){return [(selectedOptionProps.selectedOption)?_c('div',{class:[
                        'f-dropdown__selected-option',
                        '--single',
                        (selectedOptionProps.selectedOption.value ? `--value-${selectedOptionProps.selectedOption.key.slugify()}` : undefined)
                    ]},[_vm._v(" "+_vm._s(selectedOptionProps.selectedOption.value)+" ")]):_vm._e()]}},{key:"options",fn:function(optionsProps){return _vm._l((optionsProps.options),function(option,index){return _c('li',{key:option.key,class:[
                        'f-user-dropdown__option',
                        (optionsProps.selectedIndex === index ? '--selected' : null),
                        (optionsProps.selectedOption && optionsProps.value === option.key ? '--chosen' : null),
                        (option.value ? `--value-${option.key.slugify()}` : undefined)
                    ],on:{"click":function($event){$event.preventDefault();return optionsProps.onSelect(option, index)}}},[_vm._v(" "+_vm._s(option.value)+" ")])})}}]),model:{value:(_vm.dropdownValue),callback:function ($$v) {_vm.dropdownValue=$$v},expression:"dropdownValue"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }