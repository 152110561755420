<template>
    <div
        :class="[
            'f-form-row',
            (readonly ? '--read-only' : null),
            className,
        ]"
    >
        <label
            v-if="!hideLabel"
            class="f-form-row__label --no-select"
        >
            {{ label }}
            <slot name="label"/>
            <f-help-label
                v-if="help"
                :text="help"
            />
        </label>
        <div class="f-form-row__content">
            <slot/>
        </div>
    </div>
</template>

<script>
import FHelpLabel from './FHelpLabel';

export default {
    name: 'f-form-row',

    components: {
        FHelpLabel,
    },

    props: {
        className: String,
        label: String,
        help: String,
        readonly: Boolean,
        hideLabel: Boolean,
    },
}
</script>

<style lang="scss">
.f-form-row {
    position: relative;
    display: flex;
    margin-top: 5px;

    &:first-child {
        margin-top: 0;
    }

    &__content {
        flex: 1;

        .f-switch {
            margin-top: 14px;
        }

        .f-radio-button-list {
            margin-top: 11px;
        }

        .f-content {
            padding-top: 12px;
        }

        > .f-checkbox, > .f-checkbox-list {
            margin-top: 12px;
        }
    }

    @include media(mobile-down) {
        display: block;
    }

    &__label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 0 0 25%;
        line-height: 320%;
        text-align: right;
        padding-right: 20px;

        @include theme(dark) {
            color: rgba($light, .75);
        }

        @include theme(light) {
            color: rgba($dark, .75);
        }

        @include media(mobile-down) {
            text-align: left;
            line-height: 200%;
            margin-top: 20px;
            flex: none;
            display: block;
            width: 100%;
        }
    }

    //&.--read-only {
    //    flex: 1;
    //
    //    .f-form-row__content {
    //        padding-top: 11px;
    //        color: rgba(white, .75);
    //
    //        a {
    //            color: white !important;
    //            text-decoration: underline !important;
    //        }
    //    }
    //}
}
</style>
