<template>
    <div :class="['f-theme-provider', `--theme-${variant}`, ...classNames]">
        <slot/>
    </div>
</template>

<script>
import {ThemeVariants} from './config';
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-theme-provider',

    mixins: [
        ClassNameMixin,
    ],

    props: {
        variant: {
            type: String,
            default: ThemeVariants.LIGHT,
        },
    },
}
</script>
