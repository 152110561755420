<template>
    <form
        :class="[
            'f-form',
            (disabled ? '--disabled' : null),
            (loading ? '--loader --loading' : null),
            (theme ? `--theme-${theme}` : null),
            ...classNames,
        ]"
        @submit.prevent="onSubmit"
    >
        <slot/>

        <input
            type="hidden"
            autocomplete="false"
        />

        <footer class="f-form__footer">
            <slot name="footer"/>
        </footer>
    </form>
</template>

<script>
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-form',

    mixins: [
        ClassNameMixin,
    ],

    props: {
        validate: Boolean,
        loading: Boolean,
        dontScrollIntoView: Boolean,
        debug: Boolean,
        theme: String,
    },

    computed: {
        validatableChildren() {
            if (!this.validate) {
                return [];
            }
            return this.getValidatableChildren();
        },
    },

    data() {
        return {
            disabled: false,
        }
    },

    methods: {
        submit() {
            this.onSubmit();
        },

        onSubmit() {
            this.$emit('submit');

            if (this.debug) {
                console.log('Submitting form', (this.validate ? '' : 'validation is OFF'));
            }

            if (this.validate) {
                this.validateInput();
            }
        },

        isValid() {
            return this.validateInput(false, false);
        },

        validateInput(emit = true, process = true) {
            let formIsValid = true;
            let firstInvalidChild = true;
            const formErrors = [];

            this.validatableChildren.forEach(($child, i) => {
                const {
                    isInputValid,
                    inputError,
                } = this.isChildValid($child);

                if (!isInputValid) {
                    formIsValid = false;
                    formErrors.push(inputError);

                    if (process) {
                        $child.setError(inputError);
                    }

                    if (firstInvalidChild && process) {
                        firstInvalidChild = false;

                        if ($child.focus && !$child.skipValidationAutoFocus) {
                            $child.focus();
                        }

                        if (!this.dontScrollIntoView && !$child.skipValidationAutoFocus) {
                            // $child.$el.scrollIntoView({
                            //     behavior: 'auto',
                            //     block: 'center',
                            //     inline: 'start',
                            // });
                        }
                    }
                } else if (process) {
                    $child.setValid();
                }

                if (this.debug) {
                    console.log(i, $child.$options._componentTag, $child.validation, $child.getValue(), (isInputValid ? '✅' : '❌'), inputError);
                }
            });

            if (formIsValid && emit) {
                if (this.debug) {
                    console.log('Form valid ✅');
                }
                this.$emit('submitValid');
            } else if (emit) {
                if (this.debug) {
                    console.log('Form invalid ❌');
                }
                this.$emit('submitInvalid', formErrors);
            }

            return formIsValid;
        },

        getValidatableChildren(element, level = 1) {
            let children = [];
            if (!element) {
                element = this;
            }

            let tree = '';
            for (let x = 0; x < level; x++) {
                tree += '-';
            }

            for (let i in element.$children) {
                if (this.debug) console.log(
                    tree,
                    element.$children[i].$options._componentTag,
                    (element.$children[i].isValidatable ? '[validatable]' : ''),
                    `(${element.$children[i].$children.length})`,
                );

                if (element.$children[i].isValidatable && element.$children[i].validation.length > 0) {
                    children.push(element.$children[i]);
                } else if (element.$children[i].$children.length > 0) {
                    const subChildren = this.getValidatableChildren(element.$children[i], level + 1);
                    children = children.concat(subChildren);
                }
            }

            return children;
        },

        isChildValid($child) {
            let valid = true, error = null;
            const value = $child.getValue();

            $child.validation.forEach((rule) => {
                if (error) {
                    return;
                }

                // TODO: Make validation class
                if (rule === 'required') {
                    if ((!value || value.length < 1)) {
                        valid = false;
                        error = 'The {name} field is required';
                    }
                } else if (rule === 'email' && value) {
                    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                    if (!reg.test((typeof value === 'string' ? value : '').toLowerCase())) {
                        valid = false;
                        error = 'The {name} field is not a valid e-mail address';
                    }
                } else if (rule === 'url' && value) {
                    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

                    if (!pattern.test(value)) {
                        valid = false;
                        error = 'The {name} field is not a valid URL';
                    }
                } else if (rule === 'number' && value) {
                    const testValue = value + '';

                    if (testValue.match(/^-{0,1}\d+$/)) {
                        // valid integer (positive or negative)
                    } else if (testValue.match(/^\d+\.\d+$/)) {
                        // valid float
                    } else {
                        valid = false;
                        error = 'The {name} field is not a valid number';
                    }
                } else if (rule && value) {
                    console.error(`Rule ${rule} not added to validation set.`);
                }
            });

            return {
                isInputValid: valid,
                inputError: (error ? error.replace('{name}', $child.name) : null),
            };
        },
    },
}
</script>

<style lang="scss">
.f-form {
    margin: 2px;

    &.--light .f-form-row__label {
        color: rgba($light, .5);
    }

    &.--dark .f-form-row__label {
        color: rgba($dark, .75);
    }

    &.--disabled {
        pointer-events: none;
    }

    &__footer {
        text-align: right;
    }
}
</style>
