<template>
    <div
        :class="[
            ...classNames,
            'f-abstract-input',
            (focussed && !disabled ? '--focus' : null),
            (disabled ? '--disabled' : null),
            (readonly ? '--readonly' : null),
            (inputError ? '--error' : null),
        ]"
        @click="onClick"
        ref="abstract"
    >
        <slot/>

        <f-input-error
            v-if="showError && inputError"
            :error="inputError"
            :target="$refs.abstract"
            :offset-left="errorMarginLeft"
            :align="errorAlign"
        />

        <slot name="after"/>
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import AbstractInputPropsMixin from './AbstractInputPropsMixin';
import FInputError from '../error/FInputError';

export default {
    name: 'f-abstract-input',

    components: {
        FInputError,
    },

    mixins: [
        AbstractInputPropsMixin,
    ],

    props: {
        focussed: Boolean,
        showError: Boolean,
        inputError: String,
    },

    methods: {
        onClick(e) {
            e.stopPropagation();
            this.$emit('click', e);
        },
    },
}
</script>

<style lang="scss">
.f-abstract-input {
    display: inline-block;
    vertical-align: middle;
    color: $light;

    @include theme(light) {
        color: $dark;
    }

    @include theme(dark) {
        color: $light;
    }

    &.--readonly {
        opacity: .5;
        pointer-events: none;
    }
}
</style>
