<template>
    <f-swipe-gestures
        :class-name="[
            'f-carousel',
            (navigationType !== 'none' ? `--nav-align-${navigationVerticalAlign}` : null),
            `--nav-${navigationType}`,
            (navigationFloat ? '--nav-float' : undefined),
            (autoSize ? '--auto-size' : undefined),
            (stretchHeight ? '--stretch-height' : undefined),
            (animating ? '--animating' : undefined),
            ...classNames,
        ]"
        @swipe-left="onSwipeLeft"
        @swipe-right="onSwipeRight"
    >
        <div
            class="f-carousel__slide-container"
            ref="container"
            :style="containerStyles"
        >
            <slot/>
        </div>

        <ul
            v-if="navigationType === 'dots'"
            class="f-carousel__navigation"
        >
            <f-carousel-navigation-item
                v-for="(item) in navigationItems"
                :title="item.title"
                :active="(currentUID === item.id)"
                :key="item.id"
                :tooltip-options="navigationTooltipOptions"
                @click="slideToUID(item.id)"
            />
        </ul>
        <div
            v-else-if="navigationType === 'prev-next'"
            class="f-carousel__navigation"
        >
            <f-icon-button
                icon="chevron-left"
                outlined
                @click="slideToPrevious"
                :disabled="!!!previousUID"
            />
            <f-icon-button
                icon="chevron-right"
                outlined
                @click="slideToNext"
                :disabled="!!!nextUID"
            />
        </div>
    </f-swipe-gestures>
</template>

<script>
import FCarouselNavigationItem from './FCarouselNavigationItem';
import ChildrenMixin from '../../../mixins/ChildrenMixin';
import {CarouselNavigationTypes} from './config';
import FIconButton from '../../form-controls/buttons/FIconButton';
import FSwipeGestures from '../../other/swipe-gestures/FSwipeGestures';
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-carousel',

    components: {
        FSwipeGestures,
        FIconButton,
        FCarouselNavigationItem,
    },

    mixins: [
        ChildrenMixin,
        ClassNameMixin,
    ],

    props: {
        navigationType: {
            type: String,
            default: CarouselNavigationTypes.DOTS,
        },
        navigationVerticalAlign: {
            type: String,
            default: 'bottom',
        },
        navigationFloat: Boolean,
        swipe: Boolean,
        autoSize: Boolean,
        navigationTooltipOptions: {
            type: Object,
            default: () => {
                return {
                    offsetTop: 5,
                    align: 'top',
                };
            },
        },
        slideDuration: {
            type: Number,
            default: 300, // In ms
        },
        stretchHeight: Boolean,
        debug: Boolean,
        autoReturnTo: String,
        autoReturnDuration: {
            type: Number,
            default: 30, // In seconds
        },
    },

    data() {
        return {
            navigationItems: [],
            currentUID: undefined,
            currentID: undefined,
            currentIndex: undefined,
            animating: false,
            animationTimeout: undefined,
            inactivityTimeout: undefined,
            previousUID: undefined,
            nextUID: undefined,
        }
    },

    computed: {
        containerStyles() {
            return {
                transform: `translateX(-${100 * this.currentIndex}%)`,
                transition: `transform ${this.slideDurationParsed}ms`,
            };
        },
        slideDurationParsed() {
            return (this.slideDuration > 200 || !this.autoSize ? this.slideDuration : 200);
        },
    },

    watch: {
        animating(animating) {
            this.$emit('animating', animating);
        },
        currentID() {
            this.$emit('slideChange', this.currentID, this.currentIndex, this.navigationItems.length);
        },
    },

    mounted() {
        this.invalidate();
    },

    beforeDestroy() {
        window.clearTimeout(this.animationTimeout);
        window.clearTimeout(this.inactivityTimeout);
    },

    methods: {
        currentSlide() {
            let child;

            this.getChildren(['f-carousel-slide']).forEach(($child) => {
                if ($child._uid === this.currentUID) {
                    child = $child;
                }
            });

            return child;
        },

        invalidate() {
            this.currentUID = undefined;
            this.$nextTick(this.defineNavigation);
        },

        defineNavigation() {
            this.navigationItems = [];

            let $firstSlide;

            const slides = this.getChildren(['f-carousel-slide']);
            slides.forEach(($child) => {
                this.navigationItems.push({
                    id: $child._uid,
                    title: $child.title,
                });

                if (!$firstSlide || $child.defaultActive) {
                    $firstSlide = $child;
                }
            });

            if (!this.currentUID && slides.length > 0) {
                this.slideToUID($firstSlide._uid, true);
            }
        },

        slideToUID(uid, noAnimation = false) {
            // Fetch UIDs
            const oldUID = this.currentUID;
            this.currentUID = uid;

            // Clear any old timeouts
            window.clearTimeout(this.animationTimeout);

            // Set animation to ture
            this.animating = true;

            // Get children define new child
            let $oldChild, $newChild;

            let i = 0;
            this.getChildren(['f-carousel-slide']).forEach(($child) => {
                if ($child._uid === this.currentUID) {
                    this.currentIndex = i;
                    $newChild = $child;
                    this.currentID = $child.id;
                    $child.activate();
                } else if ($child._uid === oldUID) {
                    $oldChild = $child;
                    $oldChild.deactivate();
                } else {
                    $child.deactivate();
                }
                i++;
            });

            // Set previous and next slide
            this.setPreviousChild();
            this.setNextChild();

            // Animate
            const duration = (noAnimation ? 0 : this.slideDurationParsed);

            if (this.autoSize && $oldChild && $newChild && duration !== 0) {
                if (this.debug) {
                    console.log(`[Autosize] height animation: ${$oldChild.id} to ${$newChild.id}`);
                }

                this.animate($oldChild, $newChild, duration);
            } else {
                if (this.debug) {
                    console.log(`[No autosize] slide: ${($oldChild ? $oldChild.id : 'none')} to ${$newChild.id}`, $newChild, $oldChild);
                }

                this.animationTimeout = window.setTimeout(() => this.animationDone($newChild, $oldChild), duration);
            }

            // Check auto return
            this.triggerAutoReturn();
        },

        animate($oldChild, $newChild, duration) {
            const oldChildHeight = $oldChild.$el.clientHeight;

            $oldChild.$el.style.height = `${oldChildHeight}px`;
            $oldChild.$el.style.transition = `height ${duration * .5}ms linear 50ms`;

            this.$nextTick(() => {
                const newChildHeight = $newChild.$el.clientHeight;

                $newChild.$el.style.height = $oldChild.$el.clientHeight + 'px';
                $newChild.$el.style.transition = `height ${duration * .5}ms linear 0s`;

                // Animate old child
                $oldChild.$el.style.height = `${newChildHeight}px`;

                // Animate new child
                // Somehow Chrome doesn't trigger the animation on next tick (but it does for the old child), so we use this timeout.....
                window.setTimeout(() => $newChild.$el.style.height = `${newChildHeight}px`, 50);

                // Animation done
                this.animationTimeout = window.setTimeout(() => this.animationDone($newChild, $oldChild), duration);
            });
        },

        animationDone($newChild, $oldChild) {
            if ($oldChild) {
                $oldChild.hide();
                $oldChild.$el.style.height = $oldChild.$el.style.transition = null;
            }
            $newChild.$el.style.height = $newChild.$el.style.transition = null;

            this.animating = false;
        },

        slideTo(id) {
            window.clearTimeout(this.animationTimeout);
            let found = false;

            this.getChildren(['f-carousel-slide']).forEach(($child) => {
                if ($child.id === id) {
                    found = true;
                    this.slideToUID($child._uid);
                }
            });

            if (!found) {
                console.error(`Slide with ID ${id} does not exist`);
            }
        },

        setPreviousChild() {
            let $loopPrev, $prev;
            const children = this.getChildren(['f-carousel-slide']);

            // Loop all, take next
            children.forEach(($child) => {
                if ($child.active) {
                    $prev = $loopPrev;
                }
                $loopPrev = $child;
            });

            this.previousUID = ($prev ? $prev._uid : undefined);
        },

        slideToPrevious() {
            if (this.previousUID) {
                this.slideToUID(this.previousUID);
            }
        },

        setNextChild() {
            let $current, $next;
            const children = this.getChildren(['f-carousel-slide']);

            // Loop all, take next
            children.forEach(($child) => {
                if ($child.active) {
                    $current = $child;
                } else if ($current && !$next) {
                    $next = $child;
                }
            });

            this.nextUID = ($next ? $next._uid : undefined);
        },

        slideToNext() {
            if (this.nextUID) {
                this.slideToUID(this.nextUID);
            }
        },

        getChildByUID(uid) {
            let $selectedChild;

            this.getChildren(['f-carousel-slide']).forEach(($child) => {
                if ($child._uid === uid) {
                    $selectedChild = $child;
                }
            });

            return $selectedChild;
        },

        triggerAutoReturn() {
            if (this.inactivityTimeout) {
                window.clearTimeout(this.inactivityTimeout);
            }

            if (this.autoReturnTo && this.currentUID) {
                const $current = this.getChildByUID(this.currentUID);

                if ($current && $current.id !== this.autoReturnTo) {
                    this.inactivityTimeout = window.setTimeout(
                        () => this.slideTo(this.autoReturnTo),
                        (this.autoReturnDuration * 1000) + this.slideDurationParsed
                    );
                }
            }
        },

        onSwipeLeft() {
            if (this.swipe) {
                this.slideToNext();
            }
        },

        onSwipeRight() {
            if (this.swipe) {
                this.slideToPrevious();
            }
        },
    },
}
</script>

<style lang="scss">
.f-carousel {
    position: relative;
    overflow: hidden;

    // Animating
    &.--animating {
        .--scrollable-dark,
        .--scrollable-dark-vertical,
        .--scrollable-dark-horizontal,
        .--scrollable-dark-both,
        .--scrollable-light,
        .--scrollable-light-vertical,
        .--scrollable-light-horizontal,
        .--scrollable-light-both,
        .--scrollable-themed,
        .--scrollable-themed-vertical,
        .--scrollable-themed-horizontal,
        .--scrollable-themed-both,
        .--scrollable,
        .--scrollable-vertical,
        .--scrollable-horizontal,
        .--scrollable-both {
            &::-webkit-scrollbar-thumb {
                background: transparent !important;
            }
        }
    }

    // Slide container
    &__slide-container {
        display: flex;
        align-items: stretch;
    }

    // Auto size
    &.--auto-size .f-carousel__slide-container {
        align-items: flex-start;
    }

    // Stretch height
    &.--stretch-height {
        height: 100%;

        .f-carousel__slide-container, .f-carousel-slide, .f-carousel-slide__content {
            height: 100%;
        }
    }

    // Navigation
    &__navigation {
        margin: 0 !important;
        list-style-type: none !important;
        text-align: center !important;
        width: 100% !important;
        position: absolute !important;;
        left: 0 !important;
    }

    &.--nav-prev-next {
        .f-carousel__navigation {
            .f-icon-button {
                margin: 0 5px;
            }
        }
    }

    // Navigation alignment
    &.--nav-align-top {
        &:not(.--nav-float) {
            padding-top: 38px;
        }

        .f-carousel__navigation {
            top: 0;
            padding: 15px 10px 0;
        }
    }

    &.--nav-align-bottom, &.--nav-align-middle {
        &:not(.--nav-float) {
            padding-bottom: 30px;
        }

        .f-carousel__navigation {
            bottom: 2px;
            padding: 0;
        }
    }

    &.--nav-prev-next {
        &.--nav-align-bottom, &.--nav-align-middle {
            &:not(.--nav-float) {
                padding-bottom: 48px;
            }
        }
    }
}
</style>
