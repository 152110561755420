import {NotificationTypes} from './config';
import {Icons} from '../../../f-components/layout/icons/config';
import moment from 'moment-timezone';

export default {
    computed: {
        iconProps() {
            return {
                icon: this.getIconForType(this.data.definition.type),
                className: this.getBackgroundForType(this.data.definition.type),
            }
        }
    },

    methods: {
        getDateTimeDiff(date) {
            return moment(date).fromNow();
        },
        
        getIconForType(type) {
            switch (type) {
                case NotificationTypes.ANNIVERSARY:
                    return Icons.STAR;
                case NotificationTypes.BIRTHDAY:
                    return Icons.GIFT;
                case NotificationTypes.FIKA:
                    return Icons.PRESENTATION;
                case NotificationTypes.LEAVE:
                    return Icons.CLOCK3;
                case NotificationTypes.ANNOUNCEMENT:
                    return Icons.BULLHORN;
                case NotificationTypes.PICTURE_GALLERY:
                    return Icons.PICTURES;
            }

            return Icons.QUESTION;
        },

        getBackgroundForType(type) {
            switch (type) {
                case NotificationTypes.ANNIVERSARY:
                    return '--warning';
                case NotificationTypes.BIRTHDAY:
                    return '--secondary';
                case NotificationTypes.FIKA:
                    return '--primary';
                case NotificationTypes.LEAVE:
                    return '--info';
                case NotificationTypes.ANNOUNCEMENT:
                    return '--danger';
                case NotificationTypes.PICTURE_GALLERY:
                    return '--success';
            }

            return '--secondary';
        },
    },
};
