import { render, staticRenderFns } from "./AdminFikaPage.vue?vue&type=template&id=6efac16c&"
import script from "./AdminFikaPage.vue?vue&type=script&lang=js&"
export * from "./AdminFikaPage.vue?vue&type=script&lang=js&"
import style0 from "./AdminFikaPage.vue?vue&type=style&index=0&id=6efac16c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports