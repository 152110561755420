var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'entity-avatar-group',
        ..._vm.classNames,
        `--${_vm.size}`,
    ]},[_vm._l((_vm.limitedEntities),function(entity){return _c('entity-avatar',{key:entity.id,attrs:{"name":entity.name,"tooltip":entity.name + _vm.tooltipPost,"src":(entity.image ? entity.image : entity.profile_image),"size":_vm.size,"color":_vm.color,"type":_vm.type}})}),(_vm.entities.length > _vm.max)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.moreDescription),expression:"moreDescription"}],class:[
            'entity-avatar-group__more',
            `--${_vm.size}`,
        ]},[_vm._v(" +"+_vm._s(_vm.entities.length - _vm.max)+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }