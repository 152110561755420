<template>
    <f-placeholder-container class-name="f-form-placeholder">
        <f-form>
            <br/>
            <f-placeholder-form-row/>
            <f-placeholder-form-row/>
            <br/>
            <f-placeholder-form-row/>
            <f-placeholder-form-row/>
            <f-placeholder-form-row/>
            <f-placeholder-form-row/>
        </f-form>
    </f-placeholder-container>
</template>

<script>
import FPlaceholderContainer from './FPlaceholderContainer';
import FForm from '../../form-controls/forms/FForm';
import FPlaceholderFormRow from './FPlaceholderFormRow';

export default {
    name: 'f-form-placeholder',

    components: {
        FPlaceholderFormRow,
        FForm,
        FPlaceholderContainer,
    },
}
</script>

<style lang="scss">
.f-form-placeholder {
    margin-bottom: 100px;
}
</style>
