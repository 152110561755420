<template>
    <f-container
        :class-name="['page-content-header']"
        :max-width="maxWidth"
    >
        <f-flex
            class-name="page-content-header__content"
            :direction="(breakpoints.mobile ? 'column' : 'row')"
            main-axis-align="space-between"
            gap="10px"
            cross-axis-align="end"
        >
            <h2>
                <slot/>
            </h2>
            <slot name="post" v-bind="{ breakpoints }"/>
        </f-flex>
    </f-container>
</template>

<script>


import FContainer from '../../f-components/layout/container/FContainer';
import FFlex from '../../f-components/layout/flex/FFlex';
import BreakpointsMixin from '../../mixins/BreakpointsMixin';

export default {
    name: 'page-content-header',

    mixins: [
        BreakpointsMixin,
    ],

    components: {
        FFlex,
        FContainer,
    },

    props: {
        maxWidth: String,
    },
}
</script>

<style lang="scss">
.page-content-header {
    &__content {
        padding-bottom: 20px;
        border-bottom: 1px solid $quinaryGray;

        h2 {
            flex-grow: 1;
            font-size: 50px;
            line-height: 80%;
            white-space: nowrap;

            @include media(tablet-down) {
                width: 100%;
                font-size: 45px;
            }

            @include media(mobile-down) {
                font-size: 30px;
            }
        }
    }
}
</style>
