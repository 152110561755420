var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wrapper",class:[
        _vm.className,
        'f-scroll-pane',
        `--mode-${_vm.mode}`,
        (_vm.hasScroll ? '--has-scroll' : null),
        (!_vm.noScrollPadding ? '--scroll-padding' : null),
        (_vm.dragging ? '--dragging' : null),
    ],style:({ height: _vm.height })},[_c('div',{directives:[{name:"drag-scroll",rawName:"v-drag-scroll",value:(_vm.drag && !_vm.isTouch),expression:"drag && !isTouch"}],ref:"content",class:[
            'f-scroll-pane__content',
            _vm.scrollClass,
            (_vm.showScrollBarOnlyOnHover ? null : '--always-show-scrollbar'),
        ],on:{"dragScrollStart":_vm.dragScrollStart,"dragScrollStartDelayed":_vm.dragScrollStartDelayed,"dragScrollEnd":_vm.dragScrollEnd}},[_c('div',{staticClass:"f-scroll-pane__content__padder"},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }