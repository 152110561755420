import EventBus from "../../services/EventBus";
import {GlobalEvents} from '../../config/Events';
import LoadingMixin from '../LoadingMixin';

export default {
    mixins: [
        LoadingMixin,
    ],

    data() {
        return {
            entityName: 'entity',
            fetchedData: null,
            mode: 'edit', // edit | create
            debug: false,
        }
    },

    created() {
        if (this.debug) {
            console.log(`[EntityCreateEditMixin.created] ${this.mode}`);
        }
        if (this.mode === 'create') {
            this.endSilentLoading(true);
        }
    },

    mounted() {
        if (this.debug) {
            console.log(`[EntityCreateEditMixin.mounted] ${this.mode}`);
        }
        if (this.mode === 'edit') {
            this.loadFromParam();
        }
    },

    methods: {
        getBaseUrl() {
            throw new Error('Implement geBaseUrl');
        },

        getRedirectPath() {
            throw new Error('Implement getRedirectPath');
        },

        getEditID() {
            return this.$route.params.id;
        },

        loadFromParam() {
            this.startLoading();
            const endpoint = `${this.getBaseUrl()}/${this.getEditID()}`;

            if (this.debug) {
                console.log(`Loading ${endpoint}`);
            }

            window.axios
                .get(endpoint)
                .then(response => {
                    if (this.debug) {
                        console.log(`Loaded ${endpoint}`, response);
                    }

                    this.parseResponseData(response.data);
                    this.endLoading(true);
                })
                .catch(() => this.endLoading());
        },

        submit() {
            if (this.debug) {
                console.log('[EntityCreateEditMixin.submit] Setting up FormData');
            }

            let formData = new FormData();
            let endpoint = this.getBaseUrl();

            if (this.mode === 'edit') {
                formData.set('_method', 'PUT');
                endpoint = `${this.getBaseUrl()}/${this.getEditID()}`;
            }

            for (let key in this.fields) {
                if (Object.prototype.hasOwnProperty.call(this.fields, key)) {
                    if (this.debug) {
                        console.log(` - Adding ${key}: ${this.fields[key]}`);
                    }
                    formData.append(key, (this.fields[key] ? this.fields[key] : ''));
                }
            }

            formData = this.configureFormData(formData);

            this.startLoading();

            if (this.debug) {
                console.log(`[EntityCreateEditMixin.submit] Posting to ${endpoint}`);
            }

            window.axios.post(endpoint, formData, {
                headers: {'Content-Type': 'multipart/form-data'},
            }).then(response => {
                if (this.debug) {
                    console.log(`[EntityCreateEditMixin.submit] Success`, response.data);
                }

                this.parseResponseData(response.data);
                this.endLoading(true);

                EventBus.$emit(GlobalEvents.PUSH_MESSAGE, `Successfully ${this.mode === 'create' ? 'created' : 'updated'} ${this.entityName}`);
                this.$emit('success');

                const redirect = this.getRedirectPath();
                if (redirect) this.$router.push(redirect);
            }).catch((e) => {
                if (this.debug) {
                    console.error(`[EntityCreateEditMixin.submit] Error`, e);
                }
                this.endLoading(true);
            });
        },

        parseResponseData(data) {
            this.fetchedData = data;

            for (let key in this.fetchedData.data) {
                if (!Object.prototype.hasOwnProperty.call(this.fetchedData.data, key)) {
                    continue;
                }
                if (Object.prototype.hasOwnProperty.call(this.fields, key)) {
                    this.fields[key] = this.fetchedData.data[key];
                }
            }

            this.postParseResponseData(this.fetchedData);
        },

        postParseResponseData(data) {
            data;
        },

        configureFormData(formData) {
            return formData;
        },
    }
}
