<template>
    <li
        :class="[
            'public-holiday',
            (isToday ? '--today' : null),
        ]"
    >
        <div
            :class="[
                'public-holiday__day',
                (events[0].start.isBefore() && !isToday ? '--past' : null),
            ]"
        >
            <div class="public-holiday__day__weekday">
                {{ events[0].start.format('ddd') }}
            </div>
            <div class="public-holiday__day__number">
                {{ events[0].start.format('DD') }}
            </div>
        </div>

        <div class="public-holiday__events">
            <div
                v-for="(event, i) in events"
                :key="i"
                :class="[
                    'public-holiday__event',
                    (event.start.isBefore() && !isToday ? '--past' : null),
                    (getIcon(event.name) ? '--has-icon' : null),
                ]"
            >
                <div class="public-holiday__event__name">
                    {{ event.name }}
                </div>
                <div class="public-holiday__event__labels">
                    <f-chip
                        v-for="(country, i) in event.countries"
                        :key="`${country}${i}`"
                        size="small"
                    >
                        {{ country }}
                    </f-chip>
                    <f-chip
                        v-if="event.bank_holiday"
                        variant="primary"
                        size="small"
                        v-tooltip="{
                            text: `Official day off for ${getOfficesVisual(event.offices)}`,
                            variant: 'alternative',
                            wrap: true
                        }"
                    >
                        bank holiday
                    </f-chip>
                    <f-chip
                        v-if="[6, 7].includes(event.start.isoWeekday())"
                        variant="status"
                        size="small"
                    >
                        weekend
                    </f-chip>
                </div>
                <span
                    v-if="getIcon(event.name)"
                    :class="`icon f-icon f-icon-${getIcon(event.name)}`"
                />
            </div>
        </div>
    </li>
</template>

<script>
import moment from 'moment-timezone';
import FChip from '../../../f-components/data/chip/FChip';

export default {
    name: 'public-holiday-day',

    components: {
        FChip,
    },

    props: {
        events: {
            type: Array,
            required: true,
        },
    },

    computed: {
        isToday() {
            return this.events[0].start.format('DDMMYY') === moment().format('DDMMYY');
        },
    },

    methods: {
        getOfficesVisual(offices) {
            let officesVisual = [];
            for (let i in offices) {
                officesVisual.push(offices[i].ucwords());
            }
            return officesVisual.join(', ') + (offices.length > 1 ? ' offices' : ' office');
        },
        getIcon(name) {
            if (name.toLowerCase().includes('valentine')) {
                return 'heart';
            } else if (name.toLowerCase().includes('daylight')) {
                return 'clock3';
            } else if (name.toLowerCase().includes('mother')) {
                return 'woman';
            } else if (name.toLowerCase().includes('father')) {
                return 'man';
            } else if (name.toLowerCase().includes('king\'s birthday')) {
                return 'crown';
            } else if (name.toLowerCase().includes('easter sunday')) {
                return 'easter-egg';
            } else if (name.toLowerCase().includes('christmas')) {
                return 'christmas';
            } else if (name.toLowerCase().includes('tax')) {
                return 'calculator2';
            } else if (name.toLowerCase().includes('memorial day')) {
                return 'medal-empty';
            } else if (name.toLowerCase().includes('national')) {
                return 'flag2';
            }
            return false;
        },
    },
}
</script>

<style lang="scss">
.public-holiday {
    padding: 5px;
    margin-bottom: 5px;
    position: relative;
    display: flex;
    align-items: center;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    &:before {
        background: $tertiaryGray;
        left: calc(60px - 3px);
        height: 7px;
        width: 7px;
        top: calc(50% - 3px);
        content: '';
        position: absolute;
        border-radius: 50%;
    }

    &.--today {
        &:before {
            background: $secondary;
        }
    }

    .public-holiday__day {
        width: 60px;
        flex: 0 0 60px;
        line-height: 160%;

        .public-holiday__day__number {
            text-align: center;
            font-size: 180%;
        }

        .public-holiday__day__weekday {
            text-align: center;
            text-transform: lowercase;
            font-size: 105%;
        }

        &.--past {
            color: $tertiaryGray;
        }
    }

    .public-holiday__events {
        flex: 1 1 auto;
        overflow: visible;
        padding-left: 10px;

        .public-holiday__event {
            padding: 7px;
            border: 1px solid $tertiaryGray;
            position: relative;

            &.--has-icon {
                padding-right: 50px;

                .icon {
                    position: absolute;
                    right: 10px;
                    top: calc(50% - 16px);
                    font-size: 20px;
                    line-height: 30px;
                    color: $tertiaryGray;
                }
            }

            &:first-child {
                border-radius: 5px 5px 0 0;
            }

            &:not(:first-child):not(:last-child) {
                border-top-width: 0;
            }

            &:last-child {
                border-radius: 0 0 5px 5px;
                border-top-width: 0;
            }

            &:first-child:last-child {
                border-radius: 5px;
                border-width: 1px;
            }

            .public-holiday__event__name {
                padding-left: 2px;
                padding-bottom: 5px;
                line-height: 120%;
            }

            .public-holiday__event__labels {
                font-size: 0;

                .f-chip {
                    font-size: 9px;
                    margin-right: 3px;
                }
            }

            &.--past {
                opacity: .5;
            }

            &.--emphasize {
                .name {
                    font-style: italic;
                }
            }
        }
    }
}
</style>
