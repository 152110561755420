import moment from 'moment-timezone';
import APIDataStore from '../services/APIDataStore';
import TimezoneMixin from './TimezoneMixin';

/**
 * Methods for handling meeting room (dated) data.
 */
export default {
    mixins: [
        TimezoneMixin,
    ],

    data() {
        return {
            currentDateString: APIDataStore.date(),
            filterMeetingRooms: null,
        }
    },

    computed: {
        currentDateObject() {
            return moment.tz(this.currentDateString, 'DD-MM-YYYY', this.timezone);
        },
    },

    methods: {
        /**
         * Returns the state of the meeting room.
         */
        getMeetingRoomState(meetingRoom, clock) {
            if (!meetingRoom) {
                return {
                    label: '',
                    current: null,
                    next: null,
                    available: false,
                    almostStarting: false,
                    state: 'unknown',
                };
            }

            clock = (clock ? clock : this.userClock);

            let currentMeeting = null;
            let nextMeeting = null;
            let available = false;
            let almostStarting = false;
            let label = '';
            let state = 'unknown';

            const now = moment.tz(this.timezone);

            for (let i in meetingRoom.calendar.items) {
                let meeting = meetingRoom.calendar.items[i];

                if (!meeting.start || !meeting.end) continue;

                let start = moment.tz(meeting.start.date, meeting.start.timezone).tz(this.timezone);
                let end = moment.tz(meeting.end.date, meeting.end.timezone).tz(this.timezone);

                if (start < now && end > now && !currentMeeting) {
                    currentMeeting = meeting;
                } else if (start > now && end > now && !nextMeeting) {
                    nextMeeting = meeting;
                }
            }

            // Available
            if (meetingRoom.calendar.items.length === 0) {
                available = true;
                state = 'available';
                label = 'Available all day';
            }
            // Available
            else if (!currentMeeting && (!nextMeeting || this.getMeetingMinutesUntil(nextMeeting) > 9)) {
                available = true;
                state = 'available';

                if (!nextMeeting) {
                    if (now.hours() > 12 && now.hours() < 18) {
                        label = 'Available all afternoon';
                    } else if (now.hours() >= 18) {
                        label = 'Available all night';
                    } else {
                        label = 'Available all day';
                    }
                } else {
                    const end = moment.tz(nextMeeting.start.date, nextMeeting.start.timezone).tz(this.timezone);
                    label = 'Available until ' + end.format(this.getTimeFormat(clock));
                }
            }
            // Available + almost starting
            else if (!currentMeeting && this.getMeetingMinutesUntil(nextMeeting) <= 9) {
                almostStarting = true;
                label = 'Next meeting almost starting';
                state = 'booked';
            }
            // Booked
            else if (currentMeeting) {
                label = 'Currently booked';
                state = 'booked';
            }

            return {
                label: label,
                current: currentMeeting,
                next: nextMeeting,
                available: available,
                almostStarting: almostStarting,
                state: state,
            };
        },

        /**
         * @param meeting
         * @returns {*}
         */
        getMeetingMinutesUntil(meeting) {
            const start = moment.tz(meeting.start.date, meeting.start.timezone);
            const now = moment.tz(this.timezone);

            return start.diff(now, 'minutes');
        },
    },
};
