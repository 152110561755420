/**
 * Methods for numbers and budgets.
 */
export default {
    props: {
        currency: {
            type: String,
            required: false,
            default: () => {
                // if (this.data && this.data.office_id && this.data.office_id === 'austin') {
                //     return 'USD';
                // }
                return 'EUR';
            }
        },
    },

    methods: {
        getOverBudgetClass(total, current, skip = false) {
            if (skip) return '';
            
            if (current !== current) { // Check for NaNs
                current = 0;
            }
            if (total !== total) {
                total = 0;
            }

            let percentage = (current / total) * 100;
            if (percentage !== percentage) {
                percentage = 0;
            }

            if (percentage > 100 && percentage <= 105) {
                return '--price-warning';
            } else if (percentage > 105 && percentage <= 120) {
                return '--price-alert';
            } else if (percentage > 120) {
                return '--price-alert';
            }

            return '';
        },

        roundAndZeroIsEmpty(number) {
            return (Math.round(number) !== 0 ? Math.round(number) : '');
        },

        zeroIsEmpty(number) {
            return (number !== 0 ? this.formatNumber(number) : '');
        },

        formatPrice(number, doNotRenderZero = true) {
            if ((number === 0 && doNotRenderZero) || number === null || number === undefined) {
                return '';
            }

            let locale = 'nl-NL';
            if (this.$store && this.$store.state && this.$store.state.$user.office === 'austin') {
                locale = 'en-US';
            }

            return (new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: this.currency,
                minimumFractionDigits: 2,
            })).format(number);
        },

        formatNumber(number, returnEmptyWhenZero = true) {
            if (returnEmptyWhenZero && number === 0 || number === '') {
                return '';
            }

            let locale = 'nl-NL';
            if (this.$store && this.$store.state && this.$store.state.$user.office === 'austin') {
                locale = 'en-US';
            }

            return (new Intl.NumberFormat(locale, {maximumFractionDigits: 2})).format(number);
        },
    },
};
