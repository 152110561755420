<template>
    <div class="select-days-slide">
        <div class="select-days-slide__date-picker">
            <f-date-picker-select
                mode="multi"
                variant="light"
                @picked="onDatePick"
                :apply="configureDates"
                disable-month-and-year-picker
                hide-weekends
                inline
            />
        </div>

        <f-header-body-footer
            :has-header="false"
            max-content-height="calc(100vh - 430px)"
        >
            <f-table
                type="simple-dense"
                style="margin: 0 3px 5px 0;"
            >
                <template #head>
                    <f-table-header-row>
                        <f-table-header-cell>Date</f-table-header-cell>
                        <f-table-header-cell align="right">Hrs</f-table-header-cell>
                    </f-table-header-row>
                </template>
            </f-table>

            <f-inline-message
                v-if="admin && dates.length === 0"
                :closable="false"
                id="leaveRequestsDays"
                variant="warning"
            >
                Please select the day(s) to request {{ `${data.type.replace('_', ' ').replace('leave', '')}` }}
                leave for. <strong>Previously requested days for this employee are not visible in the calendar,
                so please verify the request beforehand.</strong>
            </f-inline-message>
            <f-inline-message
                v-else-if="dates.length === 0 && data.type === 'holiday'"
                :closable="false"
                id="leaveRequestsDays"
                variant="info"
            >
                Please select the day(s) you wish to get time-off for.
                <strong>Take in account your own part time days.</strong>
            </f-inline-message>
            <f-inline-message
                v-else-if="dates.length === 0 && data.type === 'flexible'"
                :closable="false"
                id="leaveRequestsDays"
                variant="info"
            >
                <p>
                    Please select the flexible day you wish to get time-off for.
                    The flexible day will not be retracted from your leave balance.
                </p>
                <p><strong>Please request the full 8 hours.</strong></p>
            </f-inline-message>
            <f-inline-message
                v-else-if="dates.length === 0"
                id="leaveRequestsDays"
                :closable="false"
                variant="info"
            >
                Please select the day(s) you want to request {{ `${data.type.replace('_', ' ').replace('leave', '')}` }}
                leave for.
            </f-inline-message>

            <f-scroll-pane
                ref="scroller"
                :max-height="(data.type === 'holiday' || data.type === 'flexible' ? '170px' : '200px')"
                :show-scroll-bar-only-on-hover="false"
                no-scroll-padding
                variant="light"
                class-name="select-days-slide__days-scroller"
            >
                <f-table
                    v-if="dates.length > 0"
                    type="simple-dense"
                    class-name="selected-days"
                >
                    <leave-requests-date-field
                        v-for="(date, i) in sortedDays"
                        :ref="`selectedDay${i}`"
                        :value="selectedDateHours[date]"
                        :key="date"
                        :date="date"
                        :disabled="data.type === 'flexible'"
                        @change="calculateTotal"
                    />
                </f-table>
            </f-scroll-pane>

            <f-table
                type="simple-dense"
                style="margin: 5px 3px 0 0;"
            >
                <template #foot>
                    <f-table-row>
                        <f-table-cell>Total:</f-table-cell>
                        <f-table-cell align="right">
                            {{ formatNumber(total, false) }}
                            {{ total === 1 ? 'hr' : 'hrs' }}
                        </f-table-cell>
                    </f-table-row>
                    <f-table-row
                        v-if="data.type === 'flexible' || (data.type === 'holiday' && !admin)"
                        :class-name="'remaining-row' + (remainingHrs < 0 ? ' --remaining-error' : '')"
                    >
                        <f-table-cell>Remaining:</f-table-cell>
                        <f-table-cell align="right">
                            {{ formatNumber(remainingHrs, false) }}
                            {{ remainingHrs === 1 ? 'hr' : 'hrs' }}
                        </f-table-cell>
                    </f-table-row>
                </template>
            </f-table>

            <template #footer>
                <f-button
                    @click="$emit('submit', {...data, selectedDateHours, dates})"
                    :value="(mode === 'edit' ? 'Update' : 'Request')"
                    :disabled="requestButtonDisabled"
                    icon="clock3"
                    variant="primary"
                    type="submit"
                />
            </template>
        </f-header-body-footer>

        <f-icon-button
            class-name="details-slide__back"
            icon="chevron-left"
            @click="$emit('back', {...data, selectedDateHours, dates})"
            size="small"
            outlined
            variant="light"
        />
    </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import moment from 'moment';
import FDatePickerSelect from '../../../../f-components/form-controls/dates/FDatePickerSelect';
import FHeaderBodyFooter from '../../../../f-components/layout/header-body-footer/FHeaderBodyFooter';
import FTable from '../../../../f-components/data/table/FTable';
import FTableRow from '../../../../f-components/data/table/FTableRow';
import FTableHeaderCell from '../../../../f-components/data/table/FTableHeaderCell';
import FInlineMessage from '../../../../f-components/notice/inline-message/FInlineMessage';
import FScrollPane from '../../../../f-components/other/scroll-pane/FScrollPane';
import LeaveRequestsDateField from './LeaveRequestsDateField';
import FTableCell from '../../../../f-components/data/table/FTableCell';
import FButton from '../../../../f-components/form-controls/buttons/FButton';
import NumbersAndBudgetMixin from '../../../../mixins/NumbersAndBudgetMixin';
import FIconButton from '../../../../f-components/form-controls/buttons/FIconButton';
import FTableHeaderRow from '../../../../f-components/data/table/FTableHeaderRow';
import Store from '../../../../services/Store';

export default {
    name: 'select-days-slide',

    components: {
        FTableHeaderRow,
        FIconButton,
        FButton,
        FTableCell,
        LeaveRequestsDateField,
        FScrollPane,
        FInlineMessage,
        FTableHeaderCell,
        FTableRow,
        FTable,
        FHeaderBodyFooter,
        FDatePickerSelect,
    },

    props: {
        admin: Boolean,
        data: {
            type: Object,
            required: true,
        },
        mode: {
            type: String,
            default: 'create',
        },
    },

    mixins: [
        NumbersAndBudgetMixin,
    ],

    data() {
        return {
            selectedDateHours: this.data.selectedDateHours,
            dates: this.data.dates,
            total: 0,
            dateFormat: 'YYYY-MM-DD',
        }
    },

    computed: {
        sortedDays() {
            return this.dates.sort();
        },
        remainingHrs() {
            if (this.data.type === 'flexible') {
                return 8 - this.total;
            }
            return (this.$store.state.$leave.remaining ? this.$store.state.$leave.remaining : 0) - this.total;
        },
        requestButtonDisabled() {
            if (this.data.type === 'flexible') {
                return this.total === 0 || this.total > 8;
            }
            if (this.admin) {
                return this.total === 0;
            }
            return this.total === 0;
            // Temporarily disabled so ppl can request days for the new year via negative balance
            // return (this.total === 0 || ((this.data.type === 'holiday' || this.data.type === 'flexible') && this.remainingHrs < 0));
        },
    },

    mounted() {
        this.calculateTotal();
    },

    methods: {
        calculateTotal() {
            let total = 0;
            this.selectedDateHours = {};

            for (let a in this.$refs) {
                let $el = this.$refs[a];
                if ($el && $el[0]) {
                    $el = $el[0];
                }

                if ($el && $el.$options && $el.$options._componentTag === 'leave-requests-date-field') {
                    total += parseInt($el.hrs);

                    this.selectedDateHours[$el.date] = parseInt($el.hrs);
                }
            }

            this.total = total;
        },

        onDatePick(date) {
            const formatted = date.format(this.dateFormat);

            if (this.dates.includes(date.format(this.dateFormat))) {
                this.dates = this.dates.filter((value) => value && formatted !== value);
            } else {
                this.dates.push(formatted);
            }

            this.$nextTick(() => {
                this.$refs.scroller.invalidate();
                this.calculateTotal();
            });
        },

        configureDates(days) {
            const now = moment().startOf('day');

            for (let i in days) {
                if (Object.prototype.hasOwnProperty.call(days, i)) {
                    const formattedDate = days[i].date.format(this.dateFormat);

                    // Add picked dates
                    if (this.dates.includes(formattedDate)) {
                        days[i].picked = true;
                    }

                    // Disable flexible days if already requested
                    if (this.data.type === 'flexible') {
                        if (days[i].date.year() < 2023) {
                            days[i].unavailable = true;
                            days[i].tooltip = `You can request a flexible<br>holiday from 2023 onwards`;

                        } else if (
                            Store.state.$leave.used_flexible_days &&
                            Store.state.$leave.used_flexible_days.includes(days[i].date.year())
                        ) {
                            days[i].unavailable = true;
                            days[i].tooltip = `Flexible day already requested<br>for ${days[i].date.year()}`;
                        }
                    }

                    // Disable past days
                    if (!this.admin && days[i].date.isBefore(now) && days[i].date.format() !== now.format()) {
                        days[i].unavailable = true;
                    }

                    // Disable weekends
                    if ([6, 7].includes(days[i].date.isoWeekday())) {
                        days[i].unavailable = true;
                    }

                    // Add current leave requests
                    if (!(this.data && this.data.type === 'sick') && !this.admin && this.$store.state.$leave.requests) {
                        for (let y in this.$store.state.$leave.requests) {
                            if (
                                !Object.prototype.hasOwnProperty.call(this.$store.state.$leave.requests, y) ||
                                this.$store.state.$leave.requests[y].status === 'denied'
                            ) continue;

                            if (Object.keys(this.$store.state.$leave.requests[y].requested_dates).includes(formattedDate)) {
                                days[i].unavailable = true;
                                days[i].tooltip = 'Already requested';
                            }
                        }
                    }

                    // Add bank holidays
                    if (this.$store.state.$publicHolidays) {
                        for (let y in this.$store.state.$publicHolidays) {
                            if (!Object.prototype.hasOwnProperty.call(this.$store.state.$publicHolidays, y)) continue;

                            const event = this.$store.state.$publicHolidays[y];
                            if (event.bank_holiday && event.offices.includes(this.$store.state.$user.office)) {
                                const formattedPublicHolidayDate = moment(event.start).format(this.dateFormat);

                                if (formattedPublicHolidayDate === formattedDate) {
                                    days[i].unavailable = true;
                                    days[i].tooltip = event.name;
                                }
                            }
                        }
                    }
                }
            }

            return days;
        },
    },
}
</script>

<style lang="scss">
.select-days-slide {
    display: flex;
    height: 100%;
    align-items: flex-start;
    overflow: visible;

    @include media(mobile-down) {
        flex-direction: column;
    }

    &__date-picker {
        padding: 0 20px 0 0;
        text-align: center;
        margin: 0 auto;

        @media only screen and (max-width: 820px) {
            padding: 0 10px;
        }
    }

    .f-inline-message {
        margin: 10px 0;
    }

    .f-table {
        thead {
            .f-table-header-cell {
                border-bottom: 2px solid $quaternaryGray;
            }
        }

        tfoot .f-table-row:first-child .f-table-cell {
            border-top: 2px solid $quaternaryGray !important;
        }

        .f-table-row.--remaining-error {
            color: #f35950;
        }

        .f-text-input {
            min-height: auto;

            &__input {
                padding-top: 7px !important;
                padding-bottom: 7px !important;
            }
        }
    }

    .f-header-body-footer {
        padding-right: 2px;

        footer {
            text-align: right;
            padding-bottom: 2px;
        }
    }

    @include media(mobile-down) {
        .f-scroll-pane.select-days-slide__days-scroller {
            height: auto;

            .f-scroll-pane__content {
                height: auto !important;
                max-height: none !important;
            }
        }
    }
}
</style>
