<template>
    <div
        class="project-lines-marking-field"
        :style="`width: ${width}; max-width: ${maxWidth};`"
    >
        <div
            v-for="(line) in lines"
            :key="line.id"
        >
            <bullet-point v-if="/^Onbekend/.test(line.name) || /^Groep/.test(line.name)">
                {{ line.name.replace('Onbekend', '').replace('Groep', '') }}
            </bullet-point>
            <project-line-marking-field-item
                v-else
                :id="line.id"
                :description="line.name + ` (${(line.quantity ? formatNumber(line.quantity) : 0)} hr${(line.quantity ? formatNumber(line.quantity) : 0) !== 1 ? 's' : ''})`"
                :marking="getMarking(line.id)"
                @change="change"
            />
        </div>
    </div>
</template>

<script>
import ProjectLineMarkingFieldItem from './ProjectLineMarkingFieldItem';
import NumbersAndBudgetMixin from "../../../mixins/NumbersAndBudgetMixin";
import BulletPoint from '../BulletPoint';

export default {
    name: 'project-lines-marking-field',

    components: {
        BulletPoint,
        ProjectLineMarkingFieldItem,
    },

    mixins: [
        NumbersAndBudgetMixin,
    ],

    props: {
        readonly: Boolean,
        width: {
            type: String,
            default: '100%',
        },
        maxWidth: {
            type: String,
            default: 'none',
        },
        lines: {
            type: Array,
            default: () => [],
        },
        linesConfiguration: Array,
    },

    methods: {
        change() {
            this.$emit('change', this.getData());
        },

        getData() {
            let data = [];

            for (let i in this.$children) {
                if (!this.$children[i].getData || !this.$children[i].getData()) {
                    continue;
                }
                data.push(this.$children[i].getData());
            }

            return data;
        },

        getMarking(id) {
            for (let i in this.linesConfiguration) {
                if (this.linesConfiguration[i].id === id) {
                    return this.linesConfiguration[i].marking;
                }
            }
            return '';
        },
    },
}
</script>
<style lang="scss">
.project-lines-marking-field {
    .bullet-point {
        margin: 50px 0 15px 0;
    }

    & > div:first-child .bullet-point {
        margin-top: 0;
    }
}
</style>
