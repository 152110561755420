<template>
    <div class="leave-balance">
        <!-- Header -->
        <div class="leave-balance__header">
            <div
                class="leave-balance__type --type-remaining --align-left"
                :style="{'flex': `0 0 ${remainingPercentage + scheduledPercentage}%`}"
                v-text="'Remaining'"
            />
            <div
                class="leave-balance__type --type-used --align-right"
                :style="{'flex': `0 0 ${usedPercentage + requestedPercentage}%`}"
                v-text="'Used'"
            />
        </div>

        <!-- Values -->
        <div class="leave-balance__values">
            <div
                class="leave-balance__value --type-remaining --align-left"
                :style="{'flex': `0 0 ${remainingPercentage + scheduledPercentage}%`}"
                v-tooltip="{text: `Remaining ${getHolidayLeaveRep()}`, align: 'left', variant: 'info'}"
            >
                {{ holidayLeaveFormat(data.remaining) }}
            </div>
            <div
                class="leave-balance__value --type-used --align-right"
                :style="{'flex': `0 0 ${usedPercentage + requestedPercentage}%`}"
                v-tooltip="{text: `Used ${getHolidayLeaveRep()}`, align: 'right'}"
            >
                {{ holidayLeaveFormat(data.used) }}
            </div>
        </div>

        <!-- Balance bar -->
        <div class="leave-balance__bar">
            <div
                class="leave-balance__bar__section --type-remaining"
                :style="{'flex': `0 0 ${remainingPercentage}%`}"
            />
            <div
                v-if="data.scheduled > 0"
                class="leave-balance__bar__section --type-scheduled"
                :style="{'flex': `0 0 ${scheduledPercentage}%`}"
            />
            <div
                v-if="data.requests_total > 0"
                class="leave-balance__bar__section --type-requested"
                :style="{'flex': `0 0 ${requestedPercentage}%`}"
            />
            <div
                class="leave-balance__bar__section --type-used"
                :style="{'flex': `0 0 ${usedPercentage}%`}"
            />
        </div>

        <!-- Values -->
        <div class="leave-balance__values --bottom">
            <div
                :class="['leave-balance__value', '--type-scheduled', '--align-right']"
                :style="{'flex': `0 0 ${scheduledPercentage + remainingPercentage}%`}"
                v-html="(scheduledPercentage > 0 ? holidayLeaveFormat(data.scheduled) : '')"
                v-tooltip="{text: (scheduledPercentage > 0 ? `Scheduled ${getHolidayLeaveRep()}` : null), align: 'right', variant: 'warning'}"
            />
            <div
                :class="['leave-balance__value', '--type-requested', '--align-left']"
                :style="{'flex': `0 0 ${requestedPercentage + usedPercentage}%`}"
                v-html="(requestedPercentage > 0 ? holidayLeaveFormat(data.requests_total) : '')"
                v-tooltip="{text: (requestedPercentage > 0 ? `Requested ${getHolidayLeaveRep()}` : null), align: 'left', variant: 'secondary'}"
            />
        </div>

        <!-- Footer -->
        <div class="leave-balance__footer">
            <div
                :class="['leave-balance__type', '--type-scheduled', '--align-right']"
                :style="{'flex': `1 ${scheduledPercentage + remainingPercentage}%`}"
                v-text="(scheduledPercentage > 0 ? 'Scheduled' : '')"
            />
            <div
                :class="['leave-balance__type', '--type-requested', '--align-left']"
                :style="{'flex': `1 ${requestedPercentage + usedPercentage}%`}"
                v-text="(requestedPercentage > 0 ? 'Requested' : '')"
            />
        </div>
    </div>
</template>

<script>

import NumbersAndBudgetMixin from '../../../../mixins/NumbersAndBudgetMixin';
import LeaveMixin from '../../../../mixins/LeaveMixin';

export default {
    name: 'leave-balance',

    mixins: [
        NumbersAndBudgetMixin,
        LeaveMixin,
    ],

    props: {
        currency: {
            type: String,
            required: false,
        },
        data: {
            type: Object,
            required: true,
        },
    },

    computed: {
        remainingPercentage() {
            return (this.totalAllSections === 0 ? 50 : ((Math.abs(this.data.remaining) / this.totalAllSections) * 100));
        },
        usedPercentage() {
            return (this.totalAllSections === 0 ? 50 : ((Math.abs(this.data.used) / this.totalAllSections) * 100));
        },
        scheduledPercentage() {
            return (this.totalAllSections === 0 ? 0 : ((Math.abs(this.data.scheduled) / this.totalAllSections) * 100));
        },
        requestedPercentage() {
            return (this.totalAllSections === 0 ? 0 : ((Math.abs(this.data.requests_total) / this.totalAllSections) * 100));
        },
        totalAllSections() {
            return Math.abs(this.data.remaining) +
                Math.abs(this.data.scheduled) +
                Math.abs(this.data.used) +
                Math.abs(this.data.requests_total);
        },
    },
}
</script>

<style lang="scss">
.leave-balance {
    max-width: 440px;
    margin: 0 auto 10px auto;
    padding: 0 15px;
    user-select: none;

    // Header & footer
    &__header, &__footer {
        display: flex;
        width: 100%;

        .leave-balance__type {
            text-align: center;
            opacity: .75;
            font-size: 85%;
            white-space: nowrap;
            padding-right: 2px;
            font-weight: 600;
        }
    }

    // Values
    &__values {
        display: flex;
        width: 100%;

        .leave-balance__value {
            text-align: center;
            font-weight: bold;
            font-size: 200%;
        }

        &.--bottom .leave-balance__value {
            padding-right: 2px;
        }
    }

    // Text colors
    &__header .leave-balance__type,
    &__footer .leave-balance__type,
    &__values .leave-balance__value {
        &.--type-used {
            color: $primary;
        }

        &.--type-scheduled {
            color: $warning;
        }

        &.--type-requested {
            color: $secondary;
        }

        &.--type-remaining {
            color: $info;
        }
    }

    // Bar
    &__bar {
        display: flex;
        width: 100%;

        &__section {
            height: 10px;
            background-color: $light;
            border-right: 2px solid $light;
            min-width: 5px;

            &:first-child {
                border-radius: 5px 0 0 5px;
            }

            &:last-child {
                border-radius: 0 5px 5px 0;
                border: 0;
            }

            &.--type-used {
                background-color: $primary;
            }

            &.--type-scheduled {
                background-color: $warning;
            }

            &.--type-requested {
                background-color: $secondary;
            }

            &.--type-remaining {
                background-color: $info;
            }
        }
    }
}
</style>
