<template>
    <f-dropdown
        ref="validatable"
        v-bind="$props"
        :class-name="[
            'f-client-dropdown',
            ...classNames,
        ]"
        pre-icon="tag"
        v-model="dropdownValue"
        :options="options"
    >
        <template v-slot:selectedOption="selectedOptionProps">
            <div
                v-if="selectedOptionProps.selectedOption"
                class="f-dropdown__selected-option f-discipline-dropdown__selected-option"
            >
                <span
                    v-for="department in selectedOptionProps.selectedOption.parents"
                    :key="department.id"
                    class="f-discipline-dropdown__selected-option__department"
                >
                    {{ department.name }}
                </span>
                <span class="f-discipline-dropdown__selected-option__discipline">
                    &nbsp;{{ selectedOptionProps.selectedOption.value }}
                </span>
            </div>
        </template>

        <template v-slot:options="optionsProps">
            <li
                v-for="(option, index) in optionsProps.options"
                :class="[
                    'f-discipline-dropdown__option',
                    (optionsProps.selectedIndex === index ? '--selected' : null),
                    (optionsProps.selectedOption && optionsProps.selectedOption.key === option.key ? '--chosen' : null),
                ]"
                :key="option.key"
                @click.prevent="optionsProps.onSelect(option, index)"
            >
                <span
                    v-for="department in option.parents"
                    :key="department.id"
                    class="f-discipline-dropdown__option__department"
                >
                    {{ department.name }}
                </span>
                <span class="f-discipline-dropdown__option__discipline">
                    &nbsp;{{ option.value }}
                </span>
            </li>
        </template>
    </f-dropdown>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import FDropdown from './FDropdown';
import DepartmentAndDisciplinesMixin, {getDisciplineOptions} from '../../../mixins/DepartmentAndDisciplinesMixin';
import AbstractInputFieldPropsMixin from '../base/AbstractInputFieldPropsMixin';
import DropdownPropsMixin from './DropdownPropsMixin';
import PassDownInputValidationMixin from '../base/PassDownInputValidationMixin';

export default {
    name: 'f-discipline-dropdown',

    components: {
        FDropdown,
    },

    mixins: [
        DepartmentAndDisciplinesMixin,
        AbstractInputFieldPropsMixin,
        DropdownPropsMixin,
        PassDownInputValidationMixin,
    ],

    props: {
        options: {
            type: Array,
            default: () => {
                return getDisciplineOptions();
            },
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        resetable: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            dropdownValue: this.value,
        }
    },

    watch: {
        dropdownValue(value) {
            this.$emit('input', value);
        },
        value(value) {
            if (value !== this.dropdownValue) {
                this.dropdownValue = value;
            }
        },
    },

    methods: {
        getSelected() {
            return this.$refs.validatable.getSelectedOption();
        },
    },
}
</script>

<style lang="scss">
.f-discipline-dropdown {
    &__selected-option, &__option {
        white-space: nowrap;

        &__department {
            opacity: .5;

            &::after {
                font-family: 'Linearicons';
                display: inline-block;
                content: '\EC3C';
                opacity: .5;
                font-size: 60%;
                vertical-align: middle;
            }
        }

        &__discipline {
        }
    }
}
</style>
