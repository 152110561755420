<template>
    <div
        :class="['f-placeholder', '--fill', ...classNames]"
        :style="{
            width,
            height,
            display: (inline ? 'inline-block': null),
        }"
    />
</template>

<script>
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-placeholder',

    mixins: [
        ClassNameMixin,
    ],

    props: {
        width: {
            type: String,
            default: '60%',
        },
        height: {
            type: String,
            default: '16px',
        },
        inline: Boolean,
    },
}
</script>

<style lang="scss">
.f-placeholder {
    margin-bottom: 5px;
    border-radius: 2px;
    opacity: .5;

    &:last-child {
        margin-bottom: 0;
    }
}
</style>
