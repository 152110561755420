<template>
    <div
        :class="[
            `f-weather-icon wi wi-${icon}`,
            (set ? `wi-${set}` : null),
            ...classNames
        ]"
    />
</template>

<script>
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-weather-icon',

    mixins: [
        ClassNameMixin,
    ],

    props: {
        icon: {
            type: String,
            required: true,
        },
        set: String,
    },

    computed: {},

    watch: {},

    data() {
        return {}
    },

    mounted() {
    },

    beforeDestroy() {
    },

    methods: {},
}
</script>

<style lang="scss">
.f-weather-icon {
    position: relative;
    text-align: center;
}
</style>
