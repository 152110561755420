<template>
    <div
        :class="[
            ...classNames,
            'f-abstract-input-field',
            (focussed && !disabled ? '--focus' : null),
            (disabled ? '--disabled' : null),
            (readonly ? '--readonly' : null),
            (inputError ? '--error' : null),
            (hasHover ? '--hover' : null),
        ]"
        :style="{
            maxWidth: maxWidth,
            width: width
        }"
        @click="onClick"
        @mouseenter="hasHover = true"
        @mouseleave="hasHover = false"
        ref="abstract"
    >
        <div class="f-abstract-input-field__pre">
            <slot name="pre"/>
        </div>

        <div
            :class="[
                'f-abstract-input-field__content',
                (align ? `--align-${align}` : null),
            ]"
        >
            <slot/>
        </div>

        <div class="f-abstract-input-field__post">
            <slot
                v-if="!resetable || (resetable && !hasContent)"
                name="post"
            />
            <f-icon-button
                v-if="resetable && !readonly && ((hideResetWhenEmpty && hasContent) || !hideResetWhenEmpty)"
                :class-name="(hasContent ? null : '--hide')"
                icon="cross"
                size="xsmall"
                variant="themed"
                outlined
                @click="onResetClick"
                tabindex="-1"
            />
        </div>

        <f-input-error
            v-if="showError && inputError"
            :target="$refs.abstract"
            :error="inputError"
            :align="errorAlign"
        />

        <slot name="after"/>
    </div>
</template>

<script>
import FIconButton from '../buttons/FIconButton';
import AbstractInputFieldPropsMixin from './AbstractInputFieldPropsMixin';
import FInputError from '../error/FInputError';

export default {
    name: 'f-abstract-input-field',

    components: {
        FInputError,
        FIconButton,
    },

    mixins: [
        AbstractInputFieldPropsMixin,
    ],

    props: {
        focussed: Boolean,
        hasContent: Boolean,
        showError: Boolean,
        inputError: String,
        hideResetWhenEmpty: Boolean,
    },

    data() {
        return {
            hasHover: false,
        }
    },

    methods: {
        onClick(e) {
            e.stopPropagation();
            this.$emit('click', e);
        },

        onResetClick(e) {
            e.stopPropagation();
            this.$emit('reset', e);
        },
    },
}
</script>

<style lang="scss">
.f-abstract-input-field {
    width: 100%;
    display: inline-flex;
    border: 1px solid;
    font-size: 13px;
    transition: all .1s;
    align-items: center;
    min-height: 42px;
    border-radius: $inputBorderRadius;
    margin-bottom: 5px;
    font-weight: 500;

    &__content {
        flex: 1 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__pre {
        flex: 0;

        > .f-icon {
            margin: 0 3px 0 15px;
        }

        > .f-icon-button {
            margin: 0 3px 0 10px;

            &.--hide {
                visibility: hidden;
            }
        }
    }

    &__post {
        flex: 0;

        > .f-icon {
            margin: 0 15px 0 3px;
        }

        > .f-icon-button {
            margin: 0 10px 0 5px;

            &.--hide {
                visibility: hidden;
            }
        }
    }

    // -- States

    &.--focus {
        @include theme(light) {
            box-shadow: 0 0 0 2px rgba($dark, .2);
        }
        @include theme(dark) {
            box-shadow: 0 0 0 2px rgba($light, .2);
        }
    }

    &.--disabled {
        opacity: .35;
        pointer-events: none;
    }

    &.--readonly {
        border-bottom-color: rgba($light, .1);
        pointer-events: none;
    }

    // -- Variants
    @include theme(light) {
        color: $dark;
        border-color: $tertiaryGray;

        input {
            color: $dark;
        }

        &.--focus {
            border-color: $quaternaryGray;
        }

        &.--readonly {
            border-color: rgba($dark, .05);
        }
    }

    @include theme(dark) {
        color: $light;
        border-color: $quaternaryGray;

        input {
            color: $light;
        }

        &.--focus {
            border-color: $tertiaryGray;
        }

        &.--readonly {
            border-color: rgba($light, .1);
        }
    }

    .--theme-dark &.--error,
    .--theme-light :not(.f-editor-toolbar) &.--error {
        border-color: $danger;

        &.--focus {
            border-color: $dangerShade;
            box-shadow: 0 0 0 2px rgba($dangerShade, .5);
        }

        .f-abstract-input-field__pre {
            .f-icon {
                color: $danger;
            }
        }
    }
}
</style>
