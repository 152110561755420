var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        text: _vm.title,
        align: 'bottom',
    }),expression:"{\n        text: title,\n        align: 'bottom',\n    }"}],class:[
        'navigation-item',
        ..._vm.classNames,
        (_vm.forceHover ? '--hover' : null),
    ],on:{"mouseenter":function($event){return _vm.$emit('mouseenter')}}},[(_vm.link)?_c('router-link',{attrs:{"to":_vm.link},nativeOn:{"click":function($event){return _vm.EventBus.$emit('mobile.menu.close')}}},[_c('f-icon',{attrs:{"icon":_vm.icon}})],1):(!_vm.to)?_c('a',{on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('f-icon',{attrs:{"icon":_vm.icon}})],1):_vm._e(),(_vm.badge)?_c('f-badge',[_vm._v(" "+_vm._s(_vm.badge > 99 ? '99+' : _vm.badge)+" ")]):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }