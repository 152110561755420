<template>
    <div :class="['f-breadcrumbs', `--align-${align}`]">
        <div class="f-breadcrumbs__container">
            <ul class="f-breadcrumbs__container__list">
                <slot/>
            </ul>
            <transition
                v-if="badge"
                name="fade"
                appear
            >
                <div class="f-breadcrumbs__container__badge">
                    <f-badge variant="alternative">
                        {{ badge }}
                    </f-badge>
                </div>
            </transition>
        </div>
        <slot name="post"/>
    </div>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import FBadge from '../../data/badge/FBadge';

export default {
    name: 'f-breadcrumbs',

    components: {
        FBadge,
    },

    props: {
        align: {
            type: String,
            default: 'left',
        },
        badge: String | Number,
    },
}
</script>

<style lang="scss">
.f-breadcrumbs {
    line-height: 130%;
    display: flex;
    align-items: flex-start;

    .f-breadcrumb-list {
        white-space: nowrap;
    }

    > * {
        flex: 1 1;
    }

    &__container {
        padding: 8px 0;

        &__list {
            display: inline-block;
        }

        &__badge {
            flex: 0 1px !important;
            display: inline-block;

            .f-badge {
                position: absolute;
                top: 50%;
                right: inherit;
                transform: translateY(-50%);
            }
        }
    }

    &.--align-left .button-container {
        text-align: right;
    }

    &.--align-right .button-container {
        text-align: left;
    }
}
</style>
