<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
    <f-table-row
        v-bind="$props"
        :selectable="false"
        :on-hover-theme-change="false"
        :class-name="['f-table-header-row', ...classNames]"
    >
        <f-table-header-cell
            v-if="selectable"
            class-name="f-table-header-row__selectable"
            no-padding-right
            no-padding-bottom-top
            hide-mobile
        >
            <f-icon-button
                ref="selectableActions"
                class-name="f-table-row__actions"
                @click="onActionsClick"
                icon="ellipsis"
                size="small"
                outlined
                :selected="selectableActionsOpen"
                no-parent-hover
            />
            <f-badge v-if="selectedCount" variant="secondary">
                {{ selectedCount }}
            </f-badge>

            <f-popup
                v-if="selectableActionsOpen && $refs.selectableActions"
                :target="$refs.selectableActions.$el"
                @close="selectableActionsOpen = false"
                padding="0"
            >
                <f-popup-list>
                    <f-popup-list-item
                        icon="check-square"
                        @click="selectableActionsOpen = false; selectAllRows();"
                    >
                        Select all
                    </f-popup-list-item>
                    <f-popup-list-item
                        icon="cross-square"
                        @click="selectableActionsOpen = false; deselectAllRows();"
                    >
                        Deselect all
                    </f-popup-list-item>

                    <f-popup-list-item
                        v-if="action"
                        v-for="(action, i) in selectableActions"
                        :disabled="action.disabled"
                        :icon="action.icon"
                        :key="i"
                        @click="selectableActionsOpen = false; action.onClick($event);"
                    >
                        {{ action.value }}
                    </f-popup-list-item>
                </f-popup-list>
            </f-popup>
        </f-table-header-cell>

        <slot/>
    </f-table-row>
</template>

<script>
import FTableRow from "./FTableRow";
import FTableHeaderCell from "./FTableHeaderCell";
import FIconButton from "../../form-controls/buttons/FIconButton";
import FPopup from "../../other/popup/FPopup";
import FPopupList from "../../other/popup/FPopupList";
import FPopupListItem from "../../other/popup/FPopupListItem";
import FBadge from "../badge/FBadge";
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-table-header-row',

    mixins: [
        ClassNameMixin,
    ],

    components: {
        FBadge,
        FPopupListItem,
        FPopupList,
        FPopup,
        FIconButton,
        FTableHeaderCell,
        FTableRow,
    },

    props: {
        disable: Boolean,
        noWrap: Boolean,
        actions: Array,
        selectable: Boolean,
        selectedCount: Number,
        selectableActions: Array,
    },

    data() {
        return {
            selectableActionsOpen: false,
        }
    },

    methods: {
        onActionsClick() {
            this.selectableActionsOpen = true;
        },
        selectAllRows() {
            this.$emit('selectAllRows');
        },
        deselectAllRows() {
            this.$emit('deselectAllRows');
        },
    },
}
</script>

<style lang="scss">
.f-table-header-row {
    &__selectable {
        width: 20px;
        position: relative;
        vertical-align: middle;
        text-align: center;

        .f-badge {
            top: -1px;
            right: -5px;
        }
    }

    .f-table-header-cell {
        &:first-child {
            padding-left: 20px !important;
        }

        &:last-child {
            padding-right: 20px !important;
        }
    }
}
</style>
