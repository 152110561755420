<template>
    <f-abstract-input
        v-bind="boundProps"
        :class-name="[
            'f-image-input',
            ...classNames,
            `--${type}`,
        ]"
        :focussed="hasFocus"
        :input-error="inputError"
        show-error
        @click="focus"
    >
        <div
            class="f-image-input__container"
            :style="{
                width: width,
                height: height,
                backgroundImage: backgroundImage
            }"
            @dragover="onDragOver"
            @dragleave="onDragLeave"
            @drop="onDrop"
        >
            <f-icon
                v-if="!readonly"
                :icon="icon"
            />
            <input
                ref="input"
                type="file"
                class="f-image-input__field"
                :name="name"
                :accept="(accept ? accept.join(',') : null)"
                @change="onFileSelect"
                @focus="onFocus"
                @blur="onBlur"
            />
        </div>

        <f-icon-button
            v-if="resetable && backgroundImage"
            class-name="f-image-input__reset-icon"
            icon="cross"
            size="xsmall"
            @click.stop="reset"
            v-tooltip="'Reset'"
        />
    </f-abstract-input>
</template>

<script>
import {Icons} from '../../layout/icons/config';
import FAbstractInput from '../base/FAbstractInput';
import AbstractInputPropsMixin from '../base/AbstractInputPropsMixin';
import AbstractInputValidationMixin from '../base/AbstractInputValidationMixin';
import AbstractInputEventsMixin from '../base/AbstractInputEventsMixin';
import AbstractInputMixin from '../base/AbstractInputMixin';
import FIcon from '../../layout/icons/FIcon';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import {ButtonVariants} from '../buttons/config';
import {humanReadableFileSize} from '../../../utils/fileSizes';
import FIconButton from '../buttons/FIconButton';

export default {
    name: 'f-image-input',

    components: {
        FIconButton,
        FIcon,
        FAbstractInput,
    },

    mixins: [
        AbstractInputPropsMixin,
        AbstractInputValidationMixin,
        AbstractInputEventsMixin,
        AbstractInputMixin,
    ],

    props: {
        src: String,
        width: {
            type: String,
            default: '80px',
        },
        height: {
            type: String,
            default: '80px',
        },
        icon: {
            type: String,
            default: Icons.PLUS,
        },
        accept: {
            type: Array,
            default: () => ['.jpg', '.jpeg', '.png', '.gif'],
        },
        skipValidationAutoFocus: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            default: 'round', // round | square
        },
        maxSize: {
            type: Number,
            default: 2097152,
        },
        resetable: Boolean,
    },

    data() {
        return {
            file: null,
            active: false,
            uploadSrc: null,
        }
    },

    computed: {
        backgroundImage() {
            let src = null;

            if (this.uploadSrc) {
                src = this.uploadSrc;
            } else if (this.src) {
                src = this.src;
            }

            this.$emit('sourceChange', src);

            return (src ? `url(${src})` : null);
        },
    },

    methods: {
        reset() {
            this.uploadSrc = null;
            this.file = null;
            this.resetValidation();
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
            this.$emit('sourceChange', undefined);
            this.$emit('reset');
        },

        getValue() {
            return this.getFile() ?? this.value;
        },

        onDragOver(e) {
            e.preventDefault();

            if (!this.active) {
                this.active = true;
            }
        },

        onDrop(e) {
            e.preventDefault();
            this.active = false;

            if (e.dataTransfer.items) {
                for (let i = 0; i < e.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (e.dataTransfer.items[i].kind === 'file') {
                        const file = e.dataTransfer.items[i].getAsFile();
                        this.setFile(file);
                    }
                }
            } else {
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    this.setFile(e.dataTransfer.files[i]);
                }
            }
        },

        setFile(file) {
            if (file.size > this.maxSize) {
                EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                    body: `The provided image is too large. Please keep it under ${humanReadableFileSize(this.maxSize)}.`,
                    confirmButtonVariant: ButtonVariants.DANGER,
                });
                return;
            }

            this.file = file;
            this.uploadSrc = URL.createObjectURL(file);

            this.$emit('input', this.getFile());

            this.resetValidation();
        },

        getFile() {
            return this.file;
        },

        onDragLeave() {
            this.active = false;
        },

        onFileSelect() {
            if (!this.$refs.input || !this.$refs.input.files) return;
            const curFiles = this.$refs.input.files;
            if (curFiles.length !== 0) {
                for (const file of curFiles) {
                    if (file) {
                        this.setFile(file);
                    }
                }
            }
        },

        focus() {
            if (this.$refs.input) {
                this.$refs.input.click();
            }
        },

        getExcludedProps() {
            return [
                'src',
                'width',
                'height',
                'icon',
                'accept',
                'skipValidationAutoFocus',
                'type',
            ];
        },
    },
}
</script>

<style lang="scss">
.f-image-input {
    display: inline-block;
    position: relative;

    &__reset-icon {
        position: absolute;
        top: -7px;
        right: -7px;
    }

    &__container {
        margin: 4px;
        cursor: pointer;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: rgba($light, .5);
        transition: color .1s;
        background-position: center center;
        background-size: cover;

        > .f-image-input__field {
            display: none;
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
        }

        &:after {
            content: '';
            transition: border-color .1s;
            position: absolute;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            top: -3px;
            left: -3px;
            border: 1px dashed rgba($light, .5);
        }

        &:hover, &.--active {
            &:after {
                border-color: rgba($light, 1);
            }

            color: rgba($light, 1);
        }
    }

    &.--round .f-image-input__container {
        border-radius: 50%;

        &:after {
            border-radius: 50%;
        }
    }

    &.--square .f-image-input__container {
        border-radius: 5px;

        &:after {
            border-radius: 5px;
        }
    }

    @include theme(light) {
        .f-image-input__container {
            color: rgba($dark, .5);

            &:after {
                border-color: rgba($dark, .5);
            }

            &:hover, &.--active {
                &:after {
                    border-color: rgba($dark, 1);
                }

                color: rgba($dark, 1);
            }
        }
    }

    &.--error {
        .f-image-input__container:after {
            border-color: $danger;
        }

        .f-icon {
            color: $danger;
        }
    }
}
</style>
