var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'f-table__container',
        '--loader',
        (_vm.rowsHoverable ? '--rows-hoverable' : null),
        (_vm.integrateInputFields ? '--integrate-input-fields' : null),
        (_vm.allowOverflow ? '--allow-overflow --scrollable' : null),
        (_vm.alignCell ? `--align-cell-${_vm.alignCell}` : null),
        `--${_vm.type}`,
        _vm.className,
    ]},[_vm._t("pre"),(!_vm.empty)?_c('div',{class:[
            'f-table__wrapper',
            (_vm.allowHorizontalScroll ? '--scrollable-themed-horizontal' : null),
        ],on:{"scroll":function($event){return _vm.EventBus.$emit(_vm.GlobalEvents.OVERFLOW_SCROLL)}}},[_c('table',{staticClass:"f-table"},[_c('thead',{staticClass:"--no-select"},[_vm._t("head")],2),_c('tbody',[_vm._t("default")],2),_c('tfoot',[_vm._t("foot")],2)])]):_c('nothing-found',{attrs:{"class-name":"f-table__nothing-found"}}),_vm._t("post")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }