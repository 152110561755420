var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'faq',
        '--loader',
        (_vm.loading ? '--loading' : null),
        (_vm.inEditMode ? '--edit-mode' : null),
    ]},[(_vm.search)?_c('f-filter',[_c('f-filter-row',[_c('f-filter-item',[_c('f-text-input',{attrs:{"pre-icon":"magnifier","class-name":"--align-center","placeholder":"Search…","disabled":_vm.inEditMode,"resetable":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchQuery"}})],1)],1)],1):_vm._e(),(_vm.editable && _vm.editButtonPosition === 'top' && _vm.hasPermission(_vm.Permissions.FAQ_EDIT))?_c('div',{staticClass:"faq__edit --top"},[_c('f-button-group',[(_vm.inEditMode)?_c('f-button',{attrs:{"outlined":"","size":"small","icon":"plus","icon-position":"left","value":"Add category"},on:{"click":_vm.showForm}}):_vm._e(),_c('f-button',{attrs:{"icon":(_vm.inEditMode ? 'cross' : 'pencil'),"icon-position":"left","outlined":"","size":"small","value":(_vm.inEditMode ? 'Disable editing' : 'Edit FAQ')},on:{"click":function($event){_vm.inEditMode = !_vm.inEditMode}}})],1),_c('f-help-label',{attrs:{"text":"You are a FAQ admin, so you see all items, regardless of which office you belong to"}})],1):_vm._e(),(_vm.loading && !_vm.fetchedData)?_c('f-table-placeholder'):_vm._e(),(_vm.fetchedData && _vm.items.length === 0)?_c('nothing-found'):_vm._e(),(_vm.fetchedData)?_c('draggable',{class:[
            'draggable-container',
            (_vm.currentGroup ? `--dragging-${_vm.currentGroup}` : null),
        ],attrs:{"group":"items","handle":".faq-drag","data-id":0,"list":_vm.items},on:{"change":_vm.onChange,"start":_vm.onDragStart,"end":_vm.onDragEnd}},_vm._l((_vm.items),function(item){return _c('faq-item',{key:item.id,attrs:{"item":item,"edit":_vm.inEditMode,"render-category-as-bullet-point":_vm.renderCategoryAsBulletPoint},on:{"refresh":_vm.onRefresh,"change":_vm.onChange,"start":_vm.onDragStart,"end":_vm.onDragEnd}})}),1):_vm._e(),(_vm.editable && _vm.editButtonPosition === 'bottom' && _vm.hasPermission(_vm.Permissions.FAQ_EDIT))?_c('div',{staticClass:"faq__edit --bottom"},[_c('f-button-group',[(_vm.inEditMode)?_c('f-button',{attrs:{"outlined":"","size":"small","icon":"plus","icon-position":"left","value":"Add category"},on:{"click":_vm.showForm}}):_vm._e(),_c('f-button',{attrs:{"icon":(_vm.inEditMode ? 'cross' : 'pencil'),"icon-position":"left","outlined":"","size":"small","value":(_vm.inEditMode ? 'Disable editing' : 'Edit FAQ')},on:{"click":function($event){_vm.inEditMode = !_vm.inEditMode}}})],1),_c('f-help-label',{attrs:{"text":"You are a FAQ admin, so you see all items, regardless of which office you belong to"}})],1):_vm._e(),(_vm.inEditMode && _vm.showCategoryForm)?_c('f-modal',{attrs:{"title":`Create FAQ category`,"width":"800px"},on:{"close":function($event){_vm.showCategoryForm = false}}},[_c('faq-edit-create-form',{attrs:{"form-mode":"create","default-places":[_vm.place],"parent-id":0,"parent-titles":[]},on:{"success":function($event){_vm.showCategoryForm = false; _vm.onRefresh()}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }