<template>
    <li
        class="time-table-meeting"
        :data-column-i="data.columnI"
        :data-column-span="data.columnSpan"
        :data-column-count="data.columnCount"
        :style="styles"
        :class="[
            (isSmall ? '--small' : null),
            (hasPast ? '--past' : null),
            (data.alternative ? '--alternative' : null),
            (data.pending && data.pending === true ? '--pending' : null),
        ]"
    >
        <div class="time-table-meeting__content">
            <div class="time-table-meeting__content__heading">
                <b>{{ data.name }}</b>
                <span
                    v-if="!data.is_all_day"
                    v-html="visualTimeSpan"
                    class="time-table-meeting__time-span"
                />
            </div>

            <entity-avatar-group
                :entities="data.attendees"
                size="xsmall"
            />
        </div>
    </li>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import moment from 'moment-timezone';
import TimezoneMixin from '../../../mixins/TimezoneMixin';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import EntityAvatarGroup from '../entities/EntityAvatarGroup';

export default {
    name: 'meeting',

    components: {
        EntityAvatarGroup,
    },

    mixins: [
        TimezoneMixin,
    ],

    props: {
        data: Object,
        i: String | Number,
        clock: String, // '24' or '12' if you want to overrule the user setting.
        office: {
            type: String,
            required: true,
        },
    },

    computed: {
        styles() {
            if (this.data.is_all_day) {
                const top = this.i * 24 + 2;
                return `top: ${top}px;`;
            }

            const start = moment.tz(this.data.start.date, this.data.start.timezone).tz(this.timezone);
            let end = moment.tz(this.data.end.date, this.data.end.timezone).tz(this.timezone);
            let extra = '';

            // Some meetings span to the next day(s)
            if (end.day() !== start.day()) {
                end = end.subtract(end.hours(), 'hours');
                end = end.subtract(end.minutes() + 1, 'minutes');
            }

            const diff = start.diff(end, 'minutes');
            const minute = 100 / (24 * 60);
            const top = (start.minutes() + (start.hours() * 60)) * minute;
            const height = Math.abs(diff) * minute;

            let left = 0;
            const columnWidth = (100 / this.data.columnCount);

            if (this.data.columnCount) {
                let width = columnWidth * this.data.columnSpan;

                extra = `width: calc(${width}% - 1px);`;
                left = columnWidth * this.data.columnI;
            }

            return `top: calc(${top}% + 2px); height: calc(${height}% - 3px); left: calc(${left}% + 1px); ${extra}`;
        },

        visualTimeSpan() {
            const start = moment.tz(this.data.start.date, this.data.start.timezone);
            const end = moment.tz(this.data.end.date, this.data.start.timezone);

            return start.tz(this.timezone).format(this.timeFormat) + ' – ' + end.tz(this.timezone).format(this.timeFormat);
        },

        isSmall() {
            const start = moment.tz(this.data.start.date, this.data.start.timezone);
            const end = moment.tz(this.data.end.date, this.data.end.timezone);
            const minutes = end.diff(start, 'minutes');

            return minutes < 20;
        },
    },

    data() {
        return {
            hasPast: false,
        }
    },

    mounted() {
        EventBus.$on(GlobalEvents.MINUTE_CHANGE, this.defineHasPast);
        this.defineHasPast();
    },

    beforeDestroy() {
        EventBus.$off(GlobalEvents.MINUTE_CHANGE, this.defineHasPast);
    },

    methods: {
        defineHasPast() {
            if (this.data.is_all_day) return false;

            const now = moment.tz(this.data.start.timezone);
            const end = moment.tz(this.data.end.date, this.data.end.timezone);

            this.hasPast = (now > end);
        },
    },
}
</script>

<style lang="scss">
.time-table-meeting {
    color: $light;
    position: absolute;
    width: calc(100% - 1px);
    border-radius: 1px;
    overflow: hidden;
    box-shadow: rgba(black, .05) 0 0 5px;
    background-color: $info;
    min-height: 10px;

    @include theme(light) {
        background-color: $info;
    }

    @include theme(dark) {
        background-color: $infoShade;
    }

    &.--past {
        @include theme(light) {
            background-color: $tertiaryGray;
        }

        @include theme(dark) {
            background-color: lighten($quaternaryGray, 5);
        }

        color: $light;

        .time-table-meeting__content__heading {
            text-decoration: line-through;
            filter: grayscale(100%);
        }

        .entity-avatar-group {
            .entity-avatar {
                background: darken($tertiaryGray, 20);
            }
        }
    }

    &.--pending {
        opacity: .5;
    }

    &__content {
        overflow: hidden;
        font-size: 80%;
        padding: 3px 6px;
        white-space: nowrap;
        height: 100%;
        text-overflow: ellipsis;
        position: relative;
        z-index: 2;

        &__heading {
            b {
                padding-right: 3px;
                font-weight: 600;
            }

            padding-bottom: 3px;
        }
    }

    &__time-span {
        opacity: .75;

        .--am-pm {
            text-transform: lowercase;
        }
    }

    &.--small {
        .time-table-meeting__content {
            line-height: 120%;

            .entity-avatar-group {
                display: none;
            }
        }
    }

    .entity-avatar-group {
        .entity-avatar {
            background: darken($info, 20);
            color: $light;
        }
    }
}
</style>
