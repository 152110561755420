<template>
    <div
        :class="['picture-gallery-event', (isSafari ? '--bs' : null)]"
        @click="openGallery"
    >
        <div
            class="picture-gallery-event__bg"
            :style="{ backgroundImage: `url(${cover})` }"
        />

        <f-text tag="div" font-weight="400">
            {{ subtitle }}
        </f-text>
        <div class="f-text">
            {{ title }}
        </div>

        <custom-cursor/>
    </div>
</template>

<script>
import FText from '../../../../f-components/layout/text/FText';
import CustomCursor from '../../CustomCursor';
import {isSafari} from '../../../../utils/helpers';
import EventBus from '../../../../services/EventBus';
import {GlobalEvents} from '../../../../config/Events';

export default {
    name: 'picture-gallery-event',

    components: {
        CustomCursor,
        FText,
    },

    props: {
        title: String,
        subtitle: String,
        slug: String,
        cover: String,
    },

    computed: {
        // coverSrc() {
        //     return APIEndpoints.secureAsset(this.cover.src);
        // },
    },

    data() {
        return {
            galleryOpen: false,
            isSafari: isSafari(),
        }
    },

    methods: {
        openGallery() {
            EventBus.$emit(GlobalEvents.GALLERY_OPEN, this.slug);
        },
    },
}
</script>

<style lang="scss">
.picture-gallery-event {
    background-color: $tertiaryGray;
    border-radius: 6px;
    padding: 20px 20px;
    height: 240px;
    width: 240px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    overflow: hidden;
    position: relative;

    @include media(mobile-down) {
        height: 200px;
        width: 200px;
    }

    > .f-text {
        position: relative;
        z-index: 3;
        text-shadow: rgba(#000, .5) 0 0 5px;
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        transition: transform .2s ease-in-out;
        background-position: 50% 90%;
    }

    &:hover:not(.--bs) {
        .picture-gallery-event__bg {
            transform: scale(1.1);
            transition: transform 2s ease-in-out;
        }
    }

    &:before, &:after {
        position: absolute;
        left: 0;
        content: '';
        height: 60px;
        width: 100%;
        z-index: 2;
    }

    &:before {
        top: 0;
        background: linear-gradient(180deg, rgba(#000, .5) 0%, rgba(#000, 0) 100%);
    }

    &:after {
        bottom: 0;
        background: linear-gradient(180deg, rgba(#000, 0) 0%, rgba(#000, .5) 100%);
    }
}
</style>
