<template>
    <div :class="['environment-label', '--no-select']">
        {{ environment }}
    </div>
</template>

<script>
export default {
    name: 'environment-label',

    props: {
        environment: String,
    },
}
</script>

<style lang="scss">
.environment-label {
    background-color: $danger;
    color: white;
    text-align: center;
    padding: 5px;
    width: 400px;
    font-weight: 600;
    z-index: 9999;
    position: fixed;
    transform: rotate(-45deg);
    top: 22px;
    left: -168px;
    pointer-events: none;
    opacity: .75;
}
</style>
