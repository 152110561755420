<template>
    <div
        :class="[
            'meeting-room',
            // `--${state.state}`,
        ]"
        :style="{
            left: `${left}px`,
            top: `${top}px`,
            height: `${height}px`,
            width: `${width}px`,
        }"
    >
        <pin
            :left="pinLeft"
            :top="pinTop"
            :title="title"
            color="purple"
            icon="group-work"
        />
    </div>
</template>

<script>
import Pin from './Pin';
// import MeetingRoomsMixin from '../../../../mixins/MeetingRoomsMixin';
// import MeetingRoomsTodayMixin from '../../../../mixins/MeetingRoomsTodayMixin';

export default {
    name: 'meeting-room',

    components: {
        Pin,
    },

    props: {
        id: Number,
        title: String,
        left: String,
        top: String,
        pinLeft: String,
        pinTop: String,
        width: String,
        height: String,
        office: {
            type: String,
            default: 'amsterdam',
        },
    },

    mixins: [
        // MeetingRoomsMixin,
        // MeetingRoomsTodayMixin,
    ],

    computed: {
        meetingRoom() {
            // for (let i in this.meetingRooms) {
            //     if (this.meetingRooms[i].id === this.id) {
            //         return this.meetingRooms[i];
            //     }
            // }
            return null;
        },
        // state() {
        //     return this.getMeetingRoomState(this.meetingRoom);
        // },
        // statusTitle() {
        //     return `<strong>${this.title}</strong><br><span style="font-size: 90%">${this.state.label}</span>`;
        // },
    },
}
</script>

<style lang="scss">
.meeting-room {
    position: absolute;
    cursor: pointer;
    transform-style: preserve-3d;

    &.--booked {
        background: rgba($primary, .2);
    }

    &.--available {
        background: rgba($success, .2);
    }
}
</style>
