var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{ref:"link",class:['main-menu-item']},[(_vm.link)?_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            text: (_vm.noTooltip === false ? _vm.title : null),
            align: 'right',
            className: 'main-menu-tooltip'
        }),expression:"{\n            text: (noTooltip === false ? title : null),\n            align: 'right',\n            className: 'main-menu-tooltip'\n        }"}],class:[
            'main-menu-item__link',
            (_vm.forceHover ? '--force-hover' : null)
        ],attrs:{"to":_vm.link}},[_c('div',{staticClass:"main-menu-item__link__icon"},[_c('f-icon',{attrs:{"icon":_vm.icon}},[(_vm.badge)?_c('f-badge',[_vm._v(" "+_vm._s(_vm.badge)+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"main-menu-item__link__body"},[_c('div',{staticClass:"main-menu-item__link__body__title",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('div',{staticClass:"main-menu-item__link__body__subtitle",domProps:{"innerHTML":_vm._s(_vm.subtitle)}})])]):_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            text: (_vm.noTooltip === false ? _vm.title : null),
            align: 'right',
            className: 'main-menu-tooltip'
        }),expression:"{\n            text: (noTooltip === false ? title : null),\n            align: 'right',\n            className: 'main-menu-tooltip'\n        }"}],class:[
            'main-menu-item__link',
            (_vm.forceHover ? '--force-hover' : null)
        ],attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click', $event)}}},[_c('div',{staticClass:"main-menu-item__link__icon"},[_c('f-icon',{attrs:{"icon":_vm.icon}},[(_vm.badge)?_c('f-badge',[_vm._v(" "+_vm._s(_vm.badge)+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"main-menu-item__link__body"},[_c('div',{staticClass:"main-menu-item__link__body__title",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('div',{staticClass:"main-menu-item__link__body__subtitle",domProps:{"innerHTML":_vm._s(_vm.subtitle)}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }