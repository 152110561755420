var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'f-flex-table-hours-price-cell',
         (_vm.hideMobile ? '--hide-mobile': null),
         (_vm.showMobile ? '--show-mobile': null),
         (_vm.align ? `--align-${_vm.align}` : null),
         (_vm.border ? '--border': null),
         '--no-wrap',
         _vm.className,
    ],style:(`flex: ${_vm.flex}`)},[(_vm.hrs)?_c('div',{staticClass:"f-flex-table-hours-price-cell__hours"},[_vm._v(" "+_vm._s(_vm.zeroIsEmpty(_vm.hrs))+" "),_vm._t("post-hours")],2):_vm._e(),(_vm.price)?_c('div',{staticClass:"f-flex-table-hours-price-cell__price"},[_vm._t("pre-price"),_vm._v(" "+_vm._s(_vm.formatPrice(_vm.price))+" "),_vm._t("post-price")],2):_c('div',{staticClass:"f-flex-table-hours-price-cell__price"},[_vm._t("price")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }