<template>
    <page
        v-if="hasPermission(Permissions.ADMIN_CLIENTS_VIEW)"
        class-name="admin-client-show-page"
        :loading="loading"
        :title="['Admin', 'Clients', 'View']"
        header="View client"
        :skewed-footer-foreground="(!fetchedData && loading ? '#fff' : null)"
        pre-max-width="800px"
        :actions="actions"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb link="/admin/clients">Clients</f-breadcrumb>
                <f-breadcrumb>
                    {{ fields && fields.name ? fields.name : '' }}
                </f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-container max-width="800px">
            <entity-header
                v-if="fetchedData"
                :image="fields.image_url"
                :name="fields.name"
                :title="fields.name"
            >
                <div class="admin-client-show-page__entity-header__subtitle">
                    {{ (fields.office_id ? fields.office_id.ucfirst() : 'Unknown') }} office
                </div>

                <user-card-mini
                    v-if="userById(fields.account_director_id)"
                    :user="userById(fields.account_director_id)"
                    variant="primary"
                />

                <template #post>
                    <f-chip
                        v-if="fields.forecast_id"
                        v-tooltip="'Synced from Harvest Forecast'"
                        variant="info"
                    >
                        <img src="/img/harvest.png" alt="Harvest"/>
                    </f-chip>
                    <f-chip
                        v-if="fields.gripp_id"
                        v-tooltip="'Synced from Gripp'"
                        variant="info"
                    >
                        <img src="/img/gripp.png" alt="Gripp"/>
                    </f-chip>
                    <f-chip
                        v-if="fields.xero_id"
                        v-tooltip="'Synced from XERO'"
                        variant="info"
                    >
                        <img src="/img/xero.png" alt="Xero"/>
                    </f-chip>
                </template>
            </entity-header>

            <f-form-row-spacer v-if="fields"/>

            <f-form-fieldset
                v-if="fetchedData"
                alternative
            >
                <f-form-row label="Created at" v-if="fields.created_at">
                    <f-date-picker
                        v-model="fields.created_at"
                        width="200px"
                        readonly
                    />
                </f-form-row>
                <f-form-row label="Updated at" v-if="fields.updated_at">
                    <f-date-picker
                        v-model="fields.updated_at"
                        width="200px"
                        readonly
                    />
                </f-form-row>
            </f-form-fieldset>

            <f-entity-header-placeholder
                v-if="!fetchedData && loading"
            />
        </f-container>

        <skewed-section
            v-if="fetchedData"
            foreground-color="#333"
            after-background="#222"
            padding="100px 0 150px"
            margin="100px 0 -50px"
        >
            <f-theme-provider variant="dark">
                <f-container max-width="800px">
                    <f-tabs margin @tabChange="tabID = $event">
                        <f-tab id="exceptions" title="Rate card exceptions"/>
                        <f-tab id="references" title="References"/>
                    </f-tabs>

                    <div v-if="tabID === 'exceptions'">
                        <f-scroll-pane
                            v-if="fields.price_exceptions && fields.price_exceptions.length > 0"
                            max-height="400px"
                            variant="light"
                        >
                            <f-table
                                class-name="admin-user-show-page__exceptions"
                                type="simple-dense"
                            >
                                <f-table-row
                                    v-for="row in fields.price_exceptions"
                                    :key="row.id"
                                >
                                    <f-table-cell width="50px" is-id align="right">
                                        {{ row.product.number }}
                                    </f-table-cell>
                                    <f-table-cell>
                                        {{ row.product.name }}
                                        <f-chip
                                            v-if="fields.is_archived"
                                            variant="archived"
                                        >
                                            archived
                                        </f-chip>
                                    </f-table-cell>
                                    <f-table-cell align="center">
                                        <f-chip
                                            v-for="(label, i) in row.tags"
                                            :key="i"
                                        >
                                            {{ label }}
                                        </f-chip>
                                    </f-table-cell>
                                    <f-table-cell align="center">
                                        <f-chip
                                            v-tooltip="'Synced from Gripp'"
                                            variant="dark"
                                        >
                                            <img src="/img/gripp.png" alt="Gripp"/>
                                        </f-chip>
                                    </f-table-cell>
                                    <f-table-cell width="160px" align="right" class-name="--no-wrap">
                                        <span class="--strike">&euro; {{ row.product.selling_price }}</span>&nbsp;&nbsp;
                                        &euro; {{ row.selling_price }}
                                    </f-table-cell>
                                </f-table-row>
                            </f-table>
                        </f-scroll-pane>

                        <nothing-found v-else/>
                    </div>

                    <references-list
                        v-if="tabID === 'references'"
                        :references="fields.references"
                    />
                </f-container>
            </f-theme-provider>
        </skewed-section>

        <entity-action-button
            :permission="Permissions.ADMIN_CLIENTS_EDIT"
            :route="`/admin/clients/edit/${$route.params.id}`"
            icon="pencil"
            tooltip="Edit"
        />
    </page>
</template>

<script>
import APIEndpoints from '../../../../config/APIEndpoints';
import Page from '../../Page';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import NumbersAndBudgetMixin from '../../../../mixins/NumbersAndBudgetMixin';
import EntityHeader from '../../../misc/entities/EntityHeader';
import FChip from '../../../../f-components/data/chip/FChip';
import UserHelperMixin from '../../../../mixins/UserHelperMixin';
import UserCardMini from '../../../cards/users/UserCardMini';
import FTable from '../../../../f-components/data/table/FTable';
import FTableRow from '../../../../f-components/data/table/FTableRow';
import FTableCell from '../../../../f-components/data/table/FTableCell';
import FFormFieldset from '../../../../f-components/form-controls/forms/FFormFieldSet';
import FFormRow from '../../../../f-components/form-controls/forms/FFormRow';
import FDatePicker from '../../../../f-components/form-controls/dates/FDatePicker';
import FFormRowSpacer from '../../../../f-components/form-controls/forms/FFormRowSpacer';
import EntityShowMixin from '../../../../mixins/entities/EntityShowMixin';
import FContainer from '../../../../f-components/layout/container/FContainer';
import FEntityHeaderPlaceholder from '../../../../f-components/loaders/placeholder/FEntityHeaderPlaceholder';
import SkewedSection from '../../../misc/skewed-section/SkewedSection';
import FThemeProvider from '../../../../f-components/layout/theme/FThemeProvider';
import FTabs from '../../../../f-components/navigation/tabs/FTabs';
import FTab from '../../../../f-components/navigation/tabs/FTab';
import {Permissions} from '../../../../config/Permissions';
import {Icons} from '../../../../f-components/layout/icons/config';
import ReferencesList from '../../../misc/ReferencesList';
import NothingFound from '../../../misc/NothingFound';
import FScrollPane from '../../../../f-components/other/scroll-pane/FScrollPane';
import EntityActionButton from '../../../misc/entities/EntityActionButton';

export default {
    name: 'admin-client-show-page',

    components: {
        EntityActionButton,
        FScrollPane,
        NothingFound,
        ReferencesList,
        FTab,
        FTabs,
        FThemeProvider,
        SkewedSection,
        FEntityHeaderPlaceholder,
        FContainer,
        FFormRowSpacer,
        FDatePicker,
        FFormRow,
        FFormFieldset,
        FTableCell,
        FTableRow,
        FTable,
        UserCardMini,
        FChip,
        EntityHeader,
        FBreadcrumb,
        FBreadcrumbs,
        Page,
    },

    mixins: [
        NumbersAndBudgetMixin,
        UserHelperMixin,
        EntityShowMixin,
    ],

    computed: {
        actions() {
            if (!this.fetchedData || !this.hasPermission(Permissions.ADMIN_CLIENTS_EDIT)) {
                return [];
            }

            const actions = [];

            actions.push({
                value: 'Edit client',
                icon: Icons.PENCIL,
                link: `/admin/clients/edit/${this.$route.params.id}`,
            });

            return actions;
        },
    },

    data() {
        return {
            entityName: 'client',
            tabID: null,
        }
    },

    methods: {
        getBaseUrl() {
            return APIEndpoints.ADMIN_CLIENTS;
        },
    },
}
</script>

<style lang="scss">
.admin-client-show-page {
    &__entity-header__subtitle {
        margin: 3px 0 5px !important;
    }

    .entity-header {
        .f-chip {
            margin-left: 2px;
        }
    }
}
</style>
