<template>
    <page
        v-if="hasPermission(Permissions.PROJECTS_VIEW)"
        class-name="projects-page"
        title="Projects"
        skewed-footer-foreground="#fff"
        header="Projects"
        pre-max-width="800px"
    >
        <!--        <template #pre>-->
        <!--            <f-breadcrumbs>-->
        <!--                <f-breadcrumb>-->
        <!--                    Projects <sup>DEPRECATED</sup>-->
        <!--                </f-breadcrumb>-->
        <!--                <f-breadcrumb>-->
        <!--                    Overview-->
        <!--                </f-breadcrumb>-->
        <!--            </f-breadcrumbs>-->
        <!--        </template>-->

        <f-container max-width="800px">
            <!--            <div id="projectsPage"/>-->

            <!--            <f-filter-->
            <!--                extra-->
            <!--                show-active-filters-->
            <!--                :default-open="filterOpen"-->
            <!--                :active-filters="$store.state.$projects.filters"-->
            <!--                :filter-casts="{-->
            <!--                    responsible_id: 'user',-->
            <!--                    archived: 'boolean',-->
            <!--                    overspend_only: 'boolean',-->
            <!--                    client_id: 'client',-->
            <!--                    date_start: 'date',-->
            <!--                    date_end: 'date',-->
            <!--                    query: 'query'-->
            <!--                }"-->
            <!--            >-->
            <!--                <f-filter-row>-->
            <!--                    <f-filter-item>-->
            <!--                        <f-text-input-->
            <!--                            placeholder="Search…"-->
            <!--                            pre-icon="magnifier"-->
            <!--                            v-model="searchQuery"-->
            <!--                            class-name="&#45;&#45;align-center"-->
            <!--                            max-width="300px"-->
            <!--                            resetable-->
            <!--                        />-->
            <!--                    </f-filter-item>-->
            <!--                    <f-filter-item>-->
            <!--                        <f-dropdown-->
            <!--                            placeholder="Type"-->
            <!--                            v-model="type"-->
            <!--                            :options="[-->
            <!--                                {key: 'quote', value: 'Quotes'},-->
            <!--                                 {key: 'assignment', value: 'Assignments'}-->
            <!--                             ]"-->
            <!--                            pre-icon="folder-search"-->
            <!--                            resetable-->
            <!--                        />-->
            <!--                    </f-filter-item>-->
            <!--                </f-filter-row>-->
            <!--                <f-filter-row>-->
            <!--                    <f-filter-item>-->
            <!--                        <f-sorting-dropdown-->
            <!--                            :options="[-->
            <!--                                {key: 'number', value: 'Number'},-->
            <!--                                {key: 'start_date', value: 'Start date'},-->
            <!--                                {key: 'budget', value: 'Budget'},-->
            <!--                                {key: 'result', value: 'Result'},-->
            <!--                                {key: 'hours_sold', value: 'Hours sold'},-->
            <!--                                {key: 'hours_allocated', value: 'Hours allocated'},-->
            <!--                                {key: 'hours_allocated_cost', value: 'Hours cost'},-->
            <!--                            ]"-->
            <!--                            v-model="sorting"-->
            <!--                        />-->
            <!--                    </f-filter-item>-->
            <!--                    <f-filter-item>-->
            <!--                        <f-dropdown-->
            <!--                            :options="[-->
            <!--                                {key: 'Client Work - Fixed Scope', value: 'Client Work - Fixed Scope'},-->
            <!--                                {key: 'Client Work - Time and Material', value: 'Client Work - Time and Material'},-->
            <!--                                {key: 'Client Work - Retainer', value: 'Client Work - Retainer'},-->
            <!--                            ]"-->
            <!--                            resetable-->
            <!--                            placeholder="Tag"-->
            <!--                            pre-icon="tag"-->
            <!--                            v-model="tag"-->
            <!--                            width="100%"-->
            <!--                        />-->
            <!--                    </f-filter-item>-->
            <!--                </f-filter-row>-->

            <!--                <template #extra>-->
            <!--                    <f-filter-row>-->
            <!--                        <f-filter-item>-->
            <!--                            <f-user-dropdown-->
            <!--                                class-name="&#45;&#45;align-center"-->
            <!--                                placeholder="Responsible"-->
            <!--                                v-model="responsibleUser"-->
            <!--                                width="100%"-->
            <!--                                resetable-->
            <!--                            />-->
            <!--                        </f-filter-item>-->
            <!--                        <f-filter-item>-->
            <!--                            <f-client-dropdown-->
            <!--                                class-name="&#45;&#45;align-center"-->
            <!--                                placeholder="Client"-->
            <!--                                v-model="client"-->
            <!--                            />-->
            <!--                        </f-filter-item>-->
            <!--                    </f-filter-row>-->
            <!--                    <f-filter-row>-->
            <!--                        <f-filter-item>-->
            <!--                            <f-date-picker-->
            <!--                                placeholder="Date start"-->
            <!--                                v-model="dateStart"-->
            <!--                                resetable-->
            <!--                            />-->
            <!--                        </f-filter-item>-->
            <!--                        <f-filter-item class-name="filter-dash &#45;&#45;no-select">-->
            <!--                            till-->
            <!--                        </f-filter-item>-->
            <!--                        <f-filter-item>-->
            <!--                            <f-date-picker-->
            <!--                                placeholder="Date end"-->
            <!--                                v-model="dateEnd"-->
            <!--                                resetable-->
            <!--                                :disabled="!dateStart"-->
            <!--                            />-->
            <!--                        </f-filter-item>-->
            <!--                    </f-filter-row>-->
            <!--                    <f-filter-row>-->
            <!--                        <f-filter-item class-name="filter-switch">-->
            <!--                            <f-switch v-model="archived" id="archiveSwitch"/>-->
            <!--                            <label for="archiveSwitch" class="&#45;&#45;no-select">Archived</label>-->
            <!--                        </f-filter-item>-->
            <!--                        <f-filter-item class-name="filter-switch">-->
            <!--                            <f-switch v-model="overspendOnly" id="overspendSwitch"/>-->
            <!--                            <label for="overspendSwitch" class="&#45;&#45;no-select">Overspend only</label>-->
            <!--                        </f-filter-item>-->
            <!--                    </f-filter-row>-->
            <!--                </template>-->
            <!--            </f-filter>-->

            <!--            <f-inline-message-->
            <!--                variant="did-you-know"-->
            <!--                id="projectsDidYouKnow"-->
            <!--                class-name="&#45;&#45;hide-mobile"-->
            <!--            >-->
            <!--                <strong>Did you know?</strong> You can favourite a project by clicking-->
            <!--                <f-icon icon="ellipsis" style="margin: 0 5px;"/>-->
            <!--                on a project row, and toggling-->
            <!--                <f-icon icon="power" style="margin: 0 0 0 5px;"/>-->
            <!--                . Favourite projects will show up on your dashboard!-->
            <!--            </f-inline-message>-->

            <f-inline-message
                id="productiveProjectsMessage"
                :closable="false"
            >
                <p>
                    We have migrated to <a href="https://app.productive.io/22699" target="_blank">Productive</a>.
                    Productive is replacing this projects module.
                </p>
            </f-inline-message>

            <!--            <projects-overview-->
            <!--                v-if="$store.state.$projects"-->
            <!--                ref="table"-->
            <!--                @refresh="onNavigate(1)"-->
            <!--                @navigate="onNavigate"-->
            <!--            />-->

            <!--            <f-table-placeholder v-else/>-->
        </f-container>
    </page>
</template>

<script>
import Page from '../Page';
import FFilter from "../../../f-components/data/filter/FFilter";
import FFilterRow from "../../../f-components/data/filter/FFilterRow";
import FFilterItem from "../../../f-components/data/filter/FFilterItem";
import FTextInput from "../../../f-components/form-controls/text/FTextInput";
import FClientDropdown from "../../../f-components/form-controls/dropdowns/FClientDropdown";
import FDatePicker from "../../../f-components/form-controls/dates/FDatePicker";
import FSwitch from "../../../f-components/form-controls/switches/FSwitch";
import APIDataStore from "../../../services/APIDataStore";
import APIDataStoreResources from "../../../config/APIDataStoreResources";
import ErrorHandler from "../../../services/ErrorHandler";
import ProjectsOverview from "./components/ProjectsOverview";
import FDropdown from "../../../f-components/form-controls/dropdowns/FDropdown";
import FUserDropdown from "../../../f-components/form-controls/dropdowns/FUserDropdown";
import FSortingDropdown from "../../../f-components/data/filter/FSortingDropdown";
import FInlineMessage from "../../../f-components/notice/inline-message/FInlineMessage";
import FIcon from "../../../f-components/layout/icons/FIcon";
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import FContainer from '../../../f-components/layout/container/FContainer';
import FTablePlaceholder from '../../../f-components/loaders/placeholder/FTablePlaceholder';
import FBreadcrumbs from '../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../f-components/navigation/breadcrumbs/FBreadcrumb';

export default {
    name: 'projects-page',

    components: {
        FBreadcrumb,
        FBreadcrumbs,
        FTablePlaceholder,
        FContainer,
        FIcon,
        FInlineMessage,
        FSortingDropdown,
        FUserDropdown,
        FDropdown,
        ProjectsOverview,
        FSwitch,
        FDatePicker,
        FClientDropdown,
        FTextInput,
        FFilterItem,
        FFilterRow,
        FFilter,
        Page,
    },

    data() {
        return {
            // loading: false,
            // timeOut: null,
            //
            // // -- Filters
            // searchQuery: this.$store.state.$projects.filters.query,
            // responsibleUser: this.$store.state.$projects.filters.responsible_id,
            // type: this.$store.state.$projects.filters.type,
            // tag: this.$store.state.$projects.filters.tag,
            // client: this.$store.state.$projects.filters.client_id,
            // overspendOnly: this.$store.state.$projects.filters.overspend_only,
            // archived: this.$store.state.$projects.filters.archived,
            // dateStart: this.$store.state.$projects.filters.date_start,
            // dateEnd: this.$store.state.$projects.filters.date_end,
            // // -- Sorting
            // sorting: {
            //     field: (this.$store.state.$projects.sorting ? this.$store.state.$projects.sorting.field || 'number' : 'number'),
            //     direction: (this.$store.state.$projects.sorting ? this.$store.state.$projects.sorting.direction || 'desc' : 'desc'),
            // },
            //
            // filterOpen: !!(
            //     this.$store.state.$projects.filters.overspend_only ||
            //     this.$store.state.$projects.filters.responsible_id ||
            //     this.$store.state.$projects.filters.client_id ||
            //     this.$store.state.$projects.filters.archived ||
            //     this.$store.state.$projects.filters.date_start ||
            //     this.$store.state.$projects.filters.date_end
            // ),
        }
    },

    watch: {
        // searchQuery() {
        //     this.filterChange();
        // },
        // responsibleUser() {
        //     this.filterChange();
        // },
        // type() {
        //     this.filterChange();
        // },
        // client() {
        //     this.filterChange();
        // },
        // tag() {
        //     this.filterChange();
        // },
        // archived() {
        //     this.filterChange();
        // },
        // overspendOnly() {
        //     this.filterChange();
        // },
        // sorting() {
        //     this.filterChange();
        // },
        // dateEnd(value) {
        //     if (value !== null && this.dateStart) {
        //         this.filterChange();
        //     }
        // },
        // dateStart(value) {
        //     if (value === null) {
        //         this.dateEnd = null;
        //         this.filterChange();
        //     } else if (this.dateEnd) {
        //         this.filterChange();
        //     }
        // },
    },

    created() {
        // APIDataStore.check(APIDataStoreResources.CLIENTS_COLLECTION);
        // APIDataStore.check(APIDataStoreResources.USERS_COLLECTION);
        // APIDataStore.fetch(APIDataStoreResources.PROJECTS, {
        //     queryParams: {
        //         page: (this.$store.state.$projects.current_page ? this.$store.state.$projects.current_page : 1),
        //         ...this.getFilter(),
        //     },
        // });
    },

    mounted() {
        // APIDataStore.fetch(APIDataStoreResources.PROJECT_FAVOURITES);
        // EventBus.$on(`ds.${APIDataStoreResources.PROJECTS.id}.update`, this.onUpdate);
        // EventBus.$on(`ds.${APIDataStoreResources.PROJECTS.id}.error`, this.onError);
    },

    beforeDestroy() {
        // EventBus.$off(`ds.${APIDataStoreResources.PROJECTS.id}.update`, this.onUpdate);
        // EventBus.$off(`ds.${APIDataStoreResources.PROJECTS.id}.error`, this.onError);
    },

    methods: {
        // onNavigate(pageNumber, position = 'top') {
        //     this.loading = true;
        //
        //     if (position === 'bottom') {
        //         this.$refs.table.$el.scrollIntoView({
        //             behavior: 'smooth',
        //             block: 'start',
        //         });
        //     }
        //
        //     APIDataStore.fetch(APIDataStoreResources.PROJECTS, {
        //         queryParams: {
        //             page: pageNumber,
        //             ...this.getFilter(),
        //         },
        //     });
        // },
        //
        // filterChange() {
        //     window.clearTimeout(this.timeOut);
        //
        //     this.timeOut = window.setTimeout(() => this.applyFilter(), 500);
        // },
        //
        // applyFilter() {
        //     this.loading = true;
        //
        //     APIDataStore.fetch(APIDataStoreResources.PROJECTS, {
        //         queryParams: this.getFilter(),
        //     });
        // },
        //
        // getFilter() {
        //     return {
        //         'filter[query]': this.searchQuery,
        //         'filter[responsible_id]': this.responsibleUser,
        //         'filter[type]': this.type,
        //         'filter[tag]': this.tag,
        //         'filter[client_id]': this.client,
        //         'filter[archived]': this.archived,
        //         'filter[overspend_only]': this.overspendOnly,
        //         'filter[date_start]': this.dateStart,
        //         'filter[date_end]': this.dateEnd,
        //         'sorting[field]': this.sorting.field,
        //         'sorting[direction]': this.sorting.direction,
        //     };
        // },
        //
        // onUpdate() {
        //     this.loading = false;
        //
        //     if (this.$store.state.$projects.errors && this.$store.state.$projects.errors.length > 0) {
        //         EventBus.$emit(GlobalEvents.PUSH_MESSAGE, this.$store.state.$projects.errors.join(' '), 'error');
        //     }
        //
        //     this.dateStart = this.$store.state.$projects.filters.date_start;
        //     this.dateEnd = this.$store.state.$projects.filters.date_end;
        // },
        //
        // onError(e) {
        //     this.loading = false;
        //     ErrorHandler.onGeneralFatalError(e);
        // },
    },
}
</script>

<style lang="scss">
.projects-page {

}
</style>
