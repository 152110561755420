if (typeof (Number.prototype.toRad) === 'undefined') {
    Number.prototype.toRad = function () {
        return this * Math.PI / 180;
    }
}

if (typeof (Number.prototype.toDeg) === 'undefined') {
    Number.prototype.toDeg = function () {
        return this * (180 / Math.PI);
    }
}

if (typeof (String.prototype.slugify) === 'undefined') {
    String.prototype.slugify = function () {
        return this
            .toString()
            .toLowerCase()
            .normalize('NFD')  // The normalize() method returns the Unicode Normalization Form of a given string.
            .trim()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(/[^\w-]+/g, '') // Remove all non-word chars
            .replace(/--+/g, '-'); // Replace multiple - with single -
    }
}

if (typeof (String.prototype.ucfirst) === 'undefined') {
    String.prototype.ucfirst = function () {
        return this.charAt(0).toUpperCase() + this.substring(1);
    };
}

if (typeof (String.prototype.ucwords) === 'undefined') {
    String.prototype.ucwords = function () {
        let str = this.toLowerCase();

        return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
            function (s) {
                return s.toUpperCase();
            });
    };
}

if (typeof (Object.filter) === 'undefined') {
    Object.filter = function (obj, predicate) {
        let result = {}, key;

        for (key in obj) {
            if (obj[key] && predicate(key, obj[key])) {
                result[key] = obj[key];
            }
        }

        return result;
    };
}

export const ordinalSuffix = (i) => {
    const j = i % 10,
        k = i % 100;

    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }

    return i + "th";
}

export const isTouchDevice = () => {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}

export const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};
