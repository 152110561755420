/**
 * Pronouns correspond with the back-end.
 */
export const Pronouns = {
    she: 'she/her/hers',
    he: 'he/him/his',
    they: 'they/them/theirs',
    ze: 'ze/zir/zirs',
    xe: 'xe/xem/xyrs',
    custom: 'custom',
    none: 'none',
}
