<template>
    <f-table-row
        class-name="project-overview-row"
        @click="$router.push(`/projects/${project.id}`)"
        @selectStateChange="$emit('selectStateChange', $event)"
        :selectable="selectable"
        :selected="selected"
        :actions="actions"
    >
        <f-table-cell width="50px" no-padding-right>
            <entity-avatar
                v-if="clientFromId(project.client_id)"
                :src="clientFromId(project.client_id).image_url"
                :name="clientFromId(project.client_id).name"
                size="large"
            />
            <entity-avatar
                v-else-if="project.supplemental_data && project.supplemental_data.company_name"
                :name="project.supplemental_data.company_name"
                size="large"
            />
        </f-table-cell>
        <f-table-title-cell>
            {{ project.name }}

            <template #sub>
                <div class="project-overview-row__subtitle">
                    {{ project.office_id.ucfirst() }} office

                    <span v-if="clientFromId(project.client_id)">
                         <subtitle-spacer/>
                         {{ clientFromId(project.client_id).name }}
                    </span>
                </div>

                <project-dates-visualizer
                    :start-date="project.start_date"
                    :planning-start-date="project.planning_start_date"
                    :planning-end-date="project.planning_end_date"
                />

                <f-chip
                    v-if="project.is_archived"
                    variant="archived"
                    size="small"
                >
                    archived
                </f-chip>

                <f-chip
                    v-for="(tag, x) in project.supplemental_data.tags"
                    :key="x"
                    variant="info"
                    size="small"
                >
                    {{ tag }}
                </f-chip>

                <project-codes :project="project"/>
            </template>
        </f-table-title-cell>
        <f-table-cell align="center">
            <user-card-mini
                v-if="project.responsible_user_id && userById(project.responsible_user_id)"
                :user="userById(project.responsible_user_id)"
            />
        </f-table-cell>
        <f-table-cell align="right">
            <div class="project-overview-row__budget">
                <dashed-explanation tooltip="Project budget">
                    {{ formatPrice(project.budget) }}
                </dashed-explanation>
            </div>
            <div
                v-if="project.budget"
                :class="[
                    'project-overview-row__budget-result',
                    (project.result < 0 ? '--price-alert' : null),
                ]"
            >
                <dashed-explanation tooltip="Project result">
                    {{ formatPrice(project.result) }}
                </dashed-explanation>
            </div>
        </f-table-cell>
        <f-table-cell no-wrap align="right">
            <profitable-bar
                v-if="project.supplemental_data && project.supplemental_data.has_planning"
                :total="project.budget"
                :current="project.budget - project.result"
                width="20%"
                min-width="100px"
                percentage-location="left"
            />
            <span v-else class="project-overview-row__n-a">
                N/A
            </span>
        </f-table-cell>
        <f-table-hours-price-cell
            hide-mobile
            :hrs="(!project.hours_sold ? null : project.hours_sold)"
            :price="(!project.hours_sold_cost ? null : project.hours_sold_cost)"
        >
            <status-icon
                v-if="project.hours_allocated >= 0 && project.hours_sold === 0"
                type="info"
                :tooltip="`No hours found for number ${project.number} in Gripp`"
            />
        </f-table-hours-price-cell>
        <f-table-hours-price-cell
            hide-mobile
            :hrs="(!project.hours_allocated ? null : project.hours_allocated)"
            :price="(!project.hours_allocated_cost ? null : project.hours_allocated_cost)"
            :class-name="getOverBudgetClass(project.hours_sold, project.hours_allocated)"
        >
            <status-icon
                v-if="project.supplemental_data && !project.supplemental_data.has_planning && !project.supplemental_data.is_quote"
                type="warning"
                :tooltip="`No Harvest project found for number ${project.number}`"
            />
        </f-table-hours-price-cell>
    </f-table-row>
</template>

<script>
import FTableRow from "../../../../f-components/data/table/FTableRow";
import EntityAvatar from "../../../misc/entities/EntityAvatar";
import FTableCell from "../../../../f-components/data/table/FTableCell";
import FTableTitleCell from "../../../../f-components/data/table/FTableTitleCell";
import FChip from "../../../../f-components/data/chip/FChip";
import ProjectDatesVisualizer from "./ProjectDatesVisualizer";
import FTableHoursPriceCell from "../../../../f-components/data/table/FTableHoursPriceCell";
import ProfitableBar from "../../../misc/project-analysis/ProfitableBar";
import UserHelperMixin from "../../../../mixins/UserHelperMixin";
import ClientHelperMixin from "../../../../mixins/ClientHelperMixin";
import NumbersAndBudgetMixin from "../../../../mixins/NumbersAndBudgetMixin";
import ProjectsMixin from "../ProjectsMixin";
import ProjectCodes from './ProjectCodes';
import StatusIcon from '../../../misc/status-icon/StatusIcon';
import SubtitleSpacer from '../../../misc/SubtitleSpacer';
import DashedExplanation from '../../../misc/DashedExplanation';
import UserCardMini from '../../../cards/users/UserCardMini';

export default {
    name: 'project-overview-row',

    components: {
        UserCardMini,
        DashedExplanation,
        SubtitleSpacer,
        StatusIcon,
        ProjectCodes,
        ProfitableBar,
        FTableHoursPriceCell,
        ProjectDatesVisualizer,
        FChip,
        FTableTitleCell,
        FTableCell,
        EntityAvatar,
        FTableRow,
    },

    mixins: [
        UserHelperMixin,
        ClientHelperMixin,
        NumbersAndBudgetMixin,
        ProjectsMixin,
    ],

    props: {
        project: {
            type: Object,
            required: true,
        },
        selectable: Boolean,
        selected: Boolean,
    },

    computed: {
        actions() {
            return [{
                value: 'View',
                icon: 'magnifier',
                onClick: () => this.$router.push(`/projects/${this.project.id}`),
            }, {
                value: (this.favourites.includes(this.project.id) ? 'Remove as favorite' : 'Add as favorite'),
                icon: (this.favourites.includes(this.project.id) ? 'power-crossed' : 'power'),
                onClick: () => this.toggleFavourite(this.project.id),
            }];

            // if (this.hasPermission(Permissions.PROJECTS_ARCHIVE)) {
            //     actions.push({
            //         icon: 'archive',
            //         value: (!this.project.is_archived ? 'Archive' : 'Remove from archive'),
            //         onClick: () => this.setArchived(this.project.id, !this.project.is_archived),
            //     });
            // }
        },
    },
}
</script>

<style lang="scss">
.project-overview-row {
    &__n-a {
        font-size: 75%;
        opacity: .5;
    }

    .f-table-title-cell__subtitle .project-dates-visualizer {
        padding: 6px 0 2px 0;
    }

    .--price-alert {
        color: $dangerShade;
        opacity: 1;

        .dashed-explanation:hover {
            border-color: $dangerShade;
        }
    }

    .--price-warning {
        color: $warning;

        .dashed-explanation:hover {
            border-color: $warning;
        }
    }

    .project-codes {
        margin-top: 2px;
    }

    &__tags {
        display: inline-block;
    }

    .f-table-title-cell {
        .profitable-bar {
            margin-left: -2px;
        }
    }

    &__budget-result {
        opacity: .5;
        font-size: 90%;
        padding-top: 2px;
    }
}
</style>
