import ChildrenMixin from './ChildrenMixin';

/**
 * Remembers state for children with 'active' and 'enabled' data variable, and a string 'id'.
 */
export default {
    mixins: [
        ChildrenMixin,
    ],

    data() {
        return {
            hashTag: 'state',
        };
    },

    computed: {
        children() {
            return this.getChildren();
        },
    },

    props: {
        rememberState: Boolean,
    },

    mounted() {
        if (this.rememberState) {
            this.children.forEach(($child) => {
                $child.$on('active', () => this.rememberStateFor($child));
            });
        }
    },

    methods: {
        checkRememberState() {
            if (this.rememberState) {
                const rememberedId = this.hasRememberedState();
                let found = false;

                if (rememberedId) {
                    this.children.forEach(($child) => {
                        if ($child.id === rememberedId && !$child.disabled) {
                            $child.active = true;
                            found = true;
                        }
                    });
                }
                return found;
            }
            return false;
        },

        rememberStateFor($child) {
            location.hash = `${this.hashTag}-${$child.id}`;
        },

        hasRememberedState() {
            const regex = new RegExp(`#${this.hashTag}-(.*)`);
            const matches = location.hash.match(regex);

            if (!matches || matches.length !== 2) {
                return;
            }
            return matches[1];
        },
    },
};
