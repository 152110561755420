/**
 *
 */
export default {
    /**
     * Component data variables.
     */
    data() {
        return {
            childTags: [],
        };
    },

    /**
     * Component methods.
     */
    methods: {
        /**
         * @param {string[]=} tags
         * @param {any=} element
         * @return {[]}
         */
        getChildren(tags, element) {
            if (!element) element = this;
            if (!tags) tags = this.childTags;
            if (tags.length === 0) console.warn('No child tags provided');

            let validChildren = [];
            element.$children.forEach(($child) => {
                if ($child._isVue && (tags.length === 0 || tags.includes($child.$options._componentTag))) {
                    validChildren.push($child);
                }

                const subChildren = this.getChildren(tags, $child);
                validChildren = validChildren.concat(subChildren);
            });

            return validChildren;
        },
    },
};
