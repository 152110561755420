export default {
    computed: {
        officeOptions() {
            const options = [];

            this.$store.state.$officesCollection.forEach((office) => {
                options.push({
                    key: office.id,
                    value: office.name
                });
            });

            return options;
        },

        officeOptionsFlat() {
            const options = {};

            this.$store.state.$officesCollection.forEach((office) => {
                options[office.id] = office.name;
            });

            return options;
        },
    },
};
