var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'f-flex',
        ..._vm.classNames,
        (_vm.inline ? '--inline' : null),
    ],style:({
        display: (_vm.inline ? 'inline-flex' : 'flex'),
        flexDirection: _vm.direction,
        flexWrap: _vm.wrap,
        justifyContent: _vm.mainAxisAlign,
        alignItems: _vm.crossAxisAlign,
        gap: _vm.gap,
    })},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }