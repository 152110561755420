<template>
    <li
        :class="[
            'f-rating-star',
            (full ? '--full' : null),
            (readOnly ? '--read-only' : null),
        ]"
        v-html="(full ? '&#9733;' : '&#9734;')"
        @click="$emit('click')"
    />
</template>

<script>
export default {
    name: 'f-rating-star',

    props: {
        full: {
            type: Boolean,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss">
.f-rating-star {
    border: 0 !important;
    display: inline-block;
    padding: 1px;
    opacity: .5;
    font-size: 16px;

    &.--full {
        opacity: 1;
        color: $warning;
    }

    &:not(.--read-only) {
        cursor: pointer;
        transition: all .1s;

        &:hover {
            transform: scale(1.4);
        }
    }
}
</style>
