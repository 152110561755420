var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f-abstract-input',_vm._b({attrs:{"class-name":[
        'f-image-input',
        ..._vm.classNames,
        `--${_vm.type}`,
    ],"focussed":_vm.hasFocus,"input-error":_vm.inputError,"show-error":""},on:{"click":_vm.focus}},'f-abstract-input',_vm.boundProps,false),[_c('div',{staticClass:"f-image-input__container",style:({
            width: _vm.width,
            height: _vm.height,
            backgroundImage: _vm.backgroundImage
        }),on:{"dragover":_vm.onDragOver,"dragleave":_vm.onDragLeave,"drop":_vm.onDrop}},[(!_vm.readonly)?_c('f-icon',{attrs:{"icon":_vm.icon}}):_vm._e(),_c('input',{ref:"input",staticClass:"f-image-input__field",attrs:{"type":"file","name":_vm.name,"accept":(_vm.accept ? _vm.accept.join(',') : null)},on:{"change":_vm.onFileSelect,"focus":_vm.onFocus,"blur":_vm.onBlur}})],1),(_vm.resetable && _vm.backgroundImage)?_c('f-icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Reset'),expression:"'Reset'"}],attrs:{"class-name":"f-image-input__reset-icon","icon":"cross","size":"xsmall"},on:{"click":function($event){$event.stopPropagation();return _vm.reset.apply(null, arguments)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }