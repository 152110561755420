<template>
    <div :class="['f-input-addon']">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'f-input-addon',
}
</script>

<style lang="scss">
.f-input-addon {
    margin: 0 3px 0 15px;
    min-width: 13px;
    text-align: center;
    color: lighten(#222, 20);
    font-size: 15px;
}
</style>
