var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f-flex',{attrs:{"class-name":[
        'f-panel-header',
        ..._vm.classNames,
    ],"main-axis-align":"space-between"}},[(typeof _vm.heading === 'boolean')?_c('h2',{staticClass:"f-panel-header__title"},[_c('span',[_vm._t("title")],2)]):(_vm.heading)?_c('h2',{staticClass:"f-panel-header__title",on:{"click":_vm.onTitleClick}},[_c('span',{domProps:{"innerHTML":_vm._s(
           Array.isArray(_vm.heading) ?
               _vm.heading.join(` <span class='f-panel-header__title__spacer'>/</span> `) :
               (typeof _vm.heading === 'string' ? _vm.heading : '')
        )}}),_vm._t("title")],2):_vm._e(),_c('div',[_vm._t("default"),(_vm.headingBadge !== undefined)?_c('transition',{attrs:{"name":"fade","appear":""}},[_c('f-badge',{attrs:{"variant":"alternative","size":"large","inline":""}},[_vm._v(" "+_vm._s(_vm.headingBadge)+" ")])],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }