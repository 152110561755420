<template>
    <div :class="['birthday-or-anniversary-event', `--${type}`]">
        <f-text class-name="birthday-or-anniversary-event__title" tag="div">
            {{ type.ucfirst() }} alert
        </f-text>
        <div>
            <entity-avatar
                :name="user.name"
                :src="user.profile_image"
                :size="(breakpoints.mobile ? 'large' : 'xlarge')"
            >
                <f-icon :icon="(type === 'birthday' ? 'gift' : 'star')"/>
            </entity-avatar>
        </div>
        <div>
            <h3>{{ user.name }}</h3>
            <f-text class-name="birthday-or-anniversary-event__date" tag="div">
                {{ date }}
            </f-text>
        </div>
        <div>
            <f-button
                :value="`Happy ${yearsOrdinal} ${type}!`"
                variant="primary"
                :outlined="false"
                :size="(breakpoints.mobile ? 'xsmall' : 'small')"
                @click="onHappyClick"
            />
        </div>

        <sound-effect
            ref="soundConfetti"
            src="/sounds/confetti.m4a"
            :volume="0.1"
        />
        <sound-effect
            ref="soundExtra"
            :src="(type === 'birthday' ? '/sounds/party-horn.m4a' : '/sounds/champagne.m4a')"
            :volume="0.2"
        />
    </div>
</template>

<script>
import EntityAvatar from '../../entities/EntityAvatar';
import FButton from '../../../../f-components/form-controls/buttons/FButton';
import FText from '../../../../f-components/layout/text/FText';
import confetti from 'canvas-confetti';
import SoundEffect from '../../SoundEffect';
import FIcon from '../../../../f-components/layout/icons/FIcon';
import {ordinalSuffix} from '../../../../utils/helpers';
import BreakpointsMixin from '../../../../mixins/BreakpointsMixin';

export default {
    name: 'birthday-or-anniversary-event',

    mixins: [
        BreakpointsMixin,
    ],

    components: {
        FIcon,
        SoundEffect,
        FText,
        FButton,
        EntityAvatar,
    },

    props: {
        user: Object,
        date: String,
        type: String, // birthday | anniversary
        years: Number,
    },

    computed: {
        yearsOrdinal() {
            return this.years ? ordinalSuffix(this.years) : '';
        },
    },

    methods: {
        onHappyClick(e) {
            const coords = {
                x: (e.x / (window.innerWidth / 100)) / 100,
                y: (e.y / (window.innerHeight / 100)) / 100
            };

            // console.log(coords);
            
            confetti({
                origin: coords,
                shapes: [(this.type === 'birthday' ? 'square' : 'circle')],
                colors: (this.type === 'birthday' ? undefined : ['#d5a624', '#ae8e49', '#ffb900',]),
            });

            this.$refs.soundConfetti.play();

            window.setTimeout(() => {
                if (this.$refs.soundExtra) {
                    this.$refs.soundExtra.play();
                }
            }, 750);
        },
    },
}
</script>

<style lang="scss">
.birthday-or-anniversary-event {
    width: 170px;
    display: inline-flex;
    padding: 20px 10px;
    background: $softGray;
    border-radius: 6px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    text-align: center;

    @include media(mobile-down) {
        width: 150px;
    }

    &.--anniversary {
        .entity-avatar .f-icon {
            background-color: $warning;
        }
    }

    &__title {
        color: $ctaOrange;
    }

    &__date {
        color: $tertiaryGray;
        padding-bottom: 5px;
    }

    h3 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;

        @include media(mobile-down) {
            font-size: 14px;
        }
    }
}
</style>
