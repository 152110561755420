<template>
    <div
        :class="['picture-tag', (dropdownActive ? '--active' : null)]"
        :style="{
            left: `${tag.x / (imageWidth / 100)}%`,
            top: `${tag.y / (imageHeight / 100)}%`,
            width: `${tag.width / (imageWidth / 100)}%`,
            height: `${tag.height / (imageHeight / 100)}%`,
        }"
        @mouseenter="dropdownEnabled = true"
        @mouseleave="dropdownEnabled = false"
    >
        <f-user-dropdown
            v-if="dropdownEnabled || dropdownActive"
            v-model="userId"
            @popup-open="dropdownActive = true"
            @popup-close="dropdownActive = false"
            popup-variant="light"
        />
    </div>
</template>

<script>
import FUserDropdown from '../../../../f-components/form-controls/dropdowns/FUserDropdown';

export default {
    name: 'picture-tag',

    components: {
        FUserDropdown,
    },

    props: {
        tag: {
            type: Object,
            required: true,
        },
        imageWidth: {
            type: Number,
            required: true,
        },
        imageHeight: {
            type: Number,
            required: true,
        },
    },

    watch: {
        userId(id) {
            this.onUserPick(id);
        },
    },

    data() {
        return {
            userId: undefined,

            dropdownActive: false,
            dropdownEnabled: false,
            dropdownIsOpen: false,
        }
    },

    methods: {
        onUserPick(id) {
            console.log(id);
        },
    },
}
</script>

<style lang="scss">
.picture-tag {
    border-radius: 2px;
    position: absolute;
    border: 1px solid white;
    box-shadow: black 0 0 0 1px;
    opacity: .1;
    cursor: pointer;
    transition: opacity .2s;
    transform: scale(1.2);

    &:hover, &.--active {
        opacity: .75 !important;
    }

    .f-user-dropdown {
        height: calc(100% + 2px) !important;
        opacity: 0;
    }
}
</style>
