<template>
    <div class="push-notification-select --no-select">
        <div class="push-notification-select__content">
            {{
                subscribed ? 'Great, you are subscribed to push notifications!' : 'You are currently not subscribed to push notifications.'
            }}
        </div>
        <f-button
            size="small"
            @click="onClick"
            :variant="(subscribed ? 'danger' : 'primary')"
            :disabled="loading"
            show-loader-on-disabled
        >
            {{ subscribed ? 'Unsubscribe' : 'Subscribe' }}
        </f-button>
    </div>
</template>

<script>
import FButton from '../../../../../f-components/form-controls/buttons/FButton';

export default {
    name: 'push-notification-select-content',

    components: {
        FButton,
    },

    props: {
        subscribed: Boolean,
        loading: Boolean,
    },

    methods: {
        onClick() {
            this.$emit('toggle');
        },
    },
}
</script>

<style lang="scss">
.push-notification-select {
    text-align: center;
    padding: 10px 5px;
    white-space: normal;
    line-height: 120%;

    &__content {
        padding-bottom: 7px;
    }
}
</style>
