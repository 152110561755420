import Vue from 'vue';
import Vuex from 'vuex';
import JWTDecode from 'jwt-decode';
import APIDataStore from './APIDataStore';
import ErrorHandler from './ErrorHandler';
import EventBus from './EventBus';
import APIDataStoreResources from '../config/APIDataStoreResources';
import APIEndpoints from '../config/APIEndpoints';
import defaultState from '../config/defaultStoreState';
import axios from 'axios';

Vue.use(Vuex);

/**
 * @type {{VALID: string, EXPIRED: string}}
 */
export const TokenState = {
    EXPIRED: 'token-expired',
    VALID: 'token-valid',
};

// Get initial saved state
const savedState = JSON.parse(window.localStorage.getItem('riviera.app.state'));
if (savedState && savedState.accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${savedState.accessToken}`;
}

// Export Vue store
export default new Vuex.Store({
    state: (savedState ? {...defaultState, ...savedState} : defaultState),

    /**
     * Store mutations.
     */
    mutations: {
        // --- API DATA STORE $DATA --- //

        /**
         * @param {Object} state
         * @param {any} value
         */
        $user(state, value) {
            if (!value.login_type) {
                value.login_type = state.$user.login_type;
            }
            state.$user = value;
            setStateLocalStorage(state);
        },

        /**
         * @param {Object} state
         * @param {Array.<User>} value
         */
        $usersCollection(state, value) {
            state.$usersCollection = value;
            setStateLocalStorage(state);
        },

        /**
         * @param {Object} state
         * @param {any} value
         */
        $currentlyAtOffice(state, value) {
            state.$currentlyAtOffice = value;
            setStateLocalStorage(state);
        },

        /**
         * @param {Object} state
         * @param {any} value
         */
        $departmentsAndDisciplinesCollection(state, value) {
            state.$departmentsAndDisciplinesCollection = value;
            setStateLocalStorage(state);
        },

        /**
         * @deprecated
         * @param {Object} state
         * @param {any} value
         */
        $freelancersCollection(state, value) {
            state.$freelancersCollection = value;
            setStateLocalStorage(state);
        },

        /**
         * Set public holidays.
         *
         * @param {Object} state
         * @param {any} value
         */
        $publicHolidays(state, value) {
            state.$publicHolidays = value;
            setStateLocalStorage(state);
        },

        /**
         * Set available trafficers.
         *
         * @param {Object} state
         * @param {any} value
         */
        $availableTrafficers(state, value) {
            state.$availableTrafficers = value;
            setStateLocalStorage(state);
        },

        /**
         * @param {Object} state
         * @param {any} value
         */
        $officesCollection(state, value) {
            state.$officesCollection = value;
            setStateLocalStorage(state);
        },

        /**
         * @param {Object} state
         * @param {any} value
         */
        $leave(state, value) {
            state.$leave = value;
            setStateLocalStorage(state);
        },

        /**
         * @param {Object} state
         * @param {any} value
         */
        $adminLeaveRequests(state, value) {
            state.$adminLeaveRequests = value;
            setStateLocalStorage(state);
        },

        /**
         * @param {Object} state
         * @param {any} value
         */
        $projects(state, value) {
            state.$projects = value;
            setStateLocalStorage(state);
        },

        /**
         * @param {Object} state
         * @param {any} value
         */
        $absenteesToday(state, value) {
            state.$absenteesToday = value;
            setStateLocalStorage(state);
        },

        /**
         * @param {Object} state
         * @param {any} value
         */
        $projectFavourites(state, value) {
            state.$projectFavourites = value;
            setStateLocalStorage(state);
        },

        /**
         * @param {Object} state
         * @param {any} value
         */
        $clientsCollection(state, value) {
            state.$clientsCollection = value;
            setStateLocalStorage(state);
        },

        /**
         * @param {Object} state
         * @param {any} value
         */
        $notifications(state, value) {
            state.$notifications = value;
            setStateLocalStorage(state);
        },

        /**
         * @param {Object} state
         * @param {any} value
         */
        $calendar(state, value) {
            state.$calendar = value;
            setStateLocalStorage(state);
        },

        /**
         * Sets a meeting room's data (for today). Used for Meeting Room tablet???
         *
         * @param {Object} state
         * @param {Object} set
         * @param {Object} set.data
         */
        $meetingRoom(state, set) {
            state.$meetingRoom = set.data;
            setStateLocalStorage(state);
        },

        /**
         * Sets the meeting rooms data (per date).
         *
         * @param {Object} state
         * @param {Object} set
         * @param {Object} set.data
         * @param {Object} set.date
         */
        $meetingRoomsTodayAustin(state, set) {
            state.$meetingRoomsTodayAustin = set.data.data;
            setStateLocalStorage(state);
        },

        /**
         * Sets the meeting rooms data (per date).
         *
         * @param {Object} state
         * @param {Object} set
         * @param {Object} set.data
         * @param {Object} set.date
         */
        $meetingRoomsTodayAmsterdam(state, set) {
            state.$meetingRoomsTodayAmsterdam = set.data.data;
            setStateLocalStorage(state);
        },

        // --- NON-API STORE --- //

        /**
         * Adds a pending meeting.
         *
         * @param state
         * @param options
         */
        pendingMeeting(state, options) {
            if (state[`$meetingRooms${options.office.ucwords()}`][options.date]) {
                for (let k in state[`$meetingRooms${options.office.ucwords()}`][options.date]) {
                    if (state[`$meetingRooms${options.office.ucwords()}`][options.date][k].email_address === options.meetingRoom) {
                        state[`$meetingRooms${options.office.ucwords()}`][options.date][k].calendar.items.push(options.meeting);
                    }
                }
            }
        },

        /**
         * Set the time.
         *
         * @param state
         * @param value
         */
        time(state, value) {
            state.time = value;
            setStateLocalStorage(state);
        },

        /**
         * Set user name.
         *
         * @param state
         * @param value
         */
        userName(state, value) {
            state.$user.name = value;
            setStateLocalStorage(state);
        },

        /**
         * Set user email.
         *
         * @param state
         * @param value
         */
        userEmail(state, value) {
            state.$user.email = value;
            setStateLocalStorage(state);
        },

        /**
         * Set user settings.
         *
         * @param state
         * @param {Object} setting
         * @param {string} setting.key
         * @param {any} setting.value
         * @param {function} setting.onComplete
         */
        userSetting(state, setting) {
            state.$user.settings[setting.key] = setting.value;

            putUserSetting(setting);
            setStateLocalStorage(state);
        },

        /**
         * Set user access token
         *
         * @param state
         * @param value
         */
        accessToken(state, value) {
            state.accessToken = value;
            setStateLocalStorage(state);

            window.axios.defaults.headers.common['Authorization'] = `Bearer ${state.accessToken}`;
        },

        /**
         * Set user access token
         *
         * @param state
         */
        invalidateAccessToken(state) {
            state.accessToken = TokenState.EXPIRED;
            setStateLocalStorage(state);
            window.axios.defaults.headers.common['Authorization'] = null;
        },
    },

    /**
     * Store getters.
     */
    getters: {
        /**
         * Get state of current access token, returns: null | token-expired | token-valid
         */
        accessTokenState: state => {
            let tokenState = null;
            if (state.accessToken === TokenState.EXPIRED) {
                return TokenState.EXPIRED;
            }

            const token = (state.accessToken ? JWTDecode(state.accessToken) : null);
            const time = (new Date().getTime() / 1000);

            if (token) {
                tokenState = TokenState.EXPIRED;
            }
            if (token && token.exp > time) {
                tokenState = TokenState.VALID;
            }

            return tokenState;
        },

        /**
         * Get user setting.
         */
        getUserSetting: state => {
            return function (id, defaultValue = null) {
                if (!state.$user.settings || state.$user.settings[id] === undefined) {
                    return defaultValue;
                }

                return state.$user.settings[id];
            }
        },
    },

    /**
     * Store actions.
     */
    actions: {
        clearState() {
            this.commit('invalidateAccessToken');

            APIDataStore.removeCache();

            window.localStorage.removeItem('riviera.app.state');
            window.localStorage.removeItem('riviera.inline-messages');
            window.localStorage.removeItem('riviera.previous-filters');
            window.localStorage.removeItem('riviera.push-notification.show');
        },

        delete(store, $key) {
            if (store.state[$key]) {
                delete store.state[$key];
                setStateLocalStorage(store.state);
            }
        },

        notificationIsRead(store, $id) {
            for (let i in store.state.$notifications) {
                if (store.state.$notifications[i].id === $id) {
                    if (store.state.$notifications[i].is_read === 1 || store.state.$notifications[i].is_read === true) {
                        return;
                    }
                }
            }

            window.axios
                .get(APIEndpoints.parse(APIEndpoints.NOTIFICATION_READ, {id: $id}))
                .then(() => APIDataStore.fetch(APIDataStoreResources.NOTIFICATIONS))
                .catch(error => ErrorHandler.onGeneralError(error));
        },

        notificationAllRead(store) {
            for (let i in store.state.$notifications) {
                store.state.$notifications[i].is_read = true;
            }

            if (this.getters.accessTokenState !== TokenState.VALID) {
                return;
            }

            window.axios
                .get(APIEndpoints.NOTIFICATION_READ_ALL, {headers: {Authorization: `Bearer ${store.state.accessToken}`}})
                .then(() => APIDataStore.fetch(APIDataStoreResources.NOTIFICATIONS))
                .catch(error => ErrorHandler.onGeneralError(error));
        },
    }
});

/**
 * Update local with app state.
 *
 * @param {Object} state
 * @todo Isn't this suppose to be in setState method of store?
 */
const setStateLocalStorage = (state) => {
    window.localStorage.setItem('riviera.app.state', JSON.stringify(state));
};

const settingTimeouts = {};
/**
 * PUT request to backend / update user settings.
 *
 * @param {Object} setting
 * @param {string} setting.key
 * @param {any} setting.value
 * @param {function} setting.onComplete
 */
const putUserSetting = (setting) => {
    const settings = {};
    settings[setting.key] = setting.value;

    if (settingTimeouts[setting.key]) {
        window.clearTimeout(settingTimeouts[setting.key]);
    }

    settingTimeouts[setting.key] = window.setTimeout(() => {
        window.axios
            .put(APIEndpoints.USER_SETTINGS, {settings})
            .then(() => {
                EventBus.$emit(`user.settings[${setting.key}].update`);
                if (setting.onComplete) setting.onComplete();
            })
            .catch(error => ErrorHandler.onGeneralFatalError(error.response));
    }, 1000);
};
