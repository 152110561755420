<template>
    <div :class="[className, 'f-filter-item', (disabled ? '--disabled' : null)]">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'f-filter-item',

    /**
     * Component properties.
     */
    props: {
        className: String,
        disabled: Boolean,
    },
}
</script>

<style lang="scss">
.f-filter-item {
    &.--disabled {
        opacity: .25;
        pointer-events: none;
    }

    .f-radio-button-list {
        display: inline-block;
    }
}
</style>
