export const BadgeVariants = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    DANGER: 'danger',
    LIGHT: 'light',
    DARK: 'dark',

    DEFAULT: 'default',
    ERROR: 'error',
    ALTERNATIVE_INVERTED: 'alternative-inverted',
    ALTERNATIVE: 'alternative',
}

export const BadgeSizes = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
}
