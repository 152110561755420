var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'f-tab',
        (_vm.active ? '--active' : null),
        (_vm.disabled ? '--disabled' : null),
        (_vm.subtitle ? '--subtitle' : null),
        ..._vm.classNames,
    ],on:{"click":_vm.onClick}},[_vm._t("default"),(_vm.title)?_c('div',{staticClass:"f-tab__title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.title)}}),(_vm.badge)?_c('f-badge',{attrs:{"inline":""}},[_vm._v(" "+_vm._s(_vm.badge)+" ")]):_vm._e(),(_vm.subtitle)?_c('div',{staticClass:"f-tab__subtitle",domProps:{"innerHTML":_vm._s(_vm.subtitle)}}):_vm._e()],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }