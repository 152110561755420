<template>
    <span
        :class="[
            'f-icon',
            `f-icon-${icon}`,
            (crossedOut ? '--crossed-out' : null),
            ...classNames,
        ]"
    >
        <slot/>
    </span>
</template>

<script>
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-icon',

    mixins: [
        ClassNameMixin,
    ],

    props: {
        icon: {
            type: String,
            required: true,
        },
        crossedOut: Boolean,
    },
}
</script>

<style lang="scss">
.f-icon {
    position: relative;
    text-align: center;

    &.--crossed-out {
        &:after {
            content: '';
            transform: rotate(-45deg);
            position: absolute;
            background-color: white;
            height: 1px;
            width: 120%;
            top: 50%;
            left: -3px;
        }
    }

    &.rotate-90 {
        &::before {
            transform: rotate(90deg);
            display: inline-block;
        }

        &.--crossed-out {
            &:after {
                left: -1px;
            }
        }
    }
}
</style>
