<template>
    <section
        v-if="isEnabled"
        :class="[
            'widget',
            '--no-select',
            `--theme--${theme}`,
            widgetSize,
            ...classNames,
        ]"
    >
        <f-panel
            :title="title"
            :title-help="titleHelp"
            :heading="header"
            :content-class-name="contentClassName"
            :heading-badge="badge"
        >
            <template #post-title>
                <slot name="post-title"/>
            </template>

            <template #head>
                <!--<widget-drag/>-->
                <slot name="head"/>
            </template>

            <template #post>
                <slot name="header-post"/>
            </template>

            <slot/>
        </f-panel>
    </section>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import FPanel from '../../f-components/other/panel/FPanel';
import WidgetDrag from './WidgetDrag';
import WidgetSizeMixin from './WidgetSizeMixin';
import {ThemeVariants} from '../../f-components/layout/theme/config';
import ClassNameMixin from '../../mixins/ClassNameMixin';

export default {
    name: 'widget',

    mixins: [
        WidgetSizeMixin,
        ClassNameMixin,
    ],

    components: {
        WidgetDrag,
        FPanel,
    },

    props: {
        theme: {
            type: String,
            default: ThemeVariants.LIGHT,
        },
        title: String | Array,
        titleHelp: String,
        header: String | Array,
        contentClassName: String,
        badge: String | Number,
        enabledSetting: String,
    },

    computed: {
        isEnabled() {
            if (this.enabledSetting) {
                return this.$store.getters.getUserSetting(this.enabledSetting);
            }
            return true;
        },
    },
}
</script>

<style lang="scss">
.widget {
    font-size: 13px;
    padding-bottom: 50px;
    display: inline-block;
    vertical-align: top;
    width: 100%;

    @include media(mobile-down) {
        padding-bottom: 25px;
    }

    &:hover {
        .widget-drag {
            opacity: .25;
        }
    }

    &.--widget-xmedium, &.--widget-medium, &.--widget-small {
        .--widget-hide-small {
            display: none;
        }
    }
}
</style>
