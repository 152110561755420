var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.active && _vm.user)?_c('f-portal',{attrs:{"reference":"user-card-popup"}},[_c('transition',{attrs:{"name":"popup","appear":""}},[_c('div',{ref:"positionElement",class:[
                'user-card-popup',
                `--${_vm.currentAlign}`,
                `--hor-${_vm.currentAlignHorizontal}`,
                _vm.options.className,
                `--${_vm.options.variant}`,
            ],style:({
                top: `${_vm.pos.top}px`,
                left: `${_vm.pos.left}px`
            })},[_c('user-card',{attrs:{"user":_vm.user,"variant":_vm.options.variant,"popup-class-name":"user-card-popup__popup"}})],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }