<template>
    <f-form
        class-name="change-discipline-form"
        :loading="loading"
        @submit="submit"
    >
        <f-form-row label="Employee">
            <user-card-mini
                :user="user"
                variant="primary"
            />
        </f-form-row>

        <f-form-row label="From">
            <f-discipline-dropdown
                v-model="user.discipline_id"
                readonly
            />
        </f-form-row>

        <f-form-row label="To">
            <f-discipline-dropdown
                v-model="disciplineID"
                popup-variant="light"
            />
        </f-form-row>

        <f-form-row-spacer/>

        <template #footer>
            <f-button
                value="Change"
                type="submit"
                icon="floppy-disk"
                variant="primary"
                :disabled="(disciplineID === user.discipline_id) || loading"
                :show-loader-on-disabled="loading"
            />
        </template>
    </f-form>
</template>

<script>
import UserCardMini from "../../cards/users/UserCardMini";
import FForm from "../../../f-components/form-controls/forms/FForm";
import FFormRow from "../../../f-components/form-controls/forms/FFormRow";
import FDisciplineDropdown from "../../../f-components/form-controls/dropdowns/FDisciplineDropdown";
import FFormRowSpacer from "../../../f-components/form-controls/forms/FFormRowSpacer";
import FButton from "../../../f-components/form-controls/buttons/FButton";
import APIEndpoints from "../../../config/APIEndpoints";
import LoadingMixin from '../../../mixins/LoadingMixin';

export default {
    name: 'change-discipline-form',

    mixins: [
        LoadingMixin,
    ],

    components: {
        FButton,
        FFormRowSpacer,
        FDisciplineDropdown,
        FFormRow,
        FForm,
        UserCardMini,
    },

    props: {
        user: Object,
        projectId: {
            type: Number,
            required: true,
        },
        currentConfiguration: {
            type: Array,
            required: true,
        },
    },

    mounted() {
        this.disciplineID = null;
        for (let i in this.currentConfiguration) {
            if (this.currentConfiguration[i].user_id === this.user.id && this.currentConfiguration[i].user_instance === this.user.instance) {
                this.disciplineID = this.currentConfiguration[i].discipline_id;
            }
        }
    },

    data() {
        return {
            disciplineID: null,
        }
    },

    methods: {
        submit() {
            this.startLoading();

            let newConfiguration = [...this.currentConfiguration];

            // Add to array or change existing
            if (this.disciplineID) {
                const userConfig = {
                    user_id: this.user.id,
                    user_instance: this.user.instance,
                    discipline_id: this.disciplineID,
                };

                let found = false;
                for (let i in newConfiguration) {
                    if (newConfiguration[i].user_id === this.user.id && newConfiguration[i].user_instance === this.user.instance) {
                        found = true;
                        newConfiguration[i] = userConfig;
                    }
                }
                if (!found) {
                    newConfiguration.push(userConfig);
                }
            }
            // Else remove from array if (back) empty
            else {
                for (let i in newConfiguration) {
                    if (newConfiguration[i].user_id === this.user.id && newConfiguration[i].user_instance === this.user.instance) {
                        newConfiguration.splice(i, 1);
                    }
                }
            }

            // Send
            window.axios.put(`${APIEndpoints.PROJECTS}/${this.projectId}`, {
                supplemental_data: {
                    disciplines_configuration: newConfiguration,
                },
            })
                .then(() => {
                    this.endLoading();
                    this.$emit('done');
                })
                .catch(() => this.endLoading());
        }
    },
}
</script>

<style lang="scss">
.change-discipline-form {
    text-align: left;

    .user-card-mini {
        margin-top: 8px;
    }
}
</style>
