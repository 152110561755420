<template>
    <f-abstract-input
        :class-name="['f-checkbox-list', `--${direction}`]"
        :input-error="inputError"
        show-error
    >
        <slot/>
        <slot name="after"/>
    </f-abstract-input>
</template>

<script>
import ChildrenMixin from '../../../mixins/ChildrenMixin';
import AbstractInputValidationMixin from '../base/AbstractInputValidationMixin';
import FAbstractInput from '../base/FAbstractInput';

export default {
    name: 'f-checkbox-list',

    components: {
        FAbstractInput,
    },

    mixins: [
        ChildrenMixin,
        AbstractInputValidationMixin,
    ],

    props: {
        direction: {
            type: String,
            default: 'horizontal',
        },
        value: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            childTags: [
                'f-checkbox',
            ],
        }
    },

    mounted() {
        this.getChildren().forEach(($child) => {
            if (this.value.includes($child.value)) {
                $child.check();
            } else {
                $child.uncheck();
            }

            $child.$on('change', this.onChange);
        });
    },

    methods: {
        getValue() {
            return this.getValues();
        },

        getValues() {
            let values = [];

            this.getChildren().forEach(($child) => {
                if ($child.isChecked() && !$child.disabled) {
                    values.push($child.value);
                }
            });

            return values;
        },

        onChange() {
            this.$emit('input', this.getValues());
            this.$emit('change', this.getValues());
        },
    },
}
</script>

<style lang="scss">
.f-checkbox-list {
    display: inline-block;
    position: relative;

    .f-checkbox {
        margin-bottom: 5px;
    }

    &.--vertical {
        .f-checkbox {
            display: flex;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.--horizontal {
        .f-checkbox {
            .f-checkbox-visual-label {
                padding-right: 15px;

            }

            &:last-child .f-checkbox-visual-label {
                padding-right: 0;
            }
        }
    }
}
</style>
