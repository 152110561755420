import {ObserverProvider} from '../utils/ObserverProvider';

export default {
    props: {
        observer: ObserverProvider,
    },

    mounted() {
        if (this.observer) {
            this.observer.observe(this.$el, () => this.$emit('observe'));
        }
    },

    beforeDestroy() {
        if (this.observer) {
            this.observer.unobserve(this.$el);
        }
    }
};
