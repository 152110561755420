import Router from 'vue-router';
import AuthenticationPage from './components/pages/authentication/AuthenticationPage';
import DashboardPage from './components/pages/dashboard/DashboardPage';
import FAQPage from './components/pages/faq/FAQPage';
import MeetingRoomsPage from './components/pages/meeting-rooms/MeetingRoomsPage';
import LeaveRequestsAdminPage from './components/pages/leave-requests-admin/LeaveRequestsAdminPage';
import FreelancersDatabasePage from './components/pages/freelancer-database/FreelancersDatabasePage';
import PeoplePage from './components/pages/people/PeoplePage';
import AuthenticationRedirectPage from './components/pages/authentication/AuthenticationRedirectPage';
import NotFoundPage from "./components/pages/NotFoundPage";
import ProjectsPage from "./components/pages/projects/ProjectsPage";
import MeetingRoomTabletPage from "./components/pages/meeting-room-tablet/MeetingRoomTabletPage";
import AdminJamfSyncPage from './components/pages/admin/users/syncs/AdminJamfSyncPage';
import AdminUsersPage from './components/pages/admin/users/AdminUsersPage';
import AdminUserShowPage from './components/pages/admin/users/AdminUserShowPage';
import AdminUserEditPage from './components/pages/admin/users/AdminUserEditPage';
import AdminClientsPage from './components/pages/admin/clients/AdminClientsPage';
import AdminClientShowPage from './components/pages/admin/clients/AdminClientShowPage';
import AdminClientEditPage from "./components/pages/admin/clients/AdminClientEditPage";
import ProjectShowPage from './components/pages/projects/ProjectShowPage';
import AdminApiLogsPage from './components/pages/admin/api-logs/AdminApiLogsPage';
import AdminApiLogsShowPage from './components/pages/admin/api-logs/AdminApiLogsShowPage';
import AdminActiveOfficeClientsPage from './components/pages/admin/active-office-clients/AdminActiveOfficeClientsPage';
import AdminGalleryPage from './components/pages/admin/galleries/AdminGalleryPage';
import AdminGalleryEditCreatePage from './components/pages/admin/galleries/AdminGalleryEditCreatePage';
import AdminAnnouncementsPage from './components/pages/admin/announcements/AdminAnnouncementsPage';
import AdminAnnouncementEditCreatePage from './components/pages/admin/announcements/AdminAnnouncementEditCreatePage';
import HRPage from './components/pages/hr/HRPage';
import ScreensaverPage from './components/pages/meeting-room-tablet/ScreensaverPage';
import AdminFikaPage from './components/pages/admin/fika/AdminFikaPage';
import AdminFikaEditCreatePage from './components/pages/admin/fika/AdminFikaEditCreatePage';
import OfficeMapAmsterdamPage from './components/pages/amsterdam-office-map/OfficeMapAmsterdamPage';
import AdminPagesPage from './components/pages/admin/pages/AdminPagesPage.vue';
import AdminPagesEditCreatePage from './components/pages/admin/pages/AdminPagesEditCreatePage.vue';
import DynamicPage from './components/pages/pages/DynamicPage.vue';

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/auth',
            name: 'auth',
            component: AuthenticationPage,
        },
        {
            path: '/auth/gsuite',
            name: 'auth-gsuite',
            component: AuthenticationRedirectPage,
        },
        {
            path: '/',
            name: 'dashboard',
            component: DashboardPage,
        },
        {
            path: '/people',
            name: 'people',
            component: PeoplePage,
        },
        // {
        //     path: '/org',
        //     name: 'org',
        //     component: OrganizationChartPage,
        // },
        {
            path: '/hr',
            name: 'hr',
            component: HRPage,
        },
        {
            path: '/office-map/amsterdam',
            name: 'offices',
            component: OfficeMapAmsterdamPage,
        },
        {
            path: '/meeting-rooms/:office',
            name: 'meeting-rooms',
            component: MeetingRoomsPage,
        },
        {
            path: '/meeting-room-screensaver/:number',
            name: 'meeting-room-screensaver',
            component: ScreensaverPage,
        },
        {
            path: '/meeting-room/:id',
            name: 'meeting-room',
            component: MeetingRoomTabletPage,
        },
        {
            path: '/faq',
            name: 'faq',
            component: FAQPage,
        },
        {
            path: '/leave-requests',
            name: 'leave-requests',
            component: LeaveRequestsAdminPage,
        },
        {
            path: '/pages/:slug',
            name: 'page',
            component: DynamicPage,
        },
        {
            path: '/projects',
            name: 'projects',
            component: ProjectsPage,
        },
        {
            path: '/projects/:id',
            name: 'projects.show',
            component: ProjectShowPage,
        },
        // {
        //     path: '/spinning-wheel',
        //     name: 'spinning-wheel',
        //     component: SpinningWheelPage,
        // },
        // {
        //     path: '/memory',
        //     name: 'memory',
        //     component: MemoryPage,
        // },
        // {
        //     path: '/compliment',
        //     name: 'compliment',
        //     component: ComplimentPage,
        // },
        // {
        //     path: '/coffee',
        //     name: 'coffee',
        //     component: CoffeePage,
        // },
        {
            path: '/freelancers',
            name: 'freelancers',
            component: FreelancersDatabasePage,
        },
        // {
        //     path: '/freelancers/create',
        //     name: 'freelancers.create',
        //     component: FreelancerDatabaseEditCreatePage,
        // },
        // {
        //     path: '/freelancers/edit/:id',
        //     name: 'freelancers.edit',
        //     component: FreelancerDatabaseEditCreatePage,
        // },
        // {
        //     path: '/freelancers/:id',
        //     name: 'freelancers.show',
        //     component: FreelancerDatabaseShowPage,
        // },

        // -- Admin

        {
            path: '/admin/jamf-sync',
            name: 'admin.jamf-sync',
            component: AdminJamfSyncPage,
        },
        // {
        //     path: '/admin/bcs-sync',
        //     name: 'admin.bcs-sync',
        //     component: AdminBcsSyncPage,
        // },
        // {
        //     path: '/admin/forecast-sync',
        //     name: 'admin.forecast-sync',
        //     component: AdminForecastSyncPage,
        // },
        {
            path: '/admin/users',
            name: 'admin.users',
            component: AdminUsersPage,
        },
        {
            path: '/admin/users/:id',
            name: 'admin.users.show',
            component: AdminUserShowPage,
        },
        {
            path: '/admin/users/edit/:id',
            name: 'admin.users.edit',
            component: AdminUserEditPage,
        },
        // {
        //     path: '/admin/newsletters',
        //     name: 'newsletter.admin',
        //     component: AdminNewslettersPage,
        // },
        {
            path: '/admin/announcements',
            name: 'admin.announcements',
            component: AdminAnnouncementsPage,
        },
        {
            path: '/admin/announcements/create',
            name: 'admin.announcements.create',
            component: AdminAnnouncementEditCreatePage,
        },
        {
            path: '/admin/announcements/edit/:id',
            name: 'admin.announcements.edit',
            component: AdminAnnouncementEditCreatePage,
        },
        {
            path: '/admin/fika',
            name: 'admin.fika',
            component: AdminFikaPage,
        },
        {
            path: '/admin/fika/create',
            name: 'admin.fika.create',
            component: AdminFikaEditCreatePage,
        },
        {
            path: '/admin/fika/edit/:id',
            name: 'admin.fika.edit',
            component: AdminFikaEditCreatePage,
        },
        {
            path: '/admin/galleries',
            name: 'admin.galleries',
            component: AdminGalleryPage,
        },
        {
            path: '/admin/galleries/create',
            name: 'admin.galleries.create',
            component: AdminGalleryEditCreatePage,
        },
        {
            path: '/admin/galleries/edit/:id',
            name: 'admin.galleries.edit',
            component: AdminGalleryEditCreatePage,
        },
        {
            path: '/admin/pages',
            name: 'admin.pages',
            component: AdminPagesPage,
        },
        {
            path: '/admin/pages/create',
            name: 'admin.pages.create',
            component: AdminPagesEditCreatePage,
        },
        {
            path: '/admin/pages/edit/:id',
            name: 'admin.pages.edit',
            component: AdminPagesEditCreatePage,
        },
        {
            path: '/admin/clients',
            name: 'admin.clients',
            component: AdminClientsPage,
        },
        {
            path: '/admin/clients/:id',
            name: 'admin.clients.show',
            component: AdminClientShowPage,
        },
        {
            path: '/admin/clients/edit/:id',
            name: 'admin.clients.edit',
            component: AdminClientEditPage,
        },
        // {
        //     path: '/admin/products',
        //     name: 'admin.products',
        //     component: AdminProductsPage,
        // },
        // {
        //     path: '/admin/disciplines',
        //     name: 'admin.disciplines',
        //     component: AdminDisciplinesPage,
        // },
        {
            path: '/admin/api-logs',
            name: 'admin.api-logs',
            component: AdminApiLogsPage,
        },
        {
            path: '/admin/api-logs/:id',
            name: 'admin.api-logs.show',
            component: AdminApiLogsShowPage,
        },
        {
            path: '/admin/active-office-clients',
            name: 'admin.active-office-clients',
            component: AdminActiveOfficeClientsPage,
        },

        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            component: NotFoundPage,
        },
    ],
});
