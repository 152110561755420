<template>
    <f-portal
        reference="input-error"
        :style="{'zIndex': parentInPortal ? 103 : null}"
    >
        <transition name="popup" appear>
            <div
                :class="[
                    'f-input-error',
                    '--no-select',
                    `--${currentAlign}`,
                    `--hor-${currentAlignHorizontal}`,
                ]"
                :style="{
                    top: `${pos.top}px`,
                    left: `${pos.left}px`
                }"
                ref="positionElement"
            >
                <div class="f-input-error__content">
                    {{ error }}
                </div>
            </div>
        </transition>
    </f-portal>
</template>

<script>
import FPortal from '../../other/portal/FPortal';
import PositionPropsMixin from '../../../mixins/position/PositionPropsMixin';
import PositionMixin from '../../../mixins/position/PositionMixin';

export default {
    name: 'f-input-error',

    components: {
        FPortal,
    },

    mixins: [
        PositionPropsMixin,
        PositionMixin,
    ],

    props: {
        error: String,
        target: {
            type: HTMLElement,
            required: true,
        },
        margin: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            parentInPortal: !!this.$parent.$el.closest("#f-portal-container"),
        };
    },
}
</script>

<style lang="scss">
.f-input-error {
    position: absolute;
    z-index: $inputErrorOrder;
    pointer-events: none;
    background: $danger;
    color: $light;
    padding: 6px 10px 7px;
    font-size: 13px;
    border-radius: 5px;
    text-align: left;
    line-height: 110%;
    white-space: nowrap;
    box-shadow: rgba($dark, .2) 0 0 10px;

    @include arrow-pointer($danger);
}
</style>
