<template>
    <page
        v-if="hasPermission(Permissions.ADMIN_CLIENTS_EDIT)"
        class-name="admin-client-edit-page"
        :loading="loading && !dataLoaded"
        :title="['Admin', 'Clients', 'Edit']"
        header="Edit client"
        pre-max-width="800px"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb link="/admin/clients">Clients</f-breadcrumb>
                <f-breadcrumb
                    :loading="!dataLoaded"
                    :link="`/admin/clients/${$route.params.id}`"
                >
                    {{ fields ? fields.name : '' }}
                </f-breadcrumb>
                <f-breadcrumb>Edit</f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-form
            enctype="multipart/form-data"
            @submitValid="submit"
            validate
        >
            <f-container max-width="800px">
                <div v-if="dataLoaded">
                    <f-form-row label="Image">
                        <f-image-input
                            ref="imageUploadField"
                            name="image"
                            :src="fetchedData.data.image_url"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Name">
                        <f-text-input
                            name="name"
                            v-model="fields.name"
                            max-width="300px"
                            readonly
                        />
                    </f-form-row>

                    <f-form-row label="Office">
                        <f-dropdown
                            v-model="fields.office_id"
                            :options="officeOptions"
                            readonly
                            popup-width="120px"
                            name="office"
                            width="250px"
                            pre-icon="apartment"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Account director">
                        <f-user-dropdown
                            v-model="fields.account_director_id"
                            max-width="300px"
                            pre-icon="user"
                        />
                    </f-form-row>
                </div>

                <f-form-placeholder v-else-if="!dataLoaded && loading"/>

                <entity-action-button
                    v-if="dataLoaded"
                    icon="floppy-disk"
                    tooltip="Update"
                    type="submit"
                />
            </f-container>
        </f-form>
    </page>
</template>

<script>
import APIEndpoints from '../../../../config/APIEndpoints';
import OfficeOptionsMixin from '../../../../mixins/OfficeOptionsMixin';
import FForm from '../../../../f-components/form-controls/forms/FForm';
import FFormRow from '../../../../f-components/form-controls/forms/FFormRow';
import FImageInput from '../../../../f-components/form-controls/images/FImageInput';
import Page from '../../Page';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import EntityCreateEditMixin from "../../../../mixins/entities/EntityCreateEditMixin";
import FDropdown from "../../../../f-components/form-controls/dropdowns/FDropdown";
import FFormRowSpacer from "../../../../f-components/form-controls/forms/FFormRowSpacer";
import FTextInput from "../../../../f-components/form-controls/text/FTextInput";
import FUserDropdown from "../../../../f-components/form-controls/dropdowns/FUserDropdown";
import FFormPlaceholder from '../../../../f-components/loaders/placeholder/FFormPlaceholder';
import FContainer from '../../../../f-components/layout/container/FContainer';
import EntityActionButton from '../../../misc/entities/EntityActionButton';

export default {
    name: 'admin-client-edit-page',

    components: {
        EntityActionButton,
        FContainer,
        FFormPlaceholder,
        FUserDropdown,
        FTextInput,
        FFormRowSpacer,
        FDropdown,
        FBreadcrumbs,
        FBreadcrumb,
        Page,
        FImageInput,
        FFormRow,
        FForm,
    },

    mixins: [
        OfficeOptionsMixin,
        EntityCreateEditMixin,
    ],

    data() {
        return {
            entityName: 'client',
            fields: {
                name: null,
                office_id: null,
                account_director_id: null,
            },
        }
    },

    methods: {
        getBaseUrl() {
            return APIEndpoints.ADMIN_CLIENTS;
        },

        getRedirectPath() {
            return '/admin/clients';
        },

        configureFormData(formData) {
            const file = this.$refs.imageUploadField.getFile();
            if (file) {
                formData.set('image_file', file);
            }

            return formData;
        },
    }
}
</script>
