<template>
    <f-abstract-input
        v-bind="boundProps"
        :class-name="[
            'f-checkbox',
            ...classNames,
        ]"
        :focussed="hasFocus"
        :input-error="inputError"
        show-error
    >
        <div class="f-checkbox__container">
            <input
                type="checkbox"
                ref="input"
                :id="uid"
                :name="uid"
                :disabled="disabled"
                :readonly="readonly"
                :tabindex="tabIndex"
                :checked="value"
                @change="onChange"
                @focus="onFocus"
                @blur="onBlur"
            />
            <label
                :for="uid"
                class="f-checkbox__label"
            />
        </div>
        <label
            v-if="label"
            :for="uid"
            class="f-checkbox__visual-label"
        >
            <f-icon
                v-if="labelIcon"
                :icon="labelIcon"
            />
            {{ label }}
            <slot/>
        </label>

        <slot name="after"/>
    </f-abstract-input>
</template>

<script>
import FIcon from '../../layout/icons/FIcon';
import FAbstractInput from '../base/FAbstractInput';
import AbstractInputPropsMixin from '../base/AbstractInputPropsMixin';
import AbstractInputValidationMixin from '../base/AbstractInputValidationMixin';
import AbstractInputEventsMixin from '../base/AbstractInputEventsMixin';
import AbstractInputMixin from '../base/AbstractInputMixin';

export default {
    name: 'f-checkbox',

    components: {
        FAbstractInput,
        FIcon,
    },

    mixins: [
        AbstractInputPropsMixin,
        AbstractInputValidationMixin,
        AbstractInputEventsMixin,
        AbstractInputMixin,
    ],

    props: {
        label: String,
        value: {
            default: false,
        },
        labelIcon: String,
    },

    methods: {
        onChange() {
            this.$emit('input', this.$refs.input.checked);
            this.$emit('change', this.$refs.input.checked);
        },

        getValue() {
            return this.isChecked();
        },

        check() {
            if (!this.isChecked()) {
                this.toggle();
            }
        },

        uncheck() {
            if (this.isChecked()) {
                this.toggle();
            }
        },

        toggle() {
            this.$refs.input.click();
        },

        isChecked() {
            return this.$refs.input.checked;
        },

        getExcludedProps() {
            return [
                'label',
                'labelIcon',
                'validation',
            ];
        },
    }
}
</script>

<style lang="scss">
$radius: 20px;

.f-checkbox {
    position: relative;
    vertical-align: middle;
    display: inline-flex;

    input {
        opacity: 0;
        position: absolute;
        pointer-events: none;
    }

    &__label {
        width: $radius;
        height: $radius;
        border-radius: 3px;
        border: 1px solid;
        transition: border-color .1s;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
        left: 0;
        top: 0;
        text-align: left;

        &:before {
            position: absolute;
            width: $radius - 2px;
            height: $radius - 1px;
            text-align: center;
            font-size: 13px;
            transition: all .1s ease-in-out;
            line-height: 18px;
        }
    }

    // Checked
    input:checked + .f-checkbox__label {
        font-family: 'Linearicons';
        font-weight: bold;

        &::before {
            content: "\EC34";
        }
    }

    // Disabled & read only
    &.--disabled {
        opacity: .5;
        pointer-events: none;

        .f-checkbox__label {
            background: rgba($light, .02);
        }
    }

    &.--readonly {
        opacity: 1;
        pointer-events: none;

        .f-checkbox__label {
            border: 0;
        }

        input:not(:checked) + .f-checkbox__label {
            font-family: 'Linearicons';
            font-weight: bold;
            opacity: .5;

            &::before {
                content: "\ec2a";
            }
        }
    }

    // Visual label
    &__visual-label {
        display: inline-block;
        vertical-align: middle;
        padding: 1px 8px 0;
        line-height: 130%;
        margin: 0;

        .f-icon {
            margin-right: 3px;
            opacity: .5;
        }
    }

    // -- Variants

    @include theme(dark) {
        color: $light;

        &.--focus .f-checkbox__label {
            box-shadow: 0 0 0 2px rgba($light, .2);
        }

        &.--disabled /*, &.--readonly .f-checkbox__label*/
        {
            background: rgba($light, .02);
        }

        input:checked + .f-checkbox__label {
            &::before {
                color: $primary;
            }

            &:hover::before {
                color: $primary;
            }
        }

        .f-checkbox__label {
            border-color: rgba($light, .5);
            background: rgba($dark, .1);

            &:before {
                color: $light;
            }

            &:hover {
                border-color: $light;

                &:before {
                    color: $light;
                }
            }
        }
    }

    @include theme(light) {
        color: $dark;

        &.--focus .f-checkbox__label {
            box-shadow: 0 0 0 2px rgba($dark, .2);
        }

        &.--disabled /*, &.--readonly .f-checkbox__label*/
        {
            background: rgba($dark, .02);
        }

        input:checked + .f-checkbox__label {
            &::before {
                color: $primary;
            }

            &:hover::before {
                color: $primary;
            }
        }

        .f-checkbox__label {
            border-color: rgba($dark, .5);
            background: rgba($dark, .025);

            &:before {
                color: $dark;
            }

            &:hover {
                border-color: $dark;

                &:before {
                    color: $dark;
                }
            }
        }
    }

    &.--error {
        color: $danger;

        &.--focus .f-checkbox__label {
            box-shadow: 0 0 0 2px rgba($danger, .2);
        }

        &.--disabled /*, &.--readonly .f-checkbox__label*/
        {
            background: rgba($danger, .02);
        }

        input:checked + .f-checkbox__label {
            &::before {
                color: $danger;
            }

            &:hover::before {
                color: $danger;
            }
        }

        .f-checkbox__label {
            border-color: rgba($danger, .5);
            background: rgba($light, .1);
            transition: border-color .1s;

            &:before {
                color: $danger;
            }

            &:hover {
                border-color: $danger;

                &:before {
                    color: $danger;
                }
            }
        }
    }
}
</style>
