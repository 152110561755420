<template>
    <div :class="['allocations-list-days-row']">
        <div
            v-for="(date, i) in dates"
            :key="i"
            class="allocations-list-days-row__date"
        >
            <div
                v-for="assignment in date.assignments"
                :class="[
                    'allocations-list-days-row__assignment',
                    '--no-select',
                    (assignment.notes ? '--notes' : null),
                    (assignment.is_excluded ? '--excluded' : null)
                ]"
                v-tooltip="{
                    text: assignment.notes,
                    wrap: true,
                    variant: 'info'
                }"
                :key="assignment.id"
            >
                {{ formatNumber(assignment.allocation) }} {{ assignment.allocation === 1 ? 'hr' : 'hrs' }}
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import NumbersAndBudgetMixin from '../../../../mixins/NumbersAndBudgetMixin';

export default {
    name: 'allocations-list-days-row',

    mixins: [
        NumbersAndBudgetMixin,
    ],

    props: {
        assignments: {
            type: Array,
            required: true,
        },
        startDate: {
            type: Object,
            required: true,
        },
        endDate: {
            type: Object,
            required: true,
        },
        name: String,
    },

    data() {
        return {
            format: 'YYYY-MM-DD',
        }
    },

    computed: {
        dates() {
            const dates = {};

            const startDate = this.startDate.clone();
            const endDate = this.endDate.clone();
            const diff = endDate.diff(startDate, 'days') + 1;

            for (let i = 0; i < diff; i++) {
                const iStartDate = startDate.clone();
                iStartDate.add(i, 'days');

                if (![6, 7].includes(iStartDate.isoWeekday())) {
                    const date = iStartDate.clone();

                    dates[date.format(this.format)] = {
                        date: date,
                        assignments: this.assignmentsParsed[iStartDate.format(this.format)],
                    }
                }
            }

            return dates;
        },

        assignmentsParsed() {
            const assignmentsParsed = {};

            this.assignments.forEach((assignment) => {
                const date = moment(assignment.date);
                const dateFormatted = date.format(this.format);

                if (!assignmentsParsed[dateFormatted]) {
                    assignmentsParsed[dateFormatted] = [];
                }

                assignmentsParsed[dateFormatted].push(assignment);
            });

            return assignmentsParsed;
        },
    },
}
</script>

<style lang="scss">
.allocations-list-days-row {
    white-space: nowrap;
    height: 100%;
    width: 100%;

    .allocations-list-days-row__date {
        vertical-align: top;
        display: inline-flex;
        align-items: center;
        width: 46px;
        height: 100%;
        border-right: 1px solid rgba(white, .1);
        border-bottom: 1px solid rgba(white, .1);
        flex-direction: column;
        align-content: center;
        justify-content: center;

        .allocations-list-days-row__assignment {
            font-size: 75%;
            line-height: 0;
            background-color: $primary;
            border-radius: 2px;
            width: calc(100% - 4px);
            text-align: center;
            padding: 10px 0;
            margin: 1px 2px;
            position: relative;

            &.--notes {
                &:before {
                    position: absolute;
                    top: 2px;
                    right: 2px;
                    width: 3px;
                    height: 3px;
                    background: rgba(white, .5);
                    content: '';
                    border-radius: 2px 2px 2px 10px;
                    transition: background-color .1s;
                }

                &:hover:before {
                    background: rgba(white, .75);
                }
            }

            &.--excluded {
                background: repeating-linear-gradient(
                        45deg,
                        $primary,
                        $primary 4px,
                        darken($primary, 20%) 4px,
                        darken($primary, 20%) 8px
                );
            }
        }

        .allocations-list-days-row__header {
            text-align: center;
            width: 100%;
            font-size: 80%;
        }
    }
}
</style>
