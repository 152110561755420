<template>
    <div :class="[
        'f-tabs__container',
        '--no-select',
        ...classNames,
        `--type-${type}`,
        (margin ? '--margin' : null),
        '--scrollable-no-bar-horizontal',
    ]">
        <div class="f-tabs">
            <slot/>
        </div>
    </div>
</template>

<script>
import RememberComponentState from '../../../mixins/RememberComponentStateMixin';
import {TabTypes} from './config';
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-tabs',

    mixins: [
        RememberComponentState,
        ClassNameMixin,
    ],

    props: {
        margin: Boolean,
        type: {
            type: String,
            default: TabTypes.TABS,
        },
    },

    data() {
        return {
            childTags: ['f-tab'],
            hashTag: 'tab',
        };
    },

    mounted() {
        const children = this.getChildren();
        let oneActive = this.checkRememberState();

        // If none active, activate the first
        if (!oneActive && children.length > 0) {
            children[0].active = true;
        }

        // Apply on active
        children.forEach(($child) => {
            $child.$on('active', (id) => {
                let previousActiveId;

                children.forEach(($c) => {
                    if ($c.id !== id) {
                        if ($c.active) previousActiveId = $c.id;
                        $c.active = false;
                    }
                });

                if (!this.skipTabChange) {
                    this.$emit('tabChange', id, previousActiveId);
                } else {
                    this.skipTabChange = false;
                }
            });
        });
    },

    methods: {
        toID(id, skipTabChange = false) {
            this.skipTabChange = skipTabChange;

            this.getChildren().forEach(($child) => {
                if ($child.id === id) {
                    $child.activate();
                }
            });
        },
    },
}
</script>

<style lang="scss">
.f-tabs {
    display: inline-flex;
    min-width: 100%;
    align-content: flex-end;
    justify-content: center;
    text-transform: none;
    margin: 0;

    @include media(mobile-down) {
        padding: 0;
    }

    &__container {
        &.--type-tabs {
            .f-tabs {
                padding: 0 20px;
                position: relative;

                &:before {
                    content: "";
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    border-bottom: 1px solid;

                    @include theme(light) {
                        border-bottom-color: darken($softGray, 3);
                    }

                    @include theme(dark) {
                        border-bottom-color: $quaternaryGray;
                    }
                }
            }
        }

        &.--type-blocks {
            .f-tabs {
                padding: 0 5px;
                height: 70px;
                border-radius: 999px;

                @include theme(light) {
                    background: $softGray;
                }

                @include theme(dark) {
                    background: $quaternaryGray;
                }

                @include media(mobile-down) {
                    height: 40px;
                }
            }
        }

        &.--margin {
            margin-bottom: 50px;
        }
    }
}
</style>
