<template>
    <f-flex
        v-if="active"
        :class="[
            'f-inline-message',
            `--${variant}`,
            `--${type}`,
            (closable ? '--closable' : null),
            ...classNames,
            '--theme-light',
        ]"
        :gap="(type === 'dense' ? '8px' : '15px')"
        cross-axis-align="center"
        main-axis-align="start"
    >
        <f-icon :icon="icon"/>
        <div
            :class="[
                'f-inline-message__body',
                 bodyClassName,
             ]"
        >
            <slot/>
            <f-close
                v-if="closable"
                @click="close"
                v-tooltip="'Dismiss'"
            />
        </div>
    </f-flex>
</template>

<script>
import moment from 'moment-timezone';
import FClose from '../../navigation/close/FClose';
import UserHelperMixin from '../../../mixins/UserHelperMixin';
import {InlineMessageVariants} from './config';
import FIcon from '../../layout/icons/FIcon';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import {Icons} from '../../layout/icons/config';
import FFlex from '../../layout/flex/FFlex';
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-inline-message',

    components: {
        FFlex,
        FIcon,
        FClose,
    },

    mixins: [
        UserHelperMixin,
        ClassNameMixin,
    ],

    props: {
        id: {
            type: String,
            required: true,
        },
        bodyClassName: String,
        variant: {
            type: String,
            default: InlineMessageVariants.INFO,
        },
        closable: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            default: 'default', // default | dense
        },
    },

    computed: {
        icon() {
            switch (this.variant) {
                case InlineMessageVariants.ERROR:
                case InlineMessageVariants.WARNING:
                    return Icons.WARNING;
                case InlineMessageVariants.DID_YOU_KNOW:
                    return Icons.LAMP;
                case InlineMessageVariants.SUCCESS:
                    return Icons.CHECK;
                default:
                    return Icons.NOTIFICATION_CIRCLE;
            }
        },
    },

    data() {
        const dates = this.getDates();
        let active = true;

        if (this.closable && dates[this.id]) {
            const date = moment(dates[this.id]);
            active = Math.abs(moment().diff(date, 'hours')) > (24 * 7);
        }

        return {
            active: active,
        }
    },

    mounted() {
        this.parseUserCards();
    },

    methods: {
        close() {
            this.active = false;

            let dates = this.getDates();
            dates[this.id] = moment().format();

            window.localStorage.setItem('riviera.inline-messages', JSON.stringify(dates));

            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },

        getDates() {
            let dates = window.localStorage.getItem('riviera.inline-messages');

            if (dates) {
                try {
                    dates = JSON.parse(dates);
                } catch (error) {
                    console.warn(error);
                }
            }

            if (!dates || typeof dates !== 'object') {
                dates = {};
            }

            return dates;
        }
    },
}
</script>

<style lang="scss">
.f-inline-message {
    background: $primary;
    color: $primaryFront;
    padding: 20px;
    margin: 0 auto 20px;
    text-align: left;
    position: relative;
    min-height: 60px;
    border-radius: 10px;
    overflow: hidden;
    text-overflow: ellipsis;

    &.--dense {
        padding: 10px;
        min-height: 40px;
        margin: 0 auto 10px;
        border-radius: 5px;

        .f-inline-message__body {
            line-height: 110%;
        }

        > .f-icon {
            font-size: 20px;
            margin-top: -1px;
        }
    }

    &:last-child, > div > p:last-child, p:last-child {
        margin-bottom: 0;
    }

    > .f-icon {
        font-size: 30px;
        margin-top: -2px;
    }

    &__body {
        flex: 1;
        line-height: 130%;

        ol, ul {
            padding: 0 0 10px 24px;
            position: relative;
            list-style: none;
            counter-reset: ul-counter;
            line-height: 140% !important;
            margin-top: 10px;

            li {
                counter-increment: ul-counter;
                min-height: 20px;
                position: relative;
                line-height: 140%;
                padding-bottom: 5px;

                b, strong {
                    font-weight: 600;
                }

                p {
                    padding: 0;
                    margin: 0;
                }

                &:last-child {
                    padding-bottom: 0;
                }

                &::before {
                    content: counter(ul-counter);
                    left: -16px;
                    top: 0;
                    position: absolute;
                    color: white;
                }
            }
        }

        ul li {
            counter-increment: ul-counter;

            &::before {
                content: '•';
                font-size: 120%;
            }
        }
    }

    &.--closable .f-inline-message__body {
        padding-right: 35px;

        .f-close {
            top: 10px;
            right: 10px;
        }
    }

    &.--info {
        background: $info;

        &, *, a:hover, a:active, a:visited {
            color: $infoFront !important;
        }

        .f-icon-button:hover .f-icon {
            color: $info !important;
        }
    }

    &.--warning, &.--did-you-know {
        background: $warning;

        &, *, a:hover, a:active, a:visited {
            color: $warningFront !important;
        }

        .f-icon-button:hover .f-icon {
            color: $warning !important;
        }
    }

    &.--success {
        background: $success;

        &, *, a:hover, a:active, a:visited {
            color: $successFront !important;
        }

        .f-icon-button:hover .f-icon {
            color: $success !important;
        }
    }

    &.--error, &.--danger {
        background: $danger;

        &, *, a:hover, a:active, a:visited {
            color: $dangerFront !important;
        }

        .f-icon-button:hover .f-icon {
            color: $danger !important;
        }
    }
}
</style>
