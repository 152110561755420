import {API_DOMAIN} from './app';
import Store from '../services/Store';
import {cleanObj} from '../utils/cleanObject';

const APIEndpoints = {
    AUTH_LOGIN: `${API_DOMAIN}/api/auth/login/gsuite`,
    AUTH_LOGOUT: `${API_DOMAIN}/api/auth/logout`,
    USER: `${API_DOMAIN}/api/user`,
    USER_SETTINGS: `${API_DOMAIN}/api/user/settings`,
    CALENDAR_TODAY: `${API_DOMAIN}/api/calendar/today`,
    PAGES: `${API_DOMAIN}/api/pages/:slug`,

    MEETING_ROOM: `${API_DOMAIN}/api/meeting-rooms/:id`,
    MEETING_ROOMS: `${API_DOMAIN}/api/meeting-rooms/:office/date/:date`,
    MEETING_ROOMS_PHONE_BOOTHS: `${API_DOMAIN}/api/meeting-rooms/:office/date/:date/phone-booth`,
    MEETING_ROOMS_TODAY: `${API_DOMAIN}/api/meeting-rooms/:office/date/today`,
    MEETING_ROOM_QUICK_BOOK: `${API_DOMAIN}/api/meeting-rooms/quick-book`,
    MEETING_ROOM_END_MEETING: `${API_DOMAIN}/api/meeting-rooms/end`,
    MEETING_ROOM_BOOK: `${API_DOMAIN}/api/meeting-rooms/book`,

    COLLECTION_USERS: `${API_DOMAIN}/api/collections/users`,
    COLLECTION_DEPARTMENTS_AND_DISCIPLINES: `${API_DOMAIN}/api/collections/departments-disciplines`,
    COLLECTION_CLIENTS: `${API_DOMAIN}/api/collections/clients`,
    COLLECTION_OFFICES: `${API_DOMAIN}/api/collections/offices`,
    COLLECTION_FREELANCERS: `${API_DOMAIN}/api/collections/freelancers`,

    ABSENTEES_TODAY: `${API_DOMAIN}/api/absentees/today`,
    AVAILABLE_TRAFFICERS: `${API_DOMAIN}/api/absentees/available-trafficers`,
    CURRENTLY_AT_OFFICE: `${API_DOMAIN}/api/currently-at-office`,

    PROJECTS: `${API_DOMAIN}/api/projects`,
    PROJECT_FAVOURITES: `${API_DOMAIN}/api/projects/favourites`,
    PROJECT_LINES: `${API_DOMAIN}/api/projects/lines`,
    PROJECT_MERGE: `${API_DOMAIN}/api/projects/merge`,
    PROJECT_UNMERGE: `${API_DOMAIN}/api/projects/unmerge/:id`,
    PUBLIC_HOLIDAYS: `${API_DOMAIN}/api/calendar/public-holidays`,

    OFFICE_SPOTS: `${API_DOMAIN}/api/office-spots`,
    OFFICE_SPOT_DAY: `${API_DOMAIN}/api/office-spots/:date`,
    OFFICE_SPOT: `${API_DOMAIN}/api/office-spots`,

    FREELANCER_DATABASE: `${API_DOMAIN}/api/freelancer-database`,

    FAQ: `${API_DOMAIN}/api/faq`,

    NOTIFICATIONS: `${API_DOMAIN}/api/notifications`,
    NOTIFICATION_READ: `${API_DOMAIN}/api/notification/read/:id`,
    NOTIFICATION_READ_ALL: `${API_DOMAIN}/api/notification/all-read`,

    LEAVE: `${API_DOMAIN}/api/leave`,
    LEAVE_REQUEST: `${API_DOMAIN}/api/leave/request`,

    TIMELINE: `${API_DOMAIN}/api/timeline`,

    GALLERY: `${API_DOMAIN}/api/gallery`,
    GALLERY_LIKE: `${API_DOMAIN}/api/gallery/image/:id/like`,

    ADMIN_LEAVE_REQUESTS: `${API_DOMAIN}/api/admin/leave-requests`,
    ADMIN_LEAVE_RETRY: `${API_DOMAIN}/api/admin/leave-requests/retry`,
    ADMIN_LEAVE_RETRY_FORCE: `${API_DOMAIN}/api/admin/leave-requests/retry/force`,
    ADMIN_LEAVE_REQUEST_CREATE: `${API_DOMAIN}/api/admin/leave-requests/create`,
    ADMIN_LEAVE_REQUEST_EDIT: `${API_DOMAIN}/api/admin/leave-requests/edit`,
    ADMIN_LEAVE_REQUEST_APPROVAL: `${API_DOMAIN}/api/admin/leave-requests/approval`,
    ADMIN_LEAVE_REQUESTS_HANDLED: `${API_DOMAIN}/api/admin/leave-requests/handled`,
    ADMIN_LEAVE_REQUESTS_BALANCE: `${API_DOMAIN}/api/admin/leave-requests/balance/:id`,
    ADMIN_LEAVE_REQUESTS_BCS: `${API_DOMAIN}/api/admin/leave-requests/bcs/:id`,

    ADMIN_JAMF_SYNC: `${API_DOMAIN}/api/admin/user-sync/jamf`,
    ADMIN_FORECAST_SYNC: `${API_DOMAIN}/api/admin/user-sync/forecast`,
    ADMIN_FORECAST_FREELANCERS_SYNC: `${API_DOMAIN}/api/admin/user-sync/forecast-freelancer`,
    ADMIN_BCS_SYNC: `${API_DOMAIN}/api/admin/user-sync/bcs`,
    ADMIN_USER_MAPPING_UPDATE: `${API_DOMAIN}/api/admin/user-sync/update`,
    ADMIN_USERS: `${API_DOMAIN}/api/admin/users`,
    ADMIN_USER_ACTIVATE: `${API_DOMAIN}/api/admin/users/:id/activate`,
    ADMIN_USER_DEACTIVATE: `${API_DOMAIN}/api/admin/users/:id/deactivate`,
    ADMIN_CLIENTS: `${API_DOMAIN}/api/admin/clients`,
    ADMIN_PRODUCTS: `${API_DOMAIN}/api/admin/products`,
    ADMIN_DISCIPLINES: `${API_DOMAIN}/api/admin/disciplines`,
    ADMIN_DISCIPLINES_DEPARTMENTS: `${API_DOMAIN}/api/admin/disciplines/departments`,
    ADMIN_API_LOGS: `${API_DOMAIN}/api/admin/api-logs`,
    ADMIN_ACTIVE_OFFICE_CLIENTS: `${API_DOMAIN}/api/admin/active-office-clients`,
    ADMIN_PAGES: `${API_DOMAIN}/api/admin/pages`,

    EDITOR_IMAGE_UPLOAD: `${API_DOMAIN}/api/editor/image-upload`,

    // Time-line related
    ADMIN_ANNOUNCEMENTS: `${API_DOMAIN}/api/admin/announcements`,
    ADMIN_FIKA: `${API_DOMAIN}/api/admin/fika`,
    ADMIN_GALLERIES: `${API_DOMAIN}/api/admin/galleries`,
    ADMIN_GALLERY_IMAGE_UPLOAD: `${API_DOMAIN}/api/admin/galleries/:id/upload`,

    // Admin exports
    ADMIN_USERS_EXPORT: `${API_DOMAIN}/admin/users/export`,
    ADMIN_USERS_BCS_EXPORT: `${API_DOMAIN}/admin/users/bcs-export`,
    OFFICE_SPOT_EXPORT: `${API_DOMAIN}/office-spots/export`,

    // Admin external oAuth connections
    ADMIN_EXTERNAL_CONNECTIONS: `${API_DOMAIN}/admin/external/connections/:provider`,

    // Misc
    ADMIN_FLUSH_CACHE: `${API_DOMAIN}/admin/flush-cache`,
    EMBED_INSTAGRAM: `${API_DOMAIN}/embed/instagram/:id`,
    SECURE_REDIRECT: `${API_DOMAIN}/sr`,
    SECURE_ASSET: `${API_DOMAIN}/sd`,

    /**
     * @param {string} endpoint
     * @param {Object} [params]
     * @returns {string}
     */
    parse: function (endpoint, params = {}) {
        const matches = Array.from(endpoint.matchAll(/:([a-zA-Z_]+)/g));

        for (let i in matches) {
            // Verify if param is provided
            if (Object.prototype.hasOwnProperty.call(matches, i) && !params[matches[i][1]]) {
                throw Error(`Missing param ${matches[i][0]} for endpoint ${endpoint}`);
            }

            // Replace param
            endpoint = endpoint.replace(matches[i][0], params[matches[i][1]]);
        }

        return endpoint;
    },

    /**
     * @param {string} url
     * @param {Object} [params]
     * @returns {string}
     */
    secureRedirect(url, params = {}) {
        const redirectParams = {
            r: `${url}?${new URLSearchParams(cleanObj(params))}`,
            t: btoa(Store.state.accessToken),
        }
        return `${this.SECURE_REDIRECT}?${new URLSearchParams(redirectParams)}`;
    },

    /**
     * @param {string} url
     * @returns {string}
     */
    secureAsset(url) {
        const redirectParams = {
            a: url,
            t: btoa(Store.state.accessToken),
        }
        return `${this.SECURE_ASSET}?${new URLSearchParams(redirectParams)}`;
    },
}

export default APIEndpoints;
