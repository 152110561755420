<template>
    <f-button
        :class-name="['f-loader', (inline ? '--inline' : null)]"
        show-loader-on-disabled
        size="xlarge"
        disabled
    />
</template>

<script>
import FButton from '../../form-controls/buttons/FButton';

export default {
    name: 'f-loader',

    components: {
        FButton,
    },

    props: {
        inline: Boolean,
    },
}
</script>

<style lang="scss">
.f-button.--loading.f-loader {
    border: 0;
    pointer-events: none;
}

.f-button.--loading.f-loader:not(.--inline) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}
</style>
