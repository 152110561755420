<template>
    <transition name="popup" appear>
        <div
            :class="[
                'f-badge',
                className,
                (inline ? '--inline' : null),
                `--${variant}`,
                `--${size}`,
            ]"
        >
            <slot/>
        </div>
    </transition>
</template>

<script>
import {BadgeSizes, BadgeVariants} from './config';

export default {
    name: 'f-badge',

    props: {
        inline: Boolean,
        className: String,
        variant: {
            type: String,
            default: BadgeVariants.PRIMARY,
        },
        size: {
            type: String,
            default: BadgeSizes.SMALL,
        },
    },
}
</script>

<style lang="scss">
.f-badge {
    font-family: helvetica-neue, sans-serif;
    font-size: 8px;
    text-align: center;
    position: absolute;
    pointer-events: none;
    padding: 0 4px;
    top: 3px;
    right: 3px;
    font-weight: 600;

    &.--small {
        min-width: 16px;
        height: 16px;
        border-radius: 16px;
        line-height: 18px;
    }

    &.--medium {
        min-width: 26px;
        height: 26px;
        border-radius: 30px;
        line-height: 28px;
        font-size: 11px;
    }

    &.--large {
        min-width: 40px;
        height: 40px;
        border-radius: 40px;
        line-height: 43px;
        font-size: 14px;

        @include media(mobile-down) {
            min-width: 30px;
            height: 30px;
            border-radius: 30px;
            line-height: 33px;
            font-size: 12px;
        }
    }

    &.--inline {
        position: relative;
        vertical-align: middle;
        display: inline-block;
        margin-right: 4px;
        margin-left: 4px;
        top: initial;
        right: initial;
    }

    &.--primary, &.--default {
        background-color: $primary;
        color: $primaryFront;
    }

    &.--secondary {
        background-color: $secondary;
        color: $secondaryFront;
    }

    &.--alternative, &.--dark {
        background: $softGray;
        color: $dark;
    }

    &.--alternative-inverted, &.--light {
        background: $softGray;
        color: $dark;
    }

    &.--error, &.--danger {
        background: $danger;
        color: $dangerShade;
    }
}
</style>
