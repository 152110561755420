import APIDataStore from '../services/APIDataStore';
import APIDataStoreResources from '../config/APIDataStoreResources';
import Store from '../services/Store';
import UserOfficeMixin from './UserOfficeMixin';

export default {
    mixins: [
        UserOfficeMixin,
    ],

    props: {
        office: String,
        offices: Array,
    },

    created() {
        APIDataStore.check(APIDataStoreResources.OFFICES_COLLECTION);
    },

    computed: {
        /**
         * Array of office objects. Could be provided with a prop (used in Storybook), or if not provided,
         * it returns store data (normal behaviour).
         * @returns {[]|*[]}
         */
        officesCollection() {
            if (this.offices) {
                return this.offices;
            }
            if (Store.state.$officesCollection) {
                return Store.state.$officesCollection;
            }
            return [];
        },
        /**
         * Current office ID in this component. Could be provided with a prop, or if not provided,
         * it returns the current user's office.
         * @returns {string}
         */
        currentOffice() {
            return (this.office ? this.office : this.userOffice);
        },
        /**
         * Timezone of current office.
         * @returns {any}
         */
        timezone() {
            for (let i in this.officesCollection) {
                if (this.officesCollection[i].id === this.currentOffice) {
                    return this.officesCollection[i].timezone;
                }
            }
            return 'Europe/Amsterdam';
        },
        /**
         * Current office clock.
         * @returns {string}
         */
        officeClock() {
            if (this.currentOffice === 'austin') {
                return '12';
            }
            return '24';
        },
        /**
         * Current user's clock.
         * @returns {*}
         */
        userClock() {
            return Store.getters.getUserSetting('global.clock', '24');
        },
        /**
         * Time format based on the clock.
         * @returns {string}
         */
        timeFormat() {
            return this.getTimeFormat();
        },
    },

    methods: {
        getTimeFormat(clock) {
            return ((clock ?? this.userClock) === '24' ? 'H:mm' : 'h:mm [<span class="--am-pm">]A<[/span>]');
        },
    }
};
