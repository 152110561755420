<template>
    <div
        v-if="startDate || planningStartDate"
        :class="[
            'project-dates-visualizer',
            '--no-select',
            ...classNames,
        ]"
    >
        <dashed-explanation
            v-if="startDate"
            tooltip="Project start date (Gripp)"
        >
            {{ startDateVisual }}
        </dashed-explanation>
        <span
            v-if="planningStartDate"
            class="project-dates-visualizer__planning-range"
        >
            <subtitle-spacer/> &nbsp;

            <f-icon icon="calendar-empty"/>
            <dashed-explanation
                tooltip="Planning date range (Harvest)"
            >
                {{ planningStartDateVisual }} — {{ planningEndDateVisual }}
            </dashed-explanation>
        </span>
    </div>
</template>

<script>
import moment from "moment";
import FIcon from "../../../../f-components/layout/icons/FIcon";
import SubtitleSpacer from '../../../misc/SubtitleSpacer';
import DashedExplanation from '../../../misc/DashedExplanation';
import ClassNameMixin from '../../../../mixins/ClassNameMixin';

export default {
    name: 'project-dates-visualizer',

    mixins: [
        ClassNameMixin,
    ],

    components: {
        DashedExplanation,
        SubtitleSpacer,
        FIcon,
    },

    props: {
        startDate: String,
        planningStartDate: String,
        planningEndDate: String,
    },

    computed: {
        startDateVisual() {
            return (this.startDate ? moment(this.startDate).format(this.dateFormat) : '');
        },
        planningStartDateVisual() {
            return (this.planningStartDate ? moment(this.planningStartDate).format(this.dateFormat) : '');
        },
        planningEndDateVisual() {
            return (this.planningEndDate ? moment(this.planningEndDate).format(this.dateFormat) : '');
        },
    },

    data() {
        return {
            dateFormat: 'D MMM YYYY',
        }
    },
}
</script>

<style lang="scss">
.project-dates-visualizer {
    font-size: 11px;
    opacity: .65;
    padding: 6px 0;

    .f-icon {
        margin-right: 3px;
    }
}
</style>
