import {API_DOMAIN} from '../config/app';
import APIEndpoints from '../config/APIEndpoints';

export default {
    methods: {
        parseSecureLinks() {
            this.$nextTick(() => {
                if (!this.$el || !this.$el.querySelectorAll) {
                    return;
                }

                this.$el
                    .querySelectorAll("a[href^='secure://']")
                    .forEach((element) => this.parseSecureLink(element));
            });
        },

        parseSecureLink(element) {
            if (element.secureLinksParsed) return;
            element.secureLinksParsed = true;

            element.addEventListener('click', function (e) {
                e.preventDefault();
                const link = API_DOMAIN + '/_sa/' + element.href.replace('secure://', '');
                window.open(APIEndpoints.secureAsset(link));
            });
        },
    },
};
