<template>
    <ul
        v-if="data"
        class="leave-overview"
    >
        <li
            v-for="(row, i) in values"
            :key="i"
        >
            <div
                v-for="(cell, x) in row"
                :key="x"
                class="leave-overview__cell"
                v-tooltip="{text: cell.tooltip, align: 'left'}"
            >
                <div class="leave-overview__cell__title">
                    {{ cell.name }}
                </div>
                <span class="leave-overview__cell__info">
                    {{ cell.value }}
                </span>
            </div>
        </li>
    </ul>
</template>

<script>
import NumbersAndBudgetMixin from '../../../../mixins/NumbersAndBudgetMixin';
import LeaveMixin from '../../../../mixins/LeaveMixin';

export default {
    name: 'leave-overview',

    mixins: [
        NumbersAndBudgetMixin,
        LeaveMixin,
    ],

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            values: [
                [
                    {
                        'name': 'Last year',
                        'value': this.holidayLeaveFormat(this.data.credit),
                        'tooltip': `Unused leave ${this.getHolidayLeaveRep()}<br>from last year`,
                    },
                    {
                        'name': 'This year',
                        'value': this.holidayLeaveFormat(this.data.right),
                        'tooltip': `Leave ${this.getHolidayLeaveRep()} received<br>for this year`,
                    },
                    {
                        'name': 'Total this year',
                        'value': this.holidayLeaveFormat(this.data.total_right),
                        'tooltip': `Total leave ${this.getHolidayLeaveRep()} that<br>can be spend this year`,
                    },
                ],
                [
                    {
                        'name': 'Used',
                        'value': this.holidayLeaveFormat(this.data.used),
                        'tooltip': `Leave ${this.getHolidayLeaveRep()} used<br>this year`,
                    },
                    {
                        'name': 'Scheduled',
                        'value': this.holidayLeaveFormat(this.data.scheduled),
                        'tooltip': `Leave ${this.getHolidayLeaveRep()} that are<br>approved and scheduled`,
                    },
                    {
                        'name': 'Requested',
                        'value': this.holidayLeaveFormat(this.data.requests_total),
                        'tooltip': `Leave ${this.getHolidayLeaveRep()} that are<br>requested and await approval`,
                    },
                    {
                        'name': 'Remaining',
                        'value': this.holidayLeaveFormat(this.data.remaining),
                        'tooltip': `Leave ${this.getHolidayLeaveRep()} are have<br>left for this year`,
                    },
                ],
            ],
        }
    },
}
</script>

<style lang="scss">
.leave-overview {
    width: 100%;
    margin: auto;
    max-width: 400px;
    font-weight: 500;
    user-select: none;

    li {
        padding: 10px 5px;
        border-bottom: 1px solid $softGray;
        display: flex;
        width: 100%;

        &:last-child {
            border: 0;
        }

        .leave-overview__cell {
            flex: 1 1;
            padding: 5px;

            &__title {
                font-size: 85%;
                padding: 0 0 5px 0;
            }

            &__info {
                font-size: 120%;
                font-weight: 600;
                border-bottom: 1px dashed transparent;

                &::first-letter {
                    text-transform: uppercase;
                }
            }

            &:hover {
                .leave-overview__cell__info {
                    border-bottom-color: $dark;
                }
            }
        }
    }
}
</style>
