<template>
    <page
        v-if="hasPermission(Permissions.ADMIN_GALLERIES)"
        class-name="admin-pages-edit-create-page"
        :loading="loading"
        :title="['Admin', 'Pages', mode.ucfirst()]"
        :header="`${mode.ucfirst()} page`"
        stretch-first-container
        pre-max-width="800px"
        :actions="[{
            icon: 'trash2',
            value: 'Delete',
            onClick: () => deletePage($route.params.id, fields.title),
        }, {
            icon: 'link',
            value: 'Deeplink',
            onClick: () => openDeeplink(fetchedData ? fetchedData.data.deeplink : ''),
        }]"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb link="/admin/pages">
                    Pages
                </f-breadcrumb>
                <f-breadcrumb
                    v-if="mode === 'edit'"
                    :loading="!dataLoaded"
                >
                    {{ fields.title }}
                </f-breadcrumb>
                <f-breadcrumb>
                    {{ mode }}
                </f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-form
            ref="form"
            enctype="multipart/form-data"
            @submitValid="submit"
            validate
        >
            <f-container max-width="800px">
                <div v-if="dataLoaded">
                    <f-form-row label="Title">
                        <f-text-input
                            name="title"
                            v-model="fields.title"
                            :validation="['required']"
                            @blur="setSlug"
                        />
                    </f-form-row>

                    <f-form-row label="Slug">
                        <f-text-input
                            name="slug"
                            v-model="fields.slug"
                            :validation="['required']"
                            @blur="verifySlug"
                        />
                    </f-form-row>

                    <f-form-row label="Template">
                        <f-dropdown
                            v-model="fields.template"
                            :options="[{
                                key: 'newsletter',
                                value: 'Newsletter',
                            }]"
                            name="template"
                            width="230px"
                        />
                    </f-form-row>

                    <f-form-row label="Cover">
                        <f-image-input
                            type="square"
                            ref="coverImageUploadField"
                            name="cover image"
                            :src="fetchedData?.data?.cover_image_url"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-editor
                        v-model="fields.body"
                        name="description"
                        :toolbar="getEditorToolbar()"
                        max-content-height="50vh"
                    />
                </div>

                <f-form-placeholder v-else-if="!dataLoaded && loading"/>
            </f-container>

            <entity-action-button
                v-if="dataLoaded"
                :permission="Permissions.ADMIN_PAGES"
                :tooltip="(mode === 'edit' ? 'Update' : 'Create')"
                :disabled="loading"
                icon="floppy-disk"
                type="submit"
            />
        </f-form>
    </page>
</template>

<script>
import APIEndpoints from '../../../../config/APIEndpoints';
import OfficeOptionsMixin from '../../../../mixins/OfficeOptionsMixin';
import EntityCreateEditMixin from "../../../../mixins/entities/EntityCreateEditMixin";
import AdminPagesMixin from './AdminPagesMixin';
import {EditorToolbar} from '../../../../f-components/form-controls/editor/config';
import Page from '../../Page.vue';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs.vue';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb.vue';
import FForm from '../../../../f-components/form-controls/forms/FForm.vue';
import FContainer from '../../../../f-components/layout/container/FContainer.vue';
import FFormRow from '../../../../f-components/form-controls/forms/FFormRow.vue';
import FTextInput from '../../../../f-components/form-controls/text/FTextInput.vue';
import FFormRowSpacer from '../../../../f-components/form-controls/forms/FFormRowSpacer.vue';
import FEditor from '../../../../f-components/form-controls/editor/FEditor.vue';
import FFormPlaceholder from '../../../../f-components/loaders/placeholder/FFormPlaceholder.vue';
import EntityActionButton from '../../../misc/entities/EntityActionButton.vue';
import FDropdown from '../../../../f-components/form-controls/dropdowns/FDropdown.vue';
import FImageInput from '../../../../f-components/form-controls/images/FImageInput.vue';

export default {
    name: 'admin-pages-edit-create-page',

    components: {
        FImageInput,
        FDropdown,
        EntityActionButton,
        FFormPlaceholder,
        FEditor,
        FFormRowSpacer,
        FTextInput,
        FFormRow,
        FContainer,
        FForm,
        FBreadcrumb,
        FBreadcrumbs,
        Page,
    },

    mixins: [
        OfficeOptionsMixin,
        EntityCreateEditMixin,
        AdminPagesMixin,
    ],

    data() {
        return {
            entityName: 'page',
            mode: (this.$route.name === 'admin.pages.edit' ? 'edit' : 'create'),
            fields: {
                title: undefined,
                slug: undefined,
                template: 'newsletter',
                body: '',
            },
        }
    },

    computed: {
        uploadUrl() {
            return APIEndpoints.parse(APIEndpoints.ADMIN_GALLERY_IMAGE_UPLOAD, {
                id: this.$route.params.id,
            });
        },
    },

    methods: {
        getBaseUrl() {
            return APIEndpoints.ADMIN_PAGES;
        },

        getRedirectPath() {
            return '/admin/pages';
        },

        configureFormData(formData) {
            const file = this.$refs.coverImageUploadField.getFile();
            if (file) {
                formData.set('cover_image', file);
            }
            return formData;
        },

        getEditorToolbar() {
            return [
                EditorToolbar.BOLD,
                EditorToolbar.ITALIC,
                EditorToolbar.STRIKE,
                EditorToolbar.H1,
                EditorToolbar.H2,
                EditorToolbar.H3,
                EditorToolbar.LINK,
                EditorToolbar.UNDERLINE,
                EditorToolbar.BULLET_LIST,
                EditorToolbar.ORDERED_LIST,
                EditorToolbar.HARD_BREAK,
                EditorToolbar.IMAGE,
                EditorToolbar.TABLE,
                EditorToolbar.HISTORY,
                EditorToolbar.HORIZONTAL_RULE,
                EditorToolbar.SOURCE,
            ];
        },

        setSlug() {
            if (!this.fields.slug && this.fields.title) {
                this.fields.slug = this.fields.title.slugify();
            }
        },

        verifySlug() {
            if (this.fields.slug) {
                this.fields.slug = this.fields.slug.slugify();
            }
        },
    }
}
</script>
