var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        `page-${_vm.$router.currentRoute.name}`,
        `--env-${_vm.environment}`,
        `--office-${_vm.userOffice}`,
        (_vm.isChristmas    ? '--christmas' : undefined),
        (_vm.modalsOpen > 0 ? '--modal-active' : undefined),
        '--theme-light',
    ],attrs:{"id":"riviera"}},[(_vm.environment !== 'production')?_c('environment-label',{attrs:{"environment":_vm.environment}}):_vm._e(),_c('div',{staticClass:"container"},[(_vm.menuActive)?_c('main-menu'):_vm._e(),_c('div',{staticClass:"container__content"},[(_vm.menuActive)?_c('page-header'):_vm._e(),_c('router-view'),_c('page-footer')],1)],1),_c('gallery'),(_vm.isChristmas)?_c('snowflakes'):_vm._e(),(_vm.menuActive)?_c('profile-settings-pane'):_vm._e(),_c('notification-modal'),_c('user-card-popup'),(!_vm.breakpoints.mobile && _vm.tutorialOpen)?_c('tutorial',{on:{"close":function($event){_vm.tutorialOpen = false}}}):_vm._e(),_c('f-site-loader'),_c('f-portal-container'),_c('f-alert'),_c('f-tooltip'),_c('f-push-messages')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }