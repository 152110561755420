<template>
    <div
        id="dashboardTimeline"
        :class="[
            'timeline',
            (loading ? '--loading' : null),
        ]"
    >
        <f-scroll-pane
            ref="scroll"
            mode="horizontal"
            :class-name="['timeline__scroll', '--loader']"
            no-scroll-padding
            hide-scroll-bar
            drag
            @scroll="onScroll"
        >
            <div
                v-if="dataLoaded"
                class="timeline__scroll__content"
                :style="{ padding: `0 ${padding}px` }"
            >
                <f-loader inline v-if="loading"/>
                <timeline-day
                    v-for="day in days"
                    :key="day.key"
                    :label="day.label"
                    :pinned="day.key === 'pinned'"
                >
                    <component
                        v-for="(event, i) in day.events"
                        :key="i"
                        :is="event.type"
                        v-bind="event.data"
                    />
                </timeline-day>
            </div>

            <f-placeholder-container
                v-else-if="!dataLoaded && loading"
                class-name="timeline__scroll__content"
                :style="{ padding: `0 ${padding}px`, gap: '10px' }"
            >
                <!-- birthday -->
                <f-placeholder width="170px" height="238px"/>
                <!-- fika -->
                <f-placeholder width="243px" height="160px"/>
                <!-- birthday -->
                <f-placeholder width="170px" height="238px"/>
                <!-- gallery -->
                <f-placeholder width="240px" height="240px"/>
                <!-- birthday -->
                <f-placeholder width="170px" height="238px"/>
                <!-- fika -->
                <f-placeholder width="243px" height="160px"/>
                <!-- fika -->
                <f-placeholder width="243px" height="160px"/>
                <!-- birthday -->
                <f-placeholder width="170px" height="238px"/>
            </f-placeholder-container>
        </f-scroll-pane>

        <f-container
            :max-width="maxWidth"
            class-name="--align-right"
            aria-hidden="true"
        >
            <span ref="position">&nbsp;</span>
            <!-- This span is used to get the most right position,
             which is the padding / max width applied by the container -->
        </f-container>
    </div>
</template>

<script>
import moment from 'moment-timezone';
import LoadingMixin from '../../../mixins/LoadingMixin';
import FContainer from '../../../f-components/layout/container/FContainer';
import FPlaceholderContainer from '../../../f-components/loaders/placeholder/FPlaceholderContainer';
import FPlaceholder from '../../../f-components/loaders/placeholder/FPlaceholder';
import FScrollPane from '../../../f-components/other/scroll-pane/FScrollPane';
import {GlobalEvents} from '../../../config/Events';
import EventBus from '../../../services/EventBus';
import TimelineDay from './components/TimelineDay';
import BirthdayOrAnniversaryEvent from './components/BirthdayOrAnniversaryEvent';
import {SiteLoaderSizes} from '../../../f-components/loaders/site-loader/config';
import APIEndpoints from '../../../config/APIEndpoints';
import FikaEvent from './components/FikaEvent';
import PictureGalleryEvent from './components/PictureGalleryEvent';
import AnnouncementEvent from './components/announcements/AnnouncementEvent';
import FLoader from '../../../f-components/loaders/loader/FLoader';

export default {
    name: 'timeline',

    mixins: [
        LoadingMixin,
    ],

    components: {
        FLoader,
        PictureGalleryEvent,
        FikaEvent,
        BirthdayOrAnniversaryEvent,
        TimelineDay,
        FScrollPane,
        FPlaceholder,
        FPlaceholderContainer,
        FContainer,
        AnnouncementEvent,
    },

    props: {
        maxWidth: {
            type: String,
            required: true,
        },
        page: {
            type: String,
            default: "dashboard"
        },
    },

    computed: {
        days() {
            const days = {};

            if (this.page === 'dashboard') {
                // // Add birthdays
                // this.birthdays.forEach((user) => {
                //     const birthDay = moment(user.date_of_birth).year(this.today.year()).startOf('day');
                //     this.addEvent(days, birthDay, 'birthday-or-anniversary-event', {
                //         user: user,
                //         date: birthDay.format('DD MMM'),
                //         type: 'birthday',
                //     });
                // });
                //
                // // Add anniversaries
                // this.anniversaries.forEach((user) => {
                //     const startedAt = moment(user.started_at),
                //         anniversary = moment(user.started_at).year(this.today.year()).startOf('day'),
                //         yearsDiff = anniversary.diff(startedAt, 'years');
                //
                //     if (yearsDiff < 1) return;
                //
                //     let years = yearsDiff;
                //     let date = anniversary.format('DD MMM');
                //
                //     const twelveAndAHalfAnniversary = moment(user.started_at).add(12, 'years').add(6, 'months').startOf('day');
                //     const twelveAndAHalfAnniversaryDiff = twelveAndAHalfAnniversary.diff(this.today, 'days');
                //     if (twelveAndAHalfAnniversaryDiff < 20 && twelveAndAHalfAnniversaryDiff > -20) {
                //         years = 12.5;
                //         date = twelveAndAHalfAnniversary.format('DD MMM');
                //     }
                //
                //     this.addEvent(days, years === 12.5 ? twelveAndAHalfAnniversary : anniversary, 'birthday-or-anniversary-event', {
                //         user,
                //         date,
                //         type: 'anniversary',
                //         years,
                //     });
                // });
            }

            // Add timeline data
            if (this.timeline) {
                this.timeline.forEach((item) => {
                    const {type, data} = this.parseTimelineItemData(item);
                    if (type) {
                        this.addEvent(days, moment(item.date), type, data, item.is_pinned);
                    }
                });
            }

            return Object.values(days).sort((dayA, dayB) => dayA.date.unix() - dayB.date.unix());
        },

        // birthdays() {
        //     return this.$store.state.$usersCollection
        //         .filter((user) => {
        //             if (user.hide_from_list || !user.date_of_birth) return false;
        //
        //             const timeAgo = moment().subtract(7, 'days').startOf('day'),
        //                 birthDay = moment(user.date_of_birth).year(this.today.year()).startOf('day');
        //
        //             return birthDay >= timeAgo && birthDay <= this.today;
        //         });
        // },
        //
        // anniversaries() {
        //     return this.$store.state.$usersCollection
        //         .filter((user) => {
        //             if (user.hide_from_list || !user.date_of_birth) return false;
        //
        //             const timeAgo = moment().subtract(7, 'days').startOf('day'),
        //                 twelveAndAHalfAnniversary = moment(user.started_at).add(12, 'years').add(6, 'months').startOf('day'),
        //                 anniversary = moment(user.started_at).year(this.today.year()).startOf('day');
        //
        //             return (anniversary >= timeAgo && anniversary <= this.today) ||
        //                 (twelveAndAHalfAnniversary >= timeAgo && twelveAndAHalfAnniversary <= this.today);
        //         });
        // },

        loadAvailable() {
            return this.currentPage < this.totalPages;
        },
    },

    data() {
        return {
            dragging: false,
            padding: 0,
            timeline: [],
            today: moment().startOf('day'),
            currentPage: 0,
            totalPages: 1,
            lastScrollPosition: 0,
        }
    },

    watch: {
        timeline() {
            if (this.currentPage === 1) this.scrollToEnd();
            else this.scrollToLastPosition();
        },
    },

    mounted() {
        EventBus.$on(GlobalEvents.WINDOW_RESIZE, this.position);
        this.fetch();
        this.$nextTick(this.position);
        this.$nextTick(() => this.scrollToEnd());
    },

    beforeDestroy() {
        EventBus.$off(GlobalEvents.WINDOW_RESIZE, this.position);
    },

    methods: {
        scrollToEnd() {
            this.$nextTick(() => this.$refs.scroll.scrollToRight());
        },

        scrollToLastPosition() {
            this.$nextTick(() => {
                this.$refs.scroll.getScrollElement().scrollLeft =
                    this.$refs.scroll.getScrollElement().scrollWidth - this.lastScrollPosition - 40;
            });
        },

        fetch() {
            if (!this.loadAvailable) return;

            this.startLoading(SiteLoaderSizes.SMALL);
            this.currentPage++;

            // console.log(`[timeline] Fetching page ${this.currentPage}`);

            if (this.currentPage > 0) {
                this.lastScrollPosition = this.$refs.scroll.getScrollElement().scrollWidth;
            }

            let endpoint = APIEndpoints.TIMELINE;
            if (this.page) {
                endpoint = `${APIEndpoints.TIMELINE}/${this.page}`;
            }

            window.axios
                .get(`${endpoint}?${new URLSearchParams({page: this.currentPage})}`)
                .then(response => {
                    this.endLoading(true);
                    this.timeline = [...this.timeline, ...response.data.data];
                    this.totalPages = response.data.last_page;
                })
                .catch(() => this.endLoading());
        },

        addEvent(days, day, type, data, pinned = false) {
            let key = day.startOf('day').format('YY-MM-DD');

            if (pinned) {
                key = 'pinned';
                if (!days[key]) {
                    days[key] = {
                        date: moment().add('day'),
                        key: key,
                        label: ['pinned'],
                        events: [],
                    };
                }
            } else if (!days[key]) {
                const daysDiff = this.today.diff(day, 'days');

                let label;
                if (daysDiff === 0) label = ['Today'];
                else if (daysDiff === 1) label = ['Yesterday'];
                else label = [day.format('ddd'), day.format('DD MMM') + (day.year() !== this.today.year() ? ` ${day.year()}` : '')];

                days[key] = {
                    date: day.startOf('day'),
                    key: key,
                    label: label,
                    events: [],
                };
            }

            days[key].events.unshift({
                type: type,
                data: data,
            });
        },

        position() {
            this.padding = window.innerWidth - this.$refs.position.getBoundingClientRect().right;
        },

        onScroll(e) {
            // console.log(this.loading, this.loadAvailable, e.target.scrollLeft);

            if (this.loading || !this.loadAvailable) return;
            if (e.target.scrollLeft < 50) {
                this.fetch();
            }
        },

        parseTimelineItemData(item) {
            let type, data;

            switch (item.timelineable_type) {
                case 'Riviera\\Models\\Announcement':
                    type = 'announcement-event';
                    data = {
                        ratio: item.timelineable.timeline_ratio,
                        titleSize: item.timelineable.timeline_title_size,
                        image: item.timelineable.timeline_image_url,
                        type: item.timelineable.type,
                        title: item.timelineable.timeline_title,
                        subtitle: item.timelineable.timeline_subtitle,
                        modalTitle: item.timelineable.popup_title.split('/'),
                        modalBody: item.timelineable.popup_body,
                        modalHasEmbed: item.timelineable.popup_has_embed,
                        modalEmbedType: item.timelineable.popup_embed_type,
                        modalEmbedId: item.timelineable.popup_embed_id,
                        modalHasButton: item.timelineable.popup_has_button,
                        modalButtonValue: item.timelineable.popup_button_value,
                        modalButtonUrl: item.timelineable.popup_button_url,
                    }
                    break;

                case 'Riviera\\Models\\Fika':
                    type = 'fika-event';
                    data = {
                        number: item.timelineable.number,
                        slide_id: item.timelineable.slide_id,
                    };
                    break;

                case 'Riviera\\Models\\Gallery':
                    type = 'picture-gallery-event';
                    data = {
                        title: item.timelineable.title,
                        subtitle: item.timelineable.subtitle,
                        slug: item.timelineable.slug,
                        cover: item.timelineable.cover_image_url,
                    };
                    break;
            }

            return {type, data};
        }
    },
}
</script>

<style lang="scss">
.timeline {
    min-height: 320px;
    max-height: 800px;
    position: relative;

    margin-bottom: -50px;

    @include media(tablet-down) {
        margin-bottom: -40px;
    }

    &.--loading {
        .timeline__scroll {
            pointer-events: none;
        }
    }

    .f-loader {
        margin-right: 20px;
    }

    .f-placeholder-container {
        margin-top: 48px;
    }

    @include media(mobile-down) {
        min-height: 270px;
    }

    .--modal-active & {
        pointer-events: none;
    }

    .f-container {
        height: 0;
    }

    .f-placeholder-container {
        .f-placeholder {
            border-radius: 6px;
        }
    }

    &__scroll {
        width: 100%;

        &__content {
            display: inline-flex;
        }
    }
}
</style>
