<template>
    <simple-page ref="page" class-name="dynamic-page" :loading="loading">
        <div v-if="dataLoaded" class="newsletter__container">
            <img v-if="page.cover_image_original" :src="page.cover_image_original" alt="" width="100%"/>
            <div class="newsletter">
                <div class="newsletter__content">
                    <f-content v-html="body"/>
                </div>
            </div>
        </div>
    </simple-page>
</template>

<script>
import SimplePage from '../SimplePage';
import LoadingMixin from '../../../mixins/LoadingMixin';
import APIEndpoints from '../../../config/APIEndpoints';
import FContainer from '../../../f-components/layout/container/FContainer.vue';
import FContent from '../../../f-components/layout/content/FContent.vue';
import IFrameEmbed from '../../misc/embeds/IFrameEmbed.vue';

export default {
    name: 'dynamic-page',

    mixins: [
        LoadingMixin,
    ],

    components: {
        IFrameEmbed,
        FContent,
        FContainer,
        SimplePage,
    },

    created() {
        this.fetch();
    },

    data() {
        return {
            page: undefined,
            test: undefined,
            body: undefined,
        }
    },

    methods: {
        fetch() {
            const slug = this.$router.currentRoute.params.slug;
            const url = APIEndpoints.parse(APIEndpoints.PAGES, {slug});

            this.startLoading();

            window.axios
                .get(url)
                .then(response => {
                    if (response.data && response.data.data) {
                        this.page = response.data.data;

                        this.body = this.parseBody(this.page.body);
                        this.$refs.page.setDocumentTitle(this.page.title);
                    }

                    this.endLoading(true);
                })
                .catch((e) => this.onLoadingError(e));
        },

        parseBody(body) {
            const resultsA = [...body.matchAll(/<(a+) (?!(?:href=(["|']+)(.*?)["|'])) *[^>]*>(.*?)[^>]>/gm)];
            resultsA.forEach(result => {
                const match = result[0].match(/https:\/\/www\.instagram\.com\/p\/([a-zA-Z0-9]+)\/?/g);
                if (match && match[0]) {
                    body = body.replace(result[0], '[' + match[0] + ']');
                }
            });

            const resultsB = [...body.matchAll(/\[https:\/\/www\.instagram\.com\/p\/([a-zA-Z0-9]+)\/?]/g)];
            resultsB.forEach(result => {
                const url = APIEndpoints.secureRedirect(APIEndpoints.parse(APIEndpoints.EMBED_INSTAGRAM, {id: result[1]}));
                body = body.replace(result[0], `<div class="instagram-embed"><iframe src="${url}" class="google-drive-embed__iframe"></iframe></div>`);
            });

            return body;
        },
    },
}
</script>

<style lang="scss">
.dynamic-page {
    position: relative;
    padding: 30px;
    min-height: 100vh;

    @include media(mobile-down) {
        padding: 0;
    }
}

.newsletter {
    background: white;

    &__container {
        position: relative;
        max-width: 700px;
        margin: auto;
        z-index: 2;
        border-radius: 20px;
        overflow: hidden;

        @include media(mobile-down) {
            border-radius: 0;
        }

        //header {
        //    background-size: cover;
        //    padding-top: 60%;
        //    width: 100%;
        //    background-color: white;
        //}
    }

    &__content {
        padding: 30px;
        line-height: 140%;
        font-size: 15px;

        .instagram-embed {
            padding-top: 115%;
            position: relative;
            overflow: hidden;
            width: 100%;
            max-width: 400px;
            margin: 40px auto;
            border-radius: 5px;
            background-color: #fff;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }
        }

        @include media(mobile-down) {
            padding: 15px;
            font-size: 13px;
        }

        h1 {
            font-size: 30px;

            @include media(mobile-down) {
                font-size: 25px;
            }
        }

        h2 {
            font-size: 25px;

            @include media(mobile-down) {
                font-size: 20px;
            }
        }

        h3 {
            font-size: 20px;

            @include media(mobile-down) {
                font-size: 17px;
            }
        }

        a {
            color: $primary !important;;

            &:hover {
                color: $primary !important;
            }
        }

        hr {
            margin: 40px 0;

            @include media(mobile-down) {
                margin: 25px 0;
            }
        }
    }
}

.page-page {
    .page-header, .page-footer, .main-menu {
        display: none !important;
    }
}
</style>
