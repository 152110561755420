var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f-table-row',{attrs:{"class-name":"leave-requests-admin-table-row"}},[(!_vm.hideRequestee)?_c('f-table-cell',{attrs:{"width":"160px","align":"center"}},[(_vm.approvalMode)?_c('user-card-compact',{attrs:{"user":_vm.request.user,"variant":_vm.userCardVariant}}):_c('user-card-compact',{attrs:{"user":_vm.request.user,"show-disciplines":false,"variant":_vm.userCardVariant}},[_vm._v(" "+_vm._s(_vm.createdAt)+" ")])],1):_vm._e(),_c('f-table-cell',{attrs:{"class-name":"leave-requests-admin-table-row__reason-cell"}},[_c('div',{staticClass:"leave-requests-admin-table-row__dates"},_vm._l((_vm.getSortedDates(_vm.request.requested_dates)),function(dateRange){return _c('f-chip',{key:dateRange[0],attrs:{"variant":"info","size":"small"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getDateRangeDescription(dateRange, _vm.request))}})])}),1),_c('strong',[_vm._v(_vm._s(_vm.getTimeOffOptionByKey(_vm.request.type).value))]),_vm._v(" "+_vm._s(_vm.request.reason)+" ")]),_c('f-table-cell',{attrs:{"align":"center","no-padding-right":""}},[(_vm.request.approval_reason)?_c('f-chip',{attrs:{"variant":_vm.statusToVariant(_vm.request.status)}},[_c('em',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    text: _vm.request.approval_reason,
                    align: 'top',
                    wrap: true,
                    variant: (_vm.request.status === 'denied' ? 'danger' : null)
                }),expression:"{\n                    text: request.approval_reason,\n                    align: 'top',\n                    wrap: true,\n                    variant: (request.status === 'denied' ? 'danger' : null)\n                }"}]},[_vm._v(" "+_vm._s(_vm.request.status)+" ")])]):_c('f-chip',{attrs:{"variant":_vm.statusToVariant(_vm.request.status)}},[_vm._v(" "+_vm._s(_vm.request.status)+" ")]),(_vm.request.error)?_c('f-chip',{attrs:{"variant":"error"}},[_c('em',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    text: _vm.request.error.description + '<hr><code>' + _vm.request.error.message + '</code>',
                    align: 'top',
                    wrap: true,
                    variant: 'danger'
                }),expression:"{\n                    text: request.error.description + '<hr><code>' + request.error.message + '</code>',\n                    align: 'top',\n                    wrap: true,\n                    variant: 'danger'\n                }"}]},[_vm._v(" error ")])]):_vm._e(),(_vm.request.user && !_vm.request.user.bcs_employment_id)?_c('f-chip',{attrs:{"variant":"warning"}},[_c('em',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    text: 'This employee does not have a BCS ID set',
                    align: 'top',
                    wrap: true,
                    variant: 'warning'
                }),expression:"{\n                    text: 'This employee does not have a BCS ID set',\n                    align: 'top',\n                    wrap: true,\n                    variant: 'warning'\n                }"}]},[_vm._v(" missing BCS ID ")])]):_vm._e()],1),(!_vm.approvalMode)?_c('f-table-cell',{attrs:{"width":"160px","align":"center"}},[(_vm.request.approval_by_id)?_c('user-card-compact',{attrs:{"user":_vm.userById(_vm.request.approval_by_id),"show-disciplines":false,"variant":_vm.userCardVariant}},[_vm._v(" "+_vm._s(_vm.approvalAt.ucfirst())+" ")]):_vm._e()],1):_vm._e(),_c('f-table-cell',{attrs:{"align":"right","width":"20px"}},[_c('f-icon-button',{ref:"menuButton",attrs:{"class-name":"leave-requests-request-menu-button","selected":_vm.menuOpen,"disabled":_vm.menuOptions.length === 0,"icon":"ellipsis","size":"small","no-parent-hover":"","outlined":""},on:{"click":function($event){$event.preventDefault();_vm.menuOpen = true}}})],1),(_vm.menuOpen && _vm.$refs.menuButton && _vm.menuOptions.length > 0)?_c('f-popup',{attrs:{"target":_vm.$refs.menuButton.$el,"padding":"0"},on:{"close":function($event){_vm.menuOpen = false}}},[_c('f-popup-list',_vm._l((_vm.menuOptions),function(option,i){return _c('f-popup-list-item',{key:i,attrs:{"icon":option.icon},on:{"click":function($event){return _vm.onMenuSelect(option, _vm.request)}}},[_vm._v(" "+_vm._s(option.value)+" ")])}),1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }