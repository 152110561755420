import moment from 'moment';

export class Day {
    constructor(date, passive = false) {
        this._date = date;
        this._passive = passive;
        this._unavailable = false;
        this._picked = false;
        this._tooltip = null;
    }

    isPicked(dates) {
        if (this._picked) return true;

        let isPicked = false;

        dates.forEach((date) => {
            if (moment(date).isSame(this._date, 'day')) {
                isPicked = true;
            }
        });

        return isPicked;
    }

    isWithInRange(values) {
        if (!values[0] || !values[1]) {
            return false;
        }

        return this._date.isBetween(moment(values[0]), moment(values[1]));
    }

    get today() {
        return (this._date.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'));
    }

    get number() {
        return (this._date.date());
    }

    get date() {
        return this._date;
    }

    get passive() {
        return this._passive;
    }

    set passive(value) {
        this._passive = value;
    }

    get unavailable() {
        return this._unavailable;
    }

    set unavailable(value) {
        this._unavailable = value;
    }

    get picked() {
        return this.isPicked();
    }

    set picked(value) {
        this._picked = value;
    }

    get tooltip() {
        return this._tooltip;
    }

    set tooltip(value) {
        this._tooltip = value;
    }
}

export class Month {
    constructor(date) {
        this._date = date;
        this._unavailable = false;
    }

    isPicked(dates) {
        let isPicked = false;

        dates.forEach((date) => {
            if (moment(date).isSame(this._date, 'month')) {
                isPicked = true;
            }
        });

        return isPicked;
    }

    get today() {
        return (moment(this._date).startOf('month').diff(moment().startOf('month'), 'days') === 0);
    }

    get label() {
        return this._date.format('MMMM');
    }

    get date() {
        return this._date;
    }

    get unavailable() {
        return this._unavailable;
    }

    set unavailable(value) {
        this._unavailable = value;
    }
}

export class Year {
    constructor(year) {
        this._year = year;
        this._unavailable = false;
    }

    isPicked(dates) {
        let isPicked = false;

        dates.forEach((date) => {
            if (moment(date).year() === this._year) {
                isPicked = true;
            }
        });

        return isPicked;
    }

    get today() {
        return moment().year() === this._year;
    }

    get date() {
        return this._date;
    }

    get year() {
        return this._year;
    }

    set unavailable(value) {
        this._unavailable = value;
    }

    get unavailable() {
        return this._unavailable;
    }
}
