<template>
    <div
        ref="pin"
        :class="[
            'pin',
            `--${color}`,
            (isFalling ? '--is-falling' : undefined),
        ]"
        :style="{
            left: `${left}px`,
            top: `${top}px`,
        }"
        v-tooltip="{
            text: title,
            align: 'bottom',
            touch: true,
            variant: 'dark'
        }"
        @mouseover="onHover"
        @mouseleave="onLeave"
    >
        <div
            ref="container"
            class="pin__container"
            :style="{ transform: containerTransform }"
        >
            <div class="pin__container__inner">
                <f-icon :icon="icon"/>
            </div>
        </div>
        <div class="pin__shadow"/>
    </div>
</template>

<script>
import FIcon from "../../../../f-components/layout/icons/FIcon";
import EventBus from '../../../../services/EventBus';
import {GlobalEvents} from '../../../../config/Events';

export default {
    name: 'pin',

    components: {
        FIcon,
    },

    props: {
        left: String,
        top: String,
        color: {
            type: String,
            default: 'purple'
        },
        title: String,
        icon: {
            type: String,
            default: 'anchor',
        },
    },

    data() {
        return {
            isFalling: true,
            containerTransform: 'translateZ(10px)',
            timeout: undefined,
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.containerTransform = 'translateZ(1px)';
            this.timeout = window.setTimeout(() => this.isFalling = false, 300);
        });
    },

    beforeDestroy() {
        if (this.timeout) window.clearTimeout(this.timeout);
    },

    methods: {
        onHover() {
            if (!this.$refs.container) return;
            this.containerTransform = 'translateZ(8px)';
        },
        onLeave() {
            if (!this.$refs.container) return;
            this.containerTransform = 'translateZ(1px)';
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },
    },
}
</script>

<style lang="scss">
$pinDiameter: 36px;

.pin {
    transform: translateZ(1px);
    transform-style: preserve-3d;
    position: absolute;
    transition: opacity .2s;

    &::before {
        content: '';
        position: absolute;
        border-radius: 50%;
        transition: all .3s;
        border: 4px solid $primary;
        width: 0;
        height: 0;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
    }

    &.--is-falling {
        pointer-events: none;

        .pin__container__inner, .pin__shadow {
            opacity: 0;
        }

        &::before {
            border: 0 solid $primary;
        }
    }

    &:hover::before {
        border: 8px solid $primary;
    }

    &__container {
        transform-style: preserve-3d;
        transition: transform .2s;

        &__inner {
            transition: transform 1s, opacity .3s;
            animation: pin-rotate 3s infinite;
            width: $pinDiameter;
            height: $pinDiameter;
            color: white;
            left: 0;
            top: 50%;
            position: absolute;
            border-radius: 50%;
            transform: translateZ(30px) translateX(-18px) translateY(-19px) rotateZ(30deg) rotateX(-90deg);
            text-align: center;
            z-index: 2;

            .f-icon {
                position: absolute;
                z-index: 3;
                font-size: 21px;
                top: 8px;
                left: 0;
                right: 0;
                margin: auto;
            }

            &::after {
                z-index: 1;
                content: '';
                position: absolute;
                top: 69%;
                left: 50%;
                border-left: 17px solid transparent;
                border-right: 17px solid transparent;
                border-top: 24px solid firebrick;
                transform: translateX(-50%);
            }
        }
    }

    &__shadow {
        animation: pin-rotate-shadow 3s infinite;
        width: $pinDiameter;
        height: $pinDiameter;
        background: black;
        left: 0;
        top: 50%;
        position: absolute;
        border-radius: 50%;
        transition: opacity .3s;
        transform: translateZ(1px) translateX(-20px) translateY(-49px) rotateZ(0deg) scaleX(1);
        text-align: center;
        z-index: 2;
        opacity: .1;

        &::after {
            z-index: 1;
            content: '';
            position: absolute;
            top: 69%;
            left: 50%;
            border-left: 17px solid transparent;
            border-right: 17px solid transparent;
            border-top: 24px solid black;
            transform: translateX(-50%);
        }
    }

    &.--blue .pin__container__inner {
        &::after {
            border-top-color: $info;
        }

        background: $info;
    }

    &.--gray .pin__container__inner {
        &::after {
            border-top-color: dimgrey;
        }

        background: dimgrey;
    }

    &.--purple .pin__container__inner {
        &::after {
            border-top-color: #8d65e0;
        }

        background: #8d65e0;
    }

    &.--green .pin__container__inner {
        &::after {
            border-top-color: $success;
        }

        background: $success;
    }

    &.--pink .pin__container__inner {
        &::after {
            border-top-color: $secondary;
        }

        background: $secondary;
    }
}

@keyframes pin-rotate {
    0% {
        transform: translateZ(30px) translateX(-18px) translateY(-19px) rotateZ(0deg) rotateX(-90deg);
    }
    50% {
        transform: translateZ(30px) translateX(-18px) translateY(-19px) rotateZ(90deg) rotateX(-90deg);
    }
    100% {
        transform: translateZ(30px) translateX(-18px) translateY(-19px) rotateZ(0deg) rotateX(-90deg);
    }
}

@keyframes pin-rotate-shadow {
    0% {
        transform: translateZ(1px) translateX(-20px) translateY(-49px) rotateZ(0deg) scaleX(1);
    }
    50% {
        transform: translateZ(1px) translateX(-20px) translateY(-49px) rotateZ(0deg) scaleX(.2);
    }
    100% {
        transform: translateZ(1px) translateX(-20px) translateY(-49px) rotateZ(0deg) scaleX(1);
    }
}
</style>
