import EventBus from '../services/EventBus';
import {GlobalEvents} from '../config/Events';
import {breakpoints} from '../utils/breakpoints';

export default {
    data() {
        return {
            windowWidth: 0,
            windowHeight: 0,
        }
    },

    computed: {
        breakpoints() {
            const bp = {};
            for (let i in breakpoints) {
                bp[i] = breakpoints[i] >= this.windowWidth;
            }
            return bp;
        },
    },

    mounted() {
        EventBus.$on(GlobalEvents.WINDOW_RESIZE, this.calculateBreakpoints);
        this.calculateBreakpoints();
    },

    beforeDestroy() {
        EventBus.$off(GlobalEvents.WINDOW_RESIZE, this.calculateBreakpoints);
    },

    methods: {
        calculateBreakpoints() {
            this.windowWidth = window.innerWidth;
        },
    },
};
