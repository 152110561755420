<template>
    <f-portal>
        <div
            :class="[
            'f-site-loader',
            (smallLoadStart ? '--small-start' : null),
            (smallLoadAnimateStart ? '--small-animate-start' : null),
            (bigLoadStart ? '--big-start' : null),
            (bigLoadAnimateStart ? '--big-animate-start' : null),
            (loadEnd ? '--end' : null),
            (animateEnd ? '--animate-end' : null),
        ]"
        >
            <div class="f-site-loader__progress"/>
        </div>
    </f-portal>
</template>

<script>
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import {SiteLoaderSizes} from './config';
import FPortal from '../../other/portal/FPortal';

export default {
    name: 'f-site-loader',

    components: {
        FPortal,
    },

    data() {
        return {
            loading: false,

            smallLoadStart: false,
            smallLoadAnimateStart: false,

            bigLoadStart: false,
            bigLoadAnimateStart: false,

            loadEnd: false,
            animateEnd: false,

            loadingCount: 0,
        }
    },

    created() {
        EventBus.$on(GlobalEvents.SITE_LOAD_START, (size) => this.onLoad(size));
        EventBus.$on(GlobalEvents.SITE_LOAD_FINISH, () => this.onLoadFinish());
    },

    methods: {
        onLoad(size) {
            this.loadingCount++;
            this.showLoad(size);
        },

        onLoadFinish() {
            if (this.loadingCount > 0) {
                this.loadingCount--;
            }
            if (this.loadingCount === 0) {
                this.stopLoad();
            }
        },

        showLoad(size) {
            if (this.loading) {
                return;
            }
            if (!size) {
                size = SiteLoaderSizes.SMALL;
            }

            this.loading = true;

            switch (size) {
                case SiteLoaderSizes.BIG:
                    this.bigLoadStart = true;
                    this.$nextTick(() => this.bigLoadAnimateStart = true);
                    break;

                case SiteLoaderSizes.SMALL:
                default:
                    this.smallLoadStart = true;
                    this.$nextTick(() => this.smallLoadAnimateStart = true);
                    break;
            }
        },

        stopLoad() {
            this.bigLoadStart = this.smallLoadStart = false;
            this.loadEnd = true;

            this.$nextTick(() => {
                this.smallLoadAnimateStart = this.bigLoadAnimateStart = false;
                this.animateEnd = true;

                window.setTimeout(() => {
                    this.loading = this.animateEnd = this.loadEnd = false;
                }, 500);
            });
        },
    },
}
</script>

<style lang="scss">
.f-site-loader {
    width: 100%;
    opacity: 0;
    height: 3px;
    left: 0;
    top: 0;
    position: fixed;
    transition: all .5s;
    z-index: $siteLoaderOrder;

    &__progress {
        width: 0;
        height: 100%;
        background: $info;
    }

    // Small
    &.--small-start {
        opacity: 1;

        .f-site-loader__progress {
            transition: width 1s;
        }
    }

    &.--small-animate-start {
        .f-site-loader__progress {
            width: 80%;
        }
    }

    // Big
    &.--big-start {
        opacity: 1;

        .f-site-loader__progress {
            transition: width 5s;
        }
    }

    &.--big-animate-start {
        .f-site-loader__progress {
            width: 80%;
        }
    }

    // All end
    &.--end {
        opacity: 0;

        .f-site-loader__progress {
            transition: width .5s;
        }
    }

    &.--animate-end {
        .f-site-loader__progress {
            width: 100%;
        }
    }
}
</style>
