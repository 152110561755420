import APIDataStore from '../services/APIDataStore';
import APIDataStoreResources from '../config/APIDataStoreResources';
import Store from '../services/Store';

export const getDisciplines = (data = null) => {
    if (!data) {
        data = Store.state.$departmentsAndDisciplinesCollection;
    }

    let disciplines = {};
    for (let i in data) {
        disciplines = {
            ...disciplines,
            ...parseDepartmentDisciplines(data[i]),
        };
    }

    return disciplines;
}

export const getDisciplineOptions = (data = null) => {
    return Object.values(getDisciplines(data)).map((discipline) => {
        return {
            key: discipline.id,
            value: discipline.name,
            parents: discipline.parent_departments,
        };
    });
}

export const getDepartments = (data = null) => {
    if (!data) {
        data = Store.state.$departmentsAndDisciplinesCollection;
    }

    let departments = {};
    for (let i in data) {
        departments[data[i].id] = data[i];
        // console.log(data[i].name, data[i].departments);

        departments = {
            ...departments,
            ...(data[i].departments ? getDepartments(data[i].departments) : {}),
        };
    }

    return departments;
}

export const parseDepartmentDisciplines = (department, parents = []) => {
    let disciplines = parseDisciplinesFromDepartment(department, parents);

    if (!department.departments) {
        return disciplines;
    }

    for (let i in department.departments) {
        disciplines = {
            ...disciplines,
            ...parseDepartmentDisciplines(department.departments[i], [...parents, department]),
        };
    }

    return disciplines;
}

export const parseDisciplinesFromDepartment = (department, grandParents) => {
    let disciplines = {};

    if (!department.disciplines) {
        return disciplines;
    }

    for (let i in department.disciplines) {
        const parentDepartments = [...grandParents, department];
        const fullName = parentDepartments.map((department) => department.name).join(' / ') + ` / ${department.disciplines[i].name}`;

        disciplines[department.disciplines[i].id] = {
            id: department.disciplines[i].id,
            name: department.disciplines[i].name,
            department: department,
            full_name: fullName,
            parent_departments: parentDepartments,
        };
    }

    return disciplines;
}

export default {
    created() {
        APIDataStore.check(APIDataStoreResources.DEPARTMENTS_AND_DISCIPLINES_COLLECTION);
    },
};
