<template>
    <f-form
        @submitValid="submit"
        class-name="faq-edit-create-form"
        :loading="loading"
        validate
    >
        <f-form-row label="In" class-name="faq-edit-create-form__parent">
            <span v-if="parentTitles.length === 0">
                Root
            </span>
            <span
                v-for="(parentTitle, i) in parentTitles"
                :key="i"
            >
                {{ parentTitle }}
            </span>
        </f-form-row>

        <f-form-row label="Title">
            <f-text-input
                v-model="fields.title"
                :validation="['required']"
                name="title"
            />
        </f-form-row>

        <f-form-row
            v-if="parentTitles.length !== 0"
            label="Body"
        >
            <f-editor
                v-model="fields.description"
                name="description"
                :toolbar="getEditorToolbar()"
                max-content-height="50vh"
            />
        </f-form-row>

        <f-form-row
            v-if="parentTitles.length !== 0"
            label="Search tags"
            help="Extra search tags, where the user can search on and can find the FAQ item better"
        >
            <f-custom-chips-input
                v-model="fields.tags"
                name="tags"
            />
        </f-form-row>

        <f-form-row
            v-if="parentTitles.length === 0"
            label="Show where"
            help="Show the FAQ category on these pages. A specific FAQ category could also be placed on the general FAQ page, so the employee can find it very easily"
        >
            <f-chips-input
                v-model="fields.places"
                name="show where"
                :options="{'faq-page': 'FAQ page', 'hr-page': 'HR page', 'it-page': 'IT page'}"
                popup-variant="light"
                :validation="['required']"
            />
        </f-form-row>

        <f-form-row
            label="Only show for office(s)"
            help="Only show the FAQ item for the employee that belongs to the selected office(s). If empty, it will be shown to all offices"
        >
            <f-chips-input
                v-model="fields.show_for_offices"
                name="tags"
                :options="officeOptionsFlat"
                popup-variant="light"
            />
        </f-form-row>

        <f-form-row
            label="Published"
        >
            <div style="display: flex; align-items: center;">
                <f-checkbox v-model="fields.visible"/>
                <label
                    class="f-form-row__label --no-select"
                    style="margin-left: 20px;"
                >
                    Hide for freelancers
                </label>
                <f-checkbox v-model="fields.hide_for_freelancers"/>
            </div>
        </f-form-row>

        <template #footer>
            <f-button
                :value="(mode === 'edit' ? 'Save' : 'Create')"
                type="submit"
                icon="floppy-disk"
                variant="primary"
                :disabled="loading"
            />
        </template>
    </f-form>
</template>

<script>
import FForm from '../../../f-components/form-controls/forms/FForm';
import FFormRow from '../../../f-components/form-controls/forms/FFormRow';
import FTextInput from '../../../f-components/form-controls/text/FTextInput';
import FButton from '../../../f-components/form-controls/buttons/FButton';
import OfficeOptionsMixin from '../../../mixins/OfficeOptionsMixin';
import EntityCreateEditMixin from '../../../mixins/entities/EntityCreateEditMixin';
import APIEndpoints from '../../../config/APIEndpoints';
import FEditor from '../../../f-components/form-controls/editor/FEditor';
import {EditorToolbar} from '../../../f-components/form-controls/editor/config';
import FCheckbox from '../../../f-components/form-controls/checkbox/FCheckbox';
import FCustomChipsInput from '../../../f-components/form-controls/chips/FCustomChipsInput';
import FChipsInput from '../../../f-components/form-controls/chips/FChipsInput';

export default {
    name: 'faq-edit-create-form',

    mixins: [
        OfficeOptionsMixin,
        EntityCreateEditMixin,
    ],

    components: {
        FChipsInput,
        FCustomChipsInput,
        FCheckbox,
        FEditor,
        FButton,
        FTextInput,
        FFormRow,
        FForm,
    },

    props: {
        parentTitles: Array,
        parentId: Number,
        id: Number,
        formMode: {
            type: String,
            required: true,
        },
        defaultPlaces: Array,
    },

    data() {
        return {
            loading: false,
            fields: {
                title: undefined,
                description: '',
                tags: [],
                places: (this.defaultPlaces ? this.defaultPlaces : []),
                visible: true,
                hide_for_freelancers: false,
                show_for_offices: [],
                show_for_departments: [],
            },
            mode: this.formMode,
            entityName: 'FAQ item',
        }
    },

    methods: {
        configureFormData(formData) {
            formData.append('parent_id', this.parentId);
            return formData;
        },
        getBaseUrl() {
            return APIEndpoints.FAQ;
        },
        getEditID() {
            return this.id;
        },
        getRedirectPath() {
            return null;
        },
        getEditorToolbar() {
            return [
                EditorToolbar.BOLD,
                EditorToolbar.ITALIC,
                EditorToolbar.LINK,
                EditorToolbar.STRIKE,
                EditorToolbar.UNDERLINE,
                EditorToolbar.BULLET_LIST,
                EditorToolbar.CODE,
                EditorToolbar.HARD_BREAK,
                EditorToolbar.IMAGE,
                EditorToolbar.TABLE,
                EditorToolbar.HISTORY,
                EditorToolbar.SOURCE,
            ];
        },
    },
};
</script>

<style lang="scss">
.faq-edit-create-form {
    .f-form-row__label {
        max-width: 170px;
    }

    .f-form__footer {
        padding-top: 10px;
    }

    &__parent .f-form-row__content {
        padding-top: 13px;

        span {
            display: inline-block;

            &:last-child:after {
                display: none;
            }

            &:after {
                font-family: "Linearicons";
                content: "\ec3c";
                font-size: 9px;
                opacity: .5;
                margin: 0 7px 0 4px;
            }
        }
    }
}
</style>
