<template>
    <span
        :class="['subtitle-spacer', '--no-select']"
        :style="{opacity: opacity}"
    >/</span>
</template>

<script>
export default {
    name: 'subtitle-spacer',

    props: {
        opacity: String,
    },
}
</script>

<style lang="scss">
.subtitle-spacer {
    position: relative;
    opacity: .25;
    font-weight: bold;
}
</style>
