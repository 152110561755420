<template>
    <div
        :class="[
            'user-card-mini',
             (selected ? '--selected' : null),
             (isSelectable && !disabled ? '--selectable' : null),
             (disabled ? '--disabled' : null),
             `--${variant}`,
             '--no-select',
         ]"
        @click.stop="onClick"
        v-tooltip="{text: tooltip}"
    >
        <div class="user-card-mini__image">
            <entity-avatar
                :name="user.name ? user.name : '?'"
                :src="user.profile_image"
                :birthday="hasBirthday"
                size="xxsmall"
            />
        </div>
        <div class="user-card-mini__body">
            {{ nameOverride ? nameOverride : user.name }}
        </div>
        <div
            v-if="includeDisciplines"
            class="user-card-mini__disciplines"
        >
            {{ user.title ? user.title : user.discipline_desc }}
            {{ user.type && user.type === 'freelancer' ? '(freelancer)' : '' }}
        </div>
        <div class="user-card-mini__slot" v-else>
            <slot/>
        </div>
    </div>
</template>

<script>
import EntityAvatar from '../../misc/entities/EntityAvatar';
import UserCardHelperMixin from '../../../mixins/UserCardHelperMixin';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import {UserCardCompactVariants, UserCardVariants} from './config';

export default {
    name: 'user-card-mini',

    components: {
        EntityAvatar,
    },

    mixins: [
        UserCardHelperMixin,
    ],

    props: {
        user: Object,
        className: String,
        nameOverride: String,
        type: {
            type: String,
            default: 'employees',
        },
        includeDisciplines: {
            type: Boolean,
            default: false,
        },
        disabled: Boolean,
        tooltip: String,
        forceUnselectable: Boolean,
        forceSelectable: Boolean,
        variant: {
            type: String,
            default: UserCardCompactVariants.LIGHT,
        },
        userCardPopupVariant: {
            type: String,
            default: UserCardVariants.DARK,
        },
    },

    computed: {
        isSelectable() {
            if (this.forceUnselectable || this.disabled) {
                return false;
            }
            return this.forceSelectable || !(!this.user || !this.user.title);
        },
    },

    data() {
        return {
            selected: false,
        }
    },

    mounted() {
        EventBus.$on(GlobalEvents.USER_CARD_HIDE, () => this.selected = false);
    },

    methods: {
        onClick() {
            if (!this.isSelectable || this.forceSelectable) {
                this.$emit('click');
                return;
            }

            EventBus.$emit(GlobalEvents.USER_CARD_HIDE);

            this.$nextTick(() => {
                EventBus.$emit(GlobalEvents.USER_CARD_SHOW, this.$el, this.user.name, {variant: this.userCardPopupVariant});
                this.selected = true;
            });
        },
    },
}
</script>

<style lang="scss">
.user-card-mini {
    border-radius: 4px;
    display: inline-flex;
    width: auto;
    height: 30px;
    border: 1px solid transparent;
    transition: border-color .1s, background-color .1s;
    align-items: center;
    padding-right: 10px;
    font-size: 11px;

    &, * {
        vertical-align: middle;
    }

    &__image {
        height: 100%;
        position: relative;
        background-size: cover;
        padding: 4px 7px 4px 10px;
    }

    &__body {
        text-align: left;
        white-space: nowrap;
        font-weight: 600;
    }

    &__disciplines, &__slot {
        padding: 0 0 1px 5px;
        white-space: nowrap;
        font-size: 9px;
        opacity: .75;
    }

    &.--selectable {
        cursor: pointer;
    }

    &.--disabled {
        background: transparent;
        opacity: .5;
    }

    // Variants

    &.--secondary {
        border-color: $secondary;
        color: $secondaryShade;

        &.--selectable {
            background: $secondary;
            color: $secondaryFront;
        }

        &.--selected, &.--selectable:hover {
            background: $secondaryShade;
            border-color: $secondaryShade;
            color: $secondaryFront;
        }
    }

    &.--success {
        border-color: $success;
        color: $successShade;

        &.--selectable {
            background: $success;
            color: $successFront;
        }

        &.--selected, &.--selectable:hover {
            background: $successShade;
            border-color: $successShade;
            color: $successFront;
        }
    }

    &.--warning {
        border-color: $warning;
        color: $warningShade;

        &.--selectable {
            background: $warning;
            color: $warningFront;
        }

        &.--selected, &.--selectable:hover {
            background: $warningShade;
            border-color: $warningShade;
            color: $warningFront;
        }
    }

    &.--info {
        border-color: $info;
        color: $infoShade;

        &.--selectable {
            background: $info;
            color: $infoFront;
        }

        &.--selected, &.--selectable:hover {
            background: $infoShade;
            border-color: $infoShade;
            color: $infoFront;
        }
    }

    &.--danger {
        border-color: $danger;
        color: $dangerShade;

        &.--selectable {
            background: $danger;
            color: $dangerFront;
        }

        &.--selected, &.--selectable:hover {
            background: $dangerShade;
            border-color: $dangerShade;
            color: $dangerFront;
        }
    }

    &.--primary {
        border-color: $primary;
        color: $primaryShade;

        &.--selectable {
            background: $primary;
            color: $primaryFront;
        }

        &.--selected, &.--selectable:hover {
            background: $primaryShade;
            border-color: $primaryShade;
            color: $primaryFront;
        }
    }

    &.--light {
        border-color: $softGray;

        .f-table__container.--default & {
            border-color: $quinaryGray;
        }

        &.--selectable {
            background: $softGray;
            color: $dark;

            .f-table__container.--default & {
                background: $quinaryGray;
            }
        }

        &.--selected, &.--selectable:hover {
            background: $primary;
            border-color: $primary;
            color: $primaryFront;

            .f-table__container.--default & {
                background: $dark;
                border-color: $dark;
                color: $darkFront;
            }
        }

        &.--disabled {
            background: transparent;

            .user-card-compact__wrapper {
                opacity: .5;
            }
        }
    }

    &.--dark {
        border-color: $quaternaryGray;
        color: $quaternaryGray;

        &.--selectable {
            background: $quaternaryGray;
            color: $light;
        }

        &.--selected, &.--selectable:hover {
            background: $primary;
            border-color: $primary;
            color: $primaryFront;
        }

        &.--disabled {
            color: $quaternaryGray;
            background: transparent;

            .user-card-compact__wrapper {
                opacity: .5;
            }
        }
    }
}
</style>
