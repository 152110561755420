<template>
    <f-abstract-input-field
        v-bind="boundProps"
        :class-name="[
            'f-chips-input',
            ...classNames,
            `--${mode}-mode`
        ]"
        :focussed="hasFocus"
        :has-content="!!value"
        :input-error="inputError"
        :show-error="showError"
        @click="focus"
        @reset="reset"
        hide-reset-when-empty
    >
        <template #pre>
            <f-icon
                v-if="preIcon"
                :icon="preIcon"
            />
        </template>

        <template v-slot:default>
            <button
                class="f-chips-input__focus"
                ref="input"
                type="button"
                :disabled="disabled || readonly"
                :tabindex="tabIndex"
                @focus="onFocus"
                @blur="onBlur"
            />

            <ul class="f-chips-input__chips">
                <li
                    v-if="!value || (Array.isArray(value) && value.length === 0)"
                    class="f-chips-input__placeholder --placeholder --no-select"
                >
                    {{ placeholder }}
                </li>
                <li
                    v-for="selectedTag in selectedTags"
                    :key="selectedTag.key"
                >
                    <f-chip variant="status">
                        {{ selectedTag.value }}
                    </f-chip>
                    <f-icon-button
                        v-if="mode === 'multi'"
                        icon="cross"
                        tabindex="-1"
                        @click.prevent="removeTag($event, selectedTag)"
                        v-tooltip="'Remove'"
                        size="xxsmall"
                        variant="primary"
                    />
                </li>
            </ul>
        </template>

        <template #post>
            <f-icon-button
                v-if="!readonly && !disabled"
                icon="chevron-down"
                outlined
                :variant="(inputError ? 'danger' : 'themed')"
                size="xsmall"
                class-name="f-dropdown__action"
                tabindex="-1"
            />
        </template>

        <template #after>
            <f-popup
                v-if="(popupOpen && !disabled && !readonly)"
                :target="$el"
                :class-name="`f-chips-input-popup --mode-${mode}`"
                @close="closePopup"
                padding="0"
                align="bottom"
                ref="popup"
                :variant="popupVariant"
            >
                <f-popup-list>
                    <f-popup-list-item
                        v-for="selectOption in selectOptions"
                        :key="selectOption.key"
                        @click="addTag(selectOption)"
                    >
                        {{ selectOption.value }}
                    </f-popup-list-item>
                </f-popup-list>
            </f-popup>
        </template>
    </f-abstract-input-field>
</template>

<script>
import EventBus from '../../../services/EventBus';
import FChip from '../../data/chip/FChip';
import FIconButton from '../buttons/FIconButton';
import FIcon from '../../layout/icons/FIcon';
import FAbstractInputField from '../base/FAbstractInputField';
import AbstractInputFieldPropsMixin from '../base/AbstractInputFieldPropsMixin';
import AbstractInputValidationMixin from '../base/AbstractInputValidationMixin';
import AbstractInputEventsMixin from '../base/AbstractInputEventsMixin';
import AbstractInputMixin from '../base/AbstractInputMixin';
import FPopup from '../../other/popup/FPopup';
import FPopupList from '../../other/popup/FPopupList';
import FPopupListItem from '../../other/popup/FPopupListItem';
import {GlobalEvents} from '../../../config/Events';

export default {
    name: 'f-chips-input',

    mixins: [
        AbstractInputFieldPropsMixin,
        AbstractInputValidationMixin,
        AbstractInputEventsMixin,
        AbstractInputMixin,
    ],

    components: {
        FPopupListItem,
        FPopupList,
        FPopup,
        FAbstractInputField,
        FIcon,
        FIconButton,
        FChip,
    },

    props: {
        options: {
            type: Object,
            required: true,
        },
        mode: {
            type: String,
            default: 'multi', // single or multi
        },
        preIcon: {
            type: String,
            default: 'tags'
        },
        popupVariant: String,
    },

    data() {
        return {
            popupOpen: false,
            skipPopupOpen: false,
            selectedIndex: null,
            searchQuery: '',
        }
    },

    computed: {
        selectOptions() {
            let selectOptions = [];

            for (let i in this.options) {
                selectOptions.push({
                    key: i,
                    value: this.options[i],
                });
            }

            return selectOptions;
        },

        selectedTags() {
            let selected = [];

            for (let i in this.value) {
                if (Object.prototype.hasOwnProperty.call(this.value, i) && this.options[this.value[i]]) {
                    selected.push({
                        key: this.value[i],
                        value: this.options[this.value[i]],
                    });
                }
            }

            return selected;
        },
    },

    created() {
        this.registerListeners();
    },

    methods: {
        registerListeners() {
            this.$on('focus', () => {
                if (!this.skipPopupOpen) {
                    this.openPopup();
                }
            });

            EventBus.$on('input.focus', (uid) => {
                if (this.uid !== uid && this.popupOpen) {
                    this.popupOpen = false;
                }
            });

            EventBus.$on(GlobalEvents.OVERFLOW_SCROLL, () => this.popupOpen = false);
        },

        position() {
            if (this.$refs.popup) {
                this.$refs.popup.position();
            }
        },

        openPopup() {
            this.popupOpen = true;

            if (this.searchable) {
                this.$nextTick(() => {
                    if (this.$refs.search) {
                        this.$refs.search.focus();
                    }
                });
            }

            document.addEventListener('keydown', this.onKeyDownEvent);
            document.addEventListener('keyup', this.onKeyUpEvent);
        },

        closePopup(refocus = false) {
            if (!this.popupOpen) {
                return;
            }

            this.popupOpen = false;
            this.searchQuery = '';
            this.selectedIndex = null;

            this.onBlur();

            if (refocus) {
                this.skipPopupOpen = true;
                this.focus();
                this.$nextTick(() => this.skipPopupOpen = false);
            }

            document.removeEventListener('keydown', this.onKeyDownEvent);
            document.removeEventListener('keyup', this.onKeyUpEvent);
        },

        getValue() {
            return this.value;
        },

        /**
         * Returns the selected tags.
         */
        getSelected() {
            return this.selectedTags;
        },

        /**
         * Returns the selected tags keys.
         */
        getSelectedKeys() {
            return this.selectedTags.map((option) => option.key);
        },

        reset(e) {
            if (e) {
                e.stopPropagation();
            }
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
            this.$emit('input', []);
        },

        /**
         * Add new tag.
         */
        addTag(tag) {
            if (!tag) {
                console.warn('Could not find tag', tag);
                return;
            }

            let selectedKeys = this.getSelectedKeys();

            if (this.mode === 'single') {
                selectedKeys = [tag.key];
                this.$emit('input', selectedKeys);
                this.resetValidation();
                return;
            }

            if (!selectedKeys.includes(tag.key)) {
                selectedKeys.push(tag.key);
                this.$emit('input', selectedKeys);
                this.resetValidation();
            }

            this.closePopup();
        },

        /**
         * Remove tag.
         */
        removeTag(e, removedTag) {
            e.stopPropagation();

            const selectedKeys = this.getSelectedKeys().filter((key) => removedTag.key !== key);

            this.$emit('input', selectedKeys);
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },

        getExcludedProps() {
            return [
                'placeholder',
                'options',
                'validation',
                'mode',
            ];
        },
    },
}
</script>

<style lang="scss">
.f-chips-input {
    .f-abstract-input-field__content {
        overflow: visible;
    }

    &__focus {
        position: absolute;
        opacity: 0;
    }

    &__chips {
        padding: 0 10px;

        li {
            position: relative;
            display: inline-block;
            cursor: default;
            padding-right: 5px;

            &:hover {
                .f-icon-button {
                    opacity: 1;
                }
            }

            .f-icon-button {
                top: -3px;
                right: 0;
                position: absolute;
                opacity: 0;
            }
        }
    }
}
</style>
