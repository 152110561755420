<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
    <page
        class-name="dashboard-page"
        :skewed-header-foreground="false"
        margin-top="0"
        skewed-footer-foreground="#f3f3f3"
    >
        <!--        <pronouns-modal-->
        <!--            v-if="pronounsModal"-->
        <!--            @close="pronounsModal = false"-->
        <!--        />-->

        <welcome-message :max-width="maxWidth"/>

        <f-container :max-width="maxWidth">
            <f-inline-message
                id="dashboardRivieraDead"
                class-name="dashboard-page__riviera-dead"
            >
                Because we are heading towards One Brand, and the switch to Microsoft Office 365, Riviera will be
                discontinued 😢. Riviera will still be accessible at this point, but in a stripped down version.
            </f-inline-message>
        </f-container>

        <skewed-section class-name="dashboard-page__timeline">
            <timeline :max-width="maxWidth"/>
        </skewed-section>

        <!--        <page-content-header :max-width="maxWidth">-->
        <!--            Make Riviera your<br>own <em>personal space</em>-->

        <!--            <template v-slot:post="postParams">-->
        <!--                <f-button-->
        <!--                    variant="primary"-->
        <!--                    icon="plus"-->
        <!--                    value="Personalize"-->
        <!--                    icon-position="left"-->
        <!--                    :size="(postParams.breakpoints.mobile ? 'small' : 'medium')"-->
        <!--                    @click="EventBus.$emit(GlobalEvents.PROFILE_OPEN)"-->
        <!--                />-->
        <!--            </template>-->
        <!--        </page-content-header>-->

        <div id="dashboardWidgets"/>

        <f-container
            class-name="dashboard-page__widget-container"
            :max-width="maxWidth"
        >
            <draggable
                class="column column-a"
                group="widgets"
                handle=".widget-drag"
                :list="columnA"
                @start="EventBus.$emit(GlobalEvents.TOOLTIP_HIDE)"
            >
                <component
                    v-if="availableWidgets.includes(widget.id)"
                    v-for="widget in columnA"
                    :is="widget.id"
                    :key="widget.id"
                />
            </draggable>
            <draggable
                class="column column-b"
                group="widgets"
                handle=".widget-drag"
                :list="columnB"
                @change="onWidgetListChange"
                @start="EventBus.$emit(GlobalEvents.TOOLTIP_HIDE)"
            >
                <component
                    v-if="availableWidgets.includes(widget.id)"
                    v-for="widget in columnB"
                    :is="widget.id"
                    :key="widget.id"
                />
            </draggable>
        </f-container>
    </page>
</template>

<script>
import draggable from 'vuedraggable';
import Page from '../Page';
import CalendarWidget from '../../widgets/calendar/CalendarWidget';
import MeetingRoomsWidget from '../../widgets/meeting-rooms/MeetingRoomsWidget';
import OfficeWeatherWidget from '../../widgets/office-and-weather/OfficeAndWeatherWidget';
import FavouriteProjectsWidget from '../../widgets/projects/FavouriteProjectsWidget';
import BirthdaysAndAnniversariesWidget from '../../widgets/birthdays-and-anniversaries/BirthdaysAndAnniversariesWidget';
import NewPeopleWidget from '../../widgets/new-people/NewPeopleWidget';
import PublicHolidaysWidget from '../../widgets/public-holidays/PublicHolidaysWidget';
import LeaveWidget from '../../widgets/leave/LeaveWidget';
import CurrentlyAtOfficeWidget from '../../widgets/currently-at-office/CurrentlyAtOfficeWidget';
import SkewedSection from '../../misc/skewed-section/SkewedSection';
import Store from '../../../services/Store';
import FContainer from '../../../f-components/layout/container/FContainer';
import FFlex from '../../../f-components/layout/flex/FFlex';
import FButton from '../../../f-components/form-controls/buttons/FButton';
import FScrollPane from '../../../f-components/other/scroll-pane/FScrollPane';
import FPlaceholderContainer from '../../../f-components/loaders/placeholder/FPlaceholderContainer';
import FPlaceholder from '../../../f-components/loaders/placeholder/FPlaceholder';
import Timeline from '../../misc/timeline/Timeline';
import PronounsModal from '../../misc/profile-settings-pane/components/PronounsModal';
import PageContentHeader from '../../misc/PageContentHeader';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import AbsenteesWidget from '../../widgets/absentees/AbsenteesWidget.vue';
import WelcomeMessage from './components/WelcomeMessage.vue';
import FInlineMessage from '../../../f-components/notice/inline-message/FInlineMessage.vue';

export default {
    name: 'dashboard-page',

    components: {
        FInlineMessage,
        WelcomeMessage,
        PronounsModal,
        AbsenteesWidget,
        PageContentHeader,
        Timeline,
        FPlaceholder,
        FPlaceholderContainer,
        FScrollPane,
        FButton,
        FFlex,
        FContainer,
        SkewedSection,
        Page,
        draggable,
        LeaveWidget,
        BirthdaysAndAnniversariesWidget,
        NewPeopleWidget,
        FavouriteProjectsWidget,
        OfficeWeatherWidget,
        MeetingRoomsWidget,
        CalendarWidget,
        PublicHolidaysWidget,
        CurrentlyAtOfficeWidget,
    },

    data() {
        const availableWidgets = [];
        //
        // availableWidgets.push('favourite-projects-widget');
        availableWidgets.push('office-weather-widget');
        // availableWidgets.push('calendar-widget');
        // availableWidgets.push('absentees-widget');
        // availableWidgets.push('birthdays-and-anniversaries-widget');
        // availableWidgets.push('new-people-widget');
        // availableWidgets.push('public-holidays-widget');
        //
        // if (['amsterdam'].includes(this.$store.state.$user.office)) {
        //     // availableWidgets.push('office-spot-widget');
        //     // availableWidgets.push('available-trafficers-widget');
        //     availableWidgets.push('leave-widget');
        //     availableWidgets.push('currently-at-office-widget');
        // }
        //
        // if (['amsterdam', 'austin'].includes(this.$store.state.$user.office)) {
        //     availableWidgets.push('meeting-rooms-widget');
        // }
        //
        // let positions = this.$store.getters.getUserSetting('widget.positions');
        // if (positions) {
        //     try {
        //         positions = JSON.parse(this.$store.getters.getUserSetting('widget.positions'));
        //
        //         if (positions) {
        //             // Check if all widgets are in there
        //             const existingWidgets = [];
        //             positions.forEach((column) => {
        //                 column.forEach((widget) => existingWidgets.push(widget.id));
        //             });
        //
        //             availableWidgets.forEach((id) => {
        //                 if (!existingWidgets.includes(id) && positions[0]) {
        //                     positions[0].unshift({id: id});
        //                 }
        //             });
        //         }
        //     } catch (error) {
        //         positions = null;
        //         console.warn(this.$store.getters.getUserSetting('widget.positions'), error);
        //     }
        // }
        let positions = [];
        // if (!positions || positions.length === 0) {
        positions = [
            [
                // {id: 'leave-widget'},
                // {id: 'favourite-projects-widget'},
                // {id: 'currently-at-office-widget'},
                {id: 'office-weather-widget'},
                // {id: 'new-people-widget'},
            ],
            [
                // {id: 'office-spot-widget'},
                // {id: 'available-trafficers-widget'},
                // {id: 'absentees-widget'},
                // {id: 'calendar-widget'},
                // {id: 'birthdays-and-anniversaries-widget'},
                // {id: 'announcements-widget'},
                // {id: 'meeting-rooms-widget'},
                // {id: 'public-holidays-widget'},
            ]
        ];
        // }

        return {
            // tutorialTimeout: undefined,
            columnA: positions[0],
            columnB: positions[1],
            availableWidgets,
            maxWidth: '1000px',
            // pronounsModal: !Store.state.$user.pronouns,
        }
    },

    mounted() {
        // this.showTutorial();
    },

    beforeDestroy() {
        // if (this.tutorialTimeout) {
        //     window.clearTimeout(this.tutorialTimeout);
        // }
    },

    methods: {
        onWidgetListChange() {
            // Store.commit('userSetting', {
            //     key: 'widget.positions',
            //     value: JSON.stringify([
            //         this.columnA,
            //         this.columnB,
            //     ]),
            // });
        },

        // showTutorial() {
        //     const hasBeenFinished = this.$store.getters.getUserSetting('tutorial.finished');
        //     const hasBeenSkipped = this.$store.getters.getUserSetting('tutorial.skipped');
        //     if (hasBeenFinished || hasBeenSkipped) return;
        //
        //     this.tutorialTimeout = window.setTimeout(() => {
        //         const hasBeenFinished = this.$store.getters.getUserSetting('tutorial.finished');
        //         const hasBeenSkipped = this.$store.getters.getUserSetting('tutorial.skipped');
        //
        //         if (hasBeenFinished || hasBeenSkipped) return;
        //         if (this.$route.fullPath === '/') {
        //             EventBus.$emit(GlobalEvents.TUTORIAL_OPEN);
        //         }
        //     }, 1000 * 5);
        // },
    },
}
</script>

<style lang="scss">
.dashboard-page {
    &__riviera-dead {
        margin-top: 40px;
    }

    &__timeline {
        padding: 80px 0 0;
        margin: 100px 0;

        @include media(tablet-down) {
            padding: 55px 0 0;
            margin: 60px 0;
        }

        @include media(mobile-down) {
            padding: 40px 0 0;
            margin: 60px 0;
        }
    }

    //&__widget-container {
    //    margin-top: 100px;
    //    display: flex;
    //    flex-flow: row;
    //    gap: 30px;
    //
    //    @include media(mobile-down) {
    //        margin-top: 40px;
    //    }
    //
    //    @media only screen and (max-width: 970px) {
    //        flex-flow: column;
    //    }
    //
    //    .column {
    //        flex: 1;
    //        width: 50%;
    //
    //        @media only screen and (max-width: 970px) {
    //            width: 100%;
    //            flex: none;
    //        }
    //    }
    //}
}
</style>
