import APIDataStore from "../../../services/APIDataStore";
import APIDataStoreResources from "../../../config/APIDataStoreResources";
import APIEndpoints from "../../../config/APIEndpoints";
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import LoadingMixin from '../../../mixins/LoadingMixin';
import {SiteLoaderSizes} from '../../../f-components/loaders/site-loader/config';

export default {
    mixins: [
        LoadingMixin,
    ],

    computed: {
        favourites() {
            const favourites = this.$store.getters.getUserSetting('project.favourites');

            if (favourites) {
                try {
                    return JSON.parse(favourites);
                } catch (error) {
                    return [];
                }
            }

            return [];
        },
    },

    methods: {
        toggleFavourite(id) {
            let favourites,
                favourited = true;

            if (this.favourites.includes(id)) {
                favourited = false;
                favourites = this.favourites.filter((_id) => _id !== id && _id !== `${id}`);
            } else {
                favourited = true;
                favourites = this.favourites;
                favourites.push(id);
            }

            this.$store.commit('userSetting', {
                key: 'project.favourites',
                value: JSON.stringify(favourites),
                onComplete: () => {
                    EventBus.$emit(GlobalEvents.PUSH_MESSAGE, (favourited ? 'Successfully added project to your favourites' : 'Successfully removed project to your favourites'));
                    APIDataStore.fetch(APIDataStoreResources.PROJECT_FAVOURITES);
                },
            });

            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },

        setArchived(ids, archived) {
            if (!Array.isArray(ids)) {
                ids = [ids];
            }

            this.startLoading();
            window.axios
                .post(`${APIEndpoints.PROJECTS}/bulk`, {
                    ids: ids,
                    is_archived: archived,
                })
                .then(() => {
                    this.endLoading();
                    EventBus.$emit(GlobalEvents.PUSH_MESSAGE, (archived ? 'Successfully archived project' : 'Removed project from archive'));
                    this.$emit('refresh');
                })
                .catch(() => this.endLoading());
        },

        forceUpdate(id, ignoreArchive = false) {
            this.startLoading(SiteLoaderSizes.BIG);
            window.axios
                .get(`${APIEndpoints.PROJECTS}/sync/${id}` + (ignoreArchive ? '?ignore_archive=1' : ''))
                .then(() => {
                    this.endLoading();
                    EventBus.$emit(GlobalEvents.PUSH_MESSAGE, 'Successfully synced project');
                    this.$emit('refresh');
                })
                .catch(() => this.endLoading());
        },

        unmerge(id) {
            this.startLoading();
            window.axios
                .post(APIEndpoints.parse(APIEndpoints.PROJECT_UNMERGE, {id}))
                .then(() => {
                    this.endLoading();
                    EventBus.$emit(GlobalEvents.PUSH_MESSAGE, 'Successfully unmerged projects');
                    this.$emit('refresh');
                })
                .catch(() => this.endLoading());
        },
    },
};
