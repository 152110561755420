<template>
    <div :class="['page-footer']">
        <div class="page-footer__pre">
            <bullet-point text-type="header">
                Are you looking for<br>
                files? Go straight to our<br>
                <a href="https://hyspot-my.sharepoint.com/" target="_blank" rel="noopener noreferrer">
                    OneDrive</a>.
            </bullet-point>
        </div>
        <div class="page-footer__content">
            <div class="page-footer__content__rights">
                © All rights reserved
            </div>
            <logo/>
        </div>
    </div>
</template>

<script>
import Logo from '../misc/Logo';
import BulletPoint from '../misc/BulletPoint';

export default {
    name: 'page-footer',

    components: {
        BulletPoint,
        Logo,
    },
}
</script>

<style lang="scss">
.page-footer {
    background: $dark;
    color: $darkFront;
    padding: 0 50px 40px;
    position: relative;

    &__pre {
        padding: 200px 100px 150px 0;
        display: flex;
        justify-content: flex-end;

        .bullet-point {
            font-size: 35px;
            text-indent: 60px;
            line-height: 120%;

            a, a:visited, a:active {
                color: $ctaOrange;
            }
        }

        @include media(tablet-down) {
            padding: 125px 50px 75px 0;

            .bullet-point {
                font-size: 26px;
            }
        }

        @include media(mobile-down) {
            padding: 100px 25px 50px 0;

            .bullet-point {
                font-size: 20px;
            }
        }
    }

    &__content {
        font-size: 12px;
        position: relative;
        z-index: 3;
        display: flex;
        border-top: 1px solid rgba($secondaryGray, .5);
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-top: 40px;

        &__rights {
            color: $secondaryGray;
        }

        .logo {
            width: 30px;
            height: 30px;

            path {
                fill: $light;
            }
        }
    }
}
</style>
