<template>
    <f-abstract-input-field
        v-bind="boundProps"
        :class-name="[
            'f-custom-chips-input',
            ...classNames,
        ]"
        :focussed="hasFocus"
        :has-content="!!value"
        :input-error="inputError"
        :show-error="showError"
        @click="focus"
        @reset="reset"
    >
        <template #pre>
            <f-icon
                v-if="preIcon"
                :icon="preIcon"
            />
        </template>

        <template v-slot:default>
            <ul class="f-custom-chips-input__chips">
                <li
                    v-for="(tag, i) in value"
                    :key="i"
                >
                    <f-chip variant="status">
                        {{ tag }}
                    </f-chip>
                    <f-icon-button
                        icon="cross"
                        tabindex="-1"
                        @click.prevent="removeTag(tag)"
                        v-tooltip="'Remove'"
                        size="xxsmall"
                        :variant="(inputError ? 'danger' : 'themed')"
                    />
                </li>
                <li v-if="!readonly">
                    <input
                        type="text"
                        ref="input"
                        :placeholder="(value && value.length === 0 ? placeholder : null)"
                        @focus="onFocus"
                        @blur="onInputBlur"
                        @keydown="onInputKeyDown"
                    />
                </li>
            </ul>
        </template>

        <template #post>
            <f-icon
                v-if="postIcon"
                :icon="postIcon"
            />
        </template>
    </f-abstract-input-field>
</template>

<script>
import EventBus from '../../../services/EventBus';
import FChip from '../../data/chip/FChip';
import FAbstractInputField from '../base/FAbstractInputField';
import FIcon from '../../layout/icons/FIcon';
import AbstractInputFieldPropsMixin from '../base/AbstractInputFieldPropsMixin';
import AbstractInputValidationMixin from '../base/AbstractInputValidationMixin';
import AbstractInputEventsMixin from '../base/AbstractInputEventsMixin';
import AbstractInputMixin from '../base/AbstractInputMixin';
import FIconButton from '../buttons/FIconButton';
import {GlobalEvents} from '../../../config/Events';

export default {
    name: 'f-custom-chips-input',

    components: {
        FIconButton,
        FIcon,
        FAbstractInputField,
        FChip,
    },

    mixins: [
        AbstractInputFieldPropsMixin,
        AbstractInputValidationMixin,
        AbstractInputEventsMixin,
        AbstractInputMixin,
    ],

    props: {
        preIcon: {
            type: String,
            default: 'tags'
        },
    },

    methods: {
        getExcludedProps() {
            return [
                'placeholder',
                'options',
                'validation',
                'mode',
            ];
        },

        getValue() {
            return this.value;
        },

        onInputBlur() {
            if (this.$refs.input.value) {
                this.addTag(this.$refs.input.value);
            }
            this.onBlur();
        },

        onInputKeyDown(e) {
            if ([188, 13].includes(e.keyCode) && this.$refs.input.value) {
                this.addTag(this.$refs.input.value);
                e.preventDefault();
            } else if (e.keyCode === 8 && this.value && this.value.length > 0 && this.$refs.input.value === '') {
                this.removeTag(this.value[this.value.length - 1]);
            } else if (e.keyCode === 188) {
                e.preventDefault();
            }
        },

        reset(e) {
            if (e) e.stopPropagation();

            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
            this.$emit('input', []);
        },

        addTag(tag) {
            this.$refs.input.value = '';

            if (!tag) return;

            let tags = this.value;
            if (tags && !tags.includes(tag)) {
                tags.push(tag);
                this.$emit('input', tags);
                this.resetValidation();
            }
        },

        removeTag(removedTag) {
            const tags = this.value.filter((tag) => {
                return removedTag !== tag;
            }).filter((tag) => {
                return tag;
            });

            this.$emit('input', tags);
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },
    },
}
</script>

<style lang="scss">
.f-custom-chips-input {
    position: relative;

    .f-abstract-input-field__content {
        overflow: visible;
    }

    input {
        width: 160px;
        height: 100%;
        cursor: default;
        background: transparent;
        color: white;
        padding: 5px 0;
    }

    &__chips {
        padding: 0 10px;

        li {
            position: relative;
            display: inline-block;
            cursor: default;
            vertical-align: middle;
            padding-right: 5px;

            &:hover {
                .f-icon-button {
                    opacity: 1;
                }
            }

            .f-icon-button {
                top: -3px;
                right: 0;
                position: absolute;
                opacity: 0;
            }
        }
    }
}
</style>
