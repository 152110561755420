<template>
    <f-scroll-pane mode="horizontal">
        <f-flex-table
            class-name="invoices-list"
            row-padding="small"
        >
            <f-flex-table-row
                v-for="invoice in report.invoices"
                :key="invoice.number"
                collapsible
                no-left-padding
            >
                <f-flex-table-cell>
                    <f-chip size="small" variant="dark">
                        {{ invoice.number }}
                    </f-chip>
                    {{ invoice.company }}
                    <f-icon-button
                        v-if="invoice.pdf_url"
                        :href="invoice.pdf_url"
                        tag="a"
                        icon="launch"
                        target="_blank"
                        v-tooltip="'View invoice as PDF'"
                        size="xsmall"
                        variant="primary"
                    />
                </f-flex-table-cell>
                <f-flex-table-cell align="right" flex="0 0 100px">
                    {{ formatPrice(invoice.total, false) }}
                </f-flex-table-cell>

                <template #content>
                    <div v-if="invoice.description" class="invoices-list__description">
                        {{ invoice.description }}
                    </div>

                    <f-flex-table
                        tree-render
                        :level="2"
                    >
                        <f-flex-table-row
                            v-for="line in invoice.lines"
                            :key="line.id"
                            no-content-indention
                            no-left-padding
                        >
                            <f-flex-table-cell>
                                {{ line.product }}
                                <f-chip v-if="line.part_id" size="small" variant="dark">
                                    {{ line.part_id }}
                                </f-chip>
                                <div class="invoices-list__line-description">
                                    {{ line.description }}
                                </div>
                            </f-flex-table-cell>
                            <f-flex-table-cell
                                class-name="invoices-list__line-description"
                                align="right"
                                flex="0 0 120px"
                            >
                                {{ line.quantity }} × {{ formatPrice(line.unit_price, false) }}
                            </f-flex-table-cell>
                            <f-flex-table-cell align="right" flex="0 0 120px">
                                {{ formatPrice(line.quantity * line.unit_price, false) }}
                            </f-flex-table-cell>
                        </f-flex-table-row>
                    </f-flex-table>
                </template>
            </f-flex-table-row>
        </f-flex-table>
    </f-scroll-pane>
</template>

<script>
import NumbersAndBudgetMixin from '../../../mixins/NumbersAndBudgetMixin';
import FFlexTable from '../../../f-components/data/flex-table/FFlexTable';
import FFlexTableRow from '../../../f-components/data/flex-table/FFlexTableRow';
import FChip from '../../../f-components/data/chip/FChip';
import FFlexTableCell from '../../../f-components/data/flex-table/FFlexTableCell';
import FIconButton from '../../../f-components/form-controls/buttons/FIconButton';
import FScrollPane from '../../../f-components/other/scroll-pane/FScrollPane';

export default {
    name: 'invoices-list',

    components: {
        FScrollPane,
        FIconButton,
        FFlexTableCell,
        FChip,
        FFlexTableRow,
        FFlexTable,
    },

    mixins: [
        NumbersAndBudgetMixin,
    ],

    props: {
        report: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style lang="scss">
.invoices-list {
    min-width: 400px;

    &__description, &__line-description {
        font-size: 95%;
        color: rgba(white, .5);
    }

    .f-chip {
        margin: 0 5px 0;
    }

    .f-icon-button {
        margin-left: 5px;
    }

    &__description {
        position: relative;
        padding: 5px 130px 20px 0;
        z-index: 1;
        opacity: 1;
        background: $darkAlternative;
    }
}
</style>
