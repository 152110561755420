<template>
    <f-abstract-input-field
        v-bind="boundProps"
        :class-name="[
            'f-editor',
            'editor',
        ]"
        :focussed="(editor ? editor.isFocused : false)"
        :input-error="inputError"
        :show-error="(editor ? editor.isFocused : false)"
    >
        <f-editor-toolbar
            v-if="!readonly"
            :editor="editor"
            :toolbar="toolbar"
            :mock="mock"
            @source-toggle="showSource = $event"
        />

        <f-scroll-pane
            v-if="maxContentHeight && !readonly"
            :max-height="maxContentHeight"
        >
            <editor-content
                v-if="!showSource"
                :editor="editor"
            />
            <textarea
                v-else
                class="f-editor__source"
                v-model="sourceValue"
                ref="sourceTextarea"
                @input="setSourceSize"
            />
        </f-scroll-pane>

        <editor-content
            v-else-if="!readonly && !showSource"
            :editor="editor"
        />
        <textarea
            v-if="!maxContentHeight && !readonly && showSource"
            class="f-editor__source"
            v-model="sourceValue"
            ref="sourceTextarea"
            @input="setSourceSize"
        />

        <f-content
            v-if="readonly"
            v-html="value"
            class="ProseMirror --read-only"
        />
    </f-abstract-input-field>
</template>

<script>
import StarterKit from '@tiptap/starter-kit';
import {Editor, EditorContent} from '@tiptap/vue-2';
import {EditorToolbar} from './config';
import FEditorToolbar from './components/FEditorToolbar';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Underline from '@tiptap/extension-underline';
import FAbstractInputField from '../base/FAbstractInputField';
import FContent from '../../layout/content/FContent';
import AbstractInputValidationMixin from '../base/AbstractInputValidationMixin';
import AbstractInputPropsMixin from '../base/AbstractInputPropsMixin';
import AbstractInputMixin from '../base/AbstractInputMixin';
import FScrollPane from '../../other/scroll-pane/FScrollPane';
import {Table} from '@tiptap/extension-table';
import {TableHeader} from '@tiptap/extension-table-header';
import {TableCell} from '@tiptap/extension-table-cell';
import {TableRow} from '@tiptap/extension-table-row';

export default {
    name: 'f-editor',

    components: {
        FScrollPane,
        FContent,
        FAbstractInputField,
        FEditorToolbar,
        EditorContent,
    },

    mixins: [
        AbstractInputPropsMixin,
        AbstractInputValidationMixin,
        AbstractInputMixin,
    ],

    props: {
        value: String,
        toolbar: {
            type: Array,
            default: () => {
                return [
                    EditorToolbar.BOLD,
                    EditorToolbar.ITALIC,
                    EditorToolbar.STRIKE,
                    EditorToolbar.LINK,
                    EditorToolbar.HISTORY,
                ];
            }
        },
        mock: Boolean,
        maxContentHeight: String,
    },

    computed: {
        sourceValue: {
            get() {
                return this.value;
            },
            set(src) {
                this.$emit('input', src);
                this.editor.commands.setContent(src, false);
            },
        }
    },

    watch: {
        value(value) {
            const isSame = this.editor.getHTML() === value;
            if (isSame) return;
            this.editor.commands.setContent(value, false);
        },
        showSource(showSource) {
            if (showSource) this.setSourceSize();
        },
    },

    data() {
        return {
            editor: null,
            showSource: false,
        }
    },

    mounted() {
        const headings = [];
        if (this.toolbar.includes(EditorToolbar.H1)) headings.push(1);
        if (this.toolbar.includes(EditorToolbar.H2)) headings.push(2);
        if (this.toolbar.includes(EditorToolbar.H2)) headings.push(3);

        const extensions = [
            StarterKit.configure({
                gapcursor: {},
                dropcursor: (this.toolbar.includes(EditorToolbar.IMAGE) ? {} : false),
                blockquote: (this.toolbar.includes(EditorToolbar.BLOCKQUOTE) ? {} : false),
                bold: (this.toolbar.includes(EditorToolbar.BOLD) ? {} : false),
                bulletList: (this.toolbar.includes(EditorToolbar.BULLET_LIST) ? {} : false),
                code: (this.toolbar.includes(EditorToolbar.CODE) ? {} : false),
                codeBlock: (this.toolbar.includes(EditorToolbar.CODE_BLOCK) ? {} : false),
                hardBreak: (this.toolbar.includes(EditorToolbar.HARD_BREAK) ? {} : false),
                heading: (headings.length > 0 ? {levels: headings} : false),
                history: (this.toolbar.includes(EditorToolbar.HISTORY) ? {} : false),
                horizontalRule: (this.toolbar.includes(EditorToolbar.HORIZONTAL_RULE) ? {} : false),
                italic: (this.toolbar.includes(EditorToolbar.ITALIC) ? {} : false),
                orderedList: (this.toolbar.includes(EditorToolbar.ORDERED_LIST) ? {} : false),
                strike: (this.toolbar.includes(EditorToolbar.STRIKE) ? {} : false),
            }),
        ];

        // There is a bug in text-align
        // if (this.toolbar.includes(EditorToolbar.TEXT_ALIGN)) {
        //     extensions.push(TextAlign.configure({
        //         types: ['heading', 'paragraph'],
        //         defaultAlignment: 'left',
        //     })); // https://www.tiptap.dev/api/extensions/text-align/
        // }

        if (this.toolbar.includes(EditorToolbar.LINK)) {
            extensions.push(Link.configure({
                openOnClick: false,
            })); // https://www.tiptap.dev/api/marks/link/
        }
        if (this.toolbar.includes(EditorToolbar.IMAGE)) {
            extensions.push(Image); // https://tiptap.dev/api/nodes/image
        }
        if (this.toolbar.includes(EditorToolbar.UNDERLINE)) {
            extensions.push(Underline); // https://tiptap.dev/api/marks/underline
        }
        if (this.toolbar.includes(EditorToolbar.TABLE)) {
            extensions.push(Table);
            extensions.push(TableHeader);
            extensions.push(TableCell);
            extensions.push(TableRow);
        }

        this.editor = new Editor({
            extensions: extensions,
            content: this.value,
            onUpdate: () => this.$emit('input', this.editor.getHTML()),
        });
    },

    beforeDestroy() {
        if (this.editor) {
            this.editor.destroy();
        }
    },

    methods: {
        getExcludedProps() {
            return [
                'toolbar',
                'validation',
            ];
        },

        getValue() {
            const value = this.editor.getHTML();
            return (value === '<p></p>' ? null : value);
        },

        setSourceSize() {
            this.$nextTick(() => {
                this.$refs.sourceTextarea.style.height = 'auto';
                this.$nextTick(() => this.$refs.sourceTextarea.style.height = `${this.$refs.sourceTextarea.scrollHeight}px`);
            });
        },
    },
}
</script>

<style lang="scss">
.f-editor {
    margin-bottom: 10px;
    text-align: left;

    &__source {
        width: calc(100% - 30px);
        height: auto;
        resize: none;
        margin: 15px;
        padding: 0;
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
        font-size: 12px;

        @include theme(light) {
            color: $quaternaryGray;
        }

        @include theme(dark) {
            color: $softGray;
        }
    }

    .ProseMirror {
        overflow: hidden;
        font-weight: 200;
        padding: 15px;

        &.resize-cursor {
            cursor: ew-resize;
            cursor: col-resize;
        }

        img {
            &.ProseMirror-selectednode {
                outline: 3px solid #68CEF8;
            }
        }

        //    a {
        //        word-break: break-word;
        //    }
        //
        //    .tableWrapper {
        //        padding: 1rem 0;
        //        overflow-x: auto;
        //        text-align: center;
        //    }
        //
        //    table {
        //        border: 0;
        //        border-spacing: 1px;
        //        border-collapse: separate;
        //        max-width: 100%;
        //        display: inline-block;
        //        text-align: left;
        //
        //        td, th {
        //            position: relative;
        //
        //            min-width: 1em;
        //            border: 1px solid #ced4da;
        //            padding: 6px 8px;
        //            vertical-align: top;
        //            box-sizing: border-box;
        //
        //            > * {
        //                margin-bottom: 0;
        //            }
        //        }
        //
        //        th {
        //            background: rgba(black, .1);
        //
        //            &.selectedCell {
        //                background-color: rgba($primary, .3);
        //            }
        //        }
        //
        //        .column-resize-handle {
        //            position: absolute;
        //            right: -2px;
        //            top: 0;
        //            bottom: -2px;
        //            width: 4px;
        //            background-color: #adf;
        //            pointer-events: none;
        //        }
        //
        //        .selectedCell {
        //            background-color: rgba($primary, .2);
        //
        //            &:after {
        //                z-index: 2;
        //                position: absolute;
        //                content: "";
        //                left: 0;
        //                right: 0;
        //                top: 0;
        //                bottom: 0;
        //                background: rgba(200, 200, 255, .4);
        //                pointer-events: none;
        //            }
        //        }
        //    }
        //
        //    code {
        //        padding: 5px;
        //        font-size: 80%;
        //    }
        //
        //    pre {
        //        > code {
        //            width: 100%;
        //            display: block;
        //        }
        //    }
        //
        //    h1 {
        //        font-weight: bold;
        //        font-size: 20px;
        //    }
        //
        //    h2 {
        //        font-weight: bold;
        //        font-size: 17px;
        //    }
        //
        //    h3 {
        //        font-weight: bold;
        //        font-size: 14px;
        //    }
    }
}
</style>
