<template>
    <f-abstract-input
        v-bind="boundProps"
        :class-name="[
            'f-switch',
            ...classNames,
        ]"
        :focussed="hasFocus"
        :input-error="inputError"
        show-error
    >
        <input
            type="checkbox"
            value="1"
            ref="input"
            :name="uid"
            :checked="value"
            :id="uid"
            :disabled="disabled"
            :tabindex="tabIndex"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
        />
        <label :for="uid"/>
        <span
            v-if="readonly"
            v-text="(value ? 'Yes' : 'No')"
        />
    </f-abstract-input>
</template>

<script>
import FAbstractInput from '../base/FAbstractInput';
import AbstractInputPropsMixin from '../base/AbstractInputPropsMixin';
import AbstractInputValidationMixin from '../base/AbstractInputValidationMixin';
import AbstractInputEventsMixin from '../base/AbstractInputEventsMixin';
import AbstractInputMixin from '../base/AbstractInputMixin';

export default {
    name: 'f-switch',

    components: {
        FAbstractInput,
    },

    mixins: [
        AbstractInputPropsMixin,
        AbstractInputValidationMixin,
        AbstractInputEventsMixin,
        AbstractInputMixin,
    ],

    methods: {
        getValue() {
            return this.isChecked();
        },

        onChange() {
            this.$emit('input', this.$refs.input.checked);
            this.$emit('change', this.$refs.input.checked);
        },

        isChecked() {
            return this.$refs.input.checked;
        },
    }
}
</script>

<style lang="scss">
$diameter: 20px;
$height: 16px;
$overflow: (($diameter - $height) * .5);

.f-switch {
    position: relative;
    width: 40px;
    height: $height;

    input[type=checkbox] {
        opacity: 0;
    }

    // Focussed
    &.--focus {
        label {
            @include theme(dark) {
                box-shadow: 0 0 0 2px rgba($light, .2);
            }

            @include theme(light) {
                box-shadow: 0 0 0 2px rgba($dark, .2);
            }
        }
    }

    label {
        position: absolute;
        width: 100%;
        height: $height;
        border-radius: $diameter * .5;
        overflow: visible !important;
        left: 0;
        top: 0;

        &:before {
            transition: all .1s ease-in-out;
            content: '';
            position: absolute;
            width: $diameter;
            height: $diameter;
            border-radius: 100%;
            background: #d3d3d3;
            top: -$overflow;
            left: -$overflow;
        }

        @include theme(dark) {
            background: $quaternaryGray;
        }

        @include theme(light) {
            background: $dark;
        }
    }

    input[type=checkbox]:checked + label:before {
        left: calc(100% - #{$diameter} + #{$overflow});
        background: $primary;
    }

    input[type=checkbox]:not(.--locked):focus + label,
    input[type=checkbox]:not(.--locked):hover + label,
    input[type=checkbox]:not(.--locked):active + label,
    &:hover input[type=checkbox]:not(.--locked) + label {
        box-shadow: 0 0 0 2px rgba(white, .2);
        outline: 0;
    }

    &.--error {
        label:before {
            background: $danger;
        }
    }
}
</style>
