<template>
    <f-scroll-pane
        class-name="f-month-filter__wrapper"
        mode="horizontal"
        hide-scroll-bar
        no-scroll-padding
    >
        <ul
            v-if="months.length > 1"
            :class="['f-month-filter']"
        >
            <li :class="['f-month-filter__fill']"/>
            <li
                v-for="month in months"
                :key="month.id"
                :class="[
                    'f-month-filter__month',
                    (month.selected ? '--selected' : undefined),
                    (month.selectedFirst ? '--first-selected' : undefined),
                    (month.selectedLast ? '--last-selected' : undefined),
                ]"
                @click="toggleMonth(month)"
            >
                <!--v-tooltip="(month.selected ? 'Remove from range' : 'Add to range')"-->
                <div :class="['f-month-filter__month__content']">
                    <div :class="['f-month-filter__month__label']">
                        {{ month.label }}
                    </div>
                    <div :class="['f-month-filter__month__year']">
                        {{ month.year }}
                    </div>
                    <div :class="['f-month-filter__month__range']">
                        {{ month.start.format('Do') }}
                        &mdash;
                        {{ month.end.format('Do') }}
                    </div>
                </div>
            </li>
            <li :class="['f-month-filter__fill']"/>
        </ul>
    </f-scroll-pane>
</template>

<script>
import moment from 'moment';
import FScrollPane from '../../other/scroll-pane/FScrollPane';
import {GlobalEvents} from '../../../config/Events';
import EventBus from '../../../services/EventBus';

export default {
    name: 'f-month-filter',

    components: {
        FScrollPane,
    },

    props: {
        startDate: {
            type: String,
            required: true,
        },
        endDate: {
            type: String,
            required: true,
        },
    },

    computed: {
        startDateParsed() {
            return moment(this.startDate).startOf('day');
        },
        endDateParsed() {
            return moment(this.endDate).startOf('day');
        },
        months() {
            if (this.startDateParsed.format(this.compareFormat) === this.endDateParsed.format(this.compareFormat)) {
                return [];
            }

            const months = [];
            const iDate = this.startDateParsed.clone();

            while (this.endDateParsed > iDate) {
                const monthStart = (this.startDateParsed.format(this.compareFormat) === iDate.format(this.compareFormat) ? this.startDateParsed.clone() : iDate.clone().startOf('month')),
                    monthEnd = (this.endDateParsed.format(this.compareFormat) === iDate.format(this.compareFormat) ? this.endDateParsed.clone() : iDate.clone().endOf('month')),
                    id = monthStart.format(this.compareFormat);

                let selected = false;
                if (this.selectedStartMonth) {
                    selected = monthStart >= this.selectedStartMonth.start;
                    if (monthEnd > this.selectedEndMonth.end) {
                        selected = false;
                    }
                }

                months.push({
                    id: id,
                    label: iDate.format('MMMM'),
                    year: iDate.format('YYYY'),
                    start: monthStart,
                    end: monthEnd,
                    selected,
                    selectedFirst: this.selectedStartMonth && monthStart.format(this.compareFormat) === this.selectedStartMonth.start.format(this.compareFormat),
                    selectedLast: this.selectedEndMonth && monthEnd.format(this.compareFormat) === this.selectedEndMonth.end.format(this.compareFormat),
                });

                iDate.startOf('month').add(1, 'month');
            }

            return months;
        },
    },

    watch: {
        selectedStartMonth() {
            this.onRangeChange();
        },
        selectedEndMonth() {
            this.onRangeChange();
        },
    },

    data() {
        return {
            selectedStartMonth: undefined,
            selectedEndMonth: undefined,
            compareFormat: 'M-Y',
        }
    },

    methods: {
        reset() {
            this.selectedStartMonth = this.selectedEndMonth = undefined;
        },

        onRangeChange() {
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
            this.$emit(
                'input',
                this.selectedStartMonth ? [this.selectedStartMonth.start.format('YYYY-MM-DD'), this.selectedEndMonth.end.format('YYYY-MM-DD')] : null
            );
        },

        toggleMonth(month) {
            if (!this.selectedEndMonth && !this.selectedStartMonth) {
                this.selectedStartMonth = this.selectedEndMonth = month;
                return;
            }

            if (month.start.format(this.compareFormat) === this.selectedStartMonth.start.format(this.compareFormat)) {
                if (month.end.format(this.compareFormat) === this.selectedEndMonth.end.format(this.compareFormat)) {
                    return this.reset();
                }
                this.selectedStartMonth = this.getNextMonth(month);
                return;
            }

            if (month.end.format(this.compareFormat) === this.selectedEndMonth.end.format(this.compareFormat)) {
                if (month.start.format(this.compareFormat) === this.selectedStartMonth.start.format(this.compareFormat)) {
                    return this.reset();
                }
                this.selectedEndMonth = this.getPrevMonth(month);
                return;
            }

            if (month.start < this.selectedStartMonth.start) {
                this.selectedStartMonth = month;
                return;
            }

            if (month.end > this.selectedEndMonth.end) {
                this.selectedEndMonth = month;
                return;
            }

            this.selectedEndMonth = month;
        },

        getNextMonth(month) {
            let getNext = false;
            for (let i in this.months) {
                if (getNext) return this.months[i];
                if (this.months[i].id === month.id) getNext = true;
            }
        },

        getPrevMonth(month) {
            let previous = undefined;
            for (let i in this.months) {
                if (this.months[i].id === month.id && previous) return previous;
                previous = this.months[i];
            }
        },
    },
}
</script>

<style lang="scss">
$borderRadius: 5px;
$borderRadiusInner: 3px;
$defaultBorderRadius: 2px;
$selectionColor: $ctaOrange;

.f-month-filter {
    display: flex;
    align-items: center;

    &__fill, &__month__content {
        width: 110px;
        height: 70px;
    }

    &__fill {
        min-width: 20px;
        flex-grow: 1;
        margin: 2px 0 2px 2px;
        background-color: darken($softGray, 4);
        height: 50px;

        &:first-child {
            border-radius: $defaultBorderRadius;
        }

        &:last-child {
            border-radius: $defaultBorderRadius;
        }
    }

    &__month {
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        &__content {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 2px;
            user-select: none;
            margin: 2px 0 2px 2px;
            transition: background-color .1s, color .1s;
            border-radius: $defaultBorderRadius;
            background-color: darken($softGray, 10);
            color: $dark;
        }

        &:hover {
            .f-month-filter__month__content {
                background-color: darken($softGray, 20);
            }
        }

        &__label {
            font-weight: 600;
        }

        &__year {
            font-size: 90%;
            opacity: .65;
        }

        &__range {
            font-size: 80%;
            opacity: .5;
        }

        &.--selected {
            border: 1px solid $selectionColor;

            .f-month-filter__month__content {
                background-color: lighten($quaternaryGray, 10);
                color: $light;
            }
        }

        &.--selected.--first-selected.--last-selected {
            border-width: 1px !important;
            border-radius: $borderRadius !important;
        }

        &.--selected:not(.--first-selected):not(.--last-selected) {
            border-right-width: 0;
            border-left-width: 0;
            border-radius: 0;
        }

        &.--first-selected {
            border-right-width: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            margin-left: 2px;

            .f-month-filter__month__content {
                border-bottom-left-radius: $borderRadiusInner;
                border-top-left-radius: $borderRadiusInner;
            }

            &:before {
                left: -2px;
            }
        }

        &.--last-selected {
            border-left-width: 0;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;

            .f-month-filter__month__content {
                margin-right: 2px;
                border-bottom-right-radius: $borderRadiusInner;
                border-top-right-radius: $borderRadiusInner;
            }

            &:after {
                right: -2px;
            }
        }

        &.--first-selected:before, &.--last-selected:after {
            height: 50%;
            content: '';
            position: absolute;
            background: $selectionColor;
            border-radius: 1px;
            width: 3px;
            top: 25%;
        }
    }
}
</style>
