var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{class:['page-header-navigation']},[(
            _vm.hasPermission(_vm.Permissions.ADMIN_USERS_VIEW) ||
            _vm.hasPermission(_vm.Permissions.ADMIN_CLIENTS_VIEW) ||
            _vm.hasPermission(_vm.Permissions.ADMIN_PRODUCTS_VIEW) ||
            _vm.hasPermission(_vm.Permissions.ADMIN_GALLERIES) ||
            _vm.hasPermission(_vm.Permissions.ADMIN_ANNOUNCEMENTS) ||
            _vm.hasPermission(_vm.Permissions.ADMIN_DISCIPLINES_VIEW) ||
            _vm.hasPermission(_vm.Permissions.ADMIN_MISC_FLUSH_CACHE) ||
            _vm.hasPermission(_vm.Permissions.ADMIN_MISC_EXTERNAL_CONNECTIONS) ||
            _vm.hasPermission(_vm.Permissions.ADMIN_FIKA) ||
            _vm.hasPermission(_vm.Permissions.ADMIN_MISC_VIEW_LOGS) ||
            _vm.hasPermission(_vm.Permissions.ADMIN_PAGES)
        )?_c('admin-item',{attrs:{"id":"adminMenu"}}):_vm._e(),_c('navigation-spacer'),_c('li',{on:{"click":function($event){return _vm.EventBus.$emit(_vm.GlobalEvents.PROFILE_OPEN)}}},[_c('entity-avatar',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                text: 'Settings',
                align: 'bottom',
            }),expression:"{\n                text: 'Settings',\n                align: 'bottom',\n            }"}],attrs:{"src":_vm.$store.state.$user.profile_image,"name":_vm.$store.state.$user.name,"size":"xmedium","color":"dark","id":"profileSettingsMenu"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }