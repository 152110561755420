<template>
    <page
        v-if="$store.state.$user.permissions.includes('admin.misc.view_logs')"
        class-name="admin-api-logs-show-page"
        :loading="loading"
        :title="['Admin', 'API Logs']"
        pre-max-width="1000px"
        header="View API log"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb link="/admin/api-logs">API Logs</f-breadcrumb>
                <f-breadcrumb>{{ $route.params.id }}</f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-container max-width="1000px">
            <f-filter>
                <f-filter-row>
                    <f-filter-item>
                        <label>Density</label>
                        <f-radio-button-list v-model="density" direction="horizontal">
                            <f-radio-button name="radio" value="comfortable" label="Comfortable"/>
                            <f-radio-button name="radio" value="compact" label="Compact"/>
                        </f-radio-button-list>
                    </f-filter-item>
                </f-filter-row>
            </f-filter>

            <f-table
                v-if="fetchedData"
                :allow-horizontal-scroll="false"
                align-cell="top"
                :class-name="`--density-${density}`"
                type="simple"
            >
                <template #head>
                    <f-table-header-row v-if="density !== 'compact'">
                        <f-table-header-cell align="center">Time</f-table-header-cell>
                        <f-table-header-cell align="center">Type</f-table-header-cell>
                        <f-table-header-cell>Log</f-table-header-cell>
                    </f-table-header-row>
                </template>
                <f-table-row
                    v-for="(line, i) in fields.lines"
                    :key="i"
                >
                    <f-table-cell
                        v-if="density !== 'compact'"
                        no-wrap
                        align="center"
                    >
                        {{ line.time }}
                    </f-table-cell>
                    <f-table-cell
                        v-if="density !== 'compact'"
                        align="center"
                    >
                        <f-chip code :variant="getChipType(line.type)" size="small">
                            {{ line.type }}
                        </f-chip>
                    </f-table-cell>
                    <f-table-cell>
                        <log-body :body="line.body"/>
                    </f-table-cell>
                </f-table-row>
            </f-table>

            <f-placeholder-container v-else-if="!fetchedData && loading">
                <f-placeholder width="100%" height="60px"/>
                <f-placeholder width="100%" height="60px"/>
                <f-placeholder width="100%" height="60px"/>
            </f-placeholder-container>
        </f-container>
    </page>
</template>

<script>
import APIEndpoints from '../../../../config/APIEndpoints';
import Page from '../../Page';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import EntityShowMixin from '../../../../mixins/entities/EntityShowMixin';
import FPlaceholderContainer from '../../../../f-components/loaders/placeholder/FPlaceholderContainer';
import FTable from '../../../../f-components/data/table/FTable';
import FTableHeaderRow from '../../../../f-components/data/table/FTableHeaderRow';
import FTableHeaderCell from '../../../../f-components/data/table/FTableHeaderCell';
import FTableRow from '../../../../f-components/data/table/FTableRow';
import FTableCell from '../../../../f-components/data/table/FTableCell';
import FPlaceholder from '../../../../f-components/loaders/placeholder/FPlaceholder';
import FChip from '../../../../f-components/data/chip/FChip';
import LogBody from './components/LogBody';
import FFilter from '../../../../f-components/data/filter/FFilter';
import FFilterRow from '../../../../f-components/data/filter/FFilterRow';
import FFilterItem from '../../../../f-components/data/filter/FFilterItem';
import FRadioButtonList from '../../../../f-components/form-controls/radiobutton/FRadioButtonList';
import FRadioButton from '../../../../f-components/form-controls/radiobutton/FRadioButton';
import {ChipVariants} from '../../../../f-components/data/chip/config';
import FContainer from '../../../../f-components/layout/container/FContainer';

export default {
    name: 'admin-api-logs-show-page',

    components: {
        FContainer,
        FRadioButton,
        FRadioButtonList,
        FFilterItem,
        FFilterRow,
        FFilter,
        LogBody,
        FChip,
        FPlaceholder,
        FTableCell,
        FTableRow,
        FTableHeaderCell,
        FTableHeaderRow,
        FTable,
        FPlaceholderContainer,
        FBreadcrumb,
        FBreadcrumbs,
        Page,
    },

    mixins: [
        EntityShowMixin,
    ],

    data() {
        return {
            density: 'comfortable',
        }
    },

    methods: {
        getBaseUrl() {
            return APIEndpoints.ADMIN_API_LOGS;
        },

        getChipType(type) {
            if (/([a-z_-]+)\.INFO/.test(type)) {
                return null;
            } else if (/([a-z_-]+)\.ERROR/.test(type)) {
                return ChipVariants.ERROR;
            } else if (/([a-z_-]+)\.ALERT/.test(type)) {
                return ChipVariants.WARNING;
            } else if (/([a-z_-]+)\.CRITICAL/.test(type)) {
                return ChipVariants.WARNING;
            } else if (/([a-z_-]+)\.WARNING/.test(type)) {
                return ChipVariants.WARNING;
            } else if (/([a-z_-]+)\.DEBUG/.test(type)) {
                return ChipVariants.STATUS;
            }
            return ChipVariants.PRIMARY;
        },
    }
}
</script>

<style lang="scss">
.admin-api-logs-show-page {
    .f-filter__container {
        max-width: 400px;

        .f-filter-item {
            > label {
                vertical-align: middle;
                margin-right: 15px;
                opacity: .5;
            }
        }
    }

    .f-chip {
        margin-top: -2px;
    }

    .--density-compact {
        .f-table-cell {
            padding: 10px;
            border: 0;
        }
    }
}
</style>
