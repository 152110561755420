<template>
    <f-flex
        class-name="settings-pronouns-select"
        :flex-direction="(inline ? 'row' : 'column')"
        :inline="inline"
    >
        <setting-select
            :options="options"
            :searchable="false"
            :setting-key="settingKey"
            :label="label"
            :help="help"
            placeholder="Please select"
            @change="onChange"
        />
        <setting-text-input
            v-if="currentValue === 'custom'"
            setting-key="global.custom_pronouns"
            placeholder="Your pronouns"
            @change="onCustomChange"
        />
    </f-flex>
</template>

<script>
/* eslint-disable no-unused-vars */
import SettingSelect from './SettingSelect';
import FFlex from '../../../../f-components/layout/flex/FFlex';
import SettingTextInput from './SettingTextInput';
import {Pronouns} from '../../../../config/Pronouns';

export default {
    name: 'settings-pronouns-select',

    components: {
        SettingTextInput,
        FFlex,
        SettingSelect,
    },

    props: {
        help: String,
        label: String,
        inline: Boolean,
    },

    data() {
        return {
            settingKey: 'global.pronouns',
            options: Object.entries(Pronouns).map(([_, value]) => ({
                key: value,
                value: (value === 'none' ? "don't display" : value)
            })),
            pronouns: null,
            pronounsCustom: null,
        }
    },

    computed: {
        currentValue() {
            return this.$store.getters.getUserSetting(this.settingKey);
        },
    },

    methods: {
        onChange(value) {
            this.pronouns = value;
            this.$emit('change', [this.pronouns, this.pronounsCustom]);
        },

        onCustomChange(value) {
            this.pronounsCustom = value;
            this.$emit('change', [this.pronouns, this.pronounsCustom]);
        },
    },
}
</script>

<style lang="scss">
.settings-pronouns-select {
    padding-top: 10px;

    > div {
        width: 100%;
    }

    .setting-text-input {
        justify-content: flex-end;
    }

    .--value-none, .--value-custom {
        font-style: italic;
    }
}

.f-dropdown-popup {
    .--value-none, .--value-custom {
        font-style: italic;
    }
}
</style>
