<template>
    <f-button-group
        :class="['faq-item-actions']"
        variant="primary"
    >
        <f-icon-button
            icon="pencil"
            :size="size"
            outlined
            @click.stop="onEditClick"
            v-tooltip="'Edit item'"
            variant="primary"
        />
        <f-icon-button
            icon="trash2"
            :size="size"
            outlined
            @click.stop="onDeleteClick"
            v-tooltip="`Delete item and it's children`"
            variant="primary"
        />
        <f-icon-button
            icon="plus"
            :size="size"
            outlined
            @click.stop="onCreateClick"
            v-tooltip="'Create child item'"
            variant="primary"
        />

        <f-modal
            v-if="showForm"
            @close="showForm = false"
            :title="parentTitles.length === 0 && mode === 'edit' ? `Edit FAQ category` : `${mode.ucfirst()} FAQ item`"
            width="800px"
        >
            <faq-edit-create-form
                :form-mode="mode"
                :default-places="defaultPlaces"
                :id="(mode === 'create' ? undefined : item.id)"
                :parent-id="(mode === 'create' ? item.id : item.parent_id)"
                :parent-titles="(mode === 'create' ? [...parentTitles, item.title] : parentTitles)"
                @success="onSuccessfulCreateEdit"
            />
        </f-modal>
    </f-button-group>
</template>

<script>
import FButtonGroup from '../../../f-components/form-controls/buttons/FButtonGroup';
import FIconButton from '../../../f-components/form-controls/buttons/FIconButton';
import {ButtonSizes} from '../../../f-components/form-controls/buttons/config';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import APIEndpoints from '../../../config/APIEndpoints';
import LoadingMixin from '../../../mixins/LoadingMixin';
import FModal from '../../../f-components/other/modal/FModal';
import FaqEditCreateForm from './FAQEditCreateForm';

export default {
    name: 'faq-item-actions',

    mixins: [
        LoadingMixin,
    ],

    components: {
        FaqEditCreateForm,
        FModal,
        FIconButton,
        FButtonGroup,
    },

    props: {
        size: {
            type: String,
            default: ButtonSizes.SMALL,
        },
        item: {
            type: Object,
            required: true,
        },
        parentTitles: {
            type: Array,
            required: true,
        },
        defaultPlaces: Array,
    },

    data() {
        return {
            mode: 'create',
            showForm: false,
        }
    },

    methods: {
        onSuccessfulCreateEdit() {
            this.showForm = false;
            this.$emit('refresh');
        },

        onEditClick() {
            EventBus.$emit(GlobalEvents.USER_CARD_HIDE);
            this.mode = 'edit';
            this.showForm = true;
        },

        onCreateClick() {
            EventBus.$emit(GlobalEvents.USER_CARD_HIDE);
            this.mode = 'create';
            this.showForm = true;
        },

        onDeleteClick() {
            EventBus.$emit(GlobalEvents.USER_CARD_HIDE);

            EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                title: 'Delete FAQ item',
                body: `Are you sure you want to delete FAQ item <i>'${this.item.title}'</i>, and it's children?`,
                height: '200px',
                confirmButtonText: 'Delete',
                confirmButtonVariant: 'danger',
                cancelButton: true,
                onConfirm: () => {
                    this.startLoading();

                    window.axios
                        .delete(`${APIEndpoints.FAQ}/${this.item.id}`)
                        .then(() => {
                            this.endLoading();
                            EventBus.$emit(GlobalEvents.PUSH_MESSAGE, 'Successfully deleted FAQ item');
                            this.$emit('refresh');
                        })
                        .catch(() => this.endLoading());
                },
            });
        },
    },
}
</script>

<style lang="scss">
.faq-item-actions {
    margin: 0 10px;
}
</style>
