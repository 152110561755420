export const timeOffOptions = [
    {
        key: 'holiday',
        value: 'Holiday',
        icon: 'flip-flops',
    },
    {
        key: 'sick',
        value: 'Sick, doctor or hospital',
        icon: 'first-aid',
    },
    {
        key: 'flexible',
        value: 'Flexible holiday',
        icon: 'magic-wand',
    },
    {
        key: 'special_leave',
        value: 'Special leave',
        icon: 'share3',
    },
    {
        key: 'parental_leave',
        value: 'Parental leave',
        icon: 'baby-bottle',
    },
    {
        key: 'maternity_leave',
        value: 'Maternity leave',
        icon: 'baby',
    },
];

export const getTimeOffOptionByKey = (key) => {
    for (let k in timeOffOptions) {
        if (timeOffOptions[k].key === key) {
            return timeOffOptions[k];
        }
    }
    return null;
}
