/**
 * Component implementing this mixin needs a ref Vue component with the name validatable.
 */
export default {
    props: {
        validation: {
            type: Array,
            default: () => [],
        },
        skipValidationAutoFocus: Boolean,
        name: String,
    },

    data() {
        return {
            isValidatable: true,
        };
    },

    methods: {
        getValue() {
            return this.$refs.validatable.getValue();
        },

        setError(error) {
            this.$refs.validatable.setError(error);
        },

        resetValidation() {
            this.$refs.validatable.resetValidation();
        },

        setValid() {
            this.$refs.validatable.setValid();
        },
    },
};
