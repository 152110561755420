<template>
    <page
        v-if="hasPermission(Permissions.ADMIN_FIKA)"
        class-name="admin-fika-edit-create-page"
        :loading="loading && !dataLoaded"
        :title="['Admin', 'FIKA', 'Edit']"
        header="Edit FIKA entry"
        pre-max-width="700px"
        :skewed-footer-foreground="(!fetchedData && loading ? '#fff' : null)"
        stretch-first-container
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb link="/admin/fika">
                    FIKAs
                </f-breadcrumb>
                <f-breadcrumb
                    v-if="mode !== 'create'"
                    :loading="!dataLoaded"
                >
                    {{ fields ? `#${fields.number}` : '' }}
                </f-breadcrumb>
                <f-breadcrumb>{{ mode }}</f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-form
            :loading="loading"
            @submitValid="submit"
            validate
        >
            <f-container max-width="700px">
                <div v-if="dataLoaded">
                    <f-form-row label="Date">
                        <f-date-picker
                            :validation="['required']"
                            v-model="fields.date"
                            name="date"
                            max-width="200px"
                            :apply="configureDates"
                        />
                    </f-form-row>

                    <f-form-row label="Number">
                        <f-text-input
                            :validation="['required']"
                            v-model="fields.number"
                            name="number"
                            max-width="200px"
                        >
                            <template #pre>
                                <f-input-addon>#</f-input-addon>
                            </template>
                        </f-text-input>
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Google Slides ID">
                        <f-text-input
                            :validation="['required']"
                            v-model="fields.slide_id"
                            name="Slides ID"
                        />
                        <google-embed-help type="slides"/>
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Description">
                        <f-editor
                            :validation="['required']"
                            v-model="fields.description"
                            name="description"
                            :toolbar="toolbar"
                        />
                    </f-form-row>
                </div>

                <f-form-placeholder v-else-if="!dataLoaded && loading"/>
            </f-container>

            <skewed-section
                v-if="dataLoaded"
                foreground-color="#333"
                after-background="#222"
                padding="100px 0 150px"
                margin="100px 0 -50px"
            >
                <f-theme-provider variant="dark">
                    <f-container max-width="900px">
                        <bullet-point>Slides preview</bullet-point>
                        <f-form-row-spacer/>
                        <f-theme-provider variant="light">
                            <f-inline-message
                                id="addGoogleSlides"
                                :closable="false"
                                v-if="!fields.slide_id"
                            >
                                Add the Google Slides ID
                            </f-inline-message>
                            <google-slides-embed
                                v-else
                                :id="fields.slide_id"
                            />
                        </f-theme-provider>
                    </f-container>
                </f-theme-provider>
            </skewed-section>

            <entity-action-button
                v-if="dataLoaded"
                :permission="Permissions.ADMIN_FIKA"
                type="submit"
                icon="floppy-disk"
                :tooltip="(mode === 'edit' ? 'Update' : 'Create')"
            />
        </f-form>
    </page>
</template>

<script>
import moment from 'moment';
import Page from '../../Page';
import FForm from '../../../../f-components/form-controls/forms/FForm';
import FFormRow from '../../../../f-components/form-controls/forms/FFormRow';
import FTextInput from '../../../../f-components/form-controls/text/FTextInput';
import FFormRowSpacer from '../../../../f-components/form-controls/forms/FFormRowSpacer';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import EntityCreateEditMixin from '../../../../mixins/entities/EntityCreateEditMixin';
import APIEndpoints from '../../../../config/APIEndpoints';
import FContainer from '../../../../f-components/layout/container/FContainer';
import EntityActionButton from '../../../misc/entities/EntityActionButton';
import FDatePicker from '../../../../f-components/form-controls/dates/FDatePicker';
import FInputAddon from '../../../../f-components/form-controls/addon/FInputAddon';
import FFormPlaceholder from '../../../../f-components/loaders/placeholder/FFormPlaceholder';
import SkewedSection from '../../../misc/skewed-section/SkewedSection';
import FThemeProvider from '../../../../f-components/layout/theme/FThemeProvider';
import BulletPoint from '../../../misc/BulletPoint';
import FInlineMessage from '../../../../f-components/notice/inline-message/FInlineMessage';
import GoogleSlidesEmbed from '../../../misc/embeds/GoogleSlidesEmbed';
import GoogleEmbedHelp from '../../../misc/embeds/GoogleEmbedHelp';
import FEditor from '../../../../f-components/form-controls/editor/FEditor';
import {EditorToolbar} from '../../../../f-components/form-controls/editor/config';

export default {
    name: 'admin-fika-edit-create-page',

    components: {
        FEditor,
        GoogleEmbedHelp,
        GoogleSlidesEmbed,
        FInlineMessage,
        BulletPoint,
        FThemeProvider,
        SkewedSection,
        FFormPlaceholder,
        FInputAddon,
        FDatePicker,
        EntityActionButton,
        FContainer,
        FBreadcrumb,
        FBreadcrumbs,
        FFormRowSpacer,
        FTextInput,
        FFormRow,
        FForm,
        Page,
    },

    mixins: [
        EntityCreateEditMixin,
    ],

    data() {
        return {
            mode: (this.$route.name === 'admin.fika.edit' ? 'edit' : 'create'),
            entityName: 'FIKA',
            fields: {
                date: null,
                description: null,
                number: null,
                slide_id: null,
            },
            toolbar: [
                EditorToolbar.BOLD,
                EditorToolbar.ITALIC,
                EditorToolbar.STRIKE,
                EditorToolbar.LINK,
                EditorToolbar.HISTORY,
                EditorToolbar.BULLET_LIST,
            ],
        }
    },

    methods: {
        getBaseUrl() {
            return APIEndpoints.ADMIN_FIKA;
        },

        getRedirectPath() {
            return '/admin/fika';
        },

        configureDates(days) {
            const now = moment().startOf('day');

            for (let i in days) {
                if (Object.prototype.hasOwnProperty.call(days, i) && days[i].date > now) {
                    days[i].unavailable = true;
                }
            }

            return days;
        },
    },
}
</script>
