<template>
    <div
        :class="[
            'level',
            `--level-${level}`,
            (active ? '--active' : '--inactive')
        ]"
        :style="{ transform }"
    >
        <div class="level__floor-plan"/>
        <div v-if="active" class="level__container">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'level',

    props: {
        level: Number,
        title: String,
        active: Boolean,
        inactiveZ: String,
    },

    data() {
        return {
            transform: undefined,
        }
    },

    created() {
        this.setZIndex();
    },

    watch: {
        active() {
            this.setZIndex();
        },
    },

    methods: {
        setZIndex() {
            if (this.active) {
                this.transform = 'translateZ(0)';
            } else {
                this.transform = `translateZ(${this.inactiveZ})`;
            }
        },
    },
}
</script>

<style lang="scss">
.level {
    position: absolute;
    transform-style: preserve-3d;
    left: 0;
    top: 0;
    width: 957px;
    height: 731px;
    transition: opacity .2s, transform .2s;

    &__floor-plan {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        transform: translateZ(-1px);
    }

    &__container {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        transform: translateZ(0);
        transform-style: preserve-3d;
    }

    &.--inactive {
        pointer-events: none;
        opacity: .1;
    }

    // Levels

    &.--level-0 .level__floor-plan {
        background: url('../../../../../public/img/office/level-0.png?_v3');
    }

    &.--level-1 {
        height: 679px;

        .level__floor-plan {
            background: url('../../../../../public/img/office/level-1.png?_v3');
        }
    }

    // Children

    .staircase-1a-down, .staircase-1a-up, .staircase-1b-down, .staircase-1b-up {
        position: absolute;
        background: url("../../../../../public/img/office/staircase-1-down.png");
        width: 55px;
        height: 90px;
        pointer-events: none;
    }

    .staircase-1a-down, .staircase-1b-down {
        top: 554px;
        left: 9px;
        transform: translateZ(-29px) rotateX(-40deg);
    }

    .staircase-1a-up, .staircase-1b-up {
        background: url("../../../../../public/img/office/staircase-1-up.png");
        top: 544px;
        left: 9px;
        transform: translateZ(24px) rotateX(-43deg) rotateZ(-38deg) rotateY(29deg);
    }

    .staircase-1b-down {
        top: 81px;
        left: 9px;
        transform: translateZ(-23px) rotateX(210deg)
    }

    .staircase-1b-up {
        background: url(../../../../../public/img/office/staircase-1-down.png);
        top: 81px;
        left: 9px;
        transform: translateZ(21px) rotateX(33deg) rotateZ(36deg) rotateY(16deg);
    }

    .staircase-2-down, .staircase-2-up {
        position: absolute;
        background: url("../../../../../public/img/office/staircase-2-down.png") 28px 28px;
        width: 28px;
        height: 28px;
        top: 612px;
        left: 792px;
        pointer-events: none;
        transform-style: preserve-3d;
        transform: translateZ(-23px);

        &:before {
            width: 37px;
            position: absolute;
            height: 28px;
            content: '';
            background: url("../../../../../public/img/office/staircase-2-down.png") 0 28px;
            top: 0;
            left: -31px;
            transform: translateZ(-14px) rotateY(-45deg);
        }

        &:after {
            width: 28px;
            position: absolute;
            height: 32px;
            content: '';
            background: url("../../../../../public/img/office/staircase-2-down.png") 28px 0;
            top: -27px;
            left: 0;
            transform: translateZ(11px) rotateX(-45deg);
        }
    }

    .staircase-2-up {
        transform: translateZ(27px);
        left: 799px;
        background-image: url("../../../../../public/img/office/staircase-2-up.png");

        &:before, &:after {
            background-image: url("../../../../../public/img/office/staircase-2-up.png");
        }
    }
}
</style>
