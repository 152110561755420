<template>
    <div class="day-time-table">
        <div class="day-time-table__content">
            <ul class="day-time-table__content__hours">
                <li v-for="n in 24" :key="n">
                    <span v-html="getTime(( n > 10 ? '' : '0') + (n - 1) + ':00')"/>
                </li>
            </ul>

            <ul class="day-time-table__content__meetings">
                <slot/>
            </ul>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
    name: 'day-time-table',

    props: {
        clock: {
            type: String, // '24' or '12' if you want to overrule the user setting.
        },
    },

    methods: {
        getTime(time) {
            const clock = (this.clock ? this.clock : this.$store.getters.getUserSetting('global.clock', '24'));
            return (clock === '12' ? moment(time, 'HH:mm').format('h[<span class="am-pm">]A<[/span>]') : time);
        },
    }
}
</script>

<style lang="scss">
.day-time-table {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    height: 100%;
    width: 200px;
    overflow-x: visible;
    overflow-y: scroll;
    z-index: 2;
    background: linear-gradient(-90deg, rgba(0, 0, 0, .5), transparent);

    &__content {
        position: absolute;
        height: 300%;
        width: 100%;
        top: 0;
        right: 0;

        &__hours {
            position: absolute;
            height: 100%;
            top: 0;
            right: 0;
            width: 25px;
            font-size: 130%;

            li {
                position: relative;
                height: 4.1666666667%;
                border-bottom: 1px solid $light;

                &:first-child {
                    > span {
                        display: none;
                    }
                }

                &:last-child {
                    border: 0;
                }

                > span {
                    position: absolute;
                    right: 35px;
                    width: 100px;
                    white-space: nowrap;
                    font-size: 18px;
                    transform: translateY(-50%);
                }
            }
        }

        &__meetings {
            position: absolute;
            height: 100%;
            top: 0;
            right: 0;

            li {
                width: 400px;
                right: 0;
                position: absolute;

                &:after {
                    background: $primary;
                    content: '';
                    position: absolute;
                    top: 1px;
                    right: 0;
                    height: calc(100% - 2px);
                    width: 10px;
                    border-radius: 5px;
                }
            }
        }
    }
}
</style>
