import EventBus from '../services/EventBus';
import {GlobalEvents} from '../config/Events';

export default {
    props: {
        usersCollection: Array,
        freelancersCollection: Array,
    },

    methods: {
        parseUserCards() {
            this.$nextTick(() => {
                if (!this.$el || !this.$el.querySelectorAll) {
                    return;
                }
                const elements = this.$el.querySelectorAll("a[href^='user://']");
                elements.forEach((element) => this.parseUserCardLink(element));
            });
        },

        parseUserCardLink(element) {
            if (element.userLinksParsed) return;
            element.userLinksParsed = true;

            element.addEventListener('click', function (e) {
                e.preventDefault();
            });
            element.addEventListener('mouseover', () => {
                EventBus.$emit(GlobalEvents.USER_CARD_SHOW, element, element.href.replace('user://', ''));
            });
        },

        userFromName(name) {
            // Check freelancers
            const freelancersCollection = this.getFreelancersCollection();
            for (let i in freelancersCollection) {
                if (freelancersCollection[i].name === name) {
                    return {
                        ...freelancersCollection[i],
                        ...{type: "freelancer"},
                    };
                }
            }

            // Check Riviera users
            const usersCollection = this.getUsersCollection();
            for (let i in usersCollection) {
                if (usersCollection[i].name === name) {
                    return usersCollection[i];
                }
            }
        },

        userById(id) {
            id = parseInt(id);

            const usersCollection = this.getUsersCollection();
            for (let i in usersCollection) {
                if (usersCollection[i].id === id) {
                    return usersCollection[i];
                }
            }
        },

        freelancerById(id) {
            const freelancersCollection = this.getFreelancersCollection();
            for (let i in freelancersCollection) {
                if (freelancersCollection[i].id === id) {
                    return freelancersCollection[i];
                }
            }
        },

        getUsersCollection() {
            if (this.usersCollection) {
                return this.usersCollection;
            } else if (this.$store && this.$store.state && this.$store.state.$usersCollection) {
                return this.$store.state.$usersCollection;
            }
            return [];
        },

        getFreelancersCollection() {
            if (this.freelancersCollection) {
                return this.freelancersCollection;
            } else if (this.$store && this.$store.state && this.$store.state.$freelancersCollection) {
                return this.$store.state.$freelancersCollection;
            }
            return [];
        },
    },
};
