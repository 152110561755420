<template>
    <f-text
        :class-name="[
            'bullet-point',
            `--${size}`,
            ...classNames,
        ]"
        :type="textType"
        :tag="tag"
    >
        <slot/>
    </f-text>
</template>

<script>
import FText from '../../f-components/layout/text/FText';
import ClassNameMixin from '../../mixins/ClassNameMixin';

export default {
    name: 'bullet-point',

    mixins: [
        ClassNameMixin,
    ],

    props: {
        textType: {
            type: String,
            default: 'subtitle',
        },
        tag: {
            type: String,
            default: 'div',
        },
        size: {
            type: String,
            default: 'small', // small | large
        },
    },

    components: {
        FText,
    },
}
</script>

<style lang="scss">
.bullet-point {
    &:before {
        content: '•';
        color: $ctaOrange;
        font-size: 130%;
        line-height: 100%;
        margin-right: 5px;
    }

    &.--large {
        font-size: 18px;

        &:before {
            font-size: 22px;
            margin-right: 10px;
        }
    }
}
</style>
