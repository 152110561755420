<template>
    <f-modal
        v-if="active && data"
        class-name="notification-modal --no-select"
        close-button
        :width="width"
        @close="close"
    >
        <template #head>
            <f-panel-header>
                <f-flex
                    class-name="notification-modal__header"
                    gap="15px"
                    main-axis-align="start"
                >
                    <div class="notification-modal__header__avatar">
                        <entity-avatar
                            :src="data.definition.image_url"
                            size="xmedium"
                        >
                            <f-icon v-bind="iconProps"/>
                        </entity-avatar>
                    </div>
                    <div class="notification-modal__header__body">
                        <div class="notification-modal__header__body__title">
                            {{ data.definition.popup_title }}
                        </div>
                        <div class="notification-modal__header__body__date">
                            {{ getDateTimeDiff(data.created_at) }}
                        </div>
                    </div>
                </f-flex>
            </f-panel-header>
        </template>

        <f-scroll-pane max-height="55vh">
            <f-content
                v-if="data.definition.popup_body && data.definition.popup_body !== '<p></p>'"
                v-html="data.definition.popup_body"
            />

            <f-form-row-spacer v-if="data.definition.popup_has_embed"/>

            <google-slides-embed
                v-if="data.definition.popup_has_embed && data.definition.popup_embed_type === 'slides'"
                :id="data.definition.popup_embed_id"
            />
            <google-drive-embed
                v-else-if="data.definition.popup_has_embed && data.definition.popup_embed_type === 'drive'"
                :id="data.definition.popup_embed_id"
            />
            <i-frame-embed
                v-else-if="data.definition.popup_has_embed && data.definition.popup_embed_type === 'iframe'"
                :src="data.definition.popup_embed_id"
            />
        </f-scroll-pane>

        <footer
            v-if="data.definition.popup_has_button"
            class="--align-right"
            style="margin-top: 30px;"
        >
            <f-button
                tag="a"
                :value="(data.definition.popup_button_value ? data.definition.popup_button_value : defaultButtonValue)"
                :href="data.definition.popup_button_url"
                :outlined="false"
                variant="primary"
            />
        </footer>
    </f-modal>
</template>

<script>
import FModal from '../../../f-components/other/modal/FModal';
import FButton from '../../../f-components/form-controls/buttons/FButton';
import EventBus from '../../../services/EventBus';
import FFlex from '../../../f-components/layout/flex/FFlex';
import EntityAvatar from '../entities/EntityAvatar';
import FIcon from '../../../f-components/layout/icons/FIcon';
import FContent from '../../../f-components/layout/content/FContent';
import FPanelHeader from '../../../f-components/other/panel/FPanelHeader';
import NotificationMixin from './NotificationMixin';
import GoogleSlidesEmbed from '../embeds/GoogleSlidesEmbed';
import GoogleDriveEmbed from '../embeds/GoogleDriveEmbed';
import IFrameEmbed from '../embeds/IFrameEmbed';
import {NotificationTypes} from './config';
import FScrollPane from '../../../f-components/other/scroll-pane/FScrollPane';
import FFormRowSpacer from '../../../f-components/form-controls/forms/FFormRowSpacer';

export default {
    name: 'notification-modal',

    mixins: [
        NotificationMixin,
    ],

    props: {
        defaultOptions: {
            type: Object,
        },
    },

    components: {
        FFormRowSpacer,
        FScrollPane,
        IFrameEmbed,
        GoogleDriveEmbed,
        GoogleSlidesEmbed,
        FPanelHeader,
        FContent,
        FIcon,
        EntityAvatar,
        FFlex,
        FButton,
        FModal,
    },

    computed: {
        defaultButtonValue() {
            switch (this.data.definition.type) {
                case NotificationTypes.LEAVE:
                    return 'View request';
            }
            return 'View';
        },
    },

    data() {
        return {
            data: null,
            width: '600px',
            active: false,
        }
    },

    mounted() {
        EventBus.$on('notification.show', (data) => this.open(data));
    },

    methods: {
        open(data) {
            this.data = data;
            this.active = true;
            this.markAsRead();
        },

        close() {
            this.active = false;
        },

        markAsRead() {
            this.$store.dispatch('notificationIsRead', this.data.id);
        },
    },
}
</script>

<style lang="scss">
.notification-modal {
    &__header {
        padding-right: 60px;

        &__body {
            padding: 15px 0;

            &__title {
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 110%;
                margin-bottom: 2px;
                font-size: 130%;
            }

            &__date {
                font-size: 90%;
                opacity: .7;
            }
        }
    }
}
</style>
