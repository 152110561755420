<template>
    <f-container
        :class-name="[
            'welcome-message',
            '--no-wrap',
        ]"
        :max-width="maxWidth"
    >
        <h1><strong>{{ greeting }},</strong><br><em>{{ name }}</em></h1>
    </f-container>
</template>

<script>
import Store from '../../../../services/Store';
import FContainer from '../../../../f-components/layout/container/FContainer';

export default {
    name: 'welcome-message',

    components: {
        FContainer,
    },

    props: {
        maxWidth: String,
    },

    computed: {
        name() {
            return Store.state.$user.name.split(' ')[0];
        },
        greeting() {
            const hours = (new Date).getHours();

            if (hours >= 12) {
                if (hours >= 18) {
                    if (hours >= 21) {
                        return 'Good night';
                    }
                    return 'Good evening';
                }
                return 'Good afternoon';
            }
            return 'Good morning';
        },
    },
}
</script>

<style lang="scss">
.welcome-message {
    margin-top: 100px;

    @include media(tablet-down) {
        margin-top: 75px;
    }

    @include media(mobile-down) {
        margin-top: 50px;
    }

    h1 {
        font-size: 90px;
        line-height: 80%;

        @include media(tablet-down) {
            font-size: 60px;
        }

        @include media(mobile-down) {
            font-size: 40px;
        }
    }
}
</style>
