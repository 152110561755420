import EventBus from '../services/EventBus';
import {GlobalEvents} from '../config/Events';

export const tooltip = {
    inserted: (el, data) => init(el, data),
    componentUpdated: (el, data) => init(el, data, true),
    unbind: (el) => removeEventListeners(el),
}

const init = (el, data, remove = false) => {
    let options = ((typeof data.value === 'string' || data.value == null) ? {} : (typeof data.value === 'object' ? data.value : {}));

    const value = (typeof data.value === 'string' ? data.value : (options.text !== null ? options.text : null));

    if (options.options && typeof options.options === 'object') {
        options = {...options, ...options.options}
    }

    if (remove) {
        removeEventListeners(el);
    }
    addEventListeners(el, value, options);
}

const addEventListeners = (el, value, options) => {
    if (options.type === 'click') {
        el.addEventListener('click', () => onMouseOver(el, value, options));
        el.addEventListener('mouseleave', onMouseLeave);
    } else {
        el.addEventListener('mouseover', () => onMouseOver(el, value, options));
        el.addEventListener('mouseleave', onMouseLeave);

        if (options.touch !== true) {
            el.addEventListener('click', onClick);
        }
    }
}

const removeEventListeners = (el) => {
    el.removeEventListener('mouseover', onMouseOver);
    el.removeEventListener('mouseleave', onMouseLeave);
    el.removeEventListener('click', onClick);
}

const onMouseOver = (target, value, options) => {
    EventBus.$emit(GlobalEvents.TOOLTIP_SHOW, target, value, options);
}

const onMouseLeave = () => {
    EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
}

const onClick = () => {
    EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
}
