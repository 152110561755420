<template>
    <div
        :is="tag"
        :class="['f-container', '--loader', ...classNames]"
        :style="{ maxWidth }"
    >
        <slot/>
    </div>
</template>

<script>
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-container',

    mixins: [
        ClassNameMixin,
    ],

    props: {
        maxWidth: {
            type: String,
            default: "1000px",
        },
        tag: {
            type: String,
            default: 'div',
        },
    },
}
</script>

<style lang="scss">
.f-container {
    margin: 0 auto;
    padding: 0 40px;
    width: 100%;
    position: relative;
    
    @include media(tablet-down) {
        padding: 0 30px;
    }

    @include media(mobile-down) {
        padding: 0 15px;
    }
}
</style>
