<template>
    <f-form
        class-name="details-slide"
        validate
        dont-scroll-into-view
        @submitValid="$emit('next', {reason, type, userId})"
    >
        <f-header-body-footer>
            <f-form-row label="Employee" v-if="admin">
                <f-user-dropdown
                    v-model="userId"
                    :validation="['required']"
                    :disabled="mode === 'edit'"
                    name="employee"
                    placeholder="Select"
                    popup-variant="light"
                />
            </f-form-row>

            <f-form-row label="Leave type">
                <f-dropdown
                    v-model="type"
                    :options="timeOffOptions"
                    name="type"
                    width="230px"
                    popup-width="185px"
                    popup-variant="light"
                >
                    <template v-slot:selectedOption="selectedOptionProps">
                        <div
                            v-if="selectedOptionProps.selectedOption"
                            class="f-dropdown__selected-option"
                        >
                            <f-icon :icon="selectedOptionProps.selectedOption.icon"/>
                            {{ selectedOptionProps.selectedOption.value }}
                        </div>
                    </template>
                    <template v-slot:options="optionsProps">
                        <li
                            v-for="(option, index) in optionsProps.options"
                            :class="[
                                (option.disabled ? '--disabled' : null),
                                (optionsProps.selectedIndex === index ? '--selected' : null),
                                (optionsProps.selectedOption && optionsProps.selectedOption.key === option.key ? '--chosen' : null),
                            ]"
                            :key="option.key"
                            @click.prevent="optionsProps.onSelect(option, index)"
                        >
                            <f-icon :icon="option.icon"/>
                            {{ option.value }}
                        </li>
                    </template>
                </f-dropdown>
            </f-form-row>

            <f-form-row
                v-if="typeMessages[type] && !admin"
                style="margin-bottom: 5px;"
                label=""
            >
                <f-inline-message
                    :type="typeMessages[type].type"
                    :closable="false"
                    id="leaveTypeMessage"
                >
                    <span v-html="typeMessages[type].message"/>
                </f-inline-message>
            </f-form-row>

            <f-form-row label="Reason">
                <f-text-area
                    v-model="reason"
                    name="reason"
                    :placeholder="(admin ? 'Description' : 'Description of your leave request')"
                    :disabled="mode === 'edit'"
                />
            </f-form-row>

            <template #footer>
                <f-button
                    type="submit"
                    icon="chevron-right"
                    icon-position="right"
                    value="Next"
                    variant="primary"
                />
            </template>
        </f-header-body-footer>
    </f-form>
</template>

<script>
import FHeaderBodyFooter from '../../../../f-components/layout/header-body-footer/FHeaderBodyFooter';
import FDropdown from '../../../../f-components/form-controls/dropdowns/FDropdown';
import FTextArea from '../../../../f-components/form-controls/text/FTextArea';
import FButton from '../../../../f-components/form-controls/buttons/FButton';
import FForm from '../../../../f-components/form-controls/forms/FForm';
import FFormRow from '../../../../f-components/form-controls/forms/FFormRow';
import FIcon from '../../../../f-components/layout/icons/FIcon';
import FUserDropdown from '../../../../f-components/form-controls/dropdowns/FUserDropdown';
import {timeOffOptions} from '../config';
import FInlineMessage from '../../../../f-components/notice/inline-message/FInlineMessage';

export default {
    name: 'details-slide',

    components: {
        FInlineMessage,
        FUserDropdown,
        FIcon,
        FFormRow,
        FForm,
        FButton,
        FTextArea,
        FDropdown,
        FHeaderBodyFooter,
    },

    props: {
        admin: Boolean,
        data: {
            type: Object,
            required: true,
        },
        mode: {
            type: String,
            default: 'create',
        },
    },

    watch: {
        type(type) {
            this.$emit('typeChange', type);
        },
    },

    data() {
        return {
            type: this.data.type,
            reason: this.data.reason,
            userId: this.data.userId,
            timeOffOptions: timeOffOptions,
            typeMessages: {
                sick: {
                    type: 'warning',
                    message: `If you are ill and calling in sick, please note that you have to do this (separately) for each day
                    that you are sick, before 9 in the morning.<br> Inform the <a href="mailto:traffic@codedazur.com">traffic
                    department</a> if you are feeling better and ready to work again!`,
                },
                // flexible: {
                //     type: 'warning',
                //     message: `TODO!!!`
                // },
                // special_leave: {
                //     type: 'warning',
                //     message: `TODO!!!`
                // },
                // parental_leave: {
                //     type: 'warning',
                //     message: `TODO!!!`
                // },
                // maternity_leave: {
                //     type: 'warning',
                //     message: `TODO!!!`
                // },
            },
        };
    },
}
</script>

<style lang="scss">
.details-slide {
    height: 100%;

    &__back {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .f-form-row__content {
        text-align: left;
    }

    .f-header-body-footer {
        padding-right: 2px;

        footer {
            text-align: right;
            padding-bottom: 2px;
        }
    }

    .f-text-area textarea {
        min-height: 80px;
    }
}
</style>
