<template>
    <div
        :class="[
            'f-radio-button-list',
            className,
            `--${direction}`
        ]"
    >
        <slot/>
        <slot name="after"/>
    </div>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import ChildrenMixin from '../../../mixins/ChildrenMixin';

export default {
    name: 'f-radio-button-list',

    props: {
        direction: {
            type: String,
            default: 'vertical',
        },
        value: {
            type: null | String | Number,
        },
        className: {
            type: String,
        },
    },

    mixins: [
        ChildrenMixin,
    ],

    data() {
        return {
            childTags: [
                'f-radio-button',
            ],
        }
    },

    watch: {
        value() {
            this.onChange();
        },
    },

    mounted() {
        this.getChildren().forEach(($child) => {
            $child.uncheck();
            if (`${this.value}` === `${$child.value}`) {
                $child.check();
            }
            $child.$on('change', this.onChange);
        });

        this.$nextTick(() => this.onChange());
    },

    methods: {
        onChange() {
            let value = undefined;

            this.getChildren().forEach(($child) => {
                if ($child.isChecked() && !$child.disabled) {
                    value = $child.value;
                }
            });

            this.$emit('input', value);
            this.$emit('change', value);
        },

        set(value) {
            this.getChildren().forEach(($child) => {
                if (`${$child.value}` === `${value}`) {
                    value = $child.check();
                }
            });
        },
    },
}
</script>

<style lang="scss">
.f-radio-button-list {
    .f-radio-button {
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.--vertical {
        .f-radio-button {
            display: flex;
        }
    }

    &.--horizontal {
        .f-radio-button {
            margin-bottom: 0;

            .f-radio-button__visual-label {
                padding-right: 20px;
            }

            &:last-child .f-radio-button__visual-label {
                padding-right: 0;
            }
        }
    }
}
</style>
