<template>
    <div
        :class="[
            'announcement-event',
            `--${ratio}`,
            (isSafari ? '--bs' : null),
            (image ? '--image' : undefined),
        ]"
        @click="modalOpen = true"
    >
        <div
            v-if="image"
            class="announcement-event__bg"
            :style="{ backgroundImage: `url(${image})` }"
        />

        <div class="announcement-event__header">
            <f-text v-if="type" tag="div" font-weight="400">
                {{ type }}
            </f-text>
            <f-text v-if="title" tag="div" :font-size="titleFontSize">
                {{ title }}
            </f-text>
        </div>
        <f-text v-if="subtitle" type="body">
            {{ subtitle }}
        </f-text>

        <custom-cursor/>

        <f-modal
            v-if="modalOpen"
            :title="modalTitle"
            :width="(modalHasEmbed ? '1000px' : '700px')"
            height="auto"
            @close="modalOpen = false"
        >
            <f-scroll-pane max-height="55vh">
                <f-content
                    v-if="modalBody && modalBody !== '<p></p>'"
                    v-html="modalBody"
                />

                <div v-if="modalHasEmbed" style="padding-top: 20px">
                    <google-slides-embed
                        v-if="modalHasEmbed && modalEmbedType === 'slides'"
                        :id="modalEmbedId"
                    />
                    <google-drive-embed
                        v-else-if="modalHasEmbed && modalEmbedType === 'drive'"
                        :id="modalEmbedId"
                    />
                    <i-frame-embed
                        v-else-if="modalHasEmbed && modalEmbedType === 'iframe'"
                        :src="modalEmbedId"
                    />
                </div>
            </f-scroll-pane>

            <footer
                v-if="modalHasButton"
                class="--align-right"
                style="margin-top: 30px;"
            >
                <f-button
                    tag="a"
                    :value="modalButtonValue"
                    :href="modalButtonUrl"
                    :outlined="false"
                    variant="primary"
                />
            </footer>
        </f-modal>
    </div>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import {AnnouncementEventRatios, AnnouncementEventTitleSizes} from './config';
import FText from '../../../../../f-components/layout/text/FText';
import CustomCursor from '../../../CustomCursor';
import {isSafari} from '../../../../../utils/helpers';
import FModal from '../../../../../f-components/other/modal/FModal';
import FButton from '../../../../../f-components/form-controls/buttons/FButton';
import GoogleSlidesEmbed from '../../../embeds/GoogleSlidesEmbed';
import GoogleDriveEmbed from '../../../embeds/GoogleDriveEmbed';
import FContent from '../../../../../f-components/layout/content/FContent';
import IFrameEmbed from '../../../embeds/IFrameEmbed';
import FScrollPane from '../../../../../f-components/other/scroll-pane/FScrollPane.vue';

export default {
    name: 'announcement-event',

    components: {
        FScrollPane,
        IFrameEmbed,
        FContent,
        GoogleDriveEmbed,
        GoogleSlidesEmbed,
        FButton,
        FModal,
        CustomCursor,
        FText
    },

    props: {
        ratio: {
            type: String,
            default: AnnouncementEventRatios.LANDSCAPE,
        },
        titleSize: {
            type: String,
            default: AnnouncementEventTitleSizes.SMALL,
        },
        image: String,
        type: String,
        title: String,
        subtitle: String,

        modalTitle: String | Array,
        modalBody: String,

        modalHasEmbed: Boolean,
        modalEmbedType: String,
        modalEmbedId: String,

        modalHasButton: Boolean,
        modalButtonValue: String,
        modalButtonUrl: String,
    },

    computed: {
        titleFontSize() {
            switch (this.titleSize) {
                case AnnouncementEventTitleSizes.XSMALL:
                    return '11px';
                case AnnouncementEventTitleSizes.MEDIUM:
                    return '16px';
                case AnnouncementEventTitleSizes.XMEDIUM:
                    return '18px';
                case AnnouncementEventTitleSizes.LARGE:
                    return '22px';
                case AnnouncementEventTitleSizes.XLARGE:
                    return '28px';
                default:
                case AnnouncementEventTitleSizes.SMALL:
                    return '14px';
            }
        },
    },

    data() {
        return {
            modalOpen: false,
            isSafari: isSafari(),
        }
    },
}
</script>

<style lang="scss">
.announcement-event {
    position: relative;
    overflow: hidden;
    background: $dark;
    border-radius: 6px;
    padding: 20px 20px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    cursor: pointer;

    @include media(mobile-down) {
        height: 160px;
        width: 234px;
    }

    &.--square {
        height: 400px * .5;
        width: 400px * .5;
    }

    &.--landscape {
        height: 320px * .5;
        width: 468px * .5;
    }

    &.--portrait {
        width: 320px * .5;
        height: 468px * .5;
    }

    &__header {
        .f-text {
            margin-bottom: 10px;
        }
    }

    > .f-text, .announcement-event__header .f-text {
        position: relative;
        z-index: 3;
        text-shadow: rgba(#000, .35) 0 0 5px;
        line-height: 120%;
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50% 50%;
        transition: transform .2s ease-in-out;
    }

    &:hover:not(.--bs) {
        .announcement-event__bg {
            transform: scale(1.1);
            transition: transform 2s ease-in-out;
        }
    }

    &.--image {
        &:before, &:after {
            position: absolute;
            left: 0;
            content: '';
            height: 60px;
            width: 100%;
            z-index: 2;
        }

        &:before {
            top: 0;
            background: linear-gradient(180deg, rgba(#000, .35) 0%, rgba(#000, 0) 100%);
        }

        &:after {
            bottom: 0;
            background: linear-gradient(180deg, rgba(#000, 0) 0%, rgba(#000, .35) 100%);
        }
    }
}
</style>
