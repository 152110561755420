<template>
    <f-portal
        v-if="active && user"
        reference="user-card-popup"
    >
        <transition name="popup" appear>
            <div
                ref="positionElement"
                :class="[
                    'user-card-popup',
                    `--${currentAlign}`,
                    `--hor-${currentAlignHorizontal}`,
                    options.className,
                    `--${options.variant}`,
                ]"
                :style="{
                    top: `${pos.top}px`,
                    left: `${pos.left}px`
                }"
            >
                <user-card
                    :user="user"
                    :variant="options.variant"
                    popup-class-name="user-card-popup__popup"
                />
            </div>
        </transition>
    </f-portal>
</template>

<script>
import UserCard from './UserCard';
import UserHelperMixin from '../../../mixins/UserHelperMixin';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import PositionMixin from '../../../mixins/position/PositionMixin';
import FPortal from '../../../f-components/other/portal/FPortal';
import {UserCardVariants} from './config';

export default {
    name: 'user-card-popup',

    components: {
        FPortal,
        UserCard,
    },

    mixins: [
        UserHelperMixin,
        PositionMixin,
    ],

    created: function () {
        EventBus.$on(GlobalEvents.USER_CARD_SHOW, (target, name, options) => this.show(target, name, options));
        EventBus.$on(GlobalEvents.USER_CARD_HIDE, this.hide);
        EventBus.$on(GlobalEvents.PAGE_CHANGE, () => EventBus.$emit(GlobalEvents.USER_CARD_HIDE));
        EventBus.$on(GlobalEvents.OVERFLOW_SCROLL, () => EventBus.$emit(GlobalEvents.USER_CARD_HIDE));
    },

    data() {
        return {
            defaultOptions: {
                className: null,
                align: 'top',
                alignHorizontal: 'center',
                offsetTop: 0,
                offsetLeft: 0,
                margin: 0,
                variant: UserCardVariants.DARK,
            },
            options: {},
            user: null,
        }
    },

    methods: {
        show(target, name, options = {}) {
            this.active = false;
            if (!name || !target) {
                return;
            }

            this.user = this.userFromName(name);
            if (!this.user) {
                console.warn(`Can't find user ${name}`);
                this.$nextTick(() => EventBus.$emit(GlobalEvents.USER_CARD_HIDE));
                return;
            }

            this.active = true;

            this.setOptions({...{target}, ...options});
            this.registerEvents();

            this.$nextTick(() => this.position());
            this.$nextTick(() => this.$nextTick(() => this.position()));
        },

        setOptions(options) {
            this.options = {
                ...this.defaultOptions,
                ...options,
            };
        },

        registerEvents() {
            EventBus.$on(GlobalEvents.USER_CARD_HIDE, this.hide);
            EventBus.$on(GlobalEvents.PAGE_CHANGE, this.hide);
            EventBus.$on(GlobalEvents.OVERFLOW_SCROLL, this.hide);
            window.setTimeout(() => {
                if (this.active) {
                    document.addEventListener('click', this.onMouseClick);
                    document.addEventListener('touchstart', this.onMouseClick);
                }
            }, 500);
        },

        removeEvents() {
            EventBus.$off(GlobalEvents.USER_CARD_HIDE, this.hide);
            EventBus.$off(GlobalEvents.OVERFLOW_SCROLL, this.hide);
            EventBus.$off(GlobalEvents.PAGE_CHANGE, this.hide);
            document.removeEventListener('click', this.onMouseClick);
            document.removeEventListener('touchstart', this.onMouseClick);
        },

        onMouseClick(e) {
            if (
                this.active &&
                this.$refs.positionElement &&
                !this.$refs.positionElement.contains(e.target) &&
                !document.querySelector('.user-card-popup__popup')
            ) {
                EventBus.$emit(GlobalEvents.USER_CARD_HIDE);
            }
        },

        hide() {
            this.active = false;
            this.removeEvents();
        },
    },
}
</script>

<style lang="scss">
.user-card-popup {
    position: fixed;
    width: auto;
    z-index: $userCardPopupOrder;
    box-shadow: rgba($dark, .1) 0 0 3px;
    border-radius: 15px;

    &.--light {
        @include arrow-pointer(#b25228, 5px, $light);
    }

    &.--dark {
        @include arrow-pointer(#b25228, 5px, $dark);
    }

    &.--bottom.--hor-left::before {
        border-bottom-color: #be9d7e;
    }

    &.--bottom.--hor-right::before {
        border-bottom-color: #b03016;
    }

    &__popup {
        z-index: $userCardPopupOrder + 1;
    }

    // TODO: just add size prop to user card, instead of styling it here
    .user-card {
        width: 220px;

        .user-card__body {
            padding: 5px 15px 15px;
            font-size: 95%;
            line-height: 120%;

            &__name {
                line-height: 100%;
            }

            &__description {
                display: none;
            }

            &__details {
                > div {
                    padding: 0 0;
                }
            }
        }

        .user-card__image-header {
            height: 70px;

            .image {
                width: 80px;
                height: 80px;
                bottom: -40px;
                margin-left: -40px;
            }
        }
    }
}
</style>
