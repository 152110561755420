<template>
    <f-portal>
        <div :class="['screensaver-page']">
            <video
                class="screensaver-page__background"
                loop
                autoplay
                muted
                ref="background"
            >
                <source src="/mov/background-gradient.webm" type="video/webm"/>
            </video>

            <marquee :repeat="4" :duration="12">
                <f-text type="header" font-size="120px">
                    <em>Meeting room</em> {{ $route.params.number }}&nbsp;&nbsp;—&nbsp;&nbsp;
                    We are <strong>code d'azur</strong>&nbsp;&nbsp;—&nbsp;&nbsp;
                </f-text>
            </marquee>
        </div>
    </f-portal>
</template>

<script>
import FPortal from '../../../f-components/other/portal/FPortal';
import Marquee from '../../misc/marquee/Marquee';
import FText from '../../../f-components/layout/text/FText';

export default {
    name: 'screensaver-page',

    components: {
        FText,
        Marquee,
        FPortal,
    },

    mounted() {
        this.$nextTick(() => this.$refs.background.play());
    },
}
</script>

<style lang="scss">
.page-meeting-room-screensaver .container {
    display: none !important;
}

.screensaver-page {
    background: $light;
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;

    &__background {
        height: 100%;
        width: 177.77777778vh; /* 100 * 16 / 9 */
        min-width: 100%;
        min-height: 56.25vw; /* 100 * 9 / 16 */
        position: absolute;
        transform: rotateY(180deg);
    }
}
</style>
