<template>
    <page
        title="Amsterdam office map"
        header="<em>Amsterdam</em>,<br><strong>where</strong> do I go?"
        pre-max-width="800px"
    >
        <amsterdam-office-map/>
    </page>
</template>

<script>
import AmsterdamOfficeMap from './components/AmsterdamOfficeMap';
import Page from '../Page';

export default {
    name: 'office-map-page',

    components: {
        Page,
        AmsterdamOfficeMap,
    },
}
</script>
