<template>
    <div :class="['f-input-group']">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'f-input-group',
}
</script>

<style lang="scss">
.f-input-group {
    white-space: nowrap;

    .f-abstract-input-field {
        &:first-child {
            border-radius: $inputBorderRadius 0 0 $inputBorderRadius;
        }

        &:first-child, &:not(:first-child):not(:last-child) {
            border-right: 0;
        }

        &:not(:first-child):not(:last-child) {
            border-radius: 0;
            border-left: 0;
            border-right: 0;
        }

        &:last-child {
            border-left: 0;
            border-radius: 0 $inputBorderRadius $inputBorderRadius 0;
        }
    }
}
</style>
