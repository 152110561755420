<template>
    <div
        :class="[
            'f-carousel-slide',
            `--slide-id-${id}`,
            (active ? '--active' : undefined),
        ]"
    >
        <f-icon-button
            v-if="backButton"
            class-name="f-carousel-slide__back"
            @click="onBackClick"
            icon="chevron-left"
            size="medium"
            variant="light"
            outlined
        />

        <div
            v-if="!hidden"
            class="f-carousel-slide__content"
            ref="content"
        >
            <slot/>
        </div>
    </div>
</template>

<script>
import FIconButton from '../../form-controls/buttons/FIconButton';

export default {
    name: 'f-carousel-slide',

    components: {
        FIconButton,
    },

    props: {
        title: String,
        id: {
            type: String,
            required: true,
        },
        backButton: Boolean,
        defaultActive: Boolean,
    },

    data() {
        return {
            active: false,
            hidden: true,
        }
    },

    methods: {
        activate() {
            this.active = true;
            this.hidden = false;
        },
        deactivate() {
            this.active = false;
        },
        hide() {
            this.hidden = true;
        },
        onBackClick() {
            if (this.$parent.$parent)
                this.$parent.$parent.slideToPrevious();
        },
    },
}
</script>

<style lang="scss">
.f-carousel-slide {
    width: 100%;
    flex: 0 0 100%;
    align-items: stretch;
    overflow: hidden;
    position: relative;

    &__back {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.f-carousel {
    &:not(.--animating) {
        .f-carousel-slide:not(.--active) .f-carousel-slide__content {
            visibility: hidden;
        }
    }
}
</style>
