var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'meeting-room',
        // `--${state.state}`,
    ],style:({
        left: `${_vm.left}px`,
        top: `${_vm.top}px`,
        height: `${_vm.height}px`,
        width: `${_vm.width}px`,
    })},[_c('pin',{attrs:{"left":_vm.pinLeft,"top":_vm.pinTop,"title":_vm.title,"color":"purple","icon":"group-work"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }