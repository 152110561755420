<template>
    <div
        :class="['gallery-thumbnail', (selected ? '--selected' : null)]"
        :style="{ height: heightComputed, width: widthComputed }"
        @click="$emit('click')"
    >
        <f-image-loader
            :alt="alt"
            :src="src"
            :height="heightComputed"
            :width="widthComputed"
            :observer="observer"
            secure
        />
    </div>
</template>

<script>
import FImageLoader from '../../../../f-components/layout/image-loader/FImageLoader';
import {ObserverProvider} from '../../../../utils/ObserverProvider';

export default {
    name: 'gallery-thumbnail',

    components: {
        FImageLoader,
    },

    props: {
        src: {
            type: String,
            required: true,
        },
        selected: Boolean,
        alt: String,
        height: {
            type: Number,
            default: 100,
        },
        secure: Boolean,
        srcWidth: Number,
        srcHeight: Number,
        observer: ObserverProvider,
    },

    computed: {
        heightComputed() {
            return `${this.height}px`;
        },
        widthComputed() {
            if (this.srcWidth && this.srcHeight) {
                return Math.round(this.srcWidth * (this.height / this.srcHeight)) + 'px';
            }
            return undefined;
        },
    },

    watch: {
        selected(selected) {
            if (selected) {
                setTimeout(() => {
                    if (this.$el) {
                        this.$el.scrollIntoView();
                    }
                }, 100);
            }
        },
    },
}
</script>

<style lang="scss">
.gallery-thumbnail {
    position: relative;
    z-index: 1;
    transition: transform .1s, filter .1s;
    cursor: pointer;
    background: $dark;
    border-radius: 4px;

    &:hover {
        z-index: 2;
        transform: scale(1.1);

        img {
            filter: none;
        }
    }

    img {
        filter: grayscale(50%);
        pointer-events: none;
        border-radius: 4px;
        overflow: hidden;
    }

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        z-index: -1;
        border-radius: 6px;
        border: 1px solid transparent;
        transition: border-color .1s;
    }

    &.--selected {
        pointer-events: none;

        img {
            filter: none;
        }

        transform: scale(1.1);

        &:before {
            border-color: white;
        }
    }
}
</style>
