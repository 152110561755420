<template>
    <div
        :class="[
            'f-table__container',
            '--loader',
            (rowsHoverable ? '--rows-hoverable' : null),
            (integrateInputFields ? '--integrate-input-fields' : null),
            (allowOverflow ? '--allow-overflow --scrollable' : null),
            (alignCell ? `--align-cell-${alignCell}` : null),
            `--${type}`,
            className,
        ]"
    >
        <slot name="pre"/>

        <div
            v-if="!empty"
            :class="[
                'f-table__wrapper',
                (allowHorizontalScroll ? '--scrollable-themed-horizontal' : null),
            ]"
            @scroll="EventBus.$emit(GlobalEvents.OVERFLOW_SCROLL)"
        >
            <table class="f-table">
                <thead class="--no-select">
                <slot name="head"/>
                </thead>
                <tbody>
                <slot/>
                </tbody>
                <tfoot>
                <slot name="foot"/>
                </tfoot>
            </table>
        </div>

        <nothing-found
            v-else
            class-name="f-table__nothing-found"
        />

        <slot name="post"/>
    </div>
</template>

<script>
import {TableCellAlignments, TableTypes} from './config';
import NothingFound from '../../../components/misc/NothingFound';

export default {
    name: 'f-table',

    components: {
        NothingFound,
    },

    props: {
        className: String,
        rowsHoverable: Boolean,
        type: {
            type: String,
            default: TableTypes.DEFAULT,
        },
        integrateInputFields: Boolean,
        empty: Boolean,
        alignCell: {
            type: String,
            default: TableCellAlignments.MIDDLE,
        },
        allowOverflow: Boolean,
        allowHorizontalScroll: {
            type: Boolean,
            default: true,
        },
    },
}
</script>

<style lang="scss">
.f-table__container {
    .f-table {
        border-collapse: collapse;
        text-align: left;
        line-height: 120%;
        width: 100%;

        &__wrapper {
            overflow: hidden;

            &.--scrollable {
                overflow-x: auto;
            }
        }

        .f-table-header-cell {
            padding: 10px;

            //@include media(mobile-down) {
            //    padding: 6px;
            //}
        }

        .f-table-cell {
            padding: 20px 10px;

            //@include media(mobile-down) {
            //    padding: 12px 6px;
            //}

            &:first-child {
                padding-left: 20px !important;
                border-radius: 10px 0 0 10px;
            }

            &:last-child {
                padding-right: 20px !important;
                border-radius: 0 10px 10px 0;
            }
        }
    }

    // Types

    &.--default .f-table,
    &.--default-dense .f-table {
        border-spacing: 0 10px;
        border-collapse: separate;
    }

    &.--simple-dense .f-table,
    &.--default-dense .f-table {
        .f-table-header-cell {
            padding: 10px;

            //@include media(mobile-down) {
            //    padding: 5px;
            //}

            &:first-child {
                padding-left: 15px !important;
            }

            &:last-child {
                padding-right: 15px !important;
            }
        }

        .f-table-cell {
            padding: 10px;

            //@include media(mobile-down) {
            //    padding: 5px;
            //}

            &:first-child {
                padding-left: 15px !important;
            }

            &:last-child {
                padding-right: 15px !important;
            }
        }
    }

    // Settings

    .f-table__nothing-found {
        margin-top: 50px;
    }

    &.--align-cell-top {
        .f-table-header-cell, .f-table-cell {
            vertical-align: top;
        }
    }

    &.--align-cell-bottom {
        .f-table-header-cell, .f-table-cell {
            vertical-align: bottom;
        }
    }

    &.--integrate-input-fields {
        .f-table-cell {
            .f-abstract-input-field.--disabled,
            .f-abstract-input-field.--readonly,
            .f-abstract-input-field.--read-only {
                border-bottom-color: transparent !important;

                .f-icon-button {
                    display: none;
                }
            }
        }

        .f-table-row:not(:hover) {
            .f-abstract-input-field:not(.--focus) {
                border-bottom-color: transparent;
            }

            .f-abstract-input-field:not(.--focus) {
                .f-icon-button {
                    opacity: .1;
                }
            }
        }
    }
}
</style>
