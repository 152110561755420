<template>
    <f-table-row
        class="user-row"
        @click="$router.push(`/admin/users/${user.id}`)"
        :actions="actions"
    >
        <f-table-cell width="30px" no-padding-bottom-top no-padding-right>
            <entity-avatar
                size="large"
                :src="user.profile_image"
                :name="user.name"
            />
        </f-table-cell>
        <f-table-title-cell>
            <span class="--no-wrap">
                {{ user.name }}
            </span>
            <template #sub>
                {{ user.title }}

                <div class="user-row__type">
                    <f-chip
                        size="small"
                        :variant="(user.type === 'freelancer' ? 'dark' : 'secondary')"
                    >
                        {{ user.type }}
                    </f-chip>
                </div>
            </template>
        </f-table-title-cell>
        <f-table-cell>
            <department-discipline-path
                :discipline="user.discipline"
                :office="user.office"
                discipline-on-new-line
            />
        </f-table-cell>
        <f-table-contact-cell
            :email="user.email"
            :phone="user.phone_number_visual"
        />
        <f-table-cell align="center">
            <user-card-mini
                v-if="user.lead_user_id"
                :user="userById(user.lead_user_id)"
            />
        </f-table-cell>
        <f-table-cell align="center" class-name="user-row__status">
            <f-chip
                v-if="user.deleted_at"
                variant="danger"
            >
                Deleted
            </f-chip>
            <f-chip
                v-if="!user.activated_at && !user.deleted_at"
                variant="warning"
            >
                Inactive
            </f-chip>
            <f-chip
                v-if="user.activated_at && !user.deleted_at"
                variant="info"
            >
                Active
            </f-chip>
            <f-chip
                v-if="user.hide_from_list && !user.deleted_at && user.activated_at"
                variant="status"
            >
                Hidden
            </f-chip>
        </f-table-cell>
    </f-table-row>
</template>

<script>
import FTableRow from '../../../../../f-components/data/table/FTableRow';
import FTableTitleCell from '../../../../../f-components/data/table/FTableTitleCell';
import FTableCell from '../../../../../f-components/data/table/FTableCell';
import EntityAvatar from '../../../../misc/entities/EntityAvatar';
import FChip from '../../../../../f-components/data/chip/FChip';
import UserHelperMixin from '../../../../../mixins/UserHelperMixin';
import UserCardMini from '../../../../cards/users/UserCardMini';
import AdminUserMixin from '../AdminUserMixin';
import FTableContactCell from '../../../../../f-components/data/table/FTableContactCell';
import DepartmentDisciplinePath from '../../../../misc/department-discipline-path/DepartmentDisciplinePath';
import {Permissions} from '../../../../../config/Permissions';

export default {
    name: 'user-row',

    components: {
        DepartmentDisciplinePath,
        FTableContactCell,
        UserCardMini,
        FChip,
        EntityAvatar,
        FTableCell,
        FTableTitleCell,
        FTableRow,
    },

    mixins: [
        UserHelperMixin,
        AdminUserMixin,
    ],

    props: {
        user: {
            type: Object,
            required: true,
        },
    },

    computed: {
        actions() {
            const actions = [
                {
                    value: 'View',
                    icon: 'magnifier',
                    onClick: () => this.$router.push(`/admin/users/${this.user.id}`),
                },
            ];

            if (this.hasPermission(Permissions.ADMIN_USERS_EDIT)) {
                actions.push({
                    value: 'Edit',
                    icon: 'pencil',
                    onClick: () => this.$router.push(`/admin/users/edit/${this.user.id}`),
                });

                if (!this.user.activated_at && !this.user.deleted_at) {
                    actions.push({
                        value: 'Activate',
                        icon: 'pulse',
                        onClick: () => {
                            this.activateUser(this.user.id, () => this.$emit('refresh'));
                        },
                    });
                }

                if (this.user.activated_at && !this.user.deleted_at) {
                    actions.push({
                        value: 'Deactivate',
                        icon: 'skull',
                        onClick: () => {
                            this.deactivateUser(this.user.id, () => this.$emit('refresh'));
                        },
                    });
                }

                if (!this.user.deleted_at) {
                    actions.push({
                        value: 'Delete',
                        icon: 'trash2',
                        onClick: () => {
                            this.deleteUser(this.user.id, () => this.$emit('refresh'));
                        },
                    });
                }
            }

            return actions;
        },
    },
}
</script>

<style lang="scss">
.user-row {
    &__type {
        margin-top: 3px;
    }

    &__status {
        font-size: 0;

        .f-chip {
            font-size: 11px;
        }
    }
}
</style>
