const defaultState = {
    accessToken: null,
    time: {},
    $user: {
        id: 0,
        name: '',
        email: '',
        profile_image: '',
        office: 'amsterdam',
        permissions: [],
        settings: {
            'global.clock': '24',
            'global.holiday_leave_rep': 'hours',
            'global.number.format': 'dc',
            'widgets.traffic_today.enabled': true,
        },
    },
    $meetingRoom: null,
    $currentlyAtOffice: [],
    $publicHolidays: null,
    $calendar: [],
    $notifications: [],
    $projects: {
        filters: {},
    },
    $projectFavourites: [],
    $absenteesToday: {
        absentees: [],
    },
    $availableTrafficers: null,
    $leave: null,
    $adminLeaveRequests: {},

    // Collections
    $usersCollection: [],
    $freelancersCollection: [],
    $departmentsAndDisciplinesCollection: [],
    $clientsCollection: [],
    $officesCollection: [],
};

export default defaultState;
