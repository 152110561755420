<template>
    <div
        :class="[
            'skewed-section',
            `--${type}`,
            (reversed ? '--reversed' : null),
            ...classNames
        ]"
        :style="cssVars"
    >
        <div class="skewed-section__content --loader">
            <slot/>
        </div>
    </div>
</template>

<script>
import ClassNameMixin from '../../../mixins/ClassNameMixin';
import {SkewedSectionTypes} from './config';

export default {
    name: 'skewed-section',

    mixins: [
        ClassNameMixin,
    ],

    computed: {
        cssVars() {
            return {
                '--background-color': this.backgroundColor,
                '--foreground-color': this.foregroundColor,
                '--after-background': this.afterBackground,
                padding: this.padding,
                margin: this.margin,
            }
        },
    },

    props: {
        type: {
            type: String,
            default: SkewedSectionTypes.FULL,
        },
        reversed: Boolean,
        padding: String,
        margin: String,
        backgroundColor: {
            type: String,
            default: '#f3f3f3',
        },
        foregroundColor: {
            type: String,
            default: '#fff',
        },
        afterBackground: String,
    },
}
</script>

<style lang="scss">
.skewed-section {
    position: relative;

    &__content {
        position: relative;
        z-index: 3;
    }

    &.--full {
        min-height: 300px;

        &:before, &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transform: rotate(-1deg);
        }

        &:before {
            width: calc(100% + 100px);
            left: -50px;
            top: 20px;
            z-index: 1;
            border-left: 200px solid var(--after-background);
        }

        &:after {
            background: var(--foreground-color);
            border-radius: 15px;
            z-index: 2;
        }

        &.--reversed {
            &:before, &:after {
                transform: rotate(1deg);
            }
        }
    }

    &.--top-left {
        background: var(--foreground-color);

        &:before, &:after {
            content: "";
            position: absolute;
            left: 0;
            height: 300px;
            top: 0;
            transform-origin: right top;
            transform: rotate(-2.5deg);

            @include media(desktop-l-up) {
                transform: rotate(-2deg);
            }
        }

        &:before {
            background: var(--background-color);
            width: 100%;
            z-index: 1;
            height: 280px;
        }

        &:after {
            width: 100%;
            border-radius: 15px 0 0 0;
            z-index: 2;
            background: var(--foreground-color);
            left: 10px;
        }
    }

    &.--top-right {
        background: var(--foreground-color);

        &:before, &:after {
            content: "";
            position: absolute;
            left: 0;
            height: 300px;
            top: 0;
            transform-origin: left top;
            transform: rotate(-2.5deg);

            @include media(desktop-l-up) {
                transform: rotate(-2deg);
            }
        }

        &:before {
            background: var(--background-color);
            width: 100%;
            z-index: 1;
            height: 280px;
            left: 1px;
        }

        &:after {
            width: calc(100% - 10px);
            border-radius: 0 15px 0 0;
            z-index: 2;
            background: var(--foreground-color);
        }
    }

    &.--bottom-left {
        background: var(--foreground-color);

        &:before, &:after {
            content: "";
            position: absolute;
            left: -8px;
            height: 300px;
            bottom: 0;
            transform-origin: right top;
            transform: rotate(-2.5deg);

            @include media(desktop-l-up) {
                transform: rotate(-2deg);
            }
        }

        &:before {
            background: var(--background-color);
            width: 100%;
            z-index: 1;
            height: 280px;
            left: -15%;
        }

        &:after {
            width: 100%;
            border-radius: 0 0 0 15px;
            z-index: 2;
            background: var(--foreground-color);
        }
    }

    &.--bottom-right {
        background: var(--foreground-color);

        &:before, &:after {
            content: "";
            position: absolute;
            height: 300px;
            bottom: 0;
            transform-origin: left top;
            left: 1px;
            transform: rotate(-2.5deg);

            @include media(desktop-l-up) {
                transform: rotate(-2deg);
            }
        }

        &:before {
            background: var(--background-color);
            width: 110%;
            z-index: 1;
        }

        &:after {
            width: 100%;
            border-radius: 0 0 15px 0;
            z-index: 2;
            background: var(--foreground-color);
        }
    }
}
</style>
