<template>
    <div :class="['setting-text-input', '--active']">
        <div class="setting-text-input__label --no-select" v-if="label">
            {{ label }}
            <f-help-label
                v-if="help"
                :text="help"
            />
        </div>
        <f-text-input
            v-model="textValue"
            :placeholder="placeholder"
            :disabled="saving"
            align="right"
            width="40%"
            :pre-icon="(saving ? 'floppy-disk' : undefined)"
        />
    </div>
</template>

<script>
import FHelpLabel from '../../../../f-components/form-controls/forms/FHelpLabel';
import Store from '../../../../services/Store';
import FTextInput from '../../../../f-components/form-controls/text/FTextInput';

export default {
    name: 'setting-text-input',

    components: {
        FTextInput,
        FHelpLabel,
    },

    props: {
        label: String,
        help: String,
        storeCommitName: {
            type: String,
            default: 'userSetting',
        },
        settingKey: String,
        placeholder: String,
    },

    data: function () {
        return {
            textValue: this.$store.getters.getUserSetting(this.settingKey),
            timeout: null,
            saving: false,
        }
    },

    watch: {
        textValue(value) {
            if (this.timeout) window.clearTimeout(this.timeout);
            this.timeout = window.setTimeout(() => this.save(value), 750);
        },
    },

    methods: {
        save(value) {
            if (!this.settingKey) return;

            this.saving = true;
            this.$emit('lock');
            this.$emit('change', value);

            // Save setting
            const setting = {
                key: this.settingKey,
                value: value,
            };
            if (this.storeCommitName && this.settingKey) {
                Store.commit(this.storeCommitName, setting);
            }

            // Unlock it
            window.setTimeout(() => {
                this.saving = false;
                this.$emit('unlock');
            }, 500);
        },
    },
}
</script>

<style lang="scss">
.setting-text-input {
    display: flex;
    justify-content: space-between;
    width: auto;

    &__label {
        opacity: .5;
        padding-top: 12px;
        transition: opacity .15s ease-in-out;
    }

    &.--active .setting-text-input__label {
        opacity: .9;
    }
}
</style>
