<template>
    <div v-if="!permission || hasPermission(permission)">
        <button
            v-if="type === 'submit'"
            type="submit"
            ref="submit"
            class="__submit-proxy"
            :disabled="disabled"
        />
        <f-portal reference="entity-action-button">
            <f-icon-button
                @click="onClick"
                size="xlarge"
                variant="primary"
                :icon="icon"
                :disabled="disabled"
                type="button"
                fixed
                v-tooltip="{
                    text: tooltip,
                    offsetTop: -5,
                    variant: 'dark',
                    position: 'top',
                }"
            />
        </f-portal>
    </div>
</template>

<script>
import FIconButton from '../../../f-components/form-controls/buttons/FIconButton';
import FPortal from '../../../f-components/other/portal/FPortal';
import {Icons} from '../../../f-components/layout/icons/config';

export default {
    name: 'entity-action-button',

    components: {
        FPortal,
        FIconButton,
    },

    props: {
        submit: Boolean,
        disabled: Boolean,
        type: {
            type: String,
            default: 'button',
        },
        tooltip: {
            type: String,
            default: 'Create',
        },
        permission: String,
        route: String,
        icon: {
            type: String,
            default: Icons.PLUS,
        },
    },

    methods: {
        onClick(e) {
            this.$emit('click', e);

            if (this.type === 'submit') {
                this.$refs.submit.click();
            } else if (this.route) {
                this.$router.push(this.route);
                return false;
            }
        },
    },
}
</script>

<style lang="scss">
.__submit-proxy {
    visibility: hidden;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
}
</style>
