var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f-portal',[_c('div',{class:[
        'f-site-loader',
        (_vm.smallLoadStart ? '--small-start' : null),
        (_vm.smallLoadAnimateStart ? '--small-animate-start' : null),
        (_vm.bigLoadStart ? '--big-start' : null),
        (_vm.bigLoadAnimateStart ? '--big-animate-start' : null),
        (_vm.loadEnd ? '--end' : null),
        (_vm.animateEnd ? '--animate-end' : null),
    ]},[_c('div',{staticClass:"f-site-loader__progress"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }