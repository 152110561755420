<template>
    <widget
        v-if="enabled"
        class-name="leave-widget"
        title="Leave requests"
        :enabled-setting="(testData ? null : 'widgets.time_off.enabled')"
        extra-break-points
    >
        <!--        <template #header-post>-->
        <!--            <f-button-group-->
        <!--                v-if="!error"-->
        <!--                variant="primary"-->
        <!--            >-->
        <!--                <f-button-->
        <!--                    value="Request"-->
        <!--                    @click="modalActive = true"-->
        <!--                    variant="primary"-->
        <!--                    :size="(breakpoints.mobile ? 'small' : 'medium')"-->
        <!--                />-->
        <!--                <f-button-->
        <!--                    v-if="data.requests && data.requests.length > 1"-->
        <!--                    value="View all"-->
        <!--                    variant="primary"-->
        <!--                    :size="(breakpoints.mobile ? 'small' : 'medium')"-->
        <!--                    @click="allRequestsModalOpen = true"-->
        <!--                />-->
        <!--            </f-button-group>-->
        <!--        </template>-->

        <!--        <f-inline-message-->
        <!--            v-if="error"-->
        <!--            id="timeOffError"-->
        <!--            variant="error"-->
        <!--            :closable="false"-->
        <!--        >-->
        <!--            <span v-html="error"/>-->
        <!--        </f-inline-message>-->

        <!--        <f-carousel-->
        <!--            auto-size-->
        <!--            navigation-type="prev-next"-->
        <!--            swipe-->
        <!--            @slideChange="slideId = $event"-->
        <!--        >-->
        <!--            <f-carousel-slide id="productive" title="Productive">-->

        <f-inline-message
            id="productiveInfoMessage"
            :closable="false"
            :style="{ margin: 0 }"
        >
            <p>
                We have migrated all leave administration to Productive, our new agency management tool.
            </p>
            <p>
                Requesting leave via Riviera is therefore disabled. You can now request leave via Productive.
                If you have any questions, please check the <a href="https://riviera.codedazur.io/faq#item-119">FAQ</a>.
            </p>
            <f-button
                tag="a"
                href="https://app.productive.io/22699"
                target="_blank"
                style="border-color: rgba(255, 255, 255, .3);"
                :icon="Icons.LAUNCH"
                icon-position="right"
                value="To Productive"
            />
        </f-inline-message>

        <!--            </f-carousel-slide>-->
        <!--            <f-carousel-slide v-if="!error" id="balance" title="Balance">-->
        <!--                <leave-balance :data="data"/>-->
        <!--            </f-carousel-slide>-->
        <!--            <f-carousel-slide v-if="!error" id="overview" title="Overview">-->
        <!--                <leave-overview :data="data"/>-->
        <!--            </f-carousel-slide>-->
        <!--        </f-carousel>-->

        <!--        <f-modal-->
        <!--            v-if="modalActive"-->
        <!--            @close="modalActive = false"-->
        <!--            :title="`Request ${leaveRequestType.replace('_', ' ').replace('leave', '')} leave`"-->
        <!--            class-name="request-leave-modal"-->
        <!--        >-->
        <!--            <request-leave-modal-content-->
        <!--                v-model="leaveRequestType"-->
        <!--                @close="modalActive = false"-->
        <!--            />-->
        <!--        </f-modal>-->

        <!--        <f-modal-->
        <!--            v-if="allRequestsModalOpen && data.requests"-->
        <!--            @close="allRequestsModalOpen = false"-->
        <!--            title="Your leave requests"-->
        <!--        >-->
        <!--            <leave-request-table :data="data.requests"/>-->
        <!--        </f-modal>-->
    </widget>
</template>

<script>
import Widget from '../Widget';
import FButton from '../../../f-components/form-controls/buttons/FButton';
import FInlineMessage from '../../../f-components/notice/inline-message/FInlineMessage';
import FModal from '../../../f-components/other/modal/FModal';
import FButtonGroup from '../../../f-components/form-controls/buttons/FButtonGroup';
import BreakpointsMixin from '../../../mixins/BreakpointsMixin';
import {Icons} from '../../../f-components/layout/icons/config';

export default {
    name: 'leave-widget',

    mixins: [
        BreakpointsMixin,
    ],

    components: {
        FButtonGroup,
        FModal,
        Widget,
        FInlineMessage,
        FButton,
    },

    props: {
        testData: Object,
    },

    created() {
        if (!this.testData) {
            // APIDataStore.check(APIDataStoreResources.LEAVE);
            // APIDataStore.check(APIDataStoreResources.PUBLIC_HOLIDAYS);
        }
    },

    computed: {
        Icons() {
            return Icons;
        },
        enabled() {
            return true;

            // if (this.testData) {
            //     return true;
            // }
            // return this.$store.state.$leave && this.$store.state.$user && this.$store.state.$user.office === 'amsterdam';
        },
        error() {
            if (this.testData) {
                return false;
            }
            if (this.$store.state.$leave && this.$store.state.$leave.error) {
                return this.$store.state.$leave.error;
            }
            return false;
        },
        data() {
            if (this.testData) {
                return this.testData;
            }
            if (this.$store.state.$leave) {
                return this.$store.state.$leave;
            }
            return {};
        },
        year() {
            return 2022;
        },
    },

    data() {
        return {
            modalActive: false,
            allRequestsModalOpen: false,
            actionsOpen: false,
            leaveRequestType: 'holiday',
            // slideId: undefined,
        };
    },
}
</script>

<style lang="scss">
.leave-widget {
    text-align: center;

    .f-carousel {
        text-align: left;
    }

    .f-inline-message {
        margin: 0 0 10px 0;
    }
}

.request-leave-modal {
    .f-modal__container {
        width: 600px;

        .f-panel__content {
            overflow: hidden;
            height: 400px;

            > .f-scroll-pane {
                height: 100%;

                .f-scroll-pane__content {
                    height: 100%;
                }
            }

            @include media(mobile-down) {
                height: auto;

                > .f-scroll-pane {
                    height: auto;

                    .f-scroll-pane__content {
                        height: auto;
                        max-height: 80vh;
                    }
                }
            }
        }
    }
}
</style>
