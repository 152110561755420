export default {
    methods: {
        holidayLeaveFormat(total) {
            if (this.getHolidayLeaveRep() === 'days') {
                total = total / 8;
            }

            return this.formatNumber(total, false);
        },

        getHolidayLeaveRep() {
            return (this.$store && this.$store.getters ? this.$store.getters.getUserSetting('global.holiday_leave_rep') : 'days');
        },
    },
};
