export const TooltipVariants = {
    PRIMARY: 'primary',
    WARNING: 'warning',
    DANGER: 'danger',
    INFO: 'info',
    DARK: 'dark',
    /**
     * @deprecated
     */
    ALTERNATIVE: 'alternative',
    /**
     * @deprecated
     */
    DEFAULT: 'primary',
}
