<template>
    <section :class="['f-placeholder-container', ...classNames]">
        <slot/>
    </section>
</template>

<script>
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-placeholder-container',

    mixins: [
        ClassNameMixin,
    ],
}
</script>

<style lang="scss">
.f-placeholder-container {
    .--fill {
        animation-duration: 6s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeholder-text-animation;
        animation-timing-function: linear;

        background-color: transparent !important;
        background: linear-gradient(to right, rgba($tertiaryGray, .5) 8%, $tertiaryGray 18%, rgba($tertiaryGray, .5) 33%);
        background-size: 1400px 104px !important;
        background-repeat: repeat !important;
        position: relative;
    }
}

@-webkit-keyframes placeholder-text-animation {
    0% {
        background-position: -1400px 0;
    }
    100% {
        background-position: 1400px 0;
    }
}

@keyframes placeholder-text-animation {
    0% {
        background-position: -1400px 0;
    }
    100% {
        background-position: 1400px 0;
    }
}
</style>
