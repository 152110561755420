<template>
    <transition name="slide-right" apear>
        <f-theme-provider
            v-if="active"
            class="profile-settings-pane --no-select"
            variant="dark"
            id="profileSettingsPane"
        >
            <f-close @click="close"/>

            <header class="profile-settings-pane__header">
                <h2><em>Settings</em></h2>
            </header>

            <div
                class="profile-settings-pane__content --scrollable-dark"
                @scroll="EventBus.$emit(GlobalEvents.OVERFLOW_SCROLL)"
            >
                <div class="profile-settings-pane__content__inner">
                    <f-text tag="h3" type="subtitle">
                        General
                    </f-text>

                    <setting-select
                        :options="[
                            {key: '12', value: '12-hour'},
                            {key: '24', value: '24-hour'}
                        ]"
                        clas="center"
                        label="Clock"
                        setting-key="global.clock"
                    />
                    <!--                    <setting-select-->
                    <!--                        :options="[-->
                    <!--                            {key: 'hours', value: 'in hours'},-->
                    <!--                            {key: 'days', value: 'in days'}-->
                    <!--                        ]"-->
                    <!--                        clas="center"-->
                    <!--                        label="Holiday leave"-->
                    <!--                        setting-key="global.holiday_leave_rep"-->
                    <!--                        help="Show remaining holiday leave<br>in hours or days"-->
                    <!--                    />-->
                    <setting-switch
                        :is-active="$store.getters.getUserSetting('global.hide_birth_year')"
                        label="Hide birth year"
                        setting-key="global.hide_birth_year"
                        help="If you are not<br>happy with your age"
                    />

                    <settings-pronouns-select
                        help="We’re committed to building a more inclusive culture, and correct gender pronouns play an important role in creating a welcoming environment for members of all gender identities. Add your gender pronouns to your profile to let others know how to refer to you"
                        label="Pronouns"
                    />

                    <!--                    <div class="profile-settings-pane__divider"/>-->

                    <!--                    <f-text tag="h3" type="subtitle">-->
                    <!--                        Widgets-->
                    <!--                    </f-text>-->

                    <!--                    <setting-switch-->
                    <!--                        v-if="$store.state.$user.office === 'amsterdam'"-->
                    <!--                        label="Holiday leave"-->
                    <!--                        setting-key="widgets.time_off.enabled"-->
                    <!--                    />-->
                    <!--                    <setting-switch-->
                    <!--                        v-if="['amsterdam', 'austin'].includes($store.state.$user.office)"-->
                    <!--                        label="Meeting rooms"-->
                    <!--                        setting-key="widgets.meeting_rooms.enabled"-->
                    <!--                    />-->
                    <!--                    <setting-switch-->
                    <!--                        v-if="['amsterdam'].includes($store.state.$user.office)"-->
                    <!--                        label="Currently at the office"-->
                    <!--                        setting-key="widgets.currently_at_office.enabled"-->
                    <!--                    />-->
                    <!--                    <setting-switch-->
                    <!--                        label="Absentees"-->
                    <!--                        setting-key="widgets.absentees.enabled"-->
                    <!--                    />-->
                    <!--                    <setting-switch-->
                    <!--                        label="Upcoming birthdays"-->
                    <!--                        setting-key="widgets.birthdays.enabled"-->
                    <!--                    />-->
                    <!--                    <setting-switch-->
                    <!--                        label="New people"-->
                    <!--                        setting-key="widgets.new_people.enabled"-->
                    <!--                    />-->
                    <!--                    <setting-switch-->
                    <!--                        label="Today's calendar"-->
                    <!--                        setting-key="widgets.calendar.enabled"-->
                    <!--                    />-->
                    <!--                    <setting-switch-->
                    <!--                        label="Offices &amp; weather"-->
                    <!--                        setting-key="widgets.offices.enabled"-->
                    <!--                    />-->
                    <!--                    <setting-switch-->
                    <!--                        label="Traffic available today"-->
                    <!--                        setting-key="widgets.traffic_today.enabled"-->
                    <!--                    />-->
                    <!--                    <setting-switch-->
                    <!--                        label="Public holidays"-->
                    <!--                        setting-key="widgets.public_holidays.enabled"-->
                    <!--                    />-->

                    <div class="profile-settings-pane__divider"/>

                    <f-button
                        v-if="$store.state.$user && $store.state.$user.email_signature"
                        icon="envelope"
                        class-name="profile-settings-pane__signature"
                        @click="signatureModalOpen = true"
                        value="Email signature"
                        variant="primary"
                        full-width
                    />
                </div>
            </div>

            <email-signature-modal
                v-if="signatureModalOpen"
                @close="signatureModalOpen = false"
            />
        </f-theme-provider>
    </transition>
</template>

<script>
import SettingSwitch from './components/SettingSwitch';
import SettingSelect from './components/SettingSelect';
import FButton from '../../../f-components/form-controls/buttons/FButton';
import FClose from '../../../f-components/navigation/close/FClose';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import EmailSignatureModal from './components/EmailSignatureModal';
import FThemeProvider from '../../../f-components/layout/theme/FThemeProvider';
import FText from '../../../f-components/layout/text/FText';
import SettingsPronounsSelect from './components/SettingsPronounsSelect';

export default {
    name: 'profile-settings-pane',

    components: {
        SettingsPronounsSelect,
        FText,
        FThemeProvider,
        EmailSignatureModal,
        FClose,
        SettingSelect,
        FButton,
        SettingSwitch,
    },

    data() {
        return {
            signatureModalOpen: false,
            active: false,
        };
    },

    created() {
        EventBus.$on('profile.open', this.open);
        EventBus.$on('profile.close', this.close);
        EventBus.$on(GlobalEvents.WINDOW_RESIZE, this.close);
        EventBus.$on(GlobalEvents.PAGE_CHANGE, this.close);
        EventBus.$on(GlobalEvents.KEYDOWN_ESC, this.close);
    },

    beforeDestroy() {
        EventBus.$off('profile.open', this.open);
        EventBus.$off('profile.close', this.close);
        EventBus.$off(GlobalEvents.WINDOW_RESIZE, this.close);
        EventBus.$off(GlobalEvents.PAGE_CHANGE, this.close);
        EventBus.$off(GlobalEvents.KEYDOWN_ESC, this.close);
    },

    methods: {
        open() {
            this.active = true;
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },

        close() {
            this.active = false;
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },
    }
}
</script>

<style lang="scss">
.profile-settings-pane {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 400px;
    overflow: hidden;
    z-index: $slideInPaneOrder;
    background: $dark;
    box-shadow: rgba($dark, .05) 0 0 5px;

    padding: 25px 15px 18px 28px;
    @include media(mobile-down) {
        padding: 20px 10px 20px 20px !important;
    }

    .f-button:focus, .f-button:hover, .f-button:active {
        box-shadow: 0 0 0 2px rgba(black, .2);
    }

    .f-close {
        top: 30px;
        right: 30px;

        @include media(mobile-down) {
            top: 22px;
            right: 27px;
        }
    }

    &__header {
        padding: 0 20px 30px 0;

        h2 {
            margin: 0;
            width: 100%;
        }
    }

    &__content {
        height: 100%;
        max-height: calc(100% - 60px);

        &__inner {
            padding: 0 15px 2px 2px;
        }

        h3 {
            padding-bottom: 10px;
            opacity: .5;
        }

        .profile-settings-pane__divider {
            width: 100%;
            height: 1px;
            margin: 25px 0;
            background-color: rgba(#fff, .1);
        }

        .workload {
            label {
                display: block;
                opacity: .9;
                margin-bottom: 15px;
            }

            .f-button {
                margin-bottom: 0 !important;
            }
        }

        .f-button:not(.signature-button) {
            margin-bottom: 10px;
        }

        .setting-select .f-select-input .input-field {
            width: 120px;
        }
    }

    @include media(mobile-down) {
        width: 100%;
    }
}
</style>
