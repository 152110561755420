<template>
    <div
        :class="[
            'user-card',
            className,
            (menuOnHover ? '--menu-hover' : null),
            (menuOpen ? '--menu-open' : null),
            `--theme-${variant}`,
            `--${variant}`,
        ]"
    >
        <div class="user-card__image-header">
            <entity-avatar
                :name="user.name"
                :src="user.profile_image"
                :birthday="hasBirthday"
                size="xxlarge"
            />
            <f-icon-button
                v-if="menuOptions.length > 0"
                ref="menuButton"
                @click.stop="menuOpen = true"
                :selected="menuOpen"
                icon="ellipsis"
                size="small"
                variant="light"
                outlined
            />
        </div>

        <div class="user-card__body">
            <slot/>

            <div class="user-card__body__name">
                {{ user.name }}
            </div>
            <div v-if="user.title" class="user-card__body__title">
                {{ user.title }}
                <span v-if="user.type && user.type === 'freelancer'">
                    (freelancer)
                </span>
            </div>
            <div
                v-if="user.pronouns && user.pronouns !== 'none'"
                class="user-card__body__pronouns"
            >
                ({{ user.pronouns }})
            </div>

            <div class="user-card__body__details">
                <div
                    v-if="user.email"
                    class="user-card__body__email"
                >
                    <f-icon icon="envelope"/>
                    <a
                        :href="`mailto:${user.email}`"
                        v-text="user.email"
                        target="_blank"
                    />
                </div>
                <div
                    v-if="user.internal_email"
                    class="user-card__body__email"
                >
                    <f-icon icon="envelope"/>
                    <a
                        :href="`mailto:${user.internal_email}`"
                        v-text="user.internal_email"
                        target="_blank"
                    />
                </div>
                <div
                    v-if="(user.phone_number_visual !== '?' && user.phone_number_visual !== '')"
                    class="user-card__body__telephone"
                >
                    <f-icon icon="telephone2"/>
                    <a
                        :href="`tel:${user.phone_number_visual}`"
                        v-text="user.phone_number_visual"
                        target="_blank"
                    />
                </div>
                <div
                    v-if="user.office"
                    class="user-card__body__office"
                >
                    <f-icon icon="briefcase"/>
                    {{ user.office }}
                </div>
                <div
                    v-if="(type === 'employees' && user.date_of_birth_visual !== '' && user.date_of_birth_visual)"
                    class="user-card__body__date-of-birth"
                >
                    <f-icon icon="gift"/>
                    {{ user.date_of_birth_visual }}
                </div>
                <div
                    v-if="user.city && user.city !== ''"
                    class="user-card__body__city"
                >
                    <f-icon icon="home"/>
                    {{ user.city }}
                </div>
            </div>

            <div
                v-if="user.slack_user_id"
                class="user-card__body__actions"
            >
                <f-button
                    tag="a"
                    target="_blank"
                    size="small"
                    :href="`slack://user?team=T02EQNMKZ&id=${user.slack_user_id}`"
                >
                    <template #pre>
                        <div class="slack-icon"/>
                    </template>
                    <span class="f-button__value__text">
                        @{{ (user.slack_display_name ? user.slack_display_name : user.name) }}
                    </span>
                </f-button>
            </div>

            <div
                v-if="hasDescription"
                class="user-card__body__description --no-select"
            >
                <div
                    class="user-card__body__description__content"
                    v-html="user.description"
                />
                <f-icon-button
                    ref="descriptionButton"
                    icon="plus"
                    size="xsmall"
                    variant="primary"
                    tooltip="Read more"
                    @click="descriptionPopupOpen = true"
                />
            </div>

            <div
                v-if="(type === 'freelancers' && user.current_period && user.current_period.description)"
                class="user-card__body__working-on"
            >
                Working on<br/>
                <span>{{ user.current_period.description }}</span>
            </div>
        </div>

        <f-popup
            v-if="menuOpen && menuOptions.length > 0 && $refs.menuButton"
            :target="$refs.menuButton.$el"
            :class-name="popupClassName"
            @close="menuOpen = false"
            padding="0"
            variant="alternative"
        >
            <f-popup-list>
                <f-popup-list-item
                    v-for="(option, i) in menuOptions"
                    :key="i"
                    :icon="option.icon"
                    :link="option.href"
                    @click="option.onClick ? option.onClick() : null"
                >
                    {{ option.value }}
                </f-popup-list-item>
            </f-popup-list>
        </f-popup>

        <f-popup
            v-if="descriptionPopupOpen && hasDescription && $refs.descriptionButton"
            :target="$refs.descriptionButton.$el"
            :class-name="popupClassName"
            @close="descriptionPopupOpen = false"
            variant="primary"
            padding="20px 10px 20px 20px"
            width="240px"
        >
            <f-scroll-pane max-height="200px" variant="light">
                <f-content
                    class-name="user-card__popup-description"
                    v-html="user.description"
                />
            </f-scroll-pane>
        </f-popup>
    </div>
</template>

<script>
import FIconButton from '../../../f-components/form-controls/buttons/FIconButton';
import UserCardHelperMixin from '../../../mixins/UserCardHelperMixin';
import EntityAvatar from '../../misc/entities/EntityAvatar';
import FPopup from '../../../f-components/other/popup/FPopup';
import FPopupList from '../../../f-components/other/popup/FPopupList';
import FPopupListItem from '../../../f-components/other/popup/FPopupListItem';
import FContent from '../../../f-components/layout/content/FContent';
import FScrollPane from '../../../f-components/other/scroll-pane/FScrollPane';
import {Permissions} from '../../../config/Permissions';
import FIcon from '../../../f-components/layout/icons/FIcon';
import {UserCardVariants} from './config';
import FButton from '../../../f-components/form-controls/buttons/FButton';

export default {
    name: 'user-card',

    components: {
        FButton,
        FIcon,
        FScrollPane,
        FContent,
        FPopupListItem,
        FPopupList,
        FPopup,
        EntityAvatar,
        FIconButton,
    },

    props: {
        user: Object,
        className: String,
        type: {
            type: String,
            default: 'employees', // employees | freelancers
        },
        menuOnHover: Boolean,
        popupClassName: String,
        variant: {
            type: String,
            default: UserCardVariants.DARK,
        },
    },

    mixins: [
        UserCardHelperMixin,
    ],

    computed: {
        hasDescription() {
            return this.type === 'employees' && this.user.description && this.user.description !== '<p></p>' && this.user.description !== '<p><br></p>';
        },
        menuOptions() {
            const options = [];

            if (
                this.hasPermission(Permissions.ADMIN_USERS_VIEW) &&
                this.type !== 'freelancers' &&
                this.user.type !== 'freelancer'
            ) {
                options.push({
                    value: 'Admin: View user',
                    icon: 'cog',
                    href: `/admin/users/${this.user.id}`,
                });
            }

            // if (this.user.forecast_id) {
            //     options.push({
            //         href: `https://forecastapp.com/674955/schedule/team?filter=${encodeURIComponent(this.user.name)}`,
            //         value: 'Forecast planning',
            //         icon: 'launch',
            //     });
            //
            //     if (this.hasPermission(Permissions.WORKLOAD_VIEW) && this.user.workload_calendar_id) {
            //         options.push({
            //             href: `https://www.google.com/calendar/hosted/codedazur.com?cid=${this.user.workload_calendar_id}`,
            //             value: 'Workload feed (beta)',
            //             icon: 'calendar-user',
            //         });
            //     }
            // }

            if (this.user.email) {
                options.push({
                    href: `mailto:${this.user.email}`,
                    value: 'Send email',
                    icon: 'envelope',
                });
            }

            return options;
        },
    },

    data() {
        return {
            menuOpen: false,
            descriptionPopupOpen: false,
        }
    },
}
</script>

<style lang="scss">
.user-card {
    border-radius: 16px 16px 15px 15px;
    vertical-align: middle;
    display: inline-block;
    width: 100%;

    .slack-icon {
        width: 18px;
        height: 18px;
        background: url(../../../img/slack.png);
        background-size: cover;
        margin-right: 10px;
    }

    .f-button__value__text {
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 2px 0;
    }

    @include media(mobile-down) {
        font-size: 80%;
    }

    &.--light {
        background: $light;

        .user-card__body__details {
            color: $secondaryGray;

            a {
                color: $secondaryGray !important;
            }
        }
    }

    &.--dark {
        background: $dark;

        .user-card__body__details {
            color: $tertiaryGray;

            a {
                color: $tertiaryGray !important;
            }
        }
    }

    &__image-header {
        display: block;
        height: 80px;
        margin-bottom: 60px;
        position: relative;
        background: url(../../../img/gradient.jpg);
        background-size: cover;
        border-radius: 15px 15px 0 0;

        @include media(mobile-down) {
            height: 60px;
            margin-bottom: 50px;
        }

        .f-icon-button {
            position: absolute;
            top: 10px;
            right: 10px;
        }

        .entity-avatar {
            position: absolute;
            left: 50%;
            bottom: -50px;
            margin-left: -50px;
            background-color: grey;

            @include media(mobile-down) {
                width: 80px;
                height: 80px;
                bottom: -40px;
                margin-left: -40px;
            }
        }
    }

    &.--menu-hover {
        .user-card__image-header .f-icon-button {
            opacity: 0;
        }

        &:hover, &.--menu-open {
            .user-card__image-header .f-icon-button {
                opacity: 1;
            }
        }
    }

    &__body {
        overflow: hidden;
        padding: 5px 20px 25px;
        text-align: center;
        line-height: 110%;

        @include media(mobile-down) {
            padding: 5px 10px 15px;
        }

        &__name {
            font-size: 150%;
            padding-bottom: 2px;
            font-weight: 600;
            line-height: 120%;
        }

        &__title {
            font-size: 110%;
            font-weight: 400;
            line-height: 120%;
        }

        &__pronouns {
            padding-top: 4px;
            font-size: 95%;
            opacity: .65;
        }

        &__details {
            font-size: 95%;
            padding-top: 12px;

            > div {
                padding: 2px 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                a {
                    text-decoration: underline;
                }
            }

            > div:not(.user-card__body__description) .f-icon {
                line-height: 100%;
                padding-right: 5px;
                font-weight: bold;
            }
        }

        &__actions {
            display: flex;
            padding-top: 12px;
            flex-direction: column;
            gap: 2px;
        }

        &__office {
            text-transform: capitalize;
        }

        &__working-on {
            padding-top: 10px;
            white-space: normal;

            span {
                opacity: .5;
            }
        }

        &__description {
            position: relative;

            &__content {
                white-space: normal;
                opacity: .5;
                line-height: 120%;
                margin-top: 13px;
                width: 100%;
                height: 45px;
                overflow: hidden;
                text-overflow: ellipsis;

                @include media(mobile-down) {
                    height: 37px;
                }
            }

            .f-icon-button {
                position: absolute;
                bottom: 2px;
                right: 0;
                box-shadow: 0 0 10px 10px $dark !important;
            }
        }
    }

    &__popup-description {
        overflow: hidden;
        white-space: normal;
    }
}
</style>
