<template>
    <div
        :class="[
            'f-center',
            ...(Array.isArray(className) ? className : [className]),
             (fixed ? '--fixed' : null),
        ]"
        :style="{ alignItems }"
    >
        <slot/>
    </div>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
export default {
    name: 'f-center',

    props: {
        fixed: Boolean,
        className: String | Array,
        alignItems: {
            type: String,
            default: 'center',
        },
    },
}
</script>

<style lang="scss">
.f-center {
    height: 100%;
    width: 100%;
    max-width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;

    &.--fixed {
        position: fixed;
        top: 0;
        left: 0;
    }
}
</style>
