<template>
    <li class="f-breadcrumb">
        <router-link
            v-if="link"
            :to="link"
            class="f-breadcrumb__content"
        >
            <f-text v-if="!loading">
                <slot/>
            </f-text>
            <f-placeholder-container v-else>
                <f-placeholder width="60px"/>
            </f-placeholder-container>
        </router-link>
        <span
            v-if="!link"
            class="f-breadcrumb__content --inactive"
        >
            <f-text v-if="!loading">
                <slot/>
            </f-text>
            <f-placeholder-container v-else>
                <f-placeholder width="60px"/>
            </f-placeholder-container>
        </span>
        <slot name="post"/>
    </li>
</template>

<script>
import FPlaceholder from "../../loaders/placeholder/FPlaceholder";
import FPlaceholderContainer from "../../loaders/placeholder/FPlaceholderContainer";
import FText from '../../layout/text/FText';

export default {
    name: 'f-breadcrumb',

    components: {
        FText,
        FPlaceholderContainer,
        FPlaceholder,
    },

    props: {
        link: String,
        loading: Boolean,
    },
}
</script>

<style lang="scss">
.f-breadcrumb {
    display: inline-block;
    vertical-align: middle;
    color: $quaternaryGray;

    a:hover {
        text-decoration: underline;
    }

    &__content {
        display: inline-block;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;

        @include media(mobile-down) {
            max-width: 200px;
        }
    }

    &:not(:last-child) {
        &:after {
            font-size: 9px;
            display: inline-block;
            content: '\ec3c';
            opacity: .65;
            margin-left: 8px;
            margin-right: 6px;
            margin-top: -2px;
            vertical-align: middle;
        }
    }

    .f-placeholder-container {
        display: inline-block;
        vertical-align: middle;
    }
}
</style>
