<template>
    <button
        type="button"
        class="hamburger"
        @click="EventBus.$emit('mobile.menu.open')"
    >
        <f-icon icon="menu"/>
    </button>
</template>

<script>
import FIcon from '../../../f-components/layout/icons/FIcon';

export default {
    name: 'hamburger',

    components: {
        FIcon,
    },
}
</script>

<style lang="scss">
.hamburger {
    background: none;
    border: 0;
    font-size: 180%;
    color: $dark;

    &:hover {
        opacity: .75;
    }
}
</style>
