var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.active)?_c('f-flex',{class:[
        'f-inline-message',
        `--${_vm.variant}`,
        `--${_vm.type}`,
        (_vm.closable ? '--closable' : null),
        ..._vm.classNames,
        '--theme-light',
    ],attrs:{"gap":(_vm.type === 'dense' ? '8px' : '15px'),"cross-axis-align":"center","main-axis-align":"start"}},[_c('f-icon',{attrs:{"icon":_vm.icon}}),_c('div',{class:[
            'f-inline-message__body',
             _vm.bodyClassName,
         ]},[_vm._t("default"),(_vm.closable)?_c('f-close',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Dismiss'),expression:"'Dismiss'"}],on:{"click":_vm.close}}):_vm._e()],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }