var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasPermission(_vm.Permissions.ADMIN_GALLERIES))?_c('page',{attrs:{"class-name":"admin-pages-edit-create-page","loading":_vm.loading,"title":['Admin', 'Pages', _vm.mode.ucfirst()],"header":`${_vm.mode.ucfirst()} page`,"stretch-first-container":"","pre-max-width":"800px","actions":[{
        icon: 'trash2',
        value: 'Delete',
        onClick: () => _vm.deletePage(_vm.$route.params.id, _vm.fields.title),
    }, {
        icon: 'link',
        value: 'Deeplink',
        onClick: () => _vm.openDeeplink(_vm.fetchedData ? _vm.fetchedData.data.deeplink : ''),
    }]},scopedSlots:_vm._u([{key:"pre",fn:function(){return [_c('f-breadcrumbs',[_c('f-breadcrumb',[_vm._v("Admin")]),_c('f-breadcrumb',{attrs:{"link":"/admin/pages"}},[_vm._v(" Pages ")]),(_vm.mode === 'edit')?_c('f-breadcrumb',{attrs:{"loading":!_vm.dataLoaded}},[_vm._v(" "+_vm._s(_vm.fields.title)+" ")]):_vm._e(),_c('f-breadcrumb',[_vm._v(" "+_vm._s(_vm.mode)+" ")])],1)]},proxy:true}],null,false,3415311526)},[_c('f-form',{ref:"form",attrs:{"enctype":"multipart/form-data","validate":""},on:{"submitValid":_vm.submit}},[_c('f-container',{attrs:{"max-width":"800px"}},[(_vm.dataLoaded)?_c('div',[_c('f-form-row',{attrs:{"label":"Title"}},[_c('f-text-input',{attrs:{"name":"title","validation":['required']},on:{"blur":_vm.setSlug},model:{value:(_vm.fields.title),callback:function ($$v) {_vm.$set(_vm.fields, "title", $$v)},expression:"fields.title"}})],1),_c('f-form-row',{attrs:{"label":"Slug"}},[_c('f-text-input',{attrs:{"name":"slug","validation":['required']},on:{"blur":_vm.verifySlug},model:{value:(_vm.fields.slug),callback:function ($$v) {_vm.$set(_vm.fields, "slug", $$v)},expression:"fields.slug"}})],1),_c('f-form-row',{attrs:{"label":"Template"}},[_c('f-dropdown',{attrs:{"options":[{
                            key: 'newsletter',
                            value: 'Newsletter',
                        }],"name":"template","width":"230px"},model:{value:(_vm.fields.template),callback:function ($$v) {_vm.$set(_vm.fields, "template", $$v)},expression:"fields.template"}})],1),_c('f-form-row',{attrs:{"label":"Cover"}},[_c('f-image-input',{ref:"coverImageUploadField",attrs:{"type":"square","name":"cover image","src":_vm.fetchedData?.data?.cover_image_url}})],1),_c('f-form-row-spacer'),_c('f-editor',{attrs:{"name":"description","toolbar":_vm.getEditorToolbar(),"max-content-height":"50vh"},model:{value:(_vm.fields.body),callback:function ($$v) {_vm.$set(_vm.fields, "body", $$v)},expression:"fields.body"}})],1):(!_vm.dataLoaded && _vm.loading)?_c('f-form-placeholder'):_vm._e()],1),(_vm.dataLoaded)?_c('entity-action-button',{attrs:{"permission":_vm.Permissions.ADMIN_PAGES,"tooltip":(_vm.mode === 'edit' ? 'Update' : 'Create'),"disabled":_vm.loading,"icon":"floppy-disk","type":"submit"}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }