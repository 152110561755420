<template>
    <div
        :class="[
            'allocations-list-content',
            (fullscreen ? '--fullscreen' : null)
        ]"
    >
        <aside class="allocations-list-content__side">
            <f-icon-button
                :icon="(fullscreen ? 'contract' : 'expand')"
                class-name="allocations-list-content__fullscreen"
                outlined
                size="small"
                @click="onFullScreen"
                v-tooltip="(fullscreen ? 'Exit fullscreen' : 'Show fullscreen')"
            />
            <div
                class="allocations-list-content__side__container --scrollable-no-bar"
                ref="side"
                @scroll="onSideScroll"
            >
                <div
                    class="allocations-list-content__side__key"
                    v-for="(userAssignment, i) in assignments"
                    :key="i"
                >
                    <entity-avatar
                        :src="(userAssignment.user ? userAssignment.user.profile_image : null)"
                        :name="(userAssignment.user ? userAssignment.user.name : null)"
                    />
                    <user-card-compact
                        :user="userAssignment.user"
                        include-disciplines
                        variant="dark"
                        popup-variant="light"
                        :force-unselectable="userAssignment.user.instance === 'forecastPlaceholder'"
                    />

                    <div class="allocations-list-content__side__key__total">
                        {{
                            formatNumber(userAssignment.total_allocation + (userAssignment.total_excluded ?
                                userAssignment.total_excluded : 0), false)
                        }}
                        {{ userAssignment.total_allocation === 1 ? 'hr' : 'hrs' }}
                    </div>
                </div>
            </div>
        </aside>

        <div class="allocations-list-content__content">
            <div
                class="allocations-list-content__header --scrollable-no-bar"
                ref="header"
            >
                <div
                    v-for="(week, weekNumber) in weekDates"
                    class="allocations-list-content__header__week"
                    :key="weekNumber"
                >
                    <div class="allocations-list-content__header__week__weeknumber">
                        {{ weekNumber }}
                    </div>
                    <div class="allocations-list-content__header__week__months">
                        {{ (week.dates.length > 1 ? week.months.join(' — ') : '') }}
                    </div>
                    <div class="allocations-list-content__header__week__days">
                        <div
                            class="allocations-list-content__header__week__days__date"
                            v-for="(date, i) in week.dates"
                            :key="i"
                        >
                            {{ date.format('DD') }}
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="allocations-list-content__container --scrollable-both"
                ref="scroll"
                @scroll="onContainerScroll"
            >
                <allocations-list-days-row
                    v-for="(userAssignments, i) in assignments"
                    :key="i"
                    :start-date="startDateParsed"
                    :end-date="endDateParsed"
                    :name="userAssignments.user.name"
                    :assignments="userAssignments.assignments"
                />
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import NumbersAndBudgetMixin from '../../../../mixins/NumbersAndBudgetMixin';
import AllocationsListDaysRow from './AllocationsListDaysRow';
import UserCardCompact from '../../../cards/users/UserCardCompact';
import UserHelperMixin from "../../../../mixins/UserHelperMixin";
import EntityAvatar from "../../entities/EntityAvatar";
import EventBus from '../../../../services/EventBus';
import {GlobalEvents} from '../../../../config/Events';
import FIconButton from '../../../../f-components/form-controls/buttons/FIconButton';

export default {
    name: 'allocations-list-content',

    components: {
        FIconButton,
        EntityAvatar,
        UserCardCompact,
        AllocationsListDaysRow,
    },

    props: {
        assignments: {
            type: Array,
            required: true,
        },
        startDate: {
            type: String,
            required: true,
        },
        endDate: {
            type: String,
            required: true,
        },
        fullscreen: Boolean,
    },

    mixins: [
        NumbersAndBudgetMixin,
        UserHelperMixin,
    ],

    data() {
        return {
            activeScroll: null,
        }
    },

    mounted() {
    },

    computed: {
        assignmentsWithUser() {
            return this.assignments.map(assignment => {
                return {
                    ...assignment,
                    ...{
                        user: (this.userFromName(assignment.user.name, assignment.user.discipline) || assignment.user),
                    },
                };
            });
        },
        startDateParsed() {
            return moment(this.startDate);
        },
        endDateParsed() {
            return moment(this.endDate);
        },
        weekDates() {
            const weeks = {};

            const startDate = this.startDateParsed.clone();
            const endDate = this.endDateParsed.clone();
            const diff = endDate.diff(startDate, 'days') + 1;

            for (let i = 0; i < diff; i++) {
                const iStartDate = startDate.clone();
                iStartDate.add(i, 'days');

                if (![6, 7].includes(iStartDate.isoWeekday())) {
                    const weekNumber = iStartDate.week() + ' ';
                    const monthLabel = iStartDate.format('MMM');

                    if (!weeks[weekNumber]) {
                        weeks[weekNumber] = {
                            months: [],
                            dates: [],
                        }
                    }

                    if (!weeks[weekNumber].months.includes(monthLabel)) {
                        weeks[weekNumber].months.push(monthLabel);
                    }

                    weeks[weekNumber].dates.push(iStartDate);
                }
            }

            return weeks;
        },
    },

    methods: {
        onContainerScroll() {
            EventBus.$emit(GlobalEvents.OVERFLOW_SCROLL);
            this.$refs.header.scrollLeft = this.$refs.scroll.scrollLeft;
            this.$refs.side.scrollTop = this.$refs.scroll.scrollTop;
        },

        onSideScroll() {
            EventBus.$emit(GlobalEvents.OVERFLOW_SCROLL);
            this.$refs.scroll.scrollTop = this.$refs.side.scrollTop;
        },

        onFullScreen() {
            this.$emit('fullscreen');
        },
    },
}
</script>

<style lang="scss">
.allocations-list-content {
    display: flex;
    position: relative;

    .entity-avatar {
        background-color: darken($quaternaryGray, 5) !important;
    }

    &__fullscreen {
        position: absolute;
        top: 18px;
        left: 0;

        @include media(mobile-down) {
            display: none;
        }
    }

    &.--fullscreen {
        padding: 40px;
        height: 100vh;
        width: 100vw;
        background: #222;

        .allocations-list-content__side__container {
            max-height: 85vh;
        }

        .allocations-list-content__content .allocations-list-content__container {
            max-height: 85vh;
        }
    }

    .allocations-list-content__side {
        flex: 0 0 220px;
        border-right: 1px solid;
        position: relative;

        @media only screen and (max-width: 600px) {
            flex: 0 0 100px;
        }

        &__container {
            margin-top: 56px;
            border-top: 2px solid;
            max-height: 500px;
            overflow: hidden;
        }

        &__key {
            height: 55px;
            border-bottom: 1px solid;
            display: flex;
            align-items: center;

            > .user-card-compact {
                margin-right: 3px;
                width: 160px;
            }

            > .entity-avatar {
                display: none;
            }

            @media only screen and (max-width: 600px) {
                > .entity-avatar {
                    margin: 7px 4px;
                    display: inline-block;
                }

                > .user-card-compact {
                    display: none;
                }
            }

            &__total {
                flex: 0 0 50px;
                font-size: 80%;
                text-align: right;
            }
        }

        &, &__container, &__key {
            @include theme(light) {
                border-color: $softGray;
            }

            @include theme(dark) {
                border-color: $quaternaryGray;
            }
        }
    }

    .allocations-list-days-row {
        height: 55px;
    }

    .allocations-list-content__header {
        white-space: nowrap;
        vertical-align: top;
        height: 58px;
        border-bottom: 2px solid;
        pointer-events: none;
        padding-right: 2px;

        &__week {
            position: relative;
            display: inline-block;

            &__weeknumber {
                position: absolute;
                left: 2px;
                top: 2px;
                border-radius: 1px;
                font-size: 60%;
                padding: 3px 7px;

                @include theme(light) {
                    background: $softGray;
                }

                @include theme(dark) {
                    background: $quaternaryGray;
                }
            }

            &__months {
                text-align: center;
                height: 30px;
                border-right: 1px solid;
                font-size: 85%;
                padding-top: 2px;
            }

            &__days {
                white-space: nowrap;

                &__date {
                    text-align: center;
                    vertical-align: top;
                    display: inline-block;
                    width: 46px;
                    height: 28px;
                    border-right: 1px solid;
                }
            }
        }

        &, &__week__months, &__week__days__date {
            @include theme(light) {
                border-color: $softGray;
            }

            @include theme(dark) {
                border-color: $quaternaryGray;
            }
        }
    }

    .allocations-list-content__content {
        flex: 1;
        overflow: hidden;

        .allocations-list-content__container {
            width: 100%;
            max-height: 500px;
        }
    }

    .allocations-list-content__side__key {
        padding: 3px;
        overflow: hidden;
    }
}
</style>
