<template>
    <ul
        :class="[
            'f-flex-table',
            className,
            `--level-${level}`,
            `--row-padding-${rowPadding}`,
            (treeRender ? '--tree' : null),
        ]"
    >
        <slot/>
    </ul>
</template>

<script>
export default {
    name: 'f-flex-table',

    props: {
        className: String,
        treeRender: Boolean,
        level: {
            type: Number,
            default: 1,
        },
        rowPadding: {
            type: String,
            default: 'medium', // small | medium
        },
    },
}
</script>

<style lang="scss">
.f-flex-table {
    font-size: 100%;

    &.--level-2.--tree {
        .f-flex-table-row {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: -932px;
                left: -20px;
                width: 15px;
                height: 958px;
                border-radius: 0 0 0 3px;
                border: 1px solid;
                border-right: 0;
                border-top: 0;

                @include theme(dark) {
                    border-color: $quaternaryGray;
                }

                @include theme(light) {
                    border-color: $tertiaryGray;
                }
            }
        }
    }

    &.--row-padding-small {
        .f-flex-table-row__collapsible, .f-flex-table-row__title {
            padding-top: 7px;
            padding-bottom: 7px;
        }

        &.--level-2 {
            .f-flex-table-row__collapsible, .f-flex-table-row__title {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        &.--level-2.--tree {
            .f-flex-table-row {
                &:before {
                    top: -940px;
                }
            }

            .f-flex-table.--level-3.--tree {
                .f-flex-table-row {
                    &:before {
                        top: -942px;
                    }
                }
            }
        }
    }

    &.--level-2 {
        font-size: 90%;

        .f-flex-table-title-cell__icon {
            display: none;
        }

        .f-flex-table-row__collapsible__chevron {
            font-size: 75%;
        }

        .f-flex-table-title-cell__title {
            .f-flex-table-title-cell__title__heading {
                font-size: 110%;
            }
        }

        .f-flex-table-row__collapsible, .f-flex-table-row__title {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .f-flex-table.--level-3 {
            font-size: 95%;

            .f-flex-table-row__collapsible, .f-flex-table-row__title {
                padding-top: 5px;
                padding-bottom: 5px;
            }

            &.--tree {
                .f-flex-table-row {
                    &:before {
                        top: -937px;
                    }
                }
            }
        }
    }
}
</style>
