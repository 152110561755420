import EventBus from '../services/EventBus';
import {GlobalEvents} from '../config/Events';
import {SiteLoaderSizes} from '../f-components/loaders/site-loader/config';

export default {
    data() {
        return {
            loading: false,
            dataLoaded: false,
            loadingError: false,
        }
    },

    methods: {
        startLoading(loadSize = SiteLoaderSizes.SMALL) {
            this.$emit('loading', true);
            this.loading = true;
            EventBus.$emit(GlobalEvents.SITE_LOAD_START, loadSize);
        },

        onLoadingError(e) {
            this.loadingError = e.message;
            this.endLoading();
        },

        endLoading(dataLoaded = false) {
            this.dataLoaded = dataLoaded;

            if (!this.loading) return;

            this.$emit('loading', false);
            EventBus.$emit(GlobalEvents.SITE_LOAD_FINISH);
            this.loading = false;
        },

        startSilentLoading() {
            this.$emit('loading', true);
            this.loading = true;
        },

        endSilentLoading(dataLoaded = false) {
            this.dataLoaded = dataLoaded;
            this.$emit('loading', false);
            this.loading = false;
        },
    },
}
