<template>
    <div class="f-portal" :data-reference="reference">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'f-portal',

    props: {
        reference: String,
    },

    mounted() {
        try {
            document.getElementById('f-portal-container').append(this.$el);
        } catch (e) {
            console.warn(e);
        }
    },

    beforeDestroy() {
        try {
            this.$el.remove();
        } catch (e) {
            console.warn(e);
        }
    },
}
</script>

<style lang="scss">
.f-portal {
    position: relative;
}
</style>
