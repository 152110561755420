<script>
export default {
    name: 'marquee',
    props: {
        duration: {
            type: Number,
            default: 2,
        },
        repeat: {
            type: Number,
            default: 2,
            validator(val) {
                return val > 0;
            },
        },
        paused: Boolean,
        reverse: Boolean,
    },
    render(createElement) {
        return createElement('div', {class: 'marquee'}, [
            createElement('div', {
                class: [
                    this.paused
                        ? 'marquee__paused'
                        : undefined,
                    'marquee__content',
                ],
            }, Array(this.repeat).fill(
                createElement('div', {
                    class: 'marquee__text',
                    style: {
                        animationDuration: `${this.duration}s`,
                        animationDirection: this.reverse
                            ? 'reverse'
                            : undefined
                    }
                }, this.$slots.default)
            ))
        ])
    }
}
</script>

<style>
.marquee {
    overflow: hidden;
}

.marquee__content {
    width: 100000px;
}

.marquee__text {
    animation-name: animation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    float: left;
}

.marquee__paused .marquee__text {
    animation-play-state: paused
}

@keyframes animation {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
</style>
