<template>
    <f-modal
        :class-name="['f-editor-link-modal']"
        title="Link"
        width="600px"
        @close="$emit('close')"
    >
        <f-form @submitValid="submit" validate>
            <f-text-input
                auto-focus
                v-model="value"
                placeholder="https://"
            />
            <f-button
                value="Set"
                variant="primary"
                type="submit"
            />
        </f-form>
    </f-modal>
</template>

<script>
import FModal from '../../../other/modal/FModal';
import FButton from '../../buttons/FButton';
import LoadingMixin from '../../../../mixins/LoadingMixin';
import FTextInput from '../../text/FTextInput';
import FForm from '../../forms/FForm';

export default {
    name: 'f-editor-link-modal',

    mixins: [
        LoadingMixin,
    ],

    components: {
        FForm,
        FTextInput,
        FButton,
        FModal,
    },

    props: {
        link: String,
    },

    data() {
        return {
            value: this.link,
        }
    },

    methods: {
        submit() {
            this.$emit('apply', this.value);
        },
    },
}
</script>

<style lang="scss">
.f-editor-link-modal {
    .f-form {
        display: flex;
        align-content: center;
        align-items: center;
        gap: 10px;

        .f-abstract-input-field {
            margin: 0;
        }

        .f-form__footer {
            display: none;
        }
    }
}
</style>
