<template>
    <div :class="['setting-select', '--active']">
        <div class="setting-select__label --no-select">
            {{ label }}
            <f-help-label
                v-if="help"
                :text="help"
            />
        </div>
        <div>
            <f-dropdown
                v-model="dropdownValue"
                :options="options"
                :searchable="searchable"
                :placeholder="placeholder"
                popup-width="130px"
                @change="onChange"
                popup-variant="light"
            >
                <template v-slot:selectedOption="selectedOptionProps">
                    <div
                        v-if="selectedOptionProps.selectedOption"
                        :class="[
                            'f-dropdown__selected-option',
                            '--single',
                            (selectedOptionProps.selectedOption.value ? `--value-${selectedOptionProps.selectedOption.key.slugify()}` : undefined)
                        ]"
                    >
                        {{ selectedOptionProps.selectedOption.value }}
                    </div>
                </template>
                <template v-slot:options="optionsProps">
                    <li
                        v-for="(option, index) in optionsProps.options"
                        :class="[
                            'f-user-dropdown__option',
                            (optionsProps.selectedIndex === index ? '--selected' : null),
                            (optionsProps.selectedOption && optionsProps.value === option.key ? '--chosen' : null),
                            (option.value ? `--value-${option.key.slugify()}` : undefined)
                        ]"
                        :key="option.key"
                        @click.prevent="optionsProps.onSelect(option, index)"
                    >
                        {{ option.value }}
                    </li>
                </template>
            </f-dropdown>
        </div>
    </div>
</template>

<script>
import FHelpLabel from '../../../../f-components/form-controls/forms/FHelpLabel';
import Store from '../../../../services/Store';
import FDropdown from '../../../../f-components/form-controls/dropdowns/FDropdown';

export default {
    name: 'setting-select',

    components: {
        FDropdown,
        FHelpLabel,
    },

    props: {
        label: String,
        options: {
            type: Array,
            required: true,
        },
        help: String,
        storeCommitName: {
            type: String,
            default: 'userSetting',
        },
        settingKey: String,
        placeholder: String,
        searchable: Boolean,
    },

    data: function () {
        return {
            dropdownValue: this.$store.getters.getUserSetting(this.settingKey),
        }
    },

    methods: {
        onChange(value) {
            this.active = value;

            this.$emit('lock');
            this.$emit('change', value);

            if (this.settingKey) {
                // Save setting
                const setting = {
                    key: this.settingKey,
                    value: value,
                };
                if (this.storeCommitName && this.settingKey) {
                    Store.commit(this.storeCommitName, setting);
                }
            }

            // Unlock it
            window.setTimeout(() => this.$emit('unlock'), 500);
        },
    },
}
</script>

<style lang="scss">
.setting-select {
    display: flex;
    justify-content: space-between;
    width: auto;

    &__label {
        opacity: .5;
        padding-top: 12px;
        transition: opacity .15s ease-in-out;
    }

    &.--active .setting-select__label {
        opacity: .9;
    }
}
</style>
