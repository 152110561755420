import axios from 'axios';
import EventBus from '../../../../services/EventBus';
import {GlobalEvents} from '../../../../config/Events';
import APIEndpoints from '../../../../config/APIEndpoints';

export default {
    methods: {
        deleteAnnouncement(id, title) {
            EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                title: 'Delete announcement',
                body: `Are you sure you want to delete announcement ${title}?`,
                confirmButtonText: 'Delete',
                confirmButtonVariant: 'danger',
                cancelButton: true,
                onConfirm: () => {
                    this.startLoading();

                    axios['delete'](`${APIEndpoints.ADMIN_ANNOUNCEMENTS}/${id}`)
                        .then(() => {
                            this.endLoading();

                            // Add push message
                            EventBus.$emit(GlobalEvents.PUSH_MESSAGE, `Successfully deleted ${title}`);

                            // Redirect to index / refresh
                            if (this.$router.currentRoute.name !== 'admin.announcements') {
                                this.$router.push('/admin/announcements');
                            } else {
                                this.$emit('refresh');
                            }
                        })
                        .catch(() => this.endLoading());
                },
            });
        },
    },
}
