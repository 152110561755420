<template>
    <div
        :class="[
            'f-chip',
            (variant ? `--${variant}` : null),
            (size ? `--${size}` : null),
            (code ? '--code' : null),
            className,
            '--no-select',
        ]"
    >
        <slot/>
    </div>
</template>

<script>
import {ChipSizes, ChipVariants} from './config';

export default {
    name: 'f-chip',

    props: {
        className: String,
        variant: {
            type: String,
            default: ChipVariants.SECONDARY,
        },
        size: {
            type: String,
            default: ChipSizes.MEDIUM,
        },
        code: Boolean,
    },
}
</script>

<style lang="scss">
.f-chip {
    margin: 2px 0 2px 0;
    background: $primary;
    display: inline-block;
    color: #fff;
    white-space: nowrap;
    vertical-align: middle;
    text-transform: lowercase;
    line-height: 110%;
    text-overflow: ellipsis;
    overflow: hidden;

    img {
        vertical-align: middle;
        margin-bottom: 1px;
    }

    em {
        font-style: normal;
        border-bottom: dotted white 1px;
    }

    // Sizes

    &.--xsmall {
        font-size: 9px;
        padding: 3px 6px 3px;
        border-radius: 2px;

        img {
            height: 8px;
        }
    }

    &.--small {
        font-size: 10px;
        padding: 4px 7px 4px;
        border-radius: 3px;

        img {
            height: 9px;
        }
    }

    &.--medium {
        font-size: 11px;
        padding: 4px 10px 5px;
        border-radius: 3px;

        img {
            height: 10px;
        }
    }

    &.--xmedium {
        font-size: 13px;
        padding: 5px 12px 6px;
        border-radius: 3px;

        img {
            height: 12px;
        }
    }

    &.--large {
        font-size: 15px;
        padding: 10px 20px 10px;
        border-radius: 4px;

        img {
            height: 14px;
        }
    }

    // Variants

    &.--archived, &.--archive {
        background: green;
    }

    &.--primary {
        background: $primary;
        color: $primaryFront;

        em {
            border-bottom-color: $primaryFront;
        }
    }

    &.--secondary {
        background: $secondary;
        color: $secondaryFront;

        em {
            border-bottom-color: $secondaryFront;
        }
    }

    &.--success {
        background: $success;
        color: $successFront;

        em {
            border-bottom-color: $dark;
        }
    }

    &.--warning {
        background: $warning;
        color: $warningFront;

        em {
            border-bottom-color: $warningFront;
        }
    }

    &.--info {
        background: $info;
        color: $infoFront;

        em {
            border-bottom-color: $infoFront;
        }
    }

    &.--error, &.--danger {
        background: $danger;
        color: $dangerFront;

        em {
            border-bottom-color: $dangerFront;
        }
    }

    &.--alternative, &.--dark {
        background: $quaternaryGray;
        color: $darkFront;

        em {
            border-bottom-color: $darkFront;
        }
    }

    &.--alternative-inverted, &.--light {
        background: $softGray;
        color: $dark;
    }

    &.--status {
        background: mediumpurple;
    }

    &.--description {
        background: rgba($light, .25);
    }

    .f-icon {
        vertical-align: middle;
        margin: 2px 0;
        font-weight: bold;
    }

    &.--code {
        font-family: Consolas, monospace;
    }
}
</style>
