var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f-abstract-input-field',_vm._b({attrs:{"class-name":[
        'f-dropdown',
        ..._vm.classNames,
        `--mode-${_vm.mode}`,
    ],"focussed":_vm.hasFocus || _vm.popupOpen,"has-content":!!_vm.value,"input-error":_vm.inputError,"show-error":(_vm.searchable ? true : _vm.showError),"error-align":"right","hide-reset-when-empty":""},on:{"reset":_vm.reset,"click":_vm.focus},scopedSlots:_vm._u([{key:"pre",fn:function(){return [(_vm.preIcon)?_c('f-icon',{attrs:{"icon":_vm.preIcon}}):_vm._e()]},proxy:true},{key:"default",fn:function(){return [_c('button',{ref:"input",staticClass:"f-dropdown__focus",attrs:{"type":"button","disabled":(_vm.disabled || _vm.readonly),"tabindex":_vm.tabIndex},on:{"focus":_vm.onFocus,"blur":_vm.onBlur}}),(!_vm.value || (Array.isArray(_vm.value) && _vm.value.length === 0))?_c('span',{staticClass:"f-dropdown__placeholder --placeholder",domProps:{"innerHTML":_vm._s(_vm.placeholder || '&nbsp;')}}):_vm._e(),_vm._t("selectedOption",function(){return [(_vm.mode === 'single' && _vm.value)?_c('div',{staticClass:"f-dropdown__selected-option",domProps:{"innerHTML":_vm._s((_vm.selectedOption ? _vm.selectedOption.value : '&nbsp;'))}}):_vm._e(),(_vm.mode === 'multi' && (_vm.value ? _vm.value : []).length > 0)?_c('div',{staticClass:"f-dropdown__selected-option",domProps:{"innerHTML":_vm._s(_vm.selectedOption.map(option => option.value).join(', '))}}):_vm._e()]},null,{
                selectedOption: _vm.selectedOption,
                mode: _vm.mode
            })]},proxy:true},{key:"post",fn:function(){return [(!_vm.readonly && !_vm.disabled)?_c('f-icon-button',{attrs:{"icon":"chevron-down","outlined":"","variant":(_vm.inputError ? 'danger' : 'themed'),"size":"xsmall","class-name":"f-dropdown__action","tabindex":"-1"}}):_vm._e()]},proxy:true},{key:"after",fn:function(){return [((_vm.popupOpen && !_vm.disabled && !_vm.readonly))?_c('f-popup',{ref:"popup",attrs:{"target":_vm.$el,"width":_vm.popupWidth,"class-name":`f-dropdown-popup --mode-${_vm.mode}`,"variant":_vm.popupVariant,"max-height":"auto","align":"bottom"},on:{"close":_vm.closePopup}},[(_vm.searchable)?_c('div',{staticClass:"f-dropdown-popup__search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],ref:"search",attrs:{"type":"text","placeholder":"Search…","tabindex":"-1"},domProps:{"value":(_vm.searchQuery)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnterKey.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.onTabKey.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value}}}),_c('f-icon',{attrs:{"icon":"magnifier"}})],1):_vm._e(),(_vm.searchQuery && _vm.filteredOptions.length === 0)?_c('div',{staticClass:"f-dropdown-popup__no-results"},[_vm._v(" Nothing found, try again ")]):_vm._e(),_c('div',{class:[
                    'f-dropdown-popup__scroll',
                    `--scrollable-${_vm.popupVariant === 'dark' ? 'light' : 'dark'}-vertical`
                ]},[_c('ul',{staticClass:"f-dropdown-popup__list"},[_vm._t("options",function(){return _vm._l((_vm.filteredOptions),function(option,index){return _c('li',{key:option.key,class:[
                                (option.disabled ? '--disabled' : null),
                                (_vm.selectedIndex === index ? '--selected' : null),
                                (_vm.mode === 'single' && _vm.selectedOption && _vm.value === option.key ? '--chosen' : null),
                                (_vm.mode === 'multi' && _vm.selectedOption && (_vm.value ? _vm.value : []).includes(option.key) ? '--chosen' : null),
                            ],domProps:{"innerHTML":_vm._s(option.value)},on:{"click":function($event){$event.preventDefault();return _vm.onSelect(option, index)}}})})},null,{
                            onSelect: _vm.onSelect,
                            selectedIndex: _vm.selectedIndex,
                            options: _vm.filteredOptions,
                            selectedOption: _vm.selectedOption,
                            mode: _vm.mode,
                            value: _vm.value
                        })],2)])]):_vm._e()]},proxy:true}],null,true)},'f-abstract-input-field',_vm.boundProps,false))
}
var staticRenderFns = []

export { render, staticRenderFns }