<template>
    <page
        v-if="hasPermission(Permissions.ADMIN_FIKA)"
        :loading="loading"
        class-name="admin-fika-page"
        :title="['Admin', 'FIKAs']"
        header="FIKAs"
        :header-badge="(fetchedData ? fetchedData.total : null)"
        pre-max-width="700px"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb>FIKAs</f-breadcrumb>
                <f-breadcrumb>Overview</f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-container max-width="700px">
            <f-filter
                show-active-filters
                :active-filters="activeFilters"
                :filter-casts="{query: 'query'}"
            >
                <f-filter-row>
                    <f-filter-item>
                        <f-text-input
                            placeholder="Search…"
                            pre-icon="magnifier"
                            v-model="searchQuery"
                            class-name="--align-center"
                            max-width="300px"
                            resetable
                        />
                    </f-filter-item>
                </f-filter-row>
            </f-filter>

            <f-table
                v-if="fetchedData"
                :empty="(!fetchedData || items.length === 0)"
                rows-hoverable
                :allow-horizontal-scroll="false"
            >
                <template #pre>
                    <f-pagination
                        v-if="fetchedData"
                        :current-page="currentPage"
                        :page-count="lastPage"
                        @navigate="onPaginationNavigate"
                    />
                </template>

                <template #head>
                    <f-table-header-row>
                        <f-table-header-cell>
                            Number
                        </f-table-header-cell>
                        <f-table-header-cell align="center">
                            Date
                        </f-table-header-cell>
                    </f-table-header-row>
                </template>

                <f-table-row
                    v-for="row in items"
                    :key="row.id"
                    @click="$router.push(`/admin/fika/edit/${row.id}`)"
                    :actions=" [
                        {
                            value: 'Edit',
                            icon: 'pencil',
                            onClick: () => $router.push(`/admin/fika/edit/${row.id}`),
                        },
                        {
                            icon: 'trash2',
                            value: 'Delete',
                            onClick: () => deleteFika(row.id, row.number),
                        },
                    ]"
                >
                    <f-table-cell is-id>
                        #{{ row.number }}
                    </f-table-cell>
                    <f-table-cell align="center">
                        {{ getVisualDate(row.date) }}
                    </f-table-cell>
                </f-table-row>

                <template #post>
                    <f-pagination
                        v-if="fetchedData"
                        :current-page="currentPage"
                        :page-count="lastPage"
                        @navigate="onPaginationNavigate"
                        bottom
                    />
                </template>
            </f-table>

            <f-table-placeholder v-else-if="!fetchedData && loading"/>
        </f-container>

        <entity-action-button
            :permission="Permissions.ADMIN_FIKA"
            route="/admin/fika/create"
        />
    </page>
</template>

<script>
import Page from '../../Page';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import LoadingMixin from '../../../../mixins/LoadingMixin';
import EntityActionButton from '../../../misc/entities/EntityActionButton';
import EntityPaginatedFilteredOverviewMixin from '../../../../mixins/entities/EntityPaginatedFilteredOverviewMixin';
import FContainer from '../../../../f-components/layout/container/FContainer';
import APIEndpoints from '../../../../config/APIEndpoints';
import FTable from '../../../../f-components/data/table/FTable';
import FPagination from '../../../../f-components/navigation/pagination/FPagination';
import FTableHeaderRow from '../../../../f-components/data/table/FTableHeaderRow';
import FTableHeaderCell from '../../../../f-components/data/table/FTableHeaderCell';
import FTableRow from '../../../../f-components/data/table/FTableRow';
import FTableCell from '../../../../f-components/data/table/FTableCell';
import FTablePlaceholder from '../../../../f-components/loaders/placeholder/FTablePlaceholder';
import AdminFikaMixin from './AdminFikaMixin';
import moment from 'moment';
import FFilter from '../../../../f-components/data/filter/FFilter';
import FFilterRow from '../../../../f-components/data/filter/FFilterRow';
import FFilterItem from '../../../../f-components/data/filter/FFilterItem';
import FTextInput from '../../../../f-components/form-controls/text/FTextInput';

export default {
    name: 'admin-fika-page',

    mixins: [
        LoadingMixin,
        EntityPaginatedFilteredOverviewMixin,
        AdminFikaMixin,
    ],

    components: {
        FTextInput,
        FFilterItem,
        FFilterRow,
        FFilter,
        FTablePlaceholder,
        FTableCell,
        FTableRow,
        FTableHeaderCell,
        FTableHeaderRow,
        FPagination,
        FTable,
        FContainer,
        EntityActionButton,
        FBreadcrumb,
        FBreadcrumbs,
        Page,
    },

    data() {
        return {
            searchQuery: null,
        };
    },

    watch: {
        searchQuery() {
            this.onFilterChange();
        },
    },

    methods: {
        getEndpoint() {
            return APIEndpoints.ADMIN_FIKA;
        },

        getVisualDate(date) {
            return moment(date).format('MMMM Do YYYY');
        },

        getFilters() {
            return {'filter[query]': this.searchQuery};
        },

        applyPreviousFilters(filters) {
            if (filters['filter[query]']) this.searchQuery = filters['filter[query]'];
        },
    },
}
</script>

<style lang="scss">
.admin-fika-page {
    .f-filter__container {
        max-width: 360px;
    }
}
</style>
