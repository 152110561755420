<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
    <f-checkbox-list
        v-model="permissions"
        direction="vertical"
        :class="['permissions-checkbox-list', (readOnly ? '--read-only' : null)]"
    >
        <f-form-fieldset
            v-for="(group, groupName) in groupedPermissions"
            :key="groupName"
        >
            <template #legend>{{ groupName }}</template>
            <f-checkbox
                v-for="(value, key) in group"
                :readonly="readOnly"
                :label="value"
                :value="key"
                :key="key"
            />
        </f-form-fieldset>

    </f-checkbox-list>
</template>

<script>
import FCheckboxList from "../../../../../f-components/form-controls/checkbox/FCheckboxList";
import FFormFieldset from "../../../../../f-components/form-controls/forms/FFormFieldSet";
import FCheckbox from "../../../../../f-components/form-controls/checkbox/FCheckbox";
import {Permissions} from '../../../../../config/Permissions';

export default {
    name: 'permissions-checkbox-list',

    components: {
        FCheckbox,
        FFormFieldset,
        FCheckboxList,
    },

    props: {
        value: {
            type: Array,
            default: () => [],
        },
        readOnly: Boolean,
    },

    watch: {
        permissions(permissions) {
            this.$emit('input', permissions);
        },
    },

    data() {
        return {
            permissions: this.value,
        }
    },

    computed: {
        groupedPermissions() {
            const groups = {};

            for (let key in Permissions) {
                const permission = Permissions[key],
                    split = permission.split('.');

                split.pop();
                let groupName = split.join(' ').ucwords().replace('-', ' ');

                if (!groups[groupName]) groups[groupName] = {};
                groups[groupName][permission] = permission;
            }

            return groups;
        },
    },
}
</script>

<style lang="scss">
.permissions-checkbox-list {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    gap: 10px;
    justify-content: space-between;
}
</style>
