<template>
    <f-icon-button
        class-name="f-close"
        @click="$emit('click')"
        icon="cross"
        :size="size"
        :variant="(alternative ? 'dark' : 'light')"
        outlined
    />
</template>

<script>
import FIconButton from '../../form-controls/buttons/FIconButton';

export default {
    name: 'f-close',

    components: {
        FIconButton,
    },

    props: {
        alternative: Boolean,
        size: {
            type: String,
            default: 'small',
        },
    },
}
</script>

<style lang="scss">
.f-close {
    position: absolute;
    font-weight: bold;
}
</style>
