<template>
    <div :class="['timeline-day']">
        <header class="timeline-day__header">
            <bullet-point>
                <f-icon v-if="pinned" icon="pushpin"/>
                <span v-for="(value, i) in label" :key="i">
                    {{ value }}
                </span>
            </bullet-point>
        </header>
        <div class="timeline-day__content">
            <slot/>
        </div>
    </div>
</template>

<script>
import BulletPoint from '../../BulletPoint';
import FIcon from '../../../../f-components/layout/icons/FIcon';

export default {
    name: 'timeline-day',

    components: {
        FIcon,
        BulletPoint,
    },

    props: {
        label: Array,
        pinned: Boolean,
    },
}
</script>

<style lang="scss">
.timeline-day {
    text-align: left;

    &:not(:last-child) {
        .timeline-day__content {
            padding-right: 10px;
        }
    }

    &__header {
        padding-bottom: 6px;
        padding-right: 20px;
        margin-bottom: 25px;
        border-bottom: 1px solid;
        white-space: nowrap;

        @include theme(light) {
            border-bottom-color: $softGray;
        }

        @include theme(dark) {
            border-bottom-color: $quaternaryGray;
        }

        span:not(.f-icon) {
            &:after {
                content: '/';
                display: inline;
                color: $tertiaryGray;
            }

            &:last-child:after {
                display: none;
            }
        }
    }

    &__content {
        display: inline-flex;
        gap: 10px;
        align-items: flex-start;

        > * {
            flex-grow: 0;
        }
    }
}
</style>
