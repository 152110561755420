var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{class:[
        'f-flex-table',
        _vm.className,
        `--level-${_vm.level}`,
        `--row-padding-${_vm.rowPadding}`,
        (_vm.treeRender ? '--tree' : null),
    ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }