export const EditorToolbar = {
    PARAGRAPH: 'Paragraph',
    CODE_BLOCK: 'CodeBlock',
    CODE: 'Code',
    BLOCKQUOTE: 'Blockquote',
    BOLD: 'Bold',
    ITALIC: 'Italic',
    HARD_BREAK: 'HardBreak',
    H1: 'Heading1',
    H2: 'Heading2',
    H3: 'Heading3',
    HORIZONTAL_RULE: 'HorizontalRule',
    ORDERED_LIST: 'OrderedList',
    BULLET_LIST: 'BulletList',
    LINK: 'Link',
    STRIKE: 'Strike',
    UNDERLINE: 'Underline',
    TABLE: 'Table',
    IMAGE: 'Image',
    TEXT_ALIGN: 'TextAlign',

    HISTORY: 'History',
    SOURCE: 'Source',
}
