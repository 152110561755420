<template>
    <div
        :class="['widget-drag', `--${size}`]"
        :style="`border-radius: 0 ${radius}px 0 0;`"
        v-tooltip="'Move'"
    />
</template>

<script>
export default {
    name: 'widget-drag',

    props: {
        radius: {
            type: Number,
            default: 8,
        },
        size: {
            type: String,
            default: 'medium', // medium | small
        },
    },
}
</script>

<style lang="scss">
.widget-drag {
    color: $tertiaryGray;
    position: absolute;
    right: 9px;
    top: 9px;
    font-size: 1.2em;
    opacity: 0;
    transition: opacity .1s;
    cursor: grab;
    z-index: 1;
    padding: 3px;
    background: url(../../img/handle.svg) no-repeat;
    background-size: cover;
    height: 18px;
    width: 18px;

    &.--small {
        height: 14px;
        width: 14px;
    }

    &:hover {
        opacity: 1 !important;
    }

    @include media(mobile-down) {
        display: none;
    }
}
</style>
