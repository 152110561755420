<template>
    <div>
        <f-inline-message
            v-if="hasDateFilter"
            id="projectReportDateRage"
            :closable="false"
            style="margin-bottom: 50px;"
        >
            You are viewing the allocated hours for the selected month range.
            The sold hours are hidden, because they are based on the whole project, not the selected range.
        </f-inline-message>

        <!-- eslint-disable vue/no-use-v-if-with-v-for -->
        <f-scroll-pane
            mode="horizontal"
            class-name="project-report-analysis__wrapper"
        >
            <div :class="['project-report-analysis']">
                <f-flex-table>
                    <div :class="[
                        'project-report-analysis__totals-bg',
                        (showSoldColumn ? undefined : '--small'),
                    ]"/>
                    <div class="project-report-analysis__allocations-bg"/>

                    <f-flex-table-header-row collapsible>
                        <f-flex-table-cell>
                            <f-text
                                v-if="!hasDateFilter"
                                type="subtitle"
                                tag="div"
                                :style="{ paddingRight: '30px', textAlign: 'right' }"
                            >
                                Hrs sold<br>vs alloc.
                            </f-text>
                        </f-flex-table-cell>

                        <f-flex-table-cell
                            :flex="`0 0 ${hrsPriceColumnWidth * (showSoldColumn ? 2 : 1)}px`"
                            align="right"
                        >
                            <f-flex direction="column">
                                <f-text
                                    type="subtitle"
                                    tag="div"
                                    style="padding: 10px 0 10px 0; text-align: center;"
                                >
                                    Total hrs
                                </f-text>
                                <f-flex>
                                    <f-flex-table-cell
                                        v-if="showSoldColumn"
                                        flex="0 0 50%"
                                        align="right"
                                        style="padding: 0 10px 1px 0"
                                    >
                                        <span style="font-size: 11px;">Sold</span>
                                    </f-flex-table-cell>
                                    <f-flex-table-cell
                                        flex="0 0 50%"
                                        align="right"
                                        style="padding: 0 0 1px 0;"
                                    >
                                        <span style="font-size: 11px;">Allocated</span>
                                    </f-flex-table-cell>
                                </f-flex>
                            </f-flex>
                        </f-flex-table-cell>

                        <f-flex-table-cell
                            :flex="`0 0 ${hrsPriceColumnWidth * 2}px`"
                            align="right"
                            class-name="--alt-color"
                        >
                            <f-flex direction="column">
                                <f-text type="subtitle" tag="div" style="padding: 10px 0 10px 0; text-align: center;">
                                    Alloc. hrs breakdown
                                </f-text>
                                <f-flex>
                                    <f-flex-table-cell
                                        flex="0 0 50%"
                                        align="right"
                                        style="padding: 0 10px 1px 0"
                                    >
                                        <span style="font-size: 11px;">Employees</span>
                                    </f-flex-table-cell>
                                    <f-flex-table-cell
                                        flex="0 0 50%"
                                        align="right"
                                        style="padding: 0 0 1px 0;"
                                    >
                                        <span style="font-size: 11px;">Freelancers</span>
                                    </f-flex-table-cell>
                                </f-flex>
                            </f-flex>
                        </f-flex-table-cell>
                    </f-flex-table-header-row>

                    <!-- Departments -->
                    <f-flex-table-row
                        collapsible
                        v-for="department in report.hours.departments"
                        :key="department.id"
                        overflow-hidden
                    >
                        <f-flex-table-title-cell
                            :icon="department.icon"
                            :title="department.name"
                            :class-name="[(department.name[0] === 'Unknown' ? '--warning' : null)]"
                        >
                            {{ getDisciplineSubtitle(department) }}
                        </f-flex-table-title-cell>

                        <f-flex-table-cell
                            v-if="showSoldColumn"
                            :flex="`0 0 ${hrsPriceColumnWidth}px`"
                            align="right"
                        >
                            <profitable-bar
                                width="80px"
                                min-width="70px"
                                :style="{ transform: 'translateY(-10px)' }"
                                percentage-location="right"
                                :total="department.hours_sold"
                                :current="department.hours_allocated"
                            />
                        </f-flex-table-cell>

                        <!-- Department - Hours sold -->
                        <f-flex-table-hours-price-cell
                            v-if="showSoldColumn"
                            align="right"
                            :flex="`0 0 ${hrsPriceColumnWidth}px`"
                            :hrs="department.hours_sold"
                            :price="department.hours_sold_cost"
                        />
                        <!-- Department - Total hours allocated -->
                        <f-flex-table-hours-price-cell
                            align="right"
                            :flex="`0 0 ${hrsPriceColumnWidth}px`"
                            :hrs="department.hours_allocated"
                            :price="department.hours_allocated_cost"
                            :class-name="getOverBudgetClass(department.hours_sold, department.hours_allocated, !showSoldColumn)"
                        >
                            <template
                                v-if="department.hours_allocated_cost_spec.freelancer > 0"
                                #pre-price
                            >
                                ≈
                            </template>
                        </f-flex-table-hours-price-cell>
                        <!-- Department - Employee hours allocated -->
                        <f-flex-table-hours-price-cell
                            align="right"
                            :flex="`0 0 ${hrsPriceColumnWidth}px`"
                            :hrs="department.hours_allocated_spec.employee"
                            :price="department.hours_allocated_cost_spec.employee"
                            class-name="--alt-color"
                        />
                        <!-- Department - Employee hours allocated -->
                        <f-flex-table-hours-price-cell
                            align="right"
                            :flex="`0 0 ${hrsPriceColumnWidth}px`"
                            :hrs="department.hours_allocated_spec.freelancer"
                            :price="department.hours_allocated_cost_spec.freelancer"
                            class-name="--alt-color"
                        >
                            <template #pre-price>≈</template>
                        </f-flex-table-hours-price-cell>

                        <!-- Collapsed content -->
                        <template #content>
                            <!-- Department disciplines -->
                            <f-flex-table :level="2" tree-render>
                                <f-flex-table-row
                                    v-for="discipline in department.disciplines"
                                    :key="discipline.id"
                                    icon-size="xxsmall"
                                    collapsible
                                >
                                    <f-flex-table-title-cell :title="[discipline.name]">
                                        <span v-html="getUsersSubtitle(discipline)"/>
                                    </f-flex-table-title-cell>
                                    <f-flex-table-cell
                                        v-if="showSoldColumn"
                                        :flex="`0 0 ${hrsPriceColumnWidth}px`"
                                        align="right"
                                    >
                                        <profitable-bar
                                            width="60px"
                                            min-width="40px"
                                            :total="discipline.hours_sold"
                                            :current="discipline.hours_allocated"
                                            percentage-location="right"
                                            :style="{ transform: 'translateY(-8px)' }"
                                        />
                                    </f-flex-table-cell>

                                    <!-- Discipline - Employee hours sold -->
                                    <f-flex-table-hours-price-cell
                                        v-if="showSoldColumn"
                                        align="right"
                                        :flex="`0 0 ${hrsPriceColumnWidth}px`"
                                        :hrs="discipline.hours_sold"
                                        :price="discipline.hours_sold_cost"
                                    />
                                    <!-- Discipline - Total hours allocated -->
                                    <f-flex-table-hours-price-cell
                                        align="right"
                                        :flex="`0 0 ${hrsPriceColumnWidth}px`"
                                        :class-name="getOverBudgetClass(discipline.hours_sold, discipline.hours_allocated, !showSoldColumn)"
                                        :hrs="discipline.hours_allocated"
                                        :price="discipline.hours_allocated_cost"
                                    >
                                        <template
                                            v-if="discipline.hours_allocated_spec.freelancer > 0"
                                            #pre-price
                                        >
                                            ≈
                                        </template>
                                    </f-flex-table-hours-price-cell>
                                    <!-- Discipline - Employee hours allocated -->
                                    <f-flex-table-hours-price-cell
                                        align="right"
                                        :flex="`0 0 ${hrsPriceColumnWidth}px`"
                                        :hrs="discipline.hours_allocated_spec.employee"
                                        :price="discipline.hours_allocated_cost_spec.employee"
                                        class-name="--alt-color"
                                    />
                                    <!-- Discipline - Freelancer hours allocated -->
                                    <f-flex-table-hours-price-cell
                                        align="right"
                                        :flex="`0 0 ${hrsPriceColumnWidth}px`"
                                        :hrs="discipline.hours_allocated_spec.freelancer"
                                        :price="discipline.hours_allocated_cost_spec.freelancer"
                                        class-name="--alt-color"
                                    >
                                        <template #pre-price>≈</template>
                                    </f-flex-table-hours-price-cell>

                                    <template #content>
                                        <!-- Allocated employees -->
                                        <f-text v-if="discipline.users.length > 0" tag="div">
                                            Allocated
                                        </f-text>
                                        <f-flex-table
                                            v-if="discipline.users.length > 0"
                                            :level="3"
                                            tree-render
                                        >
                                            <div
                                                v-for="user in discipline.users"
                                                :key="user.id"
                                                class="project-report-analysis__user-row-wrapper"
                                            >
                                                <f-flex-table-row>
                                                    <f-flex-table-cell>
                                                        <div
                                                            v-if="user.product"
                                                            class="project-report-analysis__cards-group"
                                                        >
                                                            <user-card-mini
                                                                :user="mapToLocalUser(user)"
                                                                :variant="(user.instance === 'forecastPlaceholder' ? 'warning' : 'primary')"
                                                                :force-unselectable="user.instance === 'forecastPlaceholder'"
                                                                include-disciplines
                                                                @click="log(user)"
                                                            />
                                                            <product-card-mini
                                                                v-if="user.product"
                                                                :id="user.product.id"
                                                                :name="user.product.name"
                                                                :selling-price="user.product.selling_price"
                                                                :original-selling-price="user.product.original_selling_price"
                                                                :tooltip-options="{ variant: 'dark' }"
                                                            />
                                                        </div>
                                                        <user-card-mini
                                                            v-else
                                                            :user="mapToLocalUser(user)"
                                                            :variant="(user.instance === 'forecastPlaceholder' ? 'warning' : 'primary')"
                                                            :force-unselectable="user.instance === 'forecastPlaceholder'"
                                                            include-disciplines
                                                            @click="log(user)"
                                                        />

                                                        <f-icon-button
                                                            v-if="
                                                            hasPermission(Permissions.PROJECTS_ADMIN) &&
                                                            allowDisciplineChange &&
                                                            user.instance &&
                                                            ['freelancer', 'user'].includes(user.instance)
                                                        "
                                                            icon="ellipsis"
                                                            size="xsmall"
                                                            class-name="project-report-analysis__user-row-wrapper__actions"
                                                            outlined
                                                            @click="onUserActionClick($event, user)"
                                                        />
                                                        <status-icon
                                                            type="warning"
                                                            :tooltip="user.warnings"
                                                        />
                                                        <status-icon
                                                            type="info"
                                                            :tooltip="user.infos"
                                                        />
                                                    </f-flex-table-cell>
                                                    <f-flex-table-cell :flex="`0 0 ${hrsPriceColumnWidth}px`"/>

                                                    <!-- User - Total hours allocated -->
                                                    <f-flex-table-hours-price-cell
                                                        :flex="`0 0 ${hrsPriceColumnWidth}px`"
                                                        :hrs="user.hours_allocated + user.total_excluded"
                                                        :price="user.hours_allocated_cost"
                                                        align="right"
                                                    >
                                                        <template
                                                            v-if="user.total_excluded"
                                                            #post-hours
                                                        >
                                                            <span v-tooltip="'Excluded'" style="cursor: default;">
                                                                ({{ formatNumber(-user.total_excluded, false) }})
                                                            </span>
                                                        </template>
                                                    </f-flex-table-hours-price-cell>

                                                    <!-- If freelancer add an empty block -->
                                                    <f-flex-table-cell
                                                        v-if="user.type === 'freelancer'"
                                                        :flex="`0 0 ${hrsPriceColumnWidth}px`"
                                                    />

                                                    <!-- User - Employee or freelancer hours allocated -->
                                                    <f-flex-table-hours-price-cell
                                                        :flex="`0 0 ${hrsPriceColumnWidth}px`"
                                                        :hrs="user.hours_allocated + user.total_excluded"
                                                        :price="user.hours_allocated_cost"
                                                        align="right"
                                                        class-name="--alt-color"
                                                    >
                                                        <template
                                                            v-if="user.type === 'freelancer'"
                                                            #pre-price
                                                        >
                                                            ≈
                                                        </template>
                                                        <template
                                                            v-if="user.total_excluded"
                                                            #post-hours
                                                        >
                                                            <span v-tooltip="'Excluded'" style="cursor: default;">
                                                                ({{ formatNumber(-user.total_excluded, false) }})
                                                            </span>
                                                        </template>
                                                    </f-flex-table-hours-price-cell>

                                                    <!-- If not freelancer add an empty block -->
                                                    <f-flex-table-cell
                                                        v-if="user.type !== 'freelancer'"
                                                        :flex="`0 0 ${hrsPriceColumnWidth}px`"
                                                    />
                                                </f-flex-table-row>
                                            </div>
                                        </f-flex-table>

                                        <!-- Sold hours (aka order lines) -->
                                        <f-text
                                            v-if="discipline.lines.length > 0 && showSoldColumn"
                                            tag="div"
                                        >
                                            Sold
                                        </f-text>
                                        <f-flex-table
                                            v-if="discipline.lines.length > 0 && showSoldColumn"
                                            :level="3"
                                            :class-name="'project-report-analysis__order-lines' + (discipline.users.length > 0 ? ' --line' : '')"
                                            tree-render
                                        >
                                            <f-flex-table-row
                                                v-for="line in discipline.lines"
                                                :key="line.id"
                                                icon-size="xxsmall"
                                            >
                                                <f-flex-table-title-cell>
                                                    {{ formatPrice(line.unit_price) }}

                                                    <template #heading>
                                                        {{ line.name }}&nbsp;
                                                        <f-chip variant="light" code size="small">
                                                            {{ line.id }}
                                                        </f-chip>&nbsp;
                                                        <f-icon-button
                                                            v-if="hasPermission(Permissions.PROJECTS_ADMIN) && allowDisciplineChange"
                                                            icon="ellipsis"
                                                            size="xsmall"
                                                            class-name="project-report-analysis__order-lines__actions"
                                                            outlined
                                                            @click="onLineActionClick($event, line)"
                                                        />
                                                    </template>
                                                </f-flex-table-title-cell>
                                                <f-flex-table-hours-price-cell
                                                    :flex="`0 0 ${hrsPriceColumnWidth}px`"
                                                    align="right"
                                                    :hrs="line.hours_sold"
                                                    :price="line.hours_sold_cost"
                                                >
                                                    <template #post-hours v-if="line.total_excluded">
                                                        ({{ formatNumber(-line.total_excluded, false) }})
                                                    </template>
                                                    <template #post-price v-if="line.total_excluded_costs">
                                                        ({{ formatPrice(-line.total_excluded_costs, false) }})
                                                    </template>
                                                </f-flex-table-hours-price-cell>
                                                <f-flex-table-cell :flex="`0 0 ${hrsPriceColumnWidth}px`"/>
                                                <f-flex-table-cell :flex="`0 0 ${hrsPriceColumnWidth}px`"/>
                                                <f-flex-table-cell :flex="`0 0 ${hrsPriceColumnWidth}px`"/>
                                            </f-flex-table-row>
                                        </f-flex-table>
                                    </template>
                                </f-flex-table-row>
                            </f-flex-table>
                        </template>
                    </f-flex-table-row>

                    <!-- Footer totals -->
                    <f-flex-table-footer-row collapsible>
                        <f-flex-table-cell align="right"/>
                        <f-flex-table-cell
                            v-if="showSoldColumn"
                            flex="0 0 120px"
                            align="right"
                        >
                            <profitable-bar
                                width="80px"
                                min-width="70px"
                                percentage-location="right"
                                :style="{ transform: 'translateY(-4px)' }"
                                :total="report.totals.hours_sold"
                                :current="report.totals.hours_allocated"
                            />
                        </f-flex-table-cell>
                        <f-flex-table-hours-price-cell
                            v-if="showSoldColumn"
                            :flex="`0 0 ${hrsPriceColumnWidth}px`"
                            :hrs="report.totals.hours_sold"
                            :price="report.totals.hours_sold_cost"
                            align="right"
                        />
                        <f-flex-table-hours-price-cell
                            :flex="`0 0 ${hrsPriceColumnWidth}px`"
                            :class-name="getOverBudgetClass(report.totals.hours_sold, report.totals.hours_allocated, !showSoldColumn)"
                            :hrs="report.totals.hours_allocated"
                            :price="report.totals.hours_allocated_cost"
                            align="right"
                        >
                            <template #pre-price v-if="hasFreelancerAllocations">≈</template>
                        </f-flex-table-hours-price-cell>
                        <f-flex-table-hours-price-cell
                            :flex="`0 0 ${hrsPriceColumnWidth}px`"
                            :hrs="report.totals.hours_allocated_spec.employee"
                            :price="report.totals.hours_allocated_cost_spec.employee"
                            align="right"
                            class-name="--alt-color"
                        />
                        <f-flex-table-hours-price-cell
                            :flex="`0 0 ${hrsPriceColumnWidth}px`"
                            :hrs="report.totals.hours_allocated_spec.freelancer"
                            :price="report.totals.hours_allocated_cost_spec.freelancer"
                            align="right"
                            class-name="--alt-color"
                        >
                            <template #pre-price>≈</template>
                        </f-flex-table-hours-price-cell>
                    </f-flex-table-footer-row>
                </f-flex-table>
            </div>
        </f-scroll-pane>
    </div>
</template>

<script>
import ProfitableBar from './ProfitableBar';
import NumbersAndBudgetMixin from '../../../mixins/NumbersAndBudgetMixin';
import UserHelperMixin from '../../../mixins/UserHelperMixin';
import FFlexTable from "../../../f-components/data/flex-table/FFlexTable";
import FFlexTableRow from "../../../f-components/data/flex-table/FFlexTableRow";
import FFlexTableCell from "../../../f-components/data/flex-table/FFlexTableCell";
import FFlexTableHeaderRow from "../../../f-components/data/flex-table/FFlexTableHeaderRow";
import FFlexTableTitleCell from "../../../f-components/data/flex-table/FFlexTableTitleCell";
import FFlexTableHoursPriceCell from "../../../f-components/data/flex-table/FFlexTableHoursPriceCell";
import FFlexTableFooterRow from "../../../f-components/data/flex-table/FFlexTableFooterRow";
import FIconButton from "../../../f-components/form-controls/buttons/FIconButton";
import FChip from '../../../f-components/data/chip/FChip';
import FScrollPane from '../../../f-components/other/scroll-pane/FScrollPane';
import FText from '../../../f-components/layout/text/FText';
import StatusIcon from '../status-icon/StatusIcon';
import FFlex from '../../../f-components/layout/flex/FFlex';
import UserCardMini from '../../cards/users/UserCardMini';
import ProductCardMini from '../../cards/products/ProductCardMini';
import FInlineMessage from '../../../f-components/notice/inline-message/FInlineMessage';

export default {
    name: 'project-report-analysis',

    mixins: [
        NumbersAndBudgetMixin,
        UserHelperMixin,
    ],

    components: {
        FInlineMessage,
        ProductCardMini,
        UserCardMini,
        FFlex,
        StatusIcon,
        FText,
        FScrollPane,
        FChip,
        FIconButton,
        FFlexTableFooterRow,
        FFlexTableHoursPriceCell,
        FFlexTableTitleCell,
        FFlexTableHeaderRow,
        FFlexTableCell,
        FFlexTableRow,
        FFlexTable,
        ProfitableBar,
    },

    props: {
        report: {
            type: Object,
            required: true,
        },
        allowDisciplineChange: Boolean,
    },

    computed: {
        showSoldColumn() {
            return !this.hasDateFilter;
        },
        hasDateFilter() {
            return (!!this.report.filters.date_from && !!this.report.filters.date_to);
        },
        hasFreelancerAllocations() {
            return this.report.totals.hours_allocated_spec.freelancer > 0;
        },
    },

    data() {
        return {
            hrsPriceColumnWidth: 100,
        }
    },

    methods: {
        getDisciplineSubtitle(department) {
            let names = [];
            for (let i in department.disciplines) {
                names.push(department.disciplines[i].name);
            }
            names = names.filter((value, index, self) => self.indexOf(value) === index);
            return names.join(', ');
        },

        getUsersSubtitle(discipline) {
            if (discipline.users.length === 0) return '&mdash;';
            return discipline.users.map((user) => user.name).join(', ');
        },

        onUserActionClick($event, user) {
            this.$emit('userAction', $event, user);
        },

        onLineActionClick($event, line) {
            this.$emit('lineAction', $event, line);
        },

        mapToLocalUser(user) {
            const local = this.userFromName(user.name, user.discipline) || {};
            return {...user, ...local};
        },

        log(value) {
            console.log(value);
        },
    }
}
</script>

<style lang="scss">
.project-report-analysis {
    min-width: 800px;
    position: relative;

    .f-flex-table-title-cell.--warning {
        .f-flex-table-title-cell__icon {
            background: $warning;
        }
    }

    .--price-alert {
        color: $dangerShade;
    }

    .--price-warning {
        color: $warning;
    }

    &__totals-bg {
        position: absolute;
        top: 0;
        right: 35px + 200px;
        height: 100%;
        width: 200px;
        pointer-events: none;
        border-radius: 5px;

        background-color: #fafafa;
        z-index: -1;

        &.--small {
            width: 100px;
        }
    }

    &__allocations-bg {
        position: absolute;
        top: 0;
        right: 35px;
        height: 100%;
        width: 200px - 5px;
        pointer-events: none;
        border-radius: 5px;
        z-index: 2;
        border: 1px dashed $quinaryGray;
    }

    .--alt-color {
        color: #6d6d6d;
    }

    &__cards-group {
        display: inline-flex;
        flex-direction: column;

        > :first-child {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: 0;
        }

        > :last-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    &__user-row-wrapper {
        border-bottom: 1px solid $softGray;

        .f-flex-table-cell * {
            vertical-align: middle;
        }

        .f-flex-table-row {
            border-bottom: 0;

            .f-flex-table-cell {
                padding: 0;
            }
        }

        &__actions {
            margin: 2px 5px 0;
        }

        &__excluded_row {
            opacity: .5;

            .f-flex-table-cell {
                padding: 5px !important;
            }

            &:before {
                display: none;
            }

            .f-flex-table-row__title {
                padding-left: 30px !important;
                padding-top: 0 !important;
            }
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    .status-icon {
        margin-left: 5px;
    }

    .f-flex-table-row .f-text {
        padding: 0 10px 5px;
        opacity: .5;
        margin-top: 5px;

        &:not(:first-child) {
            margin-top: 20px;
        }
    }

    &__order-lines {
        &.--line {
            border-top: 2px solid rgba(white, .15);
        }

        &__actions {
            position: absolute;
            margin: 0 0 0 6px;
        }
    }

    &__excluded-allocations {
        margin-top: 50px;
    }
}
</style>
