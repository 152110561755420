import {ObserverProvider} from '../utils/ObserverProvider';

export default {
    data() {
        return {
            observer: undefined,
        }
    },

    created() {
        this.observer = new ObserverProvider(this.$el);
    },

    beforeDestroy() {
        this.observer.disconnect();
    },
};
