<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
    <page
        :title="documentTitle"
        class-name="meeting-rooms-page"
        :header="`<strong>Meeting</strong> <em>Rooms</em>`"
        pre-max-width="800px"
    >
        <!--        <template #headerPre>-->
        <!--            <nav class="meeting-rooms-page__navigation">-->
        <!--                <f-flex gap="10px">-->
        <!--                    <f-icon-button-->
        <!--                        icon="chevron-left"-->
        <!--                        size="xlarge"-->
        <!--                        @click="previousDate"-->
        <!--                        :disabled="isToday"-->
        <!--                        tooltip="Go to previous day"-->
        <!--                        outlined-->
        <!--                    />-->

        <!--                    <f-button-group>-->
        <!--                        <f-button-->
        <!--                            @click="todayDate"-->
        <!--                            v-tooltip="'Go to today'"-->
        <!--                            value="Today"-->
        <!--                            size="xlarge"-->
        <!--                            outlined-->
        <!--                            :disabled="isToday"-->
        <!--                        />-->
        <!--                        <f-button-->
        <!--                            v-tooltip="'Show calendar'"-->
        <!--                            ref="calendarButton"-->
        <!--                            @click="showCalendarPopup = true; EventBus.$emit(GlobalEvents.TOOLTIP_HIDE)"-->
        <!--                            icon="calendar-31"-->
        <!--                            size="xlarge"-->
        <!--                            :selected="showCalendarPopup"-->
        <!--                            outlined-->
        <!--                        />-->
        <!--                    </f-button-group>-->

        <!--                    <f-icon-button-->
        <!--                        icon="chevron-right"-->
        <!--                        size="xlarge"-->
        <!--                        @click="nextDate"-->
        <!--                        tooltip="Go to next day"-->
        <!--                        outlined-->
        <!--                    />-->
        <!--                </f-flex>-->
        <!--            </nav>-->
        <!--        </template>-->

        <f-container max-width="800px">
            <f-inline-message id="rivieraMeetingRoomsDead" :closable="false">
                The Meeting Rooms module has been discontinued. Because we are heading towards One Brand, and
                because of the recent switch to Microsoft Office 365. You can find and book the meeting rooms in the
                Microsoft Outlook Calendar.<br><br>
                If you have any questions:
                <ul>
                    <li>check our <a href="https://hyspot.sharepoint.com/sites/FAQ">Sharepoint FAQ</a></li>
                    <li>or the <a href="https://sway.office.com/KfQNu2FxpbkrT7qV" target="_blank">Ruby IT Migration
                        Support
                        document</a> (password is <code>RubyIT2023</code>)
                    </li>
                    <li>message our Slack channel <strong>#it-migration-support</strong></li>
                    <li>
                        or message our IT-specialist
                        <f-button
                            tag="a"
                            target="_blank"
                            variant="light"
                            size="small"
                            :href="`slack://user?team=T02EQNMKZ&id=U01JZ902LJV/`"
                        >
                            <template #pre>
                                <div class="slack-icon"/>
                            </template>
                            <span class="f-button__value__text">
                        @Sonny Lentze
                    </span>
                        </f-button>
                    </li>
                </ul>
            </f-inline-message>

            <!--            <div id="meetingRoomsPage"/>-->

            <!--            <f-loader v-if="loading"/>-->

            <!--            <div-->
            <!--                v-if="$store.state.$user.office === 'amsterdam'"-->
            <!--                class="&#45;&#45;align-center"-->
            <!--                style="padding: 15px 0 35px;"-->
            <!--            >-->
            <!--                <f-tabs @tabChange="type = $event">-->
            <!--                    <f-tab id="meeting-room" title="Meeting rooms"/>-->
            <!--                    <f-tab id="phone-booth" title="Phone booths"/>-->
            <!--                </f-tabs>-->
            <!--            </div>-->

            <!--            <time-table-->
            <!--                :office="currentOffice"-->
            <!--                :show-now="isToday"-->
            <!--                :meeting-rooms="meetingRooms ? meetingRooms : []"-->
            <!--                height="50vh"-->
            <!--                min-height="400px"-->
            <!--                :min-width="(meetingRooms && meetingRooms.length * 180) + 'px'"-->
            <!--                :bookable="false"-->
            <!--                ref="timeTable"-->
            <!--                show-header-->
            <!--            >-->
            <!--                <time-table-header-item-->
            <!--                    v-if="(meetingRooms && meetingRooms.length > 0)"-->
            <!--                    v-for="(meetingRoom, i) in meetingRooms"-->
            <!--                    :key="i"-->
            <!--                    :name="meetingRoom.name"-->
            <!--                    :state="(isToday && meetingRoom.calendar ? getMeetingRoomState(meetingRoom) : null)"-->
            <!--                />-->
            <!--            </time-table>-->
        </f-container>

        <!--        <f-popup-->
        <!--            v-if="showCalendarPopup && $refs.calendarButton"-->
        <!--            :target="$refs.calendarButton.$el"-->
        <!--            class-name="f-date-picker-popup"-->
        <!--            max-height="auto"-->
        <!--            @close="showCalendarPopup = false"-->
        <!--            padding="0"-->
        <!--            variant="dark"-->
        <!--        >-->
        <!--            <f-date-picker-select-->
        <!--                :value="currentDateObject"-->
        <!--                :apply="configureDates"-->
        <!--                @picked="pickDate"-->
        <!--                hide-weekends-->
        <!--            />-->
        <!--        </f-popup>-->
    </page>
</template>

<script>
import Page from '../Page';
import TimeTable from '../../misc/time-table/TimeTable';
import FIconButton from '../../../f-components/form-controls/buttons/FIconButton';
import TimeTableHeaderItem from '../../misc/time-table/TimeTableHeaderItem';
import FPopup from '../../../f-components/other/popup/FPopup';
import FDatePickerSelect from '../../../f-components/form-controls/dates/FDatePickerSelect';
import FButtonGroup from '../../../f-components/form-controls/buttons/FButtonGroup';
import FButton from '../../../f-components/form-controls/buttons/FButton';
import FContainer from '../../../f-components/layout/container/FContainer';
import FFlex from '../../../f-components/layout/flex/FFlex';
import FLoader from '../../../f-components/loaders/loader/FLoader';
import FTabs from '../../../f-components/navigation/tabs/FTabs';
import FTab from '../../../f-components/navigation/tabs/FTab';
import FInlineMessage from '../../../f-components/notice/inline-message/FInlineMessage.vue';

export default {
    name: 'meeting-rooms-page',

    components: {
        FInlineMessage,
        FTab,
        FTabs,
        FLoader,
        FFlex,
        FContainer,
        FButton,
        FButtonGroup,
        FDatePickerSelect,
        FPopup,
        Page,
        TimeTableHeaderItem,
        FIconButton,
        TimeTable,
    },

    mixins: [],

    data() {
        return {
            documentTitle: ['Meeting Rooms', this.$route.params.office.ucwords()],
            paramOffice: this.$route.params.office,
            dateIndex: 0,
            showCalendarPopup: false,
            meetingRooms: undefined,
            type: 'meeting-room',
        }
    },

    computed: {
        // currentOffice() {
        //     return this.paramOffice;
        // },
        // isToday() {
        //     return this.currentDateString === APIDataStore.date();
        // },
        // currentDateVisual() {
        //     return (this.isToday ?
        //         'today, <em>' + this.currentDateObject.format('MMMM Do') + '</em>' :
        //         '<em>' + this.currentDateObject.format('dddd') + '</em>, ' + this.currentDateObject.format('MMMM Do'));
        // },
        // currentDateObject() {
        //     return moment.tz(this.currentDateString, 'DD-MM-YYYY', this.timezone);
        // },
        // label() {
        //     if (this.type === 'meeting-room') return 'Meeting room';
        //     else return 'Phone booth';
        // },
    },

    watch: {
        // type() {
        //     this.newDateIndex();
        // },
    },

    created() {
        // this.newDateIndex();
    },

    mounted() {
        // EventBus.$on('meeting.book.start', () => this.loading = true);
        // EventBus.$on('meeting.book.end', () => this.loading = false);
    },

    methods: {
        // configureDates(days) {
        //     const now = moment().startOf('day');
        //
        //     for (let i in days) {
        //         if (Object.prototype.hasOwnProperty.call(days, i)) {
        //             if ([6, 7].includes(days[i].date.isoWeekday()) /* weekends */ || days[i].date.isBefore(now)) {
        //                 days[i].unavailable = true;
        //             }
        //         }
        //     }
        //
        //     return days;
        // },
        //
        // nextDate() {
        //     EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        //     this.dateIndex++;
        //     this.newDateIndex();
        // },
        //
        // previousDate() {
        //     EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        //     this.dateIndex--;
        //     this.newDateIndex();
        // },
        //
        // todayDate() {
        //     EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        //     this.dateIndex = 0;
        //     this.newDateIndex();
        // },
        //
        // pickDate(date) {
        //     this.showCalendarPopup = false;
        //
        //     EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        //     const today = moment().startOf('day');
        //
        //     this.dateIndex = date.startOf('day').diff(today, 'days');
        //     this.newDateIndex();
        // },
        //
        // newDateIndex() {
        //     this.startLoading();
        //
        //     this.currentDateString = APIDataStore.date(this.dateIndex);
        //     const endpoint = (this.type === 'meeting-room' ? APIEndpoints.MEETING_ROOMS : APIEndpoints.MEETING_ROOMS_PHONE_BOOTHS);
        //
        //     const url = APIEndpoints.parse(endpoint, {
        //         office: this.currentOffice,
        //         date: this.currentDateString,
        //     });
        //
        //     window.axios
        //         .get(url)
        //         .then(response => {
        //             this.endLoading(true);
        //             this.meetingRooms = response.data.data;
        //             this.$refs.timeTable.setInitialScrollPosition();
        //         })
        //         .catch((e) => this.onLoadingError(e));
        //
        //     EventBus.$emit('meetingRooms.date.change', this.currentDateString);
        //
        //     // EventBus.$emit('bookingGhost.hide', this.currentDateString);
        // },
    },
}
</script>

<style lang="scss">
.meeting-rooms-page {
    .time-table {
        margin: 50px 0 25px 0;
    }

    .page__pre__header {
        @include media(mobile-down) {
            flex-wrap: wrap;
            gap: 40px;
        }
    }

    &__navigation {
        @include media(mobile-down) {
            flex-grow: 1;
        }
    }

    .slack-icon {
        width: 18px;
        height: 18px;
        background: url(../../../img/slack.png);
        background-size: cover;
        margin-right: 10px;
    }
}
</style>
