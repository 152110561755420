<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
    <tr
        :class="[
            'f-table-row',
            ...classNames,
            (disable ? '--disable' : null),
            (noWrap ? '--no-wrap' : null),
            (actionsOpen ? '--actions-open' : null),
            (isSelected ? '--selected' : null),
            (hasHover ? '--hover' : null),
            ((onHoverThemeChange && hasHover && rowHoverable) || isSelected ? '--theme-dark' : null),
        ]"
        @click="onRowClick"
        @mouseenter="hasHover = true"
        @mouseleave="hasHover = false"
    >
        <f-table-cell
            v-if="selectable && !breakpoints.mobile"
            class-name="f-table-row__selectable"
            @click="onSelectableCellClick"
            no-padding-right
        >
            <f-checkbox v-model="isSelected"/>
        </f-table-cell>

        <slot/>

        <f-table-cell
            v-if="actions"
            align="right"
            width="20px"
        >
            <f-icon-button
                ref="actions"
                class-name="f-table-row__actions"
                @click="onActionsClick"
                icon="ellipsis"
                size="small"
                outlined
                :selected="actionsOpen"
                :disabled="actions.length === 0"
                no-parent-hover
            />

            <f-popup
                v-if="actionsOpen && $refs.actions"
                :target="$refs.actions.$el"
                @close="actionsOpen = false"
                padding="0"
            >
                <f-popup-list>
                    <f-popup-list-item
                        v-for="(action, i) in actions"
                        v-if="action"
                        :icon="action.icon"
                        :key="i"
                        :disabled="action.disabled"
                        @click="actionsOpen = false; action.onClick($event);"
                    >
                        {{ action.value }}
                    </f-popup-list-item>
                </f-popup-list>
            </f-popup>
        </f-table-cell>
    </tr>
</template>

<script>
import FTableCell from './FTableCell';
import FIconButton from '../../form-controls/buttons/FIconButton';
import FPopup from '../../other/popup/FPopup';
import FPopupList from '../../other/popup/FPopupList';
import FPopupListItem from '../../other/popup/FPopupListItem';
import FCheckbox from "../../form-controls/checkbox/FCheckbox";
import ClassNameMixin from '../../../mixins/ClassNameMixin';
import BreakpointsMixin from '../../../mixins/BreakpointsMixin';

export default {
    name: 'f-table-row',

    mixins: [
        ClassNameMixin,
        BreakpointsMixin,
    ],

    components: {
        FCheckbox,
        FPopupListItem,
        FPopupList,
        FPopup,
        FIconButton,
        FTableCell,
    },

    props: {
        disable: Boolean,
        noWrap: Boolean,
        actions: Array,
        selectable: Boolean,
        selected: Boolean,
        onHoverThemeChange: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            actionsOpen: false,
            isSelected: this.selected,
            hasHover: false,
        }
    },

    computed: {
        rowHoverable() {
            return !!(this.$parent && this.$parent.$el && this.$parent.$el.closest('.f-table__container.--rows-hoverable'));
        },
    },

    watch: {
        isSelected(value) {
            this.$emit('selectStateChange', value);
        },
    },

    methods: {
        onRowClick(e) {
            this.$emit('click', e);
        },

        onActionsClick(e) {
            e.stopPropagation();
            this.actionsOpen = true;
        },

        onSelectableCellClick(e) {
            e.stopPropagation();
        },

        select() {
            this.isSelected = true;
        },

        deselect() {
            this.isSelected = false;
        },
    },
}
</script>

<style lang="scss">
.f-table {
    .f-table-row {
        &__selectable {
            text-align: center;
            cursor: default !important;
            width: 20px;
        }

        &.--disable, &.--disabled {
            opacity: .25;
            pointer-events: none;
        }

        &.--no-wrap {
            .f-table-cell, .f-table-header-cell {
                white-space: nowrap;
            }
        }
    }

    // Simple

    &__container.--simple-dense & .f-table-row, &__container.--simple & .f-table-row {
        .f-table-cell {
            border-bottom: 1px solid;

            @include theme(light) {
                border-bottom-color: $softGray;
            }

            @include theme(dark) {
                border-bottom-color: $quaternaryGray;
            }
        }

        &:last-child {
            .f-table-cell {
                border: 0;
            }
        }
    }

    // Default

    &__container.--default & .f-table-row,
    &__container.--default-dense & .f-table-row {
        .f-table-cell {
            @include theme(light) {
                background-color: $softGray;
            }

            @include theme(dark) {
                background-color: $darkAlternative;
            }
        }
    }

    // Hover and selected state

    &__container.--rows-hoverable & {
        tbody .f-table-row:not(.--selected) {
            &:hover {
                .f-table-cell {
                    @include theme(light) {
                        background-color: $primary;
                        color: $primaryFront;
                        cursor: pointer;
                    }

                    @include theme(dark) {
                        background-color: $primary;
                        color: $primaryFront;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &__container & {
        tbody .f-table-row.--selected {
            .f-table-cell {
                @include theme(light) {
                    background-color: $info;
                    color: $infoFront;
                }

                @include theme(dark) {
                    background-color: $info;
                    color: $infoFront;
                }
            }

            &:hover {
                .f-table-cell {
                    @include theme(light) {
                        background-color: $infoShade;
                    }

                    @include theme(dark) {
                        background-color: $infoShade;
                    }
                }
            }
        }
    }

}
</style>
