<template>
    <f-portal
        v-if="active"
        reference="tooltip"
    >
        <transition name="popup" appear>
            <div
                ref="positionElement"
                :class="[
                    'f-tooltip',
                    '--no-select',
                    `--${currentAlign}`,
                    `--${currentAlignHorizontal}`,
                    (options.touch ? '--touch' : null),
                    (options.wrap ? '--wrap' : null),
                    `--${options.variant}`,
                    options.className,
                ]"
                :style="{
                    top: `${pos.top}px`,
                    left: `${pos.left}px`,
                    maxWidth: `${options.maxWidth}px`,
                    textAlign: options.textAlign,
                }"
            >
                <div
                    class="f-tooltip__content"
                    v-html="value"
                />
            </div>
        </transition>
    </f-portal>
</template>

<script>
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import PositionMixin from '../../../mixins/position/PositionMixin';
import {TooltipVariants} from './config';
import FPortal from '../../other/portal/FPortal';

export default {
    name: 'f-tooltip',

    components: {
        FPortal,
    },

    mixins: [
        PositionMixin,
    ],

    created: function () {
        EventBus.$on(GlobalEvents.TOOLTIP_SHOW, (target, value, options) => this.show(target, value, options));
    },

    data() {
        return {
            defaultOptions: {
                margin: 0,
                maxWidth: 200,
                textAlign: 'center',
                className: null,
                align: 'top',
                wrap: false,
                offsetTop: 0,
                offsetLeft: 0,
                variant: TooltipVariants.PRIMARY,
                touch: false, // TODO: Do something with touch devices?
            },
            options: {},
            active: false,
            value: '',
        };
    },

    methods: {
        show(target, value, options = {}) {
            if (!value || !target) {
                this.active = false;
                return;
            }

            this.setOptions({...options, target});

            this.value = value;
            this.active = true;

            this.$nextTick(() => this.position());
            this.$nextTick(() => this.$nextTick(() => this.position()));

            this.registerHideEvents();
        },

        setOptions(options) {
            this.options = {
                ...this.defaultOptions,
                ...options,
            };
        },

        hide() {
            this.active = false;
            this.removeHideEvents();
        },

        registerHideEvents() {
            EventBus.$on(GlobalEvents.TOOLTIP_HIDE, this.hide);
            EventBus.$on(GlobalEvents.PAGE_CHANGE, this.hide);
            EventBus.$on(GlobalEvents.OVERFLOW_SCROLL, this.hide);
            document.addEventListener('scroll', this.hide.bind());
        },

        removeHideEvents() {
            EventBus.$off(GlobalEvents.TOOLTIP_HIDE, this.hide);
            EventBus.$off(GlobalEvents.OVERFLOW_SCROLL, this.hide);
            EventBus.$off(GlobalEvents.PAGE_CHANGE, this.hide);
            document.removeEventListener('scroll', this.hide);
        },
    },
}
</script>

<style lang="scss">
.f-tooltip {
    position: fixed;
    width: auto;
    padding: 6px 10px 7px;
    font-size: 11px;
    border-radius: 5px;
    pointer-events: none;
    z-index: $tooltipOrder;
    line-height: 130%;
    box-shadow: rgba($dark, .075) 0 0 3px;
    background: $primary;
    color: $primaryFront;
    font-weight: 500;

    &.--touch {
        // TODO
    }

    &__content {
        white-space: nowrap;
        min-width: 10px;

        ul {
            text-align: left;
            list-style-type: disc;
            padding-left: 10px;
            margin-left: 10px;
        }

        code {
            opacity: .75;
            font-size: 70%;
            word-break: break-all;
            line-height: 90%;
        }
    }

    &.--wrap .f-tooltip__content {
        white-space: normal;
        overflow: hidden;
    }

    // Variants

    &.--alternative, &.--default, &.--primary {
        background: $primary;
        color: $primaryFront;

        li, li a, code {
            color: $primaryFront;
        }
    }

    &.--info {
        color: $infoFront;
        background: $info;

        li, li a, code {
            color: $infoFront;
        }
    }

    &.--secondary {
        color: $secondaryFront;
        background: $secondary;

        li, li a, code {
            color: $secondaryFront;
        }
    }

    &.--success {
        color: $successFront;
        background: $success;

        li, li a, code {
            color: $successFront;
        }
    }

    &.--warning {
        color: $warningFront;
        background: $warning;

        li, li a, code {
            color: $warningFront;
        }
    }

    &.--danger {
        color: $light;
        background: $danger;

        li, li a, code {
            color: $light;
        }
    }

    &.--dark {
        color: $light;
        background: $dark;

        li, li a, code {
            color: $light;
        }
    }

    @include arrow-pointer-themes($primary, (
        '--info': $info,
        '--secondary': $secondary,
        '--warning': $warning,
        '--success': $success,
        '--danger': $danger,
        '--dark': $dark,
    ));
}
</style>
