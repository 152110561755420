<template>
    <div :class="['projects-merge-modal-contents', (loading ? '--loading' : null), '--loader']">
        <div v-if="data">
            You are about to merge the following projects. Please select the main project:

            <f-radio-button-list v-model="mainProjectID">
                <f-scroll-pane max-height="400px">
                    <f-table type="simple">
                        <f-table-row v-for="project in data" :key="project.id">
                            <f-table-cell width="10px" no-padding-right>
                                <f-radio-button
                                    name="main"
                                    :value="project.id"
                                />
                            </f-table-cell>
                            <f-table-cell width="50px" hide-mobile no-padding-left-right>
                                <entity-avatar
                                    v-if="clientFromId(project.client_id)"
                                    :src="clientFromId(project.client_id).image_url"
                                    :name="clientFromId(project.client_id).name"
                                    size="xmedium"
                                />
                                <entity-avatar
                                    v-else-if="project.supplemental_data && project.supplemental_data.company_name"
                                    :name="project.supplemental_data.company_name"
                                    size="xmedium"
                                />
                            </f-table-cell>
                            <f-table-title-cell>
                                {{ project.name }}

                                <template #sub>
                                    <div>
                                        <f-chip
                                            variant="dark"
                                            class="projects-overview__project-number"
                                            code
                                        >
                                            {{ project.number }}
                                        </f-chip>
                                        {{ project.office_id.ucfirst() }} office
                                        <span v-if="clientFromId(project.client_id)">
                                            <subtitle-spacer/>
                                            {{ clientFromId(project.client_id).name }}
                                        </span>
                                    </div>
                                    <div v-if="project.is_archived">
                                        <f-chip
                                            variant="archived"
                                            size="small"
                                        >
                                            archived
                                        </f-chip>
                                    </div>
                                </template>
                            </f-table-title-cell>
                        </f-table-row>
                    </f-table>
                </f-scroll-pane>
            </f-radio-button-list>

            <footer>
                <f-button
                    outlined
                    variant="light"
                    @click="$emit('close')"
                >
                    Cancel
                </f-button>
                <f-button
                    show-loader-on-disabled
                    :disabled="loading"
                    @click="merge"
                    variant="primary"
                >
                    Continue
                </f-button>
            </footer>
        </div>

        <f-placeholder-container v-else-if="loading">
            <f-placeholder width="100%" height="60px"/>
            <f-placeholder width="100%" height="60px"/>
            <f-placeholder width="100%" height="60px"/>
        </f-placeholder-container>
    </div>
</template>

<script>
import APIEndpoints from "../../../../config/APIEndpoints";
import FPlaceholderContainer from "../../../../f-components/loaders/placeholder/FPlaceholderContainer";
import FPlaceholder from "../../../../f-components/loaders/placeholder/FPlaceholder";
import FTableRow from "../../../../f-components/data/table/FTableRow";
import FTableCell from "../../../../f-components/data/table/FTableCell";
import EntityAvatar from "../../../misc/entities/EntityAvatar";
import ClientHelperMixin from "../../../../mixins/ClientHelperMixin";
import FTable from "../../../../f-components/data/table/FTable";
import FTableTitleCell from "../../../../f-components/data/table/FTableTitleCell";
import FChip from "../../../../f-components/data/chip/FChip";
import FButton from "../../../../f-components/form-controls/buttons/FButton";
import FRadioButton from "../../../../f-components/form-controls/radiobutton/FRadioButton";
import FRadioButtonList from "../../../../f-components/form-controls/radiobutton/FRadioButtonList";
import EventBus from '../../../../services/EventBus';
import {GlobalEvents} from '../../../../config/Events';
import LoadingMixin from '../../../../mixins/LoadingMixin';
import {SiteLoaderSizes} from '../../../../f-components/loaders/site-loader/config';
import FScrollPane from '../../../../f-components/other/scroll-pane/FScrollPane';
import SubtitleSpacer from '../../../misc/SubtitleSpacer';

export default {
    name: 'projects-merge-modal-contents',

    components: {
        SubtitleSpacer,
        FScrollPane,
        FRadioButtonList,
        FRadioButton,
        FButton,
        FChip,
        FTableTitleCell,
        FTable,
        EntityAvatar,
        FTableCell,
        FTableRow,
        FPlaceholder,
        FPlaceholderContainer,
    },

    mixins: [
        ClientHelperMixin,
        LoadingMixin,
    ],

    props: {
        projectIds: Array,
    },

    data() {
        return {
            data: null,
            mainProjectID: this.projectIds[0],
        }
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.startLoading();

            window.axios
                .get(APIEndpoints.PROJECT_MERGE, {
                    params: {ids: this.projectIds},
                })
                .then(response => this.onHandledRequestsSuccess(response))
                .catch(() => this.endLoading());
        },

        onHandledRequestsSuccess(response) {
            this.endLoading(true);
            this.data = response.data.data;
        },

        merge() {
            this.startLoading(SiteLoaderSizes.BIG);

            window.axios
                .post(APIEndpoints.PROJECT_MERGE, {
                    ids: this.projectIds,
                    main_id: this.mainProjectID,
                })
                .then(() => {
                    this.endLoading();
                    EventBus.$emit(GlobalEvents.PUSH_MESSAGE, 'Successfully merged projects');
                    this.$emit('success');
                })
                .catch(() => this.endLoading());
        },
    },
}
</script>

<style lang="scss">
.projects-merge-modal-contents {
    .f-radio-button-list {
        margin: 40px 0 40px;
    }

    footer {
        display: flex;
        justify-content: space-between;
    }
}
</style>
