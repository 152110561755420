<template>
    <f-container
        :class-name="['page-header', '--no-select']"
        :max-width="null"
        tag="header"
    >
        <hamburger/>
        <office-timezones alternative/>
        <page-header-navigation/>
    </f-container>
</template>

<script>
import OfficeTimezones from './components/office-timezones/OfficeTimezones';
import FContainer from '../../f-components/layout/container/FContainer';
import PageHeaderNavigation from './components/navigation/HeaderNavigation';
import Hamburger from './components/Hamburger';

export default {
    name: 'page-header',

    components: {
        Hamburger,
        PageHeaderNavigation,
        FContainer,
        OfficeTimezones,
    },
}
</script>

<style lang="scss">
.page-header {
    display: flex;
    height: 110px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 3;

    .hamburger {
        display: none;
    }

    @media only screen and (max-width: 880px) {
        justify-content: end;

        .office-timezones {
            display: none;
        }
    }

    @include media(mobile-down) {
        height: 90px;
        justify-content: space-between;

        .hamburger {
            display: block;
        }
    }
}
</style>
