<template>
    <img
        :class="[
            'f-image-loader',
            (isLoading ? '--loading' : null),
            (cover ? '--cover' : null),
            (transitionSize ? '--transition-size' : null),
        ]"
        :src="srcComputed"
        :style="{
            height,
            maxHeight,
            width,
            opacity,
        }"
        :alt="alt"
        @load="onLoad"
        @error="onError"
    />
</template>

<script>
import APIEndpoints from '../../../config/APIEndpoints';
import ObserverEntryMixin from '../../../mixins/ObserverEntryMixin';

export default {
    name: 'f-image-loader',

    mixins: [
        ObserverEntryMixin,
    ],

    props: {
        src: {
            type: String,
            required: true,
        },
        alt: String,
        maxHeight: String,
        height: String,
        width: String,
        secure: Boolean,
        transitionSize: Boolean,
        cover: Boolean,
    },

    computed: {
        srcComputed() {
            if (!this.visible) return undefined;
            if (this.secure) return APIEndpoints.secureAsset(this.src);
            return this.src;
        },
    },

    watch: {
        src() {
            this.$emit('loading');
            this.isLoading = true;
        },
    },

    data() {
        return {
            isLoading: true,
            opacity: this.observer ? 0 : undefined,
            visible: !this.observer,
        }
    },

    mounted() {
        this.$emit('loading');
        if (this.observer) {
            this.$on('observe', () => this.visible = true);
        }
    },

    methods: {
        onLoad() {
            this.$emit('complete');
            this.isLoading = false;
            if (this.observer) this.opacity = 1;
        },
        onError() {
            this.$emit('error');
            this.isLoading = false;
        },
    },
}
</script>

<style lang="scss">
.f-image-loader {
    position: relative;
    transition: filter .5s, opacity 1s;

    &.--transition-size {
        transition: filter .5s, width .1s ease-in-out, height .1s ease-in-out;
    }

    &.--loading {
        filter: grayscale(100%);
    }

    &.--cover {
        object-fit: cover;
    }
}
</style>
