<template>
    <div
        :class="[
            'entity-avatar-group',
            ...classNames,
            `--${size}`,
        ]"
    >
        <entity-avatar
            v-for="entity in limitedEntities"
            :key="entity.id"
            :name="entity.name"
            :tooltip="entity.name + tooltipPost"
            :src="(entity.image ? entity.image : entity.profile_image)"
            :size="size"
            :color="color"
            :type="type"
        />
        <div
            v-if="entities.length > max"
            v-tooltip="moreDescription"
            :class="[
                'entity-avatar-group__more',
                `--${size}`,
            ]"
        >
            +{{ entities.length - max }}
        </div>
    </div>
</template>

<script>
import ClassNameMixin from '../../../mixins/ClassNameMixin';
import EntityAvatar from './EntityAvatar';
import {EntityAvatarSizes} from './config';

export default {
    name: 'entity-avatar-group',

    components: {
        EntityAvatar,
    },

    mixins: [
        ClassNameMixin,
    ],

    props: {
        className: String,
        max: {
            type: Number,
            default: 5,
        },
        entities: {
            type: Array,
            required: true,
        },
        size: {
            type: String,
            default: EntityAvatarSizes.MEDIUM,
        },
        type: {
            type: String,
            default: 'round', // round | square
        },
        color: {
            type: String,
            default: 'light', // light | dark
        },
        tooltipPost: {
            type: String,
            default: '',
        },
    },

    computed: {
        limitedEntities() {
            return [...this.entities].splice(0, this.max);
        },

        moreDescription() {
            const more = [...this.entities].splice(this.max, this.entities.length - 1);
            let items = [];

            for (let i in more) {
                items.push(more[i].name ?? more[i].email_address);
            }

            return `${items.join('<br>')}`;
        },
    },
}
</script>

<style lang="scss">
.entity-avatar-group {
    display: inline-flex;
    padding-left: 8px;

    .entity-avatar, .entity-avatar-group__more {
        margin-left: -8px;
    }

    .entity-avatar, .entity-avatar-group__more {
        position: relative;

        &:hover {
            box-shadow: 0 0 0 1px white;
            z-index: 2;
        }
    }

    &.--xsmall {
        padding-left: 5px;

        .entity-avatar, .entity-avatar-group__more {
            margin-left: -5px;
        }
    }

    &__more {
        width: 32px;
        height: 32px;
        vertical-align: middle;
        border-radius: 50%;
        background: $softGray;
        position: relative;
        line-height: 32px;
        text-align: center;
        color: $dark;
        user-select: none;

        &.--square {
            border-radius: 5px;
        }

        &.--xxsmall {
            width: 20px;
            height: 20px;
            line-height: 18px;
            font-size: 8px;
        }

        &.--xsmall {
            width: 22px;
            height: 22px;
            line-height: 22px;
            font-size: 9px;
        }

        &.--small {
            width: 25px;
            height: 25px;
            line-height: 24px;
            font-size: 10px;
        }

        &.--xmedium {
            width: 42px;
            height: 42px;
            line-height: 330%;
            font-size: 130%;
        }

        &.--large {
            width: 55px;
            height: 55px;
            line-height: 55px;
            font-size: 23px;
        }

        &.--xlarge {
            width: 80px;
            height: 80px;
            line-height: 80px;
            font-size: 35px;
        }

        &.--xxlarge {
            width: 100px;
            height: 100px;
            font-size: 44px;
            line-height: 102px;

            @include media(mobile-down) {
                width: 80px;
                height: 80px;
                font-size: 330%;
                line-height: 78px;
            }
        }
    }
}
</style>
