import EventBus from '../services/EventBus';
import {GlobalEvents} from '../config/Events';

export default {
    data() {
        return {
            modalsOpen: 0,
        }
    },

    mounted() {
        EventBus.$on(GlobalEvents.MODAL_OPEN, this.onModalOpen);
        EventBus.$on(GlobalEvents.MODAL_CLOSE, this.onModalClose);
    },

    beforeDestroy() {
        EventBus.$off(GlobalEvents.MODAL_OPEN, this.onModalOpen);
        EventBus.$off(GlobalEvents.MODAL_CLOSE, this.onModalClose);
    },

    methods: {
        onModalOpen() {
            this.modalsOpen++;
        },
        onModalClose() {
            this.modalsOpen--;

            if (this.modalsOpen < 0) {
                this.modalsOpen = 0;
            }
        },
    },
};
