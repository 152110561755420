import EventBus from '../../../services/EventBus';

export default {
    props: {
        validation: {
            type: Array,
            default: () => [],
        },
        skipValidationAutoFocus: Boolean,
        name: String,
    },

    data() {
        return {
            isValidatable: true,
            inputError: this.error,
            showError: false,
            isValid: false,
            debug: false,
        };
    },

    mounted() {
        this.registerValidationListeners();
    },

    beforeDestroy() {
        this.removeValidationListeners();
    },

    methods: {
        getValue() {
            throw new Error('Implement getValue for ' + this.$options._componentTag);
        },

        setError(error) {
            this.isValid = false;

            if (error) {
                const name = this.errorName ? this.errorName : (this.name ? this.name : 'field').replace(/_/g, ' ');
                error = error.replace('{name}', name.toLowerCase());

                if (this.debug) {
                    console.log(`On: [${this.$options._componentTag}.${this.uid}.error]: ${error}`);
                }
            }

            this.inputError = error;
        },

        resetValidation() {
            if (this.isValid) {
                return;
            }

            if (this.debug) {
                console.log(`On: ${this.$options._componentTag}.${this.uid}.resetValidation`);
            }

            this.setError();
            this.setValid();
        },

        setValid() {
            if (this.debug) {
                console.log(`On: ${this.$options._componentTag}.${this.uid}.valid`);
            }

            this.isValid = true;
        },

        registerValidationListeners() {
            // Listen to global input focus events.
            // Error label could be forcibly shown, and needs to be hidden if a focus happens of another field.
            EventBus.$on('input.focus', this.onValidationGlobalInputFocus);
            this.$on('input', this.resetValidation);
            this.$on('focus', this.onValidationFocus);
            this.$on('blur', this.onValidationBlur);
        },

        removeValidationListeners() {
            EventBus.$off('input.focus', this.onValidationGlobalInputFocus);
            this.$off('input', this.resetValidation);
            this.$off('focus', this.onValidationFocus);
            this.$off('blur', this.onValidationBlur);
        },

        onValidationGlobalInputFocus(uid) {
            if (uid !== this.uid) {
                this.showError = false;
            }
        },

        onValidationFocus() {
            this.showError = true;
            EventBus.$emit('input.focus', this.uid);
        },

        onValidationBlur() {
            this.showError = false;
        },
    },
};
