<template>
    <li
        :class="[
            'f-flex-table-footer-row',
            className,
            (collapsible ? '--collapsible' : null),
        ]"
    >
        <slot/>
    </li>
</template>

<script>
export default {
    name: 'f-flex-table-footer-row',

    props: {
        collapsible: Boolean,
        className: String,
    },
}
</script>

<style lang="scss">
.f-flex-table-footer-row {
    border-top: 1px solid;
    overflow: hidden;
    display: flex;
    margin-top: 10px;
    padding: 10px 0;

    @include theme(light) {
        border-color: $tertiaryGray;
    }

    @include theme(dark) {
        border-color: $quaternaryGray;
    }

    &.--collapsible {
        padding-right: 35px;
    }
}
</style>
