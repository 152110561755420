<template>
    <div
        :class="[
            'employee-leave-balance-tab',
            '--loader',
            (loading ? '--loading' : undefined),
        ]"
    >
        <f-user-dropdown
            v-model="employeeID"
            placeholder="Employee"
            :class-name="[
                '--align-center',
                'employee-leave-balance-tab__dropdown'
            ]"
            :options="users"
            max-width="300px"
        />

        <f-inline-message
            v-if="fetchedData && fetchedData.data.error"
            type="error"
            :closable="false"
            id="leaveBalanceAdminError"
        >
            {{ fetchedData.data.error }}
        </f-inline-message>

        <div
            class="employee-leave-balance-tab__content"
            v-else-if="fetchedData"
        >
            <f-form-fieldset legend="Balance 2022">
                <leave-balance :data="fetchedData.data.balance"/>

                <div class="employee-leave-balance-tab__content__overview">
                    <leave-overview :data="fetchedData.data.balance"/>
                </div>
            </f-form-fieldset>

            <div v-if="filteredRequests.length > 0">
                <f-tabs @tabChange="currentTab = $event">
                    <f-tab
                        id="rivieraRequests"
                        title="Riviera"
                        subtitle="Requests requested in<br>Riviera"
                    />
                    <f-tab
                        id="bcsRequests"
                        title="BCS"
                        subtitle="Requests registered in<br>our administration software"
                    />
                </f-tabs>

                <div v-if="currentTab === 'rivieraRequests'">
                    <f-filter>
                        <f-filter-row>
                            <f-filter-item>
                                <f-dropdown
                                    v-model="currentYear"
                                    placeholder="Year"
                                    class-name="--align-center"
                                    :options="years.map((year) => ({key: year, value: year}))"
                                />
                            </f-filter-item>
                        </f-filter-row>
                    </f-filter>

                    <leave-requests-admin-table
                        :data="{data: filteredRequests}"
                        hide-requestee
                        :pagination="false"
                    />
                </div>

                <div v-else-if="currentTab === 'bcsRequests'">
                    <leave-requests-admin-bcs-requests :user-id="employeeID"/>
                </div>
            </div>
        </div>

        <f-table-placeholder v-else-if="!fetchedData && loading"/>
    </div>
</template>

<script>
import FUserDropdown from '../../../../f-components/form-controls/dropdowns/FUserDropdown';
import LoadingMixin from '../../../../mixins/LoadingMixin';
import {SiteLoaderSizes} from '../../../../f-components/loaders/site-loader/config';
import APIEndpoints from '../../../../config/APIEndpoints';
import LeaveBalance from '../../../widgets/leave/components/LeaveBalance';
import FInlineMessage from '../../../../f-components/notice/inline-message/FInlineMessage';
import LeaveOverview from '../../../widgets/leave/components/LeaveOverview';
import LeaveRequestsAdminTable from './LeaveRequestsAdminTable';
import FTablePlaceholder from '../../../../f-components/loaders/placeholder/FTablePlaceholder';
import FFormFieldset from '../../../../f-components/form-controls/forms/FFormFieldSet';
import moment from 'moment';
import FFilterRow from '../../../../f-components/data/filter/FFilterRow';
import FFilterItem from '../../../../f-components/data/filter/FFilterItem';
import FFilter from '../../../../f-components/data/filter/FFilter';
import FDropdown from '../../../../f-components/form-controls/dropdowns/FDropdown';
import FTabs from '../../../../f-components/navigation/tabs/FTabs';
import FTab from '../../../../f-components/navigation/tabs/FTab';
import LeaveRequestsAdminBcsRequests from './LeaveRequestsAdminBcsRequests';

export default {
    name: 'employee-leave-balance-tab',

    mixins: [
        LoadingMixin,
    ],

    components: {
        LeaveRequestsAdminBcsRequests,
        FTab,
        FTabs,
        FDropdown,
        FFilter,
        FFilterItem,
        FFilterRow,
        FFormFieldset,
        FTablePlaceholder,
        LeaveRequestsAdminTable,
        LeaveOverview,
        FInlineMessage,
        LeaveBalance,
        FUserDropdown,
    },

    computed: {
        users() {
            return this.$store.state.$usersCollection
                .filter(user => !user.hide_from_list && user.office === 'amsterdam')
                .map((user) => {
                    return {
                        key: user.id,
                        value: user.name,
                        profile_image: user.profile_image,
                        office: user.office.ucfirst(),
                    }
                });
        },

        filteredRequests() {
            return this.fetchedData.data.balance.requests.filter((request) => {
                for (let y in request.requested_dates) {
                    if (moment(y).year() === this.currentYear) {
                        return true;
                    }
                }
                return false;
            });
        },

        years() {
            const years = [];
            if (!this.fetchedData || !this.fetchedData.data.balance) {
                return years;
            }

            for (let i in this.fetchedData.data.balance.requests) {
                const request = this.fetchedData.data.balance.requests[i];

                for (let y in request.requested_dates) {
                    const date = moment(y),
                        year = date.year();

                    if (!years.includes(year)) {
                        years.push(year);
                    }
                }
            }

            return years.sort((a, b) => (a > b ? -1 : 1));
        },
    },

    watch: {
        employeeID(id) {
            this.fetch(id);
        },
    },

    data() {
        return {
            employeeID: null,
            fetchedData: null,
            currentYear: null,
            currentTab: null,
        }
    },

    methods: {
        fetch(userID) {
            this.fetchedData = null;
            this.currentYear = null;
            this.startLoading(SiteLoaderSizes.SMALL);

            window.axios
                .get(APIEndpoints.parse(APIEndpoints.ADMIN_LEAVE_REQUESTS_BALANCE, {id: userID}))
                .then(response => {
                    this.endLoading(true);
                    this.fetchedData = response.data;

                    if (this.years[0]) {
                        this.currentYear = this.years[0];
                    }
                })
                .catch(() => this.endLoading());
        },
    },
}
</script>

<style lang="scss">
.employee-leave-balance-tab {
    text-align: center;
    margin: auto;

    &__content {
        text-align: left;
        padding-top: 75px;

        &__overview {
            margin: auto;
            margin-top: 50px;
            padding: 20px 0;
            background: $softGray;
            border-radius: 4px;
            max-width: 540px;
        }

        .f-form-fieldset {
            padding: 75px 25px 75px 25px;
            margin: 0 auto 50px;
            max-width: 700px;
        }

        .f-tabs__container {
            margin: 0 0 50px;
        }

        .leave-requests-admin-table, .f-inline-message {
            max-width: 700px;
            margin: auto;
        }
    }
}
</style>
