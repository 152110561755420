<template>
    <div :class="['google-slides-embed']">
        <f-icon icon="presentation"/>
        <iframe
            width="100%"
            :src="`https://docs.google.com/presentation/d/${id}/embed`"
            height="600"
            class="google-slides-embed__iframe"
        />
    </div>
</template>

<script>
import FIcon from '../../../f-components/layout/icons/FIcon';

export default {
    name: 'google-slides-embed',

    components: {
        FIcon,
    },

    props: {
        id: {
            type: String,
            required: true,
        },
    },
}
</script>

<style lang="scss">
.google-slides-embed {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 60%;
    border-radius: 5px;
    background-color: $softGray;

    .f-icon {
        font-size: 800%;
        color: $tertiaryGray;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__iframe {
        position: absolute;
        top: -4px;
        left: -4px;
        bottom: 0;
        right: -4px;
        width: calc(100% + 8px);
        height: calc(100% + 4px);
    }
}
</style>
