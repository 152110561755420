<template>
    <div
        :class="[
            'f-button-group',
            `--${variant}`,
            ...classNames,
        ]"
    >
        <slot/>
    </div>
</template>

<script>
import {ButtonVariants} from './config';
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-button-group',

    mixins: [
        ClassNameMixin,
    ],

    props: {
        variant: {
            type: String,
            default: ButtonVariants.THEMED,
        },
    },
}
</script>

<style lang="scss">
.f-button-group {
    display: inline-block;
    font-size: 0;
    white-space: nowrap;
    vertical-align: middle;
    position: relative;

    .f-button, .f-icon-button {
        &:not(:last-child):after {
            content: '';
            position: absolute;
            right: 0;
            top: 25%;
            height: 50%;
            border-right: 1px solid transparent;
            z-index: 2;
        }

        &:not(:last-child) {
            &, & .f-button__value {
                border-radius: 50px 0 0 50px;
            }
        }

        &:not(:first-child) {
            &, & .f-button__value {
                border-radius: 0 50px 50px 0;
            }
        }

        &:not(:last-child):not(:first-child) {
            &, & .f-button__value {
                border-radius: 0 0 0 0;
            }
        }

        &.--outline {
            &:not(:last-child) {
                border-right: 0;
            }

            &:not(:first-child) {
                border-left: 0;
            }
        }
    }

    // Variants

    &.--themed {
        .f-button:after, .f-icon-button:after {
            @include theme(light) {
                border-right-color: rgba($dark, .25);
            }

            @include theme(dark) {
                border-right-color: rgba($light, .25);
            }
        }

        .f-button.--outline:after, .f-icon-button.--outline:after {
            @include theme(light) {
                border-right-color: $tertiaryGray;
            }

            @include theme(dark) {
                border-right-color: rgba($light, .25);
            }
        }
    }

    &.--primary {
        .f-button:after, .f-icon-button:after {
            border-right-color: rgba($primaryFront, .25);
        }

        .f-button.--outline:after, .f-icon-button.--outline:after {
            border-right-color: rgba($primary, .25);
        }
    }

    &.--secondary {
        .f-button:after, .f-icon-button:after {
            border-right-color: rgba($secondaryFront, .25);
        }

        .f-button.--outline:after, .f-icon-button.--outline:after {
            border-right-color: $secondary;
        }
    }

    &.--dark {
        .f-button:after, .f-icon-button:after {
            border-right-color: rgba($darkFront, .25);
        }

        .f-button.--outline:after, .f-icon-button.--outline:after {
            border-right-color: $tertiaryGray;
        }
    }

    &.--light {
        .f-button:after, .f-icon-button:after {
            border-right-color: rgba($lightFront, .25);
        }

        .f-button.--outline:after, .f-icon-button.--outline:after {
            border-right-color: $light;
        }
    }

    &.--warning {
        .f-button:after, .f-icon-button:after {
            border-right-color: rgba($warningFront, .25);
        }

        .f-button.--outline:after, .f-icon-button.--outline:after {
            border-right-color: $warning;
        }
    }

    &.--danger {
        .f-button:after, .f-icon-button:after {
            border-right-color: rgba($dangerFront, .25);
        }

        .f-button.--outline:after, .f-icon-button.--outline:after {
            border-right-color: $danger;
        }
    }

    &.--success {
        .f-button:after, .f-icon-button:after {
            border-right-color: rgba($successFront, .25);
        }

        .f-button.--outline:after, .f-icon-button.--outline:after {
            border-right-color: $success;
        }
    }

    //

    .f-button.--icon-only:last-child {
        &.--medium {
            padding-right: 12px;
        }

        &.--large {
            padding-right: 15px;
        }

        &.--xlarge {
            padding-right: 17px;
        }

        &.--mobile-small {
            @include media(mobile-down) {
                padding-right: 10px;
            }
        }
    }
}
</style>
