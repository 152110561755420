<template>
    <div :class="['f-filter-row', className]">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'f-filter-row',

    props: {
        className: String,
    },
}
</script>
