var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"popup","appear":""}},[_c('div',{class:[
            'f-badge',
            _vm.className,
            (_vm.inline ? '--inline' : null),
            `--${_vm.variant}`,
            `--${_vm.size}`,
        ]},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }