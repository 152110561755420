/**
 * Permissions correspond with the back-end.
 */
export const Permissions = {
    ADMIN_CLIENTS_VIEW: 'admin.clients.view',
    ADMIN_CLIENTS_EDIT: 'admin.clients.edit',

    ADMIN_DISCIPLINES_VIEW: 'admin.disciplines.view',
    ADMIN_DISCIPLINES_EDIT: 'admin.disciplines.edit',

    ADMIN_MISC_EXTERNAL_CONNECTIONS: 'admin.misc.external_connections',
    ADMIN_MISC_FLUSH_CACHE: 'admin.misc.flush_cache',
    ADMIN_MISC_VIEW_LOGS: 'admin.misc.view_logs',

    ADMIN_USERS_VIEW: 'admin.users.view',
    ADMIN_USERS_EDIT: 'admin.users.edit',
    ADMIN_USERS_EXPORT_BCS: 'admin.users.export_bcs',
    ADMIN_USERS_SYNC: 'admin.users.sync',

    ADMIN_PRODUCTS_VIEW: 'admin.products.view',

    ADMIN_ANNOUNCEMENTS: 'admin.announcements',
    ADMIN_GALLERIES: 'admin.galleries',
    ADMIN_PAGES: 'admin.pages',
    ADMIN_FIKA: 'admin.fika',

    FAQ_EDIT: 'faq.edit',

    FREELANCERS_VIEW: 'freelancers.view',
    FREELANCERS_CREATE: 'freelancers.create',
    FREELANCERS_DELETE: 'freelancers.delete',

    LEAVE_REQUESTS_VIEW: 'leave.view',
    LEAVE_REQUESTS_EDIT: 'leave.edit',
    LEAVE_REQUESTS_CREATE: 'leave.create',
    LEAVE_REQUESTS_APPROVE: 'leave.approve',

    PROJECTS_VIEW: 'projects.view',
    PROJECTS_EDIT: 'projects.edit',
    PROJECTS_ADMIN: 'projects.admin',
    PROJECTS_ARCHIVE: 'projects.archive',
    PROJECTS_MERGE: 'projects.merge',

    MEETING_ROOM_BOOK: 'meeting-room.book',

    WORKLOAD_VIEW: 'workload.view',

    EDITOR_IMAGE_UPLOAD: 'editor.image_upload',
}
