<template>
    <div :class="['setting-switch', (active ? '--active' : null)]">
        <div class="setting-switch__label --no-select">
            {{ label }}
            <f-help-label
                v-if="help"
                :text="help"
            />
        </div>
        <f-switch v-model="active"/>
    </div>
</template>

<script>
import FSwitch from '../../../../f-components/form-controls/switches/FSwitch';
import FHelpLabel from '../../../../f-components/form-controls/forms/FHelpLabel';

export default {
    name: 'setting-switch',

    components: {
        FHelpLabel,
        FSwitch,
    },

    props: {
        label: String,
        help: String,
        storeCommitName: {
            type: String,
            default: 'userSetting',
        },
        settingKey: String,
    },

    watch: {
        active(value) {
            // Lock it for a small time
            this.$emit('lock');
            this.$emit('change', value);

            if (this.settingKey) {
                // Save setting
                const setting = {
                    key: this.settingKey,
                    value: value,
                };

                if (this.storeCommitName && this.settingKey) {
                    this.$store.commit(this.storeCommitName, setting);
                }
            }

            // Unlock it
            window.setTimeout(() => this.$emit('unlock'), 500);
        },
    },

    data: function () {
        return {
            active: (this.settingKey ? this.$store.getters.getUserSetting(this.settingKey) : false),
        }
    },
}
</script>

<style lang="scss">
.setting-switch {
    display: flex;
    justify-content: space-between;
    height: 36px;

    .f-switch {
        margin-top: 12px;
    }

    &__label {
        padding-top: 12px;
        opacity: .5;
        transition: opacity .15s ease-in-out;
    }

    &.--active {
        .setting-switch__label {
            opacity: .9;
        }
    }
}
</style>
