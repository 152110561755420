<template>
    <li
        :class="[
            'f-flex-table-row',
            className,
            (open ? '--open' : (collapsible ? '--closed' : null)),
            (collapsible ? '--collapsible' : null),
            (overflowHidden ? '--overflow-hidden': null),
            (noLeftPadding ? '--no-left-padding': null),
        ]"
        @mouseenter="hasHover = true"
        @mouseleave="hasHover = false"
    >
        <button
            v-if="collapsible"
            class="f-flex-table-row__collapsible"
            @click="toggle"
            type="button"
        >
            <slot/>
            <f-icon-button
                v-if="collapsible"
                class-name="f-flex-table-row__collapsible__chevron"
                :size="iconSize"
                :icon="(collapsibleType === 'chevron' ? (open ? 'chevron-up' : 'chevron-down') : (open ? 'minus' : 'plus'))"
                :selected="open || hasHover"
                outlined
            />
        </button>

        <div class="f-flex-table-row__title" v-else>
            <slot/>
        </div>

        <transition name="height" appear>
            <div
                v-if="open && collapsible"
                :class="[
                    'f-flex-table-row__content',
                    (noContentIndention ? '--no-indention' : null),
                ]"
            >
                <slot name="content"/>
            </div>
        </transition>
    </li>
</template>

<script>
import EventBus from '../../../services/EventBus';
import FIconButton from '../../form-controls/buttons/FIconButton';
import {ButtonSizes} from '../../form-controls/buttons/config';
import {FlexTableRowCollapsibleTypes} from './config';
import {GlobalEvents} from '../../../config/Events';

export default {
    name: 'f-flex-table-row',

    components: {
        FIconButton,
    },

    props: {
        className: String,
        collapsible: Boolean,
        collapsibleType: {
            type: String,
            default: FlexTableRowCollapsibleTypes.CHEVRON,
        },
        overflowHidden: Boolean,
        initiallyOpen: Boolean,
        closeWhenOtherOpens: Boolean,
        noContentIndention: Boolean,
        noLeftPadding: Boolean,
        iconSize: {
            type: String,
            default: ButtonSizes.XSMALL,
        },
    },

    data() {
        return {
            open: this.initiallyOpen,
            hasHover: false,
        }
    },

    mounted() {
        EventBus.$on('collapsible.open', this.autoClose);
    },

    beforeDestroy() {
        EventBus.$off('collapsible.open', this.autoClose);
    },

    methods: {
        toggle() {
            if (this.collapsible) {
                this.open = !this.open;

                if (this.open) {
                    EventBus.$emit('collapsible.open', this._uid);
                }

                EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
            }
        },

        autoClose(uid) {
            if (this.closeWhenOtherOpens && this.open && this._uid !== uid) {
                this.toggle();
            }
        },
    },
}
</script>

<style lang="scss">
.f-flex-table-row {
    border-top: 1px solid;
    display: flex;

    @include theme(light) {
        border-color: $softGray;
    }

    @include theme(dark) {
        border-color: $quaternaryGray;
    }

    &.--collapsible {
        display: block;
    }

    .f-flex-table-header-row + &, &:first-child {
        border: 0;
    }

    &__collapsible, &__title {
        display: flex;
        align-items: center;
        flex: 1 1;
        width: 100%;
        padding: 15px 35px 15px 10px;
        border-radius: 3px;
    }

    &.--no-left-padding {
        > .f-flex-table-row__title, > .f-flex-table-row__collapsible {
            padding-left: 0;
        }
    }

    &__collapsible {
        background: none;
        position: relative;
        width: 100%;
        text-align: left;
        transition: background-color .1s;

        &:hover {
            @include theme(light) {
                background-color: $softGray;
            }

            @include theme(dark) {
                background-color: $quaternaryGray;
            }
        }

        &__chevron {
            font-size: 10px;
            position: absolute;
            top: 50%;
            right: 8px;
            transition: transform .1s;
            font-weight: bold;
            transform: translateY(-50%);
        }
    }

    &__content {
        padding: 0 0 15px 46px;
        flex: 1 1 100%;
        overflow: hidden;

        &.--no-indention {
            padding-left: 10px;
        }
    }
}
</style>
