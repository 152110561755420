var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f-portal',{style:({'zIndex': _vm.parentInPortal ? 103 : null}),attrs:{"reference":"input-error"}},[_c('transition',{attrs:{"name":"popup","appear":""}},[_c('div',{ref:"positionElement",class:[
                'f-input-error',
                '--no-select',
                `--${_vm.currentAlign}`,
                `--hor-${_vm.currentAlignHorizontal}`,
            ],style:({
                top: `${_vm.pos.top}px`,
                left: `${_vm.pos.left}px`
            })},[_c('div',{staticClass:"f-input-error__content"},[_vm._v(" "+_vm._s(_vm.error)+" ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }