<template>
    <f-icon
        icon="menu"
        class-name="faq-drag"
    />
</template>

<script>
import FIcon from '../../../f-components/layout/icons/FIcon';

export default {
    name: 'faq-drag',

    components: {
        FIcon,
    },
}
</script>

<style lang="scss">
.faq-drag {
    margin-right: 5px;
    cursor: grab;
    opacity: .5;
    font-weight: bold;

    &:hover {
        opacity: 1;
    }
}
</style>
