var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",class:[
        'f-button',
        ..._vm.classNames,
        `--${_vm.variant}`,
        `--${_vm.size}`,
        (_vm.showLoaderOnDisabled ? '--loader' : null),
        (_vm.fullWidth ? '--full-width' : null),
        (_vm.outlined ? '--outline' : null),
        (_vm.icon && !_vm.value ? '--icon-only' : null),
        (_vm.disabled && _vm.showLoaderOnDisabled ? '--loading' : null),
        (_vm.selected ? '--selected' : null),
        (_vm.disabled ? '--disabled' : null),
        '--no-select',
    ],attrs:{"type":(_vm.tag !== 'button' ? null : _vm.type),"disabled":_vm.disabled,"tabindex":_vm.tabindex,"href":_vm.href,"target":_vm.target,"rel":(_vm.target === '_blank' ? 'noopener noreferrer' : undefined)},on:{"click":_vm.onClick}},[_c('div',{staticClass:"f-button__value"},[_vm._t("pre",function(){return [(_vm.icon && _vm.iconPosition === 'left')?_c('div',{staticClass:"f-button__icon --pre"},[_c('f-icon',{attrs:{"icon":_vm.icon}})],1):_vm._e()]}),_vm._t("default",function(){return [_c('span',{staticClass:"f-button__value__text",domProps:{"innerHTML":_vm._s(_vm.value)}})]}),_vm._t("post",function(){return [(_vm.icon && _vm.iconPosition === 'right')?_c('div',{staticClass:"f-button__icon --post"},[_c('f-icon',{attrs:{"icon":_vm.icon}})],1):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }