<template>
    <f-dropdown
        ref="validatable"
        v-bind="$props"
        :class-name="[
            'f-user-dropdown',
            ...classNames,
        ]"
        @change="(value, user) => $emit('change', value, user)"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @popup-open="$emit('popup-open')"
        @popup-close="$emit('popup-close')"
        @search="searchQuery = $event"
        v-model="dropdownValue"
    >
        <template v-slot:selectedOption="selectedOptionProps">
            <div
                v-if="mode === 'single' && selectedOptionProps.selectedOption"
                class="f-dropdown__selected-option f-user-dropdown__selected-option --single"
            >
                <entity-avatar
                    :name="selectedOptionProps.selectedOption.value"
                    :src="selectedOptionProps.selectedOption.profile_image"
                    size="xxsmall"
                />
                {{ selectedOptionProps.selectedOption.value }}
            </div>

            <div
                v-if="mode === 'multi' && selectedOptionProps.selectedOption.length > 0"
                class="f-dropdown__selected-option f-user-dropdown__selected-option --multi"
            >
                <entity-avatar
                    v-for="selectedOption in selectedOptionProps.selectedOption"
                    :name="selectedOption.value"
                    :src="selectedOption.profile_image"
                    :key="selectedOption.key"
                    v-tooltip="{ text: selectedOption.value }"
                    size="xxsmall"
                />
            </div>
        </template>

        <template v-slot:options="optionsProps">
            <li
                v-for="(option, index) in optionsProps.options"
                :class="[
                    'f-user-dropdown__option',
                    (optionsProps.selectedIndex === index ? '--selected' : null),
                    (mode === 'single' && optionsProps.selectedOption && value === option.key ? '--chosen' : null),
                    (mode === 'multi' && (optionsProps.value ? optionsProps.value : []).includes(option.key) ? '--chosen' : null),
                ]"
                :key="option.key"
                @click.prevent="optionsProps.onSelect(option, index)"
            >
                <div class="f-user-dropdown__option__image">
                    <entity-avatar
                        :name="option.value"
                        :src="option.profile_image"
                        size="xxsmall"
                        color="dark"
                        :tooltip-options="tooltipOptions"
                    />
                </div>
                <div class="f-user-dropdown__option__title">
                    {{ option.value }}
                    <div class="f-user-dropdown__option__title__subtitle">
                        {{ option.office ? option.office : 'Unknown' }} office
                    </div>
                </div>
            </li>
        </template>
    </f-dropdown>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import FDropdown from './FDropdown';
import Store from '../../../services/Store';
import EntityAvatar from "../../../components/misc/entities/EntityAvatar";
import AbstractInputFieldPropsMixin from '../base/AbstractInputFieldPropsMixin';
import DropdownPropsMixin from './DropdownPropsMixin';
import PassDownInputValidationMixin from '../base/PassDownInputValidationMixin';

export default {
    name: 'f-user-dropdown',

    components: {
        EntityAvatar,
        FDropdown,
    },

    mixins: [
        AbstractInputFieldPropsMixin,
        DropdownPropsMixin,
        PassDownInputValidationMixin,
    ],

    props: {
        preIcon: {
            type: String,
            default: 'user',
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        options: {
            type: Array,
            default: () => {
                return Store.state.$usersCollection
                    .filter(user => !user.hide_from_list)
                    .map((user) => {
                        return {
                            key: user.id,
                            value: user.name,
                            email: user.email,
                            profile_image: user.profile_image,
                            office: user.office ? user.office.ucfirst() : "",
                        }
                    });
            },
        },
        tooltipOptions: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },

    data() {
        return {
            dropdownValue: this.value,
            searchQuery: undefined,
        }
    },

    watch: {
        dropdownValue(value) {
            this.$emit('input', value);
        },
        value(value) {
            if (value !== this.dropdownValue) {
                this.dropdownValue = value;
            }
        },
        searchQuery() {
            this.$emit('search', this.searchQuery);
        },
    },

    methods: {
        getSelected() {
            return this.$refs.validatable.getSelectedOption();
        },
    },
}
</script>

<style lang="scss">
.f-user-dropdown {
    &__selected-option {
        &.--single {
            white-space: nowrap;
        }

        .entity-avatar {
            display: inline-block;
            margin: 1px 5px 1px 0;

            .f-icon-button {
                background: $primary !important;
                display: none;
                position: absolute;
                top: 0;
                right: -12px;
            }

            &:hover .f-icon-button {
                display: block;
            }
        }
    }

    &__option {
        display: flex;
        align-items: center;

        &__title {
            padding-left: 7px;

            &__subtitle {
                opacity: .5;
                font-size: 80%;
            }
        }
    }
}
</style>
