<template>
    <f-scroll-pane mode="horizontal">
        <f-flex-table
            class-name="project-totals"
            row-padding="small"
        >
            <!-- Budget -->
            <f-flex-table-row
                :collapsible="!!(report.purchase_lines.length > 0 || report.totals.hours_sold_cost)"
                :initially-open="report.purchase_lines.length > 0"
                no-left-padding
            >
                <f-flex-table-cell>Budget</f-flex-table-cell>
                <f-flex-table-cell align="right" flex="0 0 120px">
                    {{ formatPrice(report.totals.budget, false) }}
                </f-flex-table-cell>

                <template
                    v-if="!!(report.purchase_lines.length > 0 || report.totals.hours_sold_cost)"
                    #content
                >
                    <f-flex-table
                        tree-render
                        :level="2"
                        row-padding="small"
                    >
                        <f-flex-table-row
                            v-if="report.totals.hours_sold_cost"
                            icon-size="xxsmall"
                            no-left-padding
                        >
                            <f-flex-table-cell>Hours sold</f-flex-table-cell>
                            <f-flex-table-cell align="right" flex="0 0 120px">
                                {{ formatPrice(report.totals.hours_sold_cost, false) }}
                            </f-flex-table-cell>
                        </f-flex-table-row>

                        <!-- External purchases -->
                        <f-flex-table-row
                            v-if="report.purchase_lines.length > 0"
                            collapsible
                            icon-size="xxsmall"
                            no-left-padding
                        >
                            <f-flex-table-cell>Purchases, discounts and deposits
                                <f-badge variant="primary" inline>
                                    {{ report.purchase_lines.length }}
                                </f-badge>
                            </f-flex-table-cell>
                            <f-flex-table-cell align="right" flex="0 0 120px">
                                {{ formatPrice(report.totals.purchase_lines_cost, false) }}
                            </f-flex-table-cell>

                            <template #content>
                                <f-flex-table
                                    tree-render
                                    :level="3"
                                    row-padding="small"
                                >
                                    <f-flex-table-row
                                        v-for="(purchaseLine, i) in report.purchase_lines"
                                        :key="i"
                                        icon-size="xxsmall"
                                        no-left-padding
                                    >
                                        <f-flex-table-cell>
                                            {{ purchaseLine.name.replace(/^Other /, '') }}
                                            <span
                                                v-if="purchaseLine.hours_sold > 1"
                                                style="padding-left: 5px; opacity: .5"
                                            >
                                                {{
                                                    purchaseLine.hours_sold + ' &times; ' + formatPrice(purchaseLine.unit_price)
                                                }}
                                            </span>
                                        </f-flex-table-cell>
                                        <f-flex-table-cell align="right" flex="0 0 120px">
                                            {{ formatPrice(purchaseLine.cost, false) }}
                                        </f-flex-table-cell>
                                    </f-flex-table-row>
                                </f-flex-table>
                            </template>
                        </f-flex-table-row>
                    </f-flex-table>
                </template>
            </f-flex-table-row>

            <!-- Hours spend -->
            <f-flex-table-row
                v-if="report.totals.hours_allocated_cost"
                no-left-padding
            >
                <f-flex-table-cell>
                    Allocated employee costs
                    <div style="opacity: .5; font-size: 90%; padding-top: 2px;">
                        Without freelancers
                    </div>
                </f-flex-table-cell>
                <f-flex-table-cell align="right">
                    {{
                        formatPrice(-(report.totals.hours_allocated_cost - report.totals.hours_allocated_cost_spec.freelancer), false)
                    }}
                </f-flex-table-cell>
            </f-flex-table-row>

            <!-- Hours spend -->
            <!--            <f-flex-table-row v-if="report.totals.hours_allocated_cost_spec.freelancer" no-left-padding>-->
            <!--                <f-flex-table-cell>-->
            <!--                    Freelancer hours spend-->
            <!--                    <f-help-label-->
            <!--                        text="-->
            <!--                            This amount is a guessed total of what will be spend on freelancer hours, as specified above.<br>-->
            <!--                            This freelancer costs total is included in 'hours spend', but shouldn't, because it will be retracted by POs once the freelancers are getting paid.-->
            <!--                            Hence it is added back by this line-->
            <!--                        "-->
            <!--                    />-->
            <!--                </f-flex-table-cell>-->
            <!--                <f-flex-table-cell align="right">-->
            <!--                    {{ formatPrice(report.totals.hours_allocated_cost_spec.freelancer, false) }}-->
            <!--                </f-flex-table-cell>-->
            <!--            </f-flex-table-row>-->

            <!-- Purchase orders -->
            <f-flex-table-row
                v-if="report.purchase_orders.length > 0"
                collapsible
                no-left-padding
            >
                <f-flex-table-cell>
                    Purchase orders
                    <f-badge variant="primary" inline>
                        {{ report.purchase_orders.length }}
                    </f-badge>
                    <div style="opacity: .5; font-size: 90%; padding-top: 2px;">
                        Freelancers and other external costs
                        <f-help-label
                            text="POs could added at a later date, so please be aware that this amount can rise"
                        />
                    </div>
                </f-flex-table-cell>
                <f-flex-table-cell align="right">
                    {{ formatPrice(-report.totals.purchase_orders_cost, false) }}
                </f-flex-table-cell>

                <template #content>
                    <f-flex-table
                        tree-render
                        :level="2"
                    >
                        <f-flex-table-row
                            v-for="(purchaseOrder, i) in report.purchase_orders"
                            :key="i"
                            :collapsible="!!purchaseOrder.description"
                            no-content-indention
                            icon-size="xxsmall"
                        >
                            <f-flex-table-title-cell :title="[purchaseOrder.name]">
                                <f-chip size="small" variant="dark">
                                    {{ purchaseOrder.number }}
                                </f-chip>
                                {{ purchaseOrder.supplier }}
                            </f-flex-table-title-cell>
                            <f-flex-table-cell align="right" flex="0 0 120px">
                                {{ formatPrice(-purchaseOrder.cost, false) }}
                            </f-flex-table-cell>

                            <template #content>
                                <div class="project-totals__purchase-order-content">
                                    <div class="project-totals__purchase-order-content__description">
                                        {{ purchaseOrder.description }}
                                    </div>
                                    <div class="project-totals__purchase-order-content__link">
                                        <f-icon-button
                                            v-if="purchaseOrder.url"
                                            :href="purchaseOrder.url"
                                            tag="a"
                                            icon="launch"
                                            target="_blank"
                                            v-tooltip="'View purchase order as PDF'"
                                            size="xsmall"
                                            variant="primary"
                                        />
                                    </div>
                                </div>
                            </template>
                        </f-flex-table-row>
                    </f-flex-table>
                </template>
            </f-flex-table-row>

            <!-- Footer: total -->
            <f-flex-table-footer-row collapsible>
                <f-flex-table-cell align="right">
                    <profitable-bar
                        :total="report.totals.budget"
                        :current="report.totals.budget - report.totals.result"
                        width="100px"
                        min-width="70px"
                        percentage-location="right"
                    >
                        of budget used
                    </profitable-bar>
                </f-flex-table-cell>
                <f-flex-table-cell
                    flex="0 0 160px"
                    align="right"
                    :class-name="(report.totals.result < 0 ? '--warning' : null)"
                >
                    {{ formatPrice(report.totals.result, false) }}
                </f-flex-table-cell>
            </f-flex-table-footer-row>
        </f-flex-table>
    </f-scroll-pane>
</template>

<script>
import FFlexTable from "../../../f-components/data/flex-table/FFlexTable";
import FFlexTableRow from "../../../f-components/data/flex-table/FFlexTableRow";
import FFlexTableCell from "../../../f-components/data/flex-table/FFlexTableCell";
import FFlexTableFooterRow from "../../../f-components/data/flex-table/FFlexTableFooterRow";
import NumbersAndBudgetMixin from "../../../mixins/NumbersAndBudgetMixin";
import FIconButton from "../../../f-components/form-controls/buttons/FIconButton";
import FFlexTableTitleCell from "../../../f-components/data/flex-table/FFlexTableTitleCell";
import ProfitableBar from "./ProfitableBar";
import FBadge from "../../../f-components/data/badge/FBadge";
import FChip from "../../../f-components/data/chip/FChip";
import FScrollPane from '../../../f-components/other/scroll-pane/FScrollPane';
import FHelpLabel from '../../../f-components/form-controls/forms/FHelpLabel';

export default {
    name: 'project-totals',

    components: {
        FHelpLabel,
        FScrollPane,
        FChip,
        FBadge,
        ProfitableBar,
        FFlexTableTitleCell,
        FIconButton,
        FFlexTableFooterRow,
        FFlexTableCell,
        FFlexTableRow,
        FFlexTable,
    },

    mixins: [
        NumbersAndBudgetMixin,
    ],

    props: {
        report: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style lang="scss">
.project-totals {
    min-width: 400px;

    .--warning {
        color: #e1533b;
    }

    &__purchase-order-content {
        padding-top: 5px;
        padding-right: 35px;
        display: flex;

        &__description {
            opacity: .5;
            flex: 1;
        }

        &__link {
            flex: 0 0 60px;
            text-align: right;

            .f-icon-button {
                margin-top: -3px;
            }
        }
    }

    .f-flex-table-title-cell {
        .f-chip {
            margin-right: 4px;
        }
    }
}
</style>
