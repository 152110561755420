<template>
    <page
        v-if="hasPermission(Permissions.ADMIN_USERS_VIEW)"
        :loading="loading"
        :title="['Admin', 'Users', 'View']"
        :skewed-footer-foreground="(!fetchedData && loading ? '#fff' : null)"
        :actions="actions"
        class-name="admin-user-show-page"
        header="View user"
        pre-max-width="800px"
        stretch-first-container
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb link="/admin/users">Users</f-breadcrumb>
                <f-breadcrumb :loading="!fetchedData || !fields">
                    <span v-if="fields">{{ fields.name }}</span>
                </f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-container max-width="800px">
            <div v-if="fetchedData">
                <entity-header
                    :image="fields.profile_image"
                    :name="fields.name"
                    :title="fields.name"
                >
                    <department-discipline-path
                        :discipline="fields.discipline"
                        :office="fields.office"
                    />

                    <div class="admin-user-show-page__type">
                        <f-chip :variant="(fields.type === 'freelancer' ? 'dark' : 'secondary')">
                            {{ fields.type }}
                        </f-chip>
                    </div>

                    <template #post>
                        <div class="admin-user-show-page__status">
                            <f-chip
                                v-if="fields.deleted_at"
                                variant="danger"
                            >
                                Deleted
                            </f-chip>
                            <f-chip
                                v-if="!fields.activated_at && !fields.deleted_at"
                                variant="warning"
                            >
                                Inactive
                            </f-chip>
                            <f-chip
                                v-if="fields.activated_at && !fields.deleted_at"
                                variant="info"
                            >
                                Active
                            </f-chip>
                            <f-chip
                                v-if="fields.hide_from_list && !fields.deleted_at && fields.activated_at"
                                variant="status"
                            >
                                Hidden
                            </f-chip>
                        </div>
                    </template>
                </entity-header>

                <f-form>
                    <f-inline-message
                        v-if="!fields.activated_at && !fields.deleted_at"
                        id="userActivate"
                        :closable="false"
                    >
                        This user is not yet active. Once activated, the user will receive a registration email, and
                        will be visible in the front-end. Freelancers should not be activated, unless they need access
                        to Riviera.

                        <div style="margin-top: 15px;">
                            <f-button
                                value="Active user"
                                @click="activateUser($route.params.id,() => refresh())"
                                variant="light"
                                icon="pulse"
                            />
                        </div>
                    </f-inline-message>

                    <f-form-row
                        label="Job title"
                        help="Shown the people page, and in the email signature"
                    >
                        <f-text-input
                            name="job title"
                            readonly
                            v-model="fields.title"
                            max-width="340px"
                            :validation="['required']"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Email">
                        <f-text-input
                            v-model="fields.email"
                            name="email"
                            max-width="340px"
                            pre-icon="envelope"
                            readonly
                        />
                    </f-form-row>

                    <f-form-row
                        label="Started at"
                        help="Started working on"
                    >
                        <f-date-picker
                            v-model="fields.started_at"
                            max-width="200px"
                            pre-icon="calendar-user"
                            class-name="--align-left"
                            readonly
                        />
                    </f-form-row>

                    <f-form-row
                        v-if="fields.lead_user_id"
                        label="Lead"
                        help="Employee's lead lead will be cc'ed when requesting leave"
                    >
                        <f-user-dropdown
                            v-model="fields.lead_user_id"
                            max-width="300px"
                            pre-icon="user"
                            readonly
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Gender" v-if="fields.gender">
                        <f-text-input
                            v-model="fields.gender"
                            readonly
                            max-width="200px"
                        />
                    </f-form-row>

                    <f-form-row label="Pronouns" v-if="fields.pronouns">
                        <f-text-input
                            v-model="fields.pronouns"
                            readonly
                            max-width="200px"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row
                        v-if="fields.phone_number"
                        label="Phone number"
                    >
                        <f-text-input
                            v-model="fields.phone_number"
                            max-width="250px"
                            pre-icon="telephone2"
                            readonly
                        />
                    </f-form-row>

                    <f-form-row
                        v-if="fields.city"
                        label="City"
                    >
                        <f-text-input
                            v-model="fields.city"
                            max-width="250px"
                            pre-icon="home"
                            readonly
                        />
                    </f-form-row>

                    <f-form-row
                        v-if="fields.date_of_birth"
                        label="Date of birth"
                    >
                        <f-date-picker
                            v-model="fields.date_of_birth"
                            max-width="200px"
                            pre-icon="gift"
                            class-name="--align-left"
                            readonly
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row
                        label="Hide from list"
                        help="If activated and hidden from the list, this user will not be shown in on the people page, but will have a functional Riviera account"
                    >
                        <f-switch v-model="fields.hide_from_list" readonly/>
                    </f-form-row>

                    <f-form-row
                        label="Sync signature"
                        help="Automatically update signature when changes occur"
                    >
                        <f-switch v-model="fields.sync_signature" readonly/>
                    </f-form-row>

                    <f-form-row-spacer v-if="fields.description && fields.description !== '<p></p>'"/>
                    <f-form-row
                        v-if="fields.description && fields.description !== '<p></p>'"
                        label="Description"
                        help="Visible on the people page, describes the roles & responsibilities"
                    >
                        <!-- Change to f-editor read-only -->
                        <f-content
                            v-html="fields.description"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-fieldset alternative>
                        <f-form-row label="Created at">
                            <f-date-picker
                                v-model="fields.created_at"
                                width="200px"
                                readonly
                            />
                        </f-form-row>
                        <f-form-row label="Activated at" v-if="fields.activated_at">
                            <f-date-picker
                                v-model="fields.activated_at"
                                width="200px"
                                readonly
                            />
                        </f-form-row>
                        <f-form-row label="Deleted at" v-if="fields.deleted_at">
                            <f-date-picker
                                v-model="fields.deleted_at"
                                width="200px"
                                readonly
                            />
                        </f-form-row>
                        <f-form-row label="Last login at" v-if="fields.last_login_at">
                            <f-date-picker
                                v-model="fields.last_login_at"
                                width="200px"
                                readonly
                            />
                        </f-form-row>
                    </f-form-fieldset>
                </f-form>
            </div>
            <f-entity-header-placeholder
                v-if="!fetchedData && loading"
                disable-rows
            />
            <f-form-placeholder
                v-if="!fetchedData && loading"
            />
        </f-container>

        <skewed-section
            v-if="fetchedData"
            foreground-color="#333"
            after-background="#222"
            padding="100px 0 150px"
            margin="100px 0 -50px"
        >
            <f-theme-provider variant="dark">
                <f-container max-width="800px">
                    <f-tabs
                        margin
                        @tabChange="tabId = $event"
                    >
                        <f-tab id="permissions" title="Permissions"/>
                        <f-tab id="signature" title="Email signature"/>
                        <f-tab id="references" title="References"/>
                    </f-tabs>

                    <div v-if="tabId === 'permissions'">
                        <f-inline-message
                            v-if="fields.permissions.length < 1"
                            id="permissionsInfo"
                            variant="info"
                            :closable="false"
                        >
                            No extra permissions granted.
                        </f-inline-message>
                        <permissions-checkbox-list
                            v-else
                            read-only
                            v-model="fields.permissions"
                        />
                    </div>

                    <div v-if="tabId === 'signature'">
                        <email-signature
                            v-if="fields.email_signature"
                            :signature="fields.email_signature"
                        />
                        <f-inline-message id="signatureWarning" v-else variant="warning">
                            Could not find user signature.
                        </f-inline-message>
                    </div>

                    <references-list
                        v-if="tabId === 'references'"
                        :references="fields.references"
                    />
                </f-container>
            </f-theme-provider>
        </skewed-section>

        <entity-action-button
            :permission="Permissions.ADMIN_USERS_EDIT"
            :route="`/admin/users/edit/${$route.params.id}`"
            icon="pencil"
            tooltip="Edit"
        />
    </page>
</template>

<script>
import Page from '../../Page';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import EntityHeader from '../../../misc/entities/EntityHeader';
import AdminUserMixin from './AdminUserMixin';
import APIEndpoints from '../../../../config/APIEndpoints';
import FChip from '../../../../f-components/data/chip/FChip';
import FFormRow from '../../../../f-components/form-controls/forms/FFormRow';
import FDatePicker from '../../../../f-components/form-controls/dates/FDatePicker';
import FFormRowSpacer from '../../../../f-components/form-controls/forms/FFormRowSpacer';
import FForm from '../../../../f-components/form-controls/forms/FForm';
import FFormFieldset from '../../../../f-components/form-controls/forms/FFormFieldSet';
import FTextInput from '../../../../f-components/form-controls/text/FTextInput';
import FSwitch from '../../../../f-components/form-controls/switches/FSwitch';
import FUserDropdown from '../../../../f-components/form-controls/dropdowns/FUserDropdown';
import FContent from '../../../../f-components/layout/content/FContent';
import FInlineMessage from '../../../../f-components/notice/inline-message/FInlineMessage';
import DepartmentDisciplinePath from '../../../misc/department-discipline-path/DepartmentDisciplinePath';
import PermissionsCheckboxList from "./components/PermissionsCheckboxList";
import FButton from '../../../../f-components/form-controls/buttons/FButton';
import EntityShowMixin from '../../../../mixins/entities/EntityShowMixin';
import FContainer from '../../../../f-components/layout/container/FContainer';
import FEntityHeaderPlaceholder from '../../../../f-components/loaders/placeholder/FEntityHeaderPlaceholder';
import FThemeProvider from '../../../../f-components/layout/theme/FThemeProvider';
import SkewedSection from '../../../misc/skewed-section/SkewedSection';
import FTabs from '../../../../f-components/navigation/tabs/FTabs';
import FTab from '../../../../f-components/navigation/tabs/FTab';
import EmailSignature from '../../../misc/EmailSignature';
import ReferencesList from '../../../misc/ReferencesList';
import {Icons} from '../../../../f-components/layout/icons/config';
import {Permissions} from '../../../../config/Permissions';
import EntityActionButton from '../../../misc/entities/EntityActionButton';
import FFormPlaceholder from '../../../../f-components/loaders/placeholder/FFormPlaceholder';

export default {
    name: 'admin-user-show-page',

    components: {
        FFormPlaceholder,
        EntityActionButton,
        ReferencesList,
        EmailSignature,
        FTab,
        FTabs,
        SkewedSection,
        FThemeProvider,
        FEntityHeaderPlaceholder,
        FContainer,
        FButton,
        PermissionsCheckboxList,
        DepartmentDisciplinePath,
        FInlineMessage,
        FContent,
        FUserDropdown,
        FSwitch,
        FTextInput,
        FFormFieldset,
        FForm,
        FFormRowSpacer,
        FDatePicker,
        FFormRow,
        FChip,
        EntityHeader,
        FBreadcrumb,
        FBreadcrumbs,
        Page,
    },

    mixins: [
        AdminUserMixin,
        EntityShowMixin,
    ],

    computed: {
        actions() {
            if (!this.fetchedData || !this.hasPermission(Permissions.ADMIN_USERS_EDIT)) {
                return [];
            }

            const actions = [];

            actions.push({
                value: 'Edit user',
                icon: Icons.PENCIL,
                link: `/admin/users/edit/${this.$route.params.id}`,
            });

            if (!this.fields.activated_at && !this.fields.deleted_at) {
                actions.push({
                    value: 'Activate user',
                    icon: Icons.PULSE,
                    onClick: () => {
                        this.activateUser(this.$route.params.id, () => this.refresh());
                    },
                });
            }

            if (this.fields.activated_at && !this.fields.deleted_at) {
                actions.push({
                    value: 'Deactivate user',
                    icon: Icons.SKULL,
                    onClick: () => {
                        this.deactivateUser(this.$route.params.id, () => this.refresh());
                    },
                });
            }

            if (!this.fields.deleted_at) {
                actions.push({
                    value: 'Delete user',
                    icon: Icons.TRASH2,
                    onClick: () => {
                        this.deleteUser(this.$route.params.id, () => this.refresh());
                    },
                });
            }

            return actions;
        },
    },

    data() {
        return {
            entityName: 'user',
            tabId: null,
        }
    },

    methods: {
        getBaseUrl() {
            return APIEndpoints.ADMIN_USERS;
        },
    },
}
</script>

<style lang="scss">
.admin-user-show-page {
    &__status {
        .f-chip {
            margin-left: 2px;
        }
    }
}
</style>
