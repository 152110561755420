<template>
    <div
        :class="[
            'simple-page',
            ...classNames,
            '--loader',
            (loading ? '--loading' : null),
        ]"
    >
        <slot/>
    </div>
</template>

<script>
import {TokenState} from '../../services/Store';
import ClassNameMixin from '../../mixins/ClassNameMixin';

export default {
    name: 'simple-page',

    mixins: [
        ClassNameMixin,
    ],

    props: {
        loading: Boolean,
    },

    created() {
        this.scrollToTop();
        if (this.authenticated) {
            this.verifyIfAuthenticated();
        }
    },

    mounted() {
        this.scrollToTop();
    },

    methods: {
        setDocumentTitle(title) {
            if (title && Array.isArray(title)) {
                document.title = title.join(' › ');
            } else if (title) {
                document.title = title;
            } else {
                document.title = 'Riviera';
            }
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        /**
         * Verify if authenticated. If not, redirect to /auth.
         */
        verifyIfAuthenticated() {
            if (this.$store.getters.accessTokenState !== TokenState.VALID) {
                if (this.$router.currentRoute.fullPath !== this.authLink) {
                    window.localStorage.setItem('riviera.auth.redirect', this.$router.currentRoute.fullPath);
                } else if (!window.localStorage.getItem('riviera.auth.redirect')) {
                    window.localStorage.setItem('riviera.auth.redirect', '/')
                }
                window.localStorage.setItem('riviera.auth.page', this.authLink);
                this.$router.push(this.authLink);
            } else {
                this.afterAuthenticationVerified();
            }
        },

        /**
         * What to do after authentication.
         */
        afterAuthenticationVerified() {
            this.$emit('authenticated');
        },
    },
}
</script>

<style lang="scss">
</style>
