<template>
    <hr :class="['f-form-row-spacer', (line ? '--line' : null)]"/>
</template>

<script>
export default {
    name: 'f-form-row-spacer',

    props: {
        line: Boolean,
    },
}
</script>

<style lang="scss">
.f-form-row-spacer {
    margin: 20px 0 !important;
    background: 0 !important;
    border: 0 !important;
    background-color: transparent;

    &.--line {
        border-top: 1px solid $tertiaryGray !important;
    }
}
</style>
