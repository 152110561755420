<template>
    <f-flex
        v-if="data.weather && data.weather.current"
        class="office-weather-slide"
        gap="10px"
        main-axis-align="space-between"
    >
        <div class="office-weather-slide__current">
            <f-flex gap="15px">
                <f-weather-icon :icon="data.weather.current.icon"/>
                <div class="office-weather-slide__current__content">
                    <div class="office-weather-slide__current__content__now">
                        {{ data.weather.current.temperature_c }}<span>°c</span>
                    </div>
                    <div class="office-weather-slide__current__content__min-max">
                        <span>H</span> {{ data.weather.current.forecast.max_temp_c }}
                        &nbsp;&ndash;&nbsp;
                        <span>L</span> {{ data.weather.current.forecast.min_temp_c }}
                    </div>
                    <div class="office-weather-slide__current__content__condition">
                        {{ data.weather.current.condition.ucfirst() }}
                    </div>
                </div>
            </f-flex>
        </div>
        <div class="office-weather-slide__current-details">
            <div
                v-for="row in details"
                class="office-weather-slide__current-details__row"
                :key="row.tooltip"
            >
                <f-weather-icon
                    :icon="row.icon"
                    :set="row.iconSet"
                />
                <dashed-explanation
                    class-name="office-weather-slide__current-details__row__body"
                    :tooltip="row.tooltip"
                >
                    {{ row.value }}
                </dashed-explanation>
            </div>
        </div>
        <div class="office-weather-slide__forecast">
            <f-flex
                class-name="office-weather-slide__forecast__day"
                v-for="(day, i) in data.weather.forecast"
                :key="i"
                gap="10px"
                main-axis-align="space-between"
            >
                <div class="office-weather-slide__forecast__day__body">
                    <div class="office-weather-slide__forecast__day__body__time">
                        {{ getTime(day.description) }}
                    </div>
                    <div class="office-weather-slide__forecast__day__body__condition">
                        {{ day.condition.ucfirst() }}
                    </div>
                </div>
                <div class="office-weather-slide__forecast__day__temperature">
                    <i :class="`wi wi-${day.icon}`"/>
                    <div class="office-weather-slide__forecast__day__temperature__body">
                        {{ day.temperature_c }}<span>°c</span>
                    </div>
                </div>
            </f-flex>
        </div>
    </f-flex>
</template>

<script>
import moment from 'moment-timezone';
import FWeatherIcon from '../../../f-components/layout/icons/FWeatherIcon';
import FFlex from '../../../f-components/layout/flex/FFlex';
import DashedExplanation from '../../misc/DashedExplanation';

export default {
    name: 'office-weather-slide',

    components: {
        DashedExplanation,
        FFlex,
        FWeatherIcon,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    computed: {
        details() {
            return [
                {
                    iconSet: 'wind',
                    icon: `wind-from-${this.data.weather.current.wind_direction}`,
                    tooltip: 'Wind speed',
                    value: `${this.data.weather.current.wind_kph} kph`,
                },
                {
                    icon: 'humidity',
                    tooltip: 'Humidity',
                    value: `${this.data.weather.current.humidity}%`,
                },
                {
                    icon: 'cloud',
                    tooltip: 'Cloud cover',
                    value: `${this.data.weather.current.cloud}%`,
                },
                {
                    icon: 'raindrops',
                    tooltip: 'Total precipitation (last 1h)',
                    value: `${this.data.weather.current.precip_mm} mm`,
                },
                {
                    icon: 'sunrise',
                    tooltip: 'Sunrise',
                    value: `${this.data.weather.current.astro.sunrise}`,
                },
                {
                    icon: 'sunset',
                    tooltip: 'Sunset',
                    value: `${this.data.weather.current.astro.sunset}`,
                },
            ];
        },
    },

    methods: {
        getTime(time) {
            return (this.$store.getters.getUserSetting('global.clock') === '12' ? moment(time, 'HH:mm').format('h:mm A') : time);
        },
    },
}
</script>

<style lang="scss">
.office-weather-slide {
    text-align: left;
    margin: auto;
    overflow: hidden;
    height: 170px;

    &__current {
        flex: 0 0 250px;

        .f-weather-icon {
            font-size: 100px;
            line-height: 100%;
            color: $primary;
        }

        &__content {
            &__now {
                font-size: 400%;
                line-height: 100%;

                span {
                    font-size: 80%;
                }
            }

            &__min-max {
                padding: 5px 0;
                opacity: .5;
            }

            &__condition {
            }
        }
    }

    &__current-details {
        &__row {
            line-height: 160%;
            white-space: nowrap;

            .f-weather-icon {
                display: inline-block;
                width: 16px;
                margin-right: 2px;
                text-align: center;
            }

            &__body {
                font-size: 12px;
            }
        }
    }

    &__forecast {
        flex: 0 0 130px;
        padding-top: 2px;

        &__day {
            padding: 9px;
            border-bottom: 1px solid $softGray;

            &:last-child {
                border: 0;
            }

            &__body {
                &__time {
                    font-size: 12px;
                    font-weight: 600;
                    text-transform: lowercase;
                }

                &__condition {
                    font-size: 10px;
                    color: $secondaryGray;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &__temperature {
                text-align: right;

                .f-weather-icon {
                    font-size: 14px;
                }

                &__body {
                    font-size: 10px;
                    color: $secondaryGray;
                }
            }
        }
    }
}
</style>
