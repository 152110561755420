var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({text: _vm.tooltip}),expression:"{text: tooltip}"}],class:[
        'user-card-mini',
         (_vm.selected ? '--selected' : null),
         (_vm.isSelectable && !_vm.disabled ? '--selectable' : null),
         (_vm.disabled ? '--disabled' : null),
         `--${_vm.variant}`,
         '--no-select',
     ],on:{"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)}}},[_c('div',{staticClass:"user-card-mini__image"},[_c('entity-avatar',{attrs:{"name":_vm.user.name ? _vm.user.name : '?',"src":_vm.user.profile_image,"birthday":_vm.hasBirthday,"size":"xxsmall"}})],1),_c('div',{staticClass:"user-card-mini__body"},[_vm._v(" "+_vm._s(_vm.nameOverride ? _vm.nameOverride : _vm.user.name)+" ")]),(_vm.includeDisciplines)?_c('div',{staticClass:"user-card-mini__disciplines"},[_vm._v(" "+_vm._s(_vm.user.title ? _vm.user.title : _vm.user.discipline_desc)+" "+_vm._s(_vm.user.type && _vm.user.type === 'freelancer' ? '(freelancer)' : '')+" ")]):_c('div',{staticClass:"user-card-mini__slot"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }