var render = function render(){var _vm=this,_c=_vm._self._c;return _c((_vm.item.parent_id === 0 || true ? 'div' : 'li'),{tag:"component",class:[
        'faq-item',
        (_vm.item.children.length > 0 ? '--children' : null),
        ((_vm.isOpen || _vm.isForcedOpen) && _vm.item.parent_id !== 0 ? '--open' : null),
        `--level-${_vm.level}`,
    ],attrs:{"id":`item-${_vm.item.id}`,"data-id":_vm.item.id},on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(_vm.item.parent_id === 0)?_c('div',{class:['faq-item__category', (_vm.renderCategoryAsBulletPoint ? '--align-left' : null)]},[(_vm.renderCategoryAsBulletPoint)?_c('bullet-point',{staticStyle:{"color":"#000"}},[(_vm.edit)?_c('faq-drag'):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.item.title)}}),(_vm.edit)?_c('faq-item-actions',{attrs:{"item":_vm.item,"parent-titles":_vm.parentTitles,"default-places":_vm.defaultPlaces},on:{"refresh":function($event){return _vm.$emit('refresh')}}}):_vm._e()],1):_c('h2',[_c('f-text',[(_vm.edit)?_c('faq-drag'):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.item.title)}})],1),(_vm.edit)?_c('faq-item-actions',{attrs:{"item":_vm.item,"parent-titles":_vm.parentTitles,"default-places":_vm.defaultPlaces},on:{"refresh":function($event){return _vm.$emit('refresh')}}}):_vm._e()],1),(
                ((_vm.item.show_for_offices && _vm.item.show_for_offices.length > 0) || !_vm.item.visible || _vm.item.hidden_for_freelancers)
                && _vm.hasPermission(_vm.Permissions.FAQ_EDIT)
            )?_c('div',{staticClass:"faq-item__category__visibility"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({text: 'You are seeing this FAQ category because you are a FAQ admin', wrap: true}),expression:"{text: 'You are seeing this FAQ category because you are a FAQ admin', wrap: true}"}]},[(!_vm.item.visible)?_c('f-chip',{attrs:{"size":"small"}},[_vm._v(" Not published ")]):_vm._e(),((_vm.item.show_for_offices && _vm.item.show_for_offices.length > 0))?_c('f-chip-group',[_c('f-chip',{attrs:{"size":"small","variant":"dark"}},[_vm._v(" Only visible for ")]),_c('f-chip',{attrs:{"size":"small","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.item.show_for_offices.join(', '))+" ")])],1):_vm._e(),(_vm.item.hidden_for_freelancers)?_c('f-chip',{attrs:{"size":"small","variant":"warning"}},[_vm._v(" Hidden for freelancers ")]):_vm._e()],1)]):_vm._e()],1):_c('button',{staticClass:"faq-item__toggle",attrs:{"type":"button"},on:{"click":_vm.toggle}},[(_vm.edit)?_c('f-flex-item',{attrs:{"grow":"0","basis":"13px"}},[_c('faq-drag')],1):_vm._e(),_c('f-flex-item',{attrs:{"grow":"1"}},[_c('f-text',{attrs:{"type":"header","font-weight":"600","line-height":"120%"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.item.title)}})]),(
                    ((_vm.item.show_for_offices && _vm.item.show_for_offices.length > 0) || !_vm.item.visible || _vm.item.hidden_for_freelancers)
                    && _vm.hasPermission(_vm.Permissions.FAQ_EDIT)
                )?_c('div',{staticClass:"faq-item__toggle__visibility"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({text: 'You are seeing this FAQ item because you are a FAQ admin', wrap: true}),expression:"{text: 'You are seeing this FAQ item because you are a FAQ admin', wrap: true}"}]},[(!_vm.item.visible)?_c('f-chip',{attrs:{"size":"small"}},[_vm._v(" Not published ")]):_vm._e(),((_vm.item.show_for_offices && _vm.item.show_for_offices.length > 0))?_c('f-chip-group',[_c('f-chip',{attrs:{"size":"small","variant":"dark"}},[_vm._v(" Only visible for ")]),_c('f-chip',{attrs:{"size":"small","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.item.show_for_offices.join(', '))+" ")])],1):_vm._e(),(_vm.item.hidden_for_freelancers)?_c('f-chip',{attrs:{"size":"small","variant":"warning"}},[_vm._v(" Hidden for freelancers ")]):_vm._e()],1)]):_vm._e()],1),_c('f-flex-item',{attrs:{"grow":"0","class-name":"--no-wrap"}},[(_vm.edit)?_c('faq-item-actions',{attrs:{"item":_vm.item,"size":(_vm.level > 1 ? 'xsmall' : 'small'),"parent-titles":_vm.parentTitles,"default-places":_vm.defaultPlaces},on:{"refresh":function($event){return _vm.$emit('refresh')}}}):_vm._e(),_c('f-icon-button',{attrs:{"icon":(_vm.isOpen || _vm.isForcedOpen ? 'chevron-up' : 'chevron-down'),"selected":_vm.isOpen || _vm.isForcedOpen || _vm.hover,"size":(_vm.level > 1 ? 'xsmall' : 'small'),"outlined":""}})],1)],1),_c('transition',{attrs:{"name":(_vm.animate ? 'height-small' : null)}},[(_vm.isOpen || _vm.isForcedOpen || _vm.item.parent_id === 0)?_c('div',{staticClass:"faq-item__content"},[(_vm.item.description && _vm.item.parent_id !== 0)?_c('f-content',{domProps:{"innerHTML":_vm._s(_vm.item.description)}}):_vm._e(),(_vm.item.children.length > 0)?_c('draggable',{class:[
                    'faq-item__children',
                    (_vm.currentGroup ? `--dragging-${_vm.currentGroup}` : null),
                ],attrs:{"list":_vm.item.children,"group":"faq-item","handle":".faq-drag"},on:{"change":function($event){return _vm.$emit('change', $event)},"start":function($event){return _vm.$emit('start', $event)},"end":function($event){return _vm.$emit('end', $event)}}},_vm._l((_vm.item.children),function(child,y){return _c('faq-item',{key:y,attrs:{"item":child,"parent-ids":_vm.ids,"level":_vm.level + 1,"parent-titles":[
                        ..._vm.parentTitles,
                        _vm.item.title,
                    ],"default-places":_vm.defaultPlaces,"edit":_vm.edit},on:{"refresh":function($event){return _vm.$emit('refresh', $event)},"change":function($event){return _vm.$emit('change', $event)},"start":function($event){return _vm.$emit('start', $event)},"end":function($event){return _vm.$emit('end', $event)}}})}),1):_vm._e()],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }