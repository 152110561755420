<template>
    <component
        :is="(item.parent_id === 0 || true ? 'div' : 'li')"
        :class="[
            'faq-item',
            (item.children.length > 0 ? '--children' : null),
            ((isOpen || isForcedOpen) && item.parent_id !== 0 ? '--open' : null),
            `--level-${level}`,
        ]"
        :id="`item-${item.id}`"
        :data-id="item.id"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
    >
        <div
            v-if="item.parent_id === 0"
            :class="['faq-item__category', (renderCategoryAsBulletPoint ? '--align-left' : null)]"
        >
            <bullet-point
                v-if="renderCategoryAsBulletPoint"
                style="color: #000;"
            >
                <faq-drag v-if="edit"/>
                <span v-html="item.title"/>
                <faq-item-actions
                    v-if="edit"
                    :item="item"
                    @refresh="$emit('refresh')"
                    :parent-titles="parentTitles"
                    :default-places="defaultPlaces"
                />
            </bullet-point>
            <h2 v-else>
                <f-text>
                    <faq-drag v-if="edit"/>
                    <span v-html="item.title"/>
                </f-text>
                <faq-item-actions
                    v-if="edit"
                    :item="item"
                    @refresh="$emit('refresh')"
                    :parent-titles="parentTitles"
                    :default-places="defaultPlaces"
                />
            </h2>

            <div
                v-if="
                    ((item.show_for_offices && item.show_for_offices.length > 0) || !item.visible || item.hidden_for_freelancers)
                    && hasPermission(Permissions.FAQ_EDIT)
                "
                class="faq-item__category__visibility"
            >
                <span v-tooltip="{text: 'You are seeing this FAQ category because you are a FAQ admin', wrap: true}">
                    <f-chip v-if="!item.visible" size="small">
                        Not published
                    </f-chip>
                    <f-chip-group v-if="(item.show_for_offices && item.show_for_offices.length > 0)">
                        <f-chip size="small" variant="dark">
                            Only visible for
                        </f-chip>
                        <f-chip
                            size="small"
                            variant="primary"
                        >
                            {{ item.show_for_offices.join(', ') }}
                        </f-chip>
                    </f-chip-group>
                    <f-chip v-if="item.hidden_for_freelancers" size="small" variant="warning">
                        Hidden for freelancers
                    </f-chip>
                </span>
            </div>
        </div>

        <button
            v-else
            class="faq-item__toggle"
            type="button"
            @click="toggle"
        >
            <f-flex-item v-if="edit" grow="0" basis="13px">
                <faq-drag/>
            </f-flex-item>
            <f-flex-item grow="1">
                <f-text
                    type="header"
                    font-weight="600"
                    line-height="120%"
                >
                    <span v-html="item.title"/>
                </f-text>
                <div
                    v-if="
                        ((item.show_for_offices && item.show_for_offices.length > 0) || !item.visible || item.hidden_for_freelancers)
                        && hasPermission(Permissions.FAQ_EDIT)
                    "
                    class="faq-item__toggle__visibility"
                >
                    <span v-tooltip="{text: 'You are seeing this FAQ item because you are a FAQ admin', wrap: true}">
                        <f-chip v-if="!item.visible" size="small">
                            Not published
                        </f-chip>
                        <f-chip-group v-if="(item.show_for_offices && item.show_for_offices.length > 0)">
                            <f-chip size="small" variant="dark">
                                Only visible for
                            </f-chip>
                            <f-chip
                                size="small"
                                variant="primary"
                            >
                                {{ item.show_for_offices.join(', ') }}
                            </f-chip>
                        </f-chip-group>
                        <f-chip v-if="item.hidden_for_freelancers" size="small" variant="warning">
                            Hidden for freelancers
                        </f-chip>
                    </span>
                </div>
            </f-flex-item>
            <f-flex-item grow="0" class-name="--no-wrap">
                <faq-item-actions
                    v-if="edit"
                    :item="item"
                    :size="(level > 1 ? 'xsmall' : 'small')"
                    :parent-titles="parentTitles"
                    :default-places="defaultPlaces"
                    @refresh="$emit('refresh')"
                />
                <f-icon-button
                    :icon="(isOpen || isForcedOpen ? 'chevron-up' : 'chevron-down')"
                    :selected="isOpen || isForcedOpen || hover"
                    :size="(level > 1 ? 'xsmall' : 'small')"
                    outlined
                />
            </f-flex-item>
        </button>

        <transition :name="(animate ? 'height-small' : null)">
            <div
                v-if="isOpen || isForcedOpen || item.parent_id === 0"
                class="faq-item__content"
            >
                <f-content
                    v-if="item.description && item.parent_id !== 0"
                    v-html="item.description"
                />

                <draggable
                    v-if="item.children.length > 0"
                    :class="[
                        'faq-item__children',
                        (currentGroup ? `--dragging-${currentGroup}` : null),
                    ]"
                    :list="item.children"
                    group="faq-item"
                    handle=".faq-drag"
                    @change="$emit('change', $event)"
                    @start="$emit('start', $event)"
                    @end="$emit('end', $event)"
                >
                    <faq-item
                        v-for="(child, y) in item.children"
                        :item="child"
                        :key="y"
                        :parent-ids="ids"
                        :level="level + 1"
                        :parent-titles="[
                            ...parentTitles,
                            item.title,
                        ]"
                        :default-places="defaultPlaces"
                        :edit="edit"
                        @refresh="$emit('refresh', $event)"
                        @change="$emit('change', $event)"
                        @start="$emit('start', $event)"
                        @end="$emit('end', $event)"
                    />
                </draggable>
            </div>
        </transition>
    </component>
</template>

<script>
import FContent from '../../../f-components/layout/content/FContent';
import EventBus from '../../../services/EventBus';
import FIconButton from '../../../f-components/form-controls/buttons/FIconButton';
import FText from '../../../f-components/layout/text/FText';
import FaqItemActions from './FAQItemActions';
import FIcon from '../../../f-components/layout/icons/FIcon';
import FaqDrag from './FAQDrag';
import {GlobalEvents} from '../../../config/Events';
import draggable from 'vuedraggable';
import FChip from '../../../f-components/data/chip/FChip';
import FFlexItem from '../../../f-components/layout/flex/FFlexItem';
import FChipGroup from '../../../f-components/data/chip/FChipGroup';
import BulletPoint from '../BulletPoint';

export default {
    name: 'faq-item',

    components: {
        BulletPoint,
        FChipGroup,
        FFlexItem,
        FChip,
        draggable,
        FaqDrag,
        FIcon,
        FaqItemActions,
        FText,
        FIconButton,
        FContent,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },
        parentTitles: {
            type: Array,
            default: () => [],
        },
        parentIds: {
            type: Array,
            default: () => [],
        },
        edit: Boolean,
        level: {
            type: Number,
            default: 0,
        },
        defaultPlaces: Array,
        renderCategoryAsBulletPoint: Boolean,
    },

    computed: {
        ids() {
            return [
                ...(this.parentIds ? this.parentIds : []),
                this.item.id,
            ];
        },
    },

    data() {
        return {
            isOpen: false,
            isForcedOpen: false,
            hover: false,
            currentGroup: null,
            animate: true,
        }
    },

    created() {
        EventBus.$on('faqItem.open', this.onItemOpen);
        EventBus.$on('faqItem.forceOpen', this.onItemForceOpen);
    },

    beforeDestroy() {
        EventBus.$off('faqItem.open', this.onItemOpen);
        EventBus.$off('faqItem.forceOpen', this.onItemForceOpen);
    },

    watch: {
        isOpen: function (isOpen) {
            if (isOpen) {
                history.pushState(null, null, `#item-${this.item.id}`);
                EventBus.$emit('faqItem.open', this.ids);
            }
        },
    },

    methods: {
        onItemOpen(ids) {
            if (!ids.includes(this.item.id)) {
                this.close();
            }
        },

        onItemForceOpen(id, jumpTo = false) {
            if (this.item.id === id) {
                this.animate = false;

                // Open all parents
                this.ids.forEach((_id) => {
                    if (this.item.id !== _id)
                        EventBus.$emit('faqItem.forceOpen', _id, false);
                });

                this.forceOpen();

                if (jumpTo) {
                    const position = (window.pageYOffset || document.documentElement.scrollTop) + this.$el.getBoundingClientRect().top - (window.innerHeight / 3);
                    setTimeout(() => window.scrollTo({top: position}), 200);
                    this.$nextTick(() => this.animate = true);
                }
            }
        },

        toggle() {
            this.isOpen = !this.isOpen;
            this.isForcedOpen = false;

            if (this.isOpen) {
                const top = this.$el.getBoundingClientRect().top;

                this.$nextTick(() => {
                    const change = top - this.$el.getBoundingClientRect().top;
                    if (change !== 0) {
                        window.scrollBy({top: -change});
                    }
                });
            }
        },

        close() {
            this.isOpen = false;
            this.isForcedOpen = false;
        },

        open() {
            if (!this.isOpen) this.isOpen = true;
            this.isForcedOpen = false;
        },

        forceOpen() {
            this.isForcedOpen = true;
        },

        onChange(changes) {
            console.log('changes', changes);
            // this.startLoading();

            // // Save to DB
            // window.axios.post(
            //     `${APIEndpoints.ADMIN_DISCIPLINES}/order`,
            //     {
            //         '_method': 'PUT',
            //         'items': this.items
            //     }
            // )
            //     .then(() => this.endLoading(true))
            //     .catch(() => this.endLoading(true));
        },

        onDragStart(e) {
            this.currentGroup = e.from.dataset.group;
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },

        onDragEnd() {
            this.currentGroup = null;
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },
    },
}
</script>

<style lang="scss">
.faq-item {
    border: 1px solid transparent;
    border-top-color: $softGray;
    position: relative;
    overflow: hidden;
    font-size: 13px;
    transition: background-color .1s, border-top-color .1s;

    &.--open {
        border-radius: 5px;
        background: $softGray;
        border-top-color: transparent;

        & + .faq-item {
            border-top-color: transparent;
        }
    }

    &__category {
        margin-top: 50px;
        text-align: center;
        color: $secondaryGray;
        margin-bottom: 25px;

        &__visibility {
            > span > div {
                margin-right: 2px;
            }
        }
    }

    &__toggle {
        background: none;
        border-radius: 1px;
        width: 100%;
        text-align: left;
        padding: 20px;
        display: flex;
        gap: 10px;
        min-height: 60px;
        align-items: center;
        justify-content: space-between;

        @include media(mobile-down) {
            padding: 15px;
            min-height: 50px;
        }

        .f-text {
            font-size: 16px;

            @include media(mobile-down) {
                font-size: 15px;
            }
        }

        &__visibility {
            > span > div {
                margin-right: 2px;
            }
        }
    }

    &.--level-0 {
        border: 0;
    }

    &.--level-1, &.--level-3 {
        border-top-color: $softGray;

        &.--open {
            background: $softGray;
        }
    }

    &.--level-2 {
        .faq-item__toggle .f-text {
            font-size: 14px;
        }
    }

    .f-text .f-chip {
        margin-left: 5px;
    }

    &__content {
        overflow: hidden;
        padding: 0 0 20px;
        color: lighten($dark, 15%);

        @include media(mobile-down) {
            padding: 0 0 15px;
        }

        .faq-item__content {
            padding: 0 20px 20px;

            @include media(mobile-down) {
                padding: 0 15px 15px;
            }
        }
    }

    &.--children > .faq-item__content > .f-content {
        padding-bottom: 20px;
    }

    &:first-child {
        border: none;
    }
}
</style>
