<template>
    <div
        id="riviera"
        :class="[
            `page-${$router.currentRoute.name}`,
            `--env-${environment}`,
            `--office-${userOffice}`,
            (isChristmas    ? '--christmas' : undefined),
            (modalsOpen > 0 ? '--modal-active' : undefined),
            '--theme-light',
        ]"
    >
        <environment-label
            v-if="environment !== 'production'"
            :environment="environment"
        />

        <div class="container">
            <main-menu v-if="menuActive"/>
            <div class="container__content">
                <page-header v-if="menuActive"/>
                <router-view/>
                <page-footer/>
            </div>
        </div>

        <gallery/>
        <snowflakes v-if="isChristmas"/>
        <profile-settings-pane v-if="menuActive"/>
        <!--<notifications-pane v-if="menuActive"/>-->
        <notification-modal/>
        <user-card-popup/>
        <tutorial
            v-if="!breakpoints.mobile && tutorialOpen"
            @close="tutorialOpen = false"
        />

        <f-site-loader/>
        <f-portal-container/>
        <f-alert/>
        <f-tooltip/>
        <f-push-messages/>
    </div>
</template>

<script>
import moment from 'moment-timezone';
import {APP_ENVIRONMENT, APP_VERSION} from '../config/app';
import {TokenState} from '../services/Store';
import APIDataStoreResources from '../config/APIDataStoreResources';
import FAlert from '../f-components/notice/alert/FAlert';
import ActiveModalsMixin from '../mixins/ActiveModalsMixin';
import APIDataStore from '../services/APIDataStore';
import PageHeader from './header/PageHeader';
import MainMenu from './main-menu/MainMenu';
import GlobalMinuteChangeEventMixin from '../mixins/GlobalMinuteChangeEventMixin';
import NotificationModal from './misc/notifications-pane/NotificationModal';
import UserCardPopup from './cards/users/UserCardPopup';
import FSiteLoader from '../f-components/loaders/site-loader/FSiteLoader';
import Snowflakes from './misc/festive/SnowFlakes';
import FPortalContainer from '../f-components/other/portal/FPortalContainer';
import EventBus from '../services/EventBus';
import {GlobalEvents} from '../config/Events';
import ProfileSettingsPane from './misc/profile-settings-pane/ProfileSettingsPane';
import NotificationsPane from './misc/notifications-pane/NotificationsPane';
import FTooltip from '../f-components/notice/tooltip/FTooltip';
import FPushMessages from '../f-components/notice/push-message/FPushMessages';
import {Permissions} from '../config/Permissions';
import OneSignal from '../services/OneSignal';
import UserOfficeMixin from '../mixins/UserOfficeMixin';
import PageFooter from './footer/PageFooter';
import EnvironmentLabel from './misc/EnvironmentLabel';
import Gallery from './misc/gallery/Gallery';
import APIEndpoints from '../config/APIEndpoints';
import Tutorial from './tutorial/Tutorial';
import BreakpointsMixin from '../mixins/BreakpointsMixin';

export default {
    components: {
        Tutorial,
        Gallery,
        EnvironmentLabel,
        PageFooter,
        FPushMessages,
        FTooltip,
        NotificationsPane,
        ProfileSettingsPane,
        FPortalContainer,
        FSiteLoader,
        UserCardPopup,
        NotificationModal,
        MainMenu,
        PageHeader,
        FAlert,
        Snowflakes,
    },

    mixins: [
        ActiveModalsMixin,
        GlobalMinuteChangeEventMixin,
        UserOfficeMixin,
        BreakpointsMixin,
    ],

    computed: {
        isChristmas() {
            return (this.today.month() === 11 && this.today.date() > 10) || (this.today.month() === 0 && this.today.date() < 3);
        },
    },

    data() {
        return {
            menuActive: true,
            minuteTimeout: null,
            today: moment(),
            environment: APP_ENVIRONMENT,
            tutorialOpen: false,
        }
    },

    watch: {
        $route() {
            EventBus.$emit(GlobalEvents.PAGE_CHANGE);
            window.scrollTo(0, 0);
            this.defineUi();
        },
        modalsOpen(num) {
            // Scroll lock
            if (num === 1) {
                document.body.style.overflow = 'hidden';
            } else if (num === 0) {
                document.body.style.overflow = '';
            }
        },
    },

    created() {
        this.defineUi();
        this.registerGlobalEvents();
        OneSignal.load();
    },

    mounted() {
        // Fetch default data used throughout the app
        this.fetchDefaultData();
        // Check app version
        this.$nextTick(() => this.checkAppVersion());
    },

    methods: {
        fetchDefaultData() {
            APIDataStore.fetch(APIDataStoreResources.USER);
            APIDataStore.check(APIDataStoreResources.USERS_COLLECTION);
            // APIDataStore.fetch(APIDataStoreResources.LEAVE);
            // APIDataStore.fetch(APIDataStoreResources.CLIENTS_COLLECTION);
            // APIDataStore.check(APIDataStoreResources.OFFICES_COLLECTION);
            // APIDataStore.check(APIDataStoreResources.DEPARTMENTS_AND_DISCIPLINES_COLLECTION);
            // if (this.hasPermission(Permissions.LEAVE_REQUESTS_VIEW)) {
            //     APIDataStore.check(APIDataStoreResources.ADMIN_LEAVE_REQUESTS);
            // }
        },

        registerGlobalEvents() {
            EventBus.$on(GlobalEvents.LOGIN_SUCCESS, this.fetchDefaultData);
            EventBus.$on(GlobalEvents.LOGOUT, this.logout);
            EventBus.$on(GlobalEvents.TUTORIAL_OPEN, () => this.tutorialOpen = true);

            document.addEventListener('keyup', this.onKeyUpEvent);
            document.addEventListener('keydown', this.onKeyDownEvent);
            window.addEventListener('resize', (e) => EventBus.$emit(GlobalEvents.WINDOW_RESIZE, e));

            EventBus.$on(
                'user.settings[global.hide_birth_year].update',
                () => APIDataStore.fetch(APIDataStoreResources.USERS_COLLECTION)
            );
            EventBus.$on(
                'user.settings[global.pronouns].update',
                () => {
                    APIDataStore.fetch(APIDataStoreResources.USER);
                    APIDataStore.fetch(APIDataStoreResources.USERS_COLLECTION);
                },
            );
            EventBus.$on(
                'user.settings[global.custom_pronouns].update',
                () => {
                    APIDataStore.fetch(APIDataStoreResources.USER);
                    APIDataStore.fetch(APIDataStoreResources.USERS_COLLECTION);
                },
            );
            EventBus.$on(
                'user.settings[global.pronouns_signature].update',
                () => APIDataStore.fetch(APIDataStoreResources.USER),
            );
        },

        defineUi() {
            this.menuActive = (this.$store.getters.accessTokenState === TokenState.VALID && this.$router.currentRoute.name !== 'meeting-room');
        },

        onKeyDownEvent(event) {
            switch (event.keyCode) {
                // Left and rights keys are messing with scroll pane on the timeline and gallery
                case 37:
                case 39:
                    if (event.target === document.body) {
                        event.preventDefault();
                    }
                    break;
            }
        },

        onKeyUpEvent(event) {
            switch (event.keyCode) {
                case 27:
                    EventBus.$emit(GlobalEvents.KEYDOWN_ESC, event);
                    break;
                case 40:
                    EventBus.$emit(GlobalEvents.KEYDOWN_DOWN, event);
                    break;
                case 38:
                    EventBus.$emit(GlobalEvents.KEYDOWN_UP, event);
                    break;
                case 13:
                    EventBus.$emit(GlobalEvents.KEYDOWN_ENTER, event);
                    break;
                case 37:
                    EventBus.$emit(GlobalEvents.KEYDOWN_LEFT, event);
                    break;
                case 39:
                    EventBus.$emit(GlobalEvents.KEYDOWN_RIGHT, event);
                    break;
            }
        },

        logout() {
            window.axios.post(APIEndpoints.AUTH_LOGOUT);
            this.$store.dispatch('clearState');
            this.$router.push({name: 'auth'});
            EventBus.$emit(GlobalEvents.LOGOUT_SUCCESS);
        },

        checkAppVersion() {
            if (window.localStorage.getItem('riviera.version') !== APP_VERSION) {
                EventBus.$emit(GlobalEvents.PUSH_MESSAGE, 'Riviera has been updated. Please login again.', 'info');
                EventBus.$emit(GlobalEvents.LOGOUT);
                window.localStorage.setItem('riviera.version', APP_VERSION);
            }
        },
    }
}
</script>

<style lang="scss">
#riviera {
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    min-height: 100%;
    width: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
    background: $background;

    .container {
        display: flex;
        align-items: stretch;
        align-content: stretch;
        min-height: 100vh;

        &__content {
            position: relative;
            overflow: hidden;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > .page {
                flex-grow: 1;
            }
        }
    }
}
</style>
