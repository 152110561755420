var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'f-panel',
        ...(Array.isArray(_vm.className) ? _vm.className : [_vm.className]),
        (_vm.hasHeading || _vm.heading ? '--heading' : null),
        (_vm.collapsible && !_vm.open ? '--collapsed' : null),
        (_vm.loading ? '--loading' : null),
        (_vm.title ? '--has-title' : null),
        `--theme-${_vm.variant}`,
    ]},[_vm._t("head"),(_vm.title)?_c('f-text',{attrs:{"type":"subtitle","class-name":"f-panel__title"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.title)}}),(_vm.titleHelp)?_c('f-help-label',{attrs:{"text":_vm.titleHelp,"size":"11px"}}):_vm._e()],1):_vm._e(),(_vm.heading || _vm.collapsible)?_c('f-panel-header',{attrs:{"heading":_vm.heading,"heading-badge":_vm.headingBadge,"class-name":(_vm.collapsible ? '--collapsible' : null)},on:{"titleClick":_vm.onCollapseToggle},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._t("post-title"),_vm._t("post")]},proxy:true},{key:"title",fn:function(){return [_vm._t("title"),(_vm.collapsible)?_c('f-icon-button',{staticStyle:{"margin-left":"5px"},attrs:{"icon":(_vm.open ? 'chevron-up' : 'chevron-down'),"size":"xxsmall","outlined":""}}):_vm._e()]},proxy:true}],null,true)}):_vm._e(),((_vm.collapsible && _vm.open) || !_vm.collapsible)?_vm._t("post-head"):_vm._e(),(_vm.open)?_c('div',{class:['f-panel__content', _vm.contentClassName]},[_vm._t("default")],2):_vm._e(),_vm._t("post-content")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }