export const AnnouncementEventRatios = {
    LANDSCAPE: 'landscape',
    SQUARE: 'square',
    PORTRAIT: 'portrait',
};

export const AnnouncementEventTitleSizes = {
    XSMALL: 'xsmall',
    SMALL: 'small',
    MEDIUM: 'medium',
    XMEDIUM: 'xmedium',
    LARGE: 'large',
    XLARGE: 'xlarge',
};
