<template>
    <div :class="['references-list']">
        <f-table
            v-if="references && references.length > 0"
            type="simple"
        >
            <f-table-row
                v-for="reference in references"
                :key="reference.type"
            >
                <f-table-cell is-id>
                    {{ reference.type }}
                </f-table-cell>
                <f-table-cell
                    v-if="reference.type === 'riviera.freelancer.id' && hasPermission(Permissions.FREELANCERS_CREATE)"
                    is-id
                >
                    <router-link :to="`/freelancers/edit/${reference.value}`">
                        {{ reference.value }}
                    </router-link>
                </f-table-cell>
                <f-table-cell is-id v-else>
                    {{ reference.value }}
                </f-table-cell>
            </f-table-row>
        </f-table>
        <nothing-found v-else>
            No references found.
        </nothing-found>
    </div>
</template>

<script>
import FTable from '../../f-components/data/table/FTable';
import FTableRow from '../../f-components/data/table/FTableRow';
import FTableCell from '../../f-components/data/table/FTableCell';
import NothingFound from './NothingFound';

export default {
    name: 'references-list',

    components: {
        NothingFound,
        FTableCell,
        FTableRow,
        FTable,
    },

    props: {
        references: {
            type: Array,
            default: () => [],
        },
    },

    computed: {},

    watch: {},

    data() {
        return {}
    },

    mounted() {
    },

    beforeDestroy() {
    },

    methods: {},
}
</script>

<style lang="scss">
.references-list {

}
</style>
