<template>
    <div
        :class="['google-drive-embed']"
        :style="{ paddingTop: heightRatio }"
    >
        <f-icon icon="presentation"/>
        <iframe
            :src="`https://drive.google.com/file/d/${id}/preview`"
            class="google-drive-embed__iframe"
        />
    </div>
</template>

<script>
import FIcon from '../../../f-components/layout/icons/FIcon';

export default {
    name: 'google-drive-embed',

    components: {
        FIcon,
    },

    props: {
        id: {
            type: String,
            required: true,
        },
        heightRatio: {
            type: String,
            default: '60%',
        },
    },
}
</script>

<style lang="scss">
.google-drive-embed {
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 5px;
    background-color: $softGray;

    .f-icon {
        font-size: 800%;
        color: $tertiaryGray;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__iframe {
        position: absolute;
        top: -4px;
        left: -4px;
        bottom: 0;
        right: -4px;
        width: calc(100% + 8px);
        height: calc(100% + 4px);
    }
}
</style>
