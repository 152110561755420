<template>
    <li :class="['widget-user-list-item']">
        <slot/>
    </li>
</template>

<script>
export default {
    name: 'widget-user-list-item',
}
</script>
