<template>
    <f-modal
        :close-button="false"
        :backdrop="false"
        :title="(currentTutorialItem ? currentTutorialItem.title : 'Tutorial')"
        class-name="tutorial"
        width="600px"
        align-items="flex-end"
        panel-variant="dark"
        @close="close"
    >
        <template #pre>
            <div
                :class="[
                    'tutorial__clip',
                    (hasClipping ? '--clipped' : undefined),
                ]"
                :style="{
                    '--clip-x': clipBounds.x,
                    '--clip-y': clipBounds.y,
                    '--clip-height': clipBounds.height,
                    '--clip-width': clipBounds.width
                }"
            />
            <div
                v-if="hasClipping"
                ref="clipWindow"
                class="tutorial__clip__window"
                :style="{
                    left: clipBounds.x,
                    top: clipBounds.y,
                    height: clipBounds.height,
                    width: clipBounds.width
                }"
            >
                <f-popup
                    v-if="showClipPopup && currentTutorialItem.clipDescription && !navigationDisabled"
                    :target="$refs.clipWindow"
                    padding="15px 10px 15px 10px"
                    variant="light"
                    width="230px"
                    class-name="--align-center"
                    :margin="6"
                    wrap
                >
                    <div style="line-height: 120%; font-size: 13px;">
                        {{ currentTutorialItem.clipDescription }}
                    </div>

                    <f-button-group
                        v-if="!closable"
                        style="padding-top: 10px;"
                    >
                        <f-button
                            v-if="currentIndex !== 0"
                            icon="chevron-left"
                            @click="previous"
                            size="small"
                            :disabled="navigationDisabled"
                        />
                        <f-button
                            v-if="!closable"
                            icon-position="right"
                            icon="chevron-right"
                            size="small"
                            @click="next"
                            :disabled="navigationDisabled"
                        />
                    </f-button-group>
                </f-popup>
            </div>
        </template>

        <template #head>
            <f-out-of-total
                :total="filteredTutorialDefinition.length"
                :index="currentIndex"
            />
        </template>

        <f-flex direction="column">
            <f-carousel
                ref="carousel"
                show-numbers
                auto-size
                navigation-type="none"
                @slideChange="onSlideChange"
            >
                <f-carousel-slide
                    v-for="item in filteredTutorialDefinition"
                    :key="item.id"
                    :id="item.id"
                >
                    <f-content
                        v-if="item.body"
                        v-html="item.body"
                        class-name="--align-justify"
                        style="font-size: 14px"
                    />
                </f-carousel-slide>
            </f-carousel>

            <f-flex
                gap="10px"
                main-axis-align="space-between"
            >
                <f-button
                    v-if="!closable"
                    outlined
                    value="Skip tutorial"
                    @click="skip"
                />
                <span v-else/>

                <f-button-group>
                    <f-button
                        v-if="currentIndex !== 0"
                        variant="primary"
                        :outlined="false"
                        icon="chevron-left"
                        @click="previous"
                        :disabled="navigationDisabled"
                    />
                    <f-button
                        v-if="!closable"
                        variant="primary"
                        :outlined="false"
                        icon-position="right"
                        icon="chevron-right"
                        :value="currentIndex === 0 ? 'Start' : 'Next'"
                        @click="next"
                        :disabled="navigationDisabled"
                    />
                    <f-button
                        v-else
                        variant="primary"
                        :outlined="false"
                        value="Close"
                        @click="finish"
                        :disabled="navigationDisabled"
                    />
                </f-button-group>
            </f-flex>
        </f-flex>
    </f-modal>
</template>

<script>
import FModal from '../../f-components/other/modal/FModal';
import EventBus from '../../services/EventBus';
import {GlobalEvents} from '../../config/Events';
import FFlex from '../../f-components/layout/flex/FFlex';
import FButton from '../../f-components/form-controls/buttons/FButton';
import FCarousel from '../../f-components/navigation/carousels/FCarousel';
import FCarouselSlide from '../../f-components/navigation/carousels/FCarouselSlide';
import tutorialDefinition from '../../config/tutorialDefinition';
import FContent from '../../f-components/layout/content/FContent';
import FOutOfTotal from '../../f-components/navigation/out-of-total/FOutOfTotal';
import FButtonGroup from '../../f-components/form-controls/buttons/FButtonGroup';
import FPopup from '../../f-components/other/popup/FPopup';
import UserOfficeMixin from '../../mixins/UserOfficeMixin';

export default {
    name: 'tutorial',

    mixins: [
        UserOfficeMixin,
    ],

    components: {
        FPopup,
        FButtonGroup,
        FOutOfTotal,
        FContent,
        FCarouselSlide,
        FCarousel,
        FButton,
        FFlex,
        FModal
    },

    data() {
        return {
            closable: false,
            tutorialDefinition,
            currentIndex: 0,

            firstTime: true,
            navigationDisabledTimeout: undefined,
            navigationDisabled: false,

            updateClipTries: 0,
            updateClipTimeout: undefined,
            showClipPopup: false,
            showClipPopupTimeout: undefined,

            clipBounds: {
                x: 50,
                y: 50,
                height: 100,
                width: 100,
            },
        }
    },

    computed: {
        filteredTutorialDefinition() {
            return this.tutorialDefinition.filter((tuturial) => {
                if (!tuturial.allowedOffice && !tuturial.needsPermissionOr) {
                    return true;
                }
                if (tuturial.allowedOffice && tuturial.allowedOffice.includes(this.userOffice)) {
                    return true;
                }
                if (tuturial.needsPermissionOr) {
                    for (const i in tuturial.needsPermissionOr) {
                        if (this.hasPermission(tuturial.needsPermissionOr[i])) {
                            return true;
                        }
                    }
                }
                return false;
            });
        },
        currentTutorialItem() {
            return this.filteredTutorialDefinition[this.currentIndex];
        },
        hasClipping() {
            return this.currentTutorialItem && this.currentTutorialItem.clipElementID;
        },
    },

    watch: {
        currentTutorialItem() {
            this.initializeItem();
        },
    },

    mounted() {
        EventBus.$on(GlobalEvents.WINDOW_RESIZE, this.onResize);
        this.firstTime = true;
        this.initializeItem();
    },

    beforeDestroy() {
        EventBus.$off(GlobalEvents.WINDOW_RESIZE, this.onResize);
    },

    methods: {
        onResize() {
            this.updateClip();
        },

        initializeItem() {
            // Reset
            this.clearTimeouts();
            this.disableNavigationTimeout();

            this.updateClipTries = 0;
            this.showClipPopup = false;

            EventBus.$emit(GlobalEvents.NOTIFICATIONS_CLOSE);
            EventBus.$emit(GlobalEvents.PROFILE_CLOSE);

            // Check if route needs to change
            if (this.currentTutorialItem.route && this.$router.currentRoute.path !== this.currentTutorialItem.route) {
                this.$router.push(this.currentTutorialItem.route.replace('{office}', this.userOffice));
            }

            this.$nextTick(() => {
                if (this.currentTutorialItem && this.currentTutorialItem.onActive) {
                    this.currentTutorialItem.onActive();
                }
                this.updateClip();
            });
        },

        open() {
        },

        close() {
            this.clearTimeouts();
            this.$emit('close');
        },

        skip() {
            this.$store.commit('userSetting', {
                key: 'tutorial.skipped',
                value: true,
            });
            this.$emit('close');
        },

        finish() {
            this.$store.commit('userSetting', {
                key: 'tutorial.finished',
                value: true,
            });
            this.$emit('close');
        },

        next() {
            this.$refs.carousel.slideToNext();
        },

        previous() {
            this.$refs.carousel.slideToPrevious();
        },

        updateClip() {
            if (!this.currentTutorialItem.clipElementID) {
                return;
            }
            if (this.updateClipTries > 20) {
                return;
            }

            const element = document.getElementById(this.currentTutorialItem.clipElementID);
            if (!element) {
                this.updateClipTries++;
                window.setTimeout(() => this.updateClip(), 500);
                return;
            }

            this.setClipBounds(element);
        },

        setClipBounds(element) {
            element.scrollIntoView({block: 'center'});

            const bounding = element.getBoundingClientRect();
            const margin = this.currentTutorialItem.clipMargin ?? 10;

            this.clipBounds = {
                x: `${bounding.x - margin / 2}px`,
                y: `${bounding.y - margin / 2}px`,
                height: this.currentTutorialItem.clipHeight ?? `${bounding.height + margin}px`,
                width: `${bounding.width + margin}px`,
            };

            // Show popup
            this.showClipPopupTimeout = window.setTimeout(() => this.showClipPopup = true, 500);
        },

        onSlideChange(id, index, total) {
            this.currentIndex = index;
            this.closable = index + 1 === total;
        },

        disableNavigationTimeout() {
            if (this.firstTime) {
                this.firstTime = false;
                return;
            }

            this.navigationDisabled = true;
            this.navigationDisabledTimeout = window.setTimeout(() => this.navigationDisabled = false, 750);
        },

        clearTimeouts() {
            if (this.navigationDisabledTimeout) {
                window.clearTimeout(this.navigationDisabledTimeout);
            }
            if (this.updateClipTimeout) {
                window.clearTimeout(this.updateClipTimeout);
            }
            if (this.showClipPopupTimeout) {
                window.clearTimeout(this.showClipPopupTimeout);
            }
        },

        // checkDeeplink() {
        //     const matches = location.hash.match(/^#tutorial$/);
        //     if (matches && matches[0] === '#tutorial') {
        //         this.open();
        //     }
        // },
    },
}
</script>

<style lang="scss">
.tutorial {
    &.f-modal {
        .f-modal__container {
            margin-bottom: 40px;

            .f-carousel {
                width: 100%;
            }

            .f-content {
                padding-bottom: 30px;
            }

            .f-out-of-total {
                position: absolute;
                right: 25px;
                top: 16px;
            }
        }
    }

    &__clip {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($dark, .8);
        backdrop-filter: blur(2px);
        transition: clip-path .35s;

        &__window {
            border-radius: 4px;
            position: fixed;
            border: 1px solid $dark;
            box-shadow: 0 0 0 1px white;
            transition: all .35s;
        }

        &.--clipped {
            clip-path: polygon(
                    0% 0%,
                    0% 100%,
                    var(--clip-x) 100%,
                    var(--clip-x) var(--clip-y),
                    calc(var(--clip-x) + var(--clip-width)) var(--clip-y),
                    calc(var(--clip-x) + var(--clip-width)) calc(var(--clip-y) + var(--clip-height)),
                    var(--clip-x) calc(var(--clip-y) + var(--clip-height)),
                    var(--clip-x) 100%,
                    100% 100%,
                    100% 0%
            );
        }
    }
}
</style>
