<template>
    <page
        v-if="hasPermission(Permissions.ADMIN_ANNOUNCEMENTS)"
        :loading="loading"
        class-name="admin-announcements-page"
        :title="['Admin', 'Announcements']"
        header="Announcements"
        :header-badge="(fetchedData ? fetchedData.total : null)"
        pre-max-width="800px"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb>Announcements</f-breadcrumb>
                <f-breadcrumb>Overview</f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-container max-width="800px">
            <f-filter
                show-active-filters
                :active-filters="activeFilters"
                :filter-casts="{ query: 'query' }"
            >
                <f-filter-row>
                    <f-filter-item>
                        <f-text-input
                            placeholder="Search…"
                            pre-icon="magnifier"
                            v-model="searchQuery"
                            class-name="--align-center"
                            max-width="300px"
                            resetable
                        />
                    </f-filter-item>
                </f-filter-row>
            </f-filter>

            <f-table
                v-if="fetchedData"
                :empty="(!fetchedData || items.length === 0)"
                rows-hoverable
            >
                <template #pre>
                    <f-pagination
                        v-if="fetchedData"
                        :current-page="currentPage"
                        :page-count="lastPage"
                        @navigate="onPaginationNavigate"
                    />
                </template>

                <template #head>
                    <f-table-header-row class-name="--no-wrap">
                        <f-table-header-cell width="60px"/>
                        <f-table-header-cell style="min-width: 140px">
                            Title
                        </f-table-header-cell>
                        <f-table-header-cell align="center">
                            Date
                        </f-table-header-cell>
                        <f-table-header-cell/>
                        <f-table-header-cell/>
                    </f-table-header-row>
                </template>

                <f-table-row
                    v-for="row in items"
                    :key="row.id"
                    @click="$router.push(`/admin/announcements/edit/${row.id}`)"
                    :actions=" [
                        {
                            value: 'Edit',
                            icon: 'pencil',
                            onClick: () => $router.push(`/admin/announcements/edit/${row.id}`),
                        },
                        {
                            icon: 'trash2',
                            value: 'Delete',
                            onClick: () => deleteAnnouncement(row.id, row.timeline_title),
                        },
                    ]"
                >
                    <f-table-cell>
                        <f-image-loader
                            v-if="row.timeline_image_url"
                            :src="row.timeline_image_url"
                        />
                    </f-table-cell>
                    <f-table-title-cell>
                        {{ row.timeline_title }}
                        <template #sub>
                            {{ row.type }}
                            <subtitle-spacer v-if="row.type && row.timeline_subtitle"/>
                            {{ row.timeline_subtitle }}

                            <div
                                v-if="row.timeline.show_for_offices &&  row.timeline.show_for_offices.length > 0"
                                style="padding-top: 3px;"
                            >
                                <f-chip
                                    v-for="office in row.timeline.show_for_offices"
                                    :key="office"
                                    size="xsmall"
                                >
                                    {{ office }}
                                </f-chip>
                            </div>

                            <div style="padding-top: 3px;">
                                <f-chip size="xsmall" variant="secondary">
                                    {{ row.page }} page
                                </f-chip>
                            </div>
                        </template>
                    </f-table-title-cell>
                    <f-table-cell align="center" no-wrap>
                        {{ getVisualDate(row.date) }}
                    </f-table-cell>
                    <f-table-cell align="center">
                        <f-chip v-if="row.timeline.is_active" variant="success">Published</f-chip>
                        <f-chip v-else>Not published</f-chip>
                    </f-table-cell>
                    <f-table-cell>
                        <f-icon
                            v-if="row.timeline.is_pinned"
                            icon="pushpin"
                        />
                    </f-table-cell>
                </f-table-row>

                <template #post>
                    <f-pagination
                        v-if="fetchedData"
                        :current-page="currentPage"
                        :page-count="lastPage"
                        @navigate="onPaginationNavigate"
                        bottom
                    />
                </template>
            </f-table>

            <f-table-placeholder v-else-if="!fetchedData && loading"/>
        </f-container>

        <entity-action-button
            :permission="Permissions.ADMIN_ANNOUNCEMENTS"
            route="/admin/announcements/create"
        />
    </page>
</template>

<script>
import moment from 'moment';
import Page from '../../Page';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import LoadingMixin from '../../../../mixins/LoadingMixin';
import EntityActionButton from '../../../misc/entities/EntityActionButton';
import EntityPaginatedFilteredOverviewMixin from '../../../../mixins/entities/EntityPaginatedFilteredOverviewMixin';
import FContainer from '../../../../f-components/layout/container/FContainer';
import APIEndpoints from '../../../../config/APIEndpoints';
import FFilter from '../../../../f-components/data/filter/FFilter';
import FFilterRow from '../../../../f-components/data/filter/FFilterRow';
import FFilterItem from '../../../../f-components/data/filter/FFilterItem';
import FTextInput from '../../../../f-components/form-controls/text/FTextInput';
import FTable from '../../../../f-components/data/table/FTable';
import FPagination from '../../../../f-components/navigation/pagination/FPagination';
import FTableRow from '../../../../f-components/data/table/FTableRow';
import FTableCell from '../../../../f-components/data/table/FTableCell';
import FTablePlaceholder from '../../../../f-components/loaders/placeholder/FTablePlaceholder';
import AdminAnnouncementsMixin from './AdminAnnouncementsMixin';
import FImageLoader from '../../../../f-components/layout/image-loader/FImageLoader';
import FTableTitleCell from '../../../../f-components/data/table/FTableTitleCell';
import SubtitleSpacer from '../../../misc/SubtitleSpacer';
import FChip from '../../../../f-components/data/chip/FChip';
import FIcon from '../../../../f-components/layout/icons/FIcon';
import FTableHeaderRow from '../../../../f-components/data/table/FTableHeaderRow';
import FTableHeaderCell from '../../../../f-components/data/table/FTableHeaderCell';

export default {
    name: 'admin-announcements-page',

    mixins: [
        LoadingMixin,
        EntityPaginatedFilteredOverviewMixin,
        AdminAnnouncementsMixin,
    ],

    components: {
        FTableHeaderCell,
        FTableHeaderRow,
        FIcon,
        FChip,
        SubtitleSpacer,
        FTableTitleCell,
        FImageLoader,
        FTablePlaceholder,
        FTableCell,
        FTableRow,
        FPagination,
        FTable,
        FTextInput,
        FFilterItem,
        FFilterRow,
        FFilter,
        FContainer,
        EntityActionButton,
        FBreadcrumb,
        FBreadcrumbs,
        Page,
    },

    data() {
        return {
            searchQuery: null,
        };
    },

    watch: {
        searchQuery() {
            this.onFilterChange();
        },
    },

    methods: {
        getEndpoint() {
            return APIEndpoints.ADMIN_ANNOUNCEMENTS;
        },

        getFilters() {
            return {
                'filter[query]': this.searchQuery,
            };
        },

        applyPreviousFilters(filters) {
            if (filters['filter[query]']) this.searchQuery = filters['filter[query]'];
        },

        getVisualDate(date) {
            // date = moment(date);
            // if (date.diff(moment(), 'days') > 30) {
            //     return date.format('dddd, MMMM Do YYYY');
            // }
            // return date.fromNow();
            return moment(date).format('dddd, MMMM Do YYYY');
        },
    },
}
</script>

<style lang="scss">
.admin-announcements-page {
    .f-filter__container {
        max-width: 360px;
    }

    .f-image-loader {
        width: 60px;
        height: 60px;
        border-radius: 5px;
    }
}
</style>
