export const UserCardCompactVariants = {
    LIGHT: 'light',
    DARK: 'dark',
    SEMI_DARK: 'semi-dark',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    INFO: 'info',
    DANGER: 'danger',
    WARNING: 'warning',
    SUCCESS: 'success',
}

export const UserCardVariants = {
    LIGHT: 'light',
    DARK: 'dark',
}
