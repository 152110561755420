<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
    <f-theme-provider
        v-if="meetingRooms"
        class="calendar-slide"
        variant="dark"
    >
        <time-table
            :meeting-rooms="meetingRooms"
            :office="office"
            :clock="officeClock"
            :max-height="maxScrollHeight"
            ref="timeTable"
            show-header
        >
            <time-table-header-item
                v-if="(meetingRooms && meetingRooms.length > 0)"
                v-for="(meetingRoom, i) in meetingRooms"
                :key="i"
                :name="meetingRoom.name"
            >
                <span
                    v-if="meetingRoom.calendar"
                    class="state"
                    v-html="getMeetingRoomState(meetingRoom).label"
                />
            </time-table-header-item>
        </time-table>
    </f-theme-provider>
</template>

<script>
import MeetingRoomsMixin from "../../../../mixins/MeetingRoomsMixin";
import TimeTable from "../../../misc/time-table/TimeTable";
import TimeTableHeaderItem from "../../../misc/time-table/TimeTableHeaderItem";
import EventBus from '../../../../services/EventBus';
import FThemeProvider from '../../../../f-components/layout/theme/FThemeProvider';
import MeetingRoomsTodayMixin from '../../../../mixins/MeetingRoomsTodayMixin';

export default {
    name: 'calendar-slide',

    components: {
        FThemeProvider,
        TimeTableHeaderItem,
        TimeTable,
    },

    mixins: [
        MeetingRoomsMixin,
        MeetingRoomsTodayMixin,
    ],

    props: {
        office: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isFirstTimeUpdate: true,
            maxScrollHeight: '0px',
            filterMeetingRooms: (meetingRoom) => meetingRoom.name.toLowerCase() !== 'company car',
        }
    },

    mounted() {
        this.maxScrollHeight = (this.$el.clientHeight - 160) + 'px';
        EventBus.$on(`ds.meetingRooms:${this.currentDateString}.update`, this.forceUpdate);
    },

    methods: {
        forceUpdate() {
            this.$forceUpdate();
        },
    },
}
</script>

<style lang="scss">
.calendar-slide {
    padding: 30px;
    height: 100vh;
    overflow: hidden;
    background: $dark;

    .f-scroll-pane__content {
        padding-left: 40px;
    }

    .time-table {
        margin: 0;
    }

    .time-table__header-scroller {
        margin-bottom: 40px;

        .time-table__header__item__name {
            margin-top: 10px;
            font-size: 120%;
        }
    }

    .time-table__container .time-table__timeline-scroller {
        font-size: 120%;
    }
}

.meeting-room-tablet-page__content {
    .--slide-id-calendar {
        .f-carousel-slide__back {
            position: absolute;
            left: 37px;
            top: 28px;
            z-index: 2;
        }
    }
}
</style>
