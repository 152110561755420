<template>
    <page
        class-name="not-found-page"
        title="404 Not Found"
        header="<em>404</em> - not found"
        :authenticated="false"
        :skewed-header-foreground="null"
        skewed-footer-foreground="#f3f3f3"
    >
        <template #pre>
            <p class="not-found-page__body">
                We're sorry, the requested page could not be found.<br>
                Please go back to the homepage.
            </p>
        </template>
        <f-center>
            <div>
                <f-icon icon="sad"/>
            </div>
        </f-center>
    </page>
</template>

<script>
import Page from "./Page";
import FIcon from "../../f-components/layout/icons/FIcon";
import FCenter from '../../f-components/layout/center/FCenter';

export default {
    name: 'not-found-page',

    components: {
        FCenter,
        FIcon,
        Page,
    },
}
</script>

<style lang="scss">
.not-found-page {
    .f-icon {
        font-size: 200px;
    }

    &__body {
        margin-top: 25px;
    }
}
</style>
