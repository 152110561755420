<template>
    <div class="f-out-of-total">
        {{ index + 1 }}
        <subtitle-spacer/>
        {{ total }}
    </div>
</template>

<script>
import SubtitleSpacer from '../../../components/misc/SubtitleSpacer';

export default {
    name: 'f-out-of-total',

    components: {SubtitleSpacer},

    props: {
        total: Number,
        index: Number,
    },
}
</script>

<style lang="scss">
.f-out-of-total {
    border: 1px solid;
    padding: 4px 10px;
    border-radius: 999px;
    z-index: 3;
    display: inline-block;
    user-select: none;

    .--theme-light &,
    .--theme-dark .--theme-light & {
        border-color: $tertiaryGray;
    }

    .--theme-dark &,
    .--theme-light .--theme-dark & {
        border-color: rgba($light, .25);
    }
}
</style>
