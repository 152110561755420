var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f-dropdown',_vm._b({ref:"validatable",attrs:{"class-name":[
        'f-user-dropdown',
        ..._vm.classNames,
    ]},on:{"change":(value, user) => _vm.$emit('change', value, user),"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"popup-open":function($event){return _vm.$emit('popup-open')},"popup-close":function($event){return _vm.$emit('popup-close')},"search":function($event){_vm.searchQuery = $event}},scopedSlots:_vm._u([{key:"selectedOption",fn:function(selectedOptionProps){return [(_vm.mode === 'single' && selectedOptionProps.selectedOption)?_c('div',{staticClass:"f-dropdown__selected-option f-user-dropdown__selected-option --single"},[_c('entity-avatar',{attrs:{"name":selectedOptionProps.selectedOption.value,"src":selectedOptionProps.selectedOption.profile_image,"size":"xxsmall"}}),_vm._v(" "+_vm._s(selectedOptionProps.selectedOption.value)+" ")],1):_vm._e(),(_vm.mode === 'multi' && selectedOptionProps.selectedOption.length > 0)?_c('div',{staticClass:"f-dropdown__selected-option f-user-dropdown__selected-option --multi"},_vm._l((selectedOptionProps.selectedOption),function(selectedOption){return _c('entity-avatar',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ text: selectedOption.value }),expression:"{ text: selectedOption.value }"}],key:selectedOption.key,attrs:{"name":selectedOption.value,"src":selectedOption.profile_image,"size":"xxsmall"}})}),1):_vm._e()]}},{key:"options",fn:function(optionsProps){return _vm._l((optionsProps.options),function(option,index){return _c('li',{key:option.key,class:[
                'f-user-dropdown__option',
                (optionsProps.selectedIndex === index ? '--selected' : null),
                (_vm.mode === 'single' && optionsProps.selectedOption && _vm.value === option.key ? '--chosen' : null),
                (_vm.mode === 'multi' && (optionsProps.value ? optionsProps.value : []).includes(option.key) ? '--chosen' : null),
            ],on:{"click":function($event){$event.preventDefault();return optionsProps.onSelect(option, index)}}},[_c('div',{staticClass:"f-user-dropdown__option__image"},[_c('entity-avatar',{attrs:{"name":option.value,"src":option.profile_image,"size":"xxsmall","color":"dark","tooltip-options":_vm.tooltipOptions}})],1),_c('div',{staticClass:"f-user-dropdown__option__title"},[_vm._v(" "+_vm._s(option.value)+" "),_c('div',{staticClass:"f-user-dropdown__option__title__subtitle"},[_vm._v(" "+_vm._s(option.office ? option.office : 'Unknown')+" office ")])])])})}}]),model:{value:(_vm.dropdownValue),callback:function ($$v) {_vm.dropdownValue=$$v},expression:"dropdownValue"}},'f-dropdown',_vm.$props,false))
}
var staticRenderFns = []

export { render, staticRenderFns }