<template>
    <f-modal
        class-name="pronouns-modal"
        close-button
        width="480px"
        @close="$emit('close')"
        :title="['DE&I', 'Pronouns']"
        :loading="loading"
    >
        <p>
            We’re committed to building a more inclusive culture, and correct gender pronouns play an important role in
            creating a welcoming environment for members of all gender identities. Add your gender pronouns to your
            profile to let others know how to refer to you.
        </p>

        <p style="font-size: 11px; opacity: .5;">
            This is not obligatory
        </p>

        <f-form-row-spacer line/>

        <div style="margin-top: 20px;">
            <settings-pronouns-select
                label="My pronouns are"
                @change="onChange"
            />
            <!--            <setting-switch-->
            <!--                v-if="pronouns !== 'none'"-->
            <!--                :is-active="$store.getters.getUserSetting('global.pronouns_signature')"-->
            <!--                label="Include my pronouns in my email signature"-->
            <!--                setting-key="global.pronouns_signature"-->
            <!--            />-->
        </div>

        <f-inline-message
            v-if="finished"
            type="success"
            id="pronounsSuccess"
            :closable="false"
        >
            Thank you! You can now close this window.
        </f-inline-message>
    </f-modal>
</template>

<script>
import FModal from '../../../../f-components/other/modal/FModal';
import SettingsPronounsSelect from './SettingsPronounsSelect';
import FInlineMessage from '../../../../f-components/notice/inline-message/FInlineMessage';
import EventBus from '../../../../services/EventBus';
import {GlobalEvents} from '../../../../config/Events';
import FFormRowSpacer from '../../../../f-components/form-controls/forms/FFormRowSpacer';

export default {
    name: 'pronouns-modal',

    props: {
        active: Boolean,
    },

    components: {
        FFormRowSpacer,
        FInlineMessage,
        SettingsPronounsSelect,
        FModal,
    },

    data() {
        return {
            loading: false,
            pronouns: null,
            pronounsCustom: null,
        }
    },

    computed: {
        finished() {
            if (this.pronouns === 'custom') {
                return !!this.pronounsCustom;
            }
            return !!this.pronouns;
        },
    },

    mounted() {
        EventBus.$on('user.settings[global.pronouns].update', this.onSaveComplete);
        EventBus.$on('user.settings[global.custom_pronouns].update', this.onSaveComplete);
    },

    methods: {
        onChange([pronouns, pronounsCustom]) {
            this.pronouns = pronouns;
            this.pronounsCustom = pronounsCustom;

            if (pronouns === 'custom' && !pronounsCustom) {
                return;
            }

            this.loading = true;
            EventBus.$emit(GlobalEvents.SITE_LOAD_START);
        },

        onSaveComplete() {
            this.loading = false;
            EventBus.$emit(GlobalEvents.SITE_LOAD_FINISH);
        },
    },
}
</script>

<style lang="scss">
.pronouns-modal {
    .f-inline-message {
        margin-top: 20px;
    }
}
</style>
