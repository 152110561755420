<template>
    <f-abstract-input
        v-bind="boundProps"
        :class-name="[
            'f-radio-button',
            ...classNames,
            '--no-select'
        ]"
        :focussed="hasFocus"
        :input-error="inputError"
        show-error
    >
        <div class="f-radio-button__container">
            <input
                type="radio"
                ref="input"
                :id="uid"
                :name="name"
                :disabled="disabled"
                :readonly="readonly"
                :tabindex="tabIndex"
                :checked="value"
                @change="onChange"
                @focus="onFocus"
                @blur="onBlur"
            />
            <label
                :for="uid"
                class="f-radio-button__label"
            />
        </div>
        <label
            class="f-radio-button__visual-label"
            :for="uid"
        >
            <f-icon
                v-if="labelIcon"
                :icon="labelIcon"
            />
            {{ label }}
            <slot/>
        </label>

        <slot name="after"/>
    </f-abstract-input>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import FAbstractInput from '../base/FAbstractInput';
import AbstractInputPropsMixin from '../base/AbstractInputPropsMixin';
import AbstractInputValidationMixin from '../base/AbstractInputValidationMixin';
import AbstractInputEventsMixin from '../base/AbstractInputEventsMixin';
import AbstractInputMixin from '../base/AbstractInputMixin';
import FIcon from '../../layout/icons/FIcon';

export default {
    name: 'f-radio-button',

    components: {
        FIcon,
        FAbstractInput,
    },

    mixins: [
        AbstractInputPropsMixin,
        AbstractInputValidationMixin,
        AbstractInputEventsMixin,
        AbstractInputMixin,
    ],

    props: {
        checked: Boolean,
        label: String,
        name: {
            type: String,
            required: true,
        },
        value: {
            type: String | Number,
            required: true,
        },
        labelIcon: String,
    },

    methods: {
        onChange() {
            this.$emit('input', this.$refs.input.checked);
            this.$emit('change', this.$refs.input.checked);

            if (this.$refs.input.checked) {
                this.$emit('checked');
            }
        },

        getValue() {
            return this.isChecked();
        },

        check() {
            if (!this.isChecked()) {
                this.$refs.input.checked = true;
            }
        },

        uncheck() {
            if (this.isChecked()) {
                this.$refs.input.checked = false;
            }
        },

        toggle() {
            this.$refs.input.click();
        },

        isChecked() {
            return this.$refs.input.checked;
        },

        getExcludedProps() {
            return [
                'checked',
                'label',
                'labelIcon',
                'validation',
            ];
        },
    }
}
</script>

<style lang="scss">
.f-radio-button {
    $radius: 18px;
    position: relative;
    display: inline-flex;

    input {
        opacity: 0;
        position: absolute;
    }

    .f-radio-button__label {
        position: relative;
        width: $radius;
        height: $radius;
        border-radius: 50%;
        border: 1px solid rgba($light, .5);
        transition: border-color .3s;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
        left: 0;
        top: 0;

        &:before {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: $radius - 6px;
            height: $radius - 6px;
            border-radius: 50%;
            color: $primary;
            transition: all .1s ease-in-out;
        }

        &:hover {
            border-color: $light;

            &:before {
                color: $light;
            }
        }
    }

    // Checked
    input:checked + .f-radio-button__label {
        &::before {
            background-color: $primary;
        }

        &:hover {
            &::before {
                background-color: $primary;
            }
        }
    }

    // Focussed
    &.--focus {
        .f-radio-button__label {
            outline: 0;
            border-color: $light;

            @include theme(dark) {
                box-shadow: 0 0 0 2px rgba($light, .2);
            }

            @include theme(light) {
                box-shadow: 0 0 0 2px rgba($dark, .2);
            }
        }
    }

    // Visual label
    .f-radio-button__visual-label {
        display: inline-block;
        vertical-align: middle;
        padding: 0 8px;
        line-height: 140%;
        margin: 0;
    }

    // Variants
    @include theme(light) {
        .f-radio-button__label {
            border: 1px solid rgba($dark, .5);
            background: rgba($dark, .025);

            &:hover {
                border-color: $dark;

                &:before {
                    color: $dark;
                }
            }
        }

        .f-radio-button__visual-label {
            color: $dark;
        }
    }
}
</style>
