<template>
    <f-modal
        v-if="active"
        ref="modal"
        class-name="f-alert"
        :close-button="currentOptions.closeButton"
        @close="close"
        :title="currentOptions.title"
    >
        <f-header-body-footer max-content-height="280px" :has-header="false">
            <div
                class="f-alert__body"
                v-html="currentOptions.body"
            />
            <div
                v-if="currentOptions.textArea"
                class="f-alert__textarea"
            >
                <f-text-area
                    v-model="textValue"
                    :placeholder="currentOptions.textAreaPlaceholder"
                    name="text"
                />
            </div>

            <template #footer>
                <div>
                    <f-button
                        v-if="currentOptions.cancelButton"
                        type="button"
                        :value="currentOptions.cancelButtonText"
                        @click="cancel"
                        @touchstart="cancel"
                        outlined
                        variant="light"
                        :size="currentOptions.buttonSize"
                    />
                </div>
                <div>
                    <f-button
                        v-if="currentOptions.confirmButton"
                        type="button"
                        :value="currentOptions.confirmButtonText"
                        :variant="currentOptions.confirmButtonVariant"
                        @click="confirm"
                        @touchstart="confirm"
                        :size="currentOptions.buttonSize"
                    />
                </div>
            </template>
        </f-header-body-footer>
    </f-modal>
</template>

<script>
import FModal from "../../other/modal/FModal";
import FButton from "../../form-controls/buttons/FButton";
import FTextArea from "../../form-controls/text/FTextArea";
import FHeaderBodyFooter from "../../layout/header-body-footer/FHeaderBodyFooter";
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import {ButtonSizes, ButtonVariants} from '../../form-controls/buttons/config';

export default {
    name: 'f-alert',

    props: {
        options: {
            type: Object,
        },
    },

    components: {
        FHeaderBodyFooter,
        FTextArea,
        FButton,
        FModal,
    },

    data() {
        return {
            defaultOptions: {
                body: null,
                title: null,
                autoClose: 0, // In seconds
                titleIcon: null,
                cancelButton: false,
                textArea: false,
                textAreaPlaceholder: 'Text',
                confirmButton: true,
                confirmButtonVariant: ButtonVariants.PRIMARY,
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                onConfirm: () => true,
                once: false,
                height: '260px',
                closeButton: true,
                buttonSize: ButtonSizes.MEDIUM,
            },
            currentOptions: {},
            textValue: null,
            autoCloseTimeout: null,
            active: false,
        }
    },

    created() {
        EventBus.$on(GlobalEvents.ALERT_SHOW, (options) => this.show(options));
    },

    methods: {
        show(options) {
            this.setOptions(options);
            this.open();
        },

        open() {
            if (this.autoCloseTimeout) {
                window.clearTimeout(this.autoCloseTimeout);
            }

            this.active = true;

            if (this.currentOptions.autoClose > 0) {
                this.autoCloseTimeout = window.setTimeout(() => this.close(), this.currentOptions.autoClose * 1000);
            }
        },

        close() {
            if (this.autoCloseTimeout) {
                window.clearTimeout(this.autoCloseTimeout);
            }

            this.active = false;
        },

        confirm() {
            if (this.currentOptions.onConfirm) {
                this.currentOptions.onConfirm(this.textValue);
            }
            this.$emit('confirm');
            this.close();
        },

        cancel() {
            this.close();
        },

        setOptions(options) {
            this.currentOptions = {
                ...this.defaultOptions,
                ...this.options, // prop
                ...options,
            };
        },
    },
}
</script>

<style lang="scss">
.f-alert {
    z-index: $alertOrder;

    .f-panel {
        max-width: 96%;
        width: 500px;
        text-align: center;

        &__content {
            padding: 20px !important;
        }
    }

    .f-header-body-footer {
        section {
            margin: 20px 0;
        }

        footer {
            display: flex;

            > div {
                flex: 1;
                text-align: left;

                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}
</style>
