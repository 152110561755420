export const TableCellAlignments = {
    TOP: 'top',
    MIDDLE: 'middle',
    BOTTOM: 'bottom',
}

export const TableTypes = {
    DEFAULT: 'default',
    DEFAULT_DENSE: 'default-dense',
    SIMPLE: 'simple',
    SIMPLE_DENSE: 'simple-dense',
}
