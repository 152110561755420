<template>
    <div :class="[
        'log-body',
        (hasOverflow ? '--overflow' : null),
        (open ? '--open' : null),
    ]">
        <div
            ref="content"
            :class="['log-body__content']"
            v-html="body"
        />
        <f-icon-button
            v-if="hasOverflow"
            :icon="(open ? 'chevron-up' : 'chevron-down')"
            size="xsmall"
            @click="open = !open"
            outlined
            no-parent-hover
        />
    </div>
</template>

<script>
import FIconButton from '../../../../../f-components/form-controls/buttons/FIconButton';

export default {
    name: 'log-body',

    components: {
        FIconButton,
    },

    props: {
        body: String,
    },

    data() {
        return {
            hasOverflow: false,
            open: false,
        }
    },

    mounted() {
        this.$nextTick(this.checkOverflow);
    },

    methods: {
        checkOverflow() {
            if (
                this.$refs.content.offsetHeight < this.$refs.content.scrollHeight ||
                this.$refs.content.offsetWidth < this.$refs.content.scrollWidth
            ) {
                this.hasOverflow = true;
            }
        },
    },
}
</script>

<style lang="scss">
.log-body {
    position: relative;

    &__content {
        font-family: Consolas, monospace;
        font-size: 90%;

        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

        max-height: 50px;
        overflow: hidden;
    }

    .f-icon-button {
        position: absolute;
        top: 0;
        right: 0;
    }

    &.--open {
        .log-body__content {
            max-height: inherit;
        }
    }

    &.--overflow {
        .log-body__content {
            padding-right: 30px;
        }
    }
}
</style>
