import AbstractInputPropsMixin from './AbstractInputPropsMixin';

export default {
    mixins: [
        AbstractInputPropsMixin,
    ],

    props: {
        autoFocus: Boolean,
        placeholder: String | Number,
        preIcon: String,
        postIcon: String,
        maxWidth: String,
        width: String,
        align: String,
        resetable: Boolean,
        errorAlign: {
            type: String,
            default: 'right',
        },
    },
};
