<template>
    <f-portal reference="popup">
        <transition name="popup" appear>
            <div
                :class="[
                    'f-popup',
                    ...classNames,
                    '--no-select',
                    `--${currentAlign}`,
                    `--hor-${currentAlignHorizontal}`,
                    `--${variant}`,
                    `--theme-${theme}`,
                    (wrap ? '--wrap' : undefined),
                ]"
                :style="{
                    width: width,
                    top: `${pos.top}px`,
                    left: `${pos.left}px`,
                    maxHeight,
                    padding,
                }"
                @mouseenter="$emit('mouseenter')"
                @mouseleave="$emit('mouseleave')"
                ref="positionElement"
            >
                <slot/>
            </div>
        </transition>
    </f-portal>
</template>

<script>
import PositionMixin from '../../../mixins/position/PositionMixin';
import EventBus from '../../../services/EventBus';
import PositionPropsMixin from '../../../mixins/position/PositionPropsMixin';
import FPortal from '../portal/FPortal';
import {GlobalEvents} from '../../../config/Events';
import {PopupVariants} from './config';
import {ThemeVariants} from '../../layout/theme/config';
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-popup',

    components: {
        FPortal,
    },

    mixins: [
        PositionPropsMixin,
        PositionMixin,
        ClassNameMixin,
    ],

    props: {
        maxHeight: String,
        padding: String,
        wrap: Boolean,
        width: {
            type: String,
            default: 'auto',
        },
        variant: {
            type: String,
            default: PopupVariants.DARK,
        },
    },

    computed: {
        theme() {
            switch (this.variant) {
                case PopupVariants.DARK:
                    return ThemeVariants.DARK;
                default:
                    return ThemeVariants.LIGHT;
            }
        },
    },

    mounted() {
        EventBus.$on(GlobalEvents.KEYDOWN_ESC, this.onEsc)
        document.addEventListener('mousedown', this.onDocumentMouseDown);
    },

    beforeDestroy() {
        EventBus.$off(GlobalEvents.KEYDOWN_ESC, this.onEsc)
        document.removeEventListener('mousedown', this.onDocumentMouseDown);
    },

    methods: {
        onEsc(e) {
            this.$emit('close', e);
            EventBus.$emit(GlobalEvents.USER_CARD_HIDE);
        },

        onDocumentMouseDown(e) {
            if (this.active && !this.target.contains(e.target) && !this.$refs.positionElement.contains(e.target)) {
                this.$emit('close', e);
                EventBus.$emit(GlobalEvents.USER_CARD_HIDE);
            }
        },
    },
}
</script>

<style lang="scss">
.f-popup {
    position: fixed;
    padding: 7px;
    font-size: 12px;
    border-radius: 10px;
    z-index: $popupOrder;
    line-height: 100%;
    white-space: nowrap;
    box-shadow: rgba($dark, .15) 0 0 8px;
    text-overflow: ellipsis;
    min-height: 28px;
    max-width: 500px;
    min-width: 40px;

    &.--wrap {
        white-space: normal;
    }

    // -- Variants

    &.--light, &.--default {
        background: $light;

        &, *, a {
            color: $lightFront;
        }
    }

    &.--dark {
        background: $dark;

        &, *, a {
            color: $darkFront;
        }
    }

    &.--primary, &.--alternative {
        background: $primary;

        &, *, a {
            color: $primaryFront;
        }

        .f-content {
            ul {
                li::before {
                    color: $dark;
                }
            }
        }
    }

    &.--secondary {
        &, *, a {
            color: #fff;
        }

        background: $secondary;
    }

    @include arrow-pointer-themes(#fff, (
        "--light": $light,
        "--default": $light,
        "--dark": $dark,
        "--primary": $primary,
        "--secondary": $secondary,
        "--alternative": $primary,
    ));
}
</style>
