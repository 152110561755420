<template>
    <div class="project-codes">
        <f-chip-group v-if="main">
            <f-chip v-if="main.type === 'quote'" variant="warning" :size="size">
                <span v-if="supplemental.length > 0">main</span> quote
            </f-chip>
            <f-chip v-else variant="status" :size="size">
                <span v-if="supplemental.length > 0">main</span> assignment
            </f-chip>
            <f-chip variant="dark" :size="size" code>
                {{ main.number }}
            </f-chip>
            <f-chip
                v-if="main.harvest"
                class-name="--divider"
                variant="dark"
                :size="size"
                v-tooltip="{text: `Harvest project linked for ${main.number}`, variant: 'dark'}"
            >
                <img src="/img/harvest.png" alt="Harvest"/>
            </f-chip>
        </f-chip-group>
        <f-chip-group v-for="project in supplemental" :key="project.number">
            <f-chip v-if="project.type === 'quote'" variant="warning" :size="size">quote</f-chip>
            <f-chip v-else variant="status" :size="size">assignment</f-chip>
            <f-chip variant="dark" :size="size" code>
                {{ project.number }}
            </f-chip>
            <f-chip
                v-if="project.harvest"
                class-name="--divider"
                variant="dark"
                :size="size"
                v-tooltip="{text: `Harvest project linked for ${project.number}`, variant: 'dark'}"
            >
                <img src="/img/harvest.png" alt="Harvest"/>
            </f-chip>
        </f-chip-group>
    </div>
</template>

<script>
import FChipGroup from '../../../../f-components/data/chip/FChipGroup';
import FChip from '../../../../f-components/data/chip/FChip';
import {ChipSizes} from '../../../../f-components/data/chip/config';

export default {
    name: 'project-codes',

    components: {
        FChip,
        FChipGroup,
    },

    props: {
        project: {
            type: Object,
            required: true,
        },
        size: {
            type: String,
            default: ChipSizes.SMALL,
        },
    },

    computed: {
        main() {
            for (let key in this.project.supplemental_data.external_projects) {
                if (this.project.supplemental_data.external_projects[key].main) {
                    return {
                        number: key,
                        ...this.project.supplemental_data.external_projects[key]
                    };
                }
            }

            return null;
        },

        supplemental() {
            const supplemental = [];

            for (let key in this.project.supplemental_data.external_projects) {
                if (this.project.supplemental_data.external_projects[key].supplemental) {
                    supplemental.push({
                        number: key,
                        ...this.project.supplemental_data.external_projects[key]
                    });
                }
            }

            return supplemental;
        },
    },
}
</script>

<style lang="scss">
.project-codes {
    display: inline;

    .--divider {
        border-left: 1px solid rgba(white, .25);
    }
}
</style>
