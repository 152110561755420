<template>
    <f-abstract-input-field
        v-bind="boundProps"
        :class-name="[
            'f-date-picker',
            ...classNames,
            `--mode-${mode}`,
        ]"
        :focussed="hasFocus"
        :has-content="!!value"
        :input-error="inputError"
        :show-error="showError"
        hide-reset-when-empty
        @reset="reset"
        @click="focus"
        ref="field"
    >
        <template #pre>
            <f-icon
                v-if="preIcon"
                :icon="preIcon"
            />
        </template>

        <template v-slot:default>
            <button
                class="f-date-picker__focus"
                ref="input"
                type="button"
                :disabled="disabled || readonly"
                :tabindex="tabIndex"
                @focus="onFocus"
                @blur="onBlur"
            />
            <div
                v-if="!value"
                class="f-date-picker__placeholder --placeholder --no-select"
            >
                {{ placeholder }}
            </div>
            <div
                class="f-date-picker__value --no-select"
                v-else
            >
                {{ valueLabel }}
            </div>
        </template>

        <template #post>
            <f-icon-button
                v-if="!readonly && !disabled"
                icon="chevron-down"
                outlined
                :variant="(inputError ? 'danger' : 'themed')"
                size="xsmall"
                class-name="f-date-picker__action"
                tabindex="-1"
            />
        </template>

        <template #after>
            <f-popup
                v-if="(popupOpen && !disabled && !readonly)"
                :target="$refs.field.$el"
                @close="closePopup"
                ref="popup"
                class-name="f-date-picker-popup"
                :variant="popupVariant"
                padding="0"
                align="bottom"
            >
                <f-date-picker-select
                    :value="value"
                    :show-week-numbers="showWeekNumbers"
                    :allow-next="mode === 'single'"
                    :trigger-show-change-at-mount="mode !== 'single'"
                    :mode="mode"
                    :hide-weekends="hideWeekends"
                    :disable-month-and-year-picker="disableMonthAndYearPicker"
                    :apply="apply"
                    :type="type"
                    :year-range="yearRange"
                    @picked="onDatePick"
                    @position="$refs.popup.position()"
                    :variant="popupVariant"
                />
            </f-popup>
        </template>
    </f-abstract-input-field>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor, vue/return-in-computed-property, no-case-declarations */
import moment from 'moment';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import {DatePickerModes, DatePickerSelectVariants, DatePickerTypes} from './config';
import {Icons} from '../../layout/icons/config';
import FPopup from '../../other/popup/FPopup';
import FDatePickerSelect from './FDatePickerSelect';
import FIconButton from '../buttons/FIconButton';
import AbstractInputFieldPropsMixin from '../base/AbstractInputFieldPropsMixin';
import AbstractInputValidationMixin from '../base/AbstractInputValidationMixin';
import AbstractInputEventsMixin from '../base/AbstractInputEventsMixin';
import AbstractInputMixin from '../base/AbstractInputMixin';
import FAbstractInputField from '../base/FAbstractInputField';
import FIcon from '../../layout/icons/FIcon';

export default {
    name: 'f-date-picker',

    components: {
        FIcon,
        FAbstractInputField,
        FIconButton,
        FDatePickerSelect,
        FPopup,
    },

    mixins: [
        AbstractInputFieldPropsMixin,
        AbstractInputValidationMixin,
        AbstractInputEventsMixin,
        AbstractInputMixin,
    ],

    props: {
        value: Array | String | Object | null | undefined,
        showWeekNumbers: {
            type: Boolean,
            default: true,
        },
        yearRange: {
            type: Array,
            default: () => [1900, 2100],
        },
        visualFormat: {
            type: String,
            default: 'DD MMM YYYY',
        },
        dateFormat: {
            type: String,
            default: 'YYYY-MM-DD',
        },
        mode: {
            type: String,
            default: DatePickerModes.SINGLE, // @todo Test if still works, only using single atm
        },
        type: {
            type: String,
            default: DatePickerTypes.DAY,
        },
        hideWeekends: Boolean,
        disableMonthAndYearPicker: Boolean,
        apply: Function,
        preIcon: {
            type: String,
            default: Icons.CALENDAR_31,
        },
        popupVariant: {
            type: String,
            default: DatePickerSelectVariants.DARK,
        },
    },

    data() {
        return {
            popupOpen: false,
        }
    },

    computed: {
        valueLabel() {
            switch (this.mode) {
                case DatePickerModes.SINGLE:
                    return (this.value ? moment(this.value).format(this.visualFormat) : '');
                case DatePickerModes.MULTI:
                    return this.value.map((d) => moment(d).format(this.visualFormat)).join(', ');
                case DatePickerModes.RANGE:
                    return this.value.map((d) => (d ? moment(d).format(this.visualFormat) : '')).join(' – ');
                default:
                    console.error(`No date picker mode called '${this.mode}'`);
            }
        },
    },

    mounted() {
        this.registerListeners();
    },

    beforeDestroy() {
        this.removeListeners();
    },

    methods: {
        registerListeners() {
            this.$on('focus', this.openPopup);
            EventBus.$on(GlobalEvents.INPUT_FOCUS, this.onGlobalInputFocus);
            EventBus.$on(GlobalEvents.OVERFLOW_SCROLL, this.closePopup);
        },

        removeListeners() {
            this.$off('focus', this.openPopup);
            EventBus.$off(GlobalEvents.INPUT_FOCUS, this.onGlobalInputFocus);
            EventBus.$off(GlobalEvents.OVERFLOW_SCROLL, this.closePopup);
        },

        onGlobalInputFocus(uid) {
            if (this.uid !== uid && this.popupOpen) {
                this.closePopup();
            }
        },

        getValue() {
            return this.value;
        },

        reset() {
            this.$emit('input', null);
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },

        openPopup() {
            EventBus.$emit(GlobalEvents.USER_CARD_HIDE);
            this.popupOpen = true;
        },

        closePopup() {
            this.popupOpen = false;
        },

        onDatePick(date, type) {
            let value = this.value;

            switch (this.mode) {
                case DatePickerModes.SINGLE:
                    this.$emit('input', date.format(this.dateFormat));
                    if (type !== 'year') {
                        this.closePopup();
                    }
                    break;

                case DatePickerModes.MULTI:
                    if (!Array.isArray(value)) {
                        value = (value ? [value] : []);
                    }
                    if (value.includes(date.format(this.dateFormat))) {
                        value = value.filter((value) => value && date.format(this.dateFormat) !== moment(value).format(this.dateFormat));
                    } else {
                        value.push(date.format(this.dateFormat));
                    }

                    this.$emit('input', value);
                    break;

                case DatePickerModes.RANGE:
                    if (!Array.isArray(value)) {
                        value = (value ? [value] : []);
                    }

                    if (value.includes(date.format(this.dateFormat))) {
                        return;
                    }

                    let left = (value[0] ? moment(value[0]) : null),
                        right = (value[1] ? moment(value[1]) : null);

                    if (!left) {
                        left = date;
                    } else if (!right) {
                        right = date;
                    } else if (Math.abs(left.diff(date, 'days')) < Math.abs(right.diff(date, 'days'))) {
                        left = date;
                    } else {
                        right = date;
                    }

                    if (left && right && left.isAfter(right)) {
                        this.$emit('input', [right.format(this.dateFormat), left.format(this.dateFormat)]);
                    } else {
                        this.$emit('input', [(left ? left.format(this.dateFormat) : null), (right ? right.format(this.dateFormat) : null)]);
                    }

                    break;

                default:
                    console.error(`No date picker mode called '${this.mode}'`);
            }
        },

        getExcludedProps() {
            return [
                'placeholder',
                'showWeekNumbers',
                'yearRange',
                'visualFormat',
                'dateFormat',
                'mode',
                'type',
                'hideWeekends',
                'disableMonthAndYearPicker',
                'apply',
                'validation',
            ];
        },
    },
}
</script>

<style lang="scss">
.f-date-picker {
    &__focus {
        position: absolute;
        opacity: 0;
    }

    &__placeholder {
        padding: 0 10px;
    }

    &__value {
        padding: 0 10px;
    }
}

.f-date-picker-popup {
    &.--bottom {
        &:before {
            border-bottom-color: $primary !important;
        }
    }
}
</style>
