import APIDataStore from '../services/APIDataStore';
import APIDataStoreResources from '../config/APIDataStoreResources';

/**
 * Methods for handling meeting room today data.
 */
export default {
    created() {
        APIDataStore.check(APIDataStoreResources.MEETINGS_ROOMS_TODAY, {
            params: {
                office: this.currentOffice,
            },
        });
    },

    computed: {
        meetingRooms() {
            const key = this.currentOffice ? `$meetingRoomsToday${this.currentOffice.ucfirst()}` : '$meetingRoomsTodayAmsterdam';
            let meetingRooms = (this.$store.state[key] ? this.$store.state[key] : []);
            if (this.filterMeetingRooms) {
                meetingRooms = meetingRooms.filter(this.filterMeetingRooms);
            }
            return meetingRooms;
        },
    },
};
