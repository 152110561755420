<template>
    <widget
        :class-name="['bank-holidays-widget', widgetSize]"
        :header="(currentOffice ? currentOffice.replace('_', ' ').ucwords() : null)"
        title="Bank holidays"
        title-help="Days the office is closed, and all employees of that office have a day off because of a national holiday"
    >
        <f-carousel
            ref="carousel"
            auto-size
            navigation-type="prev-next"
            @slideChange="onSlideChange"
            swipe
        >
            <f-carousel-slide
                v-for="(events, office) in bankHolidays"
                :id="office.slugify()"
                :key="office"
                :title="office"
            >
                <ul class="bank-holidays-widget__holidays">
                    <li
                        v-for="event in events"
                        :key="event.id"
                        :class="[(event.start.isBefore() && event.start.format('DDMMYY') !== today ? '--past' : null)]"
                    >
                        <f-flex gap="10px" main-axis-align="space-between">
                            <f-flex-item class-name="bank-holidays-widget__holidays__day">
                                {{ event.start.format('ddd, MMM Do YYYY') }}
                            </f-flex-item>
                            <f-flex-item class-name="--align-right">
                                {{ event.name }}
                            </f-flex-item>
                        </f-flex>
                    </li>
                </ul>
            </f-carousel-slide>
        </f-carousel>
    </widget>
</template>

<script>
import Widget from '../../../widgets/Widget';
import FCarousel from '../../../../f-components/navigation/carousels/FCarousel';
import FCarouselSlide from '../../../../f-components/navigation/carousels/FCarouselSlide';
import WidgetSizeMixin from '../../../widgets/WidgetSizeMixin';
import APIDataStore from '../../../../services/APIDataStore';
import APIDataStoreResources from '../../../../config/APIDataStoreResources';
import moment from 'moment-timezone';
import FFlex from '../../../../f-components/layout/flex/FFlex';
import FFlexItem from '../../../../f-components/layout/flex/FFlexItem';

export default {
    name: 'bank-holidays-widget',

    components: {
        FFlexItem,
        FFlex,
        FCarouselSlide,
        FCarousel,
        Widget,
    },

    mixins: [
        WidgetSizeMixin,
    ],

    computed: {
        bankHolidays() {
            if (!this.$store.state.$publicHolidays) return {};

            const bankHolidays = {amsterdam: [], barcelona: [], austin: [], dubai: []};
            for (let i in this.$store.state.$publicHolidays) {
                if (!this.$store.state.$publicHolidays[i].start || this.$store.state.$publicHolidays[i].start.date) {
                    continue;
                }

                for (let y in this.$store.state.$publicHolidays[i].offices) {
                    const office = this.$store.state.$publicHolidays[i].offices[y];

                    const event = {...this.$store.state.$publicHolidays[i]};
                    event.start = moment(event.start);

                    if (!event.bank_holiday) continue;
                    if (!bankHolidays[office]) bankHolidays[office] = [];

                    bankHolidays[office].push(event);
                }
            }

            return bankHolidays;
        },
    },

    data() {
        return {
            currentOffice: null,
            today: moment().format('DDMMYY'),
        }
    },

    watch: {
        bankHolidays() {
            this.$nextTick(() => this.invalidate());
        },
    },

    created() {
        APIDataStore.check(APIDataStoreResources.PUBLIC_HOLIDAYS);
    },

    methods: {
        invalidate() {
            if (this.$refs.carousel) {
                this.$refs.carousel.invalidate();
            }
        },
        onSlideChange(office) {
            this.currentOffice = office;
        },
    },
}
</script>

<style lang="scss">
.bank-holidays-widget {
    .f-panel__content {
        padding-top: 15px;
    }

    &__holidays {
        > li {
            border: 1px solid transparent;
            border-top-color: $softGray;
            padding: 15px;

            &:first-child {
                border: 0;
            }
        }

        .--past .f-flex {
            opacity: .5;
        }

        &__day {
            font-weight: 600;
            padding-top: 1px;
        }
    }
}
</style>
