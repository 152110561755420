<template>
    <f-form
        ref="form"
        class="project-order-lines-form"
        @submitValid="onFormSubmitValid"
        :loading="loading"
        validate
    >
        <f-inline-message
            v-if="externalProjectKeys.length === 0"
            id="projectLinesNoKeys"
            variant="warning"
            :closable="false"
        >
            No external Gripp project linked to configure order lines.
        </f-inline-message>

        <div class="project-order-lines-form__markings">
            <f-scroll-pane
                v-if="dataLoaded"
                :max-height="maxScrollerHeight"
            >
                <project-lines-marking-field
                    :lines="lines"
                    :lines-configuration="lines_configuration"
                    @change="(l) => this.lines_configuration = l"
                />
            </f-scroll-pane>

            <f-placeholder-container v-if="!dataLoaded && externalProjectKeys.length !== 0">
                <f-placeholder height="30px" width="100%"/>
                <f-placeholder height="30px" width="100%"/>
                <f-placeholder height="30px" width="100%"/>
                <f-placeholder height="30px" width="100%"/>
                <f-placeholder height="30px" width="100%"/>
                <f-placeholder height="30px" width="100%"/>
            </f-placeholder-container>

            <f-inline-message
                v-if="dataLoaded && lines && lines.length === 0"
                id="noLinesFound"
                :closable="false"
            >
                No lines found.
            </f-inline-message>
        </div>

        <f-form-row-spacer/>

        <template #footer>
            <f-button
                v-if="dataLoaded && externalProjectKeys.length !== 0 && lines !== null && lines.length !== 0"
                value="Update"
                type="submit"
                icon="floppy-disk"
                variant="primary"
            />
        </template>
    </f-form>
</template>

<script>
import FForm from '../../../../f-components/form-controls/forms/FForm';
import FFormRowSpacer from '../../../../f-components/form-controls/forms/FFormRowSpacer';
import FButton from '../../../../f-components/form-controls/buttons/FButton';
import APIEndpoints from '../../../../config/APIEndpoints';
import FPlaceholderContainer from '../../../../f-components/loaders/placeholder/FPlaceholderContainer';
import FPlaceholder from '../../../../f-components/loaders/placeholder/FPlaceholder';
import FInlineMessage from '../../../../f-components/notice/inline-message/FInlineMessage';
import ProjectLinesMarkingField from "../../../misc/project-analysis/ProjectLinesMarkingField";
import FScrollPane from "../../../../f-components/other/scroll-pane/FScrollPane";
import EventBus from '../../../../services/EventBus';
import {GlobalEvents} from '../../../../config/Events';
import LoadingMixin from '../../../../mixins/LoadingMixin';
import {PushMessageTypes} from '../../../../f-components/notice/push-message/config';

export default {
    name: 'project-order-lines-form',

    mixins: [
        LoadingMixin,
    ],

    components: {
        FScrollPane,
        ProjectLinesMarkingField,
        FInlineMessage,
        FPlaceholder,
        FPlaceholderContainer,
        FButton,
        FFormRowSpacer,
        FForm,
    },

    props: {
        externalProjectKeys: {
            type: Array,
            required: true,
        },
        endpoint: {
            type: String,
            default: APIEndpoints.PROJECT_LINES,
        },
        maxScrollerHeight: String,
        defaultData: Object,
    },

    data() {
        return {
            lines: null,
            lines_configuration: (this.defaultData.supplemental_data && this.defaultData.supplemental_data.lines_configuration ?
                this.defaultData.supplemental_data.lines_configuration : []),
        }
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            if (this.externalProjectKeys.length === 0) {
                return;
            }

            this.startLoading();

            window.axios['get'](this.endpoint, {
                params: {
                    projects: this.externalProjectKeys,
                },
            })
                .then(response => {
                    this.endLoading(true);

                    if (response.data.data) {
                        this.lines = response.data.data;
                    }
                    if (response.data.errors.length > 0) {
                        for (let i in response.data.errors) {
                            if (Object.prototype.hasOwnProperty.call(response.data.errors, i)) {
                                EventBus.$emit(GlobalEvents.PUSH_MESSAGE, response.data.errors[i], PushMessageTypes.ERROR);
                            }
                        }
                    }
                })
                .catch(() => this.endLoading());
        },

        onFormSubmitValid() {
            this.$emit('formValidAndSubmit', {
                lines_configuration: this.lines_configuration,
            });
        },
    },
}
</script>

<style lang="scss">
.project-order-lines-form {
    text-align: left;

    &__markings {
        margin: auto;

        .f-scroll-pane {
            margin-right: -10px;
        }
    }
}
</style>
