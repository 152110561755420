import './bootstrap';
import Vue from 'vue';
import store from './services/Store';
import router from './router';
import Riviera from './components/Riviera';

new Vue({
    store,
    router,
    el: '#app',
    render: app => app(Riviera),
});
