import ErrorHandler from "../../services/ErrorHandler";
import LoadingMixin from '../LoadingMixin';

export default {
    mixins: [
        LoadingMixin,
    ],

    data() {
        return {
            entityName: 'entity',
            fetchedData: null,
            fields: null,
        }
    },

    mounted() {
        this.$on('refresh', this.refresh);
        this.loadFromParam();
    },

    methods: {
        getBaseUrl() {
            throw new Error('Implement getBaseUrl');
        },

        getRedirectPath() {
            throw new Error('Implement getRedirectPath');
        },

        refresh() {
            this.loadFromParam();
        },

        loadFromParam() {
            this.startLoading();

            window.axios
                .get(`${this.getBaseUrl()}/${this.$route.params.id}`)
                .then(response => {
                    this.parseResponseData(response.data);
                    this.endLoading(true);
                })
                .catch(error => {
                    this.endLoading();
                    ErrorHandler.onGeneralError(error);
                });
        },

        parseResponseData(data) {
            this.fetchedData = data;
            this.fields = data.data;

            this.postParseResponseData(data);
        },

        postParseResponseData(data) {
            data;
        },
    }
}
