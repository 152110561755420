<template>
    <f-abstract-input
        v-bind="boundProps"
        :class-name="[
            'f-images-input',
            ...classNames,
        ]"
        :focussed="hasFocus"
        :input-error="inputError"
        show-error
        :style="{ width }"
    >
        <div
            class="f-images-input__container"
            @dragover="onDragOver"
            @dragleave="onDragLeave"
            @drop="onDrop"
            @click="focus"
            :style="{ height }"
        >
            Drop files
            <f-icon
                v-if="!readonly"
                :icon="icon"
            />
            <input
                ref="input"
                type="file"
                class="f-images-input__field"
                multiple
                :name="name"
                :accept="(accept ? accept.join(',') : null)"
                @change="onFileSelect"
                @focus="onFocus"
                @blur="onBlur"
            />
        </div>

        <f-scroll-pane ref="scroll" max-height="600px" variant="light">
            <f-table
                v-if="files && files.length > 0"
                class-name="f-images-input__table"
                type="simple-dense"
                ref="table"
            >
                <f-table-row
                    v-for="(file, i) in files"
                    :key="file.id"
                    :class-name="`--${file.status}`"
                >
                    <f-table-cell width="40px" no-padding-right>
                        <div
                            class="f-images-input__table__image"
                            :style="{ backgroundImage: (file.src && file.status !== 'error' ? `url(${file.src})` : undefined) }"
                        />
                    </f-table-cell>
                    <f-table-title-cell has-body>
                        {{ file.name }}
                        <template #sub>
                            {{ file.size }}
                        </template>
                        <template #body>
                            <f-inline-message
                                v-if="file._error"
                                id="uploadError"
                                :closable="false"
                                variant="warning"
                                type="dense"
                            >
                                {{ file._error }}
                            </f-inline-message>

                            <f-progress
                                v-else-if="!file.exists"
                                max-width="200px"
                                :progress="file.progress"
                            />
                        </template>
                    </f-table-title-cell>
                    <f-table-cell align="right">
                        <f-chip
                            v-if="!file.exists"
                            :variant="getChipVariantByStatus(file.status)">
                            {{ file.status }}
                        </f-chip>
                    </f-table-cell>
                    <f-table-cell align="right" width="20px" no-padding-left>
                        <f-icon-button
                            size="small"
                            :icon="(file.status === 'deleted' ? 'undo' : 'trash2')"
                            @click="deleteFile(i)"
                            outlined
                            no-parent-hover
                            v-tooltip="(file.status === 'deleted' ? 'Undo' : 'Delete')"
                        />
                    </f-table-cell>
                </f-table-row>
            </f-table>
        </f-scroll-pane>

        <div v-if="uploadButton" class="f-images-input__buttons">
            <f-button
                value="Upload"
                variant="primary"
                :disabled="queueCount <= 0"
                @click="upload()"
            />
        </div>
    </f-abstract-input>
</template>

<script>
/* eslint-disable no-unused-vars */
import {Icons} from '../../layout/icons/config';
import FAbstractInput from '../base/FAbstractInput';
import AbstractInputPropsMixin from '../base/AbstractInputPropsMixin';
import AbstractInputValidationMixin from '../base/AbstractInputValidationMixin';
import AbstractInputEventsMixin from '../base/AbstractInputEventsMixin';
import AbstractInputMixin from '../base/AbstractInputMixin';
import FIcon from '../../layout/icons/FIcon';
import {humanReadableFileSize} from '../../../utils/fileSizes';
import FTable from '../../data/table/FTable';
import FTableRow from '../../data/table/FTableRow';
import {generateUid} from '../../../utils/generateUid';
import FTableCell from '../../data/table/FTableCell';
import FTableTitleCell from '../../data/table/FTableTitleCell';
import FChip from '../../data/chip/FChip';
import FInlineMessage from '../../notice/inline-message/FInlineMessage';
import FIconButton from '../buttons/FIconButton';
import {FileStatus} from './config';
import {ChipVariants} from '../../data/chip/config';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import APIEndpoints from '../../../config/APIEndpoints';
import ErrorHandler from '../../../services/ErrorHandler';
import LoadingMixin from '../../../mixins/LoadingMixin';
import FProgress from '../../loaders/progress/FProgress';
import FButton from '../buttons/FButton';
import FScrollPane from '../../other/scroll-pane/FScrollPane';

export default {
    name: 'f-images-input',

    components: {
        FScrollPane,
        FButton,
        FProgress,
        FIconButton,
        FInlineMessage,
        FChip,
        FTableTitleCell,
        FTableCell,
        FTableRow,
        FTable,
        FIcon,
        FAbstractInput,
    },

    mixins: [
        AbstractInputPropsMixin,
        AbstractInputValidationMixin,
        AbstractInputEventsMixin,
        AbstractInputMixin,
        LoadingMixin,
    ],

    props: {
        value: Array,
        uploadEndpoint: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            default: '100%',
        },
        height: {
            type: String,
            default: '140px',
        },
        icon: {
            type: String,
            default: Icons.PLUS,
        },
        accept: {
            type: Array,
            default: () => ['image/jpeg', 'image/png', 'image/gif'],
        },
        skipValidationAutoFocus: {
            type: Boolean,
            default: true,
        },
        maxSize: {
            type: Number,
            default: 2097152,
        },
        uploadButton: Boolean,
        defaultFiles: Array,
    },

    data() {
        return {
            files: this.defaultFiles ?? [],
            active: false,
            queueCount: 0,
        }
    },

    watch: {
        files() {
            const queueCount = this.files.filter((file) => file.status === FileStatus.QUEUED || file.status === FileStatus.PROCESSING).length;
            this.queueCount = queueCount;
            this.$emit('queue-change', queueCount);
        },
    },

    mounted() {
        this.$emit('input', this.files);
    },

    methods: {
        getChipVariantByStatus(status) {
            switch (status) {
                case FileStatus.DELETED:
                    return ChipVariants.DARK;
                case FileStatus.ERROR:
                    return ChipVariants.DANGER;
                case FileStatus.SUCCESS:
                    return ChipVariants.SUCCESS;
                case FileStatus.QUEUED:
                    return ChipVariants.INFO;
            }
        },

        reset() {
            this.files = [];
            this.resetValidation();
        },

        getValue() {
            return this.getFile();
        },

        onDragOver(e) {
            e.preventDefault();

            if (!this.active) {
                this.active = true;
            }
        },

        onDrop(e) {
            e.preventDefault();
            this.active = false;

            if (e.dataTransfer.items) {
                for (let i = 0; i < e.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (e.dataTransfer.items[i].kind === 'file') {
                        const file = e.dataTransfer.items[i].getAsFile();
                        this.addFile(file);
                    }
                }
                this.$nextTick(() => this.jumpToRow(this.files.length - 1));
            } else {
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    this.addFile(e.dataTransfer.files[i]);
                }
                this.$nextTick(() => this.jumpToRow(this.files.length - 1));
            }
        },

        addFile(file) {
            let error = undefined;

            if (!this.accept.includes(file.type)) {
                error = `File type ${file.type} is not supported. Supported file types: ${this.accept.join(', ')}.`;
            } else if (file.size > this.maxSize) {
                error = `Image is too large. Please keep it under ${humanReadableFileSize(this.maxSize)}.`;
            }

            this.file = file;
            this.uploadSrc = URL.createObjectURL(file);

            this.files.push({
                id: generateUid(),
                name: file.name,
                size: humanReadableFileSize(file.size),
                src: URL.createObjectURL(file),
                exists: false,
                status: (error ? FileStatus.ERROR : FileStatus.QUEUED),
                progress: 0,
                _previous_status: undefined,
                _error: error,
                _file: file,
            });

            this.resetValidation();
        },

        getFile() {
            return this.file;
        },

        onDragLeave() {
            this.active = false;
        },

        jumpToRow(i) {
            if (this.$refs.table.$children[i]) {
                const halfOffset = this.$refs.scroll.$el.clientHeight / 2;
                this.$refs.scroll.scrollTo(this.$refs.table.$children[i].$el.offsetTop - halfOffset, true);
            }
        },

        onFileSelect() {
            const curFiles = this.$refs.input.files;
            if (curFiles.length !== 0) {
                for (const file of curFiles) {
                    if (file) {
                        this.addFile(file);
                    }
                }
            }
        },

        focus() {
            if (this.$refs.input) {
                this.$refs.input.click();
            }
        },

        getExcludedProps() {
            return [
                'src',
                'width',
                'height',
                'icon',
                'accept',
                'skipValidationAutoFocus',
                'type',
                'defaultFiles',
                'maxSize',
                'uploadEndpoint',
                'uploadButton',
            ];
        },

        deleteFile(i) {
            const hasSuccessState = this.files[i].status === FileStatus.SUCCESS;

            if (this.files[i].status === FileStatus.DELETED) {
                this.$set(this.files, i, {
                    ...this.files[i],
                    status: this.files[i]._previous_status,
                });
            } else {
                this.$set(this.files, i, {
                    ...this.files[i],
                    _previous_status: this.files[i].status,
                    status: FileStatus.DELETED,
                });
            }

            if (hasSuccessState) {
                this.$nextTick(() => this.$emit('input', this.files));
            }
        },

        upload() {
            if (this.queueCount > 0) {
                EventBus.$emit(GlobalEvents.PUSH_MESSAGE, `Starting to upload ${this.queueCount} file(s)`, 'info');
            }
            return new Promise((resolve) => this.uploadAllQueued(resolve));
        },

        uploadAllQueued(resolve) {
            if (this.queueCount === 0) {
                this.endSilentLoading();
                resolve();
                return;
            }

            for (let i in this.files) {
                if (this.files[i].status === FileStatus.QUEUED) {
                    return this.uploadFile(i, resolve);
                }
            }
        },

        uploadFile(i, resolve) {
            this.$nextTick(() => this.jumpToRow(i));

            this.files[i].status = FileStatus.PROCESSING;
            this.files[i].progress = 50;

            const formData = new FormData();
            formData.set('file', this.files[i]._file);

            this.startSilentLoading();

            const done = () => {
                this.$nextTick(() => {
                    this.files[i].progress = 100;
                    this.uploadAllQueued(resolve);
                    // this.$emit('input', this.files);
                });
            }

            window.axios.post(this.uploadEndpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).then(() => {
                this.$set(this.files, i, {
                    ...this.files[i],
                    status: FileStatus.SUCCESS,
                });
                done();
            }).catch(() => {
                this.$set(this.files, i, {
                    ...this.files[i],
                    status: FileStatus.ERROR,
                });
                done();
            });
        },
    },
}
</script>

<style lang="scss">
.f-images-input {
    display: block;

    &__container {
        margin: 4px;
        cursor: pointer;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: color .1s;
        background-position: center center;
        background-size: cover;
        width: 100%;
        border-radius: 10px;

        > .f-images-input__field {
            display: none;
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
        }

        &:after {
            border-radius: 10px;
            content: '';
            transition: border-color .1s;
            position: absolute;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            top: -3px;
            left: -3px;
            border: 2px dashed red;
        }

        .f-icon {
            margin-left: 10px;
        }
    }

    .f-scroll-pane {
        margin-top: 30px;
    }

    &__table {
        &__image {
            width: 60px;
            height: 60px;
            background-size: cover;
            background-position: center center;
            border-radius: 5px;
            background-color: $dark;
        }

        .f-inline-message {
            margin-top: 5px;
        }

        .f-table-title-cell__subtitle {
            opacity: .5;
        }

        .f-table-row.--deleted {
            opacity: .35;

            .f-table-title-cell {
                text-decoration: line-through;
            }
        }
    }

    &__buttons {
        text-align: right;
        margin-top: 20px;
    }

    @include theme(dark) {
        .f-images-input__container {
            color: rgba($light, .75);

            &:after {
                border-color: rgba($light, .75);
            }

            &:hover, &.--active {
                &:after {
                    border-color: rgba($light, 1);
                }

                color: rgba($light, 1);
            }
        }
    }

    @include theme(light) {
        .f-images-input__container {
            color: rgba($dark, .75);

            &:after {
                border-color: rgba($dark, .75);
            }

            &:hover, &.--active {
                &:after {
                    border-color: rgba($dark, 1);
                }

                color: rgba($dark, 1);
            }
        }
    }

    &.--error {
        .f-images-input__container:after {
            border-color: $danger;
        }

        .f-icon {
            color: $danger;
        }
    }
}
</style>
