var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'employee-leave-balance-tab',
        '--loader',
        (_vm.loading ? '--loading' : undefined),
    ]},[_c('f-user-dropdown',{attrs:{"placeholder":"Employee","class-name":[
            '--align-center',
            'employee-leave-balance-tab__dropdown'
        ],"options":_vm.users,"max-width":"300px"},model:{value:(_vm.employeeID),callback:function ($$v) {_vm.employeeID=$$v},expression:"employeeID"}}),(_vm.fetchedData && _vm.fetchedData.data.error)?_c('f-inline-message',{attrs:{"type":"error","closable":false,"id":"leaveBalanceAdminError"}},[_vm._v(" "+_vm._s(_vm.fetchedData.data.error)+" ")]):(_vm.fetchedData)?_c('div',{staticClass:"employee-leave-balance-tab__content"},[_c('f-form-fieldset',{attrs:{"legend":"Balance 2022"}},[_c('leave-balance',{attrs:{"data":_vm.fetchedData.data.balance}}),_c('div',{staticClass:"employee-leave-balance-tab__content__overview"},[_c('leave-overview',{attrs:{"data":_vm.fetchedData.data.balance}})],1)],1),(_vm.filteredRequests.length > 0)?_c('div',[_c('f-tabs',{on:{"tabChange":function($event){_vm.currentTab = $event}}},[_c('f-tab',{attrs:{"id":"rivieraRequests","title":"Riviera","subtitle":"Requests requested in<br>Riviera"}}),_c('f-tab',{attrs:{"id":"bcsRequests","title":"BCS","subtitle":"Requests registered in<br>our administration software"}})],1),(_vm.currentTab === 'rivieraRequests')?_c('div',[_c('f-filter',[_c('f-filter-row',[_c('f-filter-item',[_c('f-dropdown',{attrs:{"placeholder":"Year","class-name":"--align-center","options":_vm.years.map((year) => ({key: year, value: year}))},model:{value:(_vm.currentYear),callback:function ($$v) {_vm.currentYear=$$v},expression:"currentYear"}})],1)],1)],1),_c('leave-requests-admin-table',{attrs:{"data":{data: _vm.filteredRequests},"hide-requestee":"","pagination":false}})],1):(_vm.currentTab === 'bcsRequests')?_c('div',[_c('leave-requests-admin-bcs-requests',{attrs:{"user-id":_vm.employeeID}})],1):_vm._e()],1):_vm._e()],1):(!_vm.fetchedData && _vm.loading)?_c('f-table-placeholder'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }