export default {
    props: {
        disabled: Boolean,
        readonly: Boolean,
        name: String,
        tabIndex: String | Number,
        value: Number | String | null | undefined,
        className: String | Array,
        errorMarginLeft: Number,
        errorAlign: {
            type: String,
            default: 'right',
        },
    },

    computed: {
        classNames() {
            return (Array.isArray(this.className) ? this.className : [this.className]);
        },
    },
};
