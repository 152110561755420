<template>
    <f-table-cell
        :class-name="`f-table-hours-price-cell ${className}`"
        v-bind="$props"
    >
        <div
            v-if="hrs"
            class="f-table-hours-price-cell__hours"
            v-html="zeroIsEmpty(hrs)"
        />

        <div
            v-if="price"
            class="f-table-hours-price-cell__price"
            v-html="formatPrice(price)"
        />
        <div v-else class="f-table-hours-price-cell__price">
            <slot name="price"/>
        </div>

        <slot/>
    </f-table-cell>
</template>

<script>
import FTableCell from "./FTableCell";
import FTableCellMixin from "./FTableCellMixin";
import NumbersAndBudgetMixin from "../../../mixins/NumbersAndBudgetMixin";

export default {
    name: 'f-table-hours-price-cell',

    mixins: [
        FTableCellMixin,
        NumbersAndBudgetMixin,
    ],

    components: {
        FTableCell,
    },

    props: {
        hrs: Number,
        price: Number,
    },
}
</script>

<style lang="scss">
.f-table-hours-price-cell {
    text-align: right;

    &__hours {
        padding-bottom: 2px;
    }

    &__price {
        opacity: .5;
        font-size: 90%;
    }
}
</style>
