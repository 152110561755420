import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import {tooltip} from './directives/tooltipDirective';
import DragScroll from './directives/dragScrollDirective';
import ErrorHandler from './services/ErrorHandler';
import EventBus from './services/EventBus';
import {GlobalEvents} from './config/Events';
import Store from './services/Store';
import {Permissions} from './config/Permissions';
import './sass/app.scss';
import './utils/helpers';
import moment from 'moment';

moment.locale('en');

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.directive('tooltip', tooltip);
Vue.directive('drag-scroll', DragScroll);

/**
 * Add event properties on Vue instances to enable the
 * pub-sub like event system inside templates too
 */
Object.defineProperties(Vue.prototype, {
    EventBus: {
        get: () => EventBus,
    },
    GlobalEvents: {
        get: () => GlobalEvents,
    },
    Permissions: {
        get: () => Permissions,
    },
});
// Permission helper
Vue.mixin({
    methods: {
        hasPermission(permission) {
            // console.log(permission, Store.state.$user.permissions.includes(permission));
            return Store.state.$user.permissions.includes(permission);
        },
    },
});

/**
 * Axios
 */
window.axios = require('axios'); // @todo Refac
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Intercept errors
window.axios.interceptors.response.use(undefined, error => ErrorHandler.onAxiosGeneralErrorInterceptor(error));
