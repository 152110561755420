<template>
    <div
        :class="[
            'f-flex-table-hours-price-cell',
             (hideMobile ? '--hide-mobile': null),
             (showMobile ? '--show-mobile': null),
             (align ? `--align-${align}` : null),
             (border ? '--border': null),
             '--no-wrap',
             className,
        ]"
        :style="`flex: ${flex}`"
    >
        <div
            v-if="hrs"
            class="f-flex-table-hours-price-cell__hours"
        >
            {{ zeroIsEmpty(hrs) }}
            <slot name="post-hours"/>
        </div>

        <div
            v-if="price"
            class="f-flex-table-hours-price-cell__price"
        >
            <slot name="pre-price"/>
            {{ formatPrice(price) }}
            <slot name="post-price"/>
        </div>
        <div v-else class="f-flex-table-hours-price-cell__price">
            <slot name="price"/>
        </div>
    </div>
</template>

<script>
/**
 * @todo Refac to use f-flex-table-cell
 */
import NumbersAndBudgetMixin from "../../../mixins/NumbersAndBudgetMixin";

export default {
    name: 'f-flex-table-hours-price-cell',

    props: {
        className: String,
        hideMobile: Boolean,
        showMobile: Boolean,
        align: String,
        flex: {
            type: String,
            default: '1',
        },
        hrs: Number,
        price: Number,
        border: Boolean,
    },

    mixins: [
        NumbersAndBudgetMixin,
    ],
}
</script>

<style lang="scss">
.f-flex-table-hours-price-cell {
    flex: 1;
    padding: 5px 10px;
    align-self: stretch;
    font-weight: 600;
    position: relative;

    &.--overflow-hidden {
        overflow: hidden;
    }

    &.--border {
        &:before {
            background-color: #f8f8f8;
            width: 100%;
            height: 400%;
            content: "";
            position: absolute;
            top: -200%;
            left: 0;
            z-index: -1;
        }
    }

    @media only screen and (max-width: 600px) {
        flex: 0 0 40px;
    }

    &__hours {
        padding-bottom: 2px;
    }

    &__price {
        font-size: 90%;
        font-weight: 500;
        opacity: .65;
    }
}
</style>
