var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"abstract",class:[
        ..._vm.classNames,
        'f-abstract-input',
        (_vm.focussed && !_vm.disabled ? '--focus' : null),
        (_vm.disabled ? '--disabled' : null),
        (_vm.readonly ? '--readonly' : null),
        (_vm.inputError ? '--error' : null),
    ],on:{"click":_vm.onClick}},[_vm._t("default"),(_vm.showError && _vm.inputError)?_c('f-input-error',{attrs:{"error":_vm.inputError,"target":_vm.$refs.abstract,"offset-left":_vm.errorMarginLeft,"align":_vm.errorAlign}}):_vm._e(),_vm._t("after")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }