var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ text: _vm.tooltip, ..._vm.tooltipOptions }),expression:"{ text: tooltip, ...tooltipOptions }"}],class:[
        'entity-avatar',
        '--no-select',
        (_vm.size ? `--${_vm.size}` : null),
        (_vm.color ? `--${_vm.color}` : null),
        (_vm.birthday ? `--birthday` : null),
        `--${_vm.type}`,
        _vm.className,
    ]},[(_vm.name)?_c('span',{staticClass:"entity-avatar__initials",domProps:{"textContent":_vm._s(_vm.initials)}}):_vm._e(),(_vm.src)?_c('img',{staticClass:"entity-avatar__image",attrs:{"loading":"lazy","src":_vm.src,"alt":_vm.initials}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }