<template>
    <div
        :class="[
            'f-filter__container',
            '--loader',
        ]"
    >
        <section
            :class="[
                'f-filter',
                className,
                (filterList && showActiveFilters ? '--active' : null),
                (extra ? '--extra' : null),
            ]"
        >
            <div class="f-filter__section">
                <slot/>

                <div
                    v-if="extra"
                    v-show="open"
                    class="f-filter__extra"
                >
                    <slot name="extra"/>
                </div>

                <div
                    v-if="extra"
                    class="f-filter__extra-button"
                >
                    <f-icon-button
                        @click="toggleExtra"
                        :icon="(open ? 'minus' : 'plus')"
                        v-tooltip="(open ? 'Less filters' : 'More filters')"
                        size="small"
                        outlined
                    />
                </div>
            </div>

            <div
                v-if="showActiveFilters && filterList"
                class="f-filter__section f-filter__list --no-select"
            >
                <f-text type="subtitle" class-name="f-filter__list__header">
                    Active filters
                </f-text>
                <f-chip
                    v-for="filter in filterList"
                    :key="filter.id"
                    variant="dark"
                >
                    <span class="f-filter__list__name">
                        {{ filter.name }}
                    </span>
                    <span v-html="filter.value"/>
                </f-chip>
            </div>
        </section>

        <slot name="post"/>
    </div>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor, no-case-declarations */
import moment from 'moment';
import FIconButton from '../../form-controls/buttons/FIconButton';
import FChip from '../chip/FChip';
import UserHelperMixin from '../../../mixins/UserHelperMixin';
import ClientHelperMixin from '../../../mixins/ClientHelperMixin';
import FText from '../../layout/text/FText';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';

export default {
    name: 'f-filter',

    components: {
        FText,
        FChip,
        FIconButton,
    },

    mixins: [
        UserHelperMixin,
        ClientHelperMixin,
    ],

    data() {
        return {
            open: !this.extra || this.defaultOpen,
        };
    },

    props: {
        className: String,
        extra: Boolean,
        defaultOpen: Boolean,
        showActiveFilters: Boolean,
        activeFilters: {
            type: Object | Array,
            default: function () {
                return {}
            },
        },
        filterCasts: Object,
    },

    computed: {
        filterList() {
            const filterList = [];

            if (!this.activeFilters || this.activeFilters.length === 0) {
                return false;
            }

            for (let filterKey in this.activeFilters) {
                if (Object.prototype.hasOwnProperty.call(this.activeFilters, filterKey)) {
                    let name = filterKey.replace('_id', '').replace('_', ' ');
                    let value = this.activeFilters[filterKey];

                    const cast = (this.filterCasts && this.filterCasts[filterKey] ? this.filterCasts[filterKey] : null);
                    if (cast) {
                        switch (cast) {
                            case 'boolean':
                                value = '<span class="f-icon f-icon-check"></span>';
                                break;
                            case 'date':
                                value = moment(value).format('D MMM YYYY');
                                break;
                            case 'user':
                                const user = this.userById(value);
                                if (user) {
                                    value = user.name;
                                }
                                break;
                            case 'client':
                                const client = this.clientFromId(value);
                                if (client) {
                                    value = client.name;
                                }
                                break;
                            case 'query':
                                name = 'search';
                                break;
                        }
                    }

                    filterList.push({
                        id: filterKey,
                        name: name,
                        value: value,
                    });
                }
            }

            if (this.activeFilters.length === 0) {
                return false;
            }

            return filterList;
        },
    },

    methods: {
        toggleExtra() {
            this.open = !this.open;
            this.$nextTick(() => EventBus.$emit(GlobalEvents.TOOLTIP_HIDE));
        },
    },
}
</script>

<style lang="scss">
$borderRadius: 15px;

.f-filter {
    &__container {
        margin: auto;
        max-width: 700px;
    }

    margin: 0 auto 30px auto;
    border-radius: $borderRadius;
    background: $softGray;
    width: 100%;
    position: relative;
    text-align: center;

    &__section {
        padding: 30px 20px;
    }

    &.--extra {
        .f-filter__section:first-child {
            padding: 30px 20px 20px;
        }
    }

    .f-filter-row {
        display: flex;
        position: relative;

        .f-filter-item {
            flex: 1;
            padding: 0 10px;
            text-align: center;

            &.filter-switch {
                padding: 15px 0 10px 0;
                text-align: center;

                > label {
                    vertical-align: middle;
                }

                .f-switch {
                    margin-right: 10px;
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            &.filter-dash {
                flex: 20px 0;
                opacity: .5;
                padding: 12px 0;
                font-style: italic;
            }
        }
    }

    &__list {
        text-align: center;
        border-top: 1px solid $quinaryGray;

        &__header {
            font-size: 80%;
            text-transform: uppercase;
            display: block;
            padding-bottom: 10px;
        }

        > * {
            vertical-align: middle !important;
        }

        &__name {
            padding-right: 4px;
            opacity: .5;
        }

        .f-chip {
            margin: 2px;
        }
    }

    @include media(mobile-down) {
        max-width: 400px;

        .f-filter-row {
            display: block;

            .f-filter-item {
                width: 100%;
                flex: none;
                display: inline-block;
            }
        }
    }

    .f-filter__extra-button {
        margin-top: 15px;
    }
}
</style>
