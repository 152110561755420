<template>
    <page
        v-if="hasPermission(Permissions.LEAVE_REQUESTS_VIEW)"
        class-name="leave-requests-admin-page"
        title="Leave Requests"
        skewed-footer-foreground="#fff"
        header="Leave requests"
        pre-max-width="800px"
    >
        <!--        <template #pre>-->
        <!--            <f-breadcrumbs>-->
        <!--                <f-breadcrumb>-->
        <!--                    Leave requests <sup>DEPRECATED</sup>-->
        <!--                </f-breadcrumb>-->
        <!--                <f-breadcrumb>-->
        <!--                    {{ !!tabId && tabId.ucwords() }}-->
        <!--                </f-breadcrumb>-->
        <!--            </f-breadcrumbs>-->
        <!--        </template>-->

        <f-container max-width="800px">
            <f-inline-message id="productiveLeaveAdminPageMessage" :closable="false">
                Leave requests will from now on be requested and handled in
                <a href="https://app.productive.io/22699" target="_blank">Productive</a>.
            </f-inline-message>
        </f-container>

        <!--        <f-container max-width="1000px">-->
        <!--            <f-tabs-->
        <!--                @tabChange="tabId = $event"-->
        <!--                remember-state-->
        <!--                type="tabs"-->
        <!--                margin-->
        <!--            >-->
        <!--                <f-tab id="handled" title="Handled">-->
        <!--                    <f-icon icon="check"/>-->
        <!--                </f-tab>-->
        <!--                <f-tab id="balances" title="Balances">-->
        <!--                    <f-icon icon="user"/>-->
        <!--                </f-tab>-->
        <!--            </f-tabs>-->

        <!--            <div v-if="tabId === 'open'">-->
        <!--                <leave-requests-admin-table-->
        <!--                    v-if="$store.state.$adminLeaveRequests && $store.state.$adminLeaveRequests.items && $store.state.$adminLeaveRequests.items.length > 0"-->
        <!--                    :data="{data: $store.state.$adminLeaveRequests.items}"-->
        <!--                    approval-mode-->
        <!--                />-->
        <!--                <nothing-found v-else/>-->
        <!--            </div>-->

        <!--            <div v-if="tabId === 'handled'">-->
        <!--                <handled-leave-requests-tab/>-->
        <!--            </div>-->
        <!--            <div v-else-if="tabId === 'balances'">-->
        <!--                <employee-leave-balance-tab/>-->
        <!--            </div>-->
        <!--        </f-container>-->

        <!--        <entity-action-button-->
        <!--            v-if="tabId === 'open'"-->
        <!--            :permission="Permissions.LEAVE_REQUESTS_CREATE"-->
        <!--            @click="requestModalOpen = true"-->
        <!--        />-->

        <!--        <f-modal-->
        <!--            v-if="requestModalOpen"-->
        <!--            @close="requestModalOpen = false; editLeaveRequest = null;"-->
        <!--            :title="`${editLeaveRequest ? 'Edit' : 'Create'} ${leaveRequestType.replace('_', ' ').replace('leave', '')} leave request`"-->
        <!--            class-name="request-leave-modal"-->
        <!--        >-->
        <!--            <request-leave-modal-content-->
        <!--                v-model="leaveRequestType"-->
        <!--                @close="requestModalOpen = false; editLeaveRequest = null;"-->
        <!--                :edit-data="editLeaveRequest"-->
        <!--                admin-->
        <!--            />-->
        <!--        </f-modal>-->
    </page>
</template>

<script>
import Page from '../Page';
import APIDataStoreResources from '../../../config/APIDataStoreResources';
import APIDataStore from '../../../services/APIDataStore';
import LeaveRequestsAdminTable from './components/LeaveRequestsAdminTable';
import FTabs from '../../../f-components/navigation/tabs/FTabs';
import FTab from '../../../f-components/navigation/tabs/FTab';
import FIcon from '../../../f-components/layout/icons/FIcon';
import FModal from '../../../f-components/other/modal/FModal';
import RequestLeaveModalContent from '../../misc/leave-requests/RequestLeaveModalContent';
import HandledLeaveRequestsTab from './components/HandledLeaveRequestsTab';
import EventBus from '../../../services/EventBus';
import FContainer from '../../../f-components/layout/container/FContainer';
import FBreadcrumb from '../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import FBreadcrumbs from '../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import EntityActionButton from '../../misc/entities/EntityActionButton';
import NothingFound from '../../misc/NothingFound';
import EmployeeLeaveBalanceTab from './components/EmployeeLeaveBalanceTab';
import FInlineMessage from '../../../f-components/notice/inline-message/FInlineMessage.vue';

export default {
    name: 'leave-requests-admin-page',

    components: {
        FInlineMessage,
        EmployeeLeaveBalanceTab,
        NothingFound,
        EntityActionButton,
        FBreadcrumbs,
        FBreadcrumb,
        FContainer,
        HandledLeaveRequestsTab,
        RequestLeaveModalContent,
        LeaveRequestsAdminTable,
        FModal,
        FIcon,
        FTab,
        FTabs,
        Page,
    },

    computed: {
        // openLeaveRequestCount() {
        //     if (
        //         this.$store.state.$adminLeaveRequests &&
        //         this.$store.state.$adminLeaveRequests.items &&
        //         this.$store.state.$adminLeaveRequests.items.length > 0
        //     ) {
        //         return this.$store.state.$adminLeaveRequests.items.length;
        //     }
        //     return 0;
        // },
    },

    data() {
        return {
            // tabId: 'handled',
            // leaveRequestType: 'holiday',
            // requestModalOpen: false,
            // editLeaveRequest: null,
        }
    },

    mounted() {
        // APIDataStore.fetch(APIDataStoreResources.ADMIN_LEAVE_REQUESTS);
        // APIDataStore.fetch(APIDataStoreResources.USERS_COLLECTION);
        // EventBus.$on('admin.leave.edit', this.onLeaveRequestEdit);
    },

    beforeDestroy() {
        // EventBus.$off('admin.leave.edit', this.onLeaveRequestEdit);
    },

    methods: {
        // onLeaveRequestEdit(leaveRequest) {
        //     this.editLeaveRequest = leaveRequest;
        //     this.requestModalOpen = true;
        // },
    },
}
</script>

<style lang="scss">
.leave-requests-admin-page {
    .f-filter__container {
        max-width: 360px;
    }
}
</style>
