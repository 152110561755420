<template>
    <widget
        class-name="calendar-widget"
        title="My calendar today"
        enabled-setting="widgets.calendar.enabled"
    >
        <time-table
            :office="userOffice"
            :meetings="meetings"
            :all-day-meetings="allDayMeetings"
            :max-height="`${maxScrollHeight}px`"
            :row-height="60"
            ref="timeTable"
        />
    </widget>
</template>

<script>
import APIDataStore from '../../../services/APIDataStore';
import TimeTable from '../../misc/time-table/TimeTable';
import TimezoneMixin from '../../../mixins/TimezoneMixin';
import Widget from '../Widget';
import APIDataStoreResources from '../../../config/APIDataStoreResources';
import EventBus from '../../../services/EventBus';
import UserOfficeMixin from '../../../mixins/UserOfficeMixin';

export default {
    name: 'calendar-widget',

    components: {
        Widget,
        TimeTable,
    },

    mixins: [
        TimezoneMixin,
        UserOfficeMixin,
    ],

    data() {
        return {
            isFirstTimeUpdate: true,
            maxScrollHeight: 300,
        }
    },

    computed: {
        meetings() {
            return this.$store.state.$calendar.filter((meeting) => !meeting.is_all_day);
        },
        allDayMeetings() {
            return this.$store.state.$calendar.filter((meeting) => meeting.is_all_day);
        },
    },

    created() {
        if (this.$store.getters.getUserSetting('widgets.calendar.enabled')) {
            APIDataStore.check(APIDataStoreResources.CALENDAR_TODAY);
        }
    },

    beforeDestroy() {
        EventBus.$off(`ds.${APIDataStoreResources.CALENDAR_TODAY.id}.update`);
    },
}
</script>

<style lang="scss">
.calendar-widget {
    .attendees {
        display: none;
    }

    .--small {
        .content {
            padding: 0 5px;
            line-height: 120%;
        }
    }
}
</style>
