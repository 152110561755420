<template>
    <transition name="fade">
        <div
            :class="[
                'gallery-picture-overlay',
                (disabled ? '--disabled' : null),
            ]"
        >
            <div
                class="gallery-picture-overlay__tags"
                :style="{
                    visibility: (environment === 'production' ? 'hidden' : undefined),
                    pointerEvents: (environment === 'production' ? 'none' : undefined),
                }"
            >
                <picture-tag
                    v-for="tag in image.tags"
                    :key="tag.id"
                    :tag="tag"
                    :image-width="image.width"
                    :image-height="image.height"
                />
            </div>

            <f-flex
                class-name="gallery-picture-overlay__bar"
                gap="10px"
                main-axis-align="flex-end"
                cross-axis-align="center"
            >
                <entity-avatar-group
                    v-if="likesCount > 0"
                    :entities="likers"
                    size="small"
                    tooltip-post=" liked"
                />

                <f-button
                    icon="thumbs-up"
                    :value="(liked ? 'You liked' : 'Like')"
                    size="small"
                    @click="like"
                    show-loader-on-disabled
                    :disabled="likeLoading"
                />
            </f-flex>
        </div>
    </transition>
</template>

<script>
import FButton from '../../../../f-components/form-controls/buttons/FButton';
import FFlex from '../../../../f-components/layout/flex/FFlex';
import APIEndpoints from '../../../../config/APIEndpoints';
import Store from '../../../../services/Store';
import UserHelperMixin from '../../../../mixins/UserHelperMixin';
import {APP_ENVIRONMENT} from '../../../../config/app';
import PictureTag from './PictureTag';
import EntityAvatarGroup from '../../entities/EntityAvatarGroup';

export default {
    name: 'gallery-picture-overlay',

    mixins: [
        UserHelperMixin,
    ],

    components: {
        EntityAvatarGroup,
        PictureTag,
        FFlex,
        FButton,
    },

    props: {
        disabled: Boolean,
        image: Object,
    },

    computed: {
        likesCount() {
            if (this.image.likes) {
                return this.image.likes.length;
            }
            return 0;
        },
        liked() {
            if (this.image.likes) {
                return !!this.image.likes.find((like) => {
                    return parseInt(like.user_id) === Store.state.$user.id;
                });
            }
            return false;
        },
        likers() {
            if (!this.image.likes) return [];

            // return this.image.likes.map((like) => {
            //     return this.userById(parseInt(like.user_id));
            // }).filter((user) => user).splice(0, this.maxLikers);

            return this.image.likes.map((like) => {
                return this.userById(parseInt(like.user_id));
            }).filter((user) => user);
        },
    },

    data() {
        return {
            maxLikers: 10,
            likeLoading: false,
            environment: APP_ENVIRONMENT,
        }
    },

    methods: {
        like(e) {
            e.stopPropagation();

            this.likeLoading = true;

            window.axios
                .post(APIEndpoints.parse(APIEndpoints.GALLERY_LIKE, {id: this.image.id}), {
                    like: !this.liked,
                })
                .then(({data}) => {
                    this.$emit('new-likes', this.image.id, data.data);
                    this.likeLoading = false;
                })
                .catch(() => this.likeLoading = false);
        },

        // tag(e) {
        //     const bounds = this.$el.getBoundingClientRect();
        //
        //     const x = e.pageX - bounds.left,
        //         y = e.pageY - bounds.top;
        //
        //     const perX = Math.round(x * 100 / bounds.width),
        //         perY = Math.round(y * 100 / bounds.height);
        //
        //     console.log('tag', perX, perY);
        // },
    },
}
</script>

<style lang="scss">
.gallery-picture-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &__tags {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;

        &:hover {
            .picture-tag {
                opacity: .35;
            }
        }
    }

    &.--disabled {
        opacity: .25;
        pointer-events: none;
    }

    &__likes-popup {
        li {
            text-align: center;
            padding: 2px 5px;
        }
    }

    &__bar {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 8px;
        background: rgba(black, .5);
        border-radius: 0 0 8px 8px;

        &__likes {
        }
    }
}
</style>
