<template>
    <li
        :class="[
            'f-popup-list-item',
             ...classNames,
             (disabled ? '--disabled' : null),
             (selected ? '--selected' : null),
         ]"
    >
        <router-link
            v-if="link && !link.match(/^https?:/) && !link.match(/^mailto:/)"
            :to="link"
            @click.native="$emit('click', $event)"
        >
            <f-icon
                v-if="icon"
                :icon="icon"
            />
            <span class="f-popup-list-item__content">
                <slot/>
            </span>
        </router-link>
        <a
            v-else
            :href="(link && (link.match(/^https?:/g) || link.match(/^mailto:/)) ? link : null)"
            :target="(link && link.match(/^https?:/g) ? '_blank' : null)"
            @click="$emit('click', $event)"
        >
            <f-icon
                v-if="icon"
                :icon="icon"
            />
            <span class="f-popup-list-item__content">
                <slot/>
            </span>
        </a>
    </li>
</template>

<script>
import FIcon from '../../layout/icons/FIcon';
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-popup-list-item',

    mixins: [
        ClassNameMixin,
    ],

    components: {
        FIcon,
    },

    props: {
        link: String,
        className: String,
        icon: String,
        disabled: Boolean,
        selected: Boolean,
    },
}
</script>

<style lang="scss">
.f-popup-list-item {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    transition: background-color .1s;
    margin-bottom: 2px;

    &:last-child {
        margin-bottom: 0;
    }

    &.--disabled {
        opacity: .5;
        pointer-events: none;
    }

    &.--selected {
        pointer-events: none;
    }

    > a {
        margin: 1px 0 0;
        display: flex;
        transition: background-color .1s;
        border-radius: 50%;
        padding: 5px 7px 5px 5px;
        line-height: 120%;
        text-decoration: none;

        > .f-icon {
            flex: 0 0 14px;
            margin-right: 5px;
            padding-top: 1px;
        }

        img {
            width: 16px;
            margin-right: 5px;
            vertical-align: middle;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

.f-popup {
    &.--dark .f-popup-list-item {
        &:hover {
            background-color: $primary;

            &, * {
                color: $primaryFront;
            }
        }

        &.--selected, &.--selected:hover {
            background-color: $quaternaryGray;

            &, * {
                color: $primaryFront;
            }
        }
    }

    &.--light .f-popup-list-item {
        &:hover {
            background-color: $primary;

            &, * {
                color: $primaryFront;
            }
        }

        &.--selected, &.--selected:hover {
            background-color: $softGray;
            color: $dark;

            &, * {
                color: $dark;
            }
        }
    }

    &.--primary, &.--alternative {
        .f-popup-list-item {
            &:hover {
                background-color: $dark;

                &, * {
                    color: $darkFront;
                }
            }
        }
    }
}
</style>
