<template>
    <page
        class-name="people-page"
        title="People"
        :header="`The <strong>heroes</strong> <br> of <em>code d'azur</em>`"
        pre-max-width="1350px"
        :header-badge="users.length"
    >
        <div id="peoplePage"/>

        <f-container max-width="1350px">
            <nav class="people-page__navigation">
                <f-flex gap="20px">
                    <f-icon-button
                        size="xlarge"
                        icon="chevron-left"
                        @click="prevUserType"
                        outlined
                        variant="dark"
                    />
                    <div class="people-page__navigation__current">
                        <h2>{{ userTypes[currentUserTypeIndex].type.ucwords() }}</h2>
                        <f-text type="subtitle" class="people-page__navigation__current__office">
                            {{ userTypes[currentUserTypeIndex].view.ucwords() }}
                        </f-text>
                    </div>
                    <f-icon-button
                        size="xlarge"
                        icon="chevron-right"
                        @click="nextUserType"
                        outlined
                        variant="dark"
                    />
                </f-flex>
            </nav>

            <f-filter>
                <f-filter-row>
                    <f-filter-item>
                        <f-text-input
                            pre-icon="magnifier"
                            v-model.trim="searchQuery"
                            placeholder="Search…"
                            resetable
                            align="center"
                        />
                    </f-filter-item>
                </f-filter-row>
            </f-filter>

            <ul
                v-if="(users.length > 0)"
                class="people-page__users"
            >
                <li v-for="(user) in users" :key="user.id">
                    <user-card
                        :user="user"
                        :type="userTypes[currentUserTypeIndex].type"
                        menu-on-hover
                    />
                </li>
            </ul>

            <nothing-found
                v-else
                name="users"
            />
        </f-container>
    </page>
</template>

<script>
import APIDataStoreResources from '../../../config/APIDataStoreResources';
import APIDataStore from '../../../services/APIDataStore';
import Page from '../Page';
import FContainer from '../../../f-components/layout/container/FContainer';
import FFlex from '../../../f-components/layout/flex/FFlex';
import FIconButton from '../../../f-components/form-controls/buttons/FIconButton';
import UserCard from '../../cards/users/UserCard';
import FFilter from '../../../f-components/data/filter/FFilter';
import FFilterRow from '../../../f-components/data/filter/FFilterRow';
import FFilterItem from '../../../f-components/data/filter/FFilterItem';
import FTextInput from '../../../f-components/form-controls/text/FTextInput';
import NothingFound from '../../misc/NothingFound';
import FText from '../../../f-components/layout/text/FText';

export default {
    name: 'people-page',

    components: {
        FText,
        NothingFound,
        FTextInput,
        FFilterItem,
        FFilterRow,
        FFilter,
        UserCard,
        FIconButton,
        FFlex,
        FContainer,
        Page,
    },

    data() {
        return {
            searchQuery: null,
            currentUserTypeIndex: 0,
        }
    },

    computed: {
        userTypes() {
            let types = [
                {
                    type: 'employees',
                    view: 'consolidated',
                },
            ];

            this.$store.state.$officesCollection.forEach((office) => {
                types.push({
                    type: 'employees',
                    view: office.id,
                });
            });

            // types.push({
            //     type: 'freelancers',
            //     view: 'amsterdam',
            // });

            return types;
        },

        users() {
            // if (this.userTypes[this.currentUserTypeIndex].type === 'freelancers') {
            //     return this.$store.state.$freelancersCollection.filter(this.filterFreelancers);
            // }
            return this.$store.state.$usersCollection.filter(this.filterEmployees);
        },
    },


    created() {
        APIDataStore.check(APIDataStoreResources.OFFICES_COLLECTION);
        APIDataStore.check(APIDataStoreResources.USERS_COLLECTION);
        // APIDataStore.check(APIDataStoreResources.FREELANCERS_COLLECTION);
    },

    methods: {
        nextUserType() {
            this.currentUserTypeIndex++;
            if (this.currentUserTypeIndex >= this.userTypes.length) {
                this.currentUserTypeIndex = 0;
            }
        },

        prevUserType() {
            this.currentUserTypeIndex--;
            if (this.currentUserTypeIndex < 0) {
                this.currentUserTypeIndex = this.userTypes.length - 1;
            }
        },

        filterEmployees(user) {
            if (user.hide_from_list || user.type !== 'employee') {
                return false;
            }

            // Check view
            if (
                this.userTypes[this.currentUserTypeIndex].view !== 'consolidated' &&
                user.office !== this.userTypes[this.currentUserTypeIndex].view
            ) {
                return false;
            }

            // Check search
            if (!this.searchQuery) {
                return true;
            }

            const search = [
                user.name,
                user.city,
                user.title,
                user.office,
                user.phone_number_visual,
            ].join(' ').toLowerCase();

            return search.indexOf(this.searchQuery.toLowerCase()) >= 0;
        },

        // filterFreelancers(freelancer) {
        //     if (!freelancer.current_period) {
        //         return false;
        //     }
        //     if (!this.searchQuery) {
        //         return true;
        //     }
        //
        //     const search = [
        //         freelancer.name,
        //         freelancer.title,
        //         freelancer.city,
        //         (freelancer.current_period ? freelancer.current_period.description : ''),
        //         freelancer.office,
        //         freelancer.phone_number_visual,
        //     ].join(' ').toLowerCase();
        //
        //     return search.indexOf(this.searchQuery.toLowerCase()) >= 0;
        // },
    },
}
</script>

<style lang="scss">
.people-page {
    &__navigation {
        margin: 35px 0 50px;
        white-space: nowrap;
        text-align: center;

        &__current {
            h2 {
                font-size: 35px;
                margin-bottom: 2px;
            }

            &__office {
                opacity: .5;
            }
        }
    }

    .f-filter__container {
        max-width: 360px;
    }

    &__users {
        width: 100%;
        list-style: none;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;

        li {
            width: 250px;
            padding: 10px;

            @include media(mobile-down) {
                width: 170px;
                padding: 5px;
            }
        }
    }
}
</style>
