var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'user-card-compact',
         (_vm.selected ? '--selected' : null),
         (_vm.isSelectable && !_vm.disabled ? '--selectable' : null),
         (_vm.disabled ? '--disabled' : null),
         (_vm.fullWidth ? '--full-width' : null),
         (_vm.hasBirthday ? '--birthday' : null),
         `--${_vm.variant}`,
         '--no-select',
     ],on:{"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)}}},[_c('div',{staticClass:"user-card-compact__wrapper"},[_c('div',{staticClass:"user-card-compact__avatar"},[_c('entity-avatar',{attrs:{"name":(_vm.user ? _vm.user.name : '?'),"src":(_vm.user ? _vm.user.profile_image : null),"size":"medium"}})],1),_c('div',{staticClass:"user-card-compact__body"},[_c('div',{staticClass:"user-card-compact__body__name",domProps:{"textContent":_vm._s((_vm.user ? _vm.user.name : 'Unknown'))}}),(_vm.showDisciplines && _vm.user)?_c('div',{staticClass:"user-card-compact__body__disciplines"},[_vm._v(" "+_vm._s(_vm.user.title)+" "),(_vm.user.type && _vm.user.type === 'freelancer')?_c('span',[_vm._v(" (freelancer) ")]):_vm._e()]):_c('div',{staticClass:"user-card-compact__body__slot"},[_vm._t("default")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }