export const Icons = {
    HOME: 'home',
    HOME2: 'home2',
    HOME3: 'home3',
    HOME4: 'home4',
    HOME5: 'home5',
    HOME6: 'home6',
    BATHTUB: 'bathtub',
    TOOTHBRUSH: 'toothbrush',
    BED: 'bed',
    COUCH: 'couch',
    CHAIR: 'chair',
    CITY: 'city',
    APARTMENT: 'apartment',
    PENCIL: 'pencil',
    PENCIL2: 'pencil2',
    PEN: 'pen',
    PENCIL3: 'pencil3',
    ERASER: 'eraser',
    PENCIL4: 'pencil4',
    PENCIL5: 'pencil5',
    FEATHER: 'feather',
    FEATHER2: 'feather2',
    FEATHER3: 'feather3',
    PEN2: 'pen2',
    PEN_ADD: 'pen-add',
    PEN_REMOVE: 'pen-remove',
    VECTOR: 'vector',
    PEN3: 'pen3',
    BLOG: 'blog',
    BRUSH: 'brush',
    BRUSH2: 'brush2',
    SPRAY: 'spray',
    PAINT_ROLLER: 'paint-roller',
    STAMP: 'stamp',
    TAPE: 'tape',
    DESK_TAPE: 'desk-tape',
    TEXTURE: 'texture',
    EYE_DROPPER: 'eye-dropper',
    PALETTE: 'palette',
    COLOR_SAMPLER: 'color-sampler',
    BUCKET: 'bucket',
    GRADIENT: 'gradient',
    GRADIENT2: 'gradient2',
    MAGIC_WAND: 'magic-wand',
    MAGNET: 'magnet',
    PENCIL_RULER: 'pencil-ruler',
    PENCIL_RULER2: 'pencil-ruler2',
    COMPASS: 'compass',
    AIM: 'aim',
    GUN: 'gun',
    BOTTLE: 'bottle',
    DROP: 'drop',
    DROP_CROSSED: 'drop-crossed',
    DROP2: 'drop2',
    SNOW: 'snow',
    SNOW2: 'snow2',
    FIRE: 'fire',
    LIGHTER: 'lighter',
    KNIFE: 'knife',
    DAGGER: 'dagger',
    TISSUE: 'tissue',
    TOILET_PAPER: 'toilet-paper',
    POOP: 'poop',
    UMBRELLA: 'umbrella',
    UMBRELLA2: 'umbrella2',
    RAIN: 'rain',
    TORNADO: 'tornado',
    WIND: 'wind',
    FAN: 'fan',
    CONTRAST: 'contrast',
    SUN_SMALL: 'sun-small',
    SUN: 'sun',
    SUN2: 'sun2',
    MOON: 'moon',
    CLOUD: 'cloud',
    CLOUD_UPLOAD: 'cloud-upload',
    CLOUD_DOWNLOAD: 'cloud-download',
    CLOUD_RAIN: 'cloud-rain',
    CLOUD_HAILSTONES: 'cloud-hailstones',
    CLOUD_SNOW: 'cloud-snow',
    CLOUD_WINDY: 'cloud-windy',
    SUN_WIND: 'sun-wind',
    CLOUD_FOG: 'cloud-fog',
    CLOUD_SUN: 'cloud-sun',
    CLOUD_LIGHTNING: 'cloud-lightning',
    CLOUD_SYNC: 'cloud-sync',
    CLOUD_LOCK: 'cloud-lock',
    CLOUD_GEAR: 'cloud-gear',
    CLOUD_ALERT: 'cloud-alert',
    CLOUD_CHECK: 'cloud-check',
    CLOUD_CROSS: 'cloud-cross',
    CLOUD_CROSSED: 'cloud-crossed',
    CLOUD_DATABASE: 'cloud-database',
    DATABASE: 'database',
    DATABASE_ADD: 'database-add',
    DATABASE_REMOVE: 'database-remove',
    DATABASE_LOCK: 'database-lock',
    DATABASE_REFRESH: 'database-refresh',
    DATABASE_CHECK: 'database-check',
    DATABASE_HISTORY: 'database-history',
    DATABASE_UPLOAD: 'database-upload',
    DATABASE_DOWNLOAD: 'database-download',
    SERVER: 'server',
    SHIELD: 'shield',
    SHIELD_CHECK: 'shield-check',
    SHIELD_ALERT: 'shield-alert',
    SHIELD_CROSS: 'shield-cross',
    LOCK: 'lock',
    ROTATION_LOCK: 'rotation-lock',
    UNLOCK: 'unlock',
    KEY: 'key',
    KEY_HOLE: 'key-hole',
    TOGGLE_OFF: 'toggle-off',
    TOGGLE_ON: 'toggle-on',
    COG: 'cog',
    COG2: 'cog2',
    WRENCH: 'wrench',
    SCREWDRIVER: 'screwdriver',
    HAMMER_WRENCH: 'hammer-wrench',
    HAMMER: 'hammer',
    SAW: 'saw',
    AXE: 'axe',
    AXE2: 'axe2',
    SHOVEL: 'shovel',
    PICKAXE: 'pickaxe',
    FACTORY: 'factory',
    FACTORY2: 'factory2',
    RECYCLE: 'recycle',
    TRASH: 'trash',
    TRASH2: 'trash2',
    TRASH3: 'trash3',
    BROOM: 'broom',
    GAME: 'game',
    GAMEPAD: 'gamepad',
    JOYSTICK: 'joystick',
    DICE: 'dice',
    SPADES: 'spades',
    DIAMONDS: 'diamonds',
    CLUBS: 'clubs',
    HEARTS: 'hearts',
    HEART: 'heart',
    STAR: 'star',
    STAR_HALF: 'star-half',
    STAR_EMPTY: 'star-empty',
    FLAG: 'flag',
    FLAG2: 'flag2',
    FLAG3: 'flag3',
    MAILBOX_FULL: 'mailbox-full',
    MAILBOX_EMPTY: 'mailbox-empty',
    AT_SIGN: 'at-sign',
    ENVELOPE: 'envelope',
    ENVELOPE_OPEN: 'envelope-open',
    PAPERCLIP: 'paperclip',
    PAPER_PLANE: 'paper-plane',
    REPLY: 'reply',
    REPLY_ALL: 'reply-all',
    INBOX: 'inbox',
    INBOX2: 'inbox2',
    OUTBOX: 'outbox',
    BOX: 'box',
    ARCHIVE: 'archive',
    ARCHIVE2: 'archive2',
    DRAWERS: 'drawers',
    DRAWERS2: 'drawers2',
    DRAWERS3: 'drawers3',
    EYE: 'eye',
    EYE_CROSSED: 'eye-crossed',
    EYE_PLUS: 'eye-plus',
    EYE_MINUS: 'eye-minus',
    BINOCULARS: 'binoculars',
    BINOCULARS2: 'binoculars2',
    HDD: 'hdd',
    HDD_DOWN: 'hdd-down',
    HDD_UP: 'hdd-up',
    FLOPPY_DISK: 'floppy-disk',
    DISC: 'disc',
    TAPE2: 'tape2',
    PRINTER: 'printer',
    SHREDDER: 'shredder',
    FILE_EMPTY: 'file-empty',
    FILE_ADD: 'file-add',
    FILE_CHECK: 'file-check',
    FILE_LOCK: 'file-lock',
    FILES: 'files',
    COPY: 'copy',
    COMPARE: 'compare',
    FOLDER: 'folder',
    FOLDER_SEARCH: 'folder-search',
    FOLDER_PLUS: 'folder-plus',
    FOLDER_MINUS: 'folder-minus',
    FOLDER_DOWNLOAD: 'folder-download',
    FOLDER_UPLOAD: 'folder-upload',
    FOLDER_STAR: 'folder-star',
    FOLDER_HEART: 'folder-heart',
    FOLDER_USER: 'folder-user',
    FOLDER_SHARED: 'folder-shared',
    FOLDER_MUSIC: 'folder-music',
    FOLDER_PICTURE: 'folder-picture',
    FOLDER_FILM: 'folder-film',
    SCISSORS: 'scissors',
    PASTE: 'paste',
    CLIPBOARD_EMPTY: 'clipboard-empty',
    CLIPBOARD_PENCIL: 'clipboard-pencil',
    CLIPBOARD_TEXT: 'clipboard-text',
    CLIPBOARD_CHECK: 'clipboard-check',
    CLIPBOARD_DOWN: 'clipboard-down',
    CLIPBOARD_LEFT: 'clipboard-left',
    CLIPBOARD_ALERT: 'clipboard-alert',
    CLIPBOARD_USER: 'clipboard-user',
    REGISTER: 'register',
    ENTER: 'enter',
    EXIT: 'exit',
    PAPERS: 'papers',
    NEWS: 'news',
    READING: 'reading',
    TYPEWRITER: 'typewriter',
    DOCUMENT: 'document',
    DOCUMENT2: 'document2',
    GRADUATION_HAT: 'graduation-hat',
    LICENSE: 'license',
    LICENSE2: 'license2',
    MEDAL_EMPTY: 'medal-empty',
    MEDAL_FIRST: 'medal-first',
    MEDAL_SECOND: 'medal-second',
    MEDAL_THIRD: 'medal-third',
    PODIUM: 'podium',
    TROPHY: 'trophy',
    TROPHY2: 'trophy2',
    MUSIC_NOTE: 'music-note',
    MUSIC_NOTE2: 'music-note2',
    MUSIC_NOTE3: 'music-note3',
    PLAYLIST: 'playlist',
    PLAYLIST_ADD: 'playlist-add',
    GUITAR: 'guitar',
    TRUMPET: 'trumpet',
    ALBUM: 'album',
    SHUFFLE: 'shuffle',
    REPEAT_ONE: 'repeat-one',
    REPEAT: 'repeat',
    HEADPHONES: 'headphones',
    HEADSET: 'headset',
    LOUDSPEAKER: 'loudspeaker',
    EQUALIZER: 'equalizer',
    THEATER: 'theater',
    _3D_GLASSES: '3d-glasses',
    TICKET: 'ticket',
    PRESENTATION: 'presentation',
    PLAY: 'play',
    FILM_PLAY: 'film-play',
    CLAPBOARD_PLAY: 'clapboard-play',
    MEDIA: 'media',
    FILM: 'film',
    FILM2: 'film2',
    SURVEILLANCE: 'surveillance',
    SURVEILLANCE2: 'surveillance2',
    CAMERA: 'camera',
    CAMERA_CROSSED: 'camera-crossed',
    CAMERA_PLAY: 'camera-play',
    TIME_LAPSE: 'time-lapse',
    RECORD: 'record',
    CAMERA2: 'camera2',
    CAMERA_FLIP: 'camera-flip',
    PANORAMA: 'panorama',
    TIME_LAPSE2: 'time-lapse2',
    SHUTTER: 'shutter',
    SHUTTER2: 'shutter2',
    FACE_DETECTION: 'face-detection',
    FLARE: 'flare',
    CONVEX: 'convex',
    CONCAVE: 'concave',
    PICTURE: 'picture',
    PICTURE2: 'picture2',
    PICTURE3: 'picture3',
    PICTURES: 'pictures',
    BOOK: 'book',
    AUDIO_BOOK: 'audio-book',
    BOOK2: 'book2',
    BOOKMARK: 'bookmark',
    BOOKMARK2: 'bookmark2',
    LABEL: 'label',
    LIBRARY: 'library',
    LIBRARY2: 'library2',
    CONTACTS: 'contacts',
    PROFILE: 'profile',
    PORTRAIT: 'portrait',
    PORTRAIT2: 'portrait2',
    USER: 'user',
    USER_PLUS: 'user-plus',
    USER_MINUS: 'user-minus',
    USER_LOCK: 'user-lock',
    USERS: 'users',
    USERS2: 'users2',
    USERS_PLUS: 'users-plus',
    USERS_MINUS: 'users-minus',
    GROUP_WORK: 'group-work',
    WOMAN: 'woman',
    MAN: 'man',
    BABY: 'baby',
    BABY2: 'baby2',
    BABY3: 'baby3',
    BABY_BOTTLE: 'baby-bottle',
    WALK: 'walk',
    HAND_WAVING: 'hand-waving',
    JUMP: 'jump',
    RUN: 'run',
    WOMAN2: 'woman2',
    MAN2: 'man2',
    MAN_WOMAN: 'man-woman',
    HEIGHT: 'height',
    WEIGHT: 'weight',
    SCALE: 'scale',
    BUTTON: 'button',
    BOW_TIE: 'bow-tie',
    TIE: 'tie',
    SOCKS: 'socks',
    SHOE: 'shoe',
    SHOES: 'shoes',
    HAT: 'hat',
    PANTS: 'pants',
    SHORTS: 'shorts',
    FLIP_FLOPS: 'flip-flops',
    SHIRT: 'shirt',
    HANGER: 'hanger',
    LAUNDRY: 'laundry',
    STORE: 'store',
    HAIRCUT: 'haircut',
    STORE_24: 'store-24',
    BARCODE: 'barcode',
    BARCODE2: 'barcode2',
    BARCODE3: 'barcode3',
    CASHIER: 'cashier',
    BAG: 'bag',
    BAG2: 'bag2',
    CART: 'cart',
    CART_EMPTY: 'cart-empty',
    CART_FULL: 'cart-full',
    CART_PLUS: 'cart-plus',
    CART_PLUS2: 'cart-plus2',
    CART_ADD: 'cart-add',
    CART_REMOVE: 'cart-remove',
    CART_EXCHANGE: 'cart-exchange',
    TAG: 'tag',
    TAGS: 'tags',
    RECEIPT: 'receipt',
    WALLET: 'wallet',
    CREDIT_CARD: 'credit-card',
    CASH_DOLLAR: 'cash-dollar',
    CASH_EURO: 'cash-euro',
    CASH_POUND: 'cash-pound',
    CASH_YEN: 'cash-yen',
    BAG_DOLLAR: 'bag-dollar',
    BAG_EURO: 'bag-euro',
    BAG_POUND: 'bag-pound',
    BAG_YEN: 'bag-yen',
    COIN_DOLLAR: 'coin-dollar',
    COIN_EURO: 'coin-euro',
    COIN_POUND: 'coin-pound',
    COIN_YEN: 'coin-yen',
    CALCULATOR: 'calculator',
    CALCULATOR2: 'calculator2',
    ABACUS: 'abacus',
    VAULT: 'vault',
    TELEPHONE: 'telephone',
    PHONE_LOCK: 'phone-lock',
    PHONE_WAVE: 'phone-wave',
    PHONE_PAUSE: 'phone-pause',
    PHONE_OUTGOING: 'phone-outgoing',
    PHONE_INCOMING: 'phone-incoming',
    PHONE_IN_OUT: 'phone-in-out',
    PHONE_ERROR: 'phone-error',
    PHONE_SIP: 'phone-sip',
    PHONE_PLUS: 'phone-plus',
    PHONE_MINUS: 'phone-minus',
    VOICEMAIL: 'voicemail',
    DIAL: 'dial',
    TELEPHONE2: 'telephone2',
    PUSHPIN: 'pushpin',
    PUSHPIN2: 'pushpin2',
    MAP_MARKER: 'map-marker',
    MAP_MARKER_USER: 'map-marker-user',
    MAP_MARKER_DOWN: 'map-marker-down',
    MAP_MARKER_CHECK: 'map-marker-check',
    MAP_MARKER_CROSSED: 'map-marker-crossed',
    RADAR: 'radar',
    COMPASS2: 'compass2',
    MAP: 'map',
    MAP2: 'map2',
    LOCATION: 'location',
    ROAD_SIGN: 'road-sign',
    CALENDAR_EMPTY: 'calendar-empty',
    CALENDAR_CHECK: 'calendar-check',
    CALENDAR_CROSS: 'calendar-cross',
    CALENDAR_31: 'calendar-31',
    CALENDAR_FULL: 'calendar-full',
    CALENDAR_INSERT: 'calendar-insert',
    CALENDAR_TEXT: 'calendar-text',
    CALENDAR_USER: 'calendar-user',
    MOUSE: 'mouse',
    MOUSE_LEFT: 'mouse-left',
    MOUSE_RIGHT: 'mouse-right',
    MOUSE_BOTH: 'mouse-both',
    KEYBOARD: 'keyboard',
    KEYBOARD_UP: 'keyboard-up',
    KEYBOARD_DOWN: 'keyboard-down',
    DELETE: 'delete',
    SPELL_CHECK: 'spell-check',
    ESCAPE: 'escape',
    ENTER2: 'enter2',
    SCREEN: 'screen',
    ASPECT_RATIO: 'aspect-ratio',
    SIGNAL: 'signal',
    SIGNAL_LOCK: 'signal-lock',
    SIGNAL_80: 'signal-80',
    SIGNAL_60: 'signal-60',
    SIGNAL_40: 'signal-40',
    SIGNAL_20: 'signal-20',
    SIGNAL_0: 'signal-0',
    SIGNAL_BLOCKED: 'signal-blocked',
    SIM: 'sim',
    FLASH_MEMORY: 'flash-memory',
    USB_DRIVE: 'usb-drive',
    PHONE: 'phone',
    SMARTPHONE: 'smartphone',
    SMARTPHONE_NOTIFICATION: 'smartphone-notification',
    SMARTPHONE_VIBRATION: 'smartphone-vibration',
    SMARTPHONE_EMBED: 'smartphone-embed',
    SMARTPHONE_WAVES: 'smartphone-waves',
    TABLET: 'tablet',
    TABLET2: 'tablet2',
    LAPTOP: 'laptop',
    LAPTOP_PHONE: 'laptop-phone',
    DESKTOP: 'desktop',
    LAUNCH: 'launch',
    NEW_TAB: 'new-tab',
    WINDOW: 'window',
    CABLE: 'cable',
    CABLE2: 'cable2',
    TV: 'tv',
    RADIO: 'radio',
    REMOTE_CONTROL: 'remote-control',
    POWER_SWITCH: 'power-switch',
    POWER: 'power',
    POWER_CROSSED: 'power-crossed',
    FLASH_AUTO: 'flash-auto',
    LAMP: 'lamp',
    FLASHLIGHT: 'flashlight',
    LAMPSHADE: 'lampshade',
    CORD: 'cord',
    OUTLET: 'outlet',
    BATTERY_POWER: 'battery-power',
    BATTERY_EMPTY: 'battery-empty',
    BATTERY_ALERT: 'battery-alert',
    BATTERY_ERROR: 'battery-error',
    BATTERY_LOW1: 'battery-low1',
    BATTERY_LOW2: 'battery-low2',
    BATTERY_LOW3: 'battery-low3',
    BATTERY_MID1: 'battery-mid1',
    BATTERY_MID2: 'battery-mid2',
    BATTERY_MID3: 'battery-mid3',
    BATTERY_FULL: 'battery-full',
    BATTERY_CHARGING: 'battery-charging',
    BATTERY_CHARGING2: 'battery-charging2',
    BATTERY_CHARGING3: 'battery-charging3',
    BATTERY_CHARGING4: 'battery-charging4',
    BATTERY_CHARGING5: 'battery-charging5',
    BATTERY_CHARGING6: 'battery-charging6',
    BATTERY_CHARGING7: 'battery-charging7',
    CHIP: 'chip',
    CHIP_X64: 'chip-x64',
    CHIP_X86: 'chip-x86',
    BUBBLE: 'bubble',
    BUBBLES: 'bubbles',
    BUBBLE_DOTS: 'bubble-dots',
    BUBBLE_ALERT: 'bubble-alert',
    BUBBLE_QUESTION: 'bubble-question',
    BUBBLE_TEXT: 'bubble-text',
    BUBBLE_PENCIL: 'bubble-pencil',
    BUBBLE_PICTURE: 'bubble-picture',
    BUBBLE_VIDEO: 'bubble-video',
    BUBBLE_USER: 'bubble-user',
    BUBBLE_QUOTE: 'bubble-quote',
    BUBBLE_HEART: 'bubble-heart',
    BUBBLE_EMOTICON: 'bubble-emoticon',
    BUBBLE_ATTACHMENT: 'bubble-attachment',
    PHONE_BUBBLE: 'phone-bubble',
    QUOTE_OPEN: 'quote-open',
    QUOTE_CLOSE: 'quote-close',
    DNA: 'dna',
    HEART_PULSE: 'heart-pulse',
    PULSE: 'pulse',
    SYRINGE: 'syringe',
    PILLS: 'pills',
    FIRST_AID: 'first-aid',
    LIFEBUOY: 'lifebuoy',
    BANDAGE: 'bandage',
    BANDAGES: 'bandages',
    THERMOMETER: 'thermometer',
    MICROSCOPE: 'microscope',
    BRAIN: 'brain',
    BEAKER: 'beaker',
    SKULL: 'skull',
    BONE: 'bone',
    constRUCTION: 'construction',
    constRUCTION_CONE: 'construction-cone',
    PIE_CHART: 'pie-chart',
    PIE_CHART2: 'pie-chart2',
    GRAPH: 'graph',
    CHART_GROWTH: 'chart-growth',
    CHART_BARS: 'chart-bars',
    CHART_SETTINGS: 'chart-settings',
    CAKE: 'cake',
    GIFT: 'gift',
    BALLOON: 'balloon',
    RANK: 'rank',
    RANK2: 'rank2',
    RANK3: 'rank3',
    CROWN: 'crown',
    LOTUS: 'lotus',
    DIAMOND: 'diamond',
    DIAMOND2: 'diamond2',
    DIAMOND3: 'diamond3',
    DIAMOND4: 'diamond4',
    LINEARICONS: 'linearicons',
    TEACUP: 'teacup',
    TEAPOT: 'teapot',
    GLASS: 'glass',
    BOTTLE2: 'bottle2',
    GLASS_COCKTAIL: 'glass-cocktail',
    GLASS2: 'glass2',
    DINNER: 'dinner',
    DINNER2: 'dinner2',
    CHEF: 'chef',
    SCALE2: 'scale2',
    EGG: 'egg',
    EGG2: 'egg2',
    EGGS: 'eggs',
    PLATTER: 'platter',
    STEAK: 'steak',
    HAMBURGER: 'hamburger',
    HOTDOG: 'hotdog',
    PIZZA: 'pizza',
    SAUSAGE: 'sausage',
    CHICKEN: 'chicken',
    FISH: 'fish',
    CARROT: 'carrot',
    CHEESE: 'cheese',
    BREAD: 'bread',
    ICE_CREAM: 'ice-cream',
    ICE_CREAM2: 'ice-cream2',
    CANDY: 'candy',
    LOLLIPOP: 'lollipop',
    COFFEE_BEAN: 'coffee-bean',
    COFFEE_CUP: 'coffee-cup',
    CHERRY: 'cherry',
    GRAPES: 'grapes',
    CITRUS: 'citrus',
    APPLE: 'apple',
    LEAF: 'leaf',
    LANDSCAPE: 'landscape',
    PINE_TREE: 'pine-tree',
    TREE: 'tree',
    CACTUS: 'cactus',
    PAW: 'paw',
    FOOTPRINT: 'footprint',
    SPEED_SLOW: 'speed-slow',
    SPEED_MEDIUM: 'speed-medium',
    SPEED_FAST: 'speed-fast',
    ROCKET: 'rocket',
    HAMMER2: 'hammer2',
    BALANCE: 'balance',
    BRIEFCASE: 'briefcase',
    LUGGAGE_WEIGHT: 'luggage-weight',
    DOLLY: 'dolly',
    PLANE: 'plane',
    PLANE_CROSSED: 'plane-crossed',
    HELICOPTER: 'helicopter',
    TRAFFIC_LIGHTS: 'traffic-lights',
    SIREN: 'siren',
    ROAD: 'road',
    ENGINE: 'engine',
    OIL_PRESSURE: 'oil-pressure',
    COOLANT_TEMPERATURE: 'coolant-temperature',
    CAR_BATTERY: 'car-battery',
    GAS: 'gas',
    GALLON: 'gallon',
    TRANSMISSION: 'transmission',
    CAR: 'car',
    CAR_WASH: 'car-wash',
    CAR_WASH2: 'car-wash2',
    BUS: 'bus',
    BUS2: 'bus2',
    CAR2: 'car2',
    PARKING: 'parking',
    CAR_LOCK: 'car-lock',
    TAXI: 'taxi',
    CAR_SIREN: 'car-siren',
    CAR_WASH3: 'car-wash3',
    CAR_WASH4: 'car-wash4',
    AMBULANCE: 'ambulance',
    TRUCK: 'truck',
    TRAILER: 'trailer',
    SCALE_TRUCK: 'scale-truck',
    TRAIN: 'train',
    SHIP: 'ship',
    SHIP2: 'ship2',
    ANCHOR: 'anchor',
    BOAT: 'boat',
    BICYCLE: 'bicycle',
    BICYCLE2: 'bicycle2',
    DUMBBELL: 'dumbbell',
    BENCH_PRESS: 'bench-press',
    SWIM: 'swim',
    FOOTBALL: 'football',
    BASEBALL_BAT: 'baseball-bat',
    BASEBALL: 'baseball',
    TENNIS: 'tennis',
    TENNIS2: 'tennis2',
    PING_PONG: 'ping-pong',
    HOCKEY: 'hockey',
    _8BALL: '8ball',
    BOWLING: 'bowling',
    BOWLING_PINS: 'bowling-pins',
    GOLF: 'golf',
    GOLF2: 'golf2',
    ARCHERY: 'archery',
    SLINGSHOT: 'slingshot',
    SOCCER: 'soccer',
    BASKETBALL: 'basketball',
    CUBE: 'cube',
    _3D_ROTATE: '3d-rotate',
    PUZZLE: 'puzzle',
    GLASSES: 'glasses',
    GLASSES2: 'glasses2',
    ACCESSIBILITY: 'accessibility',
    WHEELCHAIR: 'wheelchair',
    WALL: 'wall',
    FENCE: 'fence',
    WALL2: 'wall2',
    ICONS: 'icons',
    RESIZE_HANDLE: 'resize-handle',
    ICONS2: 'icons2',
    SELECT: 'select',
    SELECT2: 'select2',
    SITE_MAP: 'site-map',
    EARTH: 'earth',
    EARTH_LOCK: 'earth-lock',
    NETWORK: 'network',
    NETWORK_LOCK: 'network-lock',
    PLANET: 'planet',
    HAPPY: 'happy',
    SMILE: 'smile',
    GRIN: 'grin',
    TONGUE: 'tongue',
    SAD: 'sad',
    WINK: 'wink',
    DREAM: 'dream',
    SHOCKED: 'shocked',
    SHOCKED2: 'shocked2',
    TONGUE2: 'tongue2',
    NEUTRAL: 'neutral',
    HAPPY_GRIN: 'happy-grin',
    COOL: 'cool',
    MAD: 'mad',
    GRIN_EVIL: 'grin-evil',
    EVIL: 'evil',
    WOW: 'wow',
    ANNOYED: 'annoyed',
    WONDERING: 'wondering',
    CONFUSED: 'confused',
    ZIPPED: 'zipped',
    GRUMPY: 'grumpy',
    MUSTACHE: 'mustache',
    TOMBSTONE_HIPSTER: 'tombstone-hipster',
    TOMBSTONE: 'tombstone',
    GHOST: 'ghost',
    GHOST_HIPSTER: 'ghost-hipster',
    HALLOWEEN: 'halloween',
    CHRISTMAS: 'christmas',
    EASTER_EGG: 'easter-egg',
    MUSTACHE2: 'mustache2',
    MUSTACHE_GLASSES: 'mustache-glasses',
    PIPE: 'pipe',
    ALARM: 'alarm',
    ALARM_ADD: 'alarm-add',
    ALARM_SNOOZE: 'alarm-snooze',
    ALARM_RINGING: 'alarm-ringing',
    BULLHORN: 'bullhorn',
    HEARING: 'hearing',
    VOLUME_HIGH: 'volume-high',
    VOLUME_MEDIUM: 'volume-medium',
    VOLUME_LOW: 'volume-low',
    VOLUME: 'volume',
    MUTE: 'mute',
    LAN: 'lan',
    LAN2: 'lan2',
    WIFI: 'wifi',
    WIFI_LOCK: 'wifi-lock',
    WIFI_BLOCKED: 'wifi-blocked',
    WIFI_MID: 'wifi-mid',
    WIFI_LOW: 'wifi-low',
    WIFI_LOW2: 'wifi-low2',
    WIFI_ALERT: 'wifi-alert',
    WIFI_ALERT_MID: 'wifi-alert-mid',
    WIFI_ALERT_LOW: 'wifi-alert-low',
    WIFI_ALERT_LOW2: 'wifi-alert-low2',
    STREAM: 'stream',
    STREAM_CHECK: 'stream-check',
    STREAM_ERROR: 'stream-error',
    STREAM_ALERT: 'stream-alert',
    COMMUNICATION: 'communication',
    COMMUNICATION_CROSSED: 'communication-crossed',
    BROADCAST: 'broadcast',
    ANTENNA: 'antenna',
    SATELLITE: 'satellite',
    SATELLITE2: 'satellite2',
    MIC: 'mic',
    MIC_MUTE: 'mic-mute',
    MIC2: 'mic2',
    SPOTLIGHTS: 'spotlights',
    HOURGLASS: 'hourglass',
    LOADING: 'loading',
    LOADING2: 'loading2',
    LOADING3: 'loading3',
    REFRESH: 'refresh',
    REFRESH2: 'refresh2',
    UNDO: 'undo',
    REDO: 'redo',
    JUMP2: 'jump2',
    UNDO2: 'undo2',
    REDO2: 'redo2',
    SYNC: 'sync',
    REPEAT_ONE2: 'repeat-one2',
    SYNC_CROSSED: 'sync-crossed',
    SYNC2: 'sync2',
    REPEAT_ONE3: 'repeat-one3',
    SYNC_CROSSED2: 'sync-crossed2',
    RETURN: 'return',
    RETURN2: 'return2',
    REFUND: 'refund',
    HISTORY: 'history',
    HISTORY2: 'history2',
    SELF_TIMER: 'self-timer',
    CLOCK: 'clock',
    CLOCK2: 'clock2',
    CLOCK3: 'clock3',
    WATCH: 'watch',
    ALARM2: 'alarm2',
    ALARM_ADD2: 'alarm-add2',
    ALARM_REMOVE: 'alarm-remove',
    ALARM_CHECK: 'alarm-check',
    ALARM_ERROR: 'alarm-error',
    TIMER: 'timer',
    TIMER_CROSSED: 'timer-crossed',
    TIMER2: 'timer2',
    TIMER_CROSSED2: 'timer-crossed2',
    DOWNLOAD: 'download',
    UPLOAD: 'upload',
    DOWNLOAD2: 'download2',
    UPLOAD2: 'upload2',
    ENTER_UP: 'enter-up',
    ENTER_DOWN: 'enter-down',
    ENTER_LEFT: 'enter-left',
    ENTER_RIGHT: 'enter-right',
    EXIT_UP: 'exit-up',
    EXIT_DOWN: 'exit-down',
    EXIT_LEFT: 'exit-left',
    EXIT_RIGHT: 'exit-right',
    ENTER_UP2: 'enter-up2',
    ENTER_DOWN2: 'enter-down2',
    ENTER_VERTICAL: 'enter-vertical',
    ENTER_LEFT2: 'enter-left2',
    ENTER_RIGHT2: 'enter-right2',
    ENTER_HORIZONTAL: 'enter-horizontal',
    EXIT_UP2: 'exit-up2',
    EXIT_DOWN2: 'exit-down2',
    EXIT_LEFT2: 'exit-left2',
    EXIT_RIGHT2: 'exit-right2',
    CLI: 'cli',
    BUG: 'bug',
    CODE: 'code',
    FILE_CODE: 'file-code',
    FILE_IMAGE: 'file-image',
    FILE_ZIP: 'file-zip',
    FILE_AUDIO: 'file-audio',
    FILE_VIDEO: 'file-video',
    FILE_PREVIEW: 'file-preview',
    FILE_CHARTS: 'file-charts',
    FILE_STATS: 'file-stats',
    FILE_SPREADSHEET: 'file-spreadsheet',
    LINK: 'link',
    UNLINK: 'unlink',
    LINK2: 'link2',
    UNLINK2: 'unlink2',
    THUMBS_UP: 'thumbs-up',
    THUMBS_DOWN: 'thumbs-down',
    THUMBS_UP2: 'thumbs-up2',
    THUMBS_DOWN2: 'thumbs-down2',
    THUMBS_UP3: 'thumbs-up3',
    THUMBS_DOWN3: 'thumbs-down3',
    SHARE: 'share',
    SHARE2: 'share2',
    SHARE3: 'share3',
    MAGNIFIER: 'magnifier',
    FILE_SEARCH: 'file-search',
    FIND_REPLACE: 'find-replace',
    ZOOM_IN: 'zoom-in',
    ZOOM_OUT: 'zoom-out',
    LOUPE: 'loupe',
    LOUPE_ZOOM_IN: 'loupe-zoom-in',
    LOUPE_ZOOM_OUT: 'loupe-zoom-out',
    CROSS: 'cross',
    MENU: 'menu',
    LIST: 'list',
    LIST2: 'list2',
    LIST3: 'list3',
    MENU2: 'menu2',
    LIST4: 'list4',
    MENU3: 'menu3',
    EXCLAMATION: 'exclamation',
    QUESTION: 'question',
    CHECK: 'check',
    CROSS2: 'cross2',
    PLUS: 'plus',
    MINUS: 'minus',
    PERCENT: 'percent',
    CHEVRON_UP: 'chevron-up',
    CHEVRON_DOWN: 'chevron-down',
    CHEVRON_LEFT: 'chevron-left',
    CHEVRON_RIGHT: 'chevron-right',
    CHEVRONS_EXPAND_VERTICAL: 'chevrons-expand-vertical',
    CHEVRONS_EXPAND_HORIZONTAL: 'chevrons-expand-horizontal',
    CHEVRONS_CONTRACT_VERTICAL: 'chevrons-contract-vertical',
    CHEVRONS_CONTRACT_HORIZONTAL: 'chevrons-contract-horizontal',
    ARROW_UP: 'arrow-up',
    ARROW_DOWN: 'arrow-down',
    ARROW_LEFT: 'arrow-left',
    ARROW_RIGHT: 'arrow-right',
    ARROW_UP_RIGHT: 'arrow-up-right',
    ARROWS_MERGE: 'arrows-merge',
    ARROWS_SPLIT: 'arrows-split',
    ARROW_DIVERT: 'arrow-divert',
    ARROW_RETURN: 'arrow-return',
    EXPAND: 'expand',
    CONTRACT: 'contract',
    EXPAND2: 'expand2',
    CONTRACT2: 'contract2',
    MOVE: 'move',
    TAB: 'tab',
    ARROW_WAVE: 'arrow-wave',
    EXPAND3: 'expand3',
    EXPAND4: 'expand4',
    CONTRACT3: 'contract3',
    NOTIFICATION: 'notification',
    WARNING: 'warning',
    NOTIFICATION_CIRCLE: 'notification-circle',
    QUESTION_CIRCLE: 'question-circle',
    MENU_CIRCLE: 'menu-circle',
    CHECKMARK_CIRCLE: 'checkmark-circle',
    CROSS_CIRCLE: 'cross-circle',
    PLUS_CIRCLE: 'plus-circle',
    CIRCLE_MINUS: 'circle-minus',
    PERCENT_CIRCLE: 'percent-circle',
    ARROW_UP_CIRCLE: 'arrow-up-circle',
    ARROW_DOWN_CIRCLE: 'arrow-down-circle',
    ARROW_LEFT_CIRCLE: 'arrow-left-circle',
    ARROW_RIGHT_CIRCLE: 'arrow-right-circle',
    CHEVRON_UP_CIRCLE: 'chevron-up-circle',
    CHEVRON_DOWN_CIRCLE: 'chevron-down-circle',
    CHEVRON_LEFT_CIRCLE: 'chevron-left-circle',
    CHEVRON_RIGHT_CIRCLE: 'chevron-right-circle',
    BACKWARD_CIRCLE: 'backward-circle',
    FIRST_CIRCLE: 'first-circle',
    PREVIOUS_CIRCLE: 'previous-circle',
    STOP_CIRCLE: 'stop-circle',
    PLAY_CIRCLE: 'play-circle',
    PAUSE_CIRCLE: 'pause-circle',
    NEXT_CIRCLE: 'next-circle',
    LAST_CIRCLE: 'last-circle',
    FORWARD_CIRCLE: 'forward-circle',
    EJECT_CIRCLE: 'eject-circle',
    CROP: 'crop',
    FRAME_EXPAND: 'frame-expand',
    FRAME_CONTRACT: 'frame-contract',
    FOCUS: 'focus',
    TRANSFORM: 'transform',
    GRID: 'grid',
    GRID_CROSSED: 'grid-crossed',
    LAYERS: 'layers',
    LAYERS_CROSSED: 'layers-crossed',
    TOGGLE: 'toggle',
    RULERS: 'rulers',
    RULER: 'ruler',
    FUNNEL: 'funnel',
    FLIP_HORIZONTAL: 'flip-horizontal',
    FLIP_VERTICAL: 'flip-vertical',
    FLIP_HORIZONTAL2: 'flip-horizontal2',
    FLIP_VERTICAL2: 'flip-vertical2',
    ANGLE: 'angle',
    ANGLE2: 'angle2',
    SUBTRACT: 'subtract',
    COMBINE: 'combine',
    INTERSECT: 'intersect',
    EXCLUDE: 'exclude',
    ALIGN_CENTER_VERTICAL: 'align-center-vertical',
    ALIGN_RIGHT: 'align-right',
    ALIGN_BOTTOM: 'align-bottom',
    ALIGN_LEFT: 'align-left',
    ALIGN_CENTER_HORIZONTAL: 'align-center-horizontal',
    ALIGN_TOP: 'align-top',
    SQUARE: 'square',
    PLUS_SQUARE: 'plus-square',
    MINUS_SQUARE: 'minus-square',
    PERCENT_SQUARE: 'percent-square',
    ARROW_UP_SQUARE: 'arrow-up-square',
    ARROW_DOWN_SQUARE: 'arrow-down-square',
    ARROW_LEFT_SQUARE: 'arrow-left-square',
    ARROW_RIGHT_SQUARE: 'arrow-right-square',
    CHEVRON_UP_SQUARE: 'chevron-up-square',
    CHEVRON_DOWN_SQUARE: 'chevron-down-square',
    CHEVRON_LEFT_SQUARE: 'chevron-left-square',
    CHEVRON_RIGHT_SQUARE: 'chevron-right-square',
    CHECK_SQUARE: 'check-square',
    CROSS_SQUARE: 'cross-square',
    MENU_SQUARE: 'menu-square',
    PROHIBITED: 'prohibited',
    CIRCLE: 'circle',
    RADIO_BUTTON: 'radio-button',
    LIGATURE: 'ligature',
    TEXT_FORMAT: 'text-format',
    TEXT_FORMAT_REMOVE: 'text-format-remove',
    TEXT_SIZE: 'text-size',
    BOLD: 'bold',
    ITALIC: 'italic',
    UNDERLINE: 'underline',
    STRIKETHROUGH: 'strikethrough',
    HIGHLIGHT: 'highlight',
    TEXT_ALIGN_LEFT: 'text-align-left',
    TEXT_ALIGN_CENTER: 'text-align-center',
    TEXT_ALIGN_RIGHT: 'text-align-right',
    TEXT_ALIGN_JUSTIFY: 'text-align-justify',
    LINE_SPACING: 'line-spacing',
    INDENT_INCREASE: 'indent-increase',
    INDENT_DECREASE: 'indent-decrease',
    TEXT_WRAP: 'text-wrap',
    PILCROW: 'pilcrow',
    DIRECTION_LTR: 'direction-ltr',
    DIRECTION_RTL: 'direction-rtl',
    PAGE_BREAK: 'page-break',
    PAGE_BREAK2: 'page-break2',
    SORT_ALPHA_ASC: 'sort-alpha-asc',
    SORT_ALPHA_DESC: 'sort-alpha-desc',
    SORT_NUMERIC_ASC: 'sort-numeric-asc',
    SORT_NUMERIC_DESC: 'sort-numeric-desc',
    SORT_AMOUNT_ASC: 'sort-amount-asc',
    SORT_AMOUNT_DESC: 'sort-amount-desc',
    SORT_TIME_ASC: 'sort-time-asc',
    SORT_TIME_DESC: 'sort-time-desc',
    SIGMA: 'sigma',
    PENCIL_LINE: 'pencil-line',
    HAND: 'hand',
    POINTER_UP: 'pointer-up',
    POINTER_RIGHT: 'pointer-right',
    POINTER_DOWN: 'pointer-down',
    POINTER_LEFT: 'pointer-left',
    FINGER_TAP: 'finger-tap',
    FINGERS_TAP: 'fingers-tap',
    REMINDER: 'reminder',
    FINGERS_CROSSED: 'fingers-crossed',
    FINGERS_VICTORY: 'fingers-victory',
    GESTURE_ZOOM: 'gesture-zoom',
    GESTURE_PINCH: 'gesture-pinch',
    FINGERS_SCROLL_HORIZONTAL: 'fingers-scroll-horizontal',
    FINGERS_SCROLL_VERTICAL: 'fingers-scroll-vertical',
    FINGERS_SCROLL_LEFT: 'fingers-scroll-left',
    FINGERS_SCROLL_RIGHT: 'fingers-scroll-right',
    HAND2: 'hand2',
    POINTER_UP2: 'pointer-up2',
    POINTER_RIGHT2: 'pointer-right2',
    POINTER_DOWN2: 'pointer-down2',
    POINTER_LEFT2: 'pointer-left2',
    FINGER_TAP2: 'finger-tap2',
    FINGERS_TAP2: 'fingers-tap2',
    REMINDER2: 'reminder2',
    GESTURE_ZOOM2: 'gesture-zoom2',
    GESTURE_PINCH2: 'gesture-pinch2',
    FINGERS_SCROLL_HORIZONTAL2: 'fingers-scroll-horizontal2',
    FINGERS_SCROLL_VERTICAL2: 'fingers-scroll-vertical2',
    FINGERS_SCROLL_LEFT2: 'fingers-scroll-left2',
    FINGERS_SCROLL_RIGHT2: 'fingers-scroll-right2',
    FINGERS_SCROLL_VERTICAL3: 'fingers-scroll-vertical3',
    BORDER_STYLE: 'border-style',
    BORDER_ALL: 'border-all',
    BORDER_OUTER: 'border-outer',
    BORDER_INNER: 'border-inner',
    BORDER_TOP: 'border-top',
    BORDER_HORIZONTAL: 'border-horizontal',
    BORDER_BOTTOM: 'border-bottom',
    BORDER_LEFT: 'border-left',
    BORDER_VERTICAL: 'border-vertical',
    BORDER_RIGHT: 'border-right',
    BORDER_NONE: 'border-none',
    ELLIPSIS: 'ellipsis',
}


