<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
    <div
        :class="[
            'page',
            ...classNames,
            (loading ? '--loading' : null),
            (stretchFirstContainer ? '--stretch' : null),
        ]"
        :style="{
            backgroundColor: (skewedHeaderForeground ? skewedHeaderForeground : null),
            marginTop,
        }"
    >
        <div
            :class="[
                'page__pre',
                (header ? '--header' : null),
            ]"
        >
            <f-container
                v-if="header"
                :max-width="preMaxWidth"
            >
                <header class="page__pre__header">
                    <h1>
                        <span v-html="header"/>
                        <f-badge
                            v-if="headerBadge"
                            inline
                            size="large"
                        >
                            {{ headerBadge }}
                        </f-badge>
                    </h1>
                    <f-icon-button
                        v-if="actions && actions.length > 0"
                        ref="actions"
                        icon="ellipsis"
                        @click="actionsOpen = true"
                        :selected="actionsOpen"
                        outlined
                    />
                    <slot name="headerPre"/>
                </header>
                <slot name="pre"/>
            </f-container>
            <slot name="pre" v-else/>
        </div>

        <skewed-section
            v-if="skewedHeaderForeground"
            type="top-right"
            class="page__skewed-header"
            :foreground-color="skewedHeaderForeground"
        />

        <div :class="['page__content']">
            <slot/>
        </div>

        <skewed-section
            v-if="skewedFooterForeground"
            class="page__skewed-footer"
            type="bottom-left"
            background-color="#222"
            :foreground-color="skewedFooterForeground"
        />

        <f-popup
            v-if="actionsOpen && $refs.actions"
            :target="$refs.actions.$el"
            @close="actionsOpen = false"
            padding="0"
            align="bottom"
        >
            <f-popup-list>
                <f-popup-list-item
                    v-for="(action, i) in actions"
                    v-if="action"
                    :icon="action.icon"
                    :key="i"
                    :link="action.link"
                    :disabled="action.disabled"
                    @click="actionsOpen = false; (action.onClick ? action.onClick($event) : null);"
                >
                    {{ action.value }}
                </f-popup-list-item>
            </f-popup-list>
        </f-popup>
    </div>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import {TokenState} from '../../services/Store';
import ClassNameMixin from '../../mixins/ClassNameMixin';
import SkewedSection from '../misc/skewed-section/SkewedSection';
import FContainer from '../../f-components/layout/container/FContainer';
import FBadge from '../../f-components/data/badge/FBadge';
import FIconButton from '../../f-components/form-controls/buttons/FIconButton';
import FPopup from '../../f-components/other/popup/FPopup';
import FPopupList from '../../f-components/other/popup/FPopupList';
import FPopupListItem from '../../f-components/other/popup/FPopupListItem';
import BreakpointsMixin from '../../mixins/BreakpointsMixin';

export default {
    name: 'page',

    components: {
        FPopupListItem,
        FPopupList,
        FPopup,
        FIconButton,
        FBadge,
        FContainer,
        SkewedSection,
    },

    mixins: [
        ClassNameMixin,
        BreakpointsMixin,
    ],

    props: {
        loading: Boolean,
        stretchFirstContainer: Boolean,
        authenticated: {
            type: Boolean,
            default: true,
        },
        marginTop: String,
        title: String | Array,
        authLink: {
            type: String,
            default: '/auth',
        },
        skewedHeaderForeground: {
            type: String | Boolean,
            default: "#fff"
        },
        skewedFooterForeground: {
            type: String | Boolean,
            default: "#fff"
        },
        preMaxWidth: String,
        header: String,
        headerBadge: Number | String,
        actions: Array,
    },

    created() {
        this.scrollToTop();

        if (this.authenticated) {
            this.verifyIfAuthenticated();
        }
    },

    data() {
        return {
            actionsOpen: false,
        }
    },

    mounted() {
        this.scrollToTop();
        this.setDocumentTitle();
    },

    methods: {
        setDocumentTitle() {
            if (this.title && Array.isArray(this.title)) {
                document.title = ['Riviera', ...this.title].join(' › ');
            } else if (this.title) {
                document.title = 'Riviera › ' + this.title;
            } else {
                document.title = 'Riviera';
            }
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        /**
         * Verify if authenticated. If not, redirect to /auth.
         */
        verifyIfAuthenticated() {
            if (this.$store.getters.accessTokenState !== TokenState.VALID) {
                if (this.$router.currentRoute.fullPath !== this.authLink) {
                    window.localStorage.setItem('riviera.auth.redirect', this.$router.currentRoute.fullPath);
                } else if (!window.localStorage.getItem('riviera.auth.redirect')) {
                    window.localStorage.setItem('riviera.auth.redirect', '/')
                }
                window.localStorage.setItem('riviera.auth.page', this.authLink);
                this.$router.push(this.authLink);
            } else {
                this.afterAuthenticationVerified();
            }
        },

        /**
         * What to do after authentication.
         */
        afterAuthenticationVerified() {
            this.$emit('authenticated');
        },
    },
}
</script>

<style lang="scss">
.page {
    position: relative;
    width: 100%;
    margin: 40px auto 0;
    display: flex;
    flex-direction: column;

    @include media(tablet-down) {
        margin-top: 10px;
    }

    &__pre {
        background: $background;

        &.--header {
            padding-bottom: 100px;

            @include media(tablet-down) {
                padding-bottom: 60px;
            }
        }

        &__header {
            margin: 20px 0 0 0;
            gap: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h1 {
                margin: 0;
                font-size: 60px;
                line-height: 85%;

                @include media(tablet-down) {
                    font-size: 40px;
                }

                @include media(mobile-down) {
                    font-size: 30px;
                }

                .f-badge {
                    background: $light;
                    color: $lightFront;
                    margin: -5px 0 -5px 15px;
                }
            }
        }
    }

    &__skewed-header {
        padding-bottom: 40px;

        @include media(mobile-down) {
            padding-bottom: 20px;
        }
    }

    &__content {
        position: relative;
        z-index: 2;
        width: 100%;
        flex-grow: 1;
        min-height: 260px;
    }

    &__skewed-footer {
        height: 50px;
        z-index: 1;
    }

    &.--stretch {
        .page__content {
            display: flex;
            flex-direction: column;

            > .f-form {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
            }

            > .f-form > .f-container:first-child, > .f-container:first-child {
                flex-grow: 1;
            }
        }
    }
}
</style>
