<template>
    <div
        :class="[
            'faq',
            '--loader',
            (loading ? '--loading' : null),
            (inEditMode ? '--edit-mode' : null),
        ]"
    >
        <f-filter v-if="search">
            <f-filter-row>
                <f-filter-item>
                    <f-text-input
                        v-model.trim="searchQuery"
                        pre-icon="magnifier"
                        class-name="--align-center"
                        placeholder="Search…"
                        :disabled="inEditMode"
                        resetable
                    />
                </f-filter-item>
            </f-filter-row>
        </f-filter>

        <div
            v-if="editable && editButtonPosition === 'top' && hasPermission(Permissions.FAQ_EDIT)"
            class="faq__edit --top"
        >
            <f-button-group>
                <f-button
                    v-if="inEditMode"
                    @click="showForm"
                    outlined
                    size="small"
                    icon="plus"
                    icon-position="left"
                    value="Add category"
                />
                <f-button
                    :icon="(inEditMode ? 'cross' : 'pencil')"
                    icon-position="left"
                    @click="inEditMode = !inEditMode"
                    outlined
                    size="small"
                    :value="(inEditMode ? 'Disable editing' : 'Edit FAQ')"
                />
            </f-button-group>
            <f-help-label text="You are a FAQ admin, so you see all items, regardless of which office you belong to"/>
        </div>

        <f-table-placeholder v-if="loading && !fetchedData"/>
        <nothing-found v-if="fetchedData && items.length === 0"/>

        <draggable
            v-if="fetchedData"
            :class="[
                'draggable-container',
                (currentGroup ? `--dragging-${currentGroup}` : null),
            ]"
            group="items"
            handle=".faq-drag"
            @change="onChange"
            @start="onDragStart"
            @end="onDragEnd"
            :data-id="0"
            :list="items"
        >
            <faq-item
                v-for="item in items"
                :item="item"
                :key="item.id"
                :edit="inEditMode"
                @refresh="onRefresh"
                @change="onChange"
                @start="onDragStart"
                @end="onDragEnd"
                :render-category-as-bullet-point="renderCategoryAsBulletPoint"
            />
        </draggable>

        <div
            v-if="editable && editButtonPosition === 'bottom' && hasPermission(Permissions.FAQ_EDIT)"
            class="faq__edit --bottom"
        >
            <f-button-group>
                <f-button
                    v-if="inEditMode"
                    @click="showForm"
                    outlined
                    size="small"
                    icon="plus"
                    icon-position="left"
                    value="Add category"
                />
                <f-button
                    :icon="(inEditMode ? 'cross' : 'pencil')"
                    icon-position="left"
                    @click="inEditMode = !inEditMode"
                    outlined
                    size="small"
                    :value="(inEditMode ? 'Disable editing' : 'Edit FAQ')"
                />
            </f-button-group>
            <f-help-label text="You are a FAQ admin, so you see all items, regardless of which office you belong to"/>
        </div>

        <f-modal
            v-if="inEditMode && showCategoryForm"
            @close="showCategoryForm = false"
            :title="`Create FAQ category`"
            width="800px"
        >
            <faq-edit-create-form
                form-mode="create"
                :default-places="[place]"
                :parent-id="0"
                :parent-titles="[]"
                @success="showCategoryForm = false; onRefresh()"
            />
        </f-modal>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import FaqItem from './FAQItem';
import EventBus from '../../../services/EventBus';
import FFilter from '../../../f-components/data/filter/FFilter';
import NothingFound from '../NothingFound';
import FFilterRow from '../../../f-components/data/filter/FFilterRow';
import FFilterItem from '../../../f-components/data/filter/FFilterItem';
import FTextInput from '../../../f-components/form-controls/text/FTextInput';
import EntityPaginatedFilteredOverviewMixin from '../../../mixins/entities/EntityPaginatedFilteredOverviewMixin';
import APIEndpoints from '../../../config/APIEndpoints';
import FTablePlaceholder from '../../../f-components/loaders/placeholder/FTablePlaceholder';
import {GlobalEvents} from '../../../config/Events';
import FModal from '../../../f-components/other/modal/FModal';
import FaqEditCreateForm from './FAQEditCreateForm';
import FButtonGroup from '../../../f-components/form-controls/buttons/FButtonGroup';
import FButton from '../../../f-components/form-controls/buttons/FButton';
import FHelpLabel from '../../../f-components/form-controls/forms/FHelpLabel';

export default {
    name: 'faq',

    mixins: [
        EntityPaginatedFilteredOverviewMixin,
    ],

    components: {
        FHelpLabel,
        FButton,
        FButtonGroup,
        FaqEditCreateForm,
        FModal,
        draggable,
        FTablePlaceholder,
        FTextInput,
        FFilterItem,
        FFilterRow,
        NothingFound,
        FFilter,
        FaqItem,
    },

    props: {
        search: Boolean,
        editable: Boolean,
        editButtonPosition: {
            type: String,
            default: 'top',
        },
        paginated: {
            type: Boolean,
            default: false,
        },
        place: {
            type: String,
            required: true,
        },
        renderCategoryAsBulletPoint: Boolean,
    },

    computed: {
        items: {
            get() {
                if (!this.fetchedData) return [];

                if (this.searchQuery) {
                    let dataCopy = JSON.parse(JSON.stringify(this.fetchedData.data));
                    return this.applySearch(dataCopy);
                }

                return this.fetchedData.data;
            },
            set() {
            },
        },
    },

    data() {
        return {
            searchQuery: null,
            currentGroup: null,
            showCategoryForm: false,
            list: [],
            inEditMode: false,
        }
    },

    watch: {
        inEditMode(inEditMode) {
            if (inEditMode) {
                this.searchQuery = undefined;
            }
            this.$emit('editMode', inEditMode);
        },
        dataLoaded(loaded) {
            if (loaded) {
                this.$nextTick(() => this.checkHash());
            }
        },
    },

    methods: {
        showForm() {
            this.showCategoryForm = true;
        },

        onRefresh() {
            this.$emit('refresh');
        },

        getEndpoint() {
            return APIEndpoints.FAQ;
        },

        checkHash() {
            const matchers = location.hash.match(/^#item-([0-9]+)/);
            if (matchers && matchers[1]) {
                EventBus.$emit('faqItem.forceOpen', parseInt(matchers[1]), true);
            }
        },

        getFilters() {
            return {
                'filter[place]': this.place,
            }
        },

        applySearch(faqs) {
            if (!faqs) return [];

            return faqs.filter(faq => {
                const search = [
                    faq.title,
                    faq.tags,
                ].join(' ').toLowerCase();

                faq.children = this.applySearch(faq.children);

                return search.indexOf(this.searchQuery.toLowerCase()) >= 0 || (faq.children && faq.children.length > 0);
            });
        },

        onChange() {
            // Build new list with items and their parent ID by checking DOM
            const list = this.getChildren(this.$el);

            this.startLoading();

            // Save to DB
            window.axios.post(
                `${APIEndpoints.FAQ}/order`,
                {
                    '_method': 'PUT',
                    'list': list,
                }
            )
                .then(() => {
                    this.endLoading(true);
                    this.fetchItems();
                })
                .catch(() => this.endLoading(true));
        },

        getChildren(parent, parentID = 0, list = []) {
            const selector = (parentID === 0 ?
                `:scope [data-id="${parentID}"] > [data-id]` :
                `:scope > .faq-item__content > .faq-item__children > [data-id]`);
            let children = parent.querySelectorAll(selector);

            for (let i in children) {
                if (children[i] instanceof Element) {
                    const child = {
                        id: parseInt(children[i].getAttribute('data-id')),
                        parent_id: parentID,
                    };
                    list.push(child);
                    list = this.getChildren(children[i], child.id, list);
                }
            }
            return list;
        },

        onDragStart(e) {
            this.currentGroup = e.from.dataset.group;
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },

        onDragEnd() {
            this.currentGroup = null;
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },
    },
}
</script>

<style lang="scss">
.faq {
    .draggable-container > div:first-child > .faq-item__category {
        margin-top: 0;
    }

    .f-filter {
        max-width: 360px;
        margin-bottom: 80px;
    }

    &__edit {
        text-align: center;

        &.--top {
            margin-bottom: 50px;
        }
    }

    .draggable-container {
        border: 1px dashed transparent;
        border-radius: 10px;

        &.--dragging-level-0 {
            border-color: $ctaOrange;
        }

        .sortable-chosen {
            border: 1px solid $ctaOrange;
            border-radius: 5px;
        }
    }
}
</style>
