<template>
    <f-portal reference="modal">
        <transition name="fade-small" appear>
            <f-center
                :class-name="[
                    'f-modal',
                    (backdrop ? '--backdrop' : undefined),
                    ...classNames,
                ]"
                :align-items="alignItems"
                fixed
            >
                <slot name="pre"/>

                <f-panel
                    class-name="f-modal__container"
                    content-class-name="--loader"
                    :heading="title"
                    :loading="loading"
                    :style="{ width, height }"
                    :variant="panelVariant"
                >
                    <template #head>
                        <slot name="head"/>

                        <f-close
                            v-if="closeButton"
                            @click="close"
                        />
                    </template>

                    <slot/>
                </f-panel>
            </f-center>
        </transition>
    </f-portal>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import FClose from '../../navigation/close/FClose';
import FPanel from '../panel/FPanel';
import FCenter from '../../layout/center/FCenter';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import FPortal from '../portal/FPortal';
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-modal',

    mixins: [
        ClassNameMixin,
    ],

    components: {
        FPortal,
        FCenter,
        FPanel,
        FClose,
    },

    props: {
        closeButton: {
            type: Boolean,
            default: true,
        },
        loading: Boolean,
        title: String | Array,
        width: String,
        height: String,
        emitOpenEventName: {
            type: String,
            default: GlobalEvents.MODAL_OPEN,
        },
        emitCloseEventName: {
            type: String,
            default: GlobalEvents.MODAL_CLOSE,
        },
        backdrop: {
            type: Boolean,
            default: true,
        },
        alignItems: {
            type: String,
            default: 'center',
        },
        panelVariant: {
            type: String,
            default: 'dark',
        },
    },

    mounted() {
        if (this.closeButton) {
            EventBus.$on(GlobalEvents.KEYDOWN_ESC, this.close);
        }
        if (this.emitOpenEventName) {
            EventBus.$emit(this.emitOpenEventName);
        }
    },

    beforeDestroy() {
        if (this.closeButton) {
            EventBus.$off(GlobalEvents.KEYDOWN_ESC, this.close);
        }
        if (this.emitCloseEventName) {
            EventBus.$emit(this.emitCloseEventName);
        }
    },

    methods: {
        close() {
            this.$emit('close');
        },
    },
}
</script>

<style lang="scss">
.f-modal {
    z-index: $modalOrder;

    &.--backdrop {
        background: rgba($dark, .3);
        backdrop-filter: blur(10px);
    }

    &__container {
        &.f-panel {
            max-width: calc(100vw - 20px);
            max-height: calc(100vh - 20px);
            margin-left: auto;
            margin-right: auto;
            margin-top: inherit;
            margin-bottom: inherit;
            text-align: left;
            box-shadow: rgba(white, .25) 0 0 0 4px;

            .f-close {
                top: 17px;
                right: 17px;
                z-index: 2;

                @include media(mobile-down) {
                    top: 10px;
                }
            }
        }
    }
}
</style>
