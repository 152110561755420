<template>
    <div class="entity-header">
        <div class="entity-header__wrapper">
            <div class="entity-header__wrapper__image">
                <entity-avatar
                    v-if="image || name"
                    :src="image"
                    :name="name || title"
                    size="xlarge"
                />
                <slot name="image"/>
            </div>

            <div class="entity-header__wrapper__content">
                <h2 v-if="title" v-text="title"/>
                <slot/>
            </div>

            <slot name="post"/>
        </div>
        <f-form-row-spacer
            v-if="line"
            line
        />
        <slot name="footer"/>
    </div>
</template>

<script>
import EntityAvatar from './EntityAvatar';
import FFormRowSpacer from '../../../f-components/form-controls/forms/FFormRowSpacer';

export default {
    name: 'entity-header',

    components: {
        FFormRowSpacer,
        EntityAvatar,
    },

    props: {
        image: String,
        name: String,
        title: String,
        line: Boolean,
    },
}
</script>

<style lang="scss">
.entity-header {
    margin: 20px -20px 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 10px;

    @include theme(light) {
        background: $softGray;
    }

    @include media(tablet-down) {
        padding: 15px;
        margin: 20px 0 40px;
    }

    .f-form-row-spacer {
        margin: 0 !important;
    }

    &__wrapper {
        padding: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 10px;

        &__image {
            @include media(mobile-down) {
                display: none;
            }

            .f-placeholder {
                height: 80px !important;
                width: 80px !important;
                border-radius: 50%;
            }
        }

        &__content {
            flex: 1;
            display: inline-block;
            vertical-align: middle;

            h2 {
                margin: 0;
                font-size: 160%;
                font-weight: 600;
                text-align: left;
                line-height: 110%;
                text-overflow: ellipsis;
            }

            > div {
                margin: 1px 0;
            }
        }
    }
}
</style>
