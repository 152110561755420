export const GlobalEvents = {
    ALERT_SHOW: 'alert',

    TOOLTIP_SHOW: 'tooltip.show',
    TOOLTIP_HIDE: 'tooltip.hide',

    PAGE_CHANGE: 'page.change',

    LOGIN_FAILURE: 'login.failure', // On login failure
    LOGIN_SUCCESS: 'login.success', // On login success
    LOGOUT: 'logout', // Trigger logout
    LOGOUT_SUCCESS: 'logout.success', // On logout success

    ONESIGNAL_STATE_CHANGE: 'oneSignal.state.change',

    WINDOW_RESIZE: 'window.resize',

    KEYDOWN_ESC: 'document.keydown.esc',
    KEYDOWN_DOWN: 'document.keydown.down',
    KEYDOWN_RIGHT: 'document.keydown.right',
    KEYDOWN_LEFT: 'document.keydown.left',
    KEYDOWN_UP: 'document.keydown.up',
    KEYDOWN_ENTER: 'document.keydown.enter',

    SITE_LOAD_START: 'site.load',
    SITE_LOAD_FINISH: 'site.load.finish',

    MINUTE_CHANGE: 'minute.change',

    PUSH_MESSAGE: 'pushMessage',

    USER_CARD_SHOW: 'userCard.show',
    USER_CARD_HIDE: 'userCard.hide',

    OVERFLOW_SCROLL: 'scroller.scroll',

    MODAL_OPEN: 'modal.open',
    MODAL_CLOSE: 'modal.close',

    INPUT_FOCUS: 'input.focus',

    PROFILE_OPEN: 'profile.open',
    PROFILE_CLOSE: 'profile.close',

    NOTIFICATIONS_OPEN: 'notifications.open',
    NOTIFICATIONS_CLOSE: 'notifications.close',

    TUTORIAL_OPEN: 'tutorial.open',

    GALLERY_OPEN: 'gallery.open',
};
