import store from './Store';
import EventBus from './EventBus';
import APIDataStore from './APIDataStore';
import {GlobalEvents} from '../config/Events';

const ErrorHandler = {
    /**
     * Axios error interceptor.
     *
     * @param error
     * @returns {Promise}
     */
    onAxiosGeneralErrorInterceptor(error) {
        if (!error.response || !error.response.status) {
            this.onApiInternalServerError();

            return Promise.reject(error); // Continue axios flow
        }

        switch (error.response.status) {
            case 401:
                this.onApiUnauthorizedError(error, () => {
                    APIDataStore.clearAllIntervals();
                    store.commit('invalidateAccessToken');
                    window.location = '/auth';
                });
                break; // 401 error means token is not valid, needs new login
            case 403:
                this.onApiForbiddenError();
                break;
            case 500:
                this.onApiInternalServerError(error);
                break;
            case 503:
                this.onApiMaintenanceError();
                break;
            case 400:
            case 404:
                this.onApiDefaultError(error);
        }

        return Promise.reject(error); // Continue axios flow
    },

    /**
     * On 401 unauthorized error.
     */
    onApiUnauthorizedError(error, onConfirm) {
        if (error && error.data && error.data.message) {
            error = error.data.message;
        }

        if (location.pathname === '/auth' || location.pathname === '/meeting-room/auth') {
            return;
        }

        EventBus.$emit(
            GlobalEvents.ALERT_SHOW,
            {
                type: 'error',
                code: 401,
                title: 'Login expired',
                body: 'Your login has expired. Please log in again to continue.',
                confirmButtonText: 'Proceed to log-in',
                onConfirm: () => {
                    if (onConfirm) {
                        onConfirm();
                    }
                },
            }
        );
    },

    /**
     * On 403 forbidden error.
     */
    onApiForbiddenError() {
        EventBus.$emit(
            GlobalEvents.ALERT_SHOW,
            {
                type: 'warning',
                code: 403,
                title: 'Forbidden',
                body: "You don't have the necessary permissions to view this resource.",
            }
        );
    },

    /**
     * On 503 server maintenance error.
     */
    onApiMaintenanceError() {
        console.warn('API Maintenance Error');

        EventBus.$emit(
            GlobalEvents.ALERT_SHOW,
            {
                once: true,
                type: 'error',
                code: 503,
                title: 'Server maintenance',
                body: "Riviera's servers are currently being maintained, and will be functioning very shortly.",
                closeButton: false,
                confirmButton: false,
            }
        );
    },

    /**
     * On 500 internal server error.
     */
    onApiInternalServerError() {
        console.warn('500 Server Error');

        EventBus.$emit(
            GlobalEvents.PUSH_MESSAGE,
            'We are having some issues communicating with the servers. Some items may not be working correctly.',
            'error'
        );
    },

    /**
     * On default server error.
     */
    onApiDefaultError(error) {
        console.error(error);

        if (error.response && error.response.data && (error.response.data.message || error.response.data.error)) {
            EventBus.$emit(GlobalEvents.PUSH_MESSAGE, (error.response.data.message || error.response.data.error), 'error');
        } else {
            EventBus.$emit(GlobalEvents.PUSH_MESSAGE, 'Unknown error occurred.', 'error');
        }
    },

    /**
     * On general error handler.
     */
    onGeneralError(error) {
        console.error(error);
    },

    /**
     * On general fatal error handler.
     *
     * @param error The error message, or error obj. The method tries to extract a message from an obj.
     * @param backupError The backup error to show when no msg.
     */
    onGeneralFatalError(error, backupError) {
        if (error && error.data && error.data.message) {
            error = error.data.message;
        } else if (error && error.response && error.response.data && (error.response.data.message || error.response.data.error)) {
            error = (error.response.data.message || error.response.data.error);
        } else if (error && error.message) {
            error = error.message;
        }

        console.error(error);

        EventBus.$emit(GlobalEvents.PUSH_MESSAGE, (error ? error : backupError), 'error');
    },
};

export default ErrorHandler;
