<template>
    <li
        :class="[
            'navigation-item',
            ...classNames,
            (forceHover ? '--hover' : null),
        ]"
        v-tooltip="{
            text: title,
            align: 'bottom',
        }"
        @mouseenter="$emit('mouseenter')"
    >
        <router-link
            v-if="link"
            :to="link"
            @click.native="EventBus.$emit('mobile.menu.close')"
        >
            <f-icon :icon="icon"/>
        </router-link>
        <a v-else-if="!to" @click="$emit('click', $event)">
            <f-icon :icon="icon"/>
        </a>

        <f-badge v-if="badge">
            {{ badge > 99 ? '99+' : badge }}
        </f-badge>

        <slot/>
    </li>
</template>

<script>
import FBadge from '../../../../../f-components/data/badge/FBadge';
import FIcon from '../../../../../f-components/layout/icons/FIcon';
import ClassNameMixin from '../../../../../mixins/ClassNameMixin';

export default {
    name: 'navigation-item',

    mixins: [
        ClassNameMixin,
    ],

    components: {
        FIcon,
        FBadge,
    },

    props: {
        to: String,
        badge: [Number, String],
        link: String,
        icon: {
            type: String,
            default: 'mail',
        },
        title: String,
        forceHover: Boolean,
    },
}
</script>

<style lang="scss">
.navigation-item {
    cursor: pointer;
    position: relative;

    img {
        height: 26px;
    }

    .f-badge {
        top: 0;
        right: 0;
    }

    &.--hover a, a:hover {
        text-decoration: none;
        background: rgba($dark, .15);
    }

    a {
        vertical-align: middle;
        display: inline-block;
        color: $dark;
        transition: background-color .1s;
        padding: 7px 0;
        text-align: center;
        height: 33px;
        width: 33px;
        border-radius: 50%;
        text-decoration: none;

        .f-icon {
            font-size: 150%;
        }

        .label {
            display: none;
        }
    }
}
</style>
