<template>
    <page
        v-if="hasPermission(Permissions.PROJECTS_VIEW)"
        class-name="project-show-page"
        :loading="loading"
        :title="['Projects', 'View']"
        header="View project"
        :skewed-footer-foreground="((!project && loading) || loadingError ? '#fff' : null)"
        :actions="actions"
        stretch-first-container
        pre-max-width="1050px"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb link="/projects">Projects</f-breadcrumb>
                <f-breadcrumb :loading="!(project && project.name)">
                    {{ project && project.number ? project.number + ' &mdash; ' : '' }}
                    {{ project && project.name ? project.name : '' }}
                </f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-container max-width="1050px">
            <f-inline-message
                v-if="loadingError"
                id="projectLoadError"
                :closable="false"
                variant="danger"
            >
                {{ loadingError }}
            </f-inline-message>

            <entity-header
                v-if="project"
                :image="(clientFromId(project.client_id) ? clientFromId(project.client_id).image_url : null)"
                :name="(clientFromId(project.client_id) ? clientFromId(project.client_id).name : (project.supplemental_data && project.supplemental_data.company_name ? project.supplemental_data.company_name : '?'))"
                :title="project.name"
            >
                <!--:line="!!(project && project.planning_start_date && project.planning_end_date)"-->
                <span class="subtitle" v-if="project.client_id || project.number">
                    {{ project.office_id.ucwords() }} office
                    <subtitle-spacer/>
                    <span v-if="clientFromId(project.client_id)">
                        {{ clientFromId(project.client_id).name }}
                    </span>
                    <span v-else-if="project.supplemental_data && project.supplemental_data.company_name">
                        {{ project.supplemental_data.company_name }}
                    </span>
                </span>

                <project-dates-visualizer
                    :start-date="project.start_date"
                    :planning-start-date="project.planning_start_date"
                    :planning-end-date="project.planning_end_date"
                />

                <project-codes :project="project"/>

                <div
                    v-if="project.supplemental_data.tags && project.supplemental_data.tags.length > 0"
                    class="project-show-page__tags"
                >
                    <f-chip
                        v-for="(tag, x) in project.supplemental_data.tags"
                        :key="x"
                        variant="info"
                    >
                        {{ tag }}
                    </f-chip>
                </div>

                <template #post>
                    <div class="--align-right --hide-mobile">
                        <div class="project-show-page__users">
                            <user-card-mini
                                v-if="project.responsible_user_id"
                                :user="project.responsible_user_id ? userById(project.responsible_user_id) : {}"
                                variant="dark"
                            />
                            <f-button
                                v-else-if="hasEditPermission"
                                size="small"
                                @click="onResponsibleUserCardClick"
                            >
                                Set responsible employee
                            </f-button>
                        </div>

                        <div v-if="project.is_archived">
                            <f-chip variant="archived">archived</f-chip>
                        </div>
                    </div>
                </template>

                <template #footer v-if="project && project.planning_start_date && project.planning_end_date">
                    <f-month-filter
                        v-if="project && project.planning_start_date && project.planning_end_date"
                        :start-date="project.planning_start_date"
                        :end-date="project.planning_end_date"
                        v-model="reportDateRangeFilter"
                    />
                </template>
            </entity-header>

            <f-entity-header-placeholder v-if="!project && loading"/>

            <project-report-analysis
                v-if="project && project.report.hours.departments && Object.keys(project.report.hours.departments).length > 0"
                :project-id="project.id"
                :report="project.report"
                :currency="currency"
                allow-discipline-change
                @userAction="onUserActionClick"
                @lineAction="onLineActionClick"
            />
        </f-container>

        <skewed-section
            v-if="project && project.report"
            foreground-color="#333"
            after-background="#222"
            padding="100px 0 150px"
            margin="100px 0 -50px"
        >
            <f-theme-provider variant="dark">
                <f-container max-width="900px">
                    <f-tabs
                        margin
                        @tabChange="tabId = $event"
                    >
                        <f-tab id="project-totals" title="Project totals"/>
                        <f-tab
                            v-if="excludedAllocations"
                            id="excluded-allocations"
                            title="Excluded alloc."
                            :badge="`${excludedAllocations}`"
                        />
                        <f-tab id="invoices" title="Invoices"/>
                        <f-tab id="allocations" title="Allocations"/>
                        <f-tab id="notes" title="Notes" :badge="(hasNotes ? '•' : undefined)"/>
                        <f-tab v-if="hasPermission(Permissions.PROJECTS_ADMIN)" id="logs" title="Logs"/>
                    </f-tabs>

                    <div v-if="excludedAllocations && tabId === 'excluded-allocations'">
                        <excluded-allocations :report="project.report"/>
                    </div>

                    <div v-if="tabId === 'project-totals'">
                        <project-totals v-if="!reportDateRangeFilter" :report="project.report"/>
                        <f-inline-message v-else id="reportDateRangeProjectTotals" :closable="false">
                            Project totals are disabled when you have a month range applied.
                        </f-inline-message>
                    </div>

                    <div v-if="tabId === 'invoices'">
                        <invoices-list
                            v-if="project.report.invoices.length > 0"
                            :report="project.report"
                        />
                        <nothing-found name="invoices" v-else/>
                    </div>

                    <div v-if="tabId === 'allocations'">
                        <f-inline-message
                            v-if="reportDateRangeFilter"
                            id="reportDateRangeProjectTotals"
                            :closable="false"
                            style="margin-bottom: 40px;"
                        >
                            The selected month range is applied to the allocations.
                        </f-inline-message>
                        <allocations-list
                            v-if="project.report.hours.allocations.all.length > 0"
                            :start-date="(reportDateRangeFilter ? reportDateRangeFilter[0] : project.report.planning_start_date)"
                            :end-date="(reportDateRangeFilter ? reportDateRangeFilter[1] : project.report.planning_end_date)"
                            :assignments="project.report.hours.allocations.all"
                        />
                        <nothing-found name="allocations" v-else/>
                    </div>

                    <div v-if="tabId === 'notes'">
                        <div v-if="hasNotes && !notesEditorActive">
                            <f-content v-html="project.notes"/>
                            <f-button
                                v-if="hasPermission(Permissions.PROJECTS_EDIT)"
                                icon="pencil5"
                                value="Edit notes"
                                @click="onNotesEditorActivate"
                                variant="primary"
                                style="margin-top: 20px;"
                                size="small"
                            />
                        </div>
                        <div
                            v-else-if="!notesEditorActive"
                            class="project-show-page__notes-empty"
                        >
                            <f-button
                                v-if="hasPermission(Permissions.PROJECTS_EDIT)"
                                icon="pencil5"
                                value="Add notes"
                                @click="onNotesEditorActivate"
                                variant="primary"
                                size="small"
                            />
                            <span v-else>No notes added.</span>
                        </div>

                        <div
                            v-if="hasPermission(Permissions.PROJECTS_EDIT) && notesEditorActive"
                            class="project-show-page__notes-editor"
                        >
                            <f-editor v-model="notes"/>
                            <footer>
                                <f-button
                                    icon="cross"
                                    value="Cancel"
                                    size="small"
                                    outlined
                                    variant="light"
                                    @click="onNotesEditorCancel"
                                />
                                <f-button
                                    icon="floppy-disk"
                                    value="Save"
                                    size="small"
                                    variant="primary"
                                    @click="onNotesEditorSave"
                                />
                            </footer>
                        </div>
                    </div>

                    <div v-if="tabId === 'logs'">
                        <logs-panel
                            v-if="hasPermission(Permissions.PROJECTS_ADMIN) && project"
                            :logs="project.logs"
                            :debug-logs="project.report.debug"
                            :external-debug-logs="project.report.external_debug"
                            :latest-debug-logs="project.report.latest_debug"
                        />
                    </div>
                </f-container>
            </f-theme-provider>
        </skewed-section>

        <f-modal
            v-if="orderLinesPopupActive"
            title="Manage order lines"
            default-active
            width="600px"
            @close="orderLinesPopupActive = false"
            class-name="project-show-page__order-lines-popup"
        >
            <project-order-lines-form
                :default-data="project"
                :external-project-keys="project.report.external_keys"
                max-scroller-height="60vh"
                @formValidAndSubmit="updateOrderLinesConfiguration"
            />
        </f-modal>

        <f-modal
            v-if="responsibleEmployeePopupActive"
            width="400px"
            @close="responsibleEmployeePopupActive = false"
            class-name="project-show-page__extra-popup"
            :title="(project.responsible_user_id ? 'Change responsible employee' : 'Set responsible employee')"
        >
            <change-responsible-employee-form
                :data="project"
                @updateField="updateField"
            />
        </f-modal>

        <f-modal
            v-if="hasPermission(Permissions.PROJECTS_ADMIN) && project && changeDisciplineModalActive"
            @close="changeDisciplineModalActive = false"
            width="600px"
            title="Change discipline"
        >
            <change-discipline-form
                :user="userActionsUser"
                :project-id="project.id"
                :current-configuration="(project.supplemental_data.disciplines_configuration ? project.supplemental_data.disciplines_configuration : [])"
                @done="fetch(); changeDisciplineModalActive = false;"
            />
        </f-modal>

        <f-popup
            v-if="hasPermission(Permissions.PROJECTS_ADMIN) && userActionsTarget"
            :target="userActionsTarget"
            padding="0"
            @close="userActionsTarget = null"
        >
            <f-popup-list>
                <f-popup-list-item
                    icon="tag"
                    @click="changeDisciplineModalActive = true; userActionsTarget = null;"
                >
                    Change discipline
                </f-popup-list-item>
            </f-popup-list>
        </f-popup>

        <f-popup
            v-if="hasPermission(Permissions.PROJECTS_ADMIN) && lineActionsTarget"
            :target="lineActionsTarget"
            padding="0"
            @close="lineActionsTarget = null"
        >
            <f-popup-list>
                <f-popup-list-item
                    icon="list3"
                    @click="orderLinesPopupActive = true; lineActionsTarget = null;"
                >
                    Mark as purchase or hidden
                </f-popup-list-item>
            </f-popup-list>
        </f-popup>
    </page>
</template>

<script>
/* eslint-disable vue/return-in-computed-property */
import APIEndpoints from '../../../config/APIEndpoints';
import Page from '../Page';
import FBreadcrumb from '../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import FBreadcrumbs from '../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import EntityHeader from '../../misc/entities/EntityHeader';
import UserCardMini from '../../cards/users/UserCardMini';
import FChip from '../../../f-components/data/chip/FChip';
import FButton from '../../../f-components/form-controls/buttons/FButton';
import NumbersAndBudgetMixin from '../../../mixins/NumbersAndBudgetMixin';
import ClientHelperMixin from '../../../mixins/ClientHelperMixin';
import UserHelperMixin from '../../../mixins/UserHelperMixin';
import FPopupList from '../../../f-components/other/popup/FPopupList';
import FPopupListItem from '../../../f-components/other/popup/FPopupListItem';
import ProjectReportAnalysis from "../../misc/project-analysis/ProjectReportAnalysis";
import AllocationsList from "../../misc/project-analysis/allocations-list/AllocationsList";
import FEditor from "../../../f-components/form-controls/editor/FEditor";
import FModal from "../../../f-components/other/modal/FModal";
import ProjectDatesVisualizer from "./components/ProjectDatesVisualizer";
import ProjectTotals from "../../misc/project-analysis/ProjectTotals";
import ChangeResponsibleEmployeeForm from "./components/ChangeResposibleEmployeeForm";
import LogsPanel from "../../misc/project-analysis/LogsPanel";
import FPopup from "../../../f-components/other/popup/FPopup";
import ProjectsMixin from "./ProjectsMixin";
import ChangeDisciplineForm from "../../misc/project-analysis/ChangeDisciplineForm";
import FInlineMessage from '../../../f-components/notice/inline-message/FInlineMessage';
import InvoicesList from '../../misc/project-analysis/InvoicesList';
import ProjectCodes from './components/ProjectCodes';
import ProjectOrderLinesForm from './components/ProjectOrderLinesForm';
import {Permissions} from '../../../config/Permissions';
import LoadingMixin from '../../../mixins/LoadingMixin';
import FContainer from '../../../f-components/layout/container/FContainer';
import FEntityHeaderPlaceholder from '../../../f-components/loaders/placeholder/FEntityHeaderPlaceholder';
import SkewedSection from '../../misc/skewed-section/SkewedSection';
import FThemeProvider from '../../../f-components/layout/theme/FThemeProvider';
import FTabs from '../../../f-components/navigation/tabs/FTabs';
import FTab from '../../../f-components/navigation/tabs/FTab';
import NothingFound from '../../misc/NothingFound';
import SubtitleSpacer from '../../misc/SubtitleSpacer';
import ExcludedAllocations from '../../misc/project-analysis/ExcludedAllocations';
import FMonthFilter from '../../../f-components/data/filter/FMonthFilter';
import FContent from '../../../f-components/layout/content/FContent';

export default {
    name: 'project-show-page',

    components: {
        FContent,
        FMonthFilter,
        ExcludedAllocations,
        SubtitleSpacer,
        NothingFound,
        FTab,
        FTabs,
        FThemeProvider,
        SkewedSection,
        FEntityHeaderPlaceholder,
        FContainer,
        ProjectOrderLinesForm,
        ProjectCodes,
        InvoicesList,
        FInlineMessage,
        ChangeDisciplineForm,
        FPopup,
        LogsPanel,
        ChangeResponsibleEmployeeForm,
        ProjectTotals,
        ProjectDatesVisualizer,
        FModal,
        FEditor,
        AllocationsList,
        ProjectReportAnalysis,
        FPopupListItem,
        FPopupList,
        FButton,
        FChip,
        UserCardMini,
        EntityHeader,
        FBreadcrumbs,
        FBreadcrumb,
        Page,
    },

    mixins: [
        NumbersAndBudgetMixin,
        ClientHelperMixin,
        UserHelperMixin,
        ProjectsMixin,
        LoadingMixin,
    ],

    data() {
        return {
            id: this.$route.params.id,
            project: undefined,
            tabId: null,

            notesEditorActive: false,
            notes: null,

            responsibleEmployeePopupActive: false,
            orderLinesPopupActive: false,

            userActionsTarget: null,
            userActionsUser: null,
            changeDisciplineModalActive: false,

            lineActionsTarget: null,
            lineActionsLine: null,

            hasEditPermission: this.hasPermission(Permissions.PROJECTS_EDIT),

            reportDateRangeFilter: undefined,
            filterTimeOut: undefined,
        }
    },

    watch: {
        reportDateRangeFilter() {
            this.onFilterChange();
        },
    },

    computed: {
        actions() {
            if (!this.project) {
                return [];
            }

            const options = [{
                value: (this.favourites.includes(this.project.id) ? 'Remove as favorite' : 'Add as favorite'),
                icon: (this.favourites.includes(this.project.id) ? 'power-crossed' : 'power'),
                onClick: () => this.toggleFavourite(this.project.id),
            }];

            // Manage order lines & extra
            if (this.hasPermission(Permissions.PROJECTS_EDIT)) {
                options.push({
                    value: 'Manage order lines',
                    icon: 'list3',
                    onClick: () => this.orderLinesPopupActive = true,
                });

                options.push({
                    value: (this.project.responsible_user_id ? 'Change responsible employee' : 'Set responsible employee'),
                    icon: 'user',
                    onClick: () => this.responsibleEmployeePopupActive = true,
                });
            }

            // Unmerge
            if (this.hasPermission(Permissions.PROJECTS_MERGE)) {
                options.push({
                    value: 'Unmerge',
                    icon: 'exclude',
                    onClick: () => this.unmerge(this.project.id),
                    disabled: !(this.project.linked_external_projects && this.project.linked_external_projects.gripp_supplemental && this.project.linked_external_projects.gripp_supplemental.length > 0),
                });
            }

            // Force update
            options.push({
                value: 'Force update',
                icon: 'sync',
                onClick: () => this.forceUpdate(this.project.id),
            });

            if (this.hasPermission(Permissions.PROJECTS_ADMIN)) {
                options.push({
                    value: 'Force update (ignore archive)',
                    icon: 'sync',
                    onClick: () => this.forceUpdate(this.project.id, true),
                });
            }

            // Archive
            // if (this.hasPermission(Permissions.PROJECTS_ARCHIVE)) {
            //     options.push({
            //         value: (this.project.is_archived ? 'Remove from archive' : 'Archive'),
            //         icon: 'archive',
            //         onClick: () => this.setArchived(this.project.id, !this.project.is_archived),
            //     });
            // }

            // External URLs
            if (this.project.supplemental_data.external_projects) {
                for (let i in this.project.supplemental_data.external_projects) {
                    if (
                        !Object.prototype.hasOwnProperty.call(this.project.supplemental_data.external_projects, i) ||
                        !this.project.supplemental_data.external_projects[i].harvest
                    ) {
                        continue;
                    }

                    options.push({
                        value: `Forecast planning ${i}`,
                        icon: 'launch',
                        onClick: () => window.open(this.project.supplemental_data.external_projects[i].harvest.url),
                    });
                }
            }

            return options;
        },

        excludedAllocations() {
            if (!this.project || !this.project.report || !this.project.report.hours.allocations.excluded) {
                return 0;
            }
            return this.project.report.hours.allocations.excluded.length;
        },

        hasNotes() {
            return this.project && this.project.notes && this.project.notes !== '<p></p>';
        },
    },

    created() {
        this.fetch();
        this.$on('refresh', this.fetch);
    },

    methods: {
        onFilterChange() {
            if (this.filterTimeOut) {
                window.clearTimeout(this.filterTimeOut);
            }

            this.filterTimeOut = window.setTimeout(() => this.fetch(), 750);
        },

        getFilters() {
            const params = {};

            if (this.reportDateRangeFilter) {
                params.date_from = this.reportDateRangeFilter[0];
                params.date_to = this.reportDateRangeFilter[1];
            }

            return params;
        },

        fetch() {
            this.startLoading();

            window.axios
                .get(`${APIEndpoints.PROJECTS}/${this.id}?${new URLSearchParams(this.getFilters())}`)
                .then(response => {
                    this.endLoading(true);
                    this.project = response.data.data;
                })
                .catch((e) => this.onLoadingError(e));
        },

        onNotesEditorActivate() {
            this.notes = (this.project && this.project.notes ? this.project.notes : '');
            this.notesEditorActive = true;
        },

        onNotesEditorCancel() {
            this.notesEditorActive = false;
        },

        onNotesEditorSave() {
            this.updateField('notes', this.notes);
            this.notesEditorActive = false;
        },

        updateField(field, value, changeData = true, onComplete) {
            this.startLoading();

            window.axios.put(`${APIEndpoints.PROJECTS}/${this.id}`, {[field]: value})
                .then(() => {
                    if (changeData) this.project[field] = value;
                    this.endLoading();
                    if (onComplete) onComplete();
                })
                .catch(() => this.endLoading());
        },

        updateOrderLinesConfiguration(value) {
            this.updateField('supplemental_data', value, false, () => this.fetch());
            this.orderLinesPopupActive = false;
        },

        onUserActionClick($event, user) {
            this.userActionsTarget = $event.target;
            this.userActionsUser = user;
        },

        onLineActionClick($event, line) {
            this.lineActionsTarget = $event.target;
            this.lineActionsLine = line;
        },

        onResponsibleUserCardClick() {
            if (this.hasPermission(Permissions.PROJECTS_EDIT)) {
                this.responsibleEmployeePopupActive = true;
            }
        },
    },
}
</script>

<style lang="scss">
.project-show-page {
    &__project-number-plus {
        color: rgba(white, .25);
    }

    .f-inline-message {
        max-width: 500px;
    }

    &__date-range {
        display: block;
        font-size: 80%;
        opacity: .5;
    }

    &__users {
        margin-bottom: 5px;

        .user-card-mini {
            margin-top: 2px;
        }
    }

    .f-chip {
        vertical-align: middle;
    }

    &__notes-empty {
        text-align: center;

        > span {
            font-style: italic;
            color: rgba(white, .5);
        }
    }

    &__notes-editor {
        footer {
            padding-top: 10px;
            display: flex;
            justify-content: space-between;
        }
    }

    &__order-lines-popup {
        .project-lines-markings-container {
            width: auto;

            .f-scroll-pane__content {
                padding-right: 10px;
            }
        }

        .f-text-input.f-abstract-input-field {
            max-width: 416px !important;
        }

        .f-form .f-form-row-spacer {
            margin-top: 5px !important;
        }
    }

    .f-month-filter__wrapper {
        padding: 0 20px 20px;
    }

    .project-report-analysis__wrapper {
        margin-top: 20px;
    }
}
</style>
