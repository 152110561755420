<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
    <div
        v-if="editor"
        class="f-editor-toolbar"
    >
        <div class="f-editor-toolbar__sub-bar">
            <f-button-group v-if="
                toolbar.includes(EditorToolbar.BOLD) ||
                toolbar.includes(EditorToolbar.ITALIC) ||
                toolbar.includes(EditorToolbar.STRIKE)
            ">
                <f-icon-button
                    v-if="toolbar.includes(EditorToolbar.BOLD)"
                    icon="bold"
                    class-name="f-editor-toolbar__button"
                    variant="themed"
                    @click="editor.chain().focus().toggleBold().run()"
                    :outlined="!editor.isActive('bold')"
                    tabindex="-1"
                    v-tooltip="'Bold'"
                    no-parent-hover
                    :disabled="sourceMode"
                />
                <f-icon-button
                    v-if="toolbar.includes(EditorToolbar.ITALIC)"
                    icon="italic"
                    class-name="f-editor-toolbar__button"
                    variant="themed"
                    @click="editor.chain().focus().toggleItalic().run()"
                    :outlined="!editor.isActive('italic')"
                    tabindex="-1"
                    v-tooltip="'Italic'"
                    no-parent-hover
                    :disabled="sourceMode"
                />
                <f-icon-button
                    v-if="toolbar.includes(EditorToolbar.UNDERLINE)"
                    icon="underline"
                    class-name="f-editor-toolbar__button"
                    variant="themed"
                    @click="editor.chain().focus().toggleUnderline().run()"
                    :outlined="!editor.isActive('underline')"
                    tabindex="-1"
                    v-tooltip="'Underline'"
                    no-parent-hover
                    :disabled="sourceMode"
                />
                <f-icon-button
                    v-if="toolbar.includes(EditorToolbar.STRIKE)"
                    icon="strikethrough"
                    class-name="f-editor-toolbar__button"
                    variant="themed"
                    @click="editor.chain().focus().toggleStrike().run()"
                    :outlined="!editor.isActive('strike')"
                    tabindex="-1"
                    v-tooltip="'Strike'"
                    no-parent-hover
                    :disabled="sourceMode"
                />
            </f-button-group>

            <!--            <f-button-group v-if="toolbar.includes(EditorToolbar.TEXT_ALIGN)">-->
            <!--                <f-icon-button-->
            <!--                    v-for="alignment in ['left', 'center', 'right']"-->
            <!--                    :key="alignment"-->
            <!--                    :icon="`text-align-${alignment}`"-->
            <!--                    class-name="f-editor-toolbar__button"-->
            <!--                    variant="themed"-->
            <!--                    @click="editor.chain().focus().setTextAlign(alignment).run()"-->
            <!--                    :outlined="!editor.isActive({ textAlign: alignment })"-->
            <!--                    tabindex="-1"-->
            <!--                    v-tooltip="`Align ${alignment}`"-->
            <!--                    no-parent-hover-->
            <!--            :disabled="sourceMode"-->
            <!--                />-->
            <!--            </f-button-group>-->

            <f-button-group v-if="toolbar.includes(EditorToolbar.LINK)">
                <f-icon-button
                    icon="link"
                    class-name="f-editor-toolbar__button"
                    variant="themed"
                    @click="setLink"
                    tabindex="-1"
                    v-tooltip="'Link'"
                    no-parent-hover
                    :outlined="!editor.isActive('link')"
                    :disabled="sourceMode"
                />
                <f-icon-button
                    icon="unlink"
                    class-name="f-editor-toolbar__button"
                    variant="themed"
                    @click="editor.chain().focus().unsetLink().run()"
                    tabindex="-1"
                    v-tooltip="'Unlink'"
                    no-parent-hover
                    ref="linkButton"
                    outlined
                    :disabled="sourceMode || !editor.isActive('link')"
                />
            </f-button-group>

            <f-button-group v-if="
                toolbar.includes(EditorToolbar.H1) ||
                toolbar.includes(EditorToolbar.H2) ||
                toolbar.includes(EditorToolbar.H3) ||
                toolbar.includes(EditorToolbar.PARAGRAPH)
            ">
                <f-icon-button
                    v-for="i in 3"
                    v-if="toolbar.includes(EditorToolbar['H' + i])"
                    :key="i"
                    icon="custom"
                    class-name="f-editor-toolbar__button"
                    variant="themed"
                    @click="editor.chain().focus().toggleHeading({ level: i }).run()"
                    :outlined="!editor.isActive('heading', { level: i })"
                    tabindex="-1"
                    v-tooltip="`Heading level ${i}`"
                    no-parent-hover
                    :disabled="sourceMode"
                >
                    H{{ i }}
                </f-icon-button>
                <f-icon-button
                    v-if="toolbar.includes(EditorToolbar.PARAGRAPH)"
                    icon="pilcrow"
                    class-name="f-editor-toolbar__button"
                    variant="themed"
                    @click="editor.chain().focus().setParagraph().run()"
                    :outlined="!editor.isActive('paragraph')"
                    tabindex="-1"
                    v-tooltip="'Paragraph'"
                    no-parent-hover
                    :disabled="sourceMode"
                />
            </f-button-group>

            <f-button-group v-if="
                toolbar.includes(EditorToolbar.BULLET_LIST) ||
                toolbar.includes(EditorToolbar.ORDERED_LIST)
            ">
                <f-icon-button
                    v-if="toolbar.includes(EditorToolbar.BULLET_LIST)"
                    icon="list"
                    class-name="f-editor-toolbar__button"
                    variant="themed"
                    @click="editor.chain().focus().toggleBulletList().run()"
                    :outlined="!editor.isActive('bulletList')"
                    tabindex="-1"
                    v-tooltip="'Bullet list'"
                    no-parent-hover
                    :disabled="sourceMode"
                />
                <f-icon-button
                    v-if="toolbar.includes(EditorToolbar.ORDERED_LIST)"
                    icon="list2"
                    class-name="f-editor-toolbar__button"
                    variant="themed"
                    @click="editor.chain().focus().toggleOrderedList().run()"
                    :outlined="!editor.isActive('orderedList')"
                    tabindex="-1"
                    v-tooltip="'Ordered list'"
                    no-parent-hover
                    :disabled="sourceMode"
                />
            </f-button-group>

            <f-button-group v-if="
                toolbar.includes(EditorToolbar.CODE) ||
                toolbar.includes(EditorToolbar.CODE_BLOCK) ||
                toolbar.includes(EditorToolbar.BLOCKQUOTE)
            ">
                <f-icon-button
                    v-if="toolbar.includes(EditorToolbar.CODE)"
                    icon="code"
                    class-name="f-editor-toolbar__button"
                    variant="themed"
                    @click="editor.chain().focus().toggleCode().run()"
                    :outlined="!editor.isActive('code')"
                    tabindex="-1"
                    v-tooltip="'Code'"
                    no-parent-hover
                    :disabled="sourceMode"
                />
                <f-icon-button
                    v-if="toolbar.includes(EditorToolbar.CODE_BLOCK)"
                    icon="file-code"
                    class-name="f-editor-toolbar__button"
                    variant="themed"
                    @click="editor.chain().focus().toggleCodeBlock().run()"
                    :outlined="!editor.isActive('codeBlock')"
                    tabindex="-1"
                    v-tooltip="'Code block'"
                    no-parent-hover
                    :disabled="sourceMode"
                />
                <f-icon-button
                    v-if="toolbar.includes(EditorToolbar.BLOCKQUOTE)"
                    icon="quote-open"
                    class-name="f-editor-toolbar__button"
                    variant="themed"
                    @click="editor.chain().focus().toggleBlockquote().run()"
                    :outlined="!editor.isActive('blockquote')"
                    tabindex="-1"
                    v-tooltip="'Quote'"
                    no-parent-hover
                    :disabled="sourceMode"
                />
            </f-button-group>

            <f-icon-button
                v-if="toolbar.includes(EditorToolbar.IMAGE) && hasPermission(Permissions.EDITOR_IMAGE_UPLOAD)"
                icon="picture"
                class-name="f-editor-toolbar__button"
                variant="themed"
                @click="imageUploadActive = true"
                outlined
                tabindex="-1"
                v-tooltip="'Add image'"
                no-parent-hover
                :disabled="sourceMode"
            />

            <f-icon-button
                v-if="toolbar.includes(EditorToolbar.HORIZONTAL_RULE)"
                icon="minus"
                class-name="f-editor-toolbar__button"
                variant="themed"
                @click="editor.chain().focus().setHorizontalRule().run()"
                outlined
                tabindex="-1"
                v-tooltip="'Horizontal rule'"
                no-parent-hover
                :disabled="sourceMode"
            />

            <f-icon-button
                v-if="toolbar.includes(EditorToolbar.HARD_BREAK)"
                icon="text-wrap"
                class-name="f-editor-toolbar__button"
                variant="themed"
                @click="editor.chain().focus().setHardBreak().run()"
                outlined
                tabindex="-1"
                v-tooltip="'Hard break'"
                no-parent-hover
                :disabled="sourceMode"
            />
        </div>

        <div class="f-editor-toolbar__sub-bar" v-if="toolbar.includes(EditorToolbar.TABLE)">
            <f-icon-button
                icon="grid"
                class-name="f-editor-toolbar__button"
                variant="themed"
                @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()"
                :outlined="!editor.isActive('table')"
                tabindex="-1"
                v-tooltip="'Insert table'"
                no-parent-hover
                :disabled="sourceMode"
            />
        </div>

        <div class="f-editor-toolbar__sub-bar">
            <f-button-group v-if="toolbar.includes(EditorToolbar.HISTORY)">
                <f-icon-button
                    icon="undo"
                    class-name="f-editor-toolbar__button"
                    variant="themed"
                    @click="editor.chain().focus().undo().run()"
                    outlined
                    tabindex="-1"
                    v-tooltip="'Undo'"
                    no-parent-hover
                    :disabled="sourceMode"
                />
                <f-icon-button
                    icon="redo"
                    class-name="f-editor-toolbar__button"
                    variant="themed"
                    @click="editor.chain().focus().redo().run()"
                    outlined
                    tabindex="-1"
                    v-tooltip="'Redo'"
                    no-parent-hover
                    :disabled="sourceMode"
                />
            </f-button-group>

            <f-button
                v-if="toolbar.includes(EditorToolbar.SOURCE)"
                size="small"
                class-name="f-editor-toolbar__button"
                variant="themed"
                @click="sourceMode = !sourceMode"
                :outlined="!sourceMode"
                tabindex="-1"
                value="Source"
                no-parent-hover
            />
        </div>

        <div class="f-editor-toolbar__sub-bar" v-if="editor.isActive('table')">
            <f-button-group>
                <f-button size="small" @click="editor.chain().focus().addColumnBefore().run()">
                    Add column before
                </f-button>
                <f-button size="small" @click="editor.chain().focus().addColumnAfter().run()">
                    Add column after
                </f-button>
                <f-button size="small" @click="editor.chain().focus().deleteColumn().run()">
                    Delete column
                </f-button>
            </f-button-group>
            <f-button-group>
                <f-button size="small" @click="editor.chain().focus().addRowBefore().run()">
                    Add row before
                </f-button>
                <f-button size="small" @click="editor.chain().focus().addRowAfter().run()">
                    Add row after
                </f-button>
                <f-button size="small" @click="editor.chain().focus().deleteRow().run()">
                    Delete row
                </f-button>
            </f-button-group>
            <f-button size="small" @click="editor.chain().focus().deleteTable().run()">
                Delete table
            </f-button>
            <f-button-group>
                <f-button size="small" @click="editor.chain().focus().mergeCells().run()">
                    Merge cells
                </f-button>
                <f-button size="small" @click="editor.chain().focus().splitCell().run()">
                    Split cell
                </f-button>
            </f-button-group>
            <f-button-group>
                <f-button size="small" @click="editor.chain().focus().toggleHeaderColumn().run()">
                    Toggle header column
                </f-button>
                <f-button size="small" @click="editor.chain().focus().toggleHeaderRow().run()">
                    Toggle header row
                </f-button>
                <f-button size="small" @click="editor.chain().focus().toggleHeaderCell().run()">
                    Toggle header cell
                </f-button>
            </f-button-group>
            <!--            <f-button @click="editor.chain().focus().mergeOrSplit().run()">-->
            <!--                mergeOrSplit-->
            <!--            </f-button>-->
            <!--            <f-button @click="editor.chain().focus().setCellAttribute('colspan', 2).run()">-->
            <!--                setCellAttribute-->
            <!--            </f-button>-->
            <!--            <f-button @click="editor.chain().focus().fixTables().run()">-->
            <!--                fixTables-->
            <!--            </f-button>-->
            <!--            <f-button @click="editor.chain().focus().goToNextCell().run()">-->
            <!--                goToNextCell-->
            <!--            </f-button>-->
            <!--            <f-button @click="editor.chain().focus().goToPreviousCell().run()">-->
            <!--                goToPreviousCell-->
            <!--            </f-button>-->
        </div>

        <f-editor-link-modal
            v-if="linkModalActive"
            :link="activeLink"
            @close="linkModalActive = false"
            @apply="applyLink"
        />

        <f-editor-image-upload
            v-if="imageUploadActive"
            @close="imageUploadActive = false"
            :mock="mock"
            @insert-image="insertImage"
        />
    </div>
</template>

<script>
import {EditorToolbar} from '../config';
import FIconButton from '../../buttons/FIconButton';
import FButtonGroup from '../../buttons/FButtonGroup';
import FEditorImageUpload from './FEditorImageUpload';
import FButton from '../../buttons/FButton';
import FEditorLinkModal from './FEditorLinkModal';

/**
 * @todo: table
 */
export default {
    name: 'f-editor-toolbar',

    components: {
        FEditorLinkModal,
        FButton,
        FEditorImageUpload,
        FButtonGroup,
        FIconButton,
    },

    props: {
        editor: {
            type: Object,
            default: () => {
                return {};
            },
        },
        toolbar: {
            type: Array,
            default: () => {
                return [];
            },
        },
        mock: Boolean,
    },

    data() {
        return {
            EditorToolbar: EditorToolbar,
            imageUploadActive: false,
            linkModalActive: false,
            activeLink: undefined,
            sourceMode: false,
        }
    },

    watch: {
        sourceMode(sourceMode) {
            this.$emit('source-toggle', sourceMode);
        },
    },

    methods: {
        setLink() {
            this.activeLink = this.editor.getAttributes('link').href;
            this.linkModalActive = true;
        },

        applyLink(url) {
            this.linkModalActive = false;

            // Cancelled
            if (url === null) return;

            // Empty
            if (url === '') {
                this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
                return;
            }

            // Update
            this.editor.chain().focus().extendMarkRange('link').setLink({href: url}).run();
        },

        insertImage(url) {
            this.imageUploadActive = false;
            this.editor.chain().focus().setImage({src: url}).run();
        },
    },
}
</script>

<style lang="scss">
.f-editor-toolbar {
    overflow: hidden;
    padding: 10px 10px;
    border-radius: $inputBorderRadius $inputBorderRadius 0 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;

    @include theme(light) {
        background: rgba($dark, .1);
    }

    @include theme(dark) {
        background: rgba($light, .1);
    }

    &__sub-bar {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
    }
}
</style>
