var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:[
        'public-holiday',
        (_vm.isToday ? '--today' : null),
    ]},[_c('div',{class:[
            'public-holiday__day',
            (_vm.events[0].start.isBefore() && !_vm.isToday ? '--past' : null),
        ]},[_c('div',{staticClass:"public-holiday__day__weekday"},[_vm._v(" "+_vm._s(_vm.events[0].start.format('ddd'))+" ")]),_c('div',{staticClass:"public-holiday__day__number"},[_vm._v(" "+_vm._s(_vm.events[0].start.format('DD'))+" ")])]),_c('div',{staticClass:"public-holiday__events"},_vm._l((_vm.events),function(event,i){return _c('div',{key:i,class:[
                'public-holiday__event',
                (event.start.isBefore() && !_vm.isToday ? '--past' : null),
                (_vm.getIcon(event.name) ? '--has-icon' : null),
            ]},[_c('div',{staticClass:"public-holiday__event__name"},[_vm._v(" "+_vm._s(event.name)+" ")]),_c('div',{staticClass:"public-holiday__event__labels"},[_vm._l((event.countries),function(country,i){return _c('f-chip',{key:`${country}${i}`,attrs:{"size":"small"}},[_vm._v(" "+_vm._s(country)+" ")])}),(event.bank_holiday)?_c('f-chip',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        text: `Official day off for ${_vm.getOfficesVisual(event.offices)}`,
                        variant: 'alternative',
                        wrap: true
                    }),expression:"{\n                        text: `Official day off for ${getOfficesVisual(event.offices)}`,\n                        variant: 'alternative',\n                        wrap: true\n                    }"}],attrs:{"variant":"primary","size":"small"}},[_vm._v(" bank holiday ")]):_vm._e(),([6, 7].includes(event.start.isoWeekday()))?_c('f-chip',{attrs:{"variant":"status","size":"small"}},[_vm._v(" weekend ")]):_vm._e()],2),(_vm.getIcon(event.name))?_c('span',{class:`icon f-icon f-icon-${_vm.getIcon(event.name)}`}):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }