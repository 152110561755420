var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f-dropdown',_vm._b({ref:"validatable",attrs:{"class-name":[
        'f-client-dropdown',
        ..._vm.classNames,
    ],"pre-icon":"briefcase"},scopedSlots:_vm._u([{key:"selectedOption",fn:function(selectedOptionProps){return [(selectedOptionProps.selectedOption)?_c('div',{staticClass:"f-dropdown__selected-option f-client-dropdown__selected-option --single"},[_c('entity-avatar',{attrs:{"name":selectedOptionProps.selectedOption.value,"src":selectedOptionProps.selectedOption.image_url,"size":"xxsmall"}}),_vm._v(" "+_vm._s(selectedOptionProps.selectedOption.value)+" ")],1):_vm._e()]}},{key:"options",fn:function(optionsProps){return _vm._l((optionsProps.options),function(option,index){return _c('li',{key:option.key,class:[
                'f-client-dropdown__option',
                (optionsProps.selectedIndex === index ? '--selected' : null),
                (optionsProps.selectedOption && optionsProps.selectedOption.key === option.key ? '--chosen' : null),
            ],on:{"click":function($event){$event.preventDefault();return optionsProps.onSelect(option, index)}}},[_c('div',{staticClass:"f-client-dropdown__option__image"},[_c('entity-avatar',{attrs:{"name":option.value,"src":option.image_url,"size":"small","color":"dark"}})],1),_c('div',{staticClass:"f-client-dropdown__option__title"},[_vm._v(" "+_vm._s(option.value)+" "),_c('div',{staticClass:"f-client-dropdown__option__title__subtitle"},[_vm._v(" "+_vm._s(option.office)+" office ")])])])})}}]),model:{value:(_vm.dropdownValue),callback:function ($$v) {_vm.dropdownValue=$$v},expression:"dropdownValue"}},'f-dropdown',_vm.$props,false))
}
var staticRenderFns = []

export { render, staticRenderFns }