import Store from '../services/Store';

export default {
    computed: {
        userOffice() {
            if (Store.state.$user && Store.state.$user.office) {
                return Store.state.$user.office;
            }
            return 'amsterdam';
        },
    },
};
