<template>
    <header class="meeting-room-logo">
        <div class="meeting-room-logo__logo-container">
            <div class="meeting-room-logo__logo-container__number">
                {{ isNaN(number) ? '' : number }}
            </div>
            <logo/>
        </div>
    </header>
</template>

<script>
import Logo from '../../../misc/Logo';

export default {
    name: 'meeting-room-logo',

    components: {
        Logo,
    },

    props: {
        number: {
            required: false,
        },
    },
}
</script>

<style lang="scss">
.meeting-room-logo {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 30px;
    left: 30px;
    transform-origin: bottom left;
    transform: scale(3);
    z-index: 3;
    pointer-events: none;

    &__logo-container {
        position: relative;
        display: inline-block;

        .logo {
            width: 100px;

            * {
                fill: $light;
            }
        }

        &__number {
            color: $light;
            position: absolute;
            top: 27px;
            right: 0;
            width: 48%;
            text-align: center;
            font-weight: 600;
            font-size: 29px;
            line-height: 0;
        }
    }
}
</style>
