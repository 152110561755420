<template>
    <div
        :class="['f-swipe-gestures', ...classNames]"
        @touchstart="onTouchStart"
        @touchend="onTouchEnd"
    >
        <slot/>
    </div>
</template>

<script>
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-swipe-gestures',

    mixins: [
        ClassNameMixin,
    ],

    data() {
        return {
            touchStart: {
                x: 0,
                y: 0,
            },
            touchEnd: {
                x: 0,
                y: 0,
            },
        }
    },

    methods: {
        onTouchStart(e) {
            this.touchStart = {
                x: e.changedTouches[0].screenX,
                y: e.changedTouches[0].screenY,
            };
        },

        onTouchEnd(e) {
            this.touchEnd = {
                x: e.changedTouches[0].screenX,
                y: e.changedTouches[0].screenY,
            };

            this.handleGesture();
        },

        handleGesture() {
            if (this.touchEnd.x < this.touchStart.x && Math.abs(this.touchEnd.x - this.touchStart.x) > 100) {
                this.$emit('swipe-left');
            } else if (this.touchEnd.x > this.touchStart.x && Math.abs(this.touchEnd.x - this.touchStart.x) > 100) {
                this.$emit('swipe-right');
            } else if (this.touchEnd.y < this.touchStart.y && Math.abs(this.touchEnd.y - this.touchStart.y) > 100) {
                this.$emit('swipe-up');
            } else if (this.touchEnd.y > this.touchStart.y && Math.abs(this.touchEnd.y - this.touchStart.y) > 100) {
                this.$emit('swipe-down');
            } /*else if (this.touchEnd.y === this.touchStart.y) {
                this.$emit('tap');
            }*/
        }
    },
}
</script>
