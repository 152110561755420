var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"abstract",class:[
        ..._vm.classNames,
        'f-abstract-input-field',
        (_vm.focussed && !_vm.disabled ? '--focus' : null),
        (_vm.disabled ? '--disabled' : null),
        (_vm.readonly ? '--readonly' : null),
        (_vm.inputError ? '--error' : null),
        (_vm.hasHover ? '--hover' : null),
    ],style:({
        maxWidth: _vm.maxWidth,
        width: _vm.width
    }),on:{"click":_vm.onClick,"mouseenter":function($event){_vm.hasHover = true},"mouseleave":function($event){_vm.hasHover = false}}},[_c('div',{staticClass:"f-abstract-input-field__pre"},[_vm._t("pre")],2),_c('div',{class:[
            'f-abstract-input-field__content',
            (_vm.align ? `--align-${_vm.align}` : null),
        ]},[_vm._t("default")],2),_c('div',{staticClass:"f-abstract-input-field__post"},[(!_vm.resetable || (_vm.resetable && !_vm.hasContent))?_vm._t("post"):_vm._e(),(_vm.resetable && !_vm.readonly && ((_vm.hideResetWhenEmpty && _vm.hasContent) || !_vm.hideResetWhenEmpty))?_c('f-icon-button',{attrs:{"class-name":(_vm.hasContent ? null : '--hide'),"icon":"cross","size":"xsmall","variant":"themed","outlined":"","tabindex":"-1"},on:{"click":_vm.onResetClick}}):_vm._e()],2),(_vm.showError && _vm.inputError)?_c('f-input-error',{attrs:{"target":_vm.$refs.abstract,"error":_vm.inputError,"align":_vm.errorAlign}}):_vm._e(),_vm._t("after")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }