<template>
    <ul :class="['page-header-navigation']">
        <!--        <navigation-item-->
        <!--            v-if="!breakpoints.mobile"-->
        <!--            title="Tutorial"-->
        <!--            icon="question-circle"-->
        <!--            @click="EventBus.$emit(GlobalEvents.TUTORIAL_OPEN)"-->
        <!--        />-->
        <admin-item
            id="adminMenu"
            v-if="
                hasPermission(Permissions.ADMIN_USERS_VIEW) ||
                hasPermission(Permissions.ADMIN_CLIENTS_VIEW) ||
                hasPermission(Permissions.ADMIN_PRODUCTS_VIEW) ||
                hasPermission(Permissions.ADMIN_GALLERIES) ||
                hasPermission(Permissions.ADMIN_ANNOUNCEMENTS) ||
                hasPermission(Permissions.ADMIN_DISCIPLINES_VIEW) ||
                hasPermission(Permissions.ADMIN_MISC_FLUSH_CACHE) ||
                hasPermission(Permissions.ADMIN_MISC_EXTERNAL_CONNECTIONS) ||
                hasPermission(Permissions.ADMIN_FIKA) ||
                hasPermission(Permissions.ADMIN_MISC_VIEW_LOGS) ||
                hasPermission(Permissions.ADMIN_PAGES)
            "
        />
        <!--        <navigation-item-->
        <!--            v-if="['amsterdam'].includes(userOffice)"-->
        <!--            link="/coffee"-->
        <!--            title="A coffee with<br>colleagues"-->
        <!--            icon="coffee-cup"-->
        <!--        />-->
        <!--        <navigation-item-->
        <!--            link="/compliment"-->
        <!--            title="A compliment a day"-->
        <!--            icon="thumbs-up"-->
        <!--        />-->
        <!--        <navigation-item-->
        <!--            link="/memory"-->
        <!--            title="Memory"-->
        <!--            icon="joystick"-->
        <!--        />-->
        <!--        <push-notification-item-->
        <!--            id="pushNotificationsMenu"-->
        <!--        />-->
        <!--        <navigation-item-->
        <!--            id="notificationsMenu"-->
        <!--            title="Notifications"-->
        <!--            icon="inbox2"-->
        <!--            :badge="unreadNotifications"-->
        <!--            @click="EventBus.$emit(GlobalEvents.NOTIFICATIONS_OPEN)"-->
        <!--        />-->
        <navigation-spacer/>
        <li @click="EventBus.$emit(GlobalEvents.PROFILE_OPEN)">
            <entity-avatar
                v-tooltip="{
                    text: 'Settings',
                    align: 'bottom',
                }"
                :src="$store.state.$user.profile_image"
                :name="$store.state.$user.name"
                size="xmedium"
                color="dark"
                id="profileSettingsMenu"
            />
        </li>
    </ul>
</template>

<script>
import EntityAvatar from '../../../misc/entities/EntityAvatar';
import NavigationItem from './components/NavigationItem';
import PushNotificationItem from './components/PushNotificationItem';
import AdminItem from './components/AdminItem';
import NavigationSpacer from './components/NavigationSpacer';
import {APP_ENVIRONMENT} from '../../../../config/app';
import BreakpointsMixin from '../../../../mixins/BreakpointsMixin';
import UserOfficeMixin from '../../../../mixins/UserOfficeMixin';

export default {
    name: 'page-header-navigation',

    mixins: [
        BreakpointsMixin,
        UserOfficeMixin,
    ],

    components: {
        NavigationSpacer,
        AdminItem,
        PushNotificationItem,
        NavigationItem,
        EntityAvatar,
    },

    computed: {
        // unreadNotifications() {
        //     return Store.state.$notifications.filter((notification) => !notification.is_read).length;
        // },
    },

    data() {
        return {
            environment: APP_ENVIRONMENT,
        }
    },
}
</script>

<style lang="scss">
.page-header-navigation {
    display: inline-flex;
    align-items: center;

    .entity-avatar {
        transition: filter .1s;
        cursor: pointer;
        border: 1px solid $tertiaryGray;

        &:hover {
            filter: brightness(.8);
        }
    }
}
</style>
