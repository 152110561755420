<template>
    <div :class="['allocations-list']">
        <allocations-list-content
            :assignments="assignments"
            :start-date="startDate"
            :end-date="endDate"
            @fullscreen="onFullScreen"
        />

        <f-portal v-if="fullscreen">
            <allocations-list-content
                :assignments="assignments"
                :start-date="startDate"
                :end-date="endDate"
                fullscreen
                @fullscreen="onFullScreenClose"
            />
        </f-portal>
    </div>
</template>

<script>
import AllocationsListContent from './AllocationsListContent';
import FPortal from '../../../../f-components/other/portal/FPortal';
import EventBus from '../../../../services/EventBus';
import {GlobalEvents} from '../../../../config/Events';

export default {
    name: 'allocations-list',

    components: {
        FPortal,
        AllocationsListContent,
    },

    props: {
        assignments: {
            type: Array,
            required: true,
        },
        startDate: {
            type: String,
            required: true,
        },
        endDate: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            fullscreen: false,
        }
    },

    methods: {
        onFullScreen() {
            this.fullscreen = true;
            EventBus.$emit(GlobalEvents.MODAL_OPEN);
        },

        onFullScreenClose() {
            this.fullscreen = false;
            EventBus.$emit(GlobalEvents.MODAL_CLOSE);
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },
    },
}
</script>
