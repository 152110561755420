var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        text: _vm.tooltip,
        align: 'top',
        variant: 'dark',
        ..._vm.tooltipOptions,
    }),expression:"{\n        text: tooltip,\n        align: 'top',\n        variant: 'dark',\n        ...tooltipOptions,\n    }"}],class:[
        'dashed-explanation',
        ..._vm.classNames,
    ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }