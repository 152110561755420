<template>
    <audio ref="player" :loop="loop" :autoplay="autoplay">
        <source :src="src"/>
    </audio>
</template>

<script>
export default {
    name: 'sound-effect',

    props: {
        src: String,
        loop: Boolean,
        autoplay: Boolean,
        volume: {
            type: Number,
            default: .5,
        },
    },

    methods: {
        play() {
            this.$el.currentTime = 0;
            this.$el.volume = this.volume;
            this.$el.play();
        },
    },
}
</script>
