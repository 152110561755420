<template>
    <f-modal
        :class-name="['f-editor-image-upload']"
        title="Image upload"
        width="300px"
        @close="$emit('close')"
    >
        <f-image-input
            width="100%"
            height="200px"
            type="square"
            @input="file = $event"
        />
        <f-button
            value="Upload"
            variant="primary"
            @click="upload"
            :disabled="!!!file || loading"
            :show-loader-on-disabled="loading"
        />
    </f-modal>
</template>

<script>
import FModal from '../../../other/modal/FModal';
import FImageInput from '../../images/FImageInput';
import FButton from '../../buttons/FButton';
import APIEndpoints from '../../../../config/APIEndpoints';
import LoadingMixin from '../../../../mixins/LoadingMixin';

export default {
    name: 'f-editor-image-upload',

    mixins: [
        LoadingMixin,
    ],

    components: {
        FButton,
        FImageInput,
        FModal,
    },

    props: {
        mock: Boolean,
    },

    data() {
        return {
            file: undefined,
        }
    },

    methods: {
        upload() {
            if (!this.file) return;

            if (this.mock) {
                this.$emit('insert-image', 'https://source.unsplash.com/random');
                return;
            }

            const formData = new FormData();
            formData.set('file', this.file);

            this.startLoading();

            window.axios.post(APIEndpoints.EDITOR_IMAGE_UPLOAD, formData, {
                headers: {'Content-Type': 'multipart/form-data'},
            }).then(({data}) => {
                this.endLoading(true);
                this.$emit('insert-image', data.data.src);
            }).catch(() => this.endLoading(true));
        }
    },
}
</script>

<style lang="scss">
.f-editor-image-upload {
    .f-image-input {
        width: 100%;
        margin-bottom: 20px;
    }
}
</style>
