<template>
    <div class="snowflakes" aria-hidden="true">
        <div class="snowflake">
            ❅
        </div>
        <div class="snowflake">
            ❆
        </div>
        <div class="snowflake">
            ❅
        </div>
        <div class="snowflake">
            ❆
        </div>
        <div class="snowflake">
            ❅
        </div>
        <div class="snowflake">
            ❆
        </div>
        <div class="snowflake">
            ❅
        </div>
        <div class="snowflake">
            ❆
        </div>
        <div class="snowflake">
            ❅
        </div>
        <div class="snowflake">
            ❆
        </div>
        <div class="snowflake">
            ❅
        </div>
        <div class="snowflake">
            ❆
        </div>
    </div>
</template>

<script>
export default {
    name: 'snowflakes',
}
</script>

<style lang="scss">
.snowflakes {
    display: block !important;
}

.snowflake {
    color: #fff;
    font-family: Arial, sans-serif;
    pointer-events: none;
    position: fixed;
    top: -10%;
    z-index: 9999;
    user-select: none;
    cursor: default;
    animation-name: snowflakes-fall, snowflakes-shake;
    animation-duration: 10s, 3s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    animation-play-state: running, running;
    font-size: 2em;
    text-shadow: 0 0 4px rgba(0, 0, 0, .1) !important;

    &:nth-of-type(0) {
        left: 1%;
        animation-delay: 0s, 0s;
        font-size: 1.5em;
    }

    &:nth-of-type(1) {
        left: 10%;
        animation-delay: 1s, 1s;
    }

    &:nth-of-type(2) {
        left: 20%;
        animation-delay: 6s, .5s;
        font-size: 1em;
    }

    &:nth-of-type(3) {
        left: 30%;
        animation-delay: 4s, .1s;
    }

    &:nth-of-type(4) {
        left: 40%;
        animation-delay: 2s, .1s;
    }

    &:nth-of-type(5) {
        left: 50%;
        animation-delay: 8s, 3s;
    }

    &:nth-of-type(6) {
        left: 60%;
        animation-delay: 6s, .1s;
    }

    &:nth-of-type(7) {
        left: 70%;
        animation-delay: 2.5s, 1s;
        font-size: 2em;
    }

    &:nth-of-type(8) {
        left: 80%;
        animation-delay: 1s, 0s;
    }

    &:nth-of-type(9) {
        left: 90%;
        animation-delay: 3s, 1.5s;
    }

    &:nth-of-type(10) {
        left: 25%;
        animation-delay: 2s, 0s;
        font-size: 1.5em;
    }

    &:nth-of-type(11) {
        left: 65%;
        animation-delay: 4s, 2.5s;
    }
}

@keyframes snowflakes-fall {
    0% {
        top: -10%;
    }
    100% {
        top: 100%;
    }
}

@keyframes snowflakes-shake {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(80px);
    }
}
</style>
