<template>
    <div
        :class="[
            'f-flex-table-cell',
             (hideMobile ? '--hide-mobile': null),
             (showMobile ? '--show-mobile': null),
             (border ? '--border': null),
             (overflowHidden ? '--overflow-hidden': null),
             (align ? `--align-${align}` : null),
             className,
        ]"
        :style="`flex: ${flex}`"
    >
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'f-flex-table-cell',

    props: {
        className: String,
        hideMobile: Boolean,
        showMobile: Boolean,
        align: String,
        overflowHidden: Boolean,
        border: Boolean,
        flex: {
            type: String,
            default: '1',
        },
    },
}
</script>

<style lang="scss">
.f-flex-table-cell {
    flex: 1;
    padding: 5px 10px;

    &.--overflow-hidden {
        overflow: hidden;
    }

    &.--border {
        border-left: 1px solid red; //$softGray;
        border-right: 1px solid red; //$softGray;
    }

    @media only screen and (max-width: 600px) {
        flex: 0 0 40px;
    }
}
</style>
