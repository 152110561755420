<template>
    <div class="bcs-requests">
        <div v-if="fetchedData">
            <f-table
                :empty="fetchedData.data.result === 0"
            >
                <template #head>
                    <f-table-header-row>
                        <f-table-header-cell>
                            Request
                        </f-table-header-cell>
                        <f-table-header-cell align="center">
                            Date
                        </f-table-header-cell>
                        <f-table-header-cell align="right">
                            Hours
                        </f-table-header-cell>
                        <f-table-header-cell>
                            Description
                        </f-table-header-cell>
                    </f-table-header-row>
                </template>

                <f-table-row
                    v-for="(request, i) in fetchedData.data.result"
                    :key="i"
                >
                    <f-table-cell>
                        {{ getTimeOffOptionByKey(request.type_name).value }}
                    </f-table-cell>
                    <f-table-cell align="center">
                        <f-chip variant="info">{{ formatDate(request.date) }}</f-chip>
                    </f-table-cell>
                    <f-table-cell align="right">
                        {{ request.total_hrs }}
                    </f-table-cell>
                    <f-table-cell>
                        <f-chip v-if="request.is_deleted" variant="danger">Deleted</f-chip>
                        {{ request.description }}
                    </f-table-cell>
                </f-table-row>
            </f-table>
        </div>
        <f-table-placeholder v-else-if="loading"/>
    </div>
</template>

<script>
import LoadingMixin from '../../../../mixins/LoadingMixin';
import {SiteLoaderSizes} from '../../../../f-components/loaders/site-loader/config';
import APIEndpoints from '../../../../config/APIEndpoints';
import FTablePlaceholder from '../../../../f-components/loaders/placeholder/FTablePlaceholder';
import FTable from '../../../../f-components/data/table/FTable';
import FTableHeaderRow from '../../../../f-components/data/table/FTableHeaderRow';
import FTableHeaderCell from '../../../../f-components/data/table/FTableHeaderCell';
import FTableCell from '../../../../f-components/data/table/FTableCell';
import FTableRow from '../../../../f-components/data/table/FTableRow';
import FChip from '../../../../f-components/data/chip/FChip';
import {getTimeOffOptionByKey} from '../../../misc/leave-requests/config';
import moment from 'moment-timezone';

export default {
    name: 'bcs-requests',

    mixins: [
        LoadingMixin,
    ],

    components: {
        FChip,
        FTableRow,
        FTableCell,
        FTableHeaderCell,
        FTableHeaderRow,
        FTable,
        FTablePlaceholder
    },

    props: {
        userId: Number,
    },

    data() {
        return {
            fetchedData: null,
            getTimeOffOptionByKey: getTimeOffOptionByKey,
        }
    },

    mounted() {
        this.fetch(this.userId);
    },

    methods: {
        fetch(userID) {
            this.fetchedData = null;
            this.startLoading(SiteLoaderSizes.SMALL);

            window.axios
                .get(APIEndpoints.parse(APIEndpoints.ADMIN_LEAVE_REQUESTS_BCS, {id: userID}))
                .then(response => {
                    this.endLoading(true);
                    this.fetchedData = response.data;
                    console.log(response.data.data.result);
                })
                .catch(() => this.endLoading());
        },

        formatDate(date) {
            return moment(date).format('ddd D MMM YYYY');
        }
    },
}
</script>

<style lang="scss">
.bcs-requests {

}
</style>
