<template>
    <div
        :class="[
            'amsterdam-office-map',
             (isDragging ? '--is-dragging' : null),
         ]"

        @mousedown="onDragStart"
        @mousemove="onDragMove"
        @mouseup="onDragEnd"
        @mouseleave="onDragEnd"

        @touchstart="onDragStart"
        @touchmove="onDragMove"
        @touchend="onDragEnd"
    >
        <f-container max-width="800px">
            <f-tabs @tabChange="floor = $event">
                <f-tab id="Ground floor" title="Ground floor"/>
                <f-tab id="First floor" title="First floor"/>
            </f-tabs>
        </f-container>

        <f-container max-width="1000px">
            <div class="amsterdam-office-map__wrapper">
                <div
                    class="amsterdam-office-map__map-container"
                    ref="map"
                    :style="{ transform }"
                >
                    <level
                        title="Ground floor"
                        :level="0"
                        inactive-z="-120px"
                        :active="floor === 'Ground floor'"
                    >
                        <pin left="115" top="150" color="gray" icon="dinner" title="Kitchen"/>
                        <pin left="65" top="250" color="gray" icon="paperclip" title="Office Supplies"/>
                        <pin left="792" top="479" color="blue" icon="toilet-paper" title="Toilets"/>
                        <pin left="232" top="579" color="blue" icon="toilet-paper" title="Toilets"/>
                        <pin left="553" top="652" color="gray" icon="recycle" title="Paper Recycling<br/>Container"/>
                        <pin left="585" top="500" color="gray" icon="coffee-cup" title="Kitchen"/>

                        <meeting-room
                            pin-left="61" pin-top="41"
                            left="708" top="503"
                            width="124" height="87"
                            title="Meeting Room 5" :id="7"
                        />

                        <pin left="679" top="651" color="pink" icon="telephone" title="Phone Booth E"/>
                        <pin left="435" top="590" color="pink" icon="telephone" title="Phone Booth F"/>

                        <div class="staircase-1a-up"/>
                        <div class="staircase-1b-up"/>
                        <div class="staircase-2-up"/>
                    </level>

                    <level
                        :level="1"
                        inactive-z="260px"
                        :active="floor === 'First floor'"
                        title="First floor"
                    >
                        <div class="staircase-1a-down"/>
                        <div class="staircase-1b-down"/>
                        <div class="staircase-2-down"/>

                        <pin left="210" top="460" color="gray" icon="server" title="Server room"/>
                        <pin left="210" top="269" color="gray" icon="lock" title="Lockers"/>
                        <pin left="526" top="490" color="green" icon="printer"
                             title="<strong>Printer</strong><br><span style='font-size: 90%'>Phaser 7500N</span>"/>
                        <pin left="602" top="481" color="blue" icon="toilet-paper" title="Toilet"/>

                        <pin left="189" top="497" color="pink" icon="telephone" title="Phone Booth A"/>
                        <pin left="280" top="497" color="pink" icon="telephone" title="Phone Booth B"/>
                        <pin left="340" top="467" color="pink" icon="telephone" title="Phone Booth C"/>
                        <pin left="490" top="467" color="pink" icon="telephone" title="Phone Booth D"/>

                        <meeting-room
                            pin-left="53" pin-top="72"
                            left="54" top="99" width="117" height="138"
                            title="Meeting Room 1" :id="1"
                        />
                        <meeting-room
                            pin-left="64" pin-top="47"
                            left="172" top="589" width="124" height="88"
                            title="Meeting Room 3" :id="3"
                        />
                        <meeting-room
                            pin-left="47" pin-top="47"
                            left="77" top="589" width="94" height="88"
                            title="Meeting Room 2" :id="2"
                        />
                        <meeting-room
                            pin-left="61" pin-top="87"
                            left="833" top="508" width="120" height="169"
                            title="Meeting Room 4" :id="6"
                        />
                    </level>
                </div>
            </div>
        </f-container>
    </div>
</template>

<script>
import Pin from './Pin';
import Level from './Level';
import MeetingRoom from './MeetingRoom';
import EventBus from '../../../../services/EventBus';
import {GlobalEvents} from '../../../../config/Events';
import FContainer from '../../../../f-components/layout/container/FContainer';
import FTabs from '../../../../f-components/navigation/tabs/FTabs';
import FTab from '../../../../f-components/navigation/tabs/FTab';

export default {
    name: 'amsterdam-office-map',

    components: {
        FTab,
        FTabs,
        FContainer,
        MeetingRoom,
        Level,
        Pin,
    },

    props: {
        translateY: {
            type: Number,
            default: -250,
        },
    },

    data() {
        return {
            isDragging: false,
            dragStartX: 0,
            currentPercentage: 0,
            dragStartPercentage: 0,
            floor: 'Ground floor',
            transform: undefined,
        }
    },

    mounted() {
        this.setPercentagePosition(55);
    },

    methods: {
        onDragStart(event) {
            event.stopPropagation();

            if (event.touches) {
                this.dragStartX = event.touches[0].screenX;
            } else {
                this.dragStartX = event.screenX;
            }

            this.dragStartPercentage = this.currentPercentage;
            this.isDragging = true;
        },

        onDragEnd(event) {
            event.stopPropagation();

            this.isDragging = false;
        },

        onDragMove(event) {
            if (this.isDragging) {
                let changeX;

                if (event.touches) {
                    changeX = this.dragStartX - event.touches[0].screenX;
                } else {
                    changeX = this.dragStartX - event.screenX;
                }

                let percentageChange = changeX / (500 / 100);

                if (Math.abs(percentageChange) <= 1) {
                    return;
                }

                EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);

                this.setPercentagePosition(this.dragStartPercentage + percentageChange);
            }
        },

        onRangeChange(range) {
            let value = parseInt(range.target.value);

            this.setPercentagePosition(value);
        },

        setPercentagePosition(percentage) {
            if (percentage > 100) {
                percentage = 100;
            }
            if (percentage < 0) {
                percentage = 0;
            }

            // console.log(`Setting position percentage to ${percentage}`);

            this.currentPercentage = percentage;

            let rz = (-60) + (30 / 100) * percentage;
            let rx = 50;
            let tx = 150 - ((((680 * ((1000 /*max width */ / 100)) / this.$el.clientWidth))) / 1.4) * percentage; // -268
            let ty = this.translateY + (100 / 100) * percentage;

            // console.log(`rotateX(${rx}deg) translateX(${tx}px) translateY(${ty}px) rotateZ(${rz}deg)`);

            this.transform = `rotateX(${rx}deg) translateX(${tx}px) translateY(${ty}px) rotateZ(${rz}deg)`;
        }
    },
}
</script>

<style lang="scss">
.amsterdam-office-map {
    cursor: grab;

    &.--is-dragging {
        cursor: grabbing;
    }

    .f-tabs {
        z-index: 2;
    }

    &__wrapper {
        background: $secondaryGray;
        margin-top: 25px;
        border-radius: 10px;
        overflow: hidden;
        height: 600px;
    }

    &__map-container {
        width: 100%;
        height: 100%;
        perspective: 20000px;
        position: relative;
        transform: rotateX(50deg) translateX(57px) translateY(-80px) rotateZ(-54deg);
        transform-style: preserve-3d;

        @include media(tablet-down) {
            margin-top: 100px;
        }
    }
}
</style>
