<template>
    <page
        class-name="admin-clients-page"
        :loading="loading"
        :title="['Admin', 'Clients']"
        header="Clients"
        :header-badge="(fetchedData ? fetchedData.total : null)"
        pre-max-width="800px"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb>Clients <sup>DEPRECATED</sup></f-breadcrumb>
                <f-breadcrumb>Overview</f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-container max-width="800px">
            <f-filter
                show-active-filters
                :active-filters="activeFilters"
                :filter-casts="{
                    query: 'query'
                }"
            >
                <f-filter-row>
                    <f-filter-item>
                        <f-text-input
                            placeholder="Search…"
                            pre-icon="magnifier"
                            v-model="searchQuery"
                            class-name="--align-center"
                            max-width="300px"
                            resetable
                        />
                    </f-filter-item>
                    <f-filter-item>
                        <f-dropdown
                            v-model="office"
                            :options="officeOptions"
                            popup-width="120px"
                            placeholder="Office"
                            pre-icon="apartment"
                            resetable
                        />
                    </f-filter-item>
                </f-filter-row>
            </f-filter>

            <f-table
                v-if="fetchedData"
                :empty="(!fetchedData || items.length === 0)"
                rows-hoverable
                ref="table"
                :allow-horizontal-scroll="false"
            >
                <template #pre>
                    <f-pagination
                        v-if="fetchedData"
                        :current-page="currentPage"
                        :page-count="lastPage"
                        @navigate="onPaginationNavigate"
                    />
                </template>

                <template #head>
                    <f-table-header-row>
                        <f-table-header-cell colspan="2">
                            Client
                        </f-table-header-cell>
                        <f-table-header-cell v-if="!breakpoints.mobile"/>
                        <f-table-header-cell width="160px" align="center" v-if="!breakpoints.mobile">
                            Account director
                        </f-table-header-cell>
                    </f-table-header-row>
                </template>

                <f-table-row
                    v-for="row in items"
                    :key="row.id"
                    @click="$router.push(`/admin/clients/${row.id}`)"
                >
                    <f-table-cell width="50px">
                        <entity-avatar
                            size="large"
                            :src="row.image_url"
                            :name="row.name"
                        />
                    </f-table-cell>
                    <f-table-title-cell>
                        {{ row.name }}
                        <template #sub>
                            {{ row.office_id.ucfirst() }} office
                        </template>
                    </f-table-title-cell>
                    <f-table-cell align="center" v-if="!breakpoints.mobile">
                        <f-chip
                            v-if="row.forecast_id"
                            v-tooltip="{text: 'Synced from Harvest Forecast', variant: 'dark'}"
                            size="medium"
                            variant="info"
                        >
                            <img src="/img/harvest.png" alt="Harvest"/>
                        </f-chip>
                        <f-chip
                            v-if="row.gripp_id"
                            v-tooltip="{text: 'Synced from Gripp', variant: 'dark'}"
                            variant="info"
                        >
                            <img src="/img/gripp.png" alt="Gripp"/>
                        </f-chip>
                        <f-chip
                            v-if="row.xero_id"
                            v-tooltip="{text: 'Synced from XERO', variant: 'dark'}"
                            variant="info"
                        >
                            <img src="/img/xero.png" alt="Xero"/>
                        </f-chip>
                    </f-table-cell>
                    <f-table-cell width="160px" align="center" v-if="!breakpoints.mobile">
                        <user-card-compact
                            v-if="userById(row.account_director_id)"
                            :user="userById(row.account_director_id)"
                            variant="semi-dark"
                        />
                    </f-table-cell>
                </f-table-row>

                <template #post>
                    <f-pagination
                        v-if="fetchedData"
                        :current-page="currentPage"
                        :page-count="lastPage"
                        @navigate="onPaginationNavigate"
                        bottom
                    />
                </template>
            </f-table>

            <f-table-placeholder v-else-if="!fetchedData && loading"/>
        </f-container>
    </page>
</template>

<script>
import APIEndpoints from '../../../../config/APIEndpoints';
import Page from '../../Page';
import FTextInput from '../../../../f-components/form-controls/text/FTextInput';
import EntityPaginatedFilteredOverviewMixin from '../../../../mixins/entities/EntityPaginatedFilteredOverviewMixin';
import FTable from '../../../../f-components/data/table/FTable';
import FTableRow from '../../../../f-components/data/table/FTableRow';
import FTableHeaderCell from '../../../../f-components/data/table/FTableHeaderCell';
import FTableCell from '../../../../f-components/data/table/FTableCell';
import EntityAvatar from '../../../misc/entities/EntityAvatar';
import FTableTitleCell from '../../../../f-components/data/table/FTableTitleCell';
import UserHelperMixin from '../../../../mixins/UserHelperMixin';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import FDropdown from '../../../../f-components/form-controls/dropdowns/FDropdown';
import OfficeOptionsMixin from '../../../../mixins/OfficeOptionsMixin';
import FChip from '../../../../f-components/data/chip/FChip';
import UserCardCompact from "../../../cards/users/UserCardCompact";
import FFilterItem from "../../../../f-components/data/filter/FFilterItem";
import FFilterRow from "../../../../f-components/data/filter/FFilterRow";
import FFilter from "../../../../f-components/data/filter/FFilter";
import FTablePlaceholder from '../../../../f-components/loaders/placeholder/FTablePlaceholder';
import FContainer from '../../../../f-components/layout/container/FContainer';
import FPagination from '../../../../f-components/navigation/pagination/FPagination';
import FTableHeaderRow from '../../../../f-components/data/table/FTableHeaderRow';
import BreakpointsMixin from '../../../../mixins/BreakpointsMixin';

export default {
    name: 'admin-clients-page',

    components: {
        FTableHeaderRow,
        FPagination,
        FContainer,
        FTablePlaceholder,
        FFilter,
        FFilterRow,
        FFilterItem,
        UserCardCompact,
        FChip,
        FDropdown,
        FBreadcrumb,
        FBreadcrumbs,
        FTableTitleCell,
        EntityAvatar,
        FTableCell,
        FTableHeaderCell,
        FTableRow,
        FTable,
        FTextInput,
        Page,
    },

    mixins: [
        EntityPaginatedFilteredOverviewMixin,
        UserHelperMixin,
        OfficeOptionsMixin,
        BreakpointsMixin,
    ],

    data() {
        return {
            searchQuery: null,
            office: null,
        };
    },

    watch: {
        searchQuery() {
            this.onFilterChange();
        },
        office() {
            this.onFilterChange();
        },
    },

    methods: {
        getEndpoint() {
            return APIEndpoints.ADMIN_CLIENTS;
        },

        getFilters() {
            return {
                'filter[query]': this.searchQuery,
                'filter[office]': this.office,
            };
        },

        applyPreviousFilters(filters) {
            if (filters['filter[query]']) this.searchQuery = filters['filter[query]'];
            if (filters['filter[office]']) this.office = filters['filter[office]'];
        },
    },
}
</script>

<style lang="scss">
.admin-clients-page {
    .f-filter__container {
        max-width: 600px;
    }
}
</style>
