<template>
    <transition name="slide-right" apear>
        <f-theme-provider
            v-if="active"
            :class="[
                'notifications-pane',
                (unreadNotifications > 0 ? '--unread-notifications' : null),
            ]"
            variant="dark"
            id="notificationsPane"
        >
            <f-close @click="close"/>

            <header class="notifications-pane__header">
                <h2><em>Notifications</em></h2>
            </header>

            <div
                class="notifications-pane__content --scrollable"
                @scroll="EventBus.$emit(GlobalEvents.OVERFLOW_SCROLL)"
            >
                <div class="notifications-pane__content__inner">
                    <f-table
                        v-if="$store.state.$notifications.length > 0"
                        rows-hoverable
                        type="default-dense"
                    >
                        <notification-row
                            v-for="(notification, i) in $store.state.$notifications"
                            :key="i"
                            :data="notification"
                        />
                    </f-table>
                </div>
            </div>

            <f-button
                v-if="unreadNotifications > 0"
                @click="$store.dispatch('notificationAllRead')"
                icon="eye"
                variant="primary"
                value="Mark all as read"
            />
        </f-theme-provider>
    </transition>
</template>

<script>
import APIDataStore from '../../../services/APIDataStore';
import NotificationRow from './NotificationRow';
import FButton from '../../../f-components/form-controls/buttons/FButton';
import FClose from '../../../f-components/navigation/close/FClose';
import APIDataStoreResources from '../../../config/APIDataStoreResources';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import FThemeProvider from '../../../f-components/layout/theme/FThemeProvider';
import FTable from '../../../f-components/data/table/FTable';

export default {
    name: 'notifications-pane',

    components: {
        FTable,
        FThemeProvider,
        FClose,
        FButton,
        NotificationRow,
    },

    data() {
        return {
            active: false,
            forceFetchedOnce: false
        };
    },

    computed: {
        unreadNotifications() {
            return this.$store.state.$notifications.filter((notification) => !notification.is_read).length;
        },
    },

    created() {
        APIDataStore.check(APIDataStoreResources.NOTIFICATIONS);

        EventBus.$on('notifications.open', this.open);
        EventBus.$on('notifications.close', this.close);

        EventBus.$on(GlobalEvents.WINDOW_RESIZE, this.close);
        EventBus.$on(GlobalEvents.MODAL_OPEN, this.close);
        EventBus.$on(GlobalEvents.PAGE_CHANGE, this.close);
        EventBus.$on(GlobalEvents.KEYDOWN_ESC, this.close);

        // Deeplink events
        this.checkNotificationDeeplink();

        EventBus.$on(`ds.${APIDataStoreResources.NOTIFICATIONS.id}.update`, this.checkNotificationDeeplink);
        EventBus.$on('notification.close', this.removeNotificationDeeplink);
        window.addEventListener('hashchange', this.checkNotificationDeeplink, false);
    },

    beforeDestroy() {
        EventBus.$off('notifications.open', this.open);
        EventBus.$off('notifications.close', this.close);

        EventBus.$off(GlobalEvents.WINDOW_RESIZE, this.close);
        EventBus.$off(GlobalEvents.MODAL_OPEN, this.close);
        EventBus.$off(GlobalEvents.PAGE_CHANGE, this.close);
        EventBus.$off(GlobalEvents.KEYDOWN_ESC, this.close);

        EventBus.$off(`ds.${APIDataStoreResources.NOTIFICATIONS.id}.update`, this.checkNotificationDeeplink);
        EventBus.$off('notification.close', this.removeNotificationDeeplink);
        window.removeEventListener('hashchange', this.checkNotificationDeeplink);
    },

    methods: {
        open() {
            this.active = true;
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },

        close() {
            this.active = false;
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },

        checkNotificationDeeplink() {
            const matches = location.hash.match(/#notification\/([0-9]+)/);

            if (!matches || matches.length !== 2) {
                return;
            }
            const id = parseInt(matches[1]);

            const findings = this.$store.state.$notifications.filter((notification) => {
                return notification.id === id;
            });

            if (findings.length === 1) {
                console.log(`Found notification ${id}, showing with small timeout`);
                window.setTimeout(() => EventBus.$emit('notification.show', findings[0]), 1000)
            } else if (!this.forceFetchedOnce) {
                console.log(`Did not find notification ${id}, force fetching new`);
                this.forceFetchedOnce = true;
                APIDataStore.fetch(APIDataStoreResources.NOTIFICATIONS);
            } else {
                console.warn(`Could not find notification ${id}`);
            }
        },

        removeNotificationDeeplink() {
            if (location.hash.match(/#notification\/([0-9]+)/)) {
                location.hash = '';
            }
        },
    }
}
</script>

<style lang="scss">
.notifications-pane {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 400px;
    overflow: hidden;
    z-index: $slideInPaneOrder;
    background: $dark;
    box-shadow: rgba($dark, .1) 0 0 5px;

    padding: 25px 15px 20px 30px;
    @include media(mobile-down) {
        padding: 20px 10px 20px 20px !important;
    }

    .f-close {
        top: 30px;
        right: 30px;

        @include media(mobile-down) {
            top: 22px;
            right: 27px;
        }
    }

    &__header {
        padding: 0 20px 30px 0;

        h2 {
            margin: 0;
            width: 100%;
        }
    }

    &__content {
        height: 100%;
        max-height: calc(100% - 60px);

        &__inner {
            padding: 0 15px 0 0;
        }

        h3 {
            opacity: .5;
            font-size: 80%;
            padding-bottom: 10px;
            text-align: center;
        }
    }

    &.--unread-notifications {
        .notifications-pane__content {
            max-height: calc(100% - 125px);
        }
    }

    .f-button {
        margin-top: 20px;
        margin-bottom: 10px;
        width: calc(100% - 10px);
    }

    @include media(mobile-down) {
        width: 100%;
    }
}
</style>
