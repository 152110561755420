<template>
    <div class="f-chip-group">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'f-chip-group',
}
</script>

<style lang="scss">
.f-chip-group {
    display: inline-flex;
    margin: 2px 3px 2px 0;

    .f-chip {
        margin: 0;

        &:first-child {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        &:not(:first-child):not(:last-child) {
            border-radius: 0;
        }

        &:last-child {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }
}
</style>
