var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{class:[
        'main-menu',
        (_vm.mobileMenuActive ? '--open' : null),
    ]},[_c('div',{staticClass:"main-menu__content"},[_c('router-link',{staticClass:"main-menu__content__logo",attrs:{"to":"/"}},[_c('logo')],1),_c('f-close',{on:{"click":function($event){return _vm.EventBus.$emit('mobile.menu.close')}}}),_c('ul',{class:[
                'main-menu__content__list',
                '--no-select',
                '--scrollable-dark'
            ],on:{"scroll":function($event){return _vm.EventBus.$emit(_vm.GlobalEvents.OVERFLOW_SCROLL)}}},[_c('main-menu-item',{attrs:{"id":"peopleMenu","link":"/people","icon":"users2","title":"People","subtitle":"The people of code d'azur"}}),(['amsterdam'].includes(_vm.userOffice))?_c('main-menu-item',{attrs:{"id":"meetingRoomsMenu","link":`/meeting-rooms/${_vm.userOffice}`,"icon":"calendar-full","title":"Meeting <br>Rooms","subtitle":"Meeting rooms calendar"}}):_vm._e(),_c('main-menu-item',{attrs:{"id":"faqMenu","link":"/faq","icon":"book","title":"FAQs & <br>Guides","subtitle":"Company information & FAQ"}}),_c('main-menu-item',{attrs:{"id":"hrMenu","link":"/hr","icon":"clipboard-user","title":"HR","subtitle":"People-first at code d’azur HR"}}),_c('main-menu-item',{attrs:{"link":"/office-map/amsterdam","icon":"map","title":"Amsterdam<br> Office Map","subtitle":"Interactive 3D office map"}}),_c('li',{staticClass:"main-menu-item__grow"}),_c('main-menu-item',{attrs:{"id":"logoutMenu","icon":"power-switch","title":"Logout","subtitle":"Logout of Riviera"},on:{"click":function($event){return _vm.EventBus.$emit(_vm.GlobalEvents.LOGOUT)}}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }