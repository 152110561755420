<template>
    <widget
        v-if="!isWeekend && absentees"
        :badge="absentees.length"
        class-name="absentees-widget"
        enabled-setting="widgets.absentees.enabled"
        title="Absentees"
        header="Absent today"
        extra-break-points
    >
        <!--        <f-inline-message-->
        <!--            v-if="error"-->
        <!--            :closable="false"-->
        <!--            variant="error"-->
        <!--            id="absentees.today.forecastError"-->
        <!--        >-->
        <!--            {{ error }}-->
        <!--        </f-inline-message>-->

        <widget-user-list ref="list">
            <widget-user-list-item
                v-for="(record, i) in absentees"
                :key="i"
            >
                <user-card-compact
                    :user="userById(record.user.id) || record.user"
                    :show-disciplines="false"
                    full-width
                >
                    {{ record.event ? record.event.name : '' }}

                    <span v-if="record.booking && record.booking.time / 60 !== 8">
                        ({{ record.booking.time / 60 }} hrs)
                    </span>
                </user-card-compact>
            </widget-user-list-item>
        </widget-user-list>
    </widget>
</template>

<script>
import moment from 'moment-timezone';
import APIDataStore from '../../../services/APIDataStore';
import UserHelperMixin from '../../../mixins/UserHelperMixin';
import Widget from '../Widget';
import APIDataStoreResources from '../../../config/APIDataStoreResources';
import UserCardCompact from "../../cards/users/UserCardCompact";
import WidgetUserList from '../../misc/widget-user-list/WidgetUserList';
import WidgetUserListItem from '../../misc/widget-user-list/WidgetUserListItem';

export default {
    name: 'absentees-widget',

    components: {
        Widget,
        WidgetUserListItem,
        WidgetUserList,
        UserCardCompact,
    },

    computed: {
        isWeekend() {
            return [6, 7].includes(moment().isoWeekday());
        },
        absentees() {
            if (
                this.$store.state.$absenteesToday &&
                this.$store.state.$absenteesToday.report &&
                this.$store.state.$absenteesToday.report.length > 0
            ) {
                return this.$store.state.$absenteesToday.report;
            }
            return false;
        },
    },

    watch: {
        absentees() {
            this.$nextTick(() => {
                if (this.$refs.list) {
                    this.$refs.list.$refs.scrollPane.invalidate();
                }
            });
        },
    },

    mixins: [
        UserHelperMixin,
    ],

    created() {
        APIDataStore.check(APIDataStoreResources.ABSENTEES_TODAY);
    },
}
</script>
