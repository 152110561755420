import EventBus from '../../services/EventBus';
import {GlobalEvents} from '../../config/Events';

export default {
    data() {
        return {
            active: true,
            pos: {
                top: 0,
                left: 0,
            },
            currentAlign: 'top',
            currentAlignHorizontal: 'center',
            debug: false,
        }
    },

    watch: {
        active(active) {
            if (active) {
                this.$nextTick(this.position);
                this.$nextTick(() => this.$nextTick(this.position));
            }
        },
    },

    mounted() {
        EventBus.$on(GlobalEvents.OVERFLOW_SCROLL, this.position);
        EventBus.$on(GlobalEvents.WINDOW_RESIZE, this.position);
        window.addEventListener('scroll', this.position);

        this.position();
        this.$nextTick(this.position);
    },

    beforeDestroy() {
        EventBus.$off(GlobalEvents.OVERFLOW_SCROLL, this.position);
        EventBus.$off(GlobalEvents.WINDOW_RESIZE, this.position);
        window.removeEventListener('scroll', this.position);
    },

    methods: {
        position() {
            if (this.debug) {
                console.log('----------------------');
            }

            if (!this.active || !this.options.target) {
                if (this.debug && !this.options.target) {
                    console.log('[PositionMixin] No active target', this.options);
                } else if (this.debug && !this.active) {
                    console.log('[PositionMixin] Element is not active', this.options);
                }
                return;
            }

            if (this.debug) {
                console.log('[PositionMixin] Positioning to ', this.options.target, this.$refs.positionElement ?? this.$el);
            }

            const target = this.$refs.positionElement ?? this.$el; // < POPUP
            this.currentAlign = this.options.align;
            this.currentAlignHorizontal = 'center';

            // General dimensions
            const margin = this.options.margin;
            const arrowSize = this.options.arrowSize ?? 5;

            // Dimensions of element given by props
            const elementRect = this.options.target.getBoundingClientRect(); // < DROPDOWN

            // if (this.debug) {
            //     console.log('[PositionMixin] target.scrollTop: ', this.options.target.get());
            // }

            const elementTop = parseInt(elementRect.top);
            const elementLeft = parseInt(elementRect.left);
            const elementWidth = parseInt(elementRect.width);
            const elementHeight = parseInt(elementRect.height);

            // Select popup dimensions
            const popupWidth = target.offsetWidth;
            const popupHeight = target.offsetHeight;

            // Adjust alignment based on dimensions
            if (this.currentAlign === 'left' && elementLeft < popupWidth + arrowSize) {
                this.currentAlign = 'right';
            } else if (this.currentAlign === 'right' && elementLeft + elementWidth + popupWidth + arrowSize > window.innerWidth) {
                this.currentAlign = 'left';
            }
            if (this.currentAlign === 'bottom' && elementTop + elementHeight + popupHeight + arrowSize > window.innerHeight) {
                this.currentAlign = 'top';
            } else if (this.currentAlign === 'top' && elementTop - (popupHeight + arrowSize + margin) < 0) {
                this.currentAlign = 'bottom';
            }

            // Adjust horizontal alignment
            if (['top', 'bottom'].includes(this.currentAlign)) {
                // Overflow on right side
                if (elementLeft + (elementWidth * .5) + (popupWidth * .5) > window.innerWidth) {
                    if (this.debug) {
                        console.log('[PositionMixin] Overflow on right side');
                    }
                    this.currentAlignHorizontal = 'right';
                }
                // Overflow on left side
                else if (elementLeft - (popupWidth * .5) + (elementWidth * .5) < 0) {
                    if (this.debug) {
                        console.log('[PositionMixin] Overflow on left side');
                    }
                    this.currentAlignHorizontal = 'left';
                }
            }

            if (this.debug) {
                console.log(`[PositionMixin] Alignment: ${this.currentAlign}, ${this.currentAlignHorizontal}`);
                console.log(`[PositionMixin] [Vars] elementTop: ${elementTop}, elementLeft: ${elementLeft}, elementWidth: ${elementWidth}, popupHeight: ${popupHeight}, arrowSize: ${arrowSize}, margin: ${margin}`);
            }

            // Calculate top left position
            switch (this.currentAlign) {
                case 'top':
                    this.pos.top = Math.round(elementTop - (popupHeight + arrowSize + margin));
                    this.pos.left = Math.round(elementLeft - (popupWidth * .5) + (elementWidth * .5));
                    break;
                case 'bottom':
                    this.pos.top = Math.round(elementTop + elementHeight + arrowSize + margin);
                    this.pos.left = Math.round(elementLeft - (popupWidth * .5) + (elementWidth * .5));
                    break;
                case 'right':
                    this.pos.top = Math.round(elementTop + (elementHeight * .5) - (popupHeight * .5));
                    this.pos.left = Math.round(elementLeft + elementWidth + arrowSize + margin);
                    break;
                case 'left':
                    this.pos.top = Math.round(elementTop + (elementHeight * .5) - (popupHeight * .5));
                    this.pos.left = Math.round(elementLeft - (popupWidth + arrowSize + margin));
                    break;
                default:
                    this.pos.top = elementTop;
                    this.pos.left = elementLeft;
                    break;
            }

            // Calculate horizontal align
            switch (this.currentAlignHorizontal) {
                case 'left':
                    this.pos.left += Math.round((popupWidth * .30) + 1);
                    break;
                case 'right':
                    this.pos.left -= Math.round((popupWidth * .30) - 1);
                    break;
            }

            // Add offsets
            if (this.options.offsetTop) {
                this.pos.top += this.options.offsetTop;
            }
            if (this.options.offsetLeft) {
                this.pos.left += this.options.offsetLeft;
            }
        },
    },
}
