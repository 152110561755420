<template>
    <div
        :class="['f-flex-item', ...classNames]"
        :style="{
            flexBasis: basis,
            flexShrink: shrink,
            flexGrow: grow,
            width,
        }"
    >
        <slot/>
    </div>
</template>

<script>
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-flex-item',

    mixins: [
        ClassNameMixin,
    ],

    props: {
        basis: String,
        grow: String,
        shrink: String,
        width: String,
    },
}
</script>

<style lang="scss">
.f-flex-item {
}
</style>
