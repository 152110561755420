<template>
    <page
        v-if="hasPermission(Permissions.ADMIN_USERS_SYNC)"
        class-name="admin-user-sync-page"
        :loading="loading"
        :title="['Admin', 'Users', 'JAMF Sync']"
        pre-max-width="800px"
        header="JAMF user sync"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb link="/admin/users">Users</f-breadcrumb>
                <f-breadcrumb>JAMF sync</f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-container max-width="800px">
            <sync-page-content
                service="jamf"
                @loading="(l) => loading = l"
            />
        </f-container>
    </page>
</template>

<script>
import SyncPageContent from '../components/SyncPageContent';
import Page from '../../../Page';
import FBreadcrumbs from '../../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import FContainer from '../../../../../f-components/layout/container/FContainer';

export default {
    name: 'admin-sync-page',

    components: {
        FContainer,
        FBreadcrumb,
        FBreadcrumbs,
        SyncPageContent,
        Page,
    },

    data() {
        return {
            loading: false,
        }
    },
}
</script>
