var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{class:[
        'f-form',
        (_vm.disabled ? '--disabled' : null),
        (_vm.loading ? '--loader --loading' : null),
        (_vm.theme ? `--theme-${_vm.theme}` : null),
        ..._vm.classNames,
    ],on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._t("default"),_c('input',{attrs:{"type":"hidden","autocomplete":"false"}}),_c('footer',{staticClass:"f-form__footer"},[_vm._t("footer")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }