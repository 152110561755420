<template>
    <li class="navigation-spacer"/>
</template>

<script>
export default {
    name: 'navigation-spacer',
}
</script>

<style lang="scss">
.navigation-spacer {
    width: 10px;
}
</style>
