var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        text: _vm.title,
        options: _vm.tooltipOptions
    }),expression:"{\n        text: title,\n        options: tooltipOptions\n    }"}],class:[
        'f-carousel-navigation-item',
        (_vm.active ? '--active' : undefined),
    ]},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('click')}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }