export default {
    props: {
        className: {
            type: String,
            default: '',
        },
        width: String,
        align: String,
        noWrap: Boolean,
        hideMobile: Boolean,
        hideTablet: Boolean,
        noPaddingLeft: Boolean,
        noPaddingRight: Boolean,
        noPaddingBottomTop: Boolean,
        noPaddingLeftRight: Boolean,
        isId: Boolean,
    },
}
