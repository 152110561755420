<template>
    <f-scroll-pane
        ref="scrollPane"
        max-height="255px"
    >
        <ul :class="['widget-user-list']">
            <slot/>
        </ul>
    </f-scroll-pane>
</template>

<script>
import FScrollPane from '../../../f-components/other/scroll-pane/FScrollPane';

export default {
    name: 'widget-user-list',

    components: {
        FScrollPane,
    },

    methods: {
        getScroll() {
            return this.$refs.scrollPane;
        },
    },
}
</script>

<style lang="scss">
.widget-user-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: stretch;

    .widget-user-list-item {
        flex: 0 0 calc(50% - 5px);
        display: flex;
        align-items: center;
        overflow: hidden;
    }
}

.--widget-xxxsmall, .--widget-xxsmall, .--widget-xsmall, .--widget-small, .--widget-medium {
    .widget-user-list .widget-user-list-item {
        flex: 0 100%;

        &:last-child {
            border: 0;
        }
    }
}
</style>
