<template>
    <f-placeholder-container class-name="f-table-placeholder">
        <f-placeholder
            v-if="!noPagination"
            width="175px"
            height="25px"
            class-name="f-table-placeholder__pagination"
        />

        <f-placeholder width="100%" height="80px"/>
        <f-placeholder width="100%" height="80px"/>
        <f-placeholder width="100%" height="80px"/>
    </f-placeholder-container>
</template>

<script>
import FPlaceholderContainer from './FPlaceholderContainer';
import FPlaceholder from './FPlaceholder';

export default {
    name: 'f-table-placeholder',

    props: {
        noPagination: Boolean,
    },

    components: {
        FPlaceholder,
        FPlaceholderContainer
    },
}
</script>

<style lang="scss">
.f-table-placeholder {
    margin-bottom: 100px;

    &__pagination {
        margin: 50px auto;
    }
}
</style>
