var render = function render(){var _vm=this,_c=_vm._self._c;return _c('th',{class:[
        'f-table-header-cell',
        (_vm.hideMobile ? '--hide-mobile' : null),
        (_vm.hideTablet ? '--hide-tablet' : null),
        (_vm.noPaddingRight ? '--no-padding-right' : null),
        (_vm.noPaddingLeftRight ? '--no-padding-left-right' : null),
        (_vm.noPaddingBottomTop ? '--no-padding-bottom-top' : null),
        (_vm.noWrap ? '--no-wrap' : null),
        (_vm.align ? `--align-${_vm.align}` : null),
        _vm.className,
    ],style:({width: _vm.width})},[_c('f-text',{attrs:{"type":"subtitle"}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }