<template>
    <div
        :class="['custom-cursor']"
        @mousemove="onMouseMove"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
    >
        <div v-if="showCursor" class="custom-cursor__cursor" :style="styles">
            <transition name="custom-cursor" appear>
                <div class="custom-cursor__cursor__body">
                    <f-text font-weight="300">
                        {{ text }}
                    </f-text>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import FText from '../../f-components/layout/text/FText';

export default {
    name: 'custom-cursor',

    components: {
        FText,
    },

    props: {
        text: {
            type: String,
            default: 'view',
        },
    },

    data() {
        return {
            styles: {
                left: 0,
                top: 0,
            },
            showCursor: false,
        }
    },

    methods: {
        onMouseMove(e) {
            if (e.target !== this.$el) return;

            this.styles = {
                left: `${e.layerX}px`,
                top: `${e.layerY}px`,
            };
        },
        onMouseEnter() {
            this.$nextTick(() => this.showCursor = true)
            this.showCursor = true;
        },
        onMouseLeave() {
            this.showCursor = false;
        },
    },
}
</script>

<style lang="scss">
.custom-cursor {
    z-index: 10 !important;
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: none;
    user-select: none;

    &__cursor {
        width: 80px;
        height: 80px;
        position: absolute;
        pointer-events: none;
        transform: translate(-50%, -50%);
        color: white;

        &__body {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: $ctaOrange;
        }
    }
}

.custom-cursor-enter-active, .custom-cursor-leave-active {
    transition: opacity .05s, transform .3s;
}

.custom-cursor-enter, .custom-cursor-leave-to {
    opacity: 0;
    transform: scale(.25);
}

</style>
