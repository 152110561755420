<template>
    <page
        v-if="hasPermission(Permissions.ADMIN_MISC_VIEW_LOGS)"
        class-name="admin-api-logs-page"
        :loading="loading"
        :title="['Admin', 'API Logs']"
        header="API logs"
        :header-badge="(fetchedData ? fetchedData.total : null)"
        pre-max-width="800px"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb>API Logs</f-breadcrumb>
                <f-breadcrumb>Overview</f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-container max-width="800px">
            <f-table
                v-if="fetchedData"
                :empty="items.length === 0"
                ref="table"
                rows-hoverable
                type="simple"
                allow-overflow
            >
                <template #pre>
                    <f-pagination
                        v-if="fetchedData"
                        :current-page="currentPage"
                        :page-count="lastPage"
                        @navigate="onPaginationNavigate"
                    />
                </template>

                <template #head>
                    <f-table-header-row>
                        <f-table-header-cell>Name</f-table-header-cell>
                        <f-table-header-cell align="center">Date</f-table-header-cell>
                        <f-table-header-cell align="right">Size</f-table-header-cell>
                    </f-table-header-row>
                </template>

                <f-table-row
                    v-for="row in items"
                    :key="row.name"
                    @click="$router.push(`/admin/api-logs/${row.name}`)"
                    no-wrap
                >
                    <f-table-cell>{{ row.name }}</f-table-cell>
                    <f-table-cell align="center">{{ row.display_date }}</f-table-cell>
                    <f-table-cell align="right">{{ row.size }}</f-table-cell>
                </f-table-row>

                <template #post>
                    <f-pagination
                        v-if="fetchedData"
                        :current-page="currentPage"
                        :page-count="lastPage"
                        @navigate="onPaginationNavigate"
                        bottom
                    />
                </template>
            </f-table>

            <f-placeholder-container v-else-if="!fetchedData && loading">
                <f-placeholder width="100%" height="60px"/>
                <f-placeholder width="100%" height="60px"/>
                <f-placeholder width="100%" height="60px"/>
            </f-placeholder-container>
        </f-container>
    </page>
</template>

<script>
import APIEndpoints from '../../../../config/APIEndpoints';
import EntityPaginatedFilteredOverviewMixin from '../../../../mixins/entities/EntityPaginatedFilteredOverviewMixin';
import Page from '../../Page';
import FTable from '../../../../f-components/data/table/FTable';
import FPlaceholderContainer from '../../../../f-components/loaders/placeholder/FPlaceholderContainer';
import FPlaceholder from '../../../../f-components/loaders/placeholder/FPlaceholder';
import FTableRow from '../../../../f-components/data/table/FTableRow';
import FTableCell from '../../../../f-components/data/table/FTableCell';
import FTableHeaderCell from '../../../../f-components/data/table/FTableHeaderCell';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import FContainer from '../../../../f-components/layout/container/FContainer';
import FTableHeaderRow from '../../../../f-components/data/table/FTableHeaderRow';
import FPagination from '../../../../f-components/navigation/pagination/FPagination';

export default {
    name: 'admin-api-logs-page',

    components: {
        FPagination,
        FTableHeaderRow,
        FContainer,
        FBreadcrumb,
        FBreadcrumbs,
        FTableHeaderCell,
        FTableCell,
        FTableRow,
        FPlaceholder,
        FPlaceholderContainer,
        FTable,
        Page,
    },

    mixins: [
        EntityPaginatedFilteredOverviewMixin,
    ],

    methods: {
        getEndpoint() {
            return APIEndpoints.ADMIN_API_LOGS;
        },
    },
}
</script>

<style lang="scss">
.admin-api-logs-page {
}
</style>
