import moment from 'moment';

export default {
    computed: {
        hasBirthday() {
            if (!this.user || !this.user.date_of_birth_visual) {
                return false;
            }

            const date = moment();
            const reg = new RegExp('^' + date.format('D MMM'));

            return reg.test(this.user.date_of_birth_visual);
        },
    },
};
