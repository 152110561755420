var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'f-tabs__container',
    '--no-select',
    ..._vm.classNames,
    `--type-${_vm.type}`,
    (_vm.margin ? '--margin' : null),
    '--scrollable-no-bar-horizontal',
]},[_c('div',{staticClass:"f-tabs"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }