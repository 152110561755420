<template>
    <div :class="['office-timezone-clock', (open ? '--open' : '--closed')]">
        <div
            class="office-timezone-clock__hour"
            :style="{ transform: `rotate(${(hoursAndMinutes * 30) + 180}deg)` }"
        />
    </div>
</template>

<script>
export default {
    name: 'office-timezone-clock',

    props: {
        hours: Number,
        minutes: Number,
    },

    computed: {
        open() {
            return this.hours >= 9 && this.hours < 18;
        },
        hoursAndMinutes() {
            return this.hours + (1 / 60) * this.minutes;
        },
    },
}
</script>

<style lang="scss">
$radius: 30px;
$border: 1px;

.office-timezone-clock {
    width: $radius;
    height: $radius;
    border-radius: 100%;
    border: $border solid $dark;
    overflow: hidden;
    position: relative;

    &__minute, &__hour {
        position: absolute;
        background: $dark;
        top: 50%;
        left: 50%;
        transform-origin: top center;
        border-radius: 2px;
    }

    &__minute {
        height: $radius;
        width: $border;
        background: $dark;
    }

    &__hour {
        //height: $radius * .35;
        height: $radius;
        width: $border;
        left: calc(50% - .5px);
    }

    &.--closed {
        background: $dark;
        border: 0;
        width: calc(#{$radius} + #{$border});
        height: calc(#{$radius} + #{$border});

        .office-timezone-clock__hour, .office-timezone-clock__minute {
            background: $softGray;
        }
    }
}
</style>
