<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
    <f-table
        :empty="data.data.length === 0"
        class-name="leave-requests-admin-table"
    >
        <template #pre>
            <f-pagination
                v-if="!approvalMode && data.data && pagination"
                :current-page="data.current_page"
                :page-count="data.last_page"
                @navigate="onPageChange"
            />
        </template>

        <template #head>
            <f-table-header-row>
                <f-table-header-cell align="center" v-if="!hideRequestee">
                    Requestee
                </f-table-header-cell>
                <f-table-header-cell>
                    Request
                </f-table-header-cell>
                <f-table-header-cell align="center">
                    Status
                </f-table-header-cell>
                <f-table-header-cell
                    v-if="!approvalMode"
                    align="center"
                >
                    Handled by
                </f-table-header-cell>
                <f-table-header-cell/>
            </f-table-header-row>
        </template>

        <leave-requests-admin-table-row
            v-if="data.data"
            v-for="request in data.data"
            :request="request"
            :key="request.id"
            :approval-mode="approvalMode"
            :hide-requestee="hideRequestee"
        />

        <template #post>
            <f-pagination
                v-if="!approvalMode && data.data && pagination"
                :current-page="data.current_page"
                :page-count="data.last_page"
                @navigate="onPageChange"
                bottom
            />
        </template>
    </f-table>
</template>

<script>
import FTable from '../../../../f-components/data/table/FTable';
import FTableHeaderCell from '../../../../f-components/data/table/FTableHeaderCell';
import LeaveRequestsAdminTableRow from './LeaveRequestsAdminTableRow';
import {Permissions} from '../../../../config/Permissions';
import FTableHeaderRow from '../../../../f-components/data/table/FTableHeaderRow';
import FPagination from '../../../../f-components/navigation/pagination/FPagination';

export default {
    name: 'leave-requests-admin-table',

    components: {
        FPagination,
        FTableHeaderRow,
        LeaveRequestsAdminTableRow,
        FTableHeaderCell,
        FTable,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
        approvalMode: Boolean,
        hideRequestee: Boolean,
        pagination: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        menuOptions() {
            let options = [];

            if (this.admin && this.hasPermission(Permissions.LEAVE_REQUESTS_APPROVE) && this.request.status === 'requested') {
                options = [{
                    value: 'approve',
                    icon: 'alarm-check',
                }, {
                    value: 'deny',
                    icon: 'alarm-error',
                }];
            }

            return options;
        },
    },

    methods: {
        onPageChange(pageNumber, position) {
            this.$emit('navigate', pageNumber, position, this.$el);
        },
    },
}
</script>

<style lang="scss">
.leave-requests-admin-table {
    .f-chip {
        span span {
            opacity: .5;

            &.f-icon {
                font-size: 80%;
            }
        }
    }
}
</style>
