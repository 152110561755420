<template>
    <navigation-item
        :badge-number="(oneSignalState.isSubscribed ? null : '•')"
        icon="alarm-ringing"
        title="Push notification<br>subscription"
        @click="popupActive = true"
    >
        <f-popup
            v-if="popupActive"
            @close="popupActive = false"
            :target="$el"
            variant="dark"
            width="260px"
        >
            <push-notification-select-content
                v-if="oneSignalState.isSupported"
                @toggle="onSubscribeToggle"
                :subscribed="oneSignalState.isSubscribed"
                :loading="loading"
            />
            <div v-else style="padding: 10px; text-align: center;">
                Push notifications are not<br>
                supported in this browser.
            </div>
        </f-popup>
    </navigation-item>
</template>

<script>
import PushNotificationSelectContent from './PushNotificationSelectContent';
import EventBus from '../../../../../services/EventBus';
import {GlobalEvents} from '../../../../../config/Events';
import FPopup from '../../../../../f-components/other/popup/FPopup';
import OneSignal from '../../../../../services/OneSignal';
import NavigationItem from './NavigationItem';

export default {
    name: 'push-notification-item',

    components: {
        NavigationItem,
        PushNotificationSelectContent,
        FPopup,
    },

    data() {
        return {
            popupActive: false,
            oneSignalState: OneSignal.getState(),
            loading: false,
        }
    },

    created() {
        this.oneSignalState = OneSignal.getState();
        EventBus.$on(GlobalEvents.ONESIGNAL_STATE_CHANGE, (state) => {
            this.loading = false;
            this.oneSignalState = state;
        });
    },

    methods: {
        onSubscribeToggle() {
            this.loading = true;
            OneSignal.toggleSubscription();
        },
    },
}
</script>
