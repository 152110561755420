<template>
    <span
        :class="['profitable-bar', className, percentageClass]"
        :style="`width: ${width}; min-width: ${minWidth};`"
    >
        <span
            v-if="percentageLocation === 'left'"
            class="profitable-bar__percentage"
        >
            <span>{{ percentageLabel }} <slot/></span>
        </span>
         <span class="profitable-bar__full-bar">
            <span
                class="profitable-bar__full-bar__inner"
                :style="`width: ${(percentage > 100 ? 100 : percentage)}%`"
            />
        </span>
        <span
            v-if="percentageLocation === 'right'"
            class="profitable-bar__percentage --align-right"
        >
            <span>{{ percentageLabel }} <slot/></span>
        </span>
    </span>
</template>

<script>
export default {
    name: 'profitable-bar',

    props: {
        total: {
            type: Number,
            required: true,
        },
        current: {
            type: Number,
            required: true,
        },
        className: String,
        percentageLocation: {
            type: String,
            default: 'left', // left | right
        },
        width: {
            type: String,
            default: '20%',
        },
        minWidth: {
            type: String,
            default: '50px',
        },
    },

    computed: {
        percentage() {
            let current = this.current,
                total = this.total;

            // Check for NaNs
            if (current !== current) current = 0;
            if (total !== total) total = 0;

            let percentage = (current / total) * 100;
            if (percentage !== percentage) percentage = 0;

            // Round
            percentage = Math.round(percentage);

            return percentage;
        },

        percentageLabel() {
            let label = `${this.percentage}%`;
            if (!isFinite(this.percentage)) {
                label = '∞';
            }
            return label;
        },

        percentageClass() {
            let percentageClass;
            if (this.percentage > 100 && this.percentage <= 105) {
                percentageClass = '--warning';
            } else if (this.percentage > 105 && this.percentage <= 120) {
                percentageClass = '--alert';
            } else if (this.percentage > 120) {
                percentageClass = '--alert --extra-alert';
            } else if (!isFinite(this.percentage)) {
                percentageClass = '--alert --extra-alert';
            }
            return percentageClass;
        },
    },
}
</script>


<style lang="scss">
.profitable-bar {
    height: 8px;
    line-height: 0;
    text-align: right;
    display: inline-block;
    white-space: nowrap;

    &__full-bar {
        border: 1px solid $quaternaryGray;
        text-align: left;
        width: calc(100% - 30px);
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        overflow: hidden;
        border-radius: 999px;

        &__inner {
            height: 100%;
            display: inline-block;
            background: $success;
        }

        @include theme(dark) {
            border-color: $light;
        }
    }

    .f-table-row.--hover &__full-bar {
        border-color: $softGray;
    }

    &.--warning {
        .profitable-bar__full-bar__inner {
            background: lighten(orange, 5%);
        }
    }

    &.--alert {
        .profitable-bar__full-bar__inner {
            background: $danger;
        }
    }

    &.--extra-alert {
        .profitable-bar__full-bar {
            .profitable-bar__full-bar__inner {
                animation: blink 1.5s linear infinite;
                background: $dangerShade;
            }
        }
    }

    &__percentage {
        width: 28px;
        display: inline-block;
        font-size: 70%;
        height: 100%;
        opacity: .75;
        vertical-align: middle;
        line-height: 100%;
        position: relative;
        padding-left: 2px;
        font-weight: 500;

        > span {
            position: absolute;
            right: 2px;
            top: 0;
        }

        @media only screen and (max-width: 600px) {
            font-size: 60%;
        }

        &.--align-right > span {
            left: 4px;
            right: auto;
        }
    }
}
</style>
