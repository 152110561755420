<template>
    <transition name="fade-small" appear>
        <li
            class="booking-ghost"
            :style="styles"
            :class="[(isSmall ? '--small' : null)]"
            v-if="active"
        >
            <div class="content">
                <b>New meeting</b>
                <span>{{ visualTimeSpan }}</span>
            </div>
        </li>
    </transition>
</template>

<script>
import EventBus from '../../../../services/EventBus';

export default {
    name: 'booking-ghost',

    /**
     * Component prop variables.
     */
    props: {
        data: {
            type: Object,
            required: true,
        },
        i: Number,
    },

    /**
     * Component data variables.
     */
    data() {
        return {
            settings: {
                ...this.data,
                ...{
                    columnI: 0,
                    columnSpan: 1,
                    columnCount: 1,
                },
            },
            active: true,
        };
    },

    /**
     * Component created event.
     */
    created: function () {
        EventBus.$on('bookingGhost.update', this.updateDates.bind(this));
        EventBus.$on('bookingGhost.hide', () => {
            this.active = false;
            EventBus.$emit('meetingRoomBooking.hide');
        });
        EventBus.$on('bookingGhost.close', () => {
            this.active = false;
            EventBus.$emit('meetingRoomBooking.hide');
        });
    },

    /**
     * Component before destroy.
     */
    beforeDestroy() {
        EventBus.$off('bookingGhost.update', this.updateDates.bind(this));
    },

    /**
     * Component computed variables.
     */
    computed: {
        styles() {
            let diff = this.settings.start.diff(this.settings.end, 'minutes');
            let extra = '';

            let minute = 100 / (24 * 60);
            let top = (this.settings.start.minutes() + (this.settings.start.hours() * 60)) * minute;
            let height = Math.abs(diff) * minute;

            let left = 0;
            let columnWidth = (100 / this.settings.columnCount);

            if (this.settings.columnCount) {
                let width = columnWidth * this.settings.columnSpan;

                extra = `width: calc(${width}% - 1px);`;
                left = columnWidth * this.settings.columnI;
            }

            return `top: calc(${top}% + 2px); height: calc(${height}% - 3px); left: calc(${left}% + 1px); ${extra}`;
        },

        visualTimeSpan() {
            return this.settings.start.format('HH:mm') + ' – ' + this.settings.end.format('HH:mm');
        },

        isSmall() {
            let minutes = this.settings.end.diff(this.settings.start, 'minutes');

            return minutes < 20;
        },
    },

    /**
     * Component mounted event.
     */
    mounted() {
        EventBus.$emit('meetingRoomBooking.show', this.$el, {
            ...{
                meetingStart: this.settings.start,
                meetingEnd: this.settings.end,
            }, ...this.data
        });
    },

    /**
     * Component methods.
     */
    methods: {
        /**
         * The duration of the transition.
         */
        updateDates(start, end) {
            this.settings.start = start;
            this.settings.end = end;
        },
    },
}
</script>

<style lang="scss">
.time-table .booking-ghost {
    color: black;
    position: absolute;
    width: calc(100% - 1px);
    border-radius: 1px;
    overflow: hidden;
    background: white;
    z-index: 2;
    transition: height .1s;

    @include media(mobile-down) {
        display: none;
    }

    .content {
        overflow: hidden;
        font-size: 80%;
        padding: 3px 6px;
        white-space: nowrap;
        height: 100%;
        text-overflow: ellipsis;

        b {
            padding-right: 3px;
        }
    }
}
</style>
