<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for-->
    <div
        class="time-table"
        :style="`--row-height: ${rowHeight}px;`"
    >
        <!-- Headers -->
        <div
            v-if="showHeader"
            class="time-table__header-scroller --scrollable-no-bar"
            ref="headerScroll"
        >
            <header
                class="time-table__header"
                ref="header"
                :style="{ minWidth }"
            >
                <slot/>
            </header>
        </div>

        <!-- All day meetings -->
        <div
            v-if="allDayMeetings && allDayMeetings.length > 0"
            class="time-table__all-day-scroller --scrollable-no-bar"
            ref="allDayScroll"
        >
            <div
                class="time-table__all-day-container"
                :style="{ minWidth }"
            >
                <aside class="time-table__all-day__label">
                    All day
                </aside>
                <div class="time-table__all-day__meetings-container">
                    <ul>
                        <meeting
                            v-for="(meeting, i) in allDayMeetings"
                            :key="i"
                            :i="i"
                            :data="meeting"
                            office="_"
                        />
                    </ul>
                </div>
            </div>
        </div>

        <!-- Time table container -->
        <div class="time-table__container">
            <!-- Time line -->
            <aside
                class="time-table__timeline-scroller --scrollable-no-bar"
                :style="{ maxHeight, minHeight, height }"
                ref="timelineScroll"
            >
                <div
                    class="time-table__timeline-scroller__content"
                    ref="timelineScrollContent"
                >
                    <div
                        v-if="showNow"
                        class="time-table__now"
                        :style="{ top: `calc(${nowTopPercentage}% + 2px)` }"
                    />

                    <ul class="time-table__timeline">
                        <li
                            v-for="n in 24"
                            :key="n"
                        >
                            <div
                                class="time-table__timeline-hour"
                                v-html="getTime(( n > 10 ? '' : '0') + (n - 1) + ':00')"
                            />
                        </li>
                    </ul>
                </div>
            </aside>

            <!-- Content -->
            <div
                ref="scroll"
                class="time-table__content-scroller --scrollable-themed-both"
                :style="{ maxHeight }"
                @scroll="onScroll"
            >
                <div
                    class="time-table__content"
                    ref="timeTable"
                    :style="{ minWidth }"
                >
                    <ul class="time-table__timeline">
                        <li
                            v-for="n in 24"
                            :key="n"
                            class="time-table__timeline-line"
                        />
                    </ul>

                    <div
                        v-if="showNow"
                        class="time-table__now"
                        ref="now"
                        :style="{ top: `calc(${nowTopPercentage}% + 2px)` }"
                    />

                    <ul
                        v-if="meetingGroups"
                        v-for="(group, i) in meetingGroups"
                        :class="['time-table__content__meetings-container', `group-${i}`]"
                        :key="i"
                        :style="{
                            width: `calc(${(100 / meetingGroups.length)}% - 1px)`,
                            left: `${(i * (100 / meetingGroups.length))}%`
                        }"
                        :ref="`group${i}`"
                        @mousedown="addBookingGhostDown($event, i)"
                    >
                        <meeting
                            v-for="meeting in defineOverlaps(group)"
                            :key="meeting.id"
                            :data="meeting"
                            :clock="clock"
                            :office="office"
                        />
                        <booking-ghost
                            v-if="ghosts[i]"
                            :key="2000"
                            :data="ghosts[i]"
                        />
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone';
import Meeting from './Meeting';
import BookingGhost from './booking/BookingGhost';
import APIDataStore from '../../../services/APIDataStore';
import TimezoneMixin from '../../../mixins/TimezoneMixin';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import {Permissions} from '../../../config/Permissions';

export default {
    name: 'time-table',

    mixins: [
        TimezoneMixin,
    ],

    props: {
        /**
         * The ID of the office.
         */
        office: {
            type: String,
            required: true,
        },
        /**
         * Meeting data. Simple array with meetings.
         */
        meetings: Array,
        /**
         * All day meeting data. Simple array with meetings.
         */
        allDayMeetings: Array,
        /**
         * Meeting rooms data. Array containing meeting rooms, containing calendar with events.
         */
        meetingRooms: Array,
        /**
         * Show the current time.
         */
        showNow: {
            type: Boolean,
            default: true,
        },
        /**
         * If this timeline is bookable.
         */
        bookable: Boolean,
        /**
         * Show timeline header
         */
        showHeader: Boolean,
        /**
         * Clock.
         */
        clock: String, // '24' or '12' if you want to overrule the user setting.
        /**
         * Max height (for the ver scroll element)
         */
        maxHeight: String,
        height: String,
        /**
         * Min height (for the ver scroll element)
         */
        minHeight: String,
        /**
         * Min width (for the hor scroll element)
         */
        minWidth: String,
        /**
         * Row height (hour)
         */
        rowHeight: {
            type: Number,
            default: 120,
        },
    },

    components: {
        BookingGhost,
        Meeting,
    },

    data() {
        return {
            debug: false,
            nowTopPercentage: 0,
            currentDate: APIDataStore.date(),
            ghosts: [],
        }
    },

    computed: {
        /**
         * Arranges meetingRooms data OR meetings data, into usable groups.
         */
        meetingGroups() {
            if (this.meetingRooms) {
                let meetingGroups = [];
                for (let i in this.meetingRooms) {
                    if (this.meetingRooms[i].calendar && this.meetingRooms[i].calendar.items) {
                        meetingGroups.push(this.meetingRooms[i].calendar.items.filter((meeting) => !meeting.is_all_day));
                    } else {
                        meetingGroups.push([]);
                    }
                }

                return meetingGroups;
            } else if (this.meetings) {
                return [this.meetings];
            }

            return [];
        },
    },

    mounted() {
        if (this.showNow) {
            this.setNowStyles();
            EventBus.$on(GlobalEvents.MINUTE_CHANGE, this.setNowStyles);
        }
        EventBus.$on('meetingRooms.date.change', this.onDateChange);
        this.setInitialScrollPosition();
    },

    beforeDestroy() {
        EventBus.$off(GlobalEvents.MINUTE_CHANGE, this.setNowStyles);
        EventBus.$off('meetingRooms.date.change', this.onDateChange);
    },

    methods: {
        onDateChange(date) {
            this.ghosts = [];
            this.currentDate = date;

            this.setInitialScrollPosition();
        },

        setInitialScrollPosition() {
            this.$nextTick(() => {
                if (this.getScrollElement()) {
                    if (this.showNow) {
                        const now = moment.tz(this.timezone);
                        const verticalMiddle = this.getHeight() / 2;

                        if (now.hours() > 4) {
                            this.getScrollElement().scrollTop = Math.round(this.getClientHeight() * (this.getNowTopPercentage() / 100)) - verticalMiddle;
                        } else {
                            this.getScrollElement().scrollTop = 0;
                        }
                    } else {
                        this.getScrollElement().scrollTop = Math.round((this.getClientHeight() / 24) * 9);
                    }
                }
            });
        },

        onScroll() {
            this.$refs.timelineScroll.scrollTop = this.$refs.scroll.scrollTop;
            if (this.$refs.headerScroll) {
                this.$refs.headerScroll.scrollLeft = this.$refs.scroll.scrollLeft;
            }
            if (this.$refs.allDayScroll) {
                this.$refs.allDayScroll.scrollLeft = this.$refs.scroll.scrollLeft;
            }

            this.$refs.timelineScrollContent.style.height = this.$refs.timeTable.clientHeight + (this.$refs.scroll.clientWidth < this.$refs.scroll.scrollWidth ? 2 : 0) + 'px';

            EventBus.$emit(GlobalEvents.OVERFLOW_SCROLL);
        },

        getTime(time) {
            const clock = (this.clock ? this.clock : this.$store.getters.getUserSetting('global.clock'));

            return (clock === '12' ? moment(time, 'HH:mm').format('h [<span class="--am-pm">]A<[/span>]') : time);
        },

        /**
         * Style string for the now.
         */
        setNowStyles() {
            const minute = 100 / (24 * 60);
            const now = moment.tz(this.timezone);

            this.nowTopPercentage = (now.minutes() + (now.hours() * 60)) * minute;
        },

        /**
         * Function being called from outside.
         */
        getNowTopPercentage() {
            return this.nowTopPercentage;
        },

        /**
         * Returns now dom element.
         */
        getNowElement() {
            return this.$refs.now;
        },

        /**
         * Returns scroll container element.
         */
        getScrollElement() {
            return this.$refs.scroll;
        },

        getClientHeight() {
            if (this.$refs.timeTable) {
                return this.$refs.timeTable.getBoundingClientRect().height;
            }
            return this.rowHeight * 24;
        },

        getHeight() {
            return this.$el.clientHeight;
        },

        /**
         * @param meetings
         * @returns {*}
         */
        defineOverlaps(meetings) {
            let columns = [[]];
            let columnsDebug = [[]];

            if (this.debug) {
                console.log(' ------ START ------');
            }

            // Check if meetings overlap
            for (let i in meetings) {
                let foundColumn = false;

                for (let y in columns) {
                    if (!foundColumn) {
                        if (!this.hasMultiOverlap(meetings[i], columns[y])) {
                            foundColumn = true;

                            columns[y].push(meetings[i]);

                            if (this.debug) {
                                columnsDebug[y].push(this.getDebugMeetingString(meetings[i]));
                            }
                        }
                    }
                }

                // If not found add to new column
                if (!foundColumn) {
                    columns.push([
                        meetings[i],
                    ]);

                    if (this.debug) {
                        columnsDebug.push([
                            this.getDebugMeetingString(meetings[i]),
                        ]);
                    }
                }
            }

            if (this.debug) {
                console.log(' ------ MIDDLE ------');
            }

            // Parse columns to meeting array
            // -- Loop columns
            for (let y in columns) {
                let column = columns[y];
                let yp = parseInt(y);

                // -- Loop meetings in columns
                for (let x in column) {
                    // -- Loop all meetings in the meeting variable to get the correct i
                    for (let i in meetings) {
                        if (meetings[i].id === column[x].id) {
                            meetings[i].columnCount = columns.length;
                            meetings[i].columnI = yp;
                            meetings[i].columnSpan = 1;

                            let colSpan = 1;
                            if (yp >= columns.length - 1) {
                                continue;
                            }

                            // Calculate col span by checking overlap in remaining columns
                            for (let z in columns) {
                                let zp = parseInt(z);
                                if (zp > yp) {
                                    if (this.hasMultiOverlap(meetings[i], columns[z])) {
                                        meetings[i].columnSpan = colSpan;
                                        break;
                                    } else {
                                        colSpan++;
                                    }
                                }
                            }

                            meetings[i].columnSpan = colSpan;
                        }
                    }
                }
            }

            if (this.debug) console.log(' ------ END ------');

            return meetings;
        },

        /**
         * Checks if the meeting has any overlapping meetings. Used to create an agenda with possible overlapping items.
         *
         * @param meeting
         * @param meetings
         * @returns {boolean}
         */
        hasMultiOverlap(meeting, meetings) {
            if (this.debug) console.log('Checking', this.getDebugMeetingString(meeting));

            const meetingStartDate = moment.tz(meeting.start.date, meeting.start.timezone).tz(this.timezone).format('x');
            const meetingEndDate = moment.tz(meeting.end.date, meeting.end.timezone).tz(this.timezone).format('x');

            for (let y in meetings) {
                if (meetings[y].id !== meeting.id) {
                    let compareStartDate = moment.tz(meetings[y].start.date, meetings[y].start.timezone).tz(this.timezone).format('x');
                    let compareEndDate = moment.tz(meetings[y].end.date, meetings[y].end.timezone).tz(this.timezone).format('x');

                    if (
                        (meetingStartDate >= compareStartDate && meetingStartDate < compareEndDate)
                        || (meetingEndDate >= compareStartDate && meetingEndDate < compareEndDate)
                        || (compareStartDate >= meetingStartDate && compareStartDate < meetingEndDate)
                    ) {
                        if (this.debug) {
                            console.log(
                                '   -> against', this.getDebugMeetingString(meetings[y]),
                                (meetingStartDate >= compareStartDate && meetingStartDate < compareEndDate),
                                (meetingEndDate >= compareStartDate && meetingEndDate < compareEndDate),
                                (compareStartDate >= meetingStartDate && compareStartDate < meetingEndDate)
                            );
                        }

                        return true;
                    }

                    if (this.debug) {
                        console.log('   -> against', this.getDebugMeetingString(meetings[y]), false);
                    }
                }
            }

            return false;
        },

        /**
         * Returns a string for the meeting, used for debugging.
         *
         * @param meeting
         * @returns {string}
         */
        getDebugMeetingString(meeting) {
            const startI = moment.tz(meeting.start.date, meeting.start.timezone).tz(this.timezone);
            const endI = moment.tz(meeting.end.date, meeting.start.timezone).tz(this.timezone);

            return `[${startI.format('DD/MM HH:mm')} - ${endI.format('DD/MM HH:mm')} / ${meeting.name}]`;
        },

        /**
         * @param e
         * @param i
         * @deprecated
         */
        addBookingGhostDown(e, i) {
            if (!this.bookable || !this.hasPermission(Permissions.MEETING_ROOM_BOOK)) {
                return;
            }
            if (e.target !== this.$refs[`group${i}`][0]) {
                return;
            }

            // Calculate percentage offset
            const offsetPercentage = (e.offsetY / this.getClientHeight()) * 100;

            // Calculate percentage to total day minutes
            let minutes = (offsetPercentage / 100) * (24 * 60);

            // Round to 5's
            const diff = minutes % 5;
            minutes -= diff;

            // Create date at beginning of day and add minutes
            const dateStart = moment(this.currentDate, 'DD-MM-YYYY')
                .tz(this.timezone)
                .startOf('day')
                .minutes(minutes);
            const dateEnd = dateStart.clone().add(15, 'minutes');
            const now = moment.tz(this.timezone);

            // Check if not in past
            if (now >= dateStart) {
                return;
            }

            // Check overlap
            if (this.hasOverlap(dateStart, dateEnd, this.meetingGroups[i])) {
                return;
            }

            // Empty ghosts array
            this.ghosts = [];

            // On next tick add the actual ghost
            this.$nextTick(() => {
                this.ghosts[i] = {
                    i: i,
                    office: this.office,
                    timezone: this.timezone,
                    start: dateStart,
                    end: dateEnd,
                    parentScroll: document.querySelector('.time-table__content-scroller'),
                    date: this.currentDate,
                    meetingRoom: {
                        email_address: this.meetingRooms[i].email_address,
                    },
                    hasOverlap: (dateStart, dateEnd) => {
                        return this.hasOverlap(dateStart, dateEnd, this.meetingGroups[i]);
                    },
                };

                this.$forceUpdate();
            });
        },

        /**
         * @param dateStart
         * @param dateEnd
         * @param meetings
         * @returns {boolean}
         */
        hasOverlap(dateStart, dateEnd, meetings) {
            for (let y in meetings) {
                const compareStartDate = moment.tz(meetings[y].start.date, meetings[y].start.timezone).tz(this.timezone).format('x');
                const compareEndDate = moment.tz(meetings[y].end.date, meetings[y].end.timezone).tz(this.timezone).format('x');

                if (
                    (dateStart > compareStartDate && dateStart < compareEndDate) ||
                    (dateEnd > compareStartDate && dateEnd < compareEndDate)
                ) {
                    return true;
                }
            }
            return false;
        }
    },
}
</script>

<style lang="scss">
.time-table, .time-table__all-day {
    margin: 10px 0;

    // Time table header
    .time-table__header-scroller {
        width: calc(100% - 64px);
        margin-left: 50px;
        overflow: auto;
        min-height: 50px;
        pointer-events: none;

        header.time-table__header {
            position: relative;
            width: 100%;
            display: flex;
        }
    }

    // All day events
    .time-table__all-day-container {
        display: flex;
        width: calc(100% - 10px);
        border-bottom: 1px solid red;
        position: relative;

        @include theme(light) {
            border-bottom-color: darken($softGray, 10%);
        }

        @include theme(dark) {
            border-bottom-color: $tertiaryGray;
        }

        .time-table__all-day__label {
            flex: 0 1 50px;
            font-size: 80%;
            text-align: right;
            padding: 2px 10px 4px 2px;
            border-right: 1px solid red;

            @include theme(light) {
                border-right-color: darken($softGray, 10%);
            }

            @include theme(dark) {
                border-right-color: $tertiaryGray;
            }
        }

        .time-table__all-day__meetings-container {
            padding: 1px 2px 0 1px;
            flex: 1 1 auto;
            overflow: auto;

            .time-table-meeting {
                position: relative;
                top: inherit !important;
                left: inherit !important;
                margin-bottom: 1px;
            }
        }
    }

    // Content container
    .time-table__container {
        display: flex;
        width: 100%;

        // Time line
        .time-table__timeline-scroller {
            flex: 0 1 50px;
            pointer-events: none;

            .time-table__timeline-scroller__content {
                height: calc(24 * var(--row-height));
                position: relative;

                .time-table__now {
                    left: 100%;
                }

                &:before {
                    content: '';
                    position: absolute;
                    height: 100%;
                    right: 0;
                    top: 0;
                    border-right: 1px solid red;

                    @include theme(light) {
                        border-right-color: darken($softGray, 10%);
                    }

                    @include theme(dark) {
                        border-right-color: $quaternaryGray;
                    }
                }

                .time-table__timeline li {
                    &:before {
                        content: '';
                        position: absolute;
                        width: 5px;
                        right: 0;
                        top: 0;
                        border-top: 1px solid red;

                        @include theme(light) {
                            border-top-color: darken($softGray, 10%);
                        }

                        @include theme(dark) {
                            border-top-color: $quaternaryGray;
                        }
                    }

                    &:after {
                        display: none;
                    }
                }
            }
        }

        // Time line shared
        .time-table__timeline {
            position: relative;
            pointer-events: none;

            li {
                position: relative;
                height: var(--row-height);

                &:first-child {
                    border-color: transparent !important;

                    &:before, .time-table__timeline-hour {
                        display: none;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    right: 0;
                    top: 50%;
                    border-top: 1px solid red;

                    @include theme(light) {
                        border-top-color: darken($softGray, 10%);
                    }

                    @include theme(dark) {
                        border-top-color: $quaternaryGray;
                    }
                }

                &.time-table__timeline-line {
                    border-top: 1px solid red;

                    @include theme(light) {
                        border-top-color: darken($softGray, 10%);
                    }

                    @include theme(dark) {
                        border-top-color: $quaternaryGray;
                    }
                }

                .time-table__timeline-hour {
                    font-size: 80%;
                    position: absolute;
                    top: -8px;
                    text-align: right;
                    right: 10px;

                    .--am-pm {
                        text-transform: lowercase;
                    }
                }
            }
        }

        // Content
        .time-table__content-scroller {
            flex: 1 1 auto;
            overflow: auto;
            position: relative;

            .time-table__content {
                height: calc(24 * var(--row-height));
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-right: 10px solid;

                @include theme(light) {
                    border-right-color: $light;
                }

                @include theme(dark) {
                    border-right-color: $dark;
                }

                .time-table__content__meetings-container {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 0 1px;
                }

                //// TODO: test .ghost rename, can't find it
                //.time-table__meeting-ghost {
                //    height: 40px;
                //    background: rgba(white, 1);
                //    position: absolute;
                //    border-radius: 1px;
                //    box-shadow: rgba(0, 0, 0, .05) 0 0 5px;
                //    left: calc(0% + 1px);
                //    width: calc(100% - 1px);
                //
                //    .content {
                //        color: $dark;
                //        overflow: hidden;
                //        font-size: 80%;
                //        padding: 3px 6px;
                //        white-space: nowrap;
                //        height: 100%;
                //        text-overflow: ellipsis;
                //
                //        b {
                //            padding-right: 3px;
                //        }
                //    }
                //}
            }
        }

        // Now
        .time-table__now {
            height: 0;
            border-bottom: 1px solid $primary;
            position: absolute;
            width: 100%;
            left: 0;
            z-index: 3;

            &:before {
                content: '';
                position: absolute;
                background: $primary;
                border-radius: 50%;
                width: 7px;
                height: 7px;
                left: -4px;
                top: -3px;
            }
        }
    }
}

.time-table__all-day {
    .meetings-container {
        width: calc(100% - 63px);
    }

    .time-table-timeline {
        li {
            &:before {
                display: none;
            }

            .time-container {
                top: 0;
            }
        }
    }
}
</style>
