<template>
    <f-table-row class-name="leave-requests-date-field">
        <f-table-cell style="opacity: .5;">
            {{ formattedDate }}
        </f-table-cell>
        <f-table-cell align="right">
            <f-text-input
                v-model="hrs"
                :disabled="disabled"
                @input="onHrsChange"
                @change="onHrsChange"
                name="hrs"
                width="50px"
                align="right"
                type="number"
            />
        </f-table-cell>
    </f-table-row>
</template>

<script>
import moment from 'moment-timezone';
import FTableCell from '../../../../f-components/data/table/FTableCell';
import FTableRow from '../../../../f-components/data/table/FTableRow';
import FTextInput from '../../../../f-components/form-controls/text/FTextInput';

export default {
    name: 'leave-requests-date-field',

    components: {
        FTextInput,
        FTableRow,
        FTableCell,
    },

    props: {
        value: {
            default: 8,
        },
        date: {
            type: String,
            default: 'test',
            required: false,
        },
        disabled: Boolean,
    },

    mounted() {
        this.hrs = this.value;
    },

    data() {
        return {
            hrs: null,
        }
    },

    computed: {
        formattedDate() {
            return moment(this.date).format('ddd D MMM YYYY');
        },
    },

    methods: {
        onHrsChange() {
            if (!this.hrs) return;

            let hrs = parseFloat(this.hrs);

            if (hrs > 8) {
                hrs = 8;
            } else if (hrs < .5) {
                hrs = 1;
            }

            this.hrs = hrs;

            this.$emit('input', this.date, this.hrs);
            this.$emit('change', this.date, this.hrs);
        },
    },
}
</script>
