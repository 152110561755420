var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'f-filter__container',
        '--loader',
    ]},[_c('section',{class:[
            'f-filter',
            _vm.className,
            (_vm.filterList && _vm.showActiveFilters ? '--active' : null),
            (_vm.extra ? '--extra' : null),
        ]},[_c('div',{staticClass:"f-filter__section"},[_vm._t("default"),(_vm.extra)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],staticClass:"f-filter__extra"},[_vm._t("extra")],2):_vm._e(),(_vm.extra)?_c('div',{staticClass:"f-filter__extra-button"},[_c('f-icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:((_vm.open ? 'Less filters' : 'More filters')),expression:"(open ? 'Less filters' : 'More filters')"}],attrs:{"icon":(_vm.open ? 'minus' : 'plus'),"size":"small","outlined":""},on:{"click":_vm.toggleExtra}})],1):_vm._e()],2),(_vm.showActiveFilters && _vm.filterList)?_c('div',{staticClass:"f-filter__section f-filter__list --no-select"},[_c('f-text',{attrs:{"type":"subtitle","class-name":"f-filter__list__header"}},[_vm._v(" Active filters ")]),_vm._l((_vm.filterList),function(filter){return _c('f-chip',{key:filter.id,attrs:{"variant":"dark"}},[_c('span',{staticClass:"f-filter__list__name"},[_vm._v(" "+_vm._s(filter.name)+" ")]),_c('span',{domProps:{"innerHTML":_vm._s(filter.value)}})])})],2):_vm._e()]),_vm._t("post")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }