<template>
    <page
        v-if="hasPermission(Permissions.ADMIN_ANNOUNCEMENTS)"
        :title="['Admin', 'Announcements', 'Edit']"
        class-name="admin-announcement-edit-create-page"
        header="Edit announcements"
        pre-max-width="900px"
        :skewed-footer-foreground="(!fetchedData && loading ? '#fff' : null)"
        stretch-first-container
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>
                    Admin
                </f-breadcrumb>
                <f-breadcrumb link="/admin/announcements">
                    Announcements
                </f-breadcrumb>
                <f-breadcrumb
                    v-if="mode !== 'create'"
                    :loading="!dataLoaded"
                >
                    {{ fields ? fields.timeline_title : '' }}
                </f-breadcrumb>
                <f-breadcrumb>
                    {{ mode }}
                </f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-form
            :loading="loading"
            @submitValid="(dataLoaded ? submit() : null)"
            validate
        >
            <f-container max-width="900px">
                <div v-if="dataLoaded">
                    <f-form-fieldset legend="Timeline announcement">
                        <template #legend>
                            Timeline announcement
                            <f-help-label
                                text="You can preview the timeline item below this form"
                            />
                        </template>

                        <f-form-row label="Date">
                            <f-date-picker
                                :validation="['required']"
                                v-model="fields.date"
                                name="date"
                                max-width="200px"
                            />
                        </f-form-row>

                        <f-form-row-spacer/>

                        <f-form-row label="Image">
                            <f-flex main-axis-align="flex-start" cross-axis-align="flex-start" gap="20px">
                                <f-image-input
                                    ref="imageUploadField"
                                    name="image"
                                    :src="(fetchedData && fetchedData.data ? fetchedData.data.timeline_image_url : undefined)"
                                    type="square"
                                    @sourceChange="onImageSourceChange"
                                    @reset="onImageReset"
                                    resetable
                                />
                                <div class="admin-announcement-edit-create-page__default-images">
                                    Defaults:

                                    <f-flex main-axis-align="flex-start" cross-axis-align="flex-start" gap="5px">
                                        <div
                                            v-for="(defaultImage, i) in defaultImages"
                                            @click.prevent="setToDefaultImage(i)"
                                            :key="i"
                                            :style="{ backgroundImage: `url(${defaultImage})`}"
                                        />
                                    </f-flex>
                                </div>
                            </f-flex>
                        </f-form-row>

                        <f-form-row-spacer/>

                        <f-form-row
                            label="For page"
                            help="You can publish an announcement for the dashboard or HR page"
                        >
                            <f-dropdown
                                v-model="fields.page"
                                name="page"
                                :options="[{key: 'dashboard', value: 'dashboard'}, {key: 'hr', value: 'hr'}]"
                                :validation="['required']"
                                max-width="140px"
                            />
                        </f-form-row>

                        <f-form-row-spacer/>

                        <f-form-row
                            label="Type"
                            help="Type of the announcement, e.g. Company Presentation or CTU. Can of course also be used differently"
                        >
                            <f-text-input
                                v-model="fields.type"
                                name="type"
                                max-width="300px"
                                resetable
                            />
                        </f-form-row>

                        <f-form-row label="Title">
                            <f-text-input
                                v-model="fields.timeline_title"
                                name="title"
                                max-width="300px"
                            />
                        </f-form-row>

                        <f-form-row label="Title size">
                            <f-dropdown
                                :validation="['required']"
                                v-model="fields.timeline_title_size"
                                name="timeline title size"
                                :options="announcementEventTitleSizes"
                                max-width="120px"
                            />
                        </f-form-row>

                        <f-form-row label="Subtitle">
                            <f-text-input
                                v-model="fields.timeline_subtitle"
                                name="subtitle"
                                max-width="300px"
                                resetable
                            />
                        </f-form-row>

                        <f-form-row label="Ratio">
                            <f-dropdown
                                v-model="fields.timeline_ratio"
                                name="timeline ratio"
                                :options="announcementEventRatios"
                                :validation="['required']"
                                max-width="200px"
                            />
                        </f-form-row>

                        <f-form-row-spacer/>

                        <f-form-row label="Pin" help="Pinning this announcement, will always show it first">
                            <f-switch v-model="fields.is_pinned"/>
                        </f-form-row>

                        <f-form-row label="Published" help="If the timeline announcement is published">
                            <f-switch v-model="fields.is_active"/>
                        </f-form-row>
                    </f-form-fieldset>

                    <f-form-row-spacer/>

                    <f-form-fieldset>
                        <template #legend>
                            Announcement visibility
                        </template>
                        <f-form-row
                            label="Only show for office(s)"
                            help="Only show the announcement for the employee that belongs to the selected office(s). If empty, it will be shown to all offices"
                        >
                            <f-chips-input
                                v-model="fields.show_for_offices"
                                name="show for offices"
                                :options="officeOptionsFlat"
                            />
                        </f-form-row>

                        <f-form-row
                            label="Hide for freelancers"
                            help="If selected, the announcement will not be shown for freelancers"
                        >
                            <f-switch v-model="fields.hide_for_freelancers"/>
                        </f-form-row>
                    </f-form-fieldset>

                    <f-form-row-spacer/>

                    <f-form-fieldset legend="Announcement popup">
                        <template #legend>
                            Announcement popup
                            <f-help-label
                                text="Click on the timeline item preview below this form to also preview the popup"
                            />
                        </template>

                        <f-form-row label="Title">
                            <f-text-input
                                :validation="['required']"
                                v-model="fields.popup_title"
                                name="title"
                            />
                        </f-form-row>
                        <f-form-row label="Body">
                            <f-editor
                                v-model="fields.popup_body"
                                :toolbar="getEditorToolbar()"
                                name="body"
                            />
                        </f-form-row>

                        <f-form-row-spacer/>

                        <f-form-row label="Embed document">
                            <f-switch v-model="fields.popup_has_embed"/>
                        </f-form-row>

                        <f-form-row label="Embed type" v-if="fields.popup_has_embed">
                            <f-dropdown
                                v-model="fields.popup_embed_type"
                                name="embed type"
                                :options="[
                                    {key: 'drive', value: 'Google Drive'},
                                    {key: 'iframe', value: 'iFrame'},
                                ]"
                                :validation="['required']"
                                max-width="160px"
                            />
                        </f-form-row>

                        <f-form-row
                            v-if="fields.popup_has_embed"
                            :label="(fields.popup_embed_type === 'iframe' ? 'URL' : 'Embed ID')"
                        >
                            <f-text-input
                                :validation="['required']"
                                v-model="fields.popup_embed_id"
                                :name="(fields.popup_embed_type === 'iframe' ? 'URL' : 'embed ID')"
                            />
                            <google-embed-help v-if="fields.popup_embed_type === 'drive'"/>
                        </f-form-row>

                        <f-form-row-spacer/>

                        <f-form-row label="Button">
                            <f-switch v-model="fields.popup_has_button"/>
                        </f-form-row>
                        <f-form-row label="Button value" v-if="fields.popup_has_button">
                            <f-text-input
                                :validation="['required']"
                                v-model="fields.popup_button_value"
                                name="button value"
                                max-width="300px"
                            />
                        </f-form-row>
                        <f-form-row label="Button URL" v-if="fields.popup_has_button">
                            <f-text-input
                                :validation="['required']"
                                v-model="fields.popup_button_url"
                                name="button URL"
                            />
                        </f-form-row>
                    </f-form-fieldset>

                    <f-form-row-spacer/>

                    <f-form-fieldset style="display: none;">
                        <template #legend>
                            Riviera notification
                            <f-help-label
                                text="Send out a Riviera notification. Clicking this notification, users will see the announcement popup"
                            />
                        </template>

                        <f-form-row label="Send out notification">
                            <f-switch v-model="fields.has_notification"/>
                        </f-form-row>

                        <f-form-row label="Notification title" v-if="fields.has_notification">
                            <f-text-input
                                :validation="['required']"
                                v-model="fields.notification_title"
                                name="title"
                            />
                        </f-form-row>
                    </f-form-fieldset>
                </div>

                <f-form-placeholder v-else-if="!dataLoaded && loading"/>
            </f-container>

            <skewed-section
                v-if="dataLoaded"
                foreground-color="#333"
                after-background="#222"
                padding="100px 0 150px"
                margin="100px 0 -50px"
            >
                <f-theme-provider variant="dark">
                    <f-container max-width="900px">
                        <bullet-point>Timeline preview</bullet-point>
                        <f-form-row-spacer/>
                        <f-theme-provider
                            variant="light"
                            style="border-radius: 5px; background: white; text-align: center; padding: 40px 10px;"
                        >
                            <f-scroll-pane mode="horizontal">
                                <f-flex inline cross-axis-align="flex-start">
                                    <timeline-day :label="['Yesterday']">
                                        <f-placeholder-container>
                                            <f-placeholder width="120px" height="160px"/>
                                            <f-placeholder width="180px" height="180px"/>
                                        </f-placeholder-container>
                                    </timeline-day>
                                    <timeline-day
                                        :label="(fields.is_pinned ? ['pinned'] : (fields.date ? getVisualDate(fields.date) : ['{date}']))"
                                        :pinned="fields.is_pinned"
                                    >
                                        <announcement-event
                                            :type="fields.type"
                                            :ratio="fields.timeline_ratio"
                                            :title="(fields.timeline_title ? fields.timeline_title : '')"
                                            :title-size="fields.timeline_title_size"
                                            :subtitle="fields.timeline_subtitle"
                                            :image="timelineImageSrc"
                                            :modal-title="(fields.popup_title ? fields.popup_title.split('/') : '{popup title}')"
                                            :modal-body="fields.popup_body"
                                            :modal-has-button="fields.popup_has_button"
                                            :modal-button-url="fields.popup_button_url"
                                            :modal-button-value="(fields.popup_button_value ? fields.popup_button_value : '{button value}')"
                                            :modal-has-embed="fields.popup_has_embed"
                                            :modal-embed-type="fields.popup_embed_type"
                                            :modal-embed-id="fields.popup_embed_id"
                                        />
                                    </timeline-day>
                                </f-flex>
                            </f-scroll-pane>
                        </f-theme-provider>
                    </f-container>
                </f-theme-provider>
            </skewed-section>

            <entity-action-button
                v-if="dataLoaded"
                :permission="Permissions.ADMIN_ANNOUNCEMENTS"
                type="submit"
                icon="floppy-disk"
                :tooltip="(mode === 'edit' ? 'Update' : 'Create')"
            />
        </f-form>
    </page>
</template>

<script>
/* eslint-disable no-unused-vars */
import Page from '../../Page';
import OfficeOptionsMixin from '../../../../mixins/OfficeOptionsMixin';
import FForm from '../../../../f-components/form-controls/forms/FForm';
import FFormRow from '../../../../f-components/form-controls/forms/FFormRow';
import FTextInput from '../../../../f-components/form-controls/text/FTextInput';
import FFormRowSpacer from '../../../../f-components/form-controls/forms/FFormRowSpacer';
import FEditor from '../../../../f-components/form-controls/editor/FEditor';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import {EditorToolbar} from "../../../../f-components/form-controls/editor/config";
import EntityCreateEditMixin from '../../../../mixins/entities/EntityCreateEditMixin';
import APIEndpoints from '../../../../config/APIEndpoints';
import FContainer from '../../../../f-components/layout/container/FContainer';
import EntityActionButton from '../../../misc/entities/EntityActionButton';
import FDatePicker from '../../../../f-components/form-controls/dates/FDatePicker';
import FImageInput from '../../../../f-components/form-controls/images/FImageInput';
import FFormPlaceholder from '../../../../f-components/loaders/placeholder/FFormPlaceholder';
import FSwitch from '../../../../f-components/form-controls/switches/FSwitch';
import FDropdown from '../../../../f-components/form-controls/dropdowns/FDropdown';
import FFormFieldset from '../../../../f-components/form-controls/forms/FFormFieldSet';
import FThemeProvider from '../../../../f-components/layout/theme/FThemeProvider';
import BulletPoint from '../../../misc/BulletPoint';
import SkewedSection from '../../../misc/skewed-section/SkewedSection';
import TimelineDay from '../../../misc/timeline/components/TimelineDay';
import AnnouncementEvent from '../../../misc/timeline/components/announcements/AnnouncementEvent';
import {
    AnnouncementEventRatios,
    AnnouncementEventTitleSizes
} from '../../../misc/timeline/components/announcements/config';
import FFlex from '../../../../f-components/layout/flex/FFlex';
import FPlaceholder from '../../../../f-components/loaders/placeholder/FPlaceholder';
import FPlaceholderContainer from '../../../../f-components/loaders/placeholder/FPlaceholderContainer';
import FScrollPane from '../../../../f-components/other/scroll-pane/FScrollPane';
import FChipsInput from '../../../../f-components/form-controls/chips/FChipsInput';
import GoogleEmbedHelp from '../../../misc/embeds/GoogleEmbedHelp';
import FHelpLabel from '../../../../f-components/form-controls/forms/FHelpLabel';
import {API_DOMAIN} from '../../../../config/app';
import moment from 'moment';

export default {
    name: 'admin-announcement-edit-create-page',

    components: {
        FHelpLabel,
        GoogleEmbedHelp,
        FChipsInput,
        FScrollPane,
        FPlaceholderContainer,
        FPlaceholder,
        FFlex,
        AnnouncementEvent,
        TimelineDay,
        SkewedSection,
        BulletPoint,
        FThemeProvider,
        FFormFieldset,
        FDropdown,
        FSwitch,
        FFormPlaceholder,
        FImageInput,
        FDatePicker,
        EntityActionButton,
        FContainer,
        FBreadcrumb,
        FBreadcrumbs,
        FEditor,
        FFormRowSpacer,
        FTextInput,
        FFormRow,
        FForm,
        Page,
    },

    mixins: [
        OfficeOptionsMixin,
        EntityCreateEditMixin,
    ],

    data() {
        return {
            mode: (this.$route.name === 'admin.announcements.edit' ? 'edit' : 'create'),
            entityName: 'announcement',
            announcementEventRatios: Object.entries(AnnouncementEventRatios).map(([_, value]) => ({
                key: value,
                value: value
            })),
            announcementEventTitleSizes: Object.entries(AnnouncementEventTitleSizes).map(([_, value]) => ({
                key: value,
                value: value
            })),
            timelineImageSrc: undefined,
            tabId: undefined,
            defaultImages: {
                1: `${API_DOMAIN}/media/announcements/image-id/client/screenshot-2022-07-14-at-161139.png`,
                2: `${API_DOMAIN}/media/announcements/image-id/client/screenshot-2022-07-21-at-0927-3.jpg`,
            },
            // debug: true,

            fields: {
                date: null,
                type: 'Announcement',
                page: 'dashboard',

                timeline_title: null,
                timeline_subtitle: null,
                timeline_ratio: AnnouncementEventRatios.LANDSCAPE,
                timeline_title_size: AnnouncementEventTitleSizes.SMALL,

                popup_title: null,
                popup_body: null,
                popup_has_embed: false,
                popup_embed_type: null,
                popup_embed_id: null,
                popup_has_button: false,
                popup_button_value: null,
                popup_button_url: null,

                is_active: true,
                is_pinned: false,
                hide_for_freelancers: false,
                show_for_offices: [],
                has_notification: false,
                notification_title: null,

                default_image: false,
            },
        }
    },

    methods: {
        getEditorToolbar() {
            return [
                EditorToolbar.BOLD,
                EditorToolbar.ITALIC,
                EditorToolbar.HARD_BREAK,
                EditorToolbar.LINK,
                EditorToolbar.STRIKE,
                EditorToolbar.UNDERLINE,
                EditorToolbar.BULLET_LIST,
                EditorToolbar.HISTORY,
                EditorToolbar.IMAGE
            ];
        },

        getBaseUrl() {
            return APIEndpoints.ADMIN_ANNOUNCEMENTS;
        },

        getRedirectPath() {
            return '/admin/announcements';
        },

        configureFormData(formData) {
            const file = this.$refs.imageUploadField.getFile();
            if (file) formData.set('image', file);
            return formData;
        },

        postParseResponseData(data) {
            if (!data.data.timeline) {
                return;
            }

            this.fields.is_active = data.data.timeline.is_active;
            this.fields.is_pinned = data.data.timeline.is_pinned;
            this.fields.show_for_offices = data.data.timeline.show_for_offices ?? [];
            this.fields.hide_for_freelancers = data.data.timeline.hide_for_freelancers;

            this.fields.has_notification = data.data.timeline.has_notification;
            this.fields.notification_title = data.data.timeline.notification_title;
        },

        getVisualDate(date) {
            if (!date) return;
            date = moment(date);

            const today = moment(),
                daysDiff = today.diff(date, 'days');
            let label;

            if (daysDiff === 0) label = ['Today'];
            else if (daysDiff === 1) label = ['Yesterday'];
            else label = [date.format('ddd'), date.format('DD MMM') + (date.year() !== today.year() ? ` ${date.year()}` : '')];

            return label;
        },

        setToDefaultImage(i) {
            this.$refs.imageUploadField.reset();
            if (!this.fetchedData || !this.fetchedData.data) {
                this.fetchedData = {data: {}};
            }
            this.timelineImageSrc = this.fetchedData.data.timeline_image_url = this.defaultImages[i];
            this.fields.default_image = i;
        },

        onImageReset() {
            this.fields.default_image = null;
            if (this.fetchedData && this.fetchedData.data) {
                this.fetchedData.data.timeline_image_url = undefined;
            }
        },

        onImageSourceChange(src) {
            if (Object.values(this.defaultImages).includes(src) && src === this.defaultImages[this.fields.default_image]) {
                // console.log(this.fields.default_image);
            } else {
                this.fields.default_image = null;
            }

            this.timelineImageSrc = src;
        },
    },
}
</script>

<style lang="scss">
.admin-announcement-edit-create-page {
    .timeline-day {
        display: inline-block;
    }

    .google-embed-help {
        padding: 0 0 0 10px;
    }

    &__default-images {
        font-size: 11px;

        .f-flex {
            gap: 5px;
            margin-top: 5px;

            > div {
                width: 34px;
                height: 34px;
                border-radius: 3px;
                background-size: cover;
                transition: opacity .1s;
                cursor: pointer;

                &:hover {
                    opacity: .85;
                }
            }
        }
    }

    .f-placeholder-container {
        display: inline-flex;
        gap: 10px;

        .f-placeholder {
            border-radius: 5px;
        }
    }
}
</style>
