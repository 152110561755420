<template>
    <f-modal
        class-name="email-signature-modal"
        close-button
        width="600px"
        @close="$emit('close')"
        title="Email signature"
    >
        <f-header-body-footer>
            <template #header>
                <f-inline-message id="signatureHelp" :closable="false">
                    Check the FAQ for more info about signatures, and how to set them in your third party mail client.
                </f-inline-message>
            </template>
            <email-signature/>
        </f-header-body-footer>
    </f-modal>
</template>

<script>
import FModal from '../../../../f-components/other/modal/FModal';
import FInlineMessage from '../../../../f-components/notice/inline-message/FInlineMessage';
import FHeaderBodyFooter from '../../../../f-components/layout/header-body-footer/FHeaderBodyFooter';
import EmailSignature from '../../EmailSignature';

export default {
    name: 'email-signature-modal',

    props: {
        active: Boolean,
    },

    components: {
        EmailSignature,
        FHeaderBodyFooter,
        FInlineMessage,
        FModal,
    },
}
</script>

<style lang="scss">
.email-signature-modal {
    .f-inline-message {
        margin-bottom: 20px;
    }
}
</style>
