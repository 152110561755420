import EventBus from '../services/EventBus';
import {GlobalEvents} from './Events';
import {Permissions} from './Permissions';

const tutorialDefinition = [
    {
        id: 'welcome',
        title: 'Tutorial',
        route: '/',
        body: `
            <p>Welcome to Riviera, our internal communication platform. Riviera helps everyone at code d'azur to stay connected, even while working remotely.</p>
            <p>You can use Riviera to connect with colleagues, find internal documents, revisit presentations, book meeting rooms, and much more.</p>
            <p>This short tutorial will show you how to get the most from Riviera.</p>
            <p>The browser on your work laptop will automatically launch Riviera on startup, but you can also visit <a href="https://riviera.codedazur.io" target="_blank">riviera.codedazur.io</a> or save the web app to your phone homescreen.</p>
        `,
    },

    // Dashboard

    {
        id: 'dashboardTimeline',
        title: 'Dashboard',
        route: '/',
        body: `
            <p>When you access Riviera, you land on your personal dashboard.</p>
        `,
        clipElementID: 'dashboardTimeline',
        clipDescription: `Your dashboard timeline contains important company announcement and coverage of code d'azur events.`,
    },
    {
        id: 'dashboardWidgets',
        title: 'Dashboard',
        route: '/',
        clipElementID: 'dashboardWidgets',
        clipHeight: '50vh',
        clipDescription: `
            Your dashboard contains a range of customizable widgets: you can view and request leave days, see which meeting rooms are currently available, and view new colleagues who recently joined.
        `,
    },

    // Profile settings

    {
        id: 'profileSettings',
        clipElementID: 'profileSettingsMenu',
        clipDescription: 'Click on your avatar to change your profile settings.',
        title: 'Profile settings',
        route: '/',
    },
    {
        id: 'profileSettings2',
        clipElementID: 'profileSettingsPane',
        clipDescription: 'Use the settings panel to update your preferences and personalise your dashboard widgets.',
        clipMargin: -40,
        clipHeight: '50vh',
        title: 'Profile settings',
        route: '/',
        onActive: () => EventBus.$emit(GlobalEvents.PROFILE_OPEN),
    },

    // Notifications

    {
        id: 'notifications',
        clipElementID: 'notificationsMenu',
        clipDescription: 'Click here to view your notifications.',
        title: 'Notifications',
        route: '/',
    },
    {
        id: 'notifications2',
        clipElementID: 'notificationsPane',
        clipDescription: 'Notifications include colleague birthdays, upcoming events, leave approvals, and more.',
        clipMargin: -40,
        clipHeight: '50vh',
        title: 'Notifications',
        route: '/',
        onActive: () => EventBus.$emit(GlobalEvents.NOTIFICATIONS_OPEN),
    },
    {
        id: 'notifications3',
        clipElementID: 'pushNotificationsMenu',
        clipDescription: 'Click here to enable push notifications.',
        title: 'Notifications',
        route: '/',
        body: `
            <p>Riviera comes with push notifications at your command. Kind of like a genie.</p>
            <p>
                You can enable and disable your notification subscription at any time.
                You will have to enable notifications separately per device and browser.
                E.g. if you enable them on desktop Chrome, it does not mean that you will receive
                them on mobile Chrome, nor desktop Safari.
            </p>
            <p>When prompted by the browser to enable notifications, click on allow.</p>
        `
    },

    // Admin

    {
        id: 'admin',
        clipElementID: 'adminMenu',
        clipDescription: 'Click here to access admin-specific functionality.',
        title: 'Admin',
        route: '/',
        body: `
            Because you have admin based permissions assigned to you, you see an extra menu item.
            Depending on your permissions, you can find access to for example user management, project reporting based modules
            and modules for uploading FIKAs, announcements and picture galleries to the timeline.
        `,
        needsPermissionOr: [
            Permissions.ADMIN_USERS_VIEW,
            Permissions.ADMIN_CLIENTS_VIEW,
            Permissions.ADMIN_PRODUCTS_VIEW,
            Permissions.ADMIN_GALLERIES,
            Permissions.ADMIN_ANNOUNCEMENTS,
            Permissions.ADMIN_DISCIPLINES_VIEW,
            Permissions.ADMIN_MISC_FLUSH_CACHE,
            Permissions.ADMIN_MISC_EXTERNAL_CONNECTIONS,
            Permissions.ADMIN_FIKA,
            Permissions.ADMIN_MISC_VIEW_LOGS
        ]
    },

    // People page

    {
        id: 'people',
        clipElementID: 'peopleMenu',
        clipDescription: 'Click here to see everyone working at code d\'azur.',
        clipMargin: -10,
        title: 'People',
        route: '/people',
    },
    {
        id: 'people2',
        clipElementID: 'peoplePage',
        clipHeight: '50vh',
        clipDescription: `
            Want to reach out to someone? Here's the contact information for all colleagues and freelancers, across all code d'azur offices.
        `,
        title: 'People',
        route: '/people',
    },


    // Meeting rooms page

    {
        id: 'meetingRooms',
        clipElementID: 'meetingRoomsMenu',
        clipDescription: 'Click here for the meeting rooms page.',
        clipMargin: -10,
        title: 'Meeting rooms',
        route: '/meeting-rooms/{office}',
        allowedOffice: ['amsterdam'],
    },
    {
        id: 'meetingRooms2',
        clipElementID: 'meetingRoomsPage',
        clipHeight: '50vh',
        clipDescription: `
           On this page you can check the availability of all meeting rooms and phone booths.
        `,
        title: 'Meeting rooms',
        route: '/meeting-rooms/{office}',
        allowedOffice: ['amsterdam'],
    },

    // FAQ and guides page

    {
        id: 'faq',
        clipElementID: 'faqMenu',
        clipDescription: 'Click here for the FAQ & Guides.',
        clipMargin: -10,
        title: 'HR',
        route: '/faq',
    },
    {
        id: 'faq2',
        clipElementID: 'faqPage',
        clipHeight: '50vh',
        clipDescription: `
           On the FAQ & Guides page you can find many of your questions.
           Just started? Check this page to get up-to-speed with everything.
           If you're still unsure about something, reach out to a colleague or member of the People team.
        `,
        title: 'FAQ & guides',
        route: '/faq',
    },

    // HR page

    {
        id: 'hr',
        clipElementID: 'hrMenu',
        clipDescription: 'Click here for the HR page.',
        clipMargin: -10,
        title: 'HR',
        route: '/hr',
    },
    {
        id: 'hr2',
        clipElementID: 'hrPage',
        clipHeight: '50vh',
        clipDescription: `
            The HR page has HR-related FAQs, your Conditions of Employment, our onboarding deck, and our relocation guide.
        `,
        title: 'HR',
        route: '/hr',
    },

    // Projects

    {
        id: 'projects',
        clipElementID: 'projectsMenu',
        clipDescription: 'Click here to view the projects page.',
        clipMargin: -10,
        title: 'Projects',
        route: '/projects',
        needsPermissionOr: [Permissions.PROJECTS_VIEW],
    },
    {
        id: 'projects2',
        clipElementID: 'projectsPage',
        clipHeight: '50vh',
        clipDescription: `
          Explore all our current projects, and see who is responsible for their operation.
        `,
        title: 'Projects',
        route: '/projects',
        needsPermissionOr: [Permissions.PROJECTS_VIEW],
    },

    // Logout

    {
        id: 'logout',
        clipElementID: 'logoutMenu',
        clipDescription: 'Click here to log out of Riviera.',
        clipMargin: -10,
        title: 'Logout',
        route: '/',
    },

    // Thank you!

    {
        id: 'thankYou',
        title: 'Thank you!',
        body: `<p>
            Thank you for taking the time to get know our in-house platform.
            If you have any questions, you can ask them on Slack in the channel <a href="slack://channel?team=T02EQNMKZ&id=C043VGWFKC3" target="_blank">#riviera</a>.
        </p>`,
        route: '/',
    },
];

export default tutorialDefinition;
