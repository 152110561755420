<template>
    <f-table-cell
        :class-name="`f-table-title-cell ${hasBody? '--has-body' : ''} ${(className ? className : '')}`"
        :width="width"
        :align="align"
        :hide-mobile="hideMobile"
        :hide-tablet="hideTablet"
        :no-padding-right="noPaddingRight"
        :no-padding-bottom-top="noPaddingBottomTop"
        :no-wrap="noWrap"
    >
        <h3 class="f-table-title-cell__title" :style="{width: width}">
            <slot/>
        </h3>
        <div class="f-table-title-cell__subtitle">
            <slot name="sub"/>
        </div>
        <div class="f-table-title-cell__body" v-if="hasBody">
            <slot name="body"/>
        </div>
    </f-table-cell>
</template>

<script>
import FTableCell from './FTableCell';
import FTableCellMixin from './FTableCellMixin';

export default {
    name: 'f-table-title-cell',

    props: {
        hasBody: Boolean,
        className: String,
    },

    components: {
        FTableCell,
    },

    mixins: [
        FTableCellMixin,
    ],
}
</script>

<style lang="scss">
.f-table-title-cell {
    .f-table__container &.f-table-cell {
        padding: 15px 8px;
    }

    &__title {
        font-size: 1.15em;
        font-weight: 600;
        line-height: 115%;
        margin: 0;
        padding: 0;

        @include media(mobile-down) {
            font-size: 1.1em;
        }
    }

    &__subtitle {
        line-height: 110%;

        > div {
            padding-top: 2px;
        }
    }

    &__body {
        padding-top: 3px;
        line-height: 130%;
        color: rgba(white, .5);
    }

    &.--has-body {
        .f-table-title-cell__subtitle {
            font-size: 1.05em;
            color: rgba(white, .75);
        }

        .f-table-title-cell__body {
            color: rgba(white, .4);
        }
    }
}
</style>
