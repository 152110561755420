<template>
    <span
        :class="[
            'dashed-explanation',
            ...classNames,
        ]"
        v-tooltip="{
            text: tooltip,
            align: 'top',
            variant: 'dark',
            ...tooltipOptions,
        }"
    >
        <slot/>
    </span>
</template>

<script>
import ClassNameMixin from '../../mixins/ClassNameMixin';

export default {
    name: 'dashed-explanation',

    mixins: [
        ClassNameMixin,
    ],

    props: {
        tooltip: String,
        tooltipOptions: {
            type: Object,
            default: () => ({}),
        },
    },
}
</script>

<style lang="scss">
.dashed-explanation {
    border-bottom: 1px dashed transparent;

    &:hover {
        @include theme(light) {
            border-bottom-color: $dark;
        }

        @include theme(dark) {
            border-bottom-color: $light;
        }
    }
}
</style>
