<template>
    <div
        :class="[
            'f-tab',
            (active ? '--active' : null),
            (disabled ? '--disabled' : null),
            (subtitle ? '--subtitle' : null),
            ...classNames,
        ]"
        @click="onClick"
    >
        <slot/>
        <div
            v-if="title"
            class="f-tab__title"
        >
            <span v-html="title"/>
            <f-badge v-if="badge" inline>
                {{ badge }}
            </f-badge>
            <div
                v-if="subtitle"
                class="f-tab__subtitle"
                v-html="subtitle"
            />
        </div>
    </div>
</template>

<script>
import ClassNameMixin from '../../../mixins/ClassNameMixin';
import FBadge from '../../data/badge/FBadge';

export default {
    name: 'f-tab',

    components: {
        FBadge,
    },

    mixins: [
        ClassNameMixin,
    ],

    props: {
        id: {
            type: String,
            required: true,
        },
        title: String,
        subtitle: String,
        disabled: Boolean,
        badge: String,
    },

    data() {
        return {
            active: false,
        }
    },

    watch: {
        active(value) {
            if (value) {
                this.$emit('active', this.id);
            }
        },
    },

    methods: {
        activate() {
            this.active = true;
        },

        onClick() {
            this.activate();
            this.$emit('click');
        },
    },
}
</script>

<style lang="scss">
.f-tabs__container.--type-tabs .f-tabs .f-tab {
    padding: 15px 30px;
    border-bottom: 3px solid transparent;
    margin-bottom: -1px;
    transition: background-color .1s, border-color .1s;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
    line-height: 100%;
    position: relative;

    @include media(mobile-down) {
        padding: 10px 15px;
    }

    &.--disabled {
        pointer-events: none;
        opacity: .25;
    }

    &.--active {
        border-color: $primary;
        cursor: default;
        z-index: 2;
        font-weight: 600;
    }

    &:hover:not(.--active) {
        border-color: $info;
    }

    .f-icon {
        font-size: 110%;
        margin-right: 7px;
    }

    &.--subtitle {
        .f-icon {
            font-size: 200%;
            margin-right: 10px;
            margin-top: -1px;
        }
    }

    .f-tab__title {
        white-space: nowrap;
    }

    .f-tab__subtitle {
        line-height: 100%;
        padding-top: 3px;
        font-size: 80%;
        opacity: .65;

        @include media(mobile-down) {
            display: none;
        }
    }
}

.f-tabs__container.--type-blocks .f-tabs .f-tab {
    padding: 15px 30px;
    margin: 5px 0;
    transition: background-color .1s, border-color .1s;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    line-height: 100%;
    border-radius: 999px;
    text-align: center;

    @include media(mobile-down) {
        padding: 10px 15px;
    }

    &.--disabled {
        pointer-events: none;
        opacity: .25;
    }

    &.--active {
        background: $primary;
        cursor: default;
        color: $primaryFront;
    }

    &:hover:not(.--active) {
        border-color: $info;
    }

    .f-icon {
        font-size: 110%;
        margin-right: 7px;
    }

    &.--subtitle {
        .f-icon {
            font-size: 200%;
            margin-right: 7px;
            margin-top: -3px;
        }
    }

    .f-tab__subtitle {
        line-height: 110%;
        padding-top: 3px;
        font-size: 80%;
        opacity: .65;

        @include media(mobile-down) {
            display: none;
        }
    }
}
</style>
