<template>
    <div
        :class="[
            'f-panel',
            ...(Array.isArray(className) ? className : [className]),
            (hasHeading || heading ? '--heading' : null),
            (collapsible && !open ? '--collapsed' : null),
            (loading ? '--loading' : null),
            (title ? '--has-title' : null),
            `--theme-${variant}`,
        ]"
    >
        <slot name="head"/>

        <f-text
            v-if="title"
            type="subtitle"
            class-name="f-panel__title"
        >
            <span v-html="title"/>
            <f-help-label
                v-if="titleHelp"
                :text="titleHelp"
                size="11px"
            />
        </f-text>

        <f-panel-header
            v-if="heading || collapsible"
            :heading="heading"
            :heading-badge="headingBadge"
            :class-name="(collapsible ? '--collapsible' : null)"
            @titleClick="onCollapseToggle"
        >
            <template #default>
                <slot name="post-title"/>

                <slot name="post"/>
            </template>

            <template #title>
                <slot name="title"/>

                <f-icon-button
                    v-if="collapsible"
                    style="margin-left: 5px;"
                    :icon="(open ? 'chevron-up' : 'chevron-down')"
                    size="xxsmall"
                    outlined
                />
            </template>
        </f-panel-header>

        <slot
            v-if="(collapsible && open) || !collapsible"
            name="post-head"
        />

        <div
            v-if="open"
            :class="['f-panel__content', contentClassName]"
        >
            <slot/>
        </div>

        <slot name="post-content"/>
    </div>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import FPanelHeader from './FPanelHeader';
import FIconButton from '../../form-controls/buttons/FIconButton';
import FText from '../../layout/text/FText';
import FHelpLabel from '../../form-controls/forms/FHelpLabel';

export default {
    name: 'f-panel',

    components: {
        FHelpLabel,
        FText,
        FIconButton,
        FPanelHeader,
    },

    props: {
        loading: Boolean,
        className: String | Array,
        contentClassName: String,
        hasHeading: Boolean,
        heading: String | Array,
        title: String | Array,
        titleHelp: String,
        collapsible: Boolean,
        headingBadge: String | Number,
        variant: {
            type: String,
            default: 'light', // dark | light
        },
    },

    data() {
        return {
            open: !this.collapsible,
        }
    },

    methods: {
        onCollapseToggle() {
            if (!this.collapsible) {
                return;
            }
            this.open = !this.open;
        },
    }
}
</script>

<style lang="scss">
.f-panel {
    border-radius: 10px;
    position: relative;
    margin: auto;
    max-width: calc(100vw - 20px);
    text-align: left;

    &.--theme-light {
        background: $light;
        color: $lightFront;
    }

    &.--theme-dark {
        background: $dark;
        color: $darkFront;
    }

    .f-panel__title {
        margin-top: -25px;
        left: 0;
        position: absolute;
    }

    &.--has-title {
        margin-top: 25px;
    }

    &__content {
        padding: 25px;
        position: relative;
        width: 100%;
        height: 100%;
        text-overflow: ellipsis;

        @include media(mobile-down) {
            padding: 15px;
        }

        > .f-scroll-pane.--has-scroll {
            margin-right: -10px;

            @include media(mobile-down) {
                margin-right: 0;
            }
        }
    }

    hr {
        height: 0;
        border: 0;
        border-top: 1px solid rgba(white, .5);
        margin: 20px 0;
        background: none;
    }

    .f-panel-header.--collapsible .f-icon-button {
        margin: -2px 0 0 2px;
    }
}
</style>

