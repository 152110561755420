<template>
    <f-abstract-input-field
        v-bind="boundProps"
        :class-name="[
            'f-dropdown',
            ...classNames,
            `--mode-${mode}`,
        ]"
        :focussed="hasFocus || popupOpen"
        :has-content="!!value"
        :input-error="inputError"
        :show-error="(searchable ? true : showError)"
        error-align="right"
        hide-reset-when-empty
        @reset="reset"
        @click="focus"
    >
        <template #pre>
            <f-icon
                v-if="preIcon"
                :icon="preIcon"
            />
        </template>

        <template v-slot:default>
            <button
                class="f-dropdown__focus"
                ref="input"
                type="button"
                :disabled="(disabled || readonly)"
                :tabindex="tabIndex"
                @focus="onFocus"
                @blur="onBlur"
            />

            <span
                v-if="!value || (Array.isArray(value) && value.length === 0)"
                class="f-dropdown__placeholder --placeholder"
                v-html="placeholder || '&nbsp;'"
            />

            <slot
                name="selectedOption"
                v-bind="{
                    selectedOption: selectedOption,
                    mode: mode
                }"
            >
                <div
                    v-if="mode === 'single' && value"
                    class="f-dropdown__selected-option"
                    v-html="(selectedOption ? selectedOption.value : '&nbsp;')"
                />
                <div
                    v-if="mode === 'multi' && (value ? value : []).length > 0"
                    class="f-dropdown__selected-option"
                    v-html="selectedOption.map(option => option.value).join(', ')"
                />
            </slot>
        </template>

        <template #post>
            <f-icon-button
                v-if="!readonly && !disabled"
                icon="chevron-down"
                outlined
                :variant="(inputError ? 'danger' : 'themed')"
                size="xsmall"
                class-name="f-dropdown__action"
                tabindex="-1"
            />
        </template>

        <template #after>
            <f-popup
                v-if="(popupOpen && !disabled && !readonly)"
                :target="$el"
                :width="popupWidth"
                :class-name="`f-dropdown-popup --mode-${mode}`"
                :variant="popupVariant"
                max-height="auto"
                @close="closePopup"
                align="bottom"
                ref="popup"
            >
                <div
                    v-if="searchable"
                    class="f-dropdown-popup__search"
                >
                    <input
                        v-model="searchQuery"
                        type="text"
                        placeholder="Search…"
                        ref="search"
                        @keyup.enter="onEnterKey"
                        @keydown.tab="onTabKey"
                        tabindex="-1"
                    />
                    <f-icon icon="magnifier"/>
                </div>

                <div
                    v-if="searchQuery && filteredOptions.length === 0"
                    class="f-dropdown-popup__no-results"
                >
                    Nothing found, try again
                </div>

                <!-- TODO: change to f-popup-list -->
                <div
                    :class="[
                        'f-dropdown-popup__scroll',
                        `--scrollable-${popupVariant === 'dark' ? 'light' : 'dark'}-vertical`
                    ]"
                >
                    <ul class="f-dropdown-popup__list">
                        <slot
                            name="options"
                            v-bind="{
                                onSelect: onSelect,
                                selectedIndex: selectedIndex,
                                options: filteredOptions,
                                selectedOption: selectedOption,
                                mode: mode,
                                value: value
                            }"
                        >
                            <li
                                v-for="(option, index) in filteredOptions"
                                @click.prevent="onSelect(option, index)"
                                :class="[
                                    (option.disabled ? '--disabled' : null),
                                    (selectedIndex === index ? '--selected' : null),
                                    (mode === 'single' && selectedOption && value === option.key ? '--chosen' : null),
                                    (mode === 'multi' && selectedOption && (value ? value : []).includes(option.key) ? '--chosen' : null),
                                ]"
                                :key="option.key"
                                v-html="option.value"
                            />
                        </slot>
                    </ul>
                </div>
            </f-popup>
        </template>
    </f-abstract-input-field>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor, vue/no-side-effects-in-computed-properties */
import AbstractInputFieldPropsMixin from '../base/AbstractInputFieldPropsMixin';
import AbstractInputEventsMixin from '../base/AbstractInputEventsMixin';
import AbstractInputMixin from '../base/AbstractInputMixin';
import AbstractInputValidationMixin from '../base/AbstractInputValidationMixin';
import FAbstractInputField from '../base/FAbstractInputField';
import FIcon from '../../layout/icons/FIcon';
import FIconButton from '../buttons/FIconButton';
import FPopup from '../../other/popup/FPopup';
import DropdownPropsMixin from './DropdownPropsMixin';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';

export default {
    name: 'f-dropdown',

    components: {
        FPopup,
        FIconButton,
        FIcon,
        FAbstractInputField,
    },

    mixins: [
        AbstractInputFieldPropsMixin,
        AbstractInputValidationMixin,
        AbstractInputEventsMixin,
        AbstractInputMixin,
        DropdownPropsMixin,
    ],

    computed: {
        filteredOptions() {
            this.selectedIndex = undefined;

            this.$nextTick(() => this.position());

            if (this.searchQuery && this.optionFilter) {
                return this.options.filter(data => this.optionFilter(this.searchQuery, data));
            }

            return this.options;
        },
        selectedOption() {
            return this.getSelectedOption();
        },
    },

    data() {
        return {
            popupOpen: false,
            skipPopupOpen: false,
            selectedIndex: null,
            searchQuery: '',
        }
    },

    watch: {
        popupOpen() {
            if (this.popupOpen) {
                this.$emit('popup-open');
            } else {
                this.$emit('popup-close');
            }
        },
        searchQuery() {
            this.$emit('search', this.searchQuery);
        },
    },

    created() {
        this.$on('input', this.onDropdownInput);
        this.$on('focus', this.onDropdownFocus);
        EventBus.$on('input.focus', this.onInputFocus);
        EventBus.$on(GlobalEvents.OVERFLOW_SCROLL, this.onOverflowScroll);
    },

    beforeDestroy() {
        this.$off('input', this.onDropdownInput);
        this.$off('focus', this.onDropdownFocus);
        EventBus.$off('input.focus', this.onInputFocus);
        EventBus.$off(GlobalEvents.OVERFLOW_SCROLL, this.onOverflowScroll);
    },

    methods: {
        onDropdownInput(value) {
            this.$nextTick(() => this.$emit('change', value, this.getSelectedOption()));
        },

        onDropdownFocus() {
            if (!this.skipPopupOpen) {
                this.openPopup();
            }
        },

        onInputFocus(uid) {
            if (this.uid !== uid && this.popupOpen) {
                this.popupOpen = false;
            }
        },

        onOverflowScroll() {
            this.popupOpen = false;
        },

        getValue() {
            return this.value;
        },

        getSelectedOption(value) {
            if (!value) {
                value = this.value;
            }

            if (this.mode === 'single' && value) {
                for (let i in this.options) {
                    if (Object.prototype.hasOwnProperty.call(this.options, i) && this.options[i].key === this.value) {
                        return this.options[i];
                    }
                }
            } else if (this.mode === 'multi') {
                if (!value) {
                    value = [];
                }
                const options = [];

                for (let i in this.options) {
                    if (value.includes(this.options[i].key)) {
                        options.push(this.options[i]);
                    }
                }

                return options;
            }

            return null;
        },

        reset(e) {
            e.preventDefault();
            e.stopPropagation();

            if (this.resetable) {
                this.$emit('input', null);
            }
        },

        position() {
            if (this.$refs.popup) {
                this.$refs.popup.position();
            }
        },

        openPopup() {
            this.popupOpen = true;

            if (this.searchable) {
                this.$nextTick(() => {
                    if (this.$refs.search) {
                        this.$refs.search.focus();
                    }
                });
            }

            document.addEventListener('keydown', this.onKeyDownEvent);
            document.addEventListener('keyup', this.onKeyUpEvent);
        },

        closePopup(refocus = false) {
            if (!this.popupOpen) {
                return;
            }

            this.popupOpen = false;
            this.searchQuery = '';
            this.selectedIndex = null;

            this.onBlur();

            if (refocus) {
                this.skipPopupOpen = true;
                this.focus();
                this.$nextTick(() => this.skipPopupOpen = false);
            }

            document.removeEventListener('keydown', this.onKeyDownEvent);
            document.removeEventListener('keyup', this.onKeyUpEvent);
        },

        onSelect(option) {
            if (this.mode === 'single') {
                this.$emit('input', option.key);
            } else if (this.mode === 'multi') {
                let value = (this.value ? [...this.value] : []);

                if (!(this.value ? this.value : []).includes(option.key)) { // Add
                    value.push(option.key);
                } else { // Remove
                    value = value.filter((key) => key !== option.key);
                }

                this.$emit('input', (value.length > 0 ? value : null));
            }

            if (this.mode === 'single') {
                this.closePopup(true);
            }
        },

        onKeyDownEvent(e) {
            if (e.keyCode === 40) {
                this.onDownKey(e);
            } else if (e.keyCode === 38) {
                this.onUpKey(e);
            }
        },

        onKeyUpEvent(e) {
            if (e.keyCode === 13) {
                this.onEnterKey(e);
            }
        },

        onEnterKey(e) {
            if (!this.popupOpen) {
                return;
            }

            e.preventDefault();
            e.stopPropagation();

            if (this.selectedIndex !== null && this.filteredOptions[this.selectedIndex]) {
                this.onSelect(this.filteredOptions[this.selectedIndex], this.selectedIndex);
            }
        },

        onTabKey(e) {
            e.preventDefault();
            e.stopPropagation();

            this.closePopup(true);
        },

        onDownKey(e) {
            if (!this.popupOpen) {
                return;
            }

            e.preventDefault();
            e.stopPropagation();

            if (this.selectedIndex === undefined || this.selectedIndex === null) {
                this.selectedIndex = 0;
            } else {
                this.selectedIndex++;
            }

            if (this.selectedIndex >= this.filteredOptions.length) {
                this.selectedIndex = this.filteredOptions.length - 1;
            }

            this.$nextTick(() => {
                const selected = this.$refs.popup.$el.querySelector('.--selected');
                if (selected) {
                    selected.scrollIntoView();
                }
            });
        },

        onUpKey(e) {
            if (!this.popupOpen) {
                return;
            }

            e.preventDefault();
            e.stopPropagation();

            if (this.selectedIndex === undefined || this.selectedIndex === null) {
                this.selectedIndex = 0;
            } else {
                this.selectedIndex--;
            }

            if (this.selectedIndex === -1) {
                this.selectedIndex = 0;
            }

            this.$nextTick(() => {
                const selected = this.$refs.popup.$el.querySelector('.--selected');
                if (selected) {
                    selected.scrollIntoView();
                }
            });
        },
    },
}
</script>

<style lang="scss">
.f-dropdown {
    position: relative;

    &__selected-option {
        padding: 5px 10px;
        text-overflow: ellipsis;
        cursor: default;
        overflow: hidden;

        .f-chip {
            vertical-align: middle;
        }

        .f-icon {
            margin: 0 8px 0 5px;
            font-size: 110%;
            vertical-align: middle;
        }
    }

    &__focus {
        position: absolute;
        opacity: 0;
    }

    &__action {
        pointer-events: all;
    }

    &__placeholder {
        padding: 0 10px;
        width: 100%;
        white-space: nowrap;
    }
}

.f-dropdown-popup {
    padding: 0;

    &__list {
        width: 100%;

        > li {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 6px 4px;
            margin: 1px 0 0;
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            user-select: none;

            &.--disabled {
                pointer-events: none;
                opacity: .35;
            }

            .f-chip {
                vertical-align: middle;
            }

            .f-icon {
                margin-left: 1px;
                margin-right: 5px;
                font-size: 110%;
                vertical-align: bottom;
            }

            &:hover, &.--selected {
                background: $primary;

                &, * {
                    color: $primaryFront !important;
                }
            }
        }
    }

    &.--dark {
        .f-dropdown-popup__list > li.--chosen {
            background: $quaternaryGray;
        }
    }

    &.--light {
        .f-dropdown-popup__list > li.--chosen {
            background: $quinaryGray;

            &, * {
                color: $dark !important;
            }
        }
    }

    &.--mode-single {
        .f-dropdown-popup__list > li.--chosen {
            pointer-events: none;
        }
    }

    &__scroll {
        max-height: 180px;
        margin: 10px;
    }

    &__no-results {
        user-select: none;
        text-align: center;
        font-style: italic;
        opacity: .5;
        font-size: 90%;
        padding: 4px;
    }

    &__search {
        position: relative;
        margin: 0 0 10px;

        input {
            margin-top: 5px;
            padding: 10px 15px;
            border-bottom: 1px solid;
            width: 100%;

            @include theme(light) {
                border-bottom-color: rgba($dark, .1);

                &:focus {
                    border-bottom-color: rgba($dark, .25);
                }

                &::placeholder {
                    color: rgba($dark, .5);
                }
            }

            @include theme(dark) {
                border-bottom-color: rgba($light, .1);

                &:focus {
                    border-bottom-color: rgba($light, .25);
                }

                &::placeholder {
                    color: rgba($light, .5);
                }
            }
        }

        .f-icon {
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }
}
</style>
