<template>
    <div
        :class="[
            'f-progress',
            `--${loadSize}`,
            (progress >= 100 ? '--done' : null),
        ]"
        :style="{ maxWidth }"
    >
        <div
            class="f-progress__progress"
            :style="{ width: `${progress}%` }"
        />
    </div>
</template>

<script>
import {SiteLoaderSizes} from '../site-loader/config';

export default {
    name: 'f-progress',

    props: {
        progress: {
            type: Number,
            default: 0
        },
        loadSize: {
            type: String,
            default: SiteLoaderSizes.SMALL,
        },
        maxWidth: String,
    },
}
</script>

<style lang="scss">
.f-progress {
    display: inline-block;
    width: 100%;
    height: 5px;
    border-radius: 2px;
    background: $quaternaryGray;
    overflow: hidden;

    &__progress {
        width: 0;
        height: 100%;
        background: $info;
        transition: width 10s;
    }

    &.--big .f-progress__progress {
        transition: width 20s;
    }

    &.--done .f-progress__progress {
        transition: width .2s;
    }
}
</style>
