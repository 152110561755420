<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
    <widget
        v-if="enabled"
        enabled-setting="widgets.offices.enabled"
        class-name="office-weather-widget"
        title="Weather"
        :header="currentID.ucfirst()"
        extra-break-points
    >
        <f-carousel
            navigation-type="prev-next"
            @slideChange="currentID = $event"
            swipe
        >
            <f-carousel-slide
                v-for="office in $store.state.$officesCollection"
                v-if="(office.weather && office.weather.current)"
                :id="office.id"
                :key="office.id"
                :title="office.name"
            >
                <office-weather-slide :data="office"/>
            </f-carousel-slide>
        </f-carousel>
    </widget>
</template>

<script>
import APIDataStore from '../../../services/APIDataStore';
import OfficeWeatherSlide from './OfficeAndWeatherSlide';
import WidgetSizeMixin from '../WidgetSizeMixin';
import FCarousel from '../../../f-components/navigation/carousels/FCarousel';
import FCarouselSlide from '../../../f-components/navigation/carousels/FCarouselSlide';
import Widget from '../Widget';
import APIDataStoreResources from '../../../config/APIDataStoreResources';

export default {
    name: 'office-weather-widget',

    components: {
        Widget,
        FCarouselSlide,
        FCarousel,
        OfficeWeatherSlide,
    },

    mixins: [
        WidgetSizeMixin,
    ],

    created() {
        console.log('ee');
        APIDataStore.check(APIDataStoreResources.OFFICES_COLLECTION);
    },

    computed: {
        enabled() {
            if (this.$store.state.$officesCollection.length === 0) {
                return false;
            }

            for (let i in this.$store.state.$officesCollection) {
                if (
                    this.$store.state.$officesCollection[i].weather &&
                    this.$store.state.$officesCollection[i].weather.current
                ) {
                    return true;
                }
            }

            return false;
        },
    },

    data() {
        return {
            currentID: 'amsterdam',
        }
    },
}
</script>

<style lang="scss">
.office-weather-widget {
    .f-carousel {
        width: 100%;
        max-width: 540px;
        margin: auto;
        overflow: hidden;
    }

    &.--widget-xxxsmall,
    &.--widget-xxsmall,
    &.--widget-xsmall,
    &.--widget-small {
        .f-carousel {
            max-width: 280px;
            //background: red;
        }

        .office-weather-slide__current-details, .office-weather-slide__forecast {
            display: none;
        }
    }

    &.--widget-medium {
        .f-carousel {
            max-width: 340px;
            //background: yellow;
        }

        .office-weather-slide__current {
            flex: 0 0 200px;

            .f-weather-icon {
                font-size: 70px;
            }
        }

        .office-weather-slide__current-details {
            display: none;
        }
    }

    &.--widget-xmedium,
    &.--widget-xxmedium {
        .f-carousel {
            max-width: 400px;
            //background: green;
        }

        .office-weather-slide__current-details {
            display: none;
        }
    }

    .f-panel__content {
        overflow: hidden;
    }

    .office-weather-slide {
        padding-bottom: 20px;
    }
}
</style>
