<template>
    <div
        :class="[
            'f-flex',
            ...classNames,
            (inline ? '--inline' : null),
        ]"
        :style="{
            display: (inline ? 'inline-flex' : 'flex'),
            flexDirection: direction,
            flexWrap: wrap,
            justifyContent: mainAxisAlign,
            alignItems: crossAxisAlign,
            gap,
        }"
    >
        <slot/>
    </div>
</template>

<script>
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-flex',

    mixins: [
        ClassNameMixin,
    ],

    props: {
        inline: Boolean,
        direction: {
            type: String, // "row" | "row-reverse" | "column" | "column-reverse",
            default: "row",
        },
        wrap: {
            type: String, // "nowrap" | "wrap" | "wrap-reverse",
            default: "nowrap",
        },
        mainAxisAlign: {
            type: String, // "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly" | "start" | "end" | "left" | "right",
            default: "center",
        },
        crossAxisAlign: {
            type: String, // "stretch" | "flex-start" | "flex-end" | "center" | "baseline" | "first baseline" | "last baseline" | "start" | "end" | "self-start" | "self-end",
            default: "center",
        },
        gap: String,
    },
}
</script>

<style lang="scss">
.f-flex {
    width: 100%;

    &.--inline {
        width: auto;
    }
}
</style>
