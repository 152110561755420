<template>
    <f-portal reference="gallery" v-if="active">
        <transition name="fade-small" appear>
            <f-center
                :class-name="['gallery', className]"
                fixed
            >
                <div class="gallery__actions">
                    <f-icon-button
                        v-if="downloadUrl"
                        icon="download2"
                        outlined
                        size="large"
                        v-tooltip="'Download'"
                        tag="a"
                        :href="downloadUrl"
                        target="_blank"
                    />
                    <f-close
                        @click="close"
                        size="large"
                    />
                </div>
                <div class="gallery__content">
                    <f-center :class-name="['gallery__view']">
                        <f-swipe-gestures
                            class-name="gallery__content"
                            @swipe-left="onSwipeLeft"
                            @swipe-right="onSwipeRight"
                        >
                            <f-flex
                                gap="20px"
                                main-axis-align="space-between"
                                :style="{ height: '100%' }"
                                class-name="gallery__view__wrapper"
                            >
                                <div class="gallery__view__side-button">
                                    <f-icon-button
                                        icon="chevron-left"
                                        outlined
                                        size="xlarge"
                                        :disabled="!!!previousImage"
                                        @click="showImage(previousImage)"
                                    />
                                </div>
                                <div class="gallery__view__image" ref="imageView">
                                    <div class="gallery__view__image__container">
                                        <f-image-loader
                                            v-if="currentImage && boundingBox && imageVisible"
                                            ref="viewImage"
                                            :src="currentImage.image_url"
                                            :alt="title"
                                            :height="viewSize.height"
                                            :width="viewSize.width"
                                            @loading="onViewImageLoad"
                                            @complete="onViewImageLoadComplete"
                                            transition-size
                                            secure
                                            cover
                                        />
                                        <gallery-picture-overlay
                                            v-if="!loading && firstViewImageLoaded"
                                            @new-likes="setNewLikes"
                                            :disabled="viewImageLoading"
                                            :image="currentImage"
                                        />
                                    </div>
                                    <f-loader v-if="viewImageLoading || loading"/>
                                </div>
                                <div class="gallery__view__side-button">
                                    <f-icon-button
                                        icon="chevron-right"
                                        outlined
                                        size="xlarge"
                                        :disabled="!!!nextImage"
                                        @click="showImage(nextImage)"
                                    />
                                </div>
                            </f-flex>
                        </f-swipe-gestures>
                        <div class="gallery__view__bottom-buttons">
                            <div class="gallery__actions__mobile">
                                <f-close
                                    @click="close"
                                    size="large"
                                />
                                <f-icon-button
                                    v-if="downloadUrl"
                                    icon="download2"
                                    outlined
                                    size="large"
                                    v-tooltip="'Download'"
                                    tag="a"
                                    :href="downloadUrl"
                                    target="_blank"
                                />
                            </div>
                            <div class="gallery__actions__mobile">
                                <f-icon-button
                                    icon="chevron-left"
                                    outlined
                                    size="large"
                                    :disabled="!!!previousImage"
                                    @click="showImage(previousImage)"
                                />
                                <f-icon-button
                                    icon="chevron-right"
                                    outlined
                                    size="large"
                                    :disabled="!!!nextImage"
                                    @click="showImage(nextImage)"
                                />
                            </div>
                        </div>
                    </f-center>

                    <div class="gallery__browser">
                        <f-scroll-pane
                            mode="horizontal"
                            class-name="gallery__browser__scroll"
                            no-scroll-padding
                            hide-scroll-bar
                            drag
                        >
                            <div class="gallery__browser__content">
                                <gallery-thumbnail
                                    v-for="image in images"
                                    :key="image.id"
                                    :alt="title"
                                    :src="image.thumbnail_url"
                                    :height="80"
                                    :src-width="image.width"
                                    :src-height="image.height"
                                    :selected="currentImage && (currentImage.id === image.id)"
                                    :observer="observer"
                                    secure
                                    @click="showImage(image)"
                                />
                            </div>
                        </f-scroll-pane>
                    </div>
                </div>
            </f-center>
        </transition>
    </f-portal>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import FPortal from '../../../f-components/other/portal/FPortal';
import FCenter from '../../../f-components/layout/center/FCenter';
import FClose from '../../../f-components/navigation/close/FClose';
import FScrollPane from '../../../f-components/other/scroll-pane/FScrollPane';
import GalleryThumbnail from './components/GalleryThumbnail';
import FImageLoader from '../../../f-components/layout/image-loader/FImageLoader';
import FFlex from '../../../f-components/layout/flex/FFlex';
import FIconButton from '../../../f-components/form-controls/buttons/FIconButton';
import ObserverMixin from '../../../mixins/ObserverMixin';
import FSwipeGestures from '../../../f-components/other/swipe-gestures/FSwipeGestures';
import FLoader from '../../../f-components/loaders/loader/FLoader';
import LoadingMixin from '../../../mixins/LoadingMixin';
import APIEndpoints from '../../../config/APIEndpoints';
import GalleryPictureOverlay from './components/GalleryPictureOverlay';

export default {
    name: 'gallery',

    mixins: [
        ObserverMixin,
        LoadingMixin,
    ],

    components: {
        GalleryPictureOverlay,
        FLoader,
        FSwipeGestures,
        FIconButton,
        FFlex,
        FImageLoader,
        GalleryThumbnail,
        FScrollPane,
        FClose,
        FCenter,
        FPortal
    },

    props: {
        className: String,
    },

    computed: {
        nextImage() {
            if (!this.currentImage) return undefined;

            let nextImage, isNext = false;

            this.images.forEach((image) => {
                if (image.id === this.currentImage.id) {
                    isNext = true;
                } else if (isNext && !nextImage) {
                    nextImage = image;
                }
            });

            return nextImage;
        },
        previousImage() {
            if (!this.currentImage) return undefined;

            let previousLoopImage, previousImage;

            this.images.forEach((image) => {
                if (image.id === this.currentImage.id && !previousImage && previousLoopImage) {
                    previousImage = previousLoopImage;
                } else if (!previousImage) {
                    previousLoopImage = image;
                }
            });

            return previousImage;
        },
    },

    watch: {
        active(active) {
            if (active) {
                EventBus.$emit(GlobalEvents.MODAL_OPEN);

                EventBus.$on(GlobalEvents.KEYDOWN_ESC, this.close);
                EventBus.$on(GlobalEvents.WINDOW_RESIZE, this.onResize);
                EventBus.$on(GlobalEvents.KEYDOWN_RIGHT, this.next);
                EventBus.$on(GlobalEvents.KEYDOWN_LEFT, this.previous);

                this.$nextTick(() => {
                    this.boundingBox = this.$refs.imageView.getBoundingClientRect();
                    this.setSize();
                    this.showImage(this.images[0]);
                });
            } else {
                EventBus.$emit(GlobalEvents.MODAL_CLOSE);

                EventBus.$off(GlobalEvents.KEYDOWN_ESC, this.close);
                EventBus.$off(GlobalEvents.WINDOW_RESIZE, this.onResize);
                EventBus.$off(GlobalEvents.KEYDOWN_RIGHT, this.next);
                EventBus.$off(GlobalEvents.KEYDOWN_LEFT, this.previous);
            }
        }
    },

    data() {
        return {
            images: [],
            active: false,
            downloadUrl: undefined,
            currentImage: null,
            viewImageLoading: false,
            firstViewImageLoaded: false,
            boundingBox: undefined,
            imageVisible: true,
            viewSize: {
                width: undefined,
                height: undefined,
            },
            title: undefined,
        }
    },

    mounted() {
        EventBus.$on(GlobalEvents.GALLERY_OPEN, this.open);
        this.checkDeeplink();
    },

    beforeDestroy() {
        EventBus.$off(GlobalEvents.GALLERY_OPEN, this.open);
    },

    methods: {
        open(slug) {
            if (!slug) return;

            this.active = true;
            this.startLoading();

            window.axios
                .get(`${APIEndpoints.GALLERY}/${slug}`)
                .then(({data}) => {
                    this.downloadUrl = data.data.download_url;
                    this.title = data.data.title;
                    this.images = data.data.images.map((image) => {
                        image.image_url = APIEndpoints.secureAsset(image.image_url);
                        image.thumbnail_url = APIEndpoints.secureAsset(image.thumbnail_url);
                        return image;
                    });
                    window.location.hash = data.data.deeplink;

                    this.showImage(this.images[0]);
                    this.endLoading(true);
                })
                .catch(() => {
                    this.endLoading(true);
                    this.close();
                });
        },

        checkDeeplink() {
            const matches = location.hash.match(/#gallery\/(.*)/);

            if (!matches || matches.length !== 2) {
                return;
            }
            const slug = matches[1];

            if (slug) {
                EventBus.$emit(GlobalEvents.GALLERY_OPEN, slug);
            }
        },

        close() {
            this.$emit('close');
            this.active = false;
            this.images = [];
            this.title = undefined;
            window.location.hash = '';
            this.firstViewImageLoaded = false;
        },

        showImage(image) {
            this.currentImage = image;
        },

        onResize() {
            this.imageVisible = false;
            this.$nextTick(() => {
                this.boundingBox = this.$refs.imageView.getBoundingClientRect();
                this.setSize();
                this.imageVisible = true;
            });
        },

        next() {
            if (this.nextImage) {
                this.currentImage = this.nextImage;
            }
        },

        previous() {
            if (this.previousImage) {
                this.currentImage = this.previousImage;
            }
        },

        onSwipeLeft() {
            // console.log('onSwipeLeft');
            this.next();
        },

        onSwipeRight() {
            // console.log('onSwipeRight');
            this.previous();
        },

        onViewImageLoad() {
            this.viewImageLoading = true;
        },

        onViewImageLoadComplete() {
            this.setSize();
            this.viewImageLoading = false;
            this.firstViewImageLoaded = true;
        },

        setSize() {
            this.viewSize = this.getSize();
        },

        getSize() {
            if (!this.currentImage) {
                return {
                    width: undefined,
                    height: undefined
                };
            }

            const widthModifier = this.currentImage.width * (1 / this.currentImage.height),
                heightModifier = this.currentImage.height * (1 / this.currentImage.width),
                smallestWidth = this.boundingBox.width > this.currentImage.width ? this.currentImage.width : this.boundingBox.width,
                smallestHeight = this.boundingBox.height > this.currentImage.height ? this.currentImage.height : this.boundingBox.height;

            // Landscape
            if (this.currentImage.width > this.currentImage.height) {
                // console.log('Landscape', `${this.currentImage.width}x${this.currentImage.height}`, widthModifier, heightModifier);

                if (this.boundingBox.width > this.boundingBox.height) {
                    // console.log('--> window landscape', `${smallestWidth}, ${smallestHeight}`);

                    if (smallestHeight * widthModifier > this.boundingBox.width) {
                        // console.log('----> width overflow');
                    } else {
                        return {
                            width: `${smallestHeight * widthModifier}px`,
                            height: `${smallestHeight}px`,
                        };
                    }
                }

                // console.log('--> window portrait', smallestWidth, smallestWidth * widthModifier);

                return {
                    width: `${smallestWidth}px`,
                    height: `${smallestWidth * heightModifier}px`,
                };
            }

            // Portrait
            // console.log('Portrait', `${this.currentImage.width}x${this.currentImage.height}`, widthModifier);

            if (smallestHeight * widthModifier > this.boundingBox.width) {
                return {
                    width: `${smallestWidth}px`,
                    height: `${smallestWidth * heightModifier}px`,
                };
            }

            return {
                width: `${smallestHeight * widthModifier}px`,
                height: `${smallestHeight}px`,
            };
        },

        setNewLikes(imageId, likes) {
            for (let i in this.images) {
                if (this.images[i].id === imageId) {
                    this.images[i].likes = likes;
                    return;
                }
            }
        },
    },
}
</script>

<style lang="scss">
.gallery {
    background: rgba($dark, .75);
    z-index: $modalOrder;
    padding-top: 30px;
    backdrop-filter: blur(10px);

    * {
        user-select: none;
    }

    &__actions {
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 4;

        @include media(tablet-down) {
            display: none !important;
        }

        .f-close {
            position: relative;
        }

        .f-icon:before {
            font-weight: bold !important;
        }
    }

    &__actions, &__actions__mobile {
        display: inline-flex;
        gap: 10px;
    }

    &__content {
        display: flex;
        gap: 30px;
        flex-direction: column;
        width: 100%;
        height: 100%;

        @include media(tablet-down) {
            gap: 20px;
        }
    }

    &__browser {
        min-height: 140px;
        background: rgba(#000, .5);

        &__content {
            display: inline-flex;
            gap: 15px;
            padding: 30px;
        }
    }

    &__view {
        flex-grow: 1;
        flex-direction: column;
        gap: 30px;

        @include media(tablet-down) {
            gap: 20px;
        }

        &__image {
            &__container {
                position: relative;

                .f-image-loader {
                    pointer-events: none;
                }
            }
        }

        &__wrapper {
            padding: 0 30px;

            @include media(tablet-down) {
                padding: 0 15px;
            }
        }

        .f-image-loader {
            border-radius: 10px;
            overflow: hidden;
        }

        &__side-button {
            @include media(tablet-down) {
                display: none;
            }
        }

        &__bottom-buttons {
            display: flex;
            width: 100%;
            padding: 0 15px;
            justify-content: space-between;

            .f-close {
                position: relative !important;
            }

            @include media(tablet-up) {
                display: none;
            }
        }

        &__image {
            position: relative;
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            .f-image-loader {
                border-radius: 10px;
            }
        }
    }
}
</style>
