<template>
    <div class="f-push-messages">
        <F-push-message
            v-for="message in messages"
            :ref="message.uid"
            :uid="message.uid"
            :key="message.uid"
            :variant="message.variant"
            :body="message.body"
        />
    </div>
</template>

<script>
import FPushMessage from './FPushMessage';
import {generateUid} from '../../../utils/generateUid';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';

export default {
    name: 'f-push-messages',

    components: {
        FPushMessage,
    },

    data() {
        return {
            messages: [],
        }
    },

    created() {
        EventBus.$on(GlobalEvents.PUSH_MESSAGE, (body, variant) => this.add(body, variant));
    },

    methods: {
        add(body, variant) {
            // Check if message already exists and actives
            for (let i in this.messages) {
                if (
                    Object.prototype.hasOwnProperty.call(this.messages, i) &&
                    this.messages[i].body === body &&
                    this.$refs[this.messages[i].uid] &&
                    this.$refs[this.messages[i].uid][0] &&
                    this.$refs[this.messages[i].uid][0].isActive()
                ) {
                    return;
                }
            }

            // Add
            this.messages.push({
                uid: generateUid(),
                variant: variant,
                body,
            });
        },
    },
}
</script>

<style lang="scss">
.f-push-messages {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: $pushMessagesOrder;
}
</style>
