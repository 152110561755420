<template>
    <f-table
        allow-overflow
        rows-hoverable
        :empty="(!projectData.data || projectData.data.length === 0)"
        class="projects-overview"
    >
        <slot/>

        <template #pre>
            <f-pagination
                v-if="projectData.data"
                :current-page="projectData.current_page"
                :page-count="projectData.last_page"
                @navigate="onPageChange"
            />
        </template>

        <template #head>
            <f-table-header-row
                no-wrap
                :selectable="rowsAreSelectable"
                :selectable-actions="selectableActions"
                :selected-count="selectedRows.length"
                @selectAllRows="selectAllRows"
                @deselectAllRows="deselectAllRows"
            >
                <f-table-header-cell
                    class-name="projects-overview__project-header-cell"
                    colspan="2"
                >
                    Client / project
                </f-table-header-cell>
                <!--<f-table-header-cell align="center" hide-tablet>Tag</f-table-header-cell>-->
                <f-table-header-cell align="center">
                    Responsible
                </f-table-header-cell>
                <f-table-header-cell
                    align="right"
                    class-name="--no-wrap"
                >
                    Budget / result
                </f-table-header-cell>
                <f-table-header-cell
                    width="100px"
                    align="right"
                >
                    Accountability
                </f-table-header-cell>
                <f-table-header-cell
                    align="right"
                    hide-mobile
                >
                    Hrs sold
                </f-table-header-cell>
                <f-table-header-cell
                    align="right"
                    hide-mobile
                >
                    Hrs alloc.
                </f-table-header-cell>
                <f-table-header-cell width="25px"/>
            </f-table-header-row>
        </template>

        <project-overview-row
            v-for="project in projectData.data"
            :key="project.id"
            :selectable="rowsAreSelectable"
            :selected="selectedRows.includes(project.id)"
            :project="project"
            @selectStateChange="setSelectedRowState($event, project.id)"
            @refresh="$emit('refresh')"
        />

        <template #post>
            <f-pagination
                v-if="projectData.data"
                :current-page="projectData.current_page"
                :page-count="projectData.last_page"
                @navigate="onPageChange"
                bottom
            />

            <f-modal
                v-if="mergeModalActive"
                @close="mergeModalActive = false"
                title="Merge projects"
                width="600px"
            >
                <projects-merge-modal-contents
                    :project-ids="selectedRows"
                    @close="mergeModalActive = false"
                    @success="mergeModalActive = false; deselectAllRows(); $emit('refresh')"
                />
            </f-modal>
        </template>
    </f-table>
</template>

<script>
import FTable from '../../../../f-components/data/table/FTable';
import FTableHeaderCell from '../../../../f-components/data/table/FTableHeaderCell';
import FTableHeaderRow from "../../../../f-components/data/table/FTableHeaderRow";
import TableSelectableRowsMixin from "../../../../mixins/TableSelectableRowsMixin";
import FModal from "../../../../f-components/other/modal/FModal";
import ProjectsMergeModalContents from "./ProjectMergeModalContents";
import ProjectOverviewRow from "./ProjectOverviewRow";
import {Permissions} from '../../../../config/Permissions';
import ProjectsMixin from '../ProjectsMixin';
import FPagination from '../../../../f-components/navigation/pagination/FPagination';

export default {
    name: 'projects-overview',

    components: {
        FPagination,
        ProjectOverviewRow,
        ProjectsMergeModalContents,
        FModal,
        FTableHeaderRow,
        FTableHeaderCell,
        FTable,
    },

    mixins: [
        TableSelectableRowsMixin,
        ProjectsMixin,
    ],

    data() {
        return {
            mergeModalActive: false,
        }
    },

    watch: {
        projectData(old, $new) {
            if (old.total !== $new.total && this.selectedRows.length > 0) {
                this.deselectAllRows();
            }
        },
    },

    computed: {
        projectData() {
            return (this.$store.state.$projects ? this.$store.state.$projects : {});
        },
        rowsAreSelectable() {
            return this.hasPermission(Permissions.PROJECTS_EDIT) || this.hasPermission(Permissions.PROJECTS_MERGE);
        },
        selectableActions() {
            const actions = [];

            if (!this.rowsAreSelectable) {
                return [];
            }

            if (this.hasPermission(Permissions.PROJECTS_ARCHIVE)) {
                actions.push({
                    icon: 'archive',
                    value: 'Archive selected',
                    disabled: (this.selectedRows.length === 0),
                    onClick: () => {
                        this.setArchived(this.selectedRows, true);
                        this.deselectAllRows();
                    },
                });
            }

            if (this.hasPermission(Permissions.PROJECTS_MERGE)) {
                actions.push({
                    icon: 'combine',
                    value: 'Merge selected projects',
                    disabled: (this.selectedRows.length < 2),
                    onClick: () => this.mergeModalActive = true,
                });
            }

            return actions;
        },
    },

    methods: {
        onPageChange(pageNumber, position) {
            this.$emit('navigate', pageNumber, position);
        },
    },
}
</script>

<style lang="scss">
.projects-overview {
    &__project-header-cell {
        min-width: 420px;

        @include media(mobile-down) {
            min-width: 320px;
        }
    }
}
</style>
