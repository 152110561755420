<template>
    <div
        :class="[
            'product-card-mini',
             '--no-select',
         ]"
        v-tooltip="{text: tooltip, ...tooltipOptions}"
    >
        <div class="product-card-mini__body">
            <f-icon icon="receipt"/>
            {{ name }}

            <f-chip
                v-if="archived"
                variant="archived"
                size="xsmall"
            >
                Archived
            </f-chip>

            <div
                v-if="originalSellingPrice"
                class="product-card-mini__original-price"
            >
                {{ formatPrice(originalSellingPrice) }}
            </div>
            <div class="product-card-mini__price">
                {{ formatPrice(sellingPrice) }}
            </div>
            <slot/>
        </div>
    </div>
</template>

<script>
import FChip from '../../../f-components/data/chip/FChip';
import FIcon from '../../../f-components/layout/icons/FIcon';
import NumbersAndBudgetMixin from '../../../mixins/NumbersAndBudgetMixin';

export default {
    name: 'product-card-mini',

    components: {
        FIcon,
        FChip,
    },

    mixins: [
        NumbersAndBudgetMixin,
    ],

    props: {
        id: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        className: String,
        sellingPrice: {
            type: Number,
            required: true,
        },
        originalSellingPrice: Number,
        tooltip: {
            type: String,
            default: 'Rate card',
        },
        tooltipOptions: {
            type: Object,
            default: () => ({}),
        },
        archived: Boolean,
    },
}
</script>

<style lang="scss">
.product-card-mini {
    border-radius: 4px;
    background: $darkAlternative;
    color: $darkFront;
    vertical-align: middle;
    display: inline-flex;
    width: auto;
    border: 1px solid transparent;
    transition: border-color .1s, background-color .1s;
    align-items: center;
    text-align: left;
    font-size: 11px;
    line-height: 100%;

    * {
        vertical-align: middle;
    }

    &__body {
        white-space: nowrap;
        margin: 5px 10px;

        .f-icon {
            font-size: 12px;
            margin-right: 5px;
        }
    }

    &__original-price, &__price {
        margin-left: 5px;
        font-size: 10px;
    }

    &__original-price {
        display: inline-block;
        opacity: .3;
        text-decoration: line-through;
    }

    &__price {
        display: inline-block;
        opacity: .5;
    }

    .f-chip {
        margin: -5px 0;
        vertical-align: middle;
    }
}
</style>
