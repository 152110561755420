export default {
    data() {
        return {
            hasFocus: false,
        }
    },

    methods: {
        onBlur(e) {
            this.hasFocus = false;
            this.$emit('blur', e);
        },

        onFocus(e) {
            this.hasFocus = true;
            this.$emit('focus', e);
        },

        focus() {
            if (this.$refs.input) {
                this.$refs.input.focus();
            }
        },
    },
};
