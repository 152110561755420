export const EntityAvatarSizes = {
    XXSMALL: 'xxsmall',
    XSMALL: 'xsmall',
    SMALL: 'small',
    MEDIUM: 'medium',
    XMEDIUM: 'xmedium',
    LARGE: 'large',
    XLARGE: 'xlarge',
    XXLARGE: 'xxlarge',
}
