export class ObserverProvider {
    constructor(el, threshold = 0) {
        this.observers = [];

        this.intersectionObserver = new IntersectionObserver(
            (entries) => this.onObserve(entries),
            {
                root: el,
                threshold
            },
        );
    }

    observe(el, onObserve) {
        this.observers.push({
            target: el,
            onObserve: onObserve,
        })
        this.intersectionObserver.observe(el);
    }

    unobserve(el) {
        this.intersectionObserver.unobserve(el);
    }

    disconnect() {
        this.intersectionObserver.disconnect();
    }

    onObserve(entries) {
        for (let i in entries) {
            if (entries[i].isIntersecting) {
                this.onObservedEntry(entries[i].target);
            }
        }
    }

    onObservedEntry(target) {
        this.intersectionObserver.unobserve(target);

        for (let i in this.observers) {
            if (this.observers[i].target === target) {
                if (this.observers[i].onObserve) {
                    this.observers[i].onObserve();
                }
                // TODO: slice from this.observers
                return;
            }
        }
    }
}
