<template>
    <div
        v-if="
            (logs && logs.length > 0) ||
            (debugLogs && debugLogs.length > 0) ||
            (externalDebugLogs && externalDebugLogs.length > 0)
        "
        class="logs-panel"
    >
        <f-tabs
            @tabChange="tabId = $event"
            type="blocks"
            margin
        >
            <f-tab
                v-if="(logs && logs.length > 0)"
                id="logs"
                title="Project logs"
                subtitle="Project sync and manual<br>changes debugging"
            />
            <f-tab
                v-if="(debugLogs && debugLogs.length > 0)"
                id="debug"
                subtitle="Report analysis debugging"
                title="Report logs"
            />
            <f-tab
                v-if="(externalDebugLogs && externalDebugLogs.length > 0)"
                id="externalDebug"
                subtitle="External data sync debugging"
                title="External logs"
            />
        </f-tabs>

        <f-scroll-pane v-if="tabId === 'logs'" max-height="400px" variant="light">
            <f-table
                class-name="logs-panel__table"
                type="simple-dense"
            >
                <f-table-row
                    v-for="log in logs"
                    :key="log.id"
                >
                    <f-table-cell style="width: 150px; opacity: .5;" no-wrap>
                        {{ log.created_at }}
                    </f-table-cell>
                    <f-table-cell>{{ log.log }}</f-table-cell>
                </f-table-row>
            </f-table>
        </f-scroll-pane>

        <f-scroll-pane v-if="tabId === 'debug'" max-height="400px" variant="light">
            <f-table
                class-name="logs-panel__table"
                type="simple-dense"
            >
                <f-table-row
                    v-for="(log, i) in debugLogs"
                    :key="i"
                >
                    <f-table-cell
                        :class-name="(log.match(/^ - /) ? '--sub' : '') + (log.match(/^  - /) ? '--sub-sub' : '')"
                        v-html="log.replace(/\(.*\)/g, '<span>$&</span>').replace(/\[.*]/g, '<span>$&</span>')"
                    />
                </f-table-row>
            </f-table>
        </f-scroll-pane>

        <f-scroll-pane v-if="tabId === 'externalDebug'" max-height="400px" variant="light">
            <f-table
                class-name="logs-panel__table --external-debug"
                type="simple-dense"
                v-for="(logsSet, i) in [latestDebugLogs, externalDebugLogs]"
                :key="i"
                :style="{ marginBottom: '50px' }"
            >
                <f-table-row
                    v-for="(log, y) in logsSet"
                    :key="y"
                >
                    <f-table-cell
                        :class-name="(log.match(/^ - /) ? '--sub' : '') + (log.match(/^  - /) ? '--sub-sub' : '')"
                        v-html="
                            log.replace(/\(.*\)/g, '<span>$&</span>')
                                .replace(/\[.*]/g, '<span>$&</span>')
                                .replace(/— (.*) —/g, `<div class='logs-panel__table__external-key'>$&</div>`).replace(/—/g, '')
                        "
                    />
                </f-table-row>
            </f-table>
        </f-scroll-pane>
    </div>
</template>

<script>
import FTable from "../../../f-components/data/table/FTable";
import FTableRow from "../../../f-components/data/table/FTableRow";
import FTableCell from "../../../f-components/data/table/FTableCell";
import FTabs from "../../../f-components/navigation/tabs/FTabs";
import FTab from "../../../f-components/navigation/tabs/FTab";
import FScrollPane from '../../../f-components/other/scroll-pane/FScrollPane';

export default {
    name: 'logs-panel',

    components: {
        FScrollPane,
        FTab,
        FTabs,
        FTableCell,
        FTableRow,
        FTable,
    },

    props: {
        logs: {
            type: Array,
            required: true,
        },
        debugLogs: Array,
        externalDebugLogs: Array,
        latestDebugLogs: Array,
    },

    data() {
        return {
            tabId: 'logs'
        }
    },
}
</script>

<style lang="scss">
.logs-panel {
    .f-tabs__container {
        margin-top: -25px;
    }

    &__table {
        .--sub {
            opacity: .5;
            padding-left: 20px !important;
        }

        .--sub-sub {
            opacity: .5;
            padding-left: 40px !important;
        }

        span {
            opacity: .5;
        }

        &.--external-debug {
            .f-table-cell {
                padding-left: 30px !important;

                &.--sub {
                    padding-left: 50px !important;
                }
            }
        }

        &__external-key {
            font-size: 110%;
            font-weight: bold;
            //margin-left: -20px;
        }
    }
}
</style>
