<template>
    <ul
        :class="[
            'office-timezones',
            '--no-select',
            (alternative ? '--alternative' : null),
        ]"
    >
        <li
            v-for="zone in timezones"
            :key="zone.name"
            class="office-timezones__tz"
        >
            <div class="office-timezones__tz__column">
                <office-timezone-clock
                    v-if="alternative"
                    :hours="zone.time.hour()"
                    :minutes="zone.time.minutes()"
                />
            </div>
            <div class="office-timezones__tz__column">
                <div
                    class="office-timezones__tz__city"
                    v-text="zone.name"
                />
                <div
                    class="office-timezones__tz__time"
                    v-html="zone.time.format(timeFormat)"
                />
            </div>
        </li>
    </ul>
</template>

<script>
import moment from 'moment-timezone';
import EventBus from '../../../../services/EventBus';
import {GlobalEvents} from '../../../../config/Events';
import TimezoneMixin from '../../../../mixins/TimezoneMixin';
import APIDataStoreResources from '../../../../config/APIDataStoreResources';
import OfficeTimezoneClock from './components/OfficeTimezoneClock';

export default {
    name: 'office-timezones',

    components: {
        OfficeTimezoneClock,
    },

    mixins: [
        TimezoneMixin,
    ],

    props: {
        alternative: Boolean,
    },

    data() {
        return {
            updateTimeout: null,
            timezones: [],
        }
    },

    mounted() {
        EventBus.$on(GlobalEvents.MINUTE_CHANGE, this.defineTimezones);
        EventBus.$on(`ds.${APIDataStoreResources.OFFICES_COLLECTION.id}.update`, this.defineTimezones);
        this.defineTimezones();
    },

    methods: {
        defineTimezones() {
            if (this.officesCollection.length > 0) {
                this.timezones = [];

                for (let i in this.officesCollection) {
                    this.timezones.push({
                        name: this.officesCollection[i].city,
                        time: moment().tz(this.officesCollection[i].timezone),
                    });
                }
                return;
            }

            this.timezones = [
                {
                    name: 'Amsterdam',
                    time: moment().tz('Europe/Amsterdam'),
                },
            ];
        },
    },
}
</script>

<style lang="scss">
.office-timezones {
    display: inline-flex;
    gap: 20px;
    line-height: 120%;
    font-weight: 500;

    &__tz {
        display: inline-flex;
        align-items: center;
        text-align: left;

        &__column {
            flex: 1 1;
        }

        &__city {
            padding-bottom: 12px;
        }

        &__time {
            font-size: 200%;
            white-space: nowrap;

            span {
                text-transform: lowercase;
            }
        }
    }

    &.--alternative {
        .office-timezone-clock {
            margin-right: 10px;
        }

        .office-timezones__tz {
            align-items: start;

            &__city {
                padding-bottom: 0;
            }

            &__time {
                padding-bottom: 2px;
                font-size: 80%;
                opacity: .5;

                span {
                    text-transform: lowercase;
                }
            }
        }
    }
}
</style>
