import {APP_ENVIRONMENT} from '../config/app';
import EventBus from './EventBus';
import Store from './Store';
import {GlobalEvents} from '../config/Events';

/**
 * @typedef {{isSubscribed: boolean, isOptedOut: boolean, isSupported: boolean, isPushEnabled: boolean}} OneSignalState
 * @type {OneSignalState}
 */
export const defaultState = {
    isSupported: false,
    isSubscribed: false,
    isPushEnabled: false,
    isOptedOut: false,
}

const OneSignal = {
    /**
     * @type {OneSignalState}
     */
    state: defaultState,

    load() {
        if (APP_ENVIRONMENT !== 'local' && !window.OneSignal) {
            const oneSignal = document.createElement('script')
            oneSignal.addEventListener('load', () => this.initialize());
            oneSignal.setAttribute('src', 'https://cdn.onesignal.com/sdks/OneSignalSDK.js')
            document.head.appendChild(oneSignal);
        }
    },

    initialize() {
        window.OneSignal.push(() => {
            if (!window.OneSignal.isPushNotificationsSupported()) {
                console.warn('Push notifications is not supported.')
                return;
            }

            window.OneSignal.init({
                appId: '5b7ad959-cb22-43c8-aed7-3bd6d9e11c19',
                safari_web_id: 'web.onesignal.auto.313afc18-65a3-4cb5-bd8a-eabd69c6e4d8',
            });

            // window.OneSignal.log.setLevel('trace');

            this.changeState({
                isSupported: true,
            });

            window.OneSignal.on('subscriptionChange', (isSubscribed) => {
                this.changeState({
                    isSubscribed,
                });
                this.fetchSubscriptionState();
            });
        });

        window.OneSignal.push(() => this.fetchSubscriptionState());
    },

    /**
     * @returns {OneSignalState}
     */
    getState() {
        return this.state;
    },

    /**
     * @param {object} state
     */
    changeState(state) {
        this.state = {
            ...this.state,
            ...state
        };
        EventBus.$emit(GlobalEvents.ONESIGNAL_STATE_CHANGE, this.state);
    },

    fetchSubscriptionState() {
        Promise.all([
            window.OneSignal.isPushNotificationsEnabled(),
            window.OneSignal.isOptedOut()
        ]).then((result) => {
            const isSubscribed = result[0];
            const isOptedOut = result[1];

            this.changeState({
                isSubscribed,
                isOptedOut,
            })

            if (!isSubscribed && !window.localStorage.getItem('riviera.push-notification.show')) {
                // this.$nextTick(() => {
                //     this.onClick();
                // }); // TODO: ???????
                window.localStorage.setItem('riviera.push-notification.show', 'yes');
            }

            if (Store.state.$user && Store.state.$user.id) {
                window.OneSignal.setExternalUserId(Store.state.$user.id.toString());
            }
        });
    },

    toggleSubscription() {
        if (this.state.isSubscribed) {
            // Subscribed, opt them out
            window.OneSignal.setSubscription(false);
        } else {
            if (this.state.isOptedOut) {
                // Opted out, opt them back in
                window.OneSignal.setSubscription(true);
            } else {
                // Unsubscribed, subscribe them
                window.OneSignal.registerForPushNotifications();
            }

            if (Store.state.$user && Store.state.$user.id) {
                window.OneSignal.setExternalUserId(Store.state.$user.id.toString());
            }
        }
    },
};

export default OneSignal;
