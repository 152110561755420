<template>
    <th
        :class="[
            'f-table-header-cell',
            (hideMobile ? '--hide-mobile' : null),
            (hideTablet ? '--hide-tablet' : null),
            (noPaddingRight ? '--no-padding-right' : null),
            (noPaddingLeftRight ? '--no-padding-left-right' : null),
            (noPaddingBottomTop ? '--no-padding-bottom-top' : null),
            (noWrap ? '--no-wrap' : null),
            (align ? `--align-${align}` : null),
            className,
        ]"
        :style="{width: width}"
    >
        <f-text type="subtitle">
            <slot/>
        </f-text>
    </th>
</template>

<script>
import FTableCellMixin from './FTableCellMixin';
import FText from '../../layout/text/FText';

export default {
    name: 'f-table-header-cell',

    components: {
        FText,
    },

    mixins: [
        FTableCellMixin,
    ],
}
</script>

<style lang="scss">
.f-table-header-cell {
    vertical-align: bottom;

    &.--no-padding-right {
        padding-right: 2px !important;
    }

    &.--no-padding-left-right {
        padding-right: 2px !important;
        padding-left: 2px !important;
    }

    &.--no-padding-bottom-top {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }
}
</style>
