<template>
    <f-table type="simple-dense">
        <f-table-row
            v-for="(allocation, i) in report.hours.allocations.excluded"
            :key="(allocation.user ? (allocation.user.id + '-' + i) : i)"
        >
            <f-table-cell>
                {{
                    (allocation.description && allocation.description.toLowerCase() !== 'excluded' ?
                        allocation.description : '')
                }}
                {{ (allocation.user ? allocation.user.name : '') }}
            </f-table-cell>
            <f-table-cell align="right">
                {{ formatNumber(allocation.total) }} hours
            </f-table-cell>
        </f-table-row>
    </f-table>
</template>

<script>
import FTable from '../../../f-components/data/table/FTable';
import FTableRow from '../../../f-components/data/table/FTableRow';
import FTableCell from '../../../f-components/data/table/FTableCell';
import NumbersAndBudgetMixin from '../../../mixins/NumbersAndBudgetMixin';

export default {
    name: 'excluded-allocations',

    components: {
        FTableCell,
        FTableRow,
        FTable,
    },

    mixins: [
        NumbersAndBudgetMixin,
    ],

    props: {
        report: {
            type: Object,
            required: true,
        },
    },
}
</script>
