<template>
    <f-input-group :class="[ 'f-sorting-dropdown']">
        <f-dropdown
            :options="options"
            :pre-icon="`sort-amount-${direction}`"
            v-model="field"
            placeholder="Sorting"
            class-name="f-sorting-dropdown__field"
        />
        <f-dropdown
            :options="[
                {key: `desc`, value: `Desc`},
                {key: `asc`, value: `Asc`},
            ]"
            class-name="f-sorting-dropdown__direction"
            v-model="direction"
        />
    </f-input-group>
</template>

<script>
import FDropdown from "../../form-controls/dropdowns/FDropdown";
import FInputGroup from '../../form-controls/base/FInputGroup';

export default {
    name: 'f-sorting-dropdown',

    components: {
        FInputGroup,
        FDropdown,
    },

    props: {
        value: {
            type: Object,
            default: () => {
                return {
                    field: null,
                    direction: 'desc',
                }
            },
        },
        options: {
            type: Array,
            required: true,
        },
    },

    computed: {
        direction: {
            get() {
                return this.value.direction;
            },
            set(value) {
                this.$emit('input', {
                    field: this.value.field,
                    direction: value,
                });
            },
        },
        field: {
            get() {
                return this.value.field;
            },
            set(value) {
                this.$emit('input', {
                    field: value,
                    direction: this.value.direction,
                });
            },
        },
    },
}
</script>

<style lang="scss">
.f-sorting-dropdown {
    display: inline-flex;
    width: 100%;

    &__direction {
        flex: 0 0 60px;
    }
}
</style>
