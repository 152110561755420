<template>
    <li
        :class="[
            'f-carousel-navigation-item',
            (active ? '--active' : undefined),
        ]"
        v-tooltip="{
            text: title,
            options: tooltipOptions
        }"
    >
        <button
            type="button"
            @click="$emit('click')"
        />
    </li>
</template>

<script>
export default {
    name: 'f-carousel-navigation-item',

    props: {
        active: {
            type: Boolean,
            required: true,
        },
        title: String,
        tooltipOptions: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
}
</script>

<style lang="scss">
.f-carousel-navigation-item {
    display: inline-block;
    position: relative;

    button {
        position: relative;
        border: 0;
        background: 0;
        width: 15px;
        height: 20px;

        &:before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: background-color .1s;
            background-color: $tertiaryGray;
        }

        &:hover:before {
            background-color: $primary;
        }
    }

    &.--active {
        button:before {
            background-color: rgba($primary, 1);
        }
    }
}
</style>
