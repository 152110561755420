const POINTER_START_EVENTS = ['mousedown', 'touchstart'];
const POINTER_MOVE_EVENTS = ['mousemove', 'touchmove'];
const POINTER_END_EVENTS = ['mouseup', 'touchend'];


/**
 * Copied from https://vue-dragscroll.donfalcon.com/
 * TODO: needs work
 * @param el
 * @param binding
 * @param vnode
 */
const init = function (el, binding, vnode) {
    // Default parameters
    let target = el; // The element to apply the drag-scroll on
    let active = true; // Enable/disable drag-scroll
    let container = window;

    // Config type: boolean
    // Example: v-drag-scroll="true" or v-drag-scroll="false"
    if (typeof binding.value === 'boolean') {
        active = binding.value;
    } else if (typeof binding.value === 'object') {
        // Config type: object / Example: v-drag-scroll="{ active: true , target: "child" }"

        // Parameter: target
        if (typeof binding.value.target === 'string') {
            target = el.querySelector(binding.value.target);
            if (!target) {
                console.error('There is no element with the current target value.')
            }
        } else if (typeof binding.value.target !== 'undefined') {
            console.error(`The parameter "target" should be either 'undefined' or 'string'.`)
        }

        // Parameter: container
        if (typeof binding.value.container === 'string') {
            container = document.querySelector(binding.value.container);
            if (!container) {
                console.error('There is no element with the current container value.');
            }
        } else if (typeof binding.value.container !== 'undefined') {
            console.error(`The parameter "container" should be either 'undefined' or 'string'.`);
        }

        // Parameter: active
        if (typeof binding.value.active === 'boolean') {
            active = binding.value.active;
        } else if (typeof binding.value.active !== 'undefined') {
            console.error(`The parameter "active" should be either 'undefined' or 'true' or 'false'.`);
        }
    } else if (typeof binding.value !== 'undefined') {
        // Throw an error if invalid parameters
        console.error(`The passed value should be either 'undefined', 'true', 'false' or 'object'.`);
    }

    const scrollBy = function (x, y) {
        if (container === window) {
            window.scrollBy(x, y);
        } else {
            container.scrollLeft += x;
            container.scrollTop += y;
        }
    }

    const reset = function () {
        let lastClientX, lastClientY, pushed, timeout, isDragging = false;

        target.md = function (e) {
            const isMouseEvent = e instanceof window.MouseEvent;

            // The coordinates of the mouse pointer compared to the page when the mouse button is clicked on an element
            const pageX = isMouseEvent ? e.pageX : e.touches[0].pageX;
            const pageY = isMouseEvent ? e.pageY : e.touches[0].pageY;
            const clickedElement = document.elementFromPoint(pageX - window.pageXOffset, pageY - window.pageYOffset);

            const hasNoChildDrag = binding.arg === 'nochilddrag';
            const ignoreLeft = binding.modifiers.noleft;
            const ignoreRight = binding.modifiers.noright;
            const ignoreMiddle = binding.modifiers.nomiddle;
            const ignoreBack = binding.modifiers.noback;
            const ignoreForward = binding.modifiers.noforward;
            const hasFirstChildDrag = binding.arg === 'firstchilddrag';
            const isEl = clickedElement === target;
            const isFirstChild = clickedElement === target.firstChild;
            const isDataDraggable = hasNoChildDrag ?
                typeof clickedElement.dataset.dragscroll !== 'undefined' : typeof clickedElement.dataset.noDragscroll === 'undefined';

            if (!isEl && (!isDataDraggable || (hasFirstChildDrag && !isFirstChild))) {
                return;
            }

            if (e.which === 1 && ignoreLeft) {
                return;
            } else if (e.which === 2 && ignoreMiddle) {
                return;
            } else if (e.which === 3 && ignoreRight) {
                return;
            } else if (e.which === 4 && ignoreBack) {
                return;
            } else if (e.which === 5 && ignoreForward) {
                return;
            }

            pushed = 1;
            lastClientX = isMouseEvent ? e.clientX : e.touches[0].clientX;
            lastClientY = isMouseEvent ? e.clientY : e.touches[0].clientY;
        }

        target.mu = function () {
            pushed = 0;
            if (isDragging) {
                eventsHelper.emitEvent(vnode, 'dragScrollEnd');
                if (timeout) {
                    clearTimeout(timeout);
                }
            }
            isDragging = false;
        }

        target.mm = function (e) {
            const isMouseEvent = e instanceof window.MouseEvent;
            let newScrollX, newScrollY;
            const eventDetail = {};

            if (pushed) {
                e.preventDefault();

                // Pushed
                // Emit start event
                if (!isDragging) {
                    eventsHelper.emitEvent(vnode, 'dragScrollStart');
                    timeout = setTimeout(() => {
                        eventsHelper.emitEvent(vnode, 'dragScrollStartDelayed');
                    }, 100);
                }
                isDragging = true;

                // When we reach the end or the begining of X or Y
                const isEndX = ((target.scrollLeft + target.clientWidth) >= target.scrollWidth) || target.scrollLeft === 0;
                const isEndY = ((target.scrollTop + target.clientHeight) >= target.scrollHeight) || target.scrollTop === 0;

                // Get new scroll dimensions
                newScrollX = (-lastClientX + (lastClientX = isMouseEvent ? e.clientX : e.touches[0].clientX));
                newScrollY = (-lastClientY + (lastClientY = isMouseEvent ? e.clientY : e.touches[0].clientY));

                if (binding.modifiers.pass) {
                    // Compute and scroll
                    target.scrollLeft -= binding.modifiers.y ? -0 : newScrollX;
                    target.scrollTop -= binding.modifiers.x ? -0 : newScrollY;
                    if (target === document.body) {
                        target.scrollLeft -= binding.modifiers.y ? -0 : newScrollX;
                        target.scrollTop -= binding.modifiers.x ? -0 : newScrollY;
                    }

                    // If one side reach the end scroll container
                    if (isEndX || binding.modifiers.y) {
                        scrollBy(-newScrollX, 0);
                    }
                    if (isEndY || binding.modifiers.x) {
                        scrollBy(0, -newScrollY);
                    }
                } else {
                    // Disable one scroll direction in case x or y is specified
                    if (binding.modifiers.x) newScrollY = -0;
                    if (binding.modifiers.y) newScrollX = -0;

                    // Compute and scroll
                    target.scrollLeft -= newScrollX;
                    target.scrollTop -= newScrollY;
                    if (target === document.body) {
                        target.scrollLeft -= newScrollX;
                        target.scrollTop -= newScrollY;
                    }
                }

                // Emit events
                eventDetail.deltaX = -newScrollX;
                eventDetail.deltaY = -newScrollY;
                eventsHelper.emitEvent(vnode, 'dragScrollMove', eventDetail);
            }
        }

        eventsHelper.addEventListeners(target, POINTER_START_EVENTS, target.md);
        eventsHelper.addEventListeners(window, POINTER_END_EVENTS, target.mu);
        eventsHelper.addEventListeners(window, POINTER_MOVE_EVENTS, target.mm);
    }

    // If value is undefined or true we will init
    if (active) {
        if (document.readyState === 'complete') {
            reset();
        } else {
            window.addEventListener('load', reset);
        }
    } else {
        // If value is false means we disable
        eventsHelper.removeEventListeners(target, POINTER_START_EVENTS, target.md);
        eventsHelper.removeEventListeners(window, POINTER_END_EVENTS, target.mu);
        eventsHelper.removeEventListeners(window, POINTER_MOVE_EVENTS, target.mm);
    }
}

export default {
    inserted: function (el, binding, vnode) {
        init(el, binding, vnode);
    },
    update: function (el, binding, vnode) {
        // Update the component only if the parameters change
        if (JSON.stringify(binding.value) !== JSON.stringify(binding.oldValue)) {
            init(el, binding, vnode);
        }
    },
    unbind: function (el) {
        eventsHelper.removeEventListeners(el, POINTER_START_EVENTS, el.md);
        eventsHelper.removeEventListeners(window, POINTER_END_EVENTS, el.mu);
        eventsHelper.removeEventListeners(window, POINTER_MOVE_EVENTS, el.mm);
    },
}

const eventsHelper = {
    addEventListeners(el, events, handler) {
        for (let i = 0, len = events.length; i < len; i++) {
            el.addEventListener(events[i], handler, {passive: false})
        }
    },
    removeEventListeners(el, events, handler) {
        for (let i = 0, len = events.length; i < len; i++) {
            el.removeEventListener(events[i], handler, {passive: false})
        }
    },
    emitEvent: function (vnode, eventName, eventDetail) {
        if (vnode.componentInstance) {
            vnode.componentInstance.$emit(eventName, eventDetail);
        } else {
            let event;
            if (typeof (window.CustomEvent) === 'function') {
                event = new window.CustomEvent(eventName, {detail: eventDetail});
            } else {
                // Deprecated fallback for IE
                event = document.createEvent('CustomEvent');
                event.initCustomEvent(eventName, true, true, eventDetail);
            }
            vnode.elm.dispatchEvent(event);
        }
    },
}
