var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f-swipe-gestures',{attrs:{"class-name":[
        'f-carousel',
        (_vm.navigationType !== 'none' ? `--nav-align-${_vm.navigationVerticalAlign}` : null),
        `--nav-${_vm.navigationType}`,
        (_vm.navigationFloat ? '--nav-float' : undefined),
        (_vm.autoSize ? '--auto-size' : undefined),
        (_vm.stretchHeight ? '--stretch-height' : undefined),
        (_vm.animating ? '--animating' : undefined),
        ..._vm.classNames,
    ]},on:{"swipe-left":_vm.onSwipeLeft,"swipe-right":_vm.onSwipeRight}},[_c('div',{ref:"container",staticClass:"f-carousel__slide-container",style:(_vm.containerStyles)},[_vm._t("default")],2),(_vm.navigationType === 'dots')?_c('ul',{staticClass:"f-carousel__navigation"},_vm._l((_vm.navigationItems),function(item){return _c('f-carousel-navigation-item',{key:item.id,attrs:{"title":item.title,"active":(_vm.currentUID === item.id),"tooltip-options":_vm.navigationTooltipOptions},on:{"click":function($event){return _vm.slideToUID(item.id)}}})}),1):(_vm.navigationType === 'prev-next')?_c('div',{staticClass:"f-carousel__navigation"},[_c('f-icon-button',{attrs:{"icon":"chevron-left","outlined":"","disabled":!!!_vm.previousUID},on:{"click":_vm.slideToPrevious}}),_c('f-icon-button',{attrs:{"icon":"chevron-right","outlined":"","disabled":!!!_vm.nextUID},on:{"click":_vm.slideToNext}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }