import {generateUid} from '../../../utils/generateUid';

export default {
    data() {
        return {
            uid: generateUid(),
        }
    },

    computed: {
        boundProps() {
            return Object.filter(this.$props, (key) => !this.getExcludedProps().includes(key));
        },
    },

    methods: {
        setUid(uid) {
            this.uid = uid;
        },
        getExcludedProps() {
            return [];
        },
    },
};
