export default {
    props: {
        className: {
            type: String | Array,
            default: () => ([]),
        },
    },
    computed: {
        classNames() {
            return Array.isArray(this.className) ? this.className : [this.className];
        },
    },
};
