var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{class:[
        'f-table-cell',
        (_vm.hideMobile ? '--hide-mobile' : null),
        (_vm.hideTablet ? '--hide-tablet' : null),
        (_vm.noWrap ? '--no-wrap' : null),
        (_vm.noPaddingRight ? '--no-padding-right' : null),
        (_vm.noPaddingBottomTop ? '--no-padding-bottom-top' : null),
        (_vm.noPaddingLeftRight ? '--no-padding-left-right' : null),
        (_vm.noPaddingLeft ? '--no-padding-left' : null),
        (_vm.align ? `--align-${_vm.align}` : null),
        (_vm.isId ? `--id` : null),
        _vm.className,
    ],style:({width: _vm.width}),on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }