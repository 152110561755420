var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasPermission(_vm.Permissions.ADMIN_FIKA))?_c('page',{attrs:{"loading":_vm.loading,"class-name":"admin-fika-page","title":['Admin', 'FIKAs'],"header":"FIKAs","header-badge":(_vm.fetchedData ? _vm.fetchedData.total : null),"pre-max-width":"700px"},scopedSlots:_vm._u([{key:"pre",fn:function(){return [_c('f-breadcrumbs',[_c('f-breadcrumb',[_vm._v("Admin")]),_c('f-breadcrumb',[_vm._v("FIKAs")]),_c('f-breadcrumb',[_vm._v("Overview")])],1)]},proxy:true}],null,false,2982062414)},[_c('f-container',{attrs:{"max-width":"700px"}},[_c('f-filter',{attrs:{"show-active-filters":"","active-filters":_vm.activeFilters,"filter-casts":{query: 'query'}}},[_c('f-filter-row',[_c('f-filter-item',[_c('f-text-input',{attrs:{"placeholder":"Search…","pre-icon":"magnifier","class-name":"--align-center","max-width":"300px","resetable":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1)],1),(_vm.fetchedData)?_c('f-table',{attrs:{"empty":(!_vm.fetchedData || _vm.items.length === 0),"rows-hoverable":"","allow-horizontal-scroll":false},scopedSlots:_vm._u([{key:"pre",fn:function(){return [(_vm.fetchedData)?_c('f-pagination',{attrs:{"current-page":_vm.currentPage,"page-count":_vm.lastPage},on:{"navigate":_vm.onPaginationNavigate}}):_vm._e()]},proxy:true},{key:"head",fn:function(){return [_c('f-table-header-row',[_c('f-table-header-cell',[_vm._v(" Number ")]),_c('f-table-header-cell',{attrs:{"align":"center"}},[_vm._v(" Date ")])],1)]},proxy:true},{key:"post",fn:function(){return [(_vm.fetchedData)?_c('f-pagination',{attrs:{"current-page":_vm.currentPage,"page-count":_vm.lastPage,"bottom":""},on:{"navigate":_vm.onPaginationNavigate}}):_vm._e()]},proxy:true}],null,false,3090490816)},_vm._l((_vm.items),function(row){return _c('f-table-row',{key:row.id,attrs:{"actions":[
                    {
                        value: 'Edit',
                        icon: 'pencil',
                        onClick: () => _vm.$router.push(`/admin/fika/edit/${row.id}`),
                    },
                    {
                        icon: 'trash2',
                        value: 'Delete',
                        onClick: () => _vm.deleteFika(row.id, row.number),
                    },
                ]},on:{"click":function($event){return _vm.$router.push(`/admin/fika/edit/${row.id}`)}}},[_c('f-table-cell',{attrs:{"is-id":""}},[_vm._v(" #"+_vm._s(row.number)+" ")]),_c('f-table-cell',{attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.getVisualDate(row.date))+" ")])],1)}),1):(!_vm.fetchedData && _vm.loading)?_c('f-table-placeholder'):_vm._e()],1),_c('entity-action-button',{attrs:{"permission":_vm.Permissions.ADMIN_FIKA,"route":"/admin/fika/create"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }