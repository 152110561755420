export const ChipSizes = {
    XSMALL: 'xsmall',
    SMALL: 'small',
    MEDIUM: 'medium',
    XMEDIUM: 'xmedium',
    LARGE: 'large',
}

export const ChipVariants = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    DARK: 'dark',
    LIGHT: 'light',
    INFO: 'info',
    DANGER: 'danger',
    WARNING: 'warning',
    SUCCESS: 'success',
    ARCHIVE: 'archive',
    STATUS: 'status',

    /**
     * @deprecated
     */
    ERROR: 'error',
    /**
     * @deprecated
     */
    ALTERNATIVE: 'alternative',
    /**
     * @deprecated
     */
    ALTERNATIVE_INVERTED: 'alternative-inverted',
}
