<template>
    <div
        id="f-portal-container"
        class="--theme-dark"
    />
</template>

<script>
export default {
    name: 'f-portal-container',

    mounted() {
        document.body.append(this.$el);
    },
}
</script>

<style lang="scss">
#f-portal-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: $portalContainerOrder;
    pointer-events: none;

    .f-portal {
        pointer-events: all;
    }
}
</style>
