<template>
    <widget
        v-if="enabled"
        class-name="favourite-projects-widget"
        title="Projects"
        header="Favourites"
    >
        <f-scroll-pane max-height="400px" ref="scrollPane">
            <f-table
                allow-horizontal-scroll
                rows-hoverable
            >
                <f-table-row
                    v-for="project in projects"
                    :key="project.id"
                    @click="$router.push(`/projects/${project.id}`)"
                >
                    <f-table-cell
                        v-if="!breakpoints.mobile"
                        width="50px"
                        no-padding-right
                    >
                        <entity-avatar
                            v-if="clientFromId(project.client_id)"
                            :src="clientFromId(project.client_id).image_url"
                            :name="clientFromId(project.client_id).name"
                            size="large"
                        />
                        <entity-avatar
                            v-else-if="project.supplemental_data && project.supplemental_data.company_name"
                            :name="project.supplemental_data.company_name"
                            size="large"
                        />
                    </f-table-cell>
                    <f-table-title-cell>
                        {{ project.name }}

                        <template #sub>
                            <div class="favourite-projects-widget__subtitle">
                                {{ project.office_id.ucfirst() }} office

                                <span v-if="clientFromId(project.client_id)">
                                     <subtitle-spacer/>
                                    {{ clientFromId(project.client_id).name }}
                                </span>
                            </div>

                            <project-dates-visualizer
                                :start-date="project.start_date"
                                :planning-start-date="project.planning_start_date"
                                :planning-end-date="project.planning_end_date"
                                class-name="--hide-mobile"
                            />

                            <project-codes :project="project"/>

                            <f-chip
                                v-if="project.is_archived"
                                variant="archived"
                                size="small"
                            >
                                archived
                            </f-chip>
                        </template>
                    </f-table-title-cell>
                    <f-table-cell no-wrap align="right">
                        <div class="favourite-projects-widget__budget">
                            <dashed-explanation tooltip="Project budget">
                                {{ formatPrice(project.budget) }}
                            </dashed-explanation>
                        </div>
                        <div
                            v-if="project.budget"
                            :class="[
                                'favourite-projects-widget__budget-result',
                                (project.result < 0 ? '--price-alert' : null)
                            ]"
                        >
                            <dashed-explanation tooltip="Project result">
                                {{ formatPrice(project.result) }}
                            </dashed-explanation>
                        </div>
                        <profitable-bar
                            v-if="project.supplemental_data && project.supplemental_data.has_planning"
                            :total="project.budget"
                            :current="project.budget - project.result"
                            width="20%"
                            min-width="100px"
                            percentage-location="left"
                        />
                    </f-table-cell>
                </f-table-row>
            </f-table>
        </f-scroll-pane>
    </widget>
</template>

<script>
import APIDataStoreResources from '../../../config/APIDataStoreResources';
import APIDataStore from '../../../services/APIDataStore';
import FScrollPane from '../../../f-components/other/scroll-pane/FScrollPane';
import FChip from '../../../f-components/data/chip/FChip';
import Widget from '../Widget';
import FTable from '../../../f-components/data/table/FTable';
import FTableRow from '../../../f-components/data/table/FTableRow';
import FTableCell from '../../../f-components/data/table/FTableCell';
import EntityAvatar from '../../misc/entities/EntityAvatar';
import ClientHelperMixin from '../../../mixins/ClientHelperMixin';
import FTableTitleCell from '../../../f-components/data/table/FTableTitleCell';
import ProfitableBar from "../../misc/project-analysis/ProfitableBar";
import ProjectDatesVisualizer from "../../pages/projects/components/ProjectDatesVisualizer";
import ProjectCodes from '../../pages/projects/components/ProjectCodes';
import NumbersAndBudgetMixin from '../../../mixins/NumbersAndBudgetMixin';
import {Permissions} from '../../../config/Permissions';
import SubtitleSpacer from '../../misc/SubtitleSpacer';
import DashedExplanation from '../../misc/DashedExplanation';
import BreakpointsMixin from '../../../mixins/BreakpointsMixin';

export default {
    name: 'favourite-projects-widget',

    components: {
        DashedExplanation,
        SubtitleSpacer,
        ProjectCodes,
        ProjectDatesVisualizer,
        ProfitableBar,
        FTableTitleCell,
        EntityAvatar,
        FTableCell,
        FTableRow,
        FTable,
        Widget,
        FChip,
        FScrollPane,
    },

    mixins: [
        ClientHelperMixin,
        NumbersAndBudgetMixin,
        BreakpointsMixin,
    ],

    computed: {
        enabled() {
            return (
                this.hasPermission(Permissions.PROJECTS_VIEW) &&
                this.$store.getters.getUserSetting('project.favourites') !== null &&
                this.$store.getters.getUserSetting('project.favourites') !== '[]' &&
                this.$store.state.$projectFavourites &&
                this.$store.state.$projectFavourites.length > 0
            );
        },
        projects() {
            return this.$store.state.$projectFavourites;
        },
    },

    watch: {
        projects() {
            this.$nextTick(() => this.$refs.scrollPane && this.$refs.scrollPane.invalidate ? this.$refs.scrollPane.invalidate() : null);
        },
    },

    created() {
        if (
            this.hasPermission(Permissions.PROJECTS_VIEW) &&
            this.$store.getters.getUserSetting('project.favourites') !== null &&
            this.$store.getters.getUserSetting('project.favourites') !== '[]'
        ) {
            APIDataStore.check(APIDataStoreResources.PROJECT_FAVOURITES);
        }
    },
}
</script>

<style lang="scss">
.favourite-projects-widget {
    .f-table {
        min-width: 340px;
    }

    &__budget-result {
        opacity: .5;
        font-size: 90%;
        padding-top: 2px;
    }

    .f-table-title-cell__subtitle .project-dates-visualizer {
        padding: 6px 0 2px 0;
    }

    .project-codes {
        margin-top: 2px;
    }

    .--price-alert {
        color: $dangerShade;
        opacity: 1;

        .dashed-explanation:hover {
            border-color: $dangerShade;
        }
    }

    .--price-warning {
        color: $warning;

        .dashed-explanation:hover {
            border-color: $warning;
        }
    }

    .f-table {
        margin: -10px 0;
    }
}
</style>
