var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'f-flex-table-cell',
         (_vm.hideMobile ? '--hide-mobile': null),
         (_vm.showMobile ? '--show-mobile': null),
         (_vm.border ? '--border': null),
         (_vm.overflowHidden ? '--overflow-hidden': null),
         (_vm.align ? `--align-${_vm.align}` : null),
         _vm.className,
    ],style:(`flex: ${_vm.flex}`)},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }