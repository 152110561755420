import EventBus from '../../services/EventBus';
import {GlobalEvents} from '../../config/Events';

export default {
    mounted() {
        this.onWindowResize();
        EventBus.$on(GlobalEvents.WINDOW_RESIZE, this.onWindowResize);
    },

    props: {
        extraBreakPoints: Boolean,
    },

    data() {
        return {
            widgetSize: '--widget-large',
        }
    },

    beforeDestroy() {
        EventBus.$off(GlobalEvents.WINDOW_RESIZE, this.onWindowResize);
    },

    methods: {
        onWindowResize() {
            this.defineWidgetSize();
            this.$nextTick(() => this.defineWidgetSize());
        },

        defineWidgetSize() {
            const width = this.$el.clientWidth;
            const breaks = this.getWidgetBreakpoints();

            for (let i in breaks) {
                if (width > i) {
                    this.widgetSize = '--widget-' + breaks[i];
                }
            }
        },

        getWidgetBreakpoints() {
            if (!this.extraBreakPoints) {
                return {
                    576: 'large',
                    400: 'medium',
                    200: 'small',
                };
            }

            return {
                700: 'xxlarge',
                650: 'xlarge',
                600: 'large',
                550: 'xxxmedium',
                500: 'xxmedium',
                450: 'xmedium',
                400: 'medium',
                350: 'small',
                300: 'xsmall',
                250: 'xsmall',
                200: 'xxsmall',
                150: 'xxxsmall',
            }
        },
    },
};
