<template>
    <div
        :class="[
            'f-date-picker-select',
            (inline ? '--inline' : null),
            `--picker-${activePicker}`,
            '--no-select',
            (hideWeekends ? '--hide-weekends' : null),
            `--${variant}`,
        ]"
    >
        <header
            v-if="activePicker === 'day'"
            class="f-date-picker-select-header"
        >
            <f-icon-button
                v-if="activePicker === 'day' && allowPrevious"
                icon="chevron-left"
                class-name="f-date-picker-select-date-previous"
                @click="previousMonth"
                tabindex="-1"
                size="medium"
                variant="light"
                outlined
            />

            <div
                :class="['f-date-picker-select-date-caption', (!disableMonthAndYearPicker ? '--clickable' : null)]"
                @click="showPicker('month')"
            >
                {{ activeShowDate.format('MMMM, YYYY') }}
            </div>

            <f-icon-button
                v-if="activePicker === 'day' && allowNext"
                icon="chevron-right"
                class-name="f-date-picker-select-date-next"
                @click="nextMonth"
                tabindex="-1"
                size="medium"
                variant="light"
                outlined
            />
        </header>

        <div
            v-if="activePicker === 'day'"
            class="f-date-picker-select-days"
        >
            <ul class="f-date-picker-select-day-header">
                <li v-if="showWeekNumbers" class="f-date-picker-select-week-number"/>
                <li
                    v-for="weekday in weekdays"
                    :class="`--day-${weekday.replace('.', '').toLowerCase()}`"
                    :key="weekday"
                >
                    {{ weekday.replace('.', '') }}
                </li>
            </ul>

            <ul
                v-for="week in groupedDays"
                class="f-date-picker-select-week"
                :key="week[1].date.isoWeek()"
            >
                <li v-if="showWeekNumbers" class="f-date-picker-select-week-number">
                    {{ week[1].date.isoWeek() }}
                </li>
                <li
                    v-for="day in week"
                    @click="pickDate(day)"
                    :key="day.number"
                    :class="{
                        ['--day-' + day.date.format('dd').toLowerCase()]: true,
                        '--picked': day.isPicked(valueArray),
                        '--ranged': (mode === 'range' && day.isWithInRange(valueArray)),
                        '--unavailable': day.unavailable,
                        '--today': day.today,
                        '--passive': day.passive,
                    }"
                    v-tooltip="{text: day.tooltip}"
                >
                    {{ day.number }}
                </li>
            </ul>
        </div>

        <div
            class="f-date-picker-select-months"
            v-else-if="activePicker === 'month'"
        >
            <f-scroll-pane
                ref="yearScrollPane"
                max-height="260px"
                :emit-bus-scroll="false"
                no-scroll-padding
            >
                <ul class="f-date-picker-select-month-list">
                    <li
                        v-for="year in years"
                        :key="year.year"
                        @click="pickYear(year)"
                        :class="{
                            '--picked': year.isPicked(valueArray),
                            '--unavailable': year.unavailable,
                            '--today': year.today,
                            '--passive': year.passive,
                        }"
                    >
                        {{ year.year }}
                    </li>
                </ul>
            </f-scroll-pane>
            <f-scroll-pane
                ref="monthScrollPane"
                max-height="260px"
                :emit-bus-scroll="false"
                no-scroll-padding
            >
                <ul class="f-date-picker-select-month-list">
                    <li
                        v-for="month in months"
                        :key="month.label"
                        @click="pickMonth(month)"
                        :id="month.label"
                        :class="{
                            '--picked': month.isPicked(valueArray),
                            '--unavailable': month.unavailable,
                            '--today': month.today,
                            '--passive': month.passive,
                        }"
                    >
                        {{ month.label }}
                    </li>
                </ul>
            </f-scroll-pane>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import {DatePickerModes, DatePickerSelectVariants, DatePickerTypes} from './config';
import {Day, Month, Year} from './helpers';
import FScrollPane from '../../other/scroll-pane/FScrollPane';
import FIconButton from '../buttons/FIconButton';

export default {
    name: 'f-date-picker-select',

    components: {
        FIconButton,
        FScrollPane,
    },

    props: {
        value: {},
        type: {
            type: String,
            default: DatePickerTypes.DAY,
        },
        disableMonthAndYearPicker: Boolean,
        yearRange: {
            type: Array,
            default: () => [1900, 2100],
        },
        showWeekNumbers: {
            type: Boolean,
            default: true,
        },
        hideWeekends: Boolean,
        allowNext: {
            type: Boolean,
            default: true,
        },
        allowPrevious: {
            type: Boolean,
            default: true,
        },
        // limit: { // TODO: check
        //     type: Array,
        //     default: () => [],
        // },
        showDate: {
            type: Object,
            default: () => moment().startOf('day'),
        },
        triggerShowChangeAtMount: Boolean,
        mode: {
            type: String,
            default: DatePickerModes.SINGLE,
        },
        apply: {
            type: Function,
            default: (days) => days,
        },
        inline: Boolean,
        variant: {
            type: String,
            default: DatePickerSelectVariants.DARK,
        },
    },

    data() {
        const valueArray = Array.isArray(this.value) ? this.value : [this.value];

        return {
            forceUpdate: 0,
            activePicker: this.type,
            pickedDate: (valueArray[0] ? moment(valueArray[0]) : null),
            activeShowDate: (valueArray[0] ? moment(valueArray[0]) : this.showDate),
        }
    },

    watch: {
        activePicker() {
            this.$nextTick(() => this.$emit('position'));
        },
    },

    mounted() {
        if (this.triggerShowChangeAtMount) {
            this.$emit('showChange', this.activeShowDate);
        }
        if (this.activePicker === DatePickerTypes.MONTH) {
            this.$nextTick(() => this.setScrollPanePosition());
        }
    },

    computed: {
        weekdays() {
            return moment.weekdaysMin(true);
        },

        years() {
            this.forceUpdate;

            let years = [];
            for (let n = this.yearRange[0]; n < this.yearRange[1]; n++) {
                years.push(new Year(n));
            }

            return years;
        },

        months() {
            this.forceUpdate;

            let months = [];
            for (let i = 0; i < 12; i++) {
                const month = new Month(moment(this.activeShowDate).set({'month': i}));
                months.push(month);
            }

            return months;
        },

        days() {
            this.forceUpdate;

            let days = [];
            let firstDay = moment(this.activeShowDate).date(1).day();
            const daysInMonth = this.activeShowDate.daysInMonth();

            // Add month days
            for (let i = 1; i <= daysInMonth; ++i) {
                const day = new Day(moment(this.activeShowDate).date(i));
                days.push(day);
            }

            // Passive days prepend
            if (firstDay === 0) {
                firstDay = 7;
            }

            for (let i = 0; i < firstDay; i++) {
                const day = new Day(moment(this.activeShowDate).date(1).subtract(i + 1, 'days'), true);
                days.unshift(day);
            }

            // Passive days prepend
            const passiveDaysPrepend = 42 - days.length;
            for (let i = 1; i <= passiveDaysPrepend; i++) {
                const day = new Day(moment(this.activeShowDate).add(1, 'months').date(i), true);
                days.push(day);
            }

            return this.apply(days);
        },

        groupedDays() {
            let weeks = [], i = -1;

            for (let y in this.days) {
                if (!Object.prototype.hasOwnProperty.call(this.days, y)) continue;

                if ((y + 1) % 7 === 1) {
                    i++;
                    weeks[i] = [];
                }

                weeks[i].push(this.days[y]);
            }

            return weeks;
        },

        valueArray() {
            if (Array.isArray(this.value)) {
                return this.value;
            }

            if (!this.value) {
                return [];
            }

            return [this.value];
        },
    },

    methods: {
        showPicker(type, emit = true) {
            if (this.disableMonthAndYearPicker || this.activePicker === type) {
                return;
            }

            this.activePicker = type;

            if (type === DatePickerTypes.MONTH) {
                this.$nextTick(() => this.setScrollPanePosition());
            }

            if (emit) {
                this.$emit('picker-change', type);
            }
        },

        previousMonth() {
            this.activeShowDate = moment(this.activeShowDate).add(-1, 'M');
            this.$emit('showChange', this.activeShowDate);
        },

        nextMonth() {
            this.activeShowDate = moment(this.activeShowDate).add(1, 'M');
            this.$emit('showChange', this.activeShowDate);
        },

        setShowDate(date, emit = true) {
            this.activeShowDate = moment(date);

            if (emit) {
                this.$emit('showChange', this.activeShowDate);
            }
        },

        pickDate(day) {
            if (day.unavailable) return;

            this.pickedDate = day.date;

            if (day.passive) {
                this[(day.number > 15 ? 'previous' : 'next') + 'Month']();
            }

            this.onChange('date');
        },

        pickYear(year) {
            this.activeShowDate = moment(this.activeShowDate).set({year: year.year});
            this.$emit('showChange', this.activeShowDate);
            this.pickedDate = this.activeShowDate;
            this.onChange('year');
        },

        pickMonth(month) {
            this.activeShowDate = moment(this.activeShowDate).set({month: month.date.month()});
            this.$emit('showChange', this.activeShowDate);

            if (this.type === 'month') {
                this.pickedDate = this.activeShowDate;
                this.onChange('month');
            } else {
                this.showPicker(DatePickerTypes.DAY);
            }
        },

        setScrollPanePosition() {
            let monthLi = this.$refs.monthScrollPane.$el.getElementsByClassName('--picked');
            if (monthLi.length === 0) {
                monthLi = this.$refs.monthScrollPane.$el.getElementsByClassName('--today');
            }
            if (monthLi[0]) {
                monthLi[0].scrollIntoView();
            }
            let yearLi = this.$refs.yearScrollPane.$el.getElementsByClassName('--picked');
            if (yearLi.length === 0) {
                yearLi = this.$refs.yearScrollPane.$el.getElementsByClassName('--today');
            }
            if (yearLi[0]) {
                yearLi[0].scrollIntoView();
            }
        },

        onChange(type) {
            this.$emit('picked', this.pickedDate, type);
            this.$nextTick(() => this.forceUpdate++);
        },
    },
}
</script>

<style lang="scss">
.f-date-picker-select {
    text-align: center;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;

    &.--inline {
        display: inline-block;
        width: auto;
    }

    .f-scroll-pane {
        width: 106px;
    }

    // Hide weekends
    &.--hide-weekends {
        li.--day-su, li.--day-sa {
            display: none;
        }
    }

    // Main day picker header
    .f-date-picker-select-header {
        background: $primary;
        width: 100%;
        line-height: 130%;
        position: relative;
        padding: 20px 10px;
        border-radius: 10px 10px 0 0;

        &, * {
            color: $primaryFront;
        }

        .f-date-picker-select-date-caption {
            flex: 1;
            font-weight: bold;

            &::first-letter {
                text-transform: uppercase;
            }

            &.--clickable {
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .f-date-picker-select-date-previous, .f-date-picker-select-date-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .f-date-picker-select-date-previous {
            left: 10px;
        }

        .f-date-picker-select-date-next {
            right: 10px;
        }
    }

    // Days container
    .f-date-picker-select-days {
        border-radius: 0 0 10px 10px;
        padding: 15px;

        // Day names header & week rows
        .f-date-picker-select-day-header, .f-date-picker-select-week {
            display: flex;
            width: 100%;
            list-style-type: none;
            margin: 0;
            padding: 0;

            > li {
                flex: 0 0 26px;
                padding: 10px 0;
                width: 26px;
                height: 26px;
                line-height: 4px;
            }

            .f-date-picker-select-week-number {
                margin-right: 6px;
                border-radius: 0;
            }

            &:first-child {
                .f-date-picker-select-week-number {
                    border-radius: 50% !important;
                }
            }
        }

        .f-date-picker-select-day-header {
            font-weight: bold;
            padding-bottom: 5px;
        }


        .f-date-picker-select-week {
            > li {
                border-radius: 50%;
                transition: background-color .1s, color .1s, border .1s;
                border: 1px solid transparent;
                position: relative;
            }

            .f-date-picker-select-week-number {
                border-radius: 2px;
            }

            > li:not(.f-date-picker-select-week-number) {
                margin-bottom: 1px;
                margin-top: 1px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    // Month select
    .f-date-picker-select-months {
        display: flex;
        padding: 7px 0 7px 0;
        border-radius: 10px;
        border-top: 10px solid $primary;

        .f-date-picker-select-month-list {
            padding: 4px;
            margin: 0;

            > li {
                margin-bottom: 2px;
                border-radius: 3px;
                padding: 8px;
                transition: background-color .1s, color .1s, border .1s;
                border: 1px solid transparent;

                &::first-letter {
                    text-transform: uppercase;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    // Month and day states
    .f-date-picker-select-months, .f-date-picker-select-days {
        font-size: 12px;

        .f-date-picker-select-month-list > li, .f-date-picker-select-week > li {
            &:not(.f-date-picker-select-week-number):not(.--unavailable) {
                cursor: pointer;
            }

            &:not(.f-date-picker-select-week-number):not(.--unavailable):hover {
                background-color: $secondary;
                color: $secondaryFront;
            }

            &:before {
                transition: background-color .1s;
            }

            &.--today {
                border: 1px solid rgba(black, .25);
            }

            &.--unavailable {
                cursor: default;
                opacity: .5;
                text-decoration: line-through;
            }

            &.--ranged:not(.--passive) {
                color: $light;

                &.--today {
                    border-color: brown;
                }

                &:before {
                    content: '';
                    position: absolute;
                    background-color: aliceblue;
                    height: 100%;
                    width: calc(100% + 8px);
                    left: -4px;
                    top: 0;
                    z-index: -2;
                }

                &:not(.--unavailable):hover {
                    background-color: $primary;
                }
            }

            &.--picked:not(.--passive) {
                background-color: $primary;
                color: $light;

                &.--today {
                    border-color: $primary;
                }

                &:hover {
                    background-color: darken($primary, 15%);
                }
            }

            &.--passive {
                opacity: .5;

                &:not(.--unavailable):hover {
                    background-color: rgba(black, .25);
                }

                &.--today {
                    border-color: rgba(black, .15);
                }

                &.--ranged {
                    &:before {
                        display: none;
                    }
                }

                &.--picked {
                    background-color: $primary;
                    color: white;
                }
            }
        }
    }

    // Themes
    &.--dark {
        background: $dark;
        color: $light;

        .f-date-picker-select-week .f-date-picker-select-week-number {
            color: rgba($light, .5);
            background: rgba($light, .1);
        }

        .f-date-picker-select-months, .f-date-picker-select-days {
            .f-date-picker-select-month-list > li, .f-date-picker-select-week > li {
                &.--today {
                    border-color: rgba($light, .4);
                }
            }
        }
    }

    &.--light {
        background: $light;
        color: $dark;

        .f-date-picker-select-week .f-date-picker-select-week-number {
            color: rgba($dark, .5);
            background: rgba($dark, .1);
        }
    }
}
</style>
