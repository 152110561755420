export default {
    props: {
        margin: {
            type: Number,
            default: 0,
        },
        target: {
            type: HTMLElement,
            required: true,
        },
        align: {
            type: String,
            default: 'top',
        },
        offsetLeft: {
            type: Number,
            default: 0,
        },
        offsetRight: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        options() {
            return {
                target: this.target,
                margin: this.margin,
                align: this.align,
                offsetLeft: this.offsetLeft,
                offsetRight: this.offsetRight,
            };
        },
    },
}
