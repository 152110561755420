export const PopupVariants = {
    DARK: 'dark',
    LIGHT: 'light',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',

    /**
     * @deprecated
     */
    DEFAULT: 'default',
    /**
     * @deprecated
     */
    ALTERNATIVE: 'alternative',
}
