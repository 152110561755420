/**
 * Methods for handling form request error handling.
 */
export default {
    methods: {
        clientFromId(id) {
            for (let i in this.$store.state.$clientsCollection) {
                if ((this.$store.state.$clientsCollection[i].id + '') === (id + '')) {
                    return this.$store.state.$clientsCollection[i];
                }
            }
        },

        clientFromGrippId(id) {
            for (let i in this.$store.state.$clientsCollection) {
                if (this.$store.state.$clientsCollection[i].gripp_id === (id + '')) {
                    return this.$store.state.$clientsCollection[i];
                }
            }
        },

        clientFromXeroId(id) {
            for (let i in this.$store.state.$clientsCollection) {
                if (this.$store.state.$clientsCollection[i].xero_id === (id + '')) {
                    return this.$store.state.$clientsCollection[i];
                }
            }
        },
    },
};
