var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'page',
        ..._vm.classNames,
        (_vm.loading ? '--loading' : null),
        (_vm.stretchFirstContainer ? '--stretch' : null),
    ],style:({
        backgroundColor: (_vm.skewedHeaderForeground ? _vm.skewedHeaderForeground : null),
        marginTop: _vm.marginTop,
    })},[_c('div',{class:[
            'page__pre',
            (_vm.header ? '--header' : null),
        ]},[(_vm.header)?_c('f-container',{attrs:{"max-width":_vm.preMaxWidth}},[_c('header',{staticClass:"page__pre__header"},[_c('h1',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.header)}}),(_vm.headerBadge)?_c('f-badge',{attrs:{"inline":"","size":"large"}},[_vm._v(" "+_vm._s(_vm.headerBadge)+" ")]):_vm._e()],1),(_vm.actions && _vm.actions.length > 0)?_c('f-icon-button',{ref:"actions",attrs:{"icon":"ellipsis","selected":_vm.actionsOpen,"outlined":""},on:{"click":function($event){_vm.actionsOpen = true}}}):_vm._e(),_vm._t("headerPre")],2),_vm._t("pre")],2):_vm._t("pre")],2),(_vm.skewedHeaderForeground)?_c('skewed-section',{staticClass:"page__skewed-header",attrs:{"type":"top-right","foreground-color":_vm.skewedHeaderForeground}}):_vm._e(),_c('div',{class:['page__content']},[_vm._t("default")],2),(_vm.skewedFooterForeground)?_c('skewed-section',{staticClass:"page__skewed-footer",attrs:{"type":"bottom-left","background-color":"#222","foreground-color":_vm.skewedFooterForeground}}):_vm._e(),(_vm.actionsOpen && _vm.$refs.actions)?_c('f-popup',{attrs:{"target":_vm.$refs.actions.$el,"padding":"0","align":"bottom"},on:{"close":function($event){_vm.actionsOpen = false}}},[_c('f-popup-list',_vm._l((_vm.actions),function(action,i){return (action)?_c('f-popup-list-item',{key:i,attrs:{"icon":action.icon,"link":action.link,"disabled":action.disabled},on:{"click":function($event){_vm.actionsOpen = false; (action.onClick ? action.onClick($event) : null);}}},[_vm._v(" "+_vm._s(action.value)+" ")]):_vm._e()}),1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }