<template>
    <transition name="slide-left" appear>
        <div
            v-if="active"
            @click.prevent="onClick"
            :class="[
                'f-push-message',
                `--${variant}`,
                '--no-select'
            ]"
        >
            <f-flex gap="15px" cross-axis-align="center" main-axis-align="start">
                <f-icon :icon="icon"/>
                <div class="f-push-message__body">{{ body }}</div>
            </f-flex>
        </div>
    </transition>
</template>

<script>
import {PushMessageTypes} from './config';
import FFlex from '../../layout/flex/FFlex';
import {Icons} from '../../layout/icons/config';
import FIcon from '../../layout/icons/FIcon';

export default {
    name: 'f-push-message',

    components: {
        FIcon,
        FFlex,
    },

    props: {
        uid: {
            type: String,
            required: true,
        },
        variant: {
            type: String,
            default: PushMessageTypes.SUCCESS,
        },
        body: {
            required: true,
        },
        showFor: { // In milliseconds
            type: Number,
            default: 7500,
        },
    },

    computed: {
        icon() {
            switch (this.variant) {
                case PushMessageTypes.ERROR:
                case PushMessageTypes.WARNING:
                    return Icons.WARNING;
                case PushMessageTypes.SUCCESS:
                    return Icons.CHECK;
                default:
                    return Icons.NOTIFICATION_CIRCLE;
            }
        },
    },

    data() {
        return {
            active: true,
            timeOut: null,
        }
    },

    mounted() {
        this.timeOut = window.setTimeout(() => this.active = false, this.showFor);
    },

    methods: {
        isActive() {
            return this.active;
        },
        onClick() {
            window.clearTimeout(this.timeOut);
            this.active = false;
        },
    },
}
</script>

<style lang="scss">
.f-push-message {
    margin-top: 10px;
    padding: 20px;
    border-radius: 10px;
    width: 325px;
    box-shadow: 0 0 8px rgba($dark, .2);
    overflow: hidden;
    text-overflow: ellipsis;

    .f-icon {
        font-size: 30px;
    }

    &__body {
        flex: 1;
    }

    &.--success {
        background-color: $success;
        color: $successFront;
    }

    &.--info {
        background-color: $info;
        color: $infoFront;
    }

    &.--warning {
        background-color: $warning;
        color: $warningFront;
    }

    &.--error {
        background-color: $danger;
        color: $dangerFront;
    }
}
</style>
