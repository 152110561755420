<template>
    <span
        :class="[
            'f-text',
            `--${type}`,
            ...classNames,
        ]"
        :is="tag"
        :style="{ fontWeight, fontSize, lineHeight }"
    >
        <slot/>
    </span>
</template>

<script>
import ClassNameMixin from '../../../mixins/ClassNameMixin';
import {TextTypes} from './config';

export default {
    name: 'f-text',

    mixins: [
        ClassNameMixin,
    ],

    props: {
        tag: {
            type: String,
            default: 'span',
        },
        type: {
            type: String,
            default: TextTypes.SUBTITLE,
        },
        fontWeight: String,
        fontSize: String,
        lineHeight: String,
    },
}
</script>

<style lang="scss">
.f-text {
    margin: 0;
    padding: 0;
    vertical-align: middle;

    &.--body {
        font-size: 12px;
    }

    &.--subtitle {
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 600;

        @include media(mobile-down) {
            font-size: 10px;
        }
    }

    &.--header {
        font-family: helvetica-neue, sans-serif;
        font-weight: 300;
        line-height: 100%;

        em {
            font-family: cirka-regular, sans-serif;
            font-style: normal;
            font-size: 112%;
        }

        strong {
            font-weight: 600;
        }
    }
}
</style>
