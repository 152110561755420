<template>
    <f-placeholder-container :class="['f-entity-header-placeholder']">
        <entity-header>
            <template #image>
                <f-placeholder/>
            </template>
            <f-placeholder height="24px"/>
            <f-placeholder width="40%"/>
            <f-placeholder width="33%"/>
            <f-placeholder width="20%"/>
        </entity-header>
        <div v-if="!disableRows" class="f-entity-header-placeholder__rows">
            <f-placeholder width="100%" height="60px"/>
            <f-placeholder width="100%" height="60px"/>
            <f-placeholder width="100%" height="60px"/>
        </div>
    </f-placeholder-container>
</template>

<script>
import FPlaceholderContainer from './FPlaceholderContainer';
import EntityHeader from '../../../components/misc/entities/EntityHeader';
import FPlaceholder from './FPlaceholder';

export default {
    name: 'f-entity-header-placeholder',

    components: {
        FPlaceholder,
        EntityHeader,
        FPlaceholderContainer,
    },

    props: {
        disableRows: Boolean,
    },
}
</script>

<style lang="scss">
.f-entity-header-placeholder {
    .entity-header {
        background: transparent !important;
    }

    &__rows {
        margin-top: 40px;
    }
}
</style>
