<template>
    <div class="project-line-marking-field-item">
        <f-text-input
            :name="`${id}_description`"
            :value="description"
            readonly
            v-model="description"
            placeholder="Description"
            max-width="400px"
            :class-name="dMarking === 'hide' ? '--hidden' : null"
        />

        <f-dropdown
            v-model="dMarking"
            :name="`${id}_marking`"
            :options="options"
            popup-width="100px"
            max-width="120px"
            @change="onMarkingChange"
            resetable
            popup-variant="light"
        />
    </div>
</template>

<script>
import FTextInput from '../../../f-components/form-controls/text/FTextInput';
import FDropdown from '../../../f-components/form-controls/dropdowns/FDropdown';

export default {
    name: 'project-line-marking-field-item',

    /**
     * Child components.
     */
    components: {
        FDropdown,
        FTextInput,
    },

    /**
     * Component properties.
     */
    props: {
        id: {},
        description: {},
        marking: {},
    },

    /**
     * Component data variables.
     */
    data() {
        return {
            dMarking: this.marking,
            options: [
                {key: 'hide', value: 'hide'},
                {key: 'purchase', value: 'purchase'},
            ],
        }
    },

    /**
     * Component methods.
     */
    methods: {
        onMarkingChange(value) {
            this.$emit('change', value);
        },

        getData() {
            if (!this.dMarking) return null;

            return {
                id: this.id,
                marking: this.dMarking,
            }
        },
    },
}
</script>

<style lang="scss">
.project-line-marking-field-item {
    display: flex;

    .--hidden input {
        text-decoration: line-through;
    }

    .f-text-input {
        flex: 1;
        margin-right: 5px;
    }

    .f-select-input.f-text-input {
        flex: 0 0 140px;
    }
}
</style>
