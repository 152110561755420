<template>
    <li
        :class="['main-menu-item']"
        ref="link"
    >
        <router-link
            v-if="link"
            :to="link"
            :class="[
                'main-menu-item__link',
                (forceHover ? '--force-hover' : null)
            ]"
            v-tooltip="{
                text: (noTooltip === false ? title : null),
                align: 'right',
                className: 'main-menu-tooltip'
            }"
        >
            <div class="main-menu-item__link__icon">
                <f-icon :icon="icon">
                    <f-badge v-if="badge">
                        {{ badge }}
                    </f-badge>
                </f-icon>
            </div>
            <div class="main-menu-item__link__body">
                <div
                    class="main-menu-item__link__body__title"
                    v-html="title"
                />
                <div
                    class="main-menu-item__link__body__subtitle"
                    v-html="subtitle"
                />
            </div>
        </router-link>
        <a
            v-else
            href="#"
            :class="[
                'main-menu-item__link',
                (forceHover ? '--force-hover' : null)
            ]"
            v-tooltip="{
                text: (noTooltip === false ? title : null),
                align: 'right',
                className: 'main-menu-tooltip'
            }"
            @click.stop="$emit('click', $event)"
        >
            <div class="main-menu-item__link__icon">
                <f-icon :icon="icon">
                    <f-badge v-if="badge">
                        {{ badge }}
                    </f-badge>
                </f-icon>
            </div>
            <div class="main-menu-item__link__body">
                <div
                    class="main-menu-item__link__body__title"
                    v-html="title"
                />
                <div
                    class="main-menu-item__link__body__subtitle"
                    v-html="subtitle"
                />
            </div>
        </a>
    </li>
</template>

<script>
import FIcon from '../../f-components/layout/icons/FIcon';
import FBadge from '../../f-components/data/badge/FBadge';

export default {
    name: 'main-menu-item',

    components: {
        FBadge,
        FIcon,
    },

    props: {
        link: String,
        icon: {
            type: String,
            default: 'mail',
        },
        title: String,
        subtitle: String,
        noTooltip: Boolean,
        badge: [String, Number, Boolean],
    },

    data() {
        return {
            forceHover: false,
            subMenuOpen: false,
        }
    },
}
</script>

<style lang="scss">
.main-menu-item {
    width: 100%;
    text-align: left;
    position: relative;
    border-bottom: 1px solid $darkAlternative;

    &, * {
        color: $light;
    }

    &:first-child {
        border-top: 1px solid $darkAlternative;
    }

    &__link {
        padding: 15px 0 15px;
        display: inline-flex;
        text-decoration: none;
        align-items: center;

        &, &:hover, &:visited, &:active {
            color: $darkFront;
        }

        &:before {
            transition: border-left-color .2s, left .1s;
            border-left: 4px solid rgba($primary, 0);
            content: '';
            position: absolute;
            height: 100%;
            top: 0;
            left: -4px;
        }

        &:not(.router-link-active):hover, &:not(.router-link-active).--force-hover {
            &:before {
                left: 0;
                border-left-color: rgba($primary, 1);
            }
        }

        &.router-link-active:before {
            border-left-color: rgba($primary, 1);
            left: 0;
        }

        &__icon {
            width: $menuWidth;
            text-align: center;

            .f-icon {
                font-size: 20px;

                @include media(mobile-down) {
                    font-size: 25px;
                }

                .f-badge {
                    right: -9px;
                    top: -7px;
                }
            }
        }

        &__body {
            display: none;

            @include media(mobile-down) {
                display: block;
            }

            &__title {
                font-size: 14px;
                white-space: nowrap;

                br {
                    display: none;
                }
            }

            &__subtitle {
                font-size: 12px;
                opacity: .5;
                white-space: nowrap;

                br {
                    display: none;
                }
            }
        }
    }
}

@include media(mobile-down) {
    .main-menu-tooltip {
        display: none !important;
    }
}
</style>
