<template>
    <div :class="['nothing-found', `--align-${align}`, ...classNames]">
        <slot>No {{ name }} found.</slot>
    </div>
</template>

<script>
import ClassNameMixin from '../../mixins/ClassNameMixin';

export default {
    name: 'nothing-found',

    mixins: [
        ClassNameMixin,
    ],

    props: {
        name: {
            type: String,
            default: 'records',
        },
        align: {
            type: String,
            default: 'center',
        },
    },
}
</script>

<style lang="scss">
.nothing-found {
    font-style: italic;
    text-align: center;
    display: block;
    opacity: .5;
    margin-bottom: 10px;
}
</style>
