<template>
    <f-carousel
        ref="slider"
        stretch-height
        :class-name="[
            'request-leave-modal-content',
            (loading ? '--loader --loading' : undefined),
        ]"
        navigation-type="none"
    >
        <f-carousel-slide id="detailsSlide" ref="detailsSlide">
            <details-slide
                @next="onDetailsSlideNext"
                @typeChange="(type) => $emit('input', type)"
                :data="formData"
                :admin="admin"
                :mode="(editData ? 'edit' : 'create')"
            />
        </f-carousel-slide>

        <f-carousel-slide id="daysSlide" ref="daysSlide">
            <select-days-slide
                @back="onSelectDaysSlideBack"
                @submit="onSubmit"
                :data="formData"
                :admin="admin"
                :mode="(editData ? 'edit' : 'create')"
            />
        </f-carousel-slide>
    </f-carousel>
</template>

<script>
import FCarousel from '../../../f-components/navigation/carousels/FCarousel';
import FCarouselSlide from '../../../f-components/navigation/carousels/FCarouselSlide';
import SelectDaysSlide from './components/SelectDaysSlide';
import DetailsSlide from './components/DetailsSlide';
import APIEndpoints from '../../../config/APIEndpoints';
import APIDataStore from '../../../services/APIDataStore';
import APIDataStoreResources from '../../../config/APIDataStoreResources';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';

export default {
    name: 'request-leave-modal-content',

    components: {
        DetailsSlide,
        SelectDaysSlide,
        FCarouselSlide,
        FCarousel,
    },

    props: {
        admin: Boolean,
        editData: Object,
    },

    data() {
        let formData = {
            selectedDateHours: {},
            dates: [],
            type: 'holiday',
            reason: '',
            userId: null,
        };

        if (this.editData) {
            formData = {
                selectedDateHours: this.editData.requested_dates,
                dates: Object.keys(this.editData.requested_dates),
                type: this.editData.type,
                reason: this.editData.reason,
                userId: this.editData.user_id,
            };
        }

        return {
            loading: false,
            formData,
        }
    },

    methods: {
        mergeData(data) {
            this.formData = {
                ...this.formData,
                ...data,
            };
        },

        onDetailsSlideNext(data) {
            this.mergeData(data);
            this.$refs.slider.slideTo('daysSlide');
        },

        onSelectDaysSlideBack(data) {
            this.mergeData(data);
            this.$refs.slider.slideTo('detailsSlide');
        },

        onSubmit(data) {
            this.mergeData(data);
            this.loading = true;

            if (this.admin) {
                window.axios
                    .post((this.editData ? APIEndpoints.ADMIN_LEAVE_REQUEST_EDIT : APIEndpoints.ADMIN_LEAVE_REQUEST_CREATE), {
                        id: (this.editData ? this.editData.id : null),
                        reason: this.formData.reason,
                        dates: this.formData.selectedDateHours,
                        type: this.formData.type,
                        user_id: this.formData.userId,
                    })
                    .then(response => this.onRequestSuccess(response.data))
                    .catch(() => this.loading = false);
            } else {
                window.axios
                    .post(APIEndpoints.LEAVE_REQUEST, {
                        reason: this.formData.reason,
                        dates: this.formData.selectedDateHours,
                        type: this.formData.type,
                    })
                    .then(response => this.onRequestSuccess(response.data))
                    .catch(() => this.loading = false);
            }
        },

        onRequestSuccess() {
            this.loading = false;

            if (this.admin) {
                EventBus.$emit(GlobalEvents.PUSH_MESSAGE, (this.editData ? 'Leave requested updated.' : 'Leave requested created.'));
                APIDataStore.fetch(APIDataStoreResources.ADMIN_LEAVE_REQUESTS);
            } else {
                EventBus.$emit(GlobalEvents.PUSH_MESSAGE, 'Leave requested. You will get a response very soon.');
                APIDataStore.fetch(APIDataStoreResources.LEAVE);
            }

            this.$emit('close');
        },
    },
}
</script>

<style lang="scss">
.request-leave-modal-content {
    .f-form {
        margin: 0;
    }
}
</style>
