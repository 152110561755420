var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f-dropdown',_vm._b({ref:"validatable",attrs:{"class-name":[
        'f-client-dropdown',
        ..._vm.classNames,
    ],"pre-icon":"tag","options":_vm.options},scopedSlots:_vm._u([{key:"selectedOption",fn:function(selectedOptionProps){return [(selectedOptionProps.selectedOption)?_c('div',{staticClass:"f-dropdown__selected-option f-discipline-dropdown__selected-option"},[_vm._l((selectedOptionProps.selectedOption.parents),function(department){return _c('span',{key:department.id,staticClass:"f-discipline-dropdown__selected-option__department"},[_vm._v(" "+_vm._s(department.name)+" ")])}),_c('span',{staticClass:"f-discipline-dropdown__selected-option__discipline"},[_vm._v("  "+_vm._s(selectedOptionProps.selectedOption.value)+" ")])],2):_vm._e()]}},{key:"options",fn:function(optionsProps){return _vm._l((optionsProps.options),function(option,index){return _c('li',{key:option.key,class:[
                'f-discipline-dropdown__option',
                (optionsProps.selectedIndex === index ? '--selected' : null),
                (optionsProps.selectedOption && optionsProps.selectedOption.key === option.key ? '--chosen' : null),
            ],on:{"click":function($event){$event.preventDefault();return optionsProps.onSelect(option, index)}}},[_vm._l((option.parents),function(department){return _c('span',{key:department.id,staticClass:"f-discipline-dropdown__option__department"},[_vm._v(" "+_vm._s(department.name)+" ")])}),_c('span',{staticClass:"f-discipline-dropdown__option__discipline"},[_vm._v("  "+_vm._s(option.value)+" ")])],2)})}}]),model:{value:(_vm.dropdownValue),callback:function ($$v) {_vm.dropdownValue=$$v},expression:"dropdownValue"}},'f-dropdown',_vm.$props,false))
}
var staticRenderFns = []

export { render, staticRenderFns }