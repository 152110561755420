var render = function render(){var _vm=this,_c=_vm._self._c;return _c('img',{class:[
        'f-image-loader',
        (_vm.isLoading ? '--loading' : null),
        (_vm.cover ? '--cover' : null),
        (_vm.transitionSize ? '--transition-size' : null),
    ],style:({
        height: _vm.height,
        maxHeight: _vm.maxHeight,
        width: _vm.width,
        opacity: _vm.opacity,
    }),attrs:{"src":_vm.srcComputed,"alt":_vm.alt},on:{"load":_vm.onLoad,"error":_vm.onError}})
}
var staticRenderFns = []

export { render, staticRenderFns }