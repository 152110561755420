var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasPermission(_vm.Permissions.ADMIN_GALLERIES))?_c('page',{attrs:{"class-name":"admin-galleries-page","loading":_vm.loading,"title":['Admin', 'Picture Galleries'],"header":"Picture galleries","header-badge":(_vm.fetchedData ? _vm.fetchedData.total : null),"pre-max-width":"800px"},scopedSlots:_vm._u([{key:"pre",fn:function(){return [_c('f-breadcrumbs',[_c('f-breadcrumb',[_vm._v("Admin")]),_c('f-breadcrumb',[_vm._v(" Picture Galleries ")]),_c('f-breadcrumb',[_vm._v("Overview")])],1)]},proxy:true}],null,false,2137625018)},[_c('f-container',{attrs:{"max-width":"800px"}},[_c('f-filter',{attrs:{"show-active-filters":"","active-filters":_vm.activeFilters,"filter-casts":{
                query: 'query'
            }}},[_c('f-filter-row',[_c('f-filter-item',[_c('f-text-input',{attrs:{"placeholder":"Search…","pre-icon":"magnifier","class-name":"--align-center","max-width":"300px","resetable":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1)],1),(_vm.fetchedData)?_c('f-table',{ref:"table",attrs:{"empty":(!_vm.fetchedData || _vm.items.length === 0),"rows-hoverable":"","allow-horizontal-scroll":""},scopedSlots:_vm._u([{key:"pre",fn:function(){return [(_vm.fetchedData)?_c('f-pagination',{attrs:{"current-page":_vm.currentPage,"page-count":_vm.lastPage},on:{"navigate":_vm.onPaginationNavigate}}):_vm._e()]},proxy:true},{key:"head",fn:function(){return [_c('f-table-header-row',{attrs:{"class-name":"--no-wrap"}},[_c('f-table-header-cell',{attrs:{"width":"40px"}}),_c('f-table-header-cell',{staticStyle:{"min-width":"160px"}},[_vm._v(" Title ")]),_c('f-table-header-cell',{attrs:{"align":"center","width":"120px"}},[_vm._v(" On timeline ")]),_c('f-table-header-cell',{attrs:{"align":"center","width":"100px"}},[_vm._v(" Published ")])],1)]},proxy:true},{key:"post",fn:function(){return [(_vm.fetchedData)?_c('f-pagination',{attrs:{"current-page":_vm.currentPage,"page-count":_vm.lastPage,"bottom":""},on:{"navigate":_vm.onPaginationNavigate}}):_vm._e()]},proxy:true}],null,false,1759030036)},_vm._l((_vm.items),function(row){return _c('f-table-row',{key:row.id,attrs:{"actions":[{
                    icon: 'trash2',
                    value: 'Delete',
                    onClick: () => _vm.deleteGallery(row.id, row.title),
                }, {
                    icon: 'link',
                    value: 'Deeplink',
                    disabled: row.on_timeline && !row.published_at,
                    onClick: () => _vm.openDeeplink('https://riviera.codedazur.io/' + row.deeplink),
                }]},on:{"click":function($event){return _vm.$router.push(`/admin/galleries/edit/${row.id}`)}}},[_c('f-table-cell',[_c('entity-avatar',{attrs:{"type":"square","name":row.title,"src":row.cover_image_url,"size":"xlarge"}})],1),_c('f-table-title-cell',{scopedSlots:_vm._u([{key:"sub",fn:function(){return [_vm._v(" "+_vm._s(row.subtitle)+" "),_c('div',{staticClass:"admin-galleries-page__row__count"},[_c('f-icon',{attrs:{"icon":"picture"}}),_vm._v(" "+_vm._s(row.pictures_count)+" picture"+_vm._s(row.pictures_count !== 1 ? 's' : '')+" ")],1),_c('div',{staticClass:"admin-galleries-page__row__date"},[_c('f-icon',{attrs:{"icon":"calendar-empty"}}),_vm._v(" "+_vm._s(row.date_visual)+" ")],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(row.title)+" ")]),_c('f-table-cell',{attrs:{"align":"center"}},[(row.on_timeline)?_c('f-icon',{attrs:{"icon":(row.on_timeline ? 'check' : 'cross')}}):_vm._e(),(row.on_timeline && row.timeline.is_pinned)?_c('f-icon',{staticStyle:{"margin-left":"5px"},attrs:{"icon":"pushpin"}}):_vm._e()],1),_c('f-table-cell',{attrs:{"align":"center"}},[(row.published_at)?_c('f-chip',{attrs:{"variant":"success"}},[_vm._v("Published")]):(row.on_timeline)?_c('f-chip',[_vm._v("Not published")]):_vm._e()],1)],1)}),1):(!_vm.fetchedData && _vm.loading)?_c('f-table-placeholder'):_vm._e()],1),_c('entity-action-button',{attrs:{"permission":_vm.Permissions.ADMIN_GALLERIES,"route":"/admin/galleries/create"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }