<template>
    <f-icon-button
        v-if="tooltipText"
        class-name="status-icon"
        :icon="type"
        :variant="type"
        v-tooltip="{text: tooltipText, variant: type, wrap: true}"
        size="small"
        no-parent-hover
    />
</template>

<script>
import FIconButton from '../../../f-components/form-controls/buttons/FIconButton';
import {StatusIconTypes} from './config';

export default {
    name: 'status-icon',

    components: {
        FIconButton,
    },

    props: {
        type: {
            type: String,
            default: StatusIconTypes.INFO,
        },
        tooltip: {
            type: [Array, String],
            required: true,
            default: () => {
                return [];
            },
        },
    },

    computed: {
        // icon() {
        //     switch (this.type) {
        //         case StatusIconTypes.WARNING:
        //         case StatusIconTypes.DANGER:
        //             return Icons.WARNING;
        //         case StatusIconTypes.INFO:
        //         default:
        //             return Icons.NOTIFICATION_CIRCLE;
        //     }
        // },

        tooltipText() {
            if (Array.isArray(this.tooltip)) {
                const infos = this.tooltip.filter(n => n);
                if (infos.length === 0) {
                    return null;
                }

                return infos.length === 1 ? infos[0] : '<ul><li>' + infos.join('</li><li>') + '</li></ul>';
            }
            return this.tooltip;
        },
    },
}
</script>

<style lang="scss">
.status-icon {
    cursor: default !important;
    
    .f-icon {
        font-size: 17px !important;
        font-weight: bold;

        &:before {
            font-family: cirka-regular, sans-serif;
            padding-bottom: 2px;
            display: block;
        }
    }

    .f-icon-warning:before {
        content: '!';
    }

    .f-icon-info:before {
        content: 'i'
    }
}
</style>
