import APIEndpoints from './APIEndpoints';

/**
 * Duration and interval are defined in seconds.
 *
 * 60       = 1 min
 * 300      = 5 min
 * 3600     = 1 hour
 * 7200     = 2 hours
 * 86400    = 1 day
 *
 * @type {Object.<APIDataStoreResource>}
 */
const APIDataStoreResources = {
    MEETING_ROOM: {
        id: 'meetingRoom',
        endpoint: APIEndpoints.MEETING_ROOM,
        cacheDuration: 120,
        refreshInterval: 120,
    },
    MEETINGS_ROOMS_TODAY: {
        id: 'meetingRoomsToday:office',
        endpoint: APIEndpoints.MEETING_ROOMS_TODAY,
        cacheDuration: 30,
        refreshInterval: 60,
    },
    USER: {
        id: 'user',
        endpoint: APIEndpoints.USER,
        cacheDuration: 240,
        refreshInterval: 300,
    },
    ABSENTEES_TODAY: {
        id: 'absenteesToday',
        endpoint: APIEndpoints.ABSENTEES_TODAY,
        cacheDuration: 120,
        refreshInterval: 120,
    },
    AVAILABLE_TRAFFICERS: {
        id: 'availableTrafficers',
        endpoint: APIEndpoints.AVAILABLE_TRAFFICERS,
        cacheDuration: 120,
        refreshInterval: 120,
    },
    USERS_CURRENTLY_AT_OFFICE: {
        id: 'currentlyAtOffice',
        endpoint: APIEndpoints.CURRENTLY_AT_OFFICE,
        cacheDuration: 1800,
    },
    CALENDAR_TODAY: {
        id: 'calendar',
        endpoint: APIEndpoints.CALENDAR_TODAY,
        cacheDuration: 60,
        refreshInterval: 120,
    },
    ADMIN_LEAVE_REQUESTS: {
        id: 'adminLeaveRequests',
        endpoint: APIEndpoints.ADMIN_LEAVE_REQUESTS,
        cacheDuration: 60,
        refreshInterval: 120,
    },
    PROJECTS: {
        id: 'projects',
        paginated: true,
        endpoint: APIEndpoints.PROJECTS,
    },
    PROJECT_FAVOURITES: {
        id: 'projectFavourites',
        endpoint: APIEndpoints.PROJECT_FAVOURITES,
        cacheDuration: 3600,
    },
    PUBLIC_HOLIDAYS: {
        id: 'publicHolidays',
        endpoint: APIEndpoints.PUBLIC_HOLIDAYS,
        cacheDuration: 7200,
    },
    NOTIFICATIONS: {
        id: 'notifications',
        endpoint: APIEndpoints.NOTIFICATIONS,
        cacheDuration: 120,
        refreshInterval: 120,
    },
    LEAVE: {
        id: 'leave',
        endpoint: APIEndpoints.LEAVE,
        cacheDuration: 300,
        refreshInterval: 300,
    },
    // Collections
    USERS_COLLECTION: {
        id: 'usersCollection',
        endpoint: APIEndpoints.COLLECTION_USERS,
        cacheDuration: 3600,
    },
    FREELANCERS_COLLECTION: {
        id: 'freelancersCollection',
        endpoint: APIEndpoints.COLLECTION_FREELANCERS,
        cacheDuration: 3600,
    },
    DEPARTMENTS_AND_DISCIPLINES_COLLECTION: {
        id: 'departmentsAndDisciplinesCollection',
        endpoint: APIEndpoints.COLLECTION_DEPARTMENTS_AND_DISCIPLINES,
        cacheDuration: 3600,
    },
    CLIENTS_COLLECTION: {
        id: 'clientsCollection',
        endpoint: APIEndpoints.COLLECTION_CLIENTS,
        cacheDuration: 3600,
        refreshInterval: 3600,
    },
    OFFICES_COLLECTION: {
        id: 'officesCollection',
        endpoint: APIEndpoints.COLLECTION_OFFICES,
        cacheDuration: 86400,
    },
}

export default APIDataStoreResources;
