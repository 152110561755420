var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.active)?_c('f-portal',{attrs:{"reference":"tooltip"}},[_c('transition',{attrs:{"name":"popup","appear":""}},[_c('div',{ref:"positionElement",class:[
                'f-tooltip',
                '--no-select',
                `--${_vm.currentAlign}`,
                `--${_vm.currentAlignHorizontal}`,
                (_vm.options.touch ? '--touch' : null),
                (_vm.options.wrap ? '--wrap' : null),
                `--${_vm.options.variant}`,
                _vm.options.className,
            ],style:({
                top: `${_vm.pos.top}px`,
                left: `${_vm.pos.left}px`,
                maxWidth: `${_vm.options.maxWidth}px`,
                textAlign: _vm.options.textAlign,
            })},[_c('div',{staticClass:"f-tooltip__content",domProps:{"innerHTML":_vm._s(_vm.value)}})])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }