<template>
    <page
        class-name="authentication-page"
        title="Login"
        @authenticated="$router.push('/')"
        :skewed-header-foreground="null"
        skewed-footer-foreground="#f3f3f3"
        margin-top="0"
    >
        <f-center>
            <div>
                <logo/>

                <f-inline-message
                    v-if="error"
                    id="loginError"
                    variant="error"
                    :closable="false"
                >
                    {{ error }}
                </f-inline-message>

                <f-button
                    :href="`${API_DOMAIN}/oauth/gsuite?redirect_domain=${redirectDomain}`"
                    tag="a"
                    value="Login with"
                >
                    <template #post>
                        <img alt="Google Workspace" src="/img/google-workspace-logo.svg"/>
                    </template>
                </f-button>
            </div>
        </f-center>
    </page>
</template>

<script>
import APIDataStore from '../../../services/APIDataStore';
import Page from '../Page';
import FCenter from '../../../f-components/layout/center/FCenter';
import Logo from '../../misc/Logo';
import FInlineMessage from '../../../f-components/notice/inline-message/FInlineMessage';
import FButton from '../../../f-components/form-controls/buttons/FButton';
import {API_DOMAIN} from '../../../config/app';

export default {
    name: 'authentication-page',

    components: {
        FButton,
        FInlineMessage,
        Logo,
        FCenter,
        Page,
    },

    mounted() {
        APIDataStore.clearAllIntervals();
    },

    data() {
        return {
            error: this.$route.query.error,
            API_DOMAIN: API_DOMAIN,
            redirectDomain: encodeURIComponent(window.location.origin),
        }
    },
}
</script>

<style lang="scss">
.page-auth {
    // TODO: bit hacky needs refac
    .page-footer__pre {
        padding-top: 0;

        .f-text {
            display: none;
        }
    }
}

.authentication-page {
    .f-inline-message {
        max-width: 400px;
        margin-bottom: 25px;
    }

    .logo {
        margin: 25px auto;
        height: 85px;
    }

    .f-button {
        img {
            height: 16px;
            filter: brightness(0);
            padding: 0 0 0 10px;
            transition: filter .1s;
        }
    }
}

@media (hover: hover) {
    .authentication-page .f-button:hover {
        img {
            filter: brightness(0) invert(1);
        }
    }
}
</style>
