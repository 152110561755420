var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isEnabled)?_c('section',{class:[
        'widget',
        '--no-select',
        `--theme--${_vm.theme}`,
        _vm.widgetSize,
        ..._vm.classNames,
    ]},[_c('f-panel',{attrs:{"title":_vm.title,"title-help":_vm.titleHelp,"heading":_vm.header,"content-class-name":_vm.contentClassName,"heading-badge":_vm.badge},scopedSlots:_vm._u([{key:"post-title",fn:function(){return [_vm._t("post-title")]},proxy:true},{key:"head",fn:function(){return [_vm._t("head")]},proxy:true},{key:"post",fn:function(){return [_vm._t("header-post")]},proxy:true}],null,true)},[_vm._t("default")],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }