<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
    <f-theme-provider
        :class-name="['meeting-room-slide']"
        variant="dark"
    >
        <div class="meeting-room-slide__date-time">
            <f-text
                tag="div"
                type="header"
                font-weight="600"
                font-size="100px"
                v-html="time"
            />
            <f-text
                tag="div"
                type="header"
                font-size="80px"
                line-height="60px"
                v-html="date"
            />
        </div>

        <meeting-room-logo :number="number"/>

        <f-panel class-name="meeting-room-slide__panel">
            <f-flex
                direction="column"
                gap="30px"
                cross-axis-align="flex-start"
                main-axis-align="flex-start"
                class="meeting-room-slide__panel__current-state"
            >
                <bullet-point size="large">
                    <span v-if="currentMeeting">{{ bookedLabel }}</span>
                    <span v-else-if="available">{{ availableLabel }}</span>
                    <span v-else-if="nextMeeting && availableBookOptions.length === 0">
                        Almost starting
                    </span>
                </bullet-point>

                <div/>

                <!-- Empty: show book options -->
                <div v-if="availableBookOptions.length > 0">
                    Book for:
                </div>
                <f-flex
                    v-if="availableBookOptions.length > 0"
                    main-axis-align="flex-start"
                    gap="5px"
                >
                    <f-button
                        v-for="option in availableBookOptions"
                        :key="option"
                        @click="bookConfirm(option)"
                        @touchstart="bookConfirm(option)"
                        size="xlarge"
                    >
                        {{ option }} min
                    </f-button>
                </f-flex>

                <!-- Current meeting -->
                <f-text
                    v-if="currentMeeting"
                    v-html="getMeetingVisualTime(currentMeeting)"
                    type="header"
                    tag="div"
                    font-weight="600"
                    font-size="50px"
                />
                <f-text
                    v-if="currentMeeting"
                    v-text="currentMeeting.name"
                    type="header"
                    tag="div"
                    font-size="30px"
                />

                <div v-if="currentMeeting">
                    <entity-avatar-group
                        size="large"
                        :entities="attendees"
                    />
                </div>

                <div class="--flex-stretch"/>

                <f-button
                    v-if="currentMeeting"
                    @click="endCurrentMeetingConfirm(currentMeeting)"
                    @touchstart="endCurrentMeetingConfirm(currentMeeting)"
                    size="xlarge"
                    variant="primary"
                    value="End meeting early"
                />
            </f-flex>

            <template #post-content>
                <f-flex
                    direction="column"
                    gap="30px"
                    cross-axis-align="flex-start"
                    class-name="meeting-room-slide__panel__footer"
                >
                    <f-text v-if="nextMeeting">
                        Next meeting
                    </f-text>

                    <div v-if="nextMeeting">
                        <f-text font-size="30px" tag="div" type="header" font-weight="600">
                            {{ getMeetingVisualTime(nextMeeting) }}
                            <f-text v-if="getMeetingTimeVisualUntil(nextMeeting)" type="header">
                                ({{ getMeetingTimeVisualUntil(nextMeeting) }})
                            </f-text>
                        </f-text>
                        <f-text
                            v-if="nextMeeting"
                            font-size="24px"
                            font-weight="300"
                            type="header"
                            tag="div"
                            style="padding-top: 10px;"
                        >
                            {{ nextMeeting.name }}
                        </f-text>
                    </div>

                    <f-button
                        @click="$emit('to-calendar')"
                        size="xlarge"
                        value="View calendar"
                        variant="primary"
                    />
                </f-flex>
            </template>
        </f-panel>

        <day-time-table
            :clock="officeClock"
            ref="timeTable"
        >
            <li
                v-for="(meeting, i) in meetingRoom.data.calendar.items"
                :key="i"
                :style="getMeetingStyles(meeting)"
            />
        </day-time-table>
    </f-theme-provider>
</template>

<script>
import moment from 'moment-timezone';
import TimezoneMixin from "../../../../mixins/TimezoneMixin";
import APIDataStore from "../../../../services/APIDataStore";
import EventBus from "../../../../services/EventBus";
import APIEndpoints from "../../../../config/APIEndpoints";
import APIDataStoreResources from "../../../../config/APIDataStoreResources";
import DayTimeTable from "./DayTimeTable";
import MeetingRoomLogo from "./MeetingRoomLogo";
import {GlobalEvents} from '../../../../config/Events';
import LoadingMixin from '../../../../mixins/LoadingMixin';
import FButton from '../../../../f-components/form-controls/buttons/FButton';
import FThemeProvider from '../../../../f-components/layout/theme/FThemeProvider';
import {ButtonSizes} from '../../../../f-components/form-controls/buttons/config';
import FPanel from '../../../../f-components/other/panel/FPanel';
import FFlex from '../../../../f-components/layout/flex/FFlex';
import BulletPoint from '../../../misc/BulletPoint';
import FText from '../../../../f-components/layout/text/FText';
import EntityAvatarGroup from '../../../misc/entities/EntityAvatarGroup';

/**
 * Kinda old JS code, could be way nicer.
 */
export default {
    name: 'meeting-room-slide',

    components: {
        EntityAvatarGroup,
        FText,
        BulletPoint,
        FFlex,
        FPanel,
        FThemeProvider,
        FButton,
        MeetingRoomLogo,
        DayTimeTable,
    },

    mixins: [
        TimezoneMixin,
        LoadingMixin,
    ],

    props: {
        roomId: {
            type: Number,
            required: true,
        },
        office: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            available: false,
            almostStarting: false,
            availableLabel: 'Available',
            bookedLabel: 'Booked',
            availableBookOptions: [],
            currentMeeting: null,
            nextMeeting: null,
            time: '',
            date: '',
            isFirstTimeUpdate: true,
        }
    },

    computed: {
        meetingRoom() {
            return this.$store.state.$meetingRoom;
        },
        number() {
            return this.meetingRoom.data.name.replace(/.* ([0-9]+)/g, '$1');
        },
        attendees() {
            if (this.currentMeeting.attendees && this.currentMeeting.attendees.length > 0) {
                return this.currentMeeting.attendees
                    .filter((attendee) => attendee.status !== 'declined')
                    .map((attendee) => ({
                        id: attendee.email_address,
                        name: attendee.name ?? attendee.email_address,
                        image: attendee.profile_image,
                    }))
            }
            return [];
        },
    },

    watch: {
        meetingRoom() {
            this.renderStoreData();
        },
    },

    created() {
        this.$nextTick(this.updateTime);

        APIDataStore.check(APIDataStoreResources.MEETINGS_ROOMS_TODAY, {
            params: {
                office: this.office,
            },
        });

        EventBus.$on(`ds.meetingRoom:${this.roomId}.update`, this.updateTime);
        EventBus.$on(GlobalEvents.MINUTE_CHANGE, this.updateTime);
    },

    beforeDestroy() {
        EventBus.$off(`ds.meetingRoom:${this.roomId}.update`, this.updateTime);
        EventBus.$off(GlobalEvents.MINUTE_CHANGE, this.updateTime);
    },

    methods: {
        updateTime() {
            const now = moment.tz(this.timezone);

            if (this.officeClock === '12') {
                this.time = now.format('HH') + '<span class="--colon">:</span>' + now.format('mm [<span class="--am-pm">]A<[/span>]');
            } else {
                this.time = now.format('HH') + '<span class="--colon">:</span>' + now.format('mm');
            }
            this.date = now.format('[<em>]MMMM Do[</em>]'); // YYYY

            if (this.$refs.timeTable) {
                if (now.hours() > 4) {
                    const hrHeight = (this.$refs.timeTable.$el.scrollHeight / 24);

                    this.scrollTo((hrHeight * (now.hours() - 2)), this.isFirstTimeUpdate);
                    this.isFirstTimeUpdate = false;
                } else {
                    this.scrollTo(0, this.isFirstTimeUpdate);
                    this.isFirstTimeUpdate = false;
                }
            }

            this.renderStoreData();

            this.isFirstTimeUpdate = false;
        },

        scrollTo(i) {
            if (this.$refs.timeTable.$el) {
                this.$refs.timeTable.$el.scrollTop = i;
            }
        },

        renderStoreData() {
            this.currentMeeting = null;
            this.nextMeeting = null;
            this.available = false;
            this.almostStarting = false;
            this.availableBookOptions = [];

            const now = moment.tz(this.timezone);

            for (let i in this.meetingRoom.data.calendar.items) {
                const meeting = this.meetingRoom.data.calendar.items[i];

                if (!meeting.start || !meeting.end || meeting.is_all_day) {
                    continue;
                }

                const start = moment.tz(meeting.start.date, meeting.start.timezone).tz(this.timezone);
                const end = moment.tz(meeting.end.date, meeting.end.timezone).tz(this.timezone);

                // console.debug(' > ' + meeting.name, start.format('HH:mm'), '-', end.format('HH:mm'));

                if (start < now && end > now && !this.currentMeeting) {
                    this.currentMeeting = meeting;
                } else if (start > now && end > now && !this.nextMeeting) {
                    this.nextMeeting = meeting;
                }
            }

            // Available
            if (!this.currentMeeting && (!this.nextMeeting || this.getMeetingMinutesUntil(this.nextMeeting) > 9)) {
                this.available = true;

                if (!this.nextMeeting) {
                    if (now.hours() > 12 && now.hours() < 18) {
                        this.availableLabel = 'Available all afternoon';
                    } else if (now.hours() >= 18) {
                        this.availableLabel = 'Available all night';
                    } else {
                        this.availableLabel = 'Available all day';
                    }
                } else {
                    const end = moment.tz(this.nextMeeting.start.date, this.nextMeeting.start.timezone).tz(this.timezone);
                    this.availableLabel = 'Available until ' + end.format('HH:mm');
                }

                // Add available book options
                let i = 0;
                let _c = true;
                const max = 60;
                const min = (this.nextMeeting ? this.getMeetingMinutesUntil(this.nextMeeting) : max);

                while (_c) {
                    i += 15;

                    if (i > min || i > max) {
                        _c = false;
                        continue;
                    }

                    this.availableBookOptions.push(i);
                }
            }
            // Available + almost starting
            else if (!this.currentMeeting && this.getMeetingMinutesUntil(this.nextMeeting) <= 9) {
                this.almostStarting = true;
            }
            // Booked
            else if (this.currentMeeting) {
                this.bookedLabel = 'Booked';
            }
        },

        getMeetingVisualTime(meeting) {
            const start = moment.tz(meeting.start.date, meeting.start.timezone);
            const end = moment.tz(meeting.end.date, meeting.start.timezone);
            const format = this.getTimeFormat(this.officeClock);

            return start.tz(this.timezone).format(format) + ' – ' + end.tz(this.timezone).format(format);
        },

        getMeetingMinutesUntil(meeting) {
            const start = moment.tz(meeting.start.date, meeting.start.timezone);
            const now = moment.tz(this.timezone);

            return start.diff(now, 'minutes');
        },

        getMeetingTimeVisualUntil(meeting) {
            const diff = this.getMeetingMinutesUntil(meeting);
            if (diff < 30) {
                const diffX = diff + 1;
                return `in ${diffX} minute` + (diffX === 1 ? '' : 's');
            }

            return null;
        },

        getMeetingStyles(meeting) {
            if (!meeting.start) {
                return 'display: none;';
            }

            const start = moment.tz(meeting.start.date, meeting.start.timezone).tz(this.timezone);
            const end = moment.tz(meeting.end.date, meeting.start.timezone).tz(this.timezone);
            const diff = start.diff(end, 'minutes');
            let extra;

            const minute = 100 / (24 * 60);
            const top = (start.minutes() + (start.hours() * 60)) * minute;
            const height = Math.abs(diff) * minute;

            if (meeting === this.currentMeeting) {
                extra = 'border-right-color: $primary;';
            }

            return `top: ${top}%; height: calc(${height}% - 1px); ${extra}`;
        },

        bookConfirm(i) {
            EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                body: `Are you sure you want to book<br>${this.meetingRoom.data.name} for ${i} minutes?`,
                cancelButton: true,
                confirmButtonText: 'Book',
                style: 'dark',
                title: `Book ${this.meetingRoom.data.name}`,
                onConfirm: () => this.onBookConfirm(i),
                buttonSize: ButtonSizes.LARGE,
            });
        },

        onBookConfirm(i) {
            this.startLoading();

            window.axios
                .post(APIEndpoints.MEETING_ROOM_QUICK_BOOK, {
                    email_address: this.meetingRoom.data.email_address,
                    minutes: i,
                })
                .then(response => this.onBookSuccess(response.data))
                .catch(error => this.onBookFailure(error.response));
        },

        onBookSuccess() {
            this.endLoading();

            APIDataStore.fetch(APIDataStoreResources.MEETING_ROOM, {params: {id: this.roomId}});
        },

        onBookFailure() {
            this.endLoading();

            EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                title: 'Error',
                body: 'Failed to book meeting room.',
                buttonSize: ButtonSizes.LARGE,
            });
        },

        onErrorClick() {
            EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                title: 'Error',
                body: this.meetingRoom.data.calendar.error,
                buttonSize: ButtonSizes.LARGE,
            });
        },

        endCurrentMeetingConfirm(currentMeeting) {
            EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                title: 'End meeting',
                body: `Are you sure you want to end current meeting early?`,
                cancelButton: true,
                confirmButtonText: 'End',
                onConfirm: () => this.onEndMeetingConfirm(currentMeeting),
                buttonSize: ButtonSizes.LARGE,
            });
        },

        onEndMeetingConfirm(currentMeeting) {
            this.startLoading();

            window.axios
                .post(APIEndpoints.MEETING_ROOM_END_MEETING, {
                    email_address: this.meetingRoom.data.email_address,
                    meeting_id: currentMeeting.id,
                })
                .then(response => this.onEndMeetingSuccess(response.data))
                .catch(error => this.onEndMeetingFailure(error.response));
        },

        onEndMeetingSuccess() {
            this.endLoading();
            APIDataStore.fetch(APIDataStoreResources.MEETING_ROOM, {params: {id: this.roomId}});
        },

        onEndMeetingFailure() {
            this.endLoading();
            EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                title: 'Error',
                body: 'Failed to end current meeting.',
            });
        },
    },
}
</script>

<style lang="scss">
.meeting-room-slide {
    height: 100vh;
    position: relative;
    transition: opacity .4s;

    &__date-time {
        position: absolute;
        top: 30px;
        left: 30px;

        .--colon {
            animation: blinker 1s infinite;
        }
    }

    &__panel {
        z-index: 3;
        position: absolute;
        height: calc(100% - 60px);
        top: 30px;
        right: 30px + 120px;
        width: 30vw;
        display: flex;
        flex-direction: column;
        text-align: left;

        .f-panel__content {
            height: auto;
            flex: 1;
            padding: 30px;
            font-size: 16px;
        }

        &__current-state {
            height: 100%;
        }

        &__footer {
            padding: 30px;
            border-top: 1px solid $softGray;

            .f-text {
                font-size: 18px;
            }
        }

        .f-button {
            align-self: flex-end;
        }
    }
}

@keyframes blinker {
    50% {
        opacity: 1;
    }
    55% {
        opacity: 0;
    }
    95% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
