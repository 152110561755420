<template>
    <div
        :class="[
            'user-card-compact',
             (selected ? '--selected' : null),
             (isSelectable && !disabled ? '--selectable' : null),
             (disabled ? '--disabled' : null),
             (fullWidth ? '--full-width' : null),
             (hasBirthday ? '--birthday' : null),
             `--${variant}`,
             '--no-select',
         ]"
        @click.stop="onClick"
    >
        <div class="user-card-compact__wrapper">
            <div class="user-card-compact__avatar">
                <entity-avatar
                    :name="(user ? user.name : '?')"
                    :src="(user ? user.profile_image : null)"
                    size="medium"
                />
            </div>
            <div class="user-card-compact__body">
                <div
                    class="user-card-compact__body__name"
                    v-text="(user ? user.name : 'Unknown')"
                />
                <div
                    v-if="showDisciplines && user"
                    class="user-card-compact__body__disciplines"
                >
                    {{ user.title }}
                    <span v-if="user.type && user.type === 'freelancer'">
                        (freelancer)
                    </span>
                </div>
                <div class="user-card-compact__body__slot" v-else>
                    <slot/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EntityAvatar from '../../misc/entities/EntityAvatar';
import UserCardHelperMixin from '../../../mixins/UserCardHelperMixin';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import {UserCardCompactVariants} from './config';

export default {
    name: 'user-card-compact',

    components: {
        EntityAvatar,
    },

    mixins: [
        UserCardHelperMixin,
    ],

    props: {
        user: Object,
        className: String,
        type: {
            type: String,
            default: 'employees',
        },
        variant: {
            type: String,
            default: UserCardCompactVariants.LIGHT,
        },
        disabled: Boolean,
        fullWidth: Boolean,
        forceUnselectable: Boolean,
        showDisciplines: {
            type: Boolean,
            default: true,
        },
        popupVariant: {
            type: String,
            default: 'dark',
        },
    },

    computed: {
        isSelectable() {
            if (this.forceUnselectable) {
                return false;
            }
            return !this.disabled && !(!this.user || !this.user.title);
        },
    },

    data() {
        return {
            selected: false,
        }
    },

    mounted() {
        EventBus.$on(GlobalEvents.USER_CARD_HIDE, () => this.selected = false);
    },

    methods: {
        onClick() {
            if (!this.isSelectable) {
                return;
            }

            EventBus.$emit(GlobalEvents.USER_CARD_HIDE);

            this.$nextTick(() => {
                EventBus.$emit(GlobalEvents.USER_CARD_SHOW, this.$el, this.user.name, {variant: this.popupVariant});
                this.selected = true;
            });
        },
    },
}
</script>

<style lang="scss">
.user-card-compact {
    border-radius: 10px;
    vertical-align: middle;
    width: 180px;
    border: 1px solid transparent;
    transition: border-color .1s, background-color .1s;
    position: relative;
    line-height: 100%;

    &__wrapper {
        width: 100%;
        display: inline-flex;
        align-items: center;
        mask-image: linear-gradient(90deg, black 0%, black 80%, transparent 95%, transparent 100%);
        overflow: hidden;
        margin: 6px 6px 6px 12px;
    }

    &__avatar {
        flex: 0 0 37px;
    }

    &__body {
        text-align: left;
        flex: 1 1;

        &__name, &__disciplines {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            a {
                text-decoration: underline;
            }
        }

        &__disciplines {
            font-size: 80%;
        }

        &__name {
            width: 106px;
            font-weight: 600;
        }

        &__slot {
            width: 100%;
            font-size: 80%;
            line-height: 110%;
        }

        &__disciplines, &__slot {
            opacity: .65;
        }
    }

    &.--birthday {
        &:before {
            content: '';
            position: absolute;
            background: url('../../../../public/img/birthday-hat.png') no-repeat 50% 50%;
            background-size: cover;
            transform: rotate(-47deg);
            z-index: 2;
            width: (298px * 0.05);
            height: (448px * 0.05);
            left: 0;
            top: -4px;
        }
    }

    // Options

    &.--full-width {
        width: 100%;

        .user-card-compact__body__name {
            width: 100%;
        }
    }

    &.--selectable {
        cursor: pointer;
    }

    // Variants

    &.--primary, &.--secondary, &.--success, &.--warning, &.--info, &.--danger {
        &.--disabled {
            background: transparent;
            opacity: .5;
        }
    }

    &.--primary {
        border-color: $primary;
        color: $primaryShade;

        &.--selectable {
            background: $primary;
            color: $primaryFront;
        }

        &.--selected, &.--selectable:hover {
            background: $primaryShade;
            border-color: $primaryShade;
            color: $primaryFront;
        }
    }

    &.--secondary {
        border-color: $secondary;
        color: $secondaryShade;

        &.--selectable {
            background: $secondary;
            color: $secondaryFront;
        }

        &.--selected, &.--selectable:hover {
            background: $secondaryShade;
            border-color: $secondaryShade;
            color: $secondaryFront;
        }
    }

    &.--success {
        border-color: $success;
        color: $successShade;

        &.--selectable {
            background: $success;
            color: $successFront;
        }

        &.--selected, &.--selectable:hover {
            background: $successShade;
            border-color: $successShade;
            color: $successFront;
        }
    }

    &.--warning {
        border-color: $warning;
        color: $warningShade;

        &.--selectable {
            background: $warning;
            color: $warningFront;
        }

        &.--selected, &.--selectable:hover {
            background: $warningShade;
            border-color: $warningShade;
            color: $warningFront;
        }
    }

    &.--info {
        border-color: $info;
        color: $infoShade;

        &.--selectable {
            background: $info;
            color: $infoFront;
        }

        &.--selected, &.--selectable:hover {
            background: $infoShade;
            border-color: $infoShade;
            color: $infoFront;
        }
    }

    &.--danger {
        border-color: $danger;
        color: $dangerShade;

        &.--selectable {
            background: $danger;
            color: $dangerFront;
        }

        &.--selected, &.--selectable:hover {
            background: $dangerShade;
            border-color: $dangerShade;
            color: $dangerFront;
        }
    }

    &.--light {
        border-color: $softGray;

        &.--selectable {
            background: $softGray;
            color: $dark;
        }

        &.--selected, &.--selectable:hover {
            background: $primary;
            border-color: $primary;
            color: $primaryFront;
        }

        &.--disabled {
            background: transparent;

            .user-card-compact__wrapper {
                opacity: .5;
            }
        }
    }

    &.--dark {
        border-color: $quaternaryGray;
        color: $secondaryGray;

        &.--selectable {
            border-color: $quaternaryGray;
            background: $quaternaryGray;
            color: $light;
        }

        &.--selected, &.--selectable:hover {
            background: $primary;
            border-color: $primary;
            color: $primaryFront;
        }

        &.--disabled {
            color: $quaternaryGray;
            background: transparent;

            .user-card-compact__wrapper {
                opacity: .5;
            }
        }
    }

    &.--semi-dark {
        border-color: $tertiaryGray;
        color: $secondaryGray;

        &.--selectable {
            border-color: $quinaryGray;
            background: $quinaryGray;
            color: $dark;
        }

        &.--selected, &.--selectable:hover {
            background: $primary;
            border-color: $primary;
            color: $primaryFront;
        }

        &.--disabled {
            color: $quaternaryGray;
            background: transparent;

            .user-card-compact__wrapper {
                opacity: .5;
            }
        }
    }
}
</style>
