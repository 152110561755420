<template>
    <paginate
        v-if="pageCount && pageCount !== 1"
        v-model="computedCurrentPage"
        :page-count="pageCount"
        :click-handler="(page) => $emit('navigate', page, (bottom ? 'bottom' : 'top'))"
        :container-class="`f-pagination ${bottom ? '--bottom' : '--top'}`"
        prev-link-class="f-pagination__prev"
        next-link-class="f-pagination__next"
    />
</template>

<script>
import Paginate from 'vuejs-paginate';

export default {
    name: 'f-pagination',

    components: {
        Paginate,
    },

    props: {
        startPage: {
            type: Number,
            default: () => 1,
        },
        pageCount: Number,
        currentPage: Number,
        bottom: Boolean,
    },

    computed: {
        computedCurrentPage: {
            get: function () {
                return this.currentPage;
            },
            set: function () {
            },
        },
    },
}
</script>

<style lang="scss">
.f-pagination {
    user-select: none;
    text-align: center;

    &.--top {
        padding: 25px 0 35px;
    }

    &.--bottom {
        padding: 50px 0 0;
    }

    li {
        display: inline-block;
        padding: 1px;

        a {
            display: inline-block;
            min-width: 28px;
            height: 28px;
            overflow: hidden;
            padding: 7px 6px 0;
            line-height: 100%;
            text-align: center;
            border-radius: 15px;
            text-decoration: none;
            transition: background-color .1s, border-color .1s, color .1s;
            border: 1px solid transparent;

            @media (hover: hover) {
                &:hover {
                    background: $primary;
                    color: $primaryFront !important;
                    text-decoration: none;
                }
            }

            &.f-pagination__prev, &.f-pagination__next {
                width: 28px;
                height: 28px;
                color: transparent !important;
                text-align: center;
                position: relative;
                overflow: hidden;

                &:before {
                    font-family: 'Linearicons';
                    color: $dark;
                }

                &:hover:before {
                    color: $primaryFront;
                }
            }

            &.f-pagination__prev {
                &:before {
                    content: "\ec3b";
                    padding-left: 1px;
                }
            }

            &.f-pagination__next {
                &:before {
                    content: "\ec3c";
                    padding-left: 1px;
                }
            }
        }

        &.disabled {
            opacity: .25;
            pointer-events: none;
        }

        &.active {
            pointer-events: none;

            a {
                background: $softGray;
                //color: $primaryFront;
            }
        }
    }
}
</style>
