<template>
    <div
        :class="[
            'department-discipline-path',
            (disciplineOnNewLine ? '--discipline-on-new-line' : null),
        ]"
    >
        <span
            v-if="office"
            class="department-discipline-path__office"
        >
            {{ office.ucwords() }}
        </span>
        <span
            v-if="discipline && discipline.department && discipline.department.department"
            class="department-discipline-path__department"
        >
            {{ discipline.department.department.name }}
        </span>
        <span
            v-if="discipline && discipline.department"
            class="department-discipline-path__department"
        >
            {{ discipline.department.name }}
        </span>
        <span
            v-if="discipline"
            class="department-discipline-path__discipline"
        >
            {{ discipline.name }}
        </span>
        <span class="department-discipline-path__discipline" v-else>
            <status-icon
                type="warning"
                tooltip="Warning: No discipline set for<br>this user"
            />
        </span>
    </div>
</template>

<script>
import StatusIcon from '../status-icon/StatusIcon';

export default {
    name: 'department-discipline-path',

    components: {
        StatusIcon,
    },

    props: {
        office: String,
        discipline: Object,
        disciplineOnNewLine: Boolean,
    },
}
</script>

<style lang="scss">
.department-discipline-path {
    font-size: 95%;
    line-height: 100%;
    white-space: nowrap;

    * {
        vertical-align: middle;
    }

    .department-discipline-path__office,
    .department-discipline-path__department,
    .department-discipline-path__discipline {
        &::after {
            font-family: 'Linearicons';
            display: inline-block;
            content: '\EC3C';
            opacity: .75;
            font-size: 60%;
            vertical-align: middle;
        }

        &:last-child::after {
            content: '';
        }
    }

    .department-discipline-path__office,
    .department-discipline-path__department {
        opacity: .5;
    }

    .department-discipline-path__discipline {
        .f-icon-button {
            margin-left: 4px;
        }
    }

    &.--discipline-on-new-line {
        .department-discipline-path__discipline {
            display: block;
            font-size: 110%;
            line-height: 120%;
            margin-top: 3px;

            .f-icon-button {
                margin-left: 0;
            }
        }
    }
}
</style>
