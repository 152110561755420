<template>
    <f-form-row class-name="f-placeholder-form-row">
        <template #label>
            <f-placeholder
                inline
                height="20px"
                :width="`${random(60, 90)}%`"
            />
        </template>
        <f-placeholder
            inline
            height="32px"
            :width="`${random(60, 90)}%`"
        />
    </f-form-row>
</template>

<script>
import FPlaceholder from './FPlaceholder';
import FFormRow from '../../form-controls/forms/FFormRow';

export default {
    name: 'f-placeholder-form-row',

    components: {
        FFormRow,
        FPlaceholder,
    },

    methods: {
        random(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
    }
}
</script>
