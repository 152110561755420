import {DropdownMode} from './config';

export default {
    props: {
        popupWidth: String,
        searchable: Boolean,
        mode: {
            type: String,
            default: DropdownMode.SINGLE,
        },
        /**
         * The options to select.
         * [{key: 'hide', value: 'hide'}, {key: 'purchase', value: 'purchase'}],
         */
        options: {
            type: Array,
            required: true,
        },
        /**
         * Function to filter the list when searching.
         */
        optionFilter: {
            type: Function,
            default: (query, option) => {
                return Object.values(option).join('').toLowerCase().indexOf(query.toLowerCase()) >= 0;
            },
        },
        popupVariant: {
            type: String,
            default: "dark",
        },
    },

    methods: {
        getExcludedProps() {
            return [
                'options',
                'optionFilter',
                'mode',
                'popupWidth',
                'validation',
                'searchable',
            ];
        },
    }
};
