<template>
    <div
        :class="['email-signature']"
        v-html="signature"
    />
</template>

<script>
import Store from '../../services/Store';

export default {
    name: 'email-signature',

    props: {
        signature: {
            type: String,
            default: () => {
                return Store.state.$user.email_signature;
            }
        }
    },
}
</script>

<style lang="scss">
.email-signature {
    padding: 5px 20px;
    border-radius: 15px;
    background: white;
    user-select: all;
    text-align: left;
    overflow-x: scroll;
    font-weight: 400;

    hr {
        opacity: 1;
    }
}
</style>
