<template>
    <widget
        v-if="meetingRooms.length > 0"
        :class-name="['meeting-rooms-widget', widgetSize]"
        title="Meeting Rooms"
        :header="currentOffice.ucwords()"
        enabled-setting="widgets.meeting_rooms.enabled"
    >
        <template #header-post>
            <f-button
                tag="a"
                :href="`/meeting-rooms/${userOffice}`"
                value="View calendar"
                variant="primary"
                @click="() => { $router.push(`/meeting-rooms/${userOffice}`); return false; }"
                :size="(breakpoints.mobile ? 'small' : 'medium')"
            />
        </template>

        <div class="meeting-rooms-widget__rooms">
            <div
                v-for="(room, i) in meetingRooms"
                :class="['meeting-rooms-widget__room', `--${getMeetingRoomState(room).state}`]"
                :key="i"
            >
                <div class="meeting-rooms-widget__room__content">
                    <div
                        class="meeting-rooms-widget__room__content__name"
                        v-text="room.name.toLowerCase().ucwords()"
                    />
                    <div
                        class="meeting-rooms-widget__room__content__state"
                        v-html="getMeetingRoomState(room).label"
                    />
                </div>
            </div>
        </div>
    </widget>
</template>

<script>
import MeetingRoomsMixin from '../../../mixins/MeetingRoomsMixin';
import WidgetSizeMixin from '../WidgetSizeMixin';
import Widget from '../Widget';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import UserOfficeMixin from '../../../mixins/UserOfficeMixin';
import FButton from '../../../f-components/form-controls/buttons/FButton';
import BreakpointsMixin from '../../../mixins/BreakpointsMixin';
import MeetingRoomsTodayMixin from '../../../mixins/MeetingRoomsTodayMixin';

export default {
    name: 'meeting-rooms-widget',

    components: {
        FButton,
        Widget,
    },

    mixins: [
        MeetingRoomsMixin,
        MeetingRoomsTodayMixin,
        WidgetSizeMixin,
        UserOfficeMixin,
        BreakpointsMixin,
    ],

    data() {
        return {
            extraWidgetSizeBreakPoints: true,
        }
    },

    created() {
        EventBus.$on(GlobalEvents.MINUTE_CHANGE, this.update);
    },

    beforeDestroy() {
        EventBus.$off(GlobalEvents.MINUTE_CHANGE, this.update);
    },

    methods: {
        update() {
            this.$forceUpdate();
        },
    },
}
</script>

<style lang="scss">
.meeting-rooms-widget {
    &.--widget-small {
        .meeting-room {
            width: 100%;
        }
    }

    &__rooms {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    &__room {
        width: calc(50% - 5px);
        overflow: hidden;
        border-radius: 5px;
        border: solid $tertiaryGray;
        border-width: 1px 1px 1px 0;

        &__content {
            padding: 10px 15px;

            &__name {
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &__state {
                font-size: 90%;
                opacity: .5;
                line-height: 120%;
            }
        }

        &.--available {
            .meeting-rooms-widget__room__content {
                border-left: 4px solid $success;
            }
        }

        &.--booked {
            .meeting-rooms-widget__room__content {
                border-left: 4px solid $secondary;
            }
        }
    }
}
</style>
