var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{class:[
            'gallery-picture-overlay',
            (_vm.disabled ? '--disabled' : null),
        ]},[_c('div',{staticClass:"gallery-picture-overlay__tags",style:({
                visibility: (_vm.environment === 'production' ? 'hidden' : undefined),
                pointerEvents: (_vm.environment === 'production' ? 'none' : undefined),
            })},_vm._l((_vm.image.tags),function(tag){return _c('picture-tag',{key:tag.id,attrs:{"tag":tag,"image-width":_vm.image.width,"image-height":_vm.image.height}})}),1),_c('f-flex',{attrs:{"class-name":"gallery-picture-overlay__bar","gap":"10px","main-axis-align":"flex-end","cross-axis-align":"center"}},[(_vm.likesCount > 0)?_c('entity-avatar-group',{attrs:{"entities":_vm.likers,"size":"small","tooltip-post":" liked"}}):_vm._e(),_c('f-button',{attrs:{"icon":"thumbs-up","value":(_vm.liked ? 'You liked' : 'Like'),"size":"small","show-loader-on-disabled":"","disabled":_vm.likeLoading},on:{"click":_vm.like}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }