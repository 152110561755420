<template>
    <f-abstract-input-field
        v-bind="boundProps"
        :class-name="[
            'f-text-area',
            ...classNames,
        ]"
        :focussed="hasFocus"
        :has-content="!!inputValue"
        :input-error="inputError"
        :show-error="showError"
        @reset="inputValue = null"
    >
        <textarea
            class="f-text-area__input --scrollable"
            :name="uid"
            :placeholder="placeholder"
            :tabindex="tabIndex"
            :autofocus="autoFocus"
            :readonly="readonly"
            :disabled="disabled"
            v-model="inputValue"
            @focus="onFocus"
            @blur="onBlur"
            ref="input"
        />
    </f-abstract-input-field>
</template>

<script>
import FAbstractInputField from '../base/FAbstractInputField';
import AbstractInputFieldPropsMixin from '../base/AbstractInputFieldPropsMixin';
import AbstractInputValidationMixin from '../base/AbstractInputValidationMixin';
import AbstractInputEventsMixin from '../base/AbstractInputEventsMixin';
import AbstractInputMixin from '../base/AbstractInputMixin';

export default {
    name: 'f-text-area',

    components: {
        FAbstractInputField,
    },

    mixins: [
        AbstractInputFieldPropsMixin,
        AbstractInputValidationMixin,
        AbstractInputEventsMixin,
        AbstractInputMixin,
    ],

    watch: {
        inputValue(value) {
            this.$emit('input', value);
        },
        value(value) {
            this.inputValue = value;
        },
    },

    data() {
        return {
            inputValue: this.value,
        };
    },

    methods: {
        getValue() {
            return this.inputValue;
        },

        selectAll() {
            this.$refs.input.select();
        },

        copy() {
            this.$refs.input.select();
            this.$refs.input.setSelectionRange(0, 99999);
            document.execCommand('copy');
        },
    },
}
</script>

<style lang="scss">
.f-text-area {
    &__input {
        width: 100%;
        margin-top: 4px;
        padding: 11px 10px;
        font-size: 13px;
        background: transparent;
        border: 0;
        resize: none;
        color: $light;

        @include theme(light) {
            color: $dark;
        }
    }
}
</style>
