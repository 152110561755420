import APIEndpoints from '../../../../config/APIEndpoints';
import ErrorHandler from '../../../../services/ErrorHandler';
import EventBus from '../../../../services/EventBus';
import {GlobalEvents} from '../../../../config/Events';

/**
 * Helper methods for the Admin > Users module. Used on the overview, show  and edit pages.
 */
export default {
    methods: {
        activateUser(id, onComplete) {
            EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                title: 'Activate user',
                body: 'Are you sure you want to activate this user?',
                height: '200px',
                confirmButtonText: 'Activate',
                cancelButton: true,
                onConfirm: () => {
                    window.axios
                        .post(APIEndpoints.parse(APIEndpoints.ADMIN_USER_ACTIVATE, {
                            id: id,
                        }))
                        .then(() => {
                            EventBus.$emit(GlobalEvents.SITE_LOAD_FINISH);
                            this.loading = false;

                            EventBus.$emit(
                                GlobalEvents.PUSH_MESSAGE,
                                'Successfully activated user',
                            );

                            if (onComplete) {
                                onComplete();
                            }
                        })
                        .catch(error => {
                            EventBus.$emit(GlobalEvents.SITE_LOAD_FINISH);
                            this.loading = false;
                            ErrorHandler.onGeneralError(error);
                        });
                },
            });
        },

        deactivateUser(id, onComplete) {
            EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                title: 'Deactivate user',
                body: 'Are you sure you want to deactivate this user?',
                height: '200px',
                confirmButtonText: 'Deactivate',
                confirmButtonVariant: 'danger',
                cancelButton: true,
                onConfirm: () => {
                    window.axios
                        .post(APIEndpoints.parse(APIEndpoints.ADMIN_USER_DEACTIVATE, {
                            id: id,
                        }))
                        .then(() => {
                            EventBus.$emit(GlobalEvents.SITE_LOAD_FINISH);
                            this.loading = false;

                            EventBus.$emit(
                                GlobalEvents.PUSH_MESSAGE,
                                'Successfully deactivated user',
                            );

                            if (onComplete) {
                                onComplete();
                            }
                        })
                        .catch(error => {
                            EventBus.$emit(GlobalEvents.SITE_LOAD_FINISH);
                            this.loading = false;
                            ErrorHandler.onGeneralError(error);
                        });
                },
            });
        },

        deleteUser(id, onComplete) {
            EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                title: 'Delete user',
                body: 'Are you sure you want to delete this user?',
                height: '200px',
                confirmButtonText: 'Delete',
                confirmButtonVariant: 'danger',
                cancelButton: true,
                onConfirm: () => {
                    window.axios
                        .delete(`${APIEndpoints.ADMIN_USERS}/${id}`)
                        .then(() => {
                            EventBus.$emit(GlobalEvents.SITE_LOAD_FINISH);
                            this.loading = false;

                            EventBus.$emit(
                                GlobalEvents.PUSH_MESSAGE,
                                'Successfully deleted user',
                            );

                            if (onComplete) {
                                onComplete();
                            }
                        })
                        .catch(error => {
                            EventBus.$emit(GlobalEvents.SITE_LOAD_FINISH);
                            this.loading = false;
                            ErrorHandler.onGeneralError(error);
                        });
                },
            });
        },
    },
}
