<template>
    <f-table-row
        v-if="data.definition"
        :class-name="[
            'notification-row',
            (data.is_read ? '--read' : '--not-read'),
        ]"
        @click.prevent="EventBus.$emit('notification.show', data)"
    >
        <f-table-cell no-padding-right>
            <entity-avatar
                :src="data.definition.image_url ? data.definition.image_url : undefined"
                size="xmedium"
            >
                <f-icon v-bind="iconProps"/>
            </entity-avatar>
        </f-table-cell>
        <f-table-cell>
            <f-flex gap="10px">
                <div class="notification-row__body">
                    <div class="notification-row__body__title">
                        {{ data.definition.title }}
                    </div>
                    <div class="notification-row__body__date">
                        {{ getDateTimeDiff(data.created_at) }}
                    </div>
                </div>
                <div
                    v-if="!data.is_read"
                    class="notification-row__unread"
                >
                    &bullet;
                </div>
            </f-flex>
        </f-table-cell>
    </f-table-row>
</template>

<script>
import FIcon from '../../../f-components/layout/icons/FIcon';
import EntityAvatar from '../entities/EntityAvatar';
import FFlex from '../../../f-components/layout/flex/FFlex';
import FTableCell from '../../../f-components/data/table/FTableCell';
import FTableRow from '../../../f-components/data/table/FTableRow';
import NotificationMixin from './NotificationMixin';

export default {
    name: 'notification-row',

    mixins: [
        NotificationMixin,
    ],

    components: {
        FTableRow,
        FTableCell,
        FFlex,
        EntityAvatar,
        FIcon,
    },

    props: {
        data: Object,
    },
}
</script>

<style lang="scss">
.notification-row {
    &__body {
        flex-grow: 1;

        &__title {
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__date {
            font-size: 90%;
            opacity: .5;
        }
    }

    &__unread {
        color: $info;
        font-size: 150%;
    }
}
</style>
