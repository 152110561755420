<template>
    <div class="--loader">
        <slot/>

        <f-table v-if="syncData">
            <template #head>
                <f-table-row>
                    <f-table-header-cell
                        v-for="id in ids"
                        :key="id.key"
                        align="center"
                        no-wrap
                    >
                        {{ name ? name : service.ucfirst() }} {{ id.name }}
                    </f-table-header-cell>
                    <f-table-header-cell no-wrap>
                        {{ name ? name : service.ucfirst() }} name
                    </f-table-header-cell>
                    <f-table-header-cell align="center" no-wrap>Status</f-table-header-cell>
                    <f-table-header-cell no-wrap align="center" width="300px">
                        {{ toName }}
                    </f-table-header-cell>
                </f-table-row>
            </template>

            <f-table-row
                v-for="(user, i) in syncData"
                :key="`${user.from_id}-${i}`"
            >
                <f-table-cell
                    v-for="id in ids"
                    :key="id.key"
                    align="center"
                    is-id
                >
                    {{ user[id.key] }}
                </f-table-cell>
                <f-table-cell>{{ user.from }}</f-table-cell>
                <f-table-cell align="center">
                    <f-chip :variant="(user.status === 'failed' ? 'danger' : 'success')">
                        {{ user.status === 'failed' ? 'unmapped' : user.status }}
                    </f-chip>
                </f-table-cell>
                <f-table-cell no-padding-bottom-top align="center">
                    <f-user-dropdown
                        v-model="user.to_id"
                        max-width="300px"
                        :options="users"
                        resetable
                        @change="(toID) => onMappingChange(user, toID)"
                        style="margin: 0 !important;"
                    />
                </f-table-cell>
            </f-table-row>
        </f-table>

        <f-table-placeholder no-pagination v-if="!data"/>
    </div>
</template>

<script>
import FTable from '../../../../../f-components/data/table/FTable';
import FTableRow from '../../../../../f-components/data/table/FTableRow';
import FTableHeaderCell from '../../../../../f-components/data/table/FTableHeaderCell';
import FTableCell from '../../../../../f-components/data/table/FTableCell';
import FChip from '../../../../../f-components/data/chip/FChip';
import FUserDropdown from '../../../../../f-components/form-controls/dropdowns/FUserDropdown';
import APIEndpoints from '../../../../../config/APIEndpoints';
import ErrorHandler from '../../../../../services/ErrorHandler';
import Store from '../../../../../services/Store';
import LoadingMixin from '../../../../../mixins/LoadingMixin';
import {SiteLoaderSizes} from '../../../../../f-components/loaders/site-loader/config';
import FTablePlaceholder from '../../../../../f-components/loaders/placeholder/FTablePlaceholder';

export default {
    name: 'sync-page-content',

    mixins: [
        LoadingMixin,
    ],

    components: {
        FTablePlaceholder,
        FUserDropdown,
        FChip,
        FTableCell,
        FTableHeaderCell,
        FTableRow,
        FTable,
    },

    props: {
        service: {
            type: String,
            default: 'jamf',
        },
        toName: {
            type: String,
            default: 'Riviera user',
        },
        type: {
            type: String,
            default: 'user', // user | freelancer
        },
        name: String,
        ids: {
            type: Array,
            default: () => [{
                key: 'from_id',
                name: 'user ID',
            }],
        },
    },

    watch: {
        type() {
            this.fetch();
        },
    },

    data() {
        return {
            data: null,
            manuallyMappedFromIDs: [],
        }
    },

    computed: {
        syncData() {
            return this.data ? [
                ...this.data.failed.map((user) => {
                    return {...user, status: (this.manuallyMappedFromIDs.includes(user.from_id) ? 'mapped' : 'failed')};
                }),
                ...this.data.mapped.map((user) => {
                    return {...user, status: 'mapped'};
                }),
            ] : [];
        },
        users() {
            return Store.state[(this.type === 'user' ? '$usersCollection' : '$freelancersCollection')].map((user) => {
                return {
                    key: user.id,
                    value: user.name,
                    email: user.email,
                    profile_image: user.profile_image,
                    office: user.office ? user.office.ucfirst() : null,
                };
            });
        },
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.data = null;
            this.startLoading(SiteLoaderSizes.BIG);

            window.axios
                .get(APIEndpoints[`ADMIN_${this.service.toUpperCase()}_SYNC`])
                .then(response => this.onFetchComplete(response))
                .catch(error => {
                    this.endLoading();
                    ErrorHandler.onGeneralError(error);
                });
        },

        onFetchComplete(response) {
            this.endLoading(true);
            this.data = response.data.data;
        },

        onMappingChange(user, toID) {
            this.startLoading(SiteLoaderSizes.BIG);

            const data = {
                service: this.service,
                to_id: toID,
            };

            for (let i in this.ids) {
                data[this.ids[i].key] = user[this.ids[i].key];
            }

            window.axios
                .put(APIEndpoints.ADMIN_USER_MAPPING_UPDATE, data)
                .then(() => {
                    this.manuallyMappedFromIDs.push(user.from_id);
                    this.endLoading();
                })
                .catch(error => {
                    this.endLoading();
                    ErrorHandler.onGeneralError(error);
                });
        },
    },
}
</script>
