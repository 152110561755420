<template>
    <page title="Meeting room">
        <f-portal>
            <div class="meeting-room-tablet-page__content">
                <meeting-room-logo :number="roomId" class="meeting-room-tablet-page__logo"/>
            </div>

            <!--            <div class="meeting-room-tablet-page__content">-->
            <!--                <video-->
            <!--                    class="meeting-room-tablet-page__content__background"-->
            <!--                    loop-->
            <!--                    autoplay-->
            <!--                    muted-->
            <!--                    ref="background"-->
            <!--                >-->
            <!--                    <source src="/mov/background-gradient.webm" type="video/webm"/>-->
            <!--                </video>-->
            <!--                <f-carousel-->
            <!--                    v-if="meetingRoom"-->
            <!--                    ref="slider"-->
            <!--                    stretch-height-->
            <!--                    navigation-type="none"-->
            <!--                    auto-return-to="index"-->
            <!--                >-->
            <!--                    <f-carousel-slide id="index">-->
            <!--                        <meeting-room-slide-->
            <!--                            :room-id="roomId"-->
            <!--                            :office="meetingRoom.data.office_id"-->
            <!--                            @to-calendar="$refs.slider.slideTo('calendar')"-->
            <!--                        />-->
            <!--                    </f-carousel-slide>-->
            <!--                    <f-carousel-slide id="calendar" back-button>-->
            <!--                        <calendar-slide :office="meetingRoom.data.office_id"/>-->
            <!--                    </f-carousel-slide>-->
            <!--                </f-carousel>-->
            <!--            </div>-->
        </f-portal>
    </page>
</template>

<script>
import FCarousel from "../../../f-components/navigation/carousels/FCarousel";
import FCarouselSlide from "../../../f-components/navigation/carousels/FCarouselSlide";
import MeetingRoomSlide from "./components/MeetingRoomSlide";
import CalendarSlide from "./components/CalendarSlide";
// import APIDataStore from "../../../services/APIDataStore";
// import APIDataStoreResources from "../../../config/APIDataStoreResources";
import Page from '../Page';
// import EventBus from '../../../services/EventBus';
import FPortal from '../../../f-components/other/portal/FPortal';
import Logo from '../../misc/Logo.vue';
import MeetingRoomLogo from './components/MeetingRoomLogo.vue';

export default {
    name: 'meeting-room-tablet-page',

    components: {
        MeetingRoomLogo,
        Logo,
        FPortal,
        Page,
        CalendarSlide,
        MeetingRoomSlide,
        FCarouselSlide,
        FCarousel,
    },

    data() {
        const queryParams = new URLSearchParams(window.location.search);

        return {
            roomId: queryParams.has("roomNumber") ? parseInt(queryParams.get("roomNumber")) : "",
        }
    },

    computed: {
        // meetingRoom() {
        //     return this.$store.state.$meetingRoom;
        // },
    },

    mounted() {
        // EventBus.$on('ds.meetingRoom.update', this.update);
        // APIDataStore.fetch(APIDataStoreResources.MEETING_ROOM, {params: {id: this.roomId}});
        // this.$nextTick(() => this.$refs.background.play());
    },

    methods: {
        update() {
            // this.$nextTick(() => this.$forceUpdate());
        },
    },
}
</script>

<style lang="scss">
.meeting-room-tablet-page {
    &__logo {
        position: absolute;
        display: inline-block;
        width: 276px;
        height: 276px;
        overflow: hidden;
        bottom: auto;
        top: calc(50% + 276px / 2);
        left: calc(50% + 276px / 2);
        transform-origin: center center;
    }

    &__content {
        background: $dark;
        height: 100vh;
        width: 100vw;
        display: flex;

        //&__background {
        //    height: 100%;
        //    width: 177.77777778vh; /* 100 * 16 / 9 */
        //    min-width: 100%;
        //    min-height: 56.25vw; /* 100 * 9 / 16 */
        //    position: absolute;
        //}
    }
}
</style>
