export const ButtonSizes = {
    XXSMALL: 'xxsmall',
    XSMALL: 'xsmall',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    XLARGE: 'xlarge',
}

export const ButtonVariants = {
    THEMED: 'themed',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    DARK: 'dark',
    LIGHT: 'light',
    DANGER: 'danger',
    WARNING: 'warning',
    SUCCESS: 'success',
    INFO: 'info',
    THEMED_INPUT_CHILD: 'themed',
}

export const ButtonIconPositions = {
    LEFT: 'left',
    RIGHT: 'right',
}
