<template>
    <f-form class="change-responsible-employee-form">
        <f-form-row hide-label>
            <f-user-dropdown
                v-model="responsible_user_id"
                @change="onUserChange"
                popup-variant="light"
            />
        </f-form-row>
    </f-form>
</template>

<script>
import FForm from "../../../../f-components/form-controls/forms/FForm";
import FFormRow from "../../../../f-components/form-controls/forms/FFormRow";
import FUserDropdown from "../../../../f-components/form-controls/dropdowns/FUserDropdown";

export default {
    name: 'change-responsible-employee-form',

    components: {
        FUserDropdown,
        FFormRow,
        FForm,
    },

    props: {
        data: Object,
    },

    data() {
        return {
            responsible_user_id: this.data.responsible_user_id,
        }
    },

    methods: {
        onUserChange(id) {
            this.$emit('updateField', 'responsible_user_id', id);
        },
    },
}
</script>

<style lang="scss">
.change-responsible-employee-form {
    .f-form-row__label {
        flex: 0 0 40%;
    }
}
</style>
