<template>
    <div
        :class="[
            'entity-avatar',
            '--no-select',
            (size ? `--${size}` : null),
            (color ? `--${color}` : null),
            (birthday ? `--birthday` : null),
            `--${type}`,
            className,
        ]"
        v-tooltip="{ text: tooltip, ...tooltipOptions }"
    >
        <span
            v-if="name"
            class="entity-avatar__initials"
            v-text="initials"
        />
        <img
            v-if="src"
            class="entity-avatar__image"
            loading="lazy"
            :src="src"
            :alt="initials"
        />
        <slot/>
    </div>
</template>

<script>
import {EntityAvatarSizes} from './config';

export default {
    name: 'entity-avatar',

    computed: {
        initials() {
            const name = this.name ?? '';
            const initials = name
                .split(/\s/)
                .reduce((response, word) => response += word.replace(/[\W_]+/g, '').slice(0, 1), '');

            return (initials.length > 1 ? initials.substring(0, 2) : name.replace(/[^a-z0-9]/gi, '').substring(0, 2));
        },
    },

    props: {
        src: String,
        birthday: Boolean,
        name: String,
        tooltip: String,
        size: {
            type: String,
            default: EntityAvatarSizes.MEDIUM,
        },
        type: {
            type: String,
            default: 'round', // round | square
        },
        color: {
            type: String,
            default: 'light', // 'light', 'dark'
        },
        className: String,
        tooltipOptions: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
}
</script>

<style lang="scss">
.entity-avatar {
    width: 32px;
    height: 32px;
    vertical-align: middle;
    background-size: cover;
    border-radius: 50%;
    background-position: 50% 50%;
    text-align: center;
    position: relative;
    line-height: 32px;
    user-select: none;

    &.--square {
        border-radius: 5px;

        .entity-avatar__image {
            border-radius: 5px;
        }
    }

    @include theme(light) {
        background-color: $tertiaryGray;
        color: $dark;
    }

    @include theme(dark) {
        background-color: $quaternaryGray;
        color: $light;
    }

    .f-icon {
        position: absolute;
        bottom: -2px;
        right: -2px;
        width: 18px;
        height: 18px;
        text-align: center;
        font-size: 10px !important;
        line-height: 17px;
        border-radius: 50%;
        background: $secondary;
        color: $secondaryFront;
        font-weight: bold;

        &.--info {
            background-color: $infoShade;
        }

        &.--warning {
            background-color: $warningShade;
        }

        &.--primary {
            background-color: $primaryShade;
        }

        &.--secondary {
            background-color: $secondaryShade;
        }

        &.--danger {
            background-color: $dangerFront;
        }

        &.--success {
            background-color: $successShade;
        }
    }

    &.--birthday {
        &:before {
            content: '';
            position: absolute;
            background: url('../../../../public/img/birthday-hat.png') no-repeat 50% 50%;
            background-size: cover;
            transform: rotate(-40deg);
            z-index: 2;

            width: (298px * 0.0652);
            height: (448px * 0.0652);
            left: -9px;
            top: -16px;
        }

        &.--small:before, &.--xxsmall:before {
            width: (298px * 0.0416);
            height: (448px * 0.0416);
            left: -7px;
            top: -12px;
        }

        &.--large:before, &.--xlarge:before, &.--xxlarge:before {
            width: (298px * 0.166);
            height: (448px * 0.166);
            left: -24px;
            top: -46px;

            @include media(mobile-down) {
                width: (298px * 0.11);
                height: (448px * 0.11);
                left: -14px;
                top: -30px;
            }
        }
    }

    &__initials {
        font-size: 14px;
        text-transform: uppercase;
        pointer-events: none;
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        pointer-events: none;
    }

    &.--dark {
        background-color: rgba($dark, .3);
        overflow: hidden;
        text-align: center;
        color: white;
    }

    // Sizes

    &.--xxsmall {
        width: 20px;
        height: 20px;
        line-height: 18px;

        .entity-avatar__initials {
            font-size: 8px;
        }
    }

    &.--xsmall {
        width: 22px;
        height: 22px;
        line-height: 20px;

        .entity-avatar__initials {
            font-size: 9px;
        }
    }

    &.--small {
        width: 25px;
        height: 25px;
        line-height: 24px;

        .entity-avatar__initials {
            font-size: 10px;
        }
    }

    &.--xmedium {
        width: 42px;
        height: 42px;
        line-height: 300%;

        .entity-avatar__initials {
            font-size: 130%;
        }
    }

    &.--large {
        width: 55px;
        height: 55px;
        line-height: 55px;

        .entity-avatar__initials {
            font-size: 23px;
        }
    }

    &.--xlarge {
        width: 80px;
        height: 80px;
        line-height: 80px;

        .entity-avatar__initials {
            font-size: 35px;
        }

        .f-icon {
            bottom: 0;
            right: 0;
        }
    }

    &.--xxlarge {
        width: 100px;
        height: 100px;
        line-height: 330%;

        .entity-avatar__initials {
            font-size: 44px;
            line-height: 102px;
        }

        @include media(mobile-down) {
            width: 80px;
            height: 80px;

            .entity-avatar__initials {
                font-size: 330%;
                line-height: 78px;
            }
        }
    }
}
</style>
