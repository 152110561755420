<template>
    <article :class="['f-header-body-footer', className]">
        <header v-if="hasHeader" :class="[headerClassName]">
            <slot name="header"/>
        </header>

        <section v-if="maxContentHeight">
            <f-scroll-pane :max-height="maxContentHeight">
                <slot/>
            </f-scroll-pane>
        </section>
        <section v-else>
            <slot/>
        </section>

        <footer v-if="hasFooter" :class="[footerClassName]">
            <slot name="footer"/>
        </footer>
    </article>
</template>

<script>
import FScrollPane from '../../other/scroll-pane/FScrollPane';

export default {
    name: 'f-header-body-footer',

    components: {
        FScrollPane,
    },

    props: {
        maxContentHeight: String,
        className: String,
        headerClassName: String,
        footerClassName: String,
        hasHeader: {
            type: Boolean,
            default: true,
        },
        hasFooter: {
            type: Boolean,
            default: true,
        },
    },
}
</script>

<style lang="scss">
.f-header-body-footer {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;

    > header {
        width: 100%;
        align-self: flex-start;
        margin-bottom: 10px;
    }

    > section {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;

        &.--align-middle {
            justify-content: center;
        }
    }

    > footer {
        width: 100%;
        align-self: flex-end;
        margin-top: 10px;
    }
}
</style>
