import axios from 'axios';
import EventBus from '../../../../services/EventBus';
import {GlobalEvents} from '../../../../config/Events';
import APIEndpoints from '../../../../config/APIEndpoints';

export default {
    methods: {
        deleteGallery(id, title) {
            EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                title: 'Delete gallery',
                body: `Are you sure you want to delete gallery ${title}?`,
                confirmButtonText: 'Delete',
                confirmButtonVariant: 'danger',
                cancelButton: true,
                onConfirm: () => {
                    this.startLoading();

                    axios['delete'](`${APIEndpoints.ADMIN_GALLERIES}/${id}`)
                        .then(() => {
                            this.endLoading();

                            // Add push message
                            EventBus.$emit(GlobalEvents.PUSH_MESSAGE, `Successfully deleted ${title}`);

                            // Redirect to index / refresh
                            if (this.$router.currentRoute.name !== 'admin.galleries') {
                                this.$router.push('/admin/galleries');
                            } else {
                                this.$emit('refresh');
                            }
                        })
                        .catch(() => this.endLoading());
                },
            });
        },

        openDeeplink(deeplink) {
            window.open(deeplink);
        },
    },
}
