export const ScrollPaneMode = {
    VERTICAL: 'vertical',
    HORIZONTAL: 'horizontal',
    BOTH: 'both',
}

export const ScrollPaneVariant = {
    THEMED: 'themed',
    DARK: 'dark',
    LIGHT: 'light',
}
