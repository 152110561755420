<template>
    <f-dropdown
        ref="validatable"
        v-bind="$props"
        :class-name="[
            'f-client-dropdown',
            ...classNames,
        ]"
        pre-icon="briefcase"
        v-model="dropdownValue"
    >
        <template v-slot:selectedOption="selectedOptionProps">
            <div
                v-if="selectedOptionProps.selectedOption"
                class="f-dropdown__selected-option f-client-dropdown__selected-option --single"
            >
                <entity-avatar
                    :name="selectedOptionProps.selectedOption.value"
                    :src="selectedOptionProps.selectedOption.image_url"
                    size="xxsmall"
                />
                {{ selectedOptionProps.selectedOption.value }}
            </div>
        </template>

        <template v-slot:options="optionsProps">
            <li
                v-for="(option, index) in optionsProps.options"
                :class="[
                    'f-client-dropdown__option',
                    (optionsProps.selectedIndex === index ? '--selected' : null),
                    (optionsProps.selectedOption && optionsProps.selectedOption.key === option.key ? '--chosen' : null),
                ]"
                :key="option.key"
                @click.prevent="optionsProps.onSelect(option, index)"
            >
                <div class="f-client-dropdown__option__image">
                    <entity-avatar
                        :name="option.value"
                        :src="option.image_url"
                        size="small"
                        color="dark"
                    />
                </div>
                <div class="f-client-dropdown__option__title">
                    {{ option.value }}
                    <div class="f-client-dropdown__option__title__subtitle">
                        {{ option.office }} office
                    </div>
                </div>
            </li>
        </template>
    </f-dropdown>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import FDropdown from './FDropdown';
import Store from '../../../services/Store';
import EntityAvatar from "../../../components/misc/entities/EntityAvatar";
import DropdownPropsMixin from './DropdownPropsMixin';
import AbstractInputFieldPropsMixin from '../base/AbstractInputFieldPropsMixin';
import PassDownInputValidationMixin from '../base/PassDownInputValidationMixin';

export default {
    name: 'f-client-dropdown',

    components: {
        EntityAvatar,
        FDropdown,
    },

    mixins: [
        AbstractInputFieldPropsMixin,
        DropdownPropsMixin,
        PassDownInputValidationMixin,
    ],

    props: {
        options: {
            type: Array,
            default: () => Store.state.$clientsCollection.map((client) => {
                return {
                    key: client.id,
                    value: client.name,
                    image_url: client.image_url,
                    office: client.office_id.ucfirst(),
                }
            }),
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        resetable: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            dropdownValue: this.value,
        }
    },

    watch: {
        dropdownValue(value) {
            this.$emit('input', value);
        },
        value(value) {
            if (value !== this.dropdownValue) {
                this.dropdownValue = value;
            }
        },
    },

    methods: {
        getSelected() {
            return this.$refs.validatable.getSelectedOption();
        },
    },
}
</script>

<style lang="scss">
.f-client-dropdown {
    &__selected-option {
        &.--single {
            white-space: nowrap;
        }

        .entity-avatar {
            display: inline-block;
            margin: 1px 5px 1px 0;
        }
    }

    &__option {
        display: flex;
        align-items: center;

        &__title {
            padding-left: 7px;

            &__subtitle {
                opacity: .5;
                font-size: 80%;
            }
        }
    }
}
</style>
