var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f-portal',{attrs:{"reference":"popup"}},[_c('transition',{attrs:{"name":"popup","appear":""}},[_c('div',{ref:"positionElement",class:[
                'f-popup',
                ..._vm.classNames,
                '--no-select',
                `--${_vm.currentAlign}`,
                `--hor-${_vm.currentAlignHorizontal}`,
                `--${_vm.variant}`,
                `--theme-${_vm.theme}`,
                (_vm.wrap ? '--wrap' : undefined),
            ],style:({
                width: _vm.width,
                top: `${_vm.pos.top}px`,
                left: `${_vm.pos.left}px`,
                maxHeight: _vm.maxHeight,
                padding: _vm.padding,
            }),on:{"mouseenter":function($event){return _vm.$emit('mouseenter')},"mouseleave":function($event){return _vm.$emit('mouseleave')}}},[_vm._t("default")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }