<template>
    <div :class="['f-flex-table-title-cell', ...classNames]">
        <div class="f-flex-table-title-cell__icon --hide-mobile">
            <slot name="icon"/>
            <f-icon v-if="icon" :icon="icon"/>
        </div>
        <div class="f-flex-table-title-cell__title">
            <h4 class="f-flex-table-title-cell__title__heading">
                <span v-for="(part, i) in title" :key="i">
                    {{ part }}
                </span>
                <slot name="heading"/>
            </h4>
            <h5 class="f-flex-table-title-cell__title__sub-heading">
                <slot/>
            </h5>
        </div>
        <slot name="extra"/>
    </div>
</template>

<script>
import FIcon from '../../layout/icons/FIcon';
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-flex-table-title-cell',

    mixins: [
        ClassNameMixin,
    ],

    components: {
        FIcon,
    },

    props: {
        icon: String,
        title: {
            type: Array,
            default: function () {
                return [];
            }
        },
    },
}
</script>

<style lang="scss">
.f-flex-table-title-cell {
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;

    &__icon {
        flex: 0 0 36px;
        display: inline-block;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 10px;
        text-align: center;
        font-size: 18px;
        line-height: 39px;
        position: relative;
        color: $light;
        background: $quaternaryGray;
    }

    &__title {
        flex: 1;

        &__heading {
            margin: 0 0 2px 0;
            font-size: 120%;
            line-height: 100%;
            font-weight: 600;

            .f-chip {
                vertical-align: bottom;
            }

            span:after {
                font-family: "Linearicons";
                display: inline-block;
                content: "\ec3c";
                font-size: 60%;
                vertical-align: middle;
            }

            span:last-child:after {
                content: '';
            }
        }

        &__sub-heading {
            font-size: 90%;
            margin: 0;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            opacity: .5;
        }
    }
}
</style>
