<template>
    <page
        class-name="faq-page"
        title="FAQs & Guides"
        skewed-footer-foreground="#fff"
        header="What would <strong>you</strong><br>like to <em>know</em>?"
        pre-max-width="800px"
    >
        <f-container max-width="800px" style="padding-bottom: 40px">
            <f-inline-message
                id="faqRivieraDead"
                class-name="dashboard-page__riviera-dead"
            >
                This FAQ is still applicable, even though we are heading towards One Brand.<br/>
                <br/>
                If you have any questions related to the IT migration to Microsoft 365, please
                <ul>
                    <li>check our <a href="https://hyspot.sharepoint.com/sites/FAQ">Sharepoint FAQ</a></li>
                    <li>or the <a href="https://sway.office.com/KfQNu2FxpbkrT7qV" target="_blank">Ruby IT Migration
                        Support
                        document</a> (password is <code>RubyIT2023</code>)
                    </li>
                    <li>message our Slack channel <strong>#it-migration-support</strong></li>
                    <li>
                        or message our IT-specialist
                        <f-button
                            tag="a"
                            target="_blank"
                            variant="light"
                            size="small"
                            :href="`slack://user?team=T02EQNMKZ&id=U01JZ902LJV/`"
                        >
                            <template #pre>
                                <div class="slack-icon"/>
                            </template>
                            <span class="f-button__value__text">
                        @Sonny Lentze
                    </span>
                        </f-button>
                    </li>
                </ul>
            </f-inline-message>
        </f-container>
        <div id="faqPage"/>
        <f-container max-width="800px">
            <faq
                editable
                search
                place="faq-page"
            />
        </f-container>
    </page>
</template>

<script>
import Page from '../Page';
import FContainer from '../../../f-components/layout/container/FContainer';
import Faq from '../../misc/faq/FAQ';
import FInlineMessage from '../../../f-components/notice/inline-message/FInlineMessage.vue';
import FButton from '../../../f-components/form-controls/buttons/FButton.vue';

export default {
    name: 'faq-page',

    components: {
        FButton,
        FInlineMessage,
        Faq,
        FContainer,
        Page,
    },
}
</script>

<style lang="scss">
.faq-page {
    &__edit {
        @include media(mobile-down) {
            display: none;
        }
    }

    .slack-icon {
        width: 18px;
        height: 18px;
        background: url(../../../img/slack.png);
        background-size: cover;
        margin-right: 10px;
    }
}
</style>
