<template>
    <page
        v-if="hasPermission(Permissions.ADMIN_USERS_VIEW)"
        class-name="admin-users-page"
        :loading="loading"
        :title="['Admin', 'Users']"
        header="Users"
        :header-badge="(fetchedData ? fetchedData.total : null)"
        pre-max-width="1250px"
        :actions="actions"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb>Users</f-breadcrumb>
                <f-breadcrumb>Overview</f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-container max-width="1250px">
            <f-filter
                show-active-filters
                :active-filters="activeFilters"
                :filter-casts="{
                    query: 'query',
                }"
            >
                <f-filter-row>
                    <f-filter-item>
                        <f-text-input
                            placeholder="Search…"
                            pre-icon="magnifier"
                            v-model="searchQuery"
                            class-name="--align-center"
                            max-width="260px"
                            resetable
                            @empty="searchQuery = ''"
                        />
                    </f-filter-item>
                    <f-filter-item>
                        <f-dropdown
                            v-model="permissions"
                            :options="permissionOptions"
                            pre-icon="unlock"
                            placeholder="Permission"
                            resetable
                            searchable
                        />
                    </f-filter-item>
                </f-filter-row>
                <f-filter-row>
                    <f-filter-item>
                        <f-dropdown
                            v-model="status"
                            :options="[
                                {key: 'active', value: 'Active'},
                                {key: 'inactive', value: 'Inactive'},
                                {key: 'hidden', value: 'Hidden'},
                                {key: 'deleted', value: 'Deleted'},
                                {key: 'all', value: 'All'},
                            ]"
                            placeholder="Status"
                            pre-icon="folder-user"
                        />
                    </f-filter-item>
                    <f-filter-item>
                        <f-dropdown
                            v-model="office"
                            :options="officeOptions"
                            popup-width="120px"
                            placeholder="Office"
                            pre-icon="apartment"
                            resetable
                        />
                    </f-filter-item>
                </f-filter-row>
                <f-filter-row>
                    <f-filter-item>
                        <f-user-dropdown
                            class-name="--align-center"
                            placeholder="Lead"
                            v-model="lead"
                            width="50%"
                            resetable
                            @search="userSearchQuery = $event"
                        />
                    </f-filter-item>
                </f-filter-row>
            </f-filter>

            <f-inline-message id="adminUsersMsg">
                All Google Workspace accounts will be automatically turned into a Riviera user. However, this does not
                mean that a Riviera user has access to Riviera. A user can have a combination of 4 statuses:<br><br>
                <ul>
                    <li><strong>Inactive</strong><br>
                        <span>Automatically created users are inactive by default. Inactive users
                                can <b>not</b> access Riviera</span>
                    </li>
                    <li><strong>Active</strong><br>
                        <span>Active users can access Riviera, and will be visible as employees on
                                the employees page. Users need to be activated manually</span>
                    </li>
                    <li><strong>Deleted</strong><br>
                        <span>Deleted users can <b>not</b> access Riviera, and will not be visible
                                on the employees page</span>
                    </li>
                    <li><strong>Hidden</strong><br>
                        <span>If a user is active but hidden, that user can access Riviera, but is
                            not visible on the employee page. This use case could be applicable to freelancers, that need to
                                be able to access Riviera (e.g. to create Internal Assignments)</span>
                    </li>
                </ul>
            </f-inline-message>

            <f-table
                v-if="fetchedData"
                ref="table"
                rows-hoverable
                :empty="(fetchedData && items.length === 0)"
            >
                <template #pre>
                    <f-pagination
                        v-if="fetchedData"
                        :current-page="currentPage"
                        :page-count="lastPage"
                        @navigate="onPaginationNavigate"
                    />
                </template>

                <template #head>
                    <f-table-header-row no-wrap>
                        <f-table-header-cell colspan="2">User</f-table-header-cell>
                        <f-table-header-cell>Department & discipline</f-table-header-cell>
                        <f-table-header-cell>Contact</f-table-header-cell>
                        <f-table-header-cell align="center">Lead</f-table-header-cell>
                        <f-table-header-cell align="center">Status</f-table-header-cell>
                        <f-table-header-cell width="20px"/>
                    </f-table-header-row>
                </template>

                <user-row
                    v-for="row in items"
                    :key="row.id"
                    :user="row"
                    @refresh="$emit('refresh')"
                />

                <template #post>
                    <f-pagination
                        v-if="fetchedData"
                        :current-page="currentPage"
                        :page-count="lastPage"
                        @navigate="onPaginationNavigate"
                        bottom
                    />
                </template>
            </f-table>

            <f-table-placeholder v-else-if="!fetchedData && loading"/>
        </f-container>

        <f-modal
            v-if="officeAttendanceModalOpen && hasPermission(Permissions.ADMIN_USERS_VIEW)"
            width="400px"
            @close="officeAttendanceModalOpen = false"
            title="Export office attendance"
        >
            <div style="padding-bottom: 10px;">
                <f-date-picker
                    type="month"
                    v-model="officeAttendanceExportDate"
                    :year-range="[2021, 2030]"
                    visual-format="MMMM YYYY"
                    width="240px"
                    popup-variant="light"
                />
            </div>
            <f-button
                :disabled="!officeAttendanceExportDate"
                icon="file-spreadsheet"
                value="Export"
                variant="primary"
                @click="exportOfficeAttendance"
            />
        </f-modal>
    </page>
</template>

<script>
import moment from 'moment';
import APIEndpoints from '../../../../config/APIEndpoints';
import Page from '../../Page';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import FInlineMessage from '../../../../f-components/notice/inline-message/FInlineMessage';
import FFilterRow from '../../../../f-components/data/filter/FFilterRow';
import FFilterItem from '../../../../f-components/data/filter/FFilterItem';
import FFilter from '../../../../f-components/data/filter/FFilter';
import FDropdown from '../../../../f-components/form-controls/dropdowns/FDropdown';
import OfficeOptionsMixin from '../../../../mixins/OfficeOptionsMixin';
import FTextInput from '../../../../f-components/form-controls/text/FTextInput';
import FModal from '../../../../f-components/other/modal/FModal';
import FDatePicker from '../../../../f-components/form-controls/dates/FDatePicker';
import FButton from '../../../../f-components/form-controls/buttons/FButton';
import EntityPaginatedFilteredOverviewMixin from '../../../../mixins/entities/EntityPaginatedFilteredOverviewMixin';
import FTable from '../../../../f-components/data/table/FTable';
import FTableHeaderCell from '../../../../f-components/data/table/FTableHeaderCell';
import UserRow from './components/UserRow';
import {Permissions} from '../../../../config/Permissions';
import FContainer from '../../../../f-components/layout/container/FContainer';
import FTableHeaderRow from '../../../../f-components/data/table/FTableHeaderRow';
import {Icons} from '../../../../f-components/layout/icons/config';
import FPagination from '../../../../f-components/navigation/pagination/FPagination';
import FTablePlaceholder from '../../../../f-components/loaders/placeholder/FTablePlaceholder';
import FUserDropdown from '../../../../f-components/form-controls/dropdowns/FUserDropdown';

export default {
    name: 'admin-users-page',

    components: {
        FUserDropdown,
        FTablePlaceholder,
        FPagination,
        FTableHeaderRow,
        FContainer,
        UserRow,
        FTableHeaderCell,
        FTable,
        FButton,
        FDatePicker,
        FModal,
        FTextInput,
        FDropdown,
        FFilter,
        FFilterItem,
        FFilterRow,
        FInlineMessage,
        FBreadcrumb,
        FBreadcrumbs,
        Page,
    },

    mixins: [
        OfficeOptionsMixin,
        EntityPaginatedFilteredOverviewMixin,
    ],

    data() {
        return {
            status: 'active',
            office: null,
            searchQuery: null,
            permissions: null,
            lead: null,

            userSearchQuery: undefined,

            officeAttendanceExportDate: moment(),
            officeAttendanceModalOpen: false,
        }
    },

    watch: {
        searchQuery() {
            this.onFilterChange();
        },
        office() {
            this.onFilterChange();
        },
        status() {
            this.onFilterChange();
        },
        permissions() {
            this.onFilterChange();
        },
        lead() {
            this.onFilterChange();
        },
    },

    computed: {
        permissionOptions() {
            const options = [];
            for (let key in Permissions) {
                options.push({
                    key: Permissions[key],
                    value: Permissions[key],
                });
            }
            return options;
        },
        actions() {
            if (!this.fetchedData) {
                return [];
            }

            let actions = [];

            actions.push({
                value: 'Export (filtered) users',
                icon: Icons.FILE_SPREADSHEET,
                onClick: () => this.exportUsers(),
            });

            actions.push({
                value: 'Export user attendance',
                icon: Icons.FILE_SPREADSHEET,
                onClick: () => this.officeAttendanceModalOpen = true,
            });

            // if (this.hasPermission(Permissions.ADMIN_USERS_EXPORT_BCS)) {
            //     actions.push({
            //         value: 'Export BCS users',
            //         icon: Icons.FILE_SPREADSHEET,
            //         onClick: () => this.exportBCSUsers(),
            //     });
            // }

            if (this.hasPermission(Permissions.ADMIN_USERS_SYNC)) {
                actions.push({
                    value: 'JAMF user sync',
                    icon: 'sync',
                    link: '/admin/jamf-sync',
                });

                // actions.push({
                //     value: 'Forecast user sync',
                //     icon: 'sync',
                //     link: '/admin/forecast-sync',
                // });
                // actions.push({
                //     value: 'BCS user sync',
                //     icon: 'sync',
                //     link: '/admin/bcs-sync',
                // });
            }

            return actions;
        }
    },

    methods: {
        getEndpoint() {
            return APIEndpoints.ADMIN_USERS;
        },

        getFilters() {
            return {
                'filter[query]': this.searchQuery,
                'filter[office]': this.office,
                'filter[status]': this.status,
                'filter[lead]': this.lead,
                'filter[permissions]': this.permissions,
            };
        },

        applyPreviousFilters(filters) {
            if (filters['filter[query]']) this.searchQuery = filters['filter[query]'];
            if (filters['filter[office]']) this.office = filters['filter[office]'];
            if (filters['filter[lead]']) this.lead = filters['filter[lead]'];
            if (filters['filter[status]']) this.status = filters['filter[status]'];
            if (filters['filter[permissions]']) this.permissions = filters['filter[permissions]'];
        },

        exportUsers() {
            window.open(APIEndpoints.secureRedirect(APIEndpoints.ADMIN_USERS_EXPORT, this.getFilters()));
        },

        exportBCSUsers() {
            window.open(APIEndpoints.secureRedirect(APIEndpoints.ADMIN_USERS_BCS_EXPORT, this.getFilters()));
        },

        exportOfficeAttendance() {
            window.open(APIEndpoints.secureRedirect(APIEndpoints.OFFICE_SPOT_EXPORT, {
                date: moment(this.officeAttendanceExportDate).format('MM-YYYY'),
            }));
        },
    },
}
</script>

<style lang="scss">
.admin-users-page {
    .f-filter__container {
        max-width: 600px;
    }

    .f-inline-message-body {
        ul > li {
            padding-bottom: 5px;

            > span {
                color: rgba(white, .5);
            }
        }
    }
}
</style>
