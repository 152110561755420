<template>
    <component
        :is="tag"
        :type="(tag !== 'button' ? null : type)"
        :class="[
            'f-button',
            ...classNames,
            `--${variant}`,
            `--${size}`,
            (showLoaderOnDisabled ? '--loader' : null),
            (fullWidth ? '--full-width' : null),
            (outlined ? '--outline' : null),
            (icon && !value ? '--icon-only' : null),
            (disabled && showLoaderOnDisabled ? '--loading' : null),
            (selected ? '--selected' : null),
            (disabled ? '--disabled' : null),
            '--no-select',
        ]"
        :disabled="disabled"
        :tabindex="tabindex"
        :href="href"
        :target="target"
        :rel="(target === '_blank' ? 'noopener noreferrer' : undefined)"
        @click="onClick"
    >
        <div class="f-button__value">
            <slot name="pre">
                <div
                    v-if="icon && iconPosition === 'left'"
                    class="f-button__icon --pre"
                >
                    <f-icon :icon="icon"/>
                </div>
            </slot>
            <slot>
                <span
                    class="f-button__value__text"
                    v-html="value"
                />
            </slot>
            <slot name="post">
                <div
                    v-if="icon && iconPosition === 'right'"
                    class="f-button__icon --post"
                >
                    <f-icon :icon="icon"/>
                </div>
            </slot>
        </div>
    </component>
</template>

<script>
import FIcon from '../../layout/icons/FIcon';
import {ButtonIconPositions, ButtonSizes, ButtonVariants} from './config';
import {isExternalUrl} from '../../../utils/urls';
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-button',

    mixins: [
        ClassNameMixin,
    ],

    components: {
        FIcon,
    },

    props: {
        tag: {
            type: String,
            default: 'button',
        },
        type: {
            type: String,
            default: 'button',
        },
        value: String,
        href: String,
        variant: {
            type: String,
            default: ButtonVariants.THEMED,
        },
        size: {
            type: String,
            default: ButtonSizes.MEDIUM,
        },
        icon: String,
        iconPosition: {
            type: String,
            default: ButtonIconPositions.LEFT,
        },
        showLoaderOnDisabled: Boolean,
        outlined: {
            type: Boolean,
            default: true,
        },
        fullWidth: Boolean,
        tabindex: String,
        selected: Boolean,
        clickEventName: String,
        disabled: Boolean,
    },

    computed: {
        target() {
            return this.href && isExternalUrl(this.href) ? '_blank' : undefined;
        },
    },

    methods: {
        onClick(e) {
            this.$emit('click', e);
        },
    },
}
</script>

<style lang="scss">
.f-button {
    width: auto;
    white-space: nowrap;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    overflow: hidden;
    line-height: 100%;
    border-radius: 50px;
    position: relative;
    text-decoration: none;
    transition: opacity .1s, background-color .1s, color .1s, border-color .1s;

    &, * {
        transition: color .1s;
    }

    &__value {
        cursor: pointer;
        position: relative;
        z-index: 2;
        text-align: center;
        line-height: 100%;
        width: 100%;
        height: 100%;
        text-overflow: ellipsis;
        border-radius: 999px;
        display: flex;
        align-items: center !important;
        justify-content: center;
    }

    // States

    &:focus, &:hover, &:active {
        text-decoration: none;
        outline: 0;
    }

    &:focus, &:active {
        @include theme(light) {
            box-shadow: 0 0 0 2px rgba($dark, .2);
        }

        @include theme(dark) {
            box-shadow: 0 0 0 2px rgba($light, .2);
        }
    }

    &[disabled] {
        pointer-events: none;
        opacity: 1;

        &:not(.--loader) {
            opacity: .4;
        }
    }

    // Variants

    &.--primary {
        background: $primary;

        &, * {
            color: $primaryFront;
        }

        @media (hover: hover) {
            .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                background: $primaryShade;

                &, * {
                    color: $primaryFront;
                }
            }
        }

        &.--loader.--loading:after {
            border-color: $primaryFront;
            border-right-color: rgba($primaryFront, .25);
        }

        &.--outline {
            border-color: $primary;

            &, * {
                color: $primary;
            }

            @media (hover: hover) {
                .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                    background: $primary;

                    &, * {
                        color: $primaryFront;
                    }
                }
            }

            &.--loader.--loading:after {
                border-color: $primary;
                border-right-color: rgba($primary, .25);
            }
        }
    }

    &.--secondary {
        background: $secondary;

        &, * {
            color: $secondaryFront;
        }

        @media (hover: hover) {
            .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                background: $secondaryShade;

                &, * {
                    color: $secondaryFront;
                }
            }
        }

        &.--loader.--loading:after {
            border-color: $secondaryFront;
            border-right-color: rgba($secondaryFront, .25);
        }

        &.--outline {
            border-color: $secondary;

            &, * {
                color: $secondary;
            }

            @media (hover: hover) {
                .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                    background: $secondary;

                    &, * {
                        color: $secondaryFront;
                    }
                }
            }

            &.--loader.--loading:after {
                border-color: $secondary;
                border-right-color: rgba($secondary, .25);
            }
        }
    }

    &.--dark,
    .--theme-light &.--themed,
    .--theme-dark .--theme-light &.--themed {
        background: $dark;

        &, * {
            color: $darkFront;
        }

        @media (hover: hover) {
            .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                background: $primary;

                &, * {
                    color: $primaryFront;
                }
            }
        }

        &.--loader.--loading:after {
            border-color: $darkFront;
            border-right-color: rgba($darkFront, .25);
        }

        &.--outline {
            background: transparent;
            border-color: $tertiaryGray;

            &, * {
                color: $dark;
            }

            @media (hover: hover) {
                .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                    background: $primary;
                    border-color: $primary;

                    &, * {
                        color: $primaryFront;
                    }
                }
            }

            &.--loader.--loading:after {
                border-color: $dark;
                border-right-color: rgba($dark, .25);
            }
        }
    }

    &.--light,
    .--theme-dark &.--themed,
    .--theme-light .--theme-dark &.--themed {
        background: $light;

        &, * {
            color: $lightFront;
        }

        @media (hover: hover) {
            .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                background: $primary;

                &, * {
                    color: $primaryFront;
                }
            }
        }

        &.--loader.--loading:after {
            border-color: $lightFront;
            border-right-color: rgba($lightFront, .25);
        }

        &.--outline {
            border-color: rgba($light, .25);
            background: transparent;

            &, * {
                color: $light;
            }

            @media (hover: hover) {
                .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                    border-color: rgba($light, .25);
                    background: $light;

                    &, * {
                        color: $dark !important;
                    }
                }
            }

            &.--loader.--loading:after {
                border-color: $light;
                border-right-color: rgba($light, .25);
            }
        }
    }

    &.--warning {
        background: $warning;

        &, * {
            color: $warningFront;
        }

        @media (hover: hover) {
            .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                background: $warningShade;

                &, * {
                    color: $warningFront;
                }
            }
        }

        &.--loader.--loading:after {
            border-color: $warningFront;
            border-right-color: rgba($warningFront, .25);
        }

        &.--outline {
            border-color: $warning;

            &, * {
                color: $warning;
            }

            @media (hover: hover) {
                .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                    background: $warning;

                    &, * {
                        color: $warningFront;
                    }
                }
            }

            &.--loader.--loading:after {
                border-color: $warning;
                border-right-color: rgba($warning, .25);
            }
        }
    }

    &.--danger {
        background: $danger;

        &, * {
            color: $dangerFront;
        }

        @media (hover: hover) {
            .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                background: $dangerShade;

                &, * {
                    color: $dangerFront;
                }
            }
        }

        &.--loader.--loading:after {
            border-color: $dangerFront;
            border-right-color: rgba($dangerFront, .25);
        }

        &.--outline {
            border-color: $danger;

            &, * {
                color: $danger;
            }

            @media (hover: hover) {
                .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                    background: $danger;

                    &, * {
                        color: $dangerFront;
                    }
                }
            }

            &.--loader.--loading:after {
                border-color: $danger;
                border-right-color: rgba($danger, .25);
            }
        }
    }

    &.--success {
        background: $success;

        &, * {
            color: $successFront;
        }

        @media (hover: hover) {
            .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                background: $successShade;

                &, * {
                    color: $successFront;
                }
            }
        }

        &.--loader.--loading:after {
            border-color: $successFront;
            border-right-color: rgba($successFront, .25);
        }

        &.--outline {
            border-color: $success;

            &, * {
                color: $success;
            }

            @media (hover: hover) {
                .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                    background: $success;

                    &, * {
                        color: $successFront;
                    }
                }
            }

            &.--loader.--loading:after {
                border-color: $success;
                border-right-color: rgba($success, .25);
            }
        }
    }

    // Sizes

    &.--xsmall, &.--xxsmall {
        height: 26px;
        min-width: 26px;
        font-size: 10px;
        padding: 0 10px;

        .f-button__icon {
            &.--pre {
                margin-right: 3px;
            }

            &.--post {
                margin-left: 3px;
            }
        }

        &.--icon-only {
            padding: 0 6px;
        }
    }

    &.--small {
        height: 30px;
        min-width: 30px;
        font-size: 12px;
        padding: 0 14px;

        .f-button__icon {
            &.--pre {
                margin-right: 6px;
            }

            &.--post {
                margin-left: 6px;
            }
        }

        &.--icon-only {
            padding: 0 8px;
        }
    }

    &.--medium {
        height: 34px;
        min-width: 34px;
        font-size: 13px;
        padding: 0 17px;

        .f-button__icon {
            &.--pre {
                margin-right: 8px;
            }

            &.--post {
                margin-left: 8px;
            }
        }

        &.--icon-only {
            padding: 0 10px;
        }
    }

    &.--large {
        height: 39px;
        min-width: 39px;
        font-size: 15px;
        padding: 0 19px;
        font-weight: 400;

        .f-button__icon {
            .f-icon {
                font-weight: 500;
            }

            &.--pre {
                margin-right: 10px;
            }

            &.--post {
                margin-left: 10px;
            }
        }

        &.--icon-only {
            padding: 0 11px;
        }
    }

    &.--xlarge {
        height: 46px;
        min-width: 46px;
        font-size: 16px;
        padding: 0 22px;
        font-weight: 400;

        .f-button__icon {
            .f-icon {
                font-weight: 500;
            }

            &.--pre {
                margin-right: 12px;
            }

            &.--post {
                margin-left: 12px;
            }
        }

        &.--icon-only {
            padding: 0 12px;
        }
    }

    // Settings

    &.--outline {
        background: transparent;
        border: 1px solid;
    }

    &.--full-width:not(.--loading) {
        width: 100%;
    }

    &.--loader {
        transition: all .15s ease-in-out;
        position: relative;

        .f-button__value {
            opacity: 1;
        }

        &:after {
            transition: opacity .15s;
            opacity: 0;
        }

        &.--loading {
            border-radius: 50%;
            overflow: hidden;

            .f-button__value {
                width: 0;
                opacity: 0;
                overflow: hidden;
                padding-left: 0;
                padding-right: 0;

                &:before {
                    opacity: 0;
                }
            }

            &:after {
                opacity: 1;
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                animation: rotate 1s infinite;
                border: 3px solid;
                border-radius: 50%;
            }

            &.--xsmall:after, &.--xxsmall:after {
                height: 14px;
                width: 14px;
                margin: -7px 0 0 -7px;
            }

            &.--small:after {
                height: 16px;
                width: 16px;
                margin: -8px 0 0 -8px;
            }

            &.--medium:after {
                height: 22px;
                width: 22px;
                margin: -11px 0 0 -11px;
            }

            &.--large:after {
                height: 26px;
                width: 26px;
                margin: -13px 0 0 -13px;
            }

            &.--xlarge:after {
                height: 30px;
                width: 30px;
                margin: -15px 0 0 -15px;
            }
        }
    }

    .f-icon {
        font-size: 90%;
    }

    &__icon {
        pointer-events: none;
        vertical-align: middle;

        .f-icon {
            font-weight: 600;
        }
    }

    &.--icon-only {
        .f-button__value {
            padding-left: 0;
            padding-right: 0;
        }

        .f-button__icon {
            margin: 0 !important;
        }

        &.--large, &.--xlarge {
            .f-button__icon .f-icon {
                font-weight: 500;
            }
        }
    }
}
</style>
