import ChildrenMixin from "./ChildrenMixin";

export default {
    mixins: [
        ChildrenMixin,
    ],

    data() {
        return {
            selectedRows: [],
        }
    },

    methods: {
        setSelectedRowState(selected, id) {
            // Remove from array
            if (!selected && this.selectedRows.includes(id)) {
                for (let i = 0; i < this.selectedRows.length; i++) {
                    if (this.selectedRows[i] === id) {
                        this.selectedRows.splice(i, 1);
                    }
                }
            }
            // Add to array
            else if (selected && !this.selectedRows.includes(id)) {
                this.selectedRows.push(id);
            }
        },

        clearSelectedRows() {
            this.selectedRows = [];
        },

        selectAllRows() {
            const children = this.getChildren('f-table-row');
            for (let i in children) {
                if (children[i].selectable && children[i].select) {
                    children[i].select();
                }
            }
        },

        deselectAllRows() {
            const children = this.getChildren('f-table-row');
            for (let i in children) {
                if (children[i].selectable && children[i].deselect) {
                    children[i].deselect();
                }
            }
            this.clearSelectedRows();
        },
    },
};
