var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['allocations-list-days-row']},_vm._l((_vm.dates),function(date,i){return _c('div',{key:i,staticClass:"allocations-list-days-row__date"},_vm._l((date.assignments),function(assignment){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                text: assignment.notes,
                wrap: true,
                variant: 'info'
            }),expression:"{\n                text: assignment.notes,\n                wrap: true,\n                variant: 'info'\n            }"}],key:assignment.id,class:[
                'allocations-list-days-row__assignment',
                '--no-select',
                (assignment.notes ? '--notes' : null),
                (assignment.is_excluded ? '--excluded' : null)
            ]},[_vm._v(" "+_vm._s(_vm.formatNumber(assignment.allocation))+" "+_vm._s(assignment.allocation === 1 ? 'hr' : 'hrs')+" ")])}),0)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }