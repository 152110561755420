<template>
    <div
        :class="[
            'handled-leave-requests-tab',
            (loading ? '--loading' : null),
        ]"
    >
        <f-filter
            show-active-filters
            :active-filters="activeFilters"
            :filter-casts="{
                user_id: 'user'
            }"
        >
            <f-filter-row>
                <f-filter-item>
                    <f-user-dropdown
                        v-model="requestee"
                        placeholder="Requestee"
                        class-name="--align-center"
                        width="100%"
                        :options="users"
                        resetable
                    />
                </f-filter-item>
            </f-filter-row>
        </f-filter>

        <leave-requests-admin-table
            v-if="fetchedData"
            :data="fetchedData"
            @navigate="onPaginationNavigate"
        />

        <f-table-placeholder v-else-if="!fetchedData && loading"/>
    </div>
</template>

<script>
import FFilter from '../../../../f-components/data/filter/FFilter';
import FFilterRow from '../../../../f-components/data/filter/FFilterRow';
import FFilterItem from '../../../../f-components/data/filter/FFilterItem';
import FUserDropdown from '../../../../f-components/form-controls/dropdowns/FUserDropdown';
import LeaveRequestsAdminTable from './LeaveRequestsAdminTable';
import EntityPaginatedFilteredOverviewMixin from '../../../../mixins/entities/EntityPaginatedFilteredOverviewMixin';
import APIEndpoints from '../../../../config/APIEndpoints';
import FTablePlaceholder from '../../../../f-components/loaders/placeholder/FTablePlaceholder';

export default {
    name: 'handled-leave-requests-tab',

    components: {
        FTablePlaceholder,
        LeaveRequestsAdminTable,
        FUserDropdown,
        FFilterItem,
        FFilterRow,
        FFilter,
    },

    mixins: [
        EntityPaginatedFilteredOverviewMixin,
    ],

    watch: {
        requestee() {
            this.onFilterChange();
        },
    },

    computed: {
        users() {
            return this.$store.state.$usersCollection
                .filter(user => !user.hide_from_list && user.office === 'amsterdam')
                .map((user) => {
                    return {
                        key: user.id,
                        value: user.name,
                        profile_image: user.profile_image,
                        office: user.office.ucfirst(),
                    }
                });
        },
    },

    data() {
        return {
            requestee: null,
        }
    },

    methods: {
        getEndpoint() {
            return APIEndpoints.ADMIN_LEAVE_REQUESTS_HANDLED;
        },

        getFilters() {
            return {
                'filter[user_id]': this.requestee,
            };
        },

        applyPreviousFilters(filters) {
            if (filters['filter[user_id]']) {
                this.requestee = filters['filter[user_id]'];
            }
        },
    },
}
</script>
