<template>
    <component
        :is="tag"
        :type="(tag !== 'button' ? null : type)"
        :class="[
            'f-icon-button',
            (variant ? `--${variant}` : null),
            `--${size}`,
            '--no-select',
            (outlined ? '--outline' : null),
            (noParentHover ? '--no-parent-hover' : null),
            (selected ? '--selected' : null),
            (fixed ? '--fixed' : null),
            className,
        ]"
        :disabled="disabled"
        :tabindex="tabindex"
        @click="$emit('click', $event)"
    >
        <f-icon
            v-if="icon !== 'custom'"
            :icon="icon"
        />
        <slot v-if="icon !== 'custom'"/>
        <div
            v-else-if="icon === 'custom'"
            class="f-icon-button__custom"
        >
            <slot/>
        </div>
    </component>
</template>

<script>
import FIcon from '../../layout/icons/FIcon';
import {ButtonSizes, ButtonVariants} from './config';

export default {
    name: 'f-icon-button',

    components: {
        FIcon,
    },

    props: {
        tag: {
            type: String,
            default: 'button',
        },
        className: String,
        variant: {
            type: String,
            default: ButtonVariants.THEMED,
        },
        size: {
            type: String,
            default: ButtonSizes.MEDIUM,
        },
        icon: {
            type: String,
            required: true,
        },
        outlined: Boolean,
        type: {
            type: String,
            default: 'button',
        },
        tabindex: String,
        selected: Boolean,
        disabled: Boolean,
        noParentHover: Boolean,
        fixed: Boolean,
    },
}
</script>

<style lang="scss">
.f-icon-button {
    text-decoration: none;
    border-radius: 50%;
    text-align: center;
    display: inline-flex;
    vertical-align: middle;
    transition: opacity .1s, background-color .1s, color .1s, border-color .1s;
    align-items: center;
    line-height: 0;
    position: relative;

    span {
        flex: 0 0 0;
        margin: auto;
        pointer-events: none;
    }

    &__custom {
        flex: 1;
        text-align: center;
        font-size: 90%;
        font-family: sans-serif;
        font-weight: 200;
    }

    @media (hover: hover) {
        .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover {
            text-decoration: none;
            background: rgba(#fff, .25);
        }
    }

    &:before {
        display: none;
    }

    // Sizes

    &.--xxsmall {
        width: 16px;
        height: 16px;
        font-size: 8px;
    }

    &.--xsmall {
        width: 22px;
        height: 22px;
        font-size: 10px;
    }

    &.--small {
        width: 26px;
        height: 26px;
        font-size: 11px;
    }

    &.--medium {
        width: 32px;
        height: 32px;
        font-size: 13px;
    }

    &.--large {
        width: 39px;
        height: 39px;
        font-size: 15px;

        .f-icon {
            font-weight: 500;
        }
    }

    &.--xlarge {
        width: 46px;
        height: 46px;
        font-size: 16px;

        .f-icon {
            font-weight: 500;
        }
    }

    &:hover {
        text-decoration: none;
    }

    .f-icon {
        font-size: 90%;
    }

    // Variants

    &.--primary {
        background: $primary;

        &, * {
            color: $primaryFront;
        }

        @media (hover: hover) {
            .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                background: $primaryShade;
            }
        }

        &.--outline {
            border-color: $primary;
            background: transparent;

            &, * {
                color: $primary;
            }

            @media (hover: hover) {
                .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover {
                    background: $primary;
                    border-color: $primary;

                    &, * {
                        color: $primaryFront;
                    }
                }
            }

            &.--selected {
                background: $primary;
                border-color: $primary;

                &, * {
                    color: $primaryFront;
                }
            }
        }
    }

    &.--secondary {
        background: $secondary;

        &, * {
            color: $secondaryFront;
        }

        @media (hover: hover) {
            .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                background: $secondaryShade;
            }
        }

        &.--outline {
            border-color: $secondary;
            background: transparent;

            &, * {
                color: $secondary;
            }

            @media (hover: hover) {
                .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                    background: $secondary;
                    border-color: $secondary;

                    &, * {
                        color: $secondaryFront;
                    }
                }
            }
        }
    }

    // Dark variant (for light theme)

    &.--dark,
    .--theme-light &.--themed,
    .--theme-dark .--theme-light &.--themed {
        background: $dark;

        &, * {
            color: $darkFront;
        }

        @media (hover: hover) {
            .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover, &.--selected {
                background: lighten($dark, 10%);
            }
        }

        &.--outline {
            border-color: $tertiaryGray;
            background: transparent;

            &, * {
                color: $dark;
            }

            @media (hover: hover) {
                .--hover &:not(.--no-parent-hover):not([disabled]), &:not([disabled]):hover {
                    background: $primary;
                    border-color: $primary;

                    &, * {
                        color: $primaryFront;
                    }
                }
            }

            &.--selected {
                background: $primary;
                border-color: $primary;

                &, * {
                    color: $primaryFront;
                }
            }
        }
    }

    @media (hover: hover) { // Extra selector
        .--theme-light .--hover &.--themed:not(.--no-parent-hover):not([disabled]),
        .--theme-dark .--theme-light .--hover &.--themed:not(.--no-parent-hover):not([disabled]) {
            background: $primary;
            border-color: $primary;

            &, * {
                color: $primaryFront;
            }
        }
    }

    // Light variant (for dark theme)

    &.--light,
    .--theme-dark &.--themed,
    .--theme-light .--theme-dark &.--themed {
        background: $light;

        &, * {
            color: $lightFront;
        }

        @media (hover: hover) {
            .--hover &:not(.--no-parent-hover):not([disabled]),
            &:not([disabled]):hover,
            &.--selected {
                background: darken($light, 10%);
            }
        }

        &.--outline {
            border-color: rgba($light, .25);
            background: transparent;

            &, * {
                color: $light;
            }

            // TODO: hover does not work when theme css query is active
            @media (hover: hover) {
                .--hover &:not(.--no-parent-hover):not([disabled]),
                &:not([disabled]):hover,
                &.--selected {
                    background: $light;
                    border-color: $light;

                    &, * {
                        color: $lightFront;
                    }
                }
            }
        }
    }

    @media (hover: hover) { // Extra selector
        .--theme-dark .--hover &.--themed:not(.--no-parent-hover):not([disabled]),
        .--theme-light .--theme-dark .--hover &.--themed:not(.--no-parent-hover):not([disabled]) {
            background: $light;
            border-color: $light;

            &, * {
                color: $lightFront;
            }
        }
    }

    // Info variant

    &.--info {
        background: $info;

        &, * {
            color: $infoFront;
        }

        @media (hover: hover) {
            .--hover &:not(.--no-parent-hover):not([disabled]),
            &:not([disabled]):hover,
            &.--selected {
                background: $infoShade;
            }
        }

        &.--outline {
            border-color: $info;
            background: transparent;

            &, * {
                color: $info;
            }

            @media (hover: hover) {
                .--hover &:not(.--no-parent-hover):not([disabled]),
                &:not([disabled]):hover,
                &.--selected {
                    background: $info;
                    border-color: $info;

                    &, * {
                        color: $infoFront;
                    }
                }
            }
        }
    }

    // Warning variant

    &.--warning {
        background: $warning;
        color: $warningFront;

        @media (hover: hover) {
            .--hover &:not(.--no-parent-hover):not([disabled]),
            &:not([disabled]):hover,
            &.--selected {
                background: $warningShade;
            }
        }

        &.--outline {
            border-color: $warning;
            background: transparent;

            &, * {
                color: $warning;
            }

            @media (hover: hover) {
                .--hover &:not(.--no-parent-hover):not([disabled]),
                &:not([disabled]):hover,
                &.--selected {
                    background: $warning;
                    border-color: $warning;

                    &, * {
                        color: $warningFront;
                    }
                }
            }
        }
    }

    // Danger variant

    &.--danger {
        background: $danger;

        &, * {
            color: $dangerFront;
        }

        @media (hover: hover) {
            .--hover &:not(.--no-parent-hover):not([disabled]),
            &:not([disabled]):hover,
            &.--selected {
                background: $dangerShade;

                &, * {
                    color: $dangerFront !important;
                }
            }
        }

        &.--outline {
            border-color: $danger;
            background: transparent;

            &, * {
                color: $danger;
            }

            @media (hover: hover) {
                .--hover &:not(.--no-parent-hover):not([disabled]),
                &:not([disabled]):hover,
                &.--selected {
                    background: $danger;
                    border-color: $danger;

                    &, * {
                        color: $dangerFront;
                    }
                }
            }
        }
    }

    &.--success {
        background: $success;

        &, * {
            color: $successFront;
        }

        @media (hover: hover) {
            .--hover &:not(.--no-parent-hover):not([disabled]),
            &:not([disabled]):hover,
            &.--selected {
                background: $successShade;
            }
        }

        &.--outline {
            border-color: $success;
            background: transparent;

            &, * {
                color: $success;
            }

            @media (hover: hover) {
                .--hover &:not(.--no-parent-hover):not([disabled]),
                &:not([disabled]):hover,
                &.--selected {
                    background: $success;
                    border-color: $success;

                    &, * {
                        color: $successFront;
                    }
                }
            }
        }
    }

    // Settings

    &.--outline {
        border: 1px solid transparent;
    }

    &.--fixed {
        bottom: 20px;
        right: 20px;
        position: fixed;
        z-index: $fixedButtonOrder;
        transition: transform .1s;
        box-shadow: rgba(black, .3) 0 0 10px;

        &:hover {
            transform: scale(1.2);
        }
    }

    .f-icon {
        font-weight: 600;
    }

    // States

    &:focus, &:hover, &:active {
        text-decoration: none;
        outline: 0;
    }

    &:focus, &:active {
        @include theme(light) {
            box-shadow: 0 0 0 2px rgba($dark, .2);
        }

        @include theme(dark) {
            box-shadow: 0 0 0 2px rgba($light, .2);
        }
    }

    &[disabled] {
        opacity: .25;
        pointer-events: none;
    }
}
</style>
