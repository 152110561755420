<template>
    <widget
        v-if="newPeople.length > 0"
        title="Recent hires"
        class-name="new-people-widget"
        :badge="newPeople.length"
        enabled-setting="widgets.new_people.enabled"
        extra-break-points
        header="New colleagues"
    >
        <widget-user-list ref="list">
            <widget-user-list-item
                v-for="(user, i) in newPeople"
                :key="i"
            >
                <user-card-compact
                    :user="userFromName(user.name) || user"
                    full-width
                />
            </widget-user-list-item>
        </widget-user-list>
    </widget>
</template>

<script>
import moment from 'moment-timezone';
import APIDataStoreResources from '../../../config/APIDataStoreResources';
import WidgetSizeMixin from '../WidgetSizeMixin';
import APIDataStore from '../../../services/APIDataStore';
import UserHelperMixin from '../../../mixins/UserHelperMixin';
import Widget from '../Widget';
import UserCardCompact from "../../cards/users/UserCardCompact";
import WidgetUserList from '../../misc/widget-user-list/WidgetUserList';
import WidgetUserListItem from '../../misc/widget-user-list/WidgetUserListItem';

export default {
    name: 'new-people-widget',

    components: {
        WidgetUserListItem,
        WidgetUserList,
        UserCardCompact,
        Widget,
    },

    computed: {
        newPeople() {
            return this.$store.state.$usersCollection
                .filter((user) => {
                    if (user.hide_from_list || !user.created_at || user.type !== 'employee') return false;

                    const startDate = moment(user.started_at ? user.started_at : user.created_at).startOf('day'),
                        compare = moment().subtract(1, 'M').startOf('day');

                    return startDate >= compare;
                })
                .sort((a, b) => {
                    const now = moment().startOf('day'),
                        aDate = moment(a.started_at ? a.started_at : a.created_at).year(now.year()).startOf('day').unix(),
                        bDate = moment(b.started_at ? b.started_at : b.created_at).year(now.year()).startOf('day').unix();

                    return aDate - bDate;
                });
        },
    },

    mixins: [
        WidgetSizeMixin,
        UserHelperMixin,
    ],

    created() {
        APIDataStore.check(APIDataStoreResources.USERS_COLLECTION);
    },

    watch: {
        newPeople() {
            this.$nextTick(() => {
                if (this.$refs.list) {
                    this.$refs.list.$refs.scrollPane.invalidate();
                }
            });
        },
    },

    methods: {
        getVisualBirthday(user) {
            if (this.hasBirthday(user)) {
                return 'Today';
            }

            const now = moment().startOf('day'),
                birthDay = moment(user.created_at).year(now.year()).startOf('day');

            return birthDay.fromNow();
        },
    },
}
</script>

<style lang="scss">
.new-people-widget {
}
</style>
