export const DatePickerTypes = {
    DAY: 'day',
    MONTH: 'month',
};

export const DatePickerModes = {
    SINGLE: 'single',
    MULTI: 'multi',
    RANGE: 'range',
};

export const DatePickerSelectVariants = {
    DARK: 'dark',
    LIGHT: 'light',
}
