<template>
    <div :class="['f-content', ...classNames]">
        <slot/>
    </div>
</template>

<script>
import ClassNameMixin from '../../../mixins/ClassNameMixin';
import SecureLinksMixin from '../../../mixins/SecureLinksMixin';
import UserHelperMixin from '../../../mixins/UserHelperMixin';

export default {
    name: 'f-content',

    mixins: [
        ClassNameMixin,
        SecureLinksMixin,
        UserHelperMixin,
    ],

    mounted() {
        this.parseSecureLinks();
        this.parseUserCards();
    }
}
</script>
