<template>
    <f-flex
        :class-name="[
            'f-panel-header',
            ...classNames,
        ]"
        main-axis-align="space-between"
    >
        <h2
            v-if="typeof heading === 'boolean'"
            class="f-panel-header__title"
        >
            <span><slot name="title"/></span>
        </h2>
        <h2
            v-else-if="heading"
            class="f-panel-header__title"
            @click="onTitleClick"
        >
            <span v-html="
               Array.isArray(heading) ?
                   heading.join(` <span class='f-panel-header__title__spacer'>/</span> `) :
                   (typeof heading === 'string' ? heading : '')
            "/>
            <slot name="title"/>
        </h2>

        <div>
            <slot/>

            <transition v-if="headingBadge !== undefined" name="fade" appear>
                <f-badge variant="alternative" size="large" inline>
                    {{ headingBadge }}
                </f-badge>
            </transition>
        </div>
    </f-flex>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import FBadge from '../../data/badge/FBadge';
import FFlex from '../../layout/flex/FFlex';
import ClassNameMixin from '../../../mixins/ClassNameMixin';

export default {
    name: 'f-panel-header',

    mixins: [
        ClassNameMixin,
    ],

    components: {
        FFlex,
        FBadge,
    },

    props: {
        heading: String | Array | Boolean,
        headingBadge: String | Number,
    },

    methods: {
        onTitleClick() {
            this.$emit('titleClick');
        },
    }
}
</script>

<style lang="scss">
.f-panel-header {
    text-align: left;
    line-height: 100%;
    user-select: none;
    position: relative;
    padding: 0 25px;
    min-height: 60px;

    @include media(mobile-down) {
        min-height: 50px;
        padding: 0 15px;
    }

    @include theme(light) {
        border-bottom: 1px solid $softGray;
    }

    @include theme(dark) {
        border-bottom: 1px solid rgba(white, .1);
    }

    * {
        vertical-align: middle;
    }

    &__title {
        padding: 14px 0 10px;
        font-size: 22px;
        font-weight: 600;
        margin: 0;

        @include media(mobile-down) {
            font-size: 18px;
        }

        &__spacer {
            height: 14px;
            display: inline-block;
            margin: 0 2px;
            opacity: .25;
            font-weight: 300;
        }

        .f-panel.--collapsed & {
            padding: 20px;
            cursor: pointer;

            @include media(mobile-down) {
                padding: 15px 10px 15px;
            }
        }
    }

    .f-badge {
        margin: -10px 0 -10px 0;
    }
}
</style>
