var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:[
        'f-flex-table-row',
        _vm.className,
        (_vm.open ? '--open' : (_vm.collapsible ? '--closed' : null)),
        (_vm.collapsible ? '--collapsible' : null),
        (_vm.overflowHidden ? '--overflow-hidden': null),
        (_vm.noLeftPadding ? '--no-left-padding': null),
    ],on:{"mouseenter":function($event){_vm.hasHover = true},"mouseleave":function($event){_vm.hasHover = false}}},[(_vm.collapsible)?_c('button',{staticClass:"f-flex-table-row__collapsible",attrs:{"type":"button"},on:{"click":_vm.toggle}},[_vm._t("default"),(_vm.collapsible)?_c('f-icon-button',{attrs:{"class-name":"f-flex-table-row__collapsible__chevron","size":_vm.iconSize,"icon":(_vm.collapsibleType === 'chevron' ? (_vm.open ? 'chevron-up' : 'chevron-down') : (_vm.open ? 'minus' : 'plus')),"selected":_vm.open || _vm.hasHover,"outlined":""}}):_vm._e()],2):_c('div',{staticClass:"f-flex-table-row__title"},[_vm._t("default")],2),_c('transition',{attrs:{"name":"height","appear":""}},[(_vm.open && _vm.collapsible)?_c('div',{class:[
                'f-flex-table-row__content',
                (_vm.noContentIndention ? '--no-indention' : null),
            ]},[_vm._t("content")],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }