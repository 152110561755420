import moment from 'moment-timezone';
import EventBus from './EventBus';
import Store, {TokenState} from './Store';
import APIEndpoints from '../config/APIEndpoints';
import {GlobalEvents} from '../config/Events';

const APIDataStore = function () {
    this.cacheDates = JSON.parse(window.localStorage.getItem('riviera.cached-store.dates'));
    this.intervals = {};
    this.activity = {};
    this.debugEnabled = false;

    if (!this.cacheDates) {
        this.cacheDates = {};
    }

    EventBus.$on(GlobalEvents.LOGOUT_SUCCESS, () => this.clearAllIntervals());
};

APIDataStore.prototype = {
    /**
     * Fetch a resource.
     *
     * @param {APIDataStoreResource} resource
     * @param {APIDataStoreRequestOptions} [options]
     */
    fetch(resource, options = {}) {
        this.debug(`\x1b[33mfetching`, this.getStorageKey(resource, options), 'requesting:');

        this.request(resource, options);
    },

    /**
     * Check if a resource needs to be fetched.
     * If there is a valid cache for this resource, it does not fetch.
     *
     * @param {APIDataStoreResource} resource
     * @param {APIDataStoreRequestOptions} [options]
     */
    check(resource, options = {}) {
        const cacheKey = this.getCacheKey(resource, options);
        const cacheDate = this.getCacheDate(cacheKey);

        if (!cacheDate) {
            this.debug('\x1b[33mchecking', cacheKey, `cache missing, requesting:`);

            this.request(resource, options);
        } else if (new Date() > cacheDate) {
            this.debug('\x1b[33mchecking', cacheKey, `cache past date [${cacheDate.toLocaleString()}], requesting:`);

            this.request(resource, options);
        } else {
            this.debug('\x1b[33mchecking', cacheKey, `✅ [${cacheDate.toLocaleString()}]`);
        }
    },

    /**
     * @param {APIDataStoreResource} resource
     * @param {APIDataStoreRequestOptions} [options]
     */
    request(resource, options) {
        const storageKey = this.getStorageKey(resource, options);

        if (Store.getters.accessTokenState !== TokenState.VALID) {
            this.debug(`\x1b[34mrequesting`, storageKey, 'invalid token');
            return;
        }

        // Check if already running
        if (this.activity[storageKey]) {
            this.debug(`\x1b[34mrequesting`, storageKey, 'skipping, already running');
            return;
        }

        const endpoint = APIEndpoints.parse(resource.endpoint, options.params);

        this.debug(`\x1b[34mrequesting`, storageKey, `starting request to ${endpoint} ${(options.fromInterval === true ? ' ⏱' : '')}`);

        // Set activity
        this.activity[storageKey] = true;

        // Clear interval
        this.clearInterval(storageKey);

        // Request settings
        const requestConfig = {};
        if (options && options.queryParams) {
            requestConfig.params = options.queryParams;
        }

        // Don't emit site load if its a refresh interval
        if (options.fromInterval !== true) {
            EventBus.$emit(GlobalEvents.SITE_LOAD_START);
        }

        // Request!
        window.axios
            .get(endpoint, requestConfig)
            .then(response => this.requestSuccess(response.data, resource, options))
            .catch(error => this.requestFailure(error, resource, options));
    },

    /**
     * @param error
     * @param {APIDataStoreResource} resource
     * @param {APIDataStoreRequestOptions} options
     */
    requestFailure(error, resource, options) {
        const storageKey = this.getStorageKey(resource, options);

        this.debug(`\x1b[31mrequestFailure`, storageKey, error);

        // Clear interval after failed attempt
        if (resource.refreshInterval) {
            this.clearInterval(storageKey);
        }

        // Stop activity
        this.activity[storageKey] = false;

        // Emit error event
        EventBus.$emit(`ds.${storageKey}.error`, error);

        // Site load finish
        if (options.fromInterval !== true) {
            EventBus.$emit(GlobalEvents.SITE_LOAD_FINISH);
        }
    },

    /**
     * @param responseData
     * @param {APIDataStoreResource} resource
     * @param {APIDataStoreRequestOptions} options
     */
    requestSuccess(responseData, resource, options) {
        const storageKey = this.getStorageKey(resource, options);
        const cacheKey = this.getCacheKey(resource, options);

        this.debug('\x1b[32mrequestSuccess', storageKey);

        // Verify result
        if (!responseData.data) {
            console.error(`Incorrect request result, missing data variable for API call [${storageKey}]`);
            return;
        }

        // Parse data
        let dataSet;
        if ((options && options.params) || resource.paginated) {
            dataSet = responseData;
        } else {
            dataSet = responseData.data;
        }

        // Add to store
        if (options && options.params) {
            this.debug('\x1b[32mrequestSuccess', storageKey, `committing data obj to store on $${storageKey}`);

            Store.commit(`$${storageKey}`, {
                data: dataSet,
                ...options.params,
            });
        } else {
            this.debug('\x1b[32mrequestSuccess', storageKey, `committing data to store on $${storageKey}`);

            Store.commit(`$${storageKey}`, dataSet);
        }

        // Event
        EventBus.$emit(`ds.${cacheKey}.update`);

        // Set cache time
        if (resource.cacheDuration) {
            let cacheDate;

            // Check if date instance
            if (resource.cacheDuration instanceof Date) {
                cacheDate = resource.cacheDuration;
            }
            // Else it's an int in seconds
            else {
                cacheDate = new Date();
                cacheDate.setSeconds(cacheDate.getSeconds() + resource.cacheDuration);
            }

            // Set store date
            this.setCacheDate(cacheKey, cacheDate.toISOString());
        }

        // Set interval if needed
        if (resource.refreshInterval) {
            this.debug('\x1b[32mrequestSuccess', storageKey, 'setting interval at ' + (resource.refreshInterval * 1000));

            this.clearInterval(storageKey);

            this.intervals[storageKey] = window.setTimeout(() => {
                if (window.isMeetingRoomScreen || !document.visibilityState || (document.visibilityState && document.visibilityState !== 'hidden')) {
                    this.fetch(resource, {
                        fromInterval: true,
                        ...options,
                    });
                }
            }, resource.refreshInterval * 1000);
        }

        // Stop activity
        this.activity[storageKey] = false;

        // On complete
        if (options.onComplete) {
            options.onComplete(dataSet);
        }

        // Site load finish
        if (options.fromInterval !== true) {
            EventBus.$emit(GlobalEvents.SITE_LOAD_FINISH);
        }
    },

    /**
     * @param {string} cacheKey
     * @returns {(Date|null)}
     */
    getCacheDate(cacheKey) {
        if (this.cacheDates[cacheKey]) {
            return new Date(this.cacheDates[cacheKey]);
        }
        return null;
    },

    /**
     * @param {string} cacheKey
     * @param {string} date
     */
    setCacheDate(cacheKey, date) {
        this.cacheDates[cacheKey] = date;

        this.debug('\x1b[37msetCacheDate', cacheKey, `setting cache at ${date}`);

        window.localStorage.setItem('riviera.cached-store.dates', JSON.stringify(this.cacheDates));
    },

    /**
     * Remove all intervals.
     */
    clearAllIntervals() {
        this.debug('clearAllIntervals');

        for (let storageKey in this.intervals) {
            if (Object.prototype.hasOwnProperty.call(this.intervals.hasOwnProperty, storageKey)) {
                this.clearInterval(storageKey);
            }
        }
    },

    /**
     * Remove cache.
     */
    removeCache() {
        this.cacheDates = {};
        window.localStorage.setItem('riviera.cached-store.dates', JSON.stringify(this.cacheDates));
    },

    /**
     * @param {string} storageKey
     */
    clearInterval(storageKey) {
        if (this.intervals[storageKey]) {
            window.clearTimeout(this.intervals[storageKey]);

            this.intervals[storageKey] = false;
        }
    },

    /**
     * Date helper.
     *
     * @returns {string}
     * @todo: Remove this from here?
     */
    date(increment) {
        const date = moment();

        if (increment) {
            date.add(increment, 'days');

            // Skip weekends
            if (date.format('dddd') === 'Saturday') {
                date.add(2, 'days');
            } else if (date.format('dddd') === 'Sunday') {
                date.add(-2, 'days');
            }
        }

        return date.format('DD-MM-YYYY');
    },

    /**
     * @param {APIDataStoreResource} resource
     * @param {APIDataStoreRequestOptions} [options]
     * @returns {string}
     */
    getStorageKey(resource, options) {
        if (!resource || !resource.id) {
            console.error('Missing storage resource ID', resource);
        }

        let storageKey = resource.id;
        const matches = Array.from(storageKey.matchAll(/:([a-zA-Z_]+)/g));

        for (let i in matches) {
            // Verify if param is provided
            if (Object.prototype.hasOwnProperty.call(matches, i) && !options.params[matches[i][1]]) {
                throw Error(`Missing param ${matches[i][0]} for storageKey ${storageKey}`);
            }
            // Replace param
            storageKey = storageKey.replace(matches[i][0], options.params[matches[i][1]].ucfirst());
        }

        return storageKey;
    },

    /**
     * @param {APIDataStoreResource} resource
     * @param {APIDataStoreRequestOptions} [options]
     * @returns {string}
     */
    getCacheKey(resource, options) {
        let cacheKey = resource.id;
        const matches = Array.from(cacheKey.matchAll(/:([a-zA-Z_]+)/g));
        const matchedParams = [];

        for (let i in matches) {
            cacheKey = cacheKey.replace(matches[i][0], options.params[matches[i][1]].ucfirst());
            matchedParams.push(matches[i][1]);
        }

        if (options.params) {
            for (let k in options.params) {
                if (!matchedParams.includes(k)) {
                    cacheKey = `${cacheKey}:${options.params[k]}`;
                }
            }
        }

        return cacheKey;
    },

    /**
     * @param {string} storageKey
     */
    delete(storageKey) {
        this.debug('delete', storageKey);

        this.clearInterval(storageKey);
        delete this.cacheDates[storageKey];
        window.localStorage.setItem('riviera.cached-store.dates', JSON.stringify(this.cacheDates));

        Store.dispatch('delete', `$${storageKey}`);
    },

    /**
     * @param {string} method
     * @param {string} [storageKey]
     * @param {any} [message]
     */
    debug(method, storageKey = '', message = '') {
        if (this.debugEnabled) {
            const log = `\x1b[36mAPIDataStore.${method} \x1b[35m${storageKey} \x1b[39m${message ? message : ""}`;
            console.log(log);
        }
    },
};

export default new APIDataStore;
