<template>
    <f-abstract-input-field
        v-bind="boundProps"
        :class-name="[
            'f-text-input',
            ...classNames,
            (locked && lockable ? '--locked' : null),
        ]"
        :focussed="hasFocus"
        :has-content="!!inputValue"
        :input-error="inputError"
        :show-error="showError"
        @reset="inputValue = null; $emit('reset')"
    >
        <template #pre>
            <slot name="pre"/>
            <f-icon
                v-if="preIcon"
                :icon="preIcon"
            />
        </template>

        <template v-slot:default>
            <input
                ref="input"
                v-model="inputValue"
                autocomplete="chrome-off"
                :class="['f-text-input__input', (align ? `--align-${align}` : null)]"
                :name="uid"
                :type="type"
                :placeholder="placeholder"
                :disabled="disabled || (locked && lockable)"
                :readonly="readonly"
                :maxlength="maxLength"
                :tabindex="tabIndex"
                @focus="onFocus"
                @blur="onBlur"
                @keyup.enter="$emit('enter', $event)"
            />
        </template>

        <template #post>
            <f-icon
                v-if="postIcon && !lockable"
                :icon="postIcon"
            />
            <f-icon-button
                v-if="lockable && !readonly"
                :icon="(locked ? 'lock' : 'unlock')"
                v-tooltip="{text: (locked ? 'Unlock' : 'Lock')}"
                @click="toggleLock"
                outlined
                variant="light"
                size="xsmall"
                tabindex="-1"
            />
        </template>
    </f-abstract-input-field>
</template>

<script>
import FAbstractInputField from '../base/FAbstractInputField';
import FIcon from '../../layout/icons/FIcon';
import AbstractInputFieldPropsMixin from '../base/AbstractInputFieldPropsMixin';
import AbstractInputEventsMixin from '../base/AbstractInputEventsMixin';
import FIconButton from '../buttons/FIconButton';
import AbstractInputMixin from '../base/AbstractInputMixin';
import AbstractInputValidationMixin from '../base/AbstractInputValidationMixin';

export default {
    name: 'f-text-input',

    components: {
        FIconButton,
        FIcon,
        FAbstractInputField,
    },

    mixins: [
        AbstractInputFieldPropsMixin,
        AbstractInputValidationMixin,
        AbstractInputEventsMixin,
        AbstractInputMixin,
    ],

    props: {
        type: {
            type: String,
            default: 'text',
        },
        maxLength: Number,
        lockable: Boolean,
    },

    watch: {
        inputValue(value) {
            this.$emit('input', value);
        },
        value(value) {
            this.inputValue = value;
        },
    },

    data() {
        return {
            inputValue: this.value,
            locked: this.lockable,
        }
    },

    methods: {
        toggleLock() {
            if (!this.lockable) {
                return;
            }
            this.locked = !this.locked;
        },

        getValue() {
            return this.inputValue;
        },

        getExcludedProps() {
            return [
                'placeholder',
                'type',
                'validation',
            ];
        },
    },
}
</script>

<style lang="scss">
.f-text-input {
    &__input {
        width: 100%;
        padding: 10px;
        font-size: 13px;
        color: $light;
        background: transparent;
    }

    &.--locked {
        .f-text-input__input {
            opacity: .5;
            cursor: not-allowed;
        }
    }
}
</style>
