import { render, staticRenderFns } from "./LeaveRequestsAdminPage.vue?vue&type=template&id=4f857763&"
import script from "./LeaveRequestsAdminPage.vue?vue&type=script&lang=js&"
export * from "./LeaveRequestsAdminPage.vue?vue&type=script&lang=js&"
import style0 from "./LeaveRequestsAdminPage.vue?vue&type=style&index=0&id=4f857763&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports