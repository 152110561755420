var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",class:[
        'f-icon-button',
        (_vm.variant ? `--${_vm.variant}` : null),
        `--${_vm.size}`,
        '--no-select',
        (_vm.outlined ? '--outline' : null),
        (_vm.noParentHover ? '--no-parent-hover' : null),
        (_vm.selected ? '--selected' : null),
        (_vm.fixed ? '--fixed' : null),
        _vm.className,
    ],attrs:{"type":(_vm.tag !== 'button' ? null : _vm.type),"disabled":_vm.disabled,"tabindex":_vm.tabindex},on:{"click":function($event){return _vm.$emit('click', $event)}}},[(_vm.icon !== 'custom')?_c('f-icon',{attrs:{"icon":_vm.icon}}):_vm._e(),(_vm.icon !== 'custom')?_vm._t("default"):(_vm.icon === 'custom')?_c('div',{staticClass:"f-icon-button__custom"},[_vm._t("default")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }