<template>
    <page
        v-if="hasPermission(Permissions.ADMIN_GALLERIES)"
        class-name="admin-gallery-edit-create-page"
        :loading="loading"
        :title="['Admin', 'Picture Galleries', mode.ucfirst()]"
        :header="`${mode.ucfirst()} picture gallery`"
        :skewed-footer-foreground="(!dataLoaded && loading ? '#fff' : null)"
        stretch-first-container
        pre-max-width="800px"
        :actions="[{
            icon: 'trash2',
            value: 'Delete',
            onClick: () => deleteGallery($route.params.id, fields.title),
        }, {
            icon: 'link',
            value: 'Deeplink',
            disabled: fields.on_timeline && !fields.published_at,
            onClick: () => openDeeplink(fetchedData ? 'https://riviera.codedazur.io/' + fetchedData.data.deeplink : ''),
        }]"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb link="/admin/galleries">
                    Picture galleries
                </f-breadcrumb>
                <f-breadcrumb
                    v-if="mode === 'edit'"
                    :loading="!dataLoaded"
                >
                    {{ fields.title }}
                </f-breadcrumb>
                <f-breadcrumb>
                    {{ mode }}
                </f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-form
            ref="form"
            enctype="multipart/form-data"
            @submitValid="submit"
            validate
        >
            <f-container max-width="800px">
                <div v-if="dataLoaded">
                    <f-form-row label="Title">
                        <f-text-input
                            name="title"
                            v-model="fields.title"
                            max-width="300px"
                            :validation="['required']"
                            @input="fields.slug = $event.slugify()"
                        />
                    </f-form-row>

                    <f-form-row label="Subtitle">
                        <f-text-input
                            name="subtitle"
                            v-model="fields.subtitle"
                            max-width="300px"
                            placeholder="Party pics"
                            :validation="['required']"
                        />
                    </f-form-row>

                    <f-form-row label="Slug">
                        <f-text-input
                            name="slug"
                            v-model="fields.slug"
                            max-width="300px"
                            :validation="['required']"
                            readonly
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Date" help="Event date (also used for timeline)">
                        <f-date-picker
                            name="date"
                            :validation="['required']"
                            v-model="fields.date"
                            max-width="200px"
                        />
                    </f-form-row>

                    <f-form-row
                        label="Description"
                        help="Description is not implemented yet, will be visible in the gallery in the near future"
                    >
                        <f-editor
                            name="description"
                            v-model="fields.description"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row
                        label="Download URL"
                        help="You can optionally provide a link to for example a Google Drive directory where employees can view the originals / download the gallery images"
                    >
                        <f-text-input
                            name="download URL"
                            v-model="fields.download_url"
                            pre-icon="network"
                            placeholder="https://"
                            :validation="['url']"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Published" v-if="mode !== 'create'">
                        <f-switch
                            :disabled="!(images && (images.length - queueCount) > 0)"
                            v-model="published"
                        />

                        <f-inline-message
                            v-if="mode !== 'create' && !fields.published_at && fields.on_timeline"
                            variant="info"
                            id="createWarningImageUpload"
                            :closable="false"
                            :style="{ marginTop: '20px' }"
                        >
                            This picture gallery is not published yet. You can publish a picture gallery to the
                            timeline, once it contains successfully uploaded pictures.<br>
                        </f-inline-message>
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-fieldset legend="Timeline">
                        <f-form-row label="Cover">
                            <f-image-input
                                type="square"
                                ref="coverImageUploadField"
                                name="cover image"
                                :src="(fetchedData ? fetchedData.data.cover_image_url : undefined)"
                                :validation="['required']"
                                v-model="coverImageComputed"
                            />
                        </f-form-row>

                        <f-form-row-spacer/>

                        <f-form-row label="Include on timeline" help="Add to the homepage timeline">
                            <f-switch v-model="fields.on_timeline"/>
                        </f-form-row>

                        <f-form-row
                            v-if="fields.on_timeline"
                            label="Pin"
                            help="Pinning this gallery, will always show it first on the timeline"
                        >
                            <f-switch v-model="fields.is_pinned"/>
                        </f-form-row>

                        <f-form-row-spacer v-if="fields.on_timeline"/>

                        <f-form-row
                            v-if="fields.on_timeline"
                            label="Only show for office(s)"
                            help="Only show the gallery for the employee that belongs to the selected office(s). If empty, it will be shown to all offices"
                        >
                            <f-chips-input
                                v-model="fields.show_for_offices"
                                name="show for offices"
                                :options="officeOptionsFlat"
                            />
                        </f-form-row>

                        <f-form-row
                            v-if="fields.on_timeline"
                            label="Hide for freelancers"
                            help="If selected, the gallery will not be shown for freelancers on the timeline"
                        >
                            <f-switch v-model="fields.hide_for_freelancers"/>
                        </f-form-row>
                    </f-form-fieldset>
                </div>

                <f-form-placeholder v-else-if="!dataLoaded && loading"/>
            </f-container>

            <skewed-section
                v-if="dataLoaded"
                foreground-color="#333"
                after-background="#222"
                padding="100px 0 150px"
                margin="100px 0 -50px"
                id="upload"
            >
                <f-theme-provider variant="dark">
                    <f-container max-width="800px">
                        <bullet-point>Pictures</bullet-point>

                        <f-form-row-spacer/>

                        <f-images-input
                            v-if="mode === 'edit'"
                            ref="imagesUpload"
                            :upload-endpoint="uploadUrl"
                            upload-button
                            @queue-change="queueCount = $event"
                            :default-files="defaultFiles"
                            v-model="images"
                            @loading="$event ? startSilentLoading() : endLoading(true)"
                        />

                        <f-inline-message
                            v-else
                            variant="info"
                            id="createWarningImageUpload"
                            :closable="false"
                        >
                            You can upload pictures after creating the gallery.
                        </f-inline-message>
                    </f-container>
                </f-theme-provider>
            </skewed-section>

            <entity-action-button
                v-if="dataLoaded"
                :permission="Permissions.ADMIN_GALLERIES"
                :tooltip="(mode === 'edit' ? 'Update' : 'Create')"
                :disabled="loading"
                icon="floppy-disk"
                @click="onSubmit"
            />
        </f-form>
    </page>
</template>

<script>
import APIEndpoints from '../../../../config/APIEndpoints';
import OfficeOptionsMixin from '../../../../mixins/OfficeOptionsMixin';
import FForm from '../../../../f-components/form-controls/forms/FForm';
import FFormRow from '../../../../f-components/form-controls/forms/FFormRow';
import FImageInput from '../../../../f-components/form-controls/images/FImageInput';
import Page from '../../Page';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import EntityCreateEditMixin from "../../../../mixins/entities/EntityCreateEditMixin";
import FFormRowSpacer from "../../../../f-components/form-controls/forms/FFormRowSpacer";
import FTextInput from "../../../../f-components/form-controls/text/FTextInput";
import FFormPlaceholder from '../../../../f-components/loaders/placeholder/FFormPlaceholder';
import FContainer from '../../../../f-components/layout/container/FContainer';
import EntityActionButton from '../../../misc/entities/EntityActionButton';
import FEditor from '../../../../f-components/form-controls/editor/FEditor';
import FDatePicker from '../../../../f-components/form-controls/dates/FDatePicker';
import FThemeProvider from '../../../../f-components/layout/theme/FThemeProvider';
import SkewedSection from '../../../misc/skewed-section/SkewedSection';
import BulletPoint from '../../../misc/BulletPoint';
import FImagesInput from '../../../../f-components/form-controls/images/FImagesInput';
import FInlineMessage from '../../../../f-components/notice/inline-message/FInlineMessage';
import {FileStatus} from '../../../../f-components/form-controls/images/config';
import moment from 'moment';
import AdminGalleryMixin from './AdminGalleryMixin';
import FFormFieldset from '../../../../f-components/form-controls/forms/FFormFieldSet';
import FChipsInput from '../../../../f-components/form-controls/chips/FChipsInput';
import FSwitch from '../../../../f-components/form-controls/switches/FSwitch';

export default {
    name: 'admin-gallery-edit-create-page',

    components: {
        FSwitch,
        FChipsInput,
        FFormFieldset,
        FInlineMessage,
        FImagesInput,
        BulletPoint,
        SkewedSection,
        FThemeProvider,
        FDatePicker,
        FEditor,
        EntityActionButton,
        FContainer,
        FFormPlaceholder,
        FTextInput,
        FFormRowSpacer,
        FBreadcrumbs,
        FBreadcrumb,
        Page,
        FImageInput,
        FFormRow,
        FForm,
    },

    mixins: [
        OfficeOptionsMixin,
        EntityCreateEditMixin,
        AdminGalleryMixin,
    ],

    data() {
        return {
            entityName: 'gallery',
            mode: (this.$route.name === 'admin.galleries.edit' ? 'edit' : 'create'),
            fields: {
                title: undefined,
                subtitle: undefined,
                slug: undefined,
                date: undefined,
                cover_image_id: undefined,
                description: undefined,
                download_url: undefined,
                on_timeline: true,
                published_at: undefined,

                is_pinned: false,
                hide_for_freelancers: false,
                show_for_offices: [],
            },
            images: undefined,
            queueCount: 0,
            // debug: true,
        }
    },

    computed: {
        uploadUrl() {
            return APIEndpoints.parse(APIEndpoints.ADMIN_GALLERY_IMAGE_UPLOAD, {
                id: this.$route.params.id,
            });
        },
        coverImageComputed: {
            get() {
                return this.fetchedData ? this.fetchedData.data.cover_image_id : undefined;
            },
            set() {
            }
        },
        published: {
            get() {
                return !!this.fields.published_at;
            },
            set(value) {
                this.fields.published_at = value ? moment().format('YYYY-MM-DD HH:mm:ss') : false;
            },
        },
        defaultFiles() {
            return this.fetchedData ? this.fetchedData.data.images.map((image) => ({
                id: image.id,
                name: image.name,
                src: APIEndpoints.secureAsset(image.thumbnail_url),
                status: FileStatus.SUCCESS,
                exists: true,
            })) : [];
        },
    },

    methods: {
        getBaseUrl() {
            return APIEndpoints.ADMIN_GALLERIES;
        },

        getRedirectPath() {
            if (this.mode === 'create') {
                this.startSilentLoading();
                setTimeout(() => {
                    // Somehow it does not redirect properly, so location change
                    window.location = `/admin/galleries/edit/${this.fetchedData.data.id}#upload`;
                }, 500);
                return null;
            }
            return '/admin/galleries';
        },

        postParseResponseData(data) {
            // console.log(data.data.timeline);
            if (!data.data.timeline) return;

            this.fields.is_pinned = data.data.timeline.is_pinned;
            this.fields.show_for_offices = data.data.timeline.show_for_offices ?? [];
            this.fields.hide_for_freelancers = data.data.timeline.hide_for_freelancers;
        },

        onSubmit() {
            if (this.mode === 'create') {
                this.$refs.form.submit();
            } else {
                this.startLoading('big');

                // Start uploading the photo's, and when done, submit form
                this.$refs.imagesUpload.upload().then(() => {
                    this.endLoading(true);
                    this.$refs.form.submit();
                });
            }
        },

        configureFormData(formData) {
            const file = this.$refs.coverImageUploadField.getFile();
            if (file) {
                formData.set('cover_image', file);
            }
            if (this.images) {
                formData.set('_images', JSON.stringify(this.images));
            }
            return formData;
        },

        publish() {
            console.log('publish');
        },
    }
}
</script>
