<template>
    <fieldset
        :class="[
            'f-form-fieldset',
            ...classNames,
            (alternative ? '--alternative' : null)
        ]"
    >
        <legend class="f-form-fieldset__legend --no-select">
            <f-text type="subtitle">
                <slot name="legend">
                    {{ legend }}
                </slot>
            </f-text>
        </legend>
        <slot/>
    </fieldset>
</template>

<script>
import ClassNameMixin from '../../../mixins/ClassNameMixin';
import FText from '../../layout/text/FText';

export default {
    name: 'f-form-fieldset',

    components: {
        FText,
    },

    props: {
        alternative: Boolean,
        legend: String,
    },

    mixins: [
        ClassNameMixin,
    ],
}
</script>

<style lang="scss">
.f-form-fieldset {
    border-radius: 5px;
    padding: 20px 15px 15px;
    position: relative;
    margin-bottom: 20px;

    @include theme(light) {
        border: 1px solid darken($softGray, 5);
    }

    @include theme(dark) {
        border: 1px solid $quaternaryGray;
    }

    &.--alternative {
        padding: 25px;
        margin-left: -25px;
        margin-right: -25px;

        .f-form-fieldset__legend {
            display: none;
        }

        @include media(mobile-down) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__legend {
        text-align: left;
        position: absolute;
        display: inline-block;
        width: auto;
        top: -12px;
        left: 50%;
        transform: translateX(-50%);
        line-height: 120%;
        white-space: nowrap;

        .f-text {
            padding: 0 10px;

            @include theme(light) {
                background-color: $light;
                color: $secondaryGray;
            }

            @include theme(dark) {
                background-color: #333;
                color: $secondaryGray;
            }
        }
    }
}
</style>
