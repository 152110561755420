<template>
    <div
        class="time-table__header__item"
        :style="'width: calc('+ (100 / $parent.meetingRooms.length) +'% - 1px);'"
    >
        <f-text
            type="subtitle"
            tag="h3"
            class-name="time-table__header__item__name"
        >
            {{ name }}
        </f-text>
        <div
            v-if="state"
            :class="['time-table__header__item__availability', `--${state.state}`]"
            v-html="state.label"
        />
    </div>
</template>

<script>
import FText from '../../../f-components/layout/text/FText';

export default {
    name: 'time-table-header-item',

    components: {
        FText,
    },

    props: {
        name: {
            type: String,
            required: true,
        },
        state: Object,
    },
}
</script>

<style lang="scss">
.time-table__header__item {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;

    &__name {
        font-weight: 600;
        font-size: 100%;
        margin-bottom: 2px;
    }

    &__availability {
        font-size: 12px;
        margin-top: 5px;

        &:before {
            display: inline-block;
            content: '';
            background-color: $secondary;
            width: 8px;
            height: 8px;
            margin-right: 5px;
            border-radius: 999px;
        }

        &.--available:before {
            background-color: $success;
        }

        &.--booked:before {
            background-color: $secondary;
        }

        .--am-pm {
            text-transform: lowercase;
        }
    }
}
</style>
