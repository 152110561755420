<template>
    <div
        :class="['f-popup-list__container', `--scrollable-${scrollVariant}`]"
        :style="{ maxHeight: maxHeight }"
    >
        <ul class="f-popup-list">
            <slot/>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'f-popup-list',

    props: {
        maxHeight: {
            type: String,
            default: '200px',
        },
        scrollVariant: {
            type: String,
            default: 'dark',
        },
    },
}
</script>

<style lang="scss">
.f-popup-list {
    padding: 0;
    margin: 0;

    &__container {
        margin: 7px;
    }
}
</style>
