<template>
    <f-table-row class-name="leave-requests-admin-table-row">
        <f-table-cell width="160px" align="center" v-if="!hideRequestee">
            <user-card-compact
                v-if="approvalMode"
                :user="request.user"
                :variant="userCardVariant"
            />
            <user-card-compact
                v-else
                :user="request.user"
                :show-disciplines="false"
                :variant="userCardVariant"
            >
                {{ createdAt }}
            </user-card-compact>
        </f-table-cell>
        <f-table-cell class-name="leave-requests-admin-table-row__reason-cell">
            <div class="leave-requests-admin-table-row__dates">
                <f-chip
                    v-for="dateRange in getSortedDates(request.requested_dates)"
                    :key="dateRange[0]"
                    variant="info"
                    size="small"
                >
                    <span v-html="getDateRangeDescription(dateRange, request)"/>
                </f-chip>
            </div>
            <strong>{{ getTimeOffOptionByKey(request.type).value }}</strong>
            {{ request.reason }}
        </f-table-cell>
        <f-table-cell align="center" no-padding-right>
            <f-chip
                v-if="request.approval_reason"
                :variant="statusToVariant(request.status)"
            >
                <em
                    v-tooltip="{
                        text: request.approval_reason,
                        align: 'top',
                        wrap: true,
                        variant: (request.status === 'denied' ? 'danger' : null)
                    }"
                >
                    {{ request.status }}
                </em>
            </f-chip>
            <f-chip
                v-else
                :variant="statusToVariant(request.status)"
            >
                {{ request.status }}
            </f-chip>
            <f-chip
                v-if="request.error"
                variant="error"
            >
                <em
                    v-tooltip="{
                        text: request.error.description + '<hr><code>' + request.error.message + '</code>',
                        align: 'top',
                        wrap: true,
                        variant: 'danger'
                    }"
                >
                    error
                </em>
            </f-chip>
            <f-chip
                v-if="request.user && !request.user.bcs_employment_id"
                variant="warning"
            >
                <em
                    v-tooltip="{
                        text: 'This employee does not have a BCS ID set',
                        align: 'top',
                        wrap: true,
                        variant: 'warning'
                    }"
                >
                    missing BCS ID
                </em>
            </f-chip>
        </f-table-cell>
        <f-table-cell width="160px" v-if="!approvalMode" align="center">
            <user-card-compact
                v-if="request.approval_by_id"
                :user="userById(request.approval_by_id)"
                :show-disciplines="false"
                :variant="userCardVariant"
            >
                {{ approvalAt.ucfirst() }}
            </user-card-compact>
        </f-table-cell>
        <f-table-cell align="right" width="20px">
            <f-icon-button
                class-name="leave-requests-request-menu-button"
                @click.prevent="menuOpen = true"
                :selected="menuOpen"
                :disabled="menuOptions.length === 0"
                ref="menuButton"
                icon="ellipsis"
                size="small"
                no-parent-hover
                outlined
            />
        </f-table-cell>

        <f-popup
            v-if="menuOpen && $refs.menuButton && menuOptions.length > 0"
            :target="$refs.menuButton.$el"
            @close="menuOpen = false"
            padding="0"
        >
            <f-popup-list>
                <f-popup-list-item
                    v-for="(option, i) in menuOptions"
                    :key="i"
                    :icon="option.icon"
                    @click="onMenuSelect(option, request)"
                >
                    {{ option.value }}
                </f-popup-list-item>
            </f-popup-list>
        </f-popup>
    </f-table-row>
</template>

<script>
import moment from 'moment-timezone';
import UserCardCompact from '../../../cards/users/UserCardCompact';
import LeaveRequestHelperMixin from '../../../misc/leave-requests/LeaveRequestHelperMixin';
import UserHelperMixin from '../../../../mixins/UserHelperMixin';
import FIconButton from '../../../../f-components/form-controls/buttons/FIconButton';
import FChip from '../../../../f-components/data/chip/FChip';
import FTableRow from '../../../../f-components/data/table/FTableRow';
import FTableCell from '../../../../f-components/data/table/FTableCell';
import FPopup from '../../../../f-components/other/popup/FPopup';
import FPopupList from '../../../../f-components/other/popup/FPopupList';
import FPopupListItem from '../../../../f-components/other/popup/FPopupListItem';
import {Permissions} from '../../../../config/Permissions';
import {ChipVariants} from '../../../../f-components/data/chip/config';
import {getTimeOffOptionByKey} from '../../../misc/leave-requests/config';
import Store from '../../../../services/Store';

export default {
    name: 'leave-requests-admin-table-row',

    mixins: [
        LeaveRequestHelperMixin,
        UserHelperMixin,
    ],

    components: {
        FPopupListItem,
        FPopupList,
        FPopup,
        FTableCell,
        FTableRow,
        FChip,
        FIconButton,
        UserCardCompact,
    },

    props: {
        request: {
            type: Object,
            required: true,
        },
        approvalMode: Boolean,
        hideRequestee: Boolean,
    },

    data() {
        return {
            userCardVariant: 'semi-dark',
            getTimeOffOptionByKey: getTimeOffOptionByKey,
        }
    },

    computed: {
        menuOptions() {
            let options = [];
            return options;

            // if (this.request.user && !this.request.user.bcs_employment_id) {
            //     return options;
            // }
            //
            // if (this.hasPermission(Permissions.LEAVE_REQUESTS_APPROVE)) {
            //     switch (this.request.status) {
            //         case 'requested':
            //             options = [{
            //                 value: 'Approve',
            //                 key: 'approve',
            //                 icon: 'alarm-check',
            //                 disabled: true,
            //             }, {
            //                 value: 'Deny',
            //                 key: 'deny',
            //                 icon: 'alarm-error',
            //                 disabled: true,
            //             }];
            //             break;
            //         case 'approved':
            //             options = [{
            //                 value: 'Delete',
            //                 key: 'deleteAdmin',
            //                 icon: 'trash2',
            //                 disabled: true,
            //             }];
            //             break;
            //     }
            // }
            //
            // if (this.hasPermission(Permissions.LEAVE_REQUESTS_EDIT) && this.request.status === 'requested') {
            //     options.push({
            //         value: 'Edit',
            //         key: 'editAdmin',
            //         icon: 'pencil',
            //         disabled: true,
            //     });
            // }
            //
            // if (this.hasPermission(Permissions.LEAVE_REQUESTS_EDIT) && this.request.error) {
            //     options.push({
            //         value: 'Retry',
            //         key: 'retryAdmin',
            //         icon: 'refresh',
            //         disabled: true,
            //     });
            // }
            //
            // if (this.hasPermission(Permissions.LEAVE_REQUESTS_EDIT) && Store.state.$user.id === 34) {
            //     options.push({
            //         value: 'Retry (force)',
            //         key: 'retryForceAdmin',
            //         icon: 'refresh',
            //         disabled: true,
            //     });
            // }
            //
            // return options;
        },
        approvalAt() {
            return this.request.status + ' ' + moment(this.request.approval_at).fromNow();
        },
        createdAt() {
            return 'Requested ' + moment(this.request.created_at).fromNow();
        },
    },

    methods: {
        statusToVariant(status) {
            switch (status) {
                case 'requested':
                    return ChipVariants.SECONDARY;
                case 'approved':
                    return ChipVariants.SUCCESS;
                case 'denied':
                    return ChipVariants.DANGER;
                default:
                    return ChipVariants.DARK;
            }
        }
    }
}
</script>

<style lang="scss">
.leave-requests-admin-table-row {
    td {
        vertical-align: top !important;
    }

    &__dates {
        margin: 0 0 10px 0;
        line-height: 0;

        .f-chip {
            margin-top: 0;
        }
    }

    &__reason-cell {
        font-size: 90%;
        min-width: 240px;
        line-height: 120%;

        strong {
            display: block;
            font-size: 120%;
            margin-bottom: 3px;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }
}
</style>
