var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'f-chip',
        (_vm.variant ? `--${_vm.variant}` : null),
        (_vm.size ? `--${_vm.size}` : null),
        (_vm.code ? '--code' : null),
        _vm.className,
        '--no-select',
    ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }