<template>
    <widget
        v-if="birthdays.length > 0 || anniversaries.length > 0"
        :class-name="['birthdays-and-anniversaries-widget']"
        enabled-setting="widgets.birthdays.enabled"
        title="Celebrations"
        :header="`Upcoming ${currentSlide}`"
        :badge="(currentSlide === 'birthdays' ? birthdays.length : anniversaries.length)"
        extra-break-points
    >
        <template #post-title>
            <f-icon-button
                ref="settingsButton"
                icon="cog"
                outlined
                size="medium"
                class-name="--settings"
                @click="settingsOpen = true"
                :selected="settingsOpen"
                :style="{ opacity: settingsOpen ? '1 !important' : undefined }"
            />
        </template>

        <f-carousel
            auto-size
            navigation-type="prev-next"
            @slideChange="(slide) => currentSlide = slide"
            swipe
        >
            <f-carousel-slide
                id="birthdays"
                title="Upcoming birthdays"
            >
                <widget-user-list
                    v-if="birthdays.length > 0"
                    ref="birthdaysList"
                >
                    <widget-user-list-item
                        v-for="(user, i) in birthdays"
                        :key="i"
                    >
                        <user-card-compact
                            :user="userFromName(user.name) || user"
                            :show-disciplines="false"
                            full-width
                        >
                            {{ user.upcoming_birthday_description }}
                        </user-card-compact>
                    </widget-user-list-item>
                </widget-user-list>
                <f-inline-message v-else id="noBirthdays" :closable="false">
                    No upcoming birthdays.
                </f-inline-message>
            </f-carousel-slide>

            <f-carousel-slide
                id="anniversaries"
                title="Upcoming anniversaries"
            >
                <widget-user-list ref="anniversariesList" v-if="anniversaries.length > 0">
                    <widget-user-list-item
                        v-for="(user, i) in anniversaries"
                        :key="i"
                    >
                        <user-card-compact
                            :user="userFromName(user.name) || user"
                            :show-disciplines="false"
                            full-width
                        >
                            {{ user.anniversary_description }}
                        </user-card-compact>
                    </widget-user-list-item>
                </widget-user-list>
                <f-inline-message v-else id="noAnniversaries" :closable="false">
                    No upcoming anniversaries.
                </f-inline-message>
            </f-carousel-slide>
        </f-carousel>

        <f-popup
            v-if="settingsOpen && $refs.settingsButton"
            :target="$refs.settingsButton.$el"
            @close="settingsOpen = false"
            padding="15px"
            width="160px"
        >
            <f-flex direction="column" gap="10px" cross-axis-align="flex-start">
                <f-flex main-axis-align="space-between" gap="6px">
                    <label style="opacity: .5">Date</label>
                    <f-switch v-model="settingShowDate"/>
                </f-flex>
                <label style="opacity: .5">Show</label>
                <f-radio-button-list v-model="settingShowWeeks">
                    <f-radio-button label="2 weeks" value="2" name="settingShowWeeks"/>
                    <f-radio-button label="3 weeks" value="3" name="settingShowWeeks"/>
                    <f-radio-button label="5 weeks" value="5" name="settingShowWeeks"/>
                    <f-radio-button label="10 weeks" value="10" name="settingShowWeeks"/>
                </f-radio-button-list>
            </f-flex>
        </f-popup>
    </widget>
</template>

<script>
import moment from 'moment-timezone';
import Widget from '../Widget';
import APIDataStore from '../../../services/APIDataStore';
import UserHelperMixin from '../../../mixins/UserHelperMixin';
import APIDataStoreResources from '../../../config/APIDataStoreResources';
import UserCardCompact from "../../cards/users/UserCardCompact";
import FCarousel from '../../../f-components/navigation/carousels/FCarousel';
import FCarouselSlide from '../../../f-components/navigation/carousels/FCarouselSlide';
import WidgetUserList from '../../misc/widget-user-list/WidgetUserList';
import WidgetUserListItem from '../../misc/widget-user-list/WidgetUserListItem';
import FInlineMessage from '../../../f-components/notice/inline-message/FInlineMessage';
import FIconButton from '../../../f-components/form-controls/buttons/FIconButton';
import FPopup from '../../../f-components/other/popup/FPopup';
import FFlex from '../../../f-components/layout/flex/FFlex';
import FSwitch from '../../../f-components/form-controls/switches/FSwitch';
import FRadioButtonList from '../../../f-components/form-controls/radiobutton/FRadioButtonList';
import FRadioButton from '../../../f-components/form-controls/radiobutton/FRadioButton';
import {getFromLocalStorage, setToLocalStorage} from '../../../utils/localStorage';

export default {
    name: 'birthdays-and-anniversaries-widget',

    mixins: [
        UserHelperMixin,
    ],

    components: {
        FRadioButton,
        FRadioButtonList,
        FSwitch,
        FFlex,
        FPopup,
        FIconButton,
        FInlineMessage,
        WidgetUserListItem,
        WidgetUserList,
        FCarouselSlide,
        FCarousel,
        UserCardCompact,
        Widget,
    },

    computed: {
        birthdays() {
            const birthdays = [];
            const now = moment().startOf('day');

            for (let i in this.$store.state.$usersCollection) {
                const user = this.$store.state.$usersCollection[i];
                if (user.hide_from_list || !user.started_at) {
                    continue;
                }

                let birthDay = moment(user.date_of_birth).year(now.year()).startOf('day');
                if (birthDay < now) {
                    birthDay = moment(user.date_of_birth).year(now.year() + 1).startOf('day');
                }
                const compare = moment().add(parseInt(this.settingShowWeeks) * 7, 'days').startOf('day');

                if (birthDay >= now && birthDay <= compare) {
                    user.upcoming_birthday = birthDay;
                    user.upcoming_birthday_description = birthDay.fromNow();

                    if (this.settingShowDate) {
                        user.upcoming_birthday_description = birthDay.format('DD MMM YYYY');
                    }

                    if (now.format('D MMM') === birthDay.format('D MMM')) {
                        user.upcoming_birthday_description = 'Today';
                    }
                    if (birthDay.diff(now, 'days') === 1) {
                        user.upcoming_birthday_description = 'Tomorrow';
                    }

                    birthdays.push(user);
                }
            }

            return birthdays.sort((a, b) => {
                const aDate = moment(a.upcoming_birthday).startOf('day').unix(),
                    bDate = moment(b.upcoming_birthday).startOf('day').unix();

                return aDate - bDate;
            });
        },

        anniversaries() {
            const anniversaries = [];
            const now = moment().startOf('day');

            for (let i in this.$store.state.$usersCollection) {
                const user = this.$store.state.$usersCollection[i];
                if (user.hide_from_list || !user.started_at) {
                    continue;
                }

                const startedAt = moment(user.started_at).startOf('day');
                let anniversary = moment(user.started_at).year(now.year()).startOf('day');
                if (anniversary < now) {
                    anniversary = moment(user.started_at).year(now.year() + 1).startOf('day');
                }
                const twelveAndAHalfAnniversary = moment(user.started_at).startOf('day').add(12, 'years').add(6, 'months');
                const compare = moment().add(parseInt(this.settingShowWeeks) * 7, 'days').startOf('day');
                const years = anniversary.diff(startedAt, 'years');

                if (years < 1) continue;

                if (twelveAndAHalfAnniversary >= now && twelveAndAHalfAnniversary <= compare) {
                    user.anniversary = twelveAndAHalfAnniversary;
                    user.anniversary_description = '12,5 year anniversary, ' + twelveAndAHalfAnniversary.fromNow();

                    if (now.format('D MMM') === twelveAndAHalfAnniversary.format('D MMM')) {
                        user.anniversary_description = `12,5 year anniversary, today`;
                    }
                    if (twelveAndAHalfAnniversary.diff(now, 'days') === 1) {
                        user.anniversary_description = `12,5 year anniversary, tomorrow`;
                    }
                    if (this.settingShowDate) {
                        user.anniversary_description = twelveAndAHalfAnniversary.format('DD MMM YYYY') + ` (12,5 year anniversary)`;
                    }

                    anniversaries.push(user);
                } else if (anniversary >= now && anniversary <= compare) {
                    user.anniversary = anniversary;

                    let yearDescription = `${years} year anniversary`;
                    user.anniversary_description = yearDescription + ', ' + anniversary.fromNow();

                    if (now.format('D MMM') === anniversary.format('D MMM')) {
                        user.anniversary_description = `${yearDescription}, today`;
                    }
                    if (anniversary.diff(now, 'days') === 1) {
                        user.anniversary_description = `${yearDescription}, tomorrow`;
                    }
                    if (this.settingShowDate) {
                        user.anniversary_description = anniversary.format('DD MMM YYYY') + ` (${yearDescription})`;
                    }

                    anniversaries.push(user);
                }
            }

            return anniversaries.sort((a, b) => {
                const aDate = moment(a.anniversary).startOf('day').unix(),
                    bDate = moment(b.anniversary).startOf('day').unix();

                return aDate - bDate;
            });
        },
    },

    watch: {
        birthdays() {
            this.$nextTick(() => {
                if (this.$refs.birthdaysList) {
                    this.$refs.birthdaysList.$refs.scrollPane.invalidate();
                }
            });
        },
        anniversaries() {
            this.$nextTick(() => {
                if (this.$refs.anniversariesList) {
                    this.$refs.anniversariesList.$refs.scrollPane.invalidate();
                }
            });
        },
        settingShowDate(value) {
            setToLocalStorage('riviera.widget.setting.showBirthdaysDate', value);
        },
        settingShowWeeks(value) {
            setToLocalStorage('riviera.widget.setting.showBirthdaysWeeks', value);
        },
    },

    data() {
        return {
            extraWidgetSizeBreakPoints: false,
            currentSlide: 'birthdays',
            settingsOpen: false,
            settingShowDate: getFromLocalStorage('riviera.widget.setting.showBirthdaysDate', false) === 'true',
            settingShowWeeks: getFromLocalStorage('riviera.widget.setting.showBirthdaysWeeks', '3'),
        }
    },

    created() {
        APIDataStore.check(APIDataStoreResources.USERS_COLLECTION);
    },
}
</script>

<style lang="scss">
.birthdays-and-anniversaries-widget {
    .widget-user-list {
        margin-bottom: 10px;
    }

    .f-icon-button.--settings {
        opacity: 0;
        border: 0;
        margin-right: 6px;
        transition: opacity .1s;
    }

    .f-panel-header:hover {
        .f-icon-button.--settings {
            opacity: 1;
        }
    }
}
</style>
