<template>
    <widget
        v-if="Object.keys(this.publicHolidays).length > 0"
        :class-name="['public-holidays-widget', widgetSize]"
        :header="(currentMonth ? currentMonth.replace('-', ' ').ucwords() : null)"
        enabled-setting="widgets.public_holidays.enabled"
        title="Public holidays & festivities"
    >
        <f-carousel
            ref="carousel"
            auto-size
            navigation-type="prev-next"
            @slideChange="onSlideChange"
            swipe
        >
            <f-carousel-slide
                v-for="(dayEvents, month) in publicHolidays"
                :id="month.slugify()"
                :key="month"
                :title="month"
            >
                <f-scroll-pane
                    max-height="360px"
                    no-scroll-padding
                    ref="scroll"
                >
                    <ul class="public-holidays">
                        <public-holiday-day
                            v-for="(events, day) in dayEvents"
                            :events="events"
                            :key="day"
                        />
                    </ul>
                </f-scroll-pane>
            </f-carousel-slide>
        </f-carousel>
    </widget>
</template>

<script>
import APIDataStoreResources from '../../../config/APIDataStoreResources';
import moment from 'moment-timezone';
import WidgetSizeMixin from '../WidgetSizeMixin';
import APIDataStore from '../../../services/APIDataStore';
import FScrollPane from '../../../f-components/other/scroll-pane/FScrollPane';
import PublicHolidayDay from './PublicHolidayDay';
import FCarousel from '../../../f-components/navigation/carousels/FCarousel';
import FCarouselSlide from '../../../f-components/navigation/carousels/FCarouselSlide';
import Widget from '../Widget';

export default {
    name: 'public-holidays-widget',

    components: {
        Widget,
        FCarouselSlide,
        FCarousel,
        PublicHolidayDay,
        FScrollPane,
    },

    mixins: [
        WidgetSizeMixin,
    ],

    computed: {
        publicHolidays() {
            if (!this.$store.state.$publicHolidays) {
                return {};
            }

            const holidays = {};
            for (let i in this.$store.state.$publicHolidays) {
                if (!this.$store.state.$publicHolidays[i].start || this.$store.state.$publicHolidays[i].start.date) {
                    continue;
                }

                const event = {...this.$store.state.$publicHolidays[i]};
                event.start = moment(event.start);

                const month = event.start.format('MMMM YYYY');
                const day = event.start.format('D');

                if (!holidays[month]) {
                    holidays[month] = {};
                }
                if (!holidays[month][day]) {
                    holidays[month][day] = [];
                }

                holidays[month][day].push(event);
            }

            return holidays;
        },
    },

    data() {
        return {
            currentMonth: null,
        }
    },

    watch: {
        publicHolidays() {
            this.$nextTick(() => {
                this.invalidate();
                this.scrollToFirstActiveElement();
            });
        },
    },

    created() {
        APIDataStore.check(APIDataStoreResources.PUBLIC_HOLIDAYS);
    },

    methods: {
        invalidate() {
            if (this.$refs.carousel) {
                this.$refs.carousel.invalidate();
            }
        },
        scrollToFirstActiveElement() {
            const slide = this.$refs.carousel.currentSlide();
            if (!slide) return;
            const elements = slide.$el.querySelectorAll('.--past');
            const scroll = slide.$el.querySelector('.f-scroll-pane__content');

            if (elements.length > 0 && scroll) {
                scroll.scrollTop = elements[elements.length - 1].getBoundingClientRect().y - slide.$el.getBoundingClientRect().y;
            }
        },
        onSlideChange(month) {
            this.currentMonth = month;
            this.$nextTick(() => this.scrollToFirstActiveElement());
        },
    },
}
</script>

<style lang="scss">
.public-holidays-widget {
    .f-panel__content {
        padding-top: 15px;
    }

    .f-scroll-pane {
        margin: 20px 0 10px;
    }

    .f-carousel {
        max-width: 360px;
        width: 100%;
        margin: auto;
    }

    .public-holidays {
        position: relative;
        margin: auto;
        padding: 0 5px;

        &:before {
            border-left: 1px solid $tertiaryGray;
            left: 65px;
            height: 100%;
            top: 0;
            content: '';
            position: absolute;
        }
    }
}
</style>
