<template>
    <page
        v-if="hasPermission(Permissions.ADMIN_PAGES)"
        :loading="loading"
        :title="['Admin', 'Pages']"
        :header-badge="(fetchedData ? fetchedData.total : null)"
        header="Pages"
        pre-max-width="800px"
        class-name="admin-pages-page"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb>Pages</f-breadcrumb>
                <f-breadcrumb>Overview</f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-container max-width="800px">
            <f-filter
                show-active-filters
                :active-filters="activeFilters"
                :filter-casts="{
                    query: 'query'
                }"
            >
                <f-filter-row>
                    <f-filter-item>
                        <f-text-input
                            placeholder="Search…"
                            pre-icon="magnifier"
                            v-model="searchQuery"
                            class-name="--align-center"
                            max-width="300px"
                            resetable
                        />
                    </f-filter-item>
                </f-filter-row>
            </f-filter>

            <f-table
                v-if="fetchedData"
                :empty="(!fetchedData || items.length === 0)"
                rows-hoverable
                ref="table"
                allow-horizontal-scroll
            >
                <template #pre>
                    <f-pagination
                        v-if="fetchedData"
                        :current-page="currentPage"
                        :page-count="lastPage"
                        @navigate="onPaginationNavigate"
                    />
                </template>

                <template #head>
                    <f-table-header-row class-name="--no-wrap">
                        <f-table-header-cell>
                            Title
                        </f-table-header-cell>
                        <f-table-header-cell align="center">
                            Template
                        </f-table-header-cell>
                        <f-table-header-cell/>
                    </f-table-header-row>
                </template>

                <f-table-row
                    v-for="row in items"
                    :key="row.id"
                    @click="$router.push(`/admin/pages/edit/${row.id}`)"
                    :actions="[{
                        icon: 'trash2',
                        value: 'Delete',
                        onClick: () => deletePage(row.id, row.title),
                    }, {
                        icon: 'link',
                        value: 'Deeplink',
                        disabled: row.on_timeline && !row.published_at,
                        onClick: () => openDeeplink(row.deeplink),
                    }]"
                >
                    <f-table-title-cell>
                        {{ row.title }}
                        <template #sub>
                            <a
                                :href="row.deeplink"
                                @click="$event => $event.stopPropagation()"
                                target="_blank"
                            >
                                {{ row.deeplink }}
                            </a>
                        </template>
                    </f-table-title-cell>
                    <f-table-cell align="center">
                        {{ row.template }}
                    </f-table-cell>
                </f-table-row>

                <template #post>
                    <f-pagination
                        v-if="fetchedData"
                        :current-page="currentPage"
                        :page-count="lastPage"
                        @navigate="onPaginationNavigate"
                        bottom
                    />
                </template>
            </f-table>

            <f-table-placeholder v-else-if="!fetchedData && loading"/>
        </f-container>

        <entity-action-button
            :permission="Permissions.ADMIN_PAGES"
            route="/admin/pages/create"
        />
    </page>
</template>

<script>
import APIEndpoints from '../../../../config/APIEndpoints';
import Page from '../../Page';
import FTextInput from '../../../../f-components/form-controls/text/FTextInput';
import EntityPaginatedFilteredOverviewMixin from '../../../../mixins/entities/EntityPaginatedFilteredOverviewMixin';
import FTable from '../../../../f-components/data/table/FTable';
import FTableRow from '../../../../f-components/data/table/FTableRow';
import FTableHeaderCell from '../../../../f-components/data/table/FTableHeaderCell';
import FTableCell from '../../../../f-components/data/table/FTableCell';
import FTableTitleCell from '../../../../f-components/data/table/FTableTitleCell';
import UserHelperMixin from '../../../../mixins/UserHelperMixin';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import OfficeOptionsMixin from '../../../../mixins/OfficeOptionsMixin';
import FFilterItem from "../../../../f-components/data/filter/FFilterItem";
import FFilterRow from "../../../../f-components/data/filter/FFilterRow";
import FFilter from "../../../../f-components/data/filter/FFilter";
import FTablePlaceholder from '../../../../f-components/loaders/placeholder/FTablePlaceholder';
import FContainer from '../../../../f-components/layout/container/FContainer';
import FPagination from '../../../../f-components/navigation/pagination/FPagination';
import FTableHeaderRow from '../../../../f-components/data/table/FTableHeaderRow';
import BreakpointsMixin from '../../../../mixins/BreakpointsMixin';
import EntityActionButton from '../../../misc/entities/EntityActionButton';
import EntityAvatar from '../../../misc/entities/EntityAvatar';
import FIcon from '../../../../f-components/layout/icons/FIcon';
import FChip from '../../../../f-components/data/chip/FChip';
import AdminPagesMixin from './AdminPagesMixin';

export default {
    name: 'admin-pages-page',

    components: {
        FChip,
        FIcon,
        EntityAvatar,
        EntityActionButton,
        FTableHeaderRow,
        FPagination,
        FContainer,
        FTablePlaceholder,
        FFilter,
        FFilterRow,
        FFilterItem,
        FBreadcrumb,
        FBreadcrumbs,
        FTableTitleCell,
        FTableCell,
        FTableHeaderCell,
        FTableRow,
        FTable,
        FTextInput,
        Page,
    },

    mixins: [
        EntityPaginatedFilteredOverviewMixin,
        UserHelperMixin,
        OfficeOptionsMixin,
        BreakpointsMixin,
        AdminPagesMixin,
    ],

    data() {
        return {
            searchQuery: null,
            office: null,
        };
    },

    watch: {
        searchQuery() {
            this.onFilterChange();
        },
    },

    methods: {
        getEndpoint() {
            return APIEndpoints.ADMIN_PAGES;
        },

        getFilters() {
            return {
                'filter[query]': this.searchQuery,
            };
        },

        applyPreviousFilters(filters) {
            if (filters['filter[query]']) {
                this.searchQuery = filters['filter[query]'];
            }
        },

        openDeeplink(dl) {
            window.open(dl);
        },
    },
}
</script>

<style lang="scss">
.admin-pages-page {
    .f-filter__container {
        max-width: 360px;
    }

    .f-table-title-cell__subtitle {
        padding-top: 5px;
    }
}
</style>
