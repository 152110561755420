<template>
    <td
        :class="[
            'f-table-cell',
            (hideMobile ? '--hide-mobile' : null),
            (hideTablet ? '--hide-tablet' : null),
            (noWrap ? '--no-wrap' : null),
            (noPaddingRight ? '--no-padding-right' : null),
            (noPaddingBottomTop ? '--no-padding-bottom-top' : null),
            (noPaddingLeftRight ? '--no-padding-left-right' : null),
            (noPaddingLeft ? '--no-padding-left' : null),
            (align ? `--align-${align}` : null),
            (isId ? `--id` : null),
            className,
        ]"
        :style="{width: width}"
        @click="$emit('click', $event)"
    >
        <slot/>
    </td>
</template>

<script>
import FTableCellMixin from './FTableCellMixin';

export default {
    name: 'f-table-cell',

    mixins: [
        FTableCellMixin,
    ],
}
</script>

<style lang="scss">
.f-table-cell {
    transition: background-color .1s;

    &.--no-padding-left {
        padding-left: 2px !important;
    }

    &.--no-padding-right {
        padding-right: 2px !important;
    }

    &.--no-padding-left-right {
        padding-right: 2px !important;
        padding-left: 2px !important;
    }

    &.--no-padding-bottom-top {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    .f-text-input, .f-user-select {
        margin-bottom: 0;
    }

    &.--id {
        font-family: Consolas, monospace;
        font-size: 90%;
        //color: $secondaryGray;
    }

    .f-chip {
        margin-right: 3px;
    }
}
</style>
