import moment from 'moment-timezone';
import APIDataStore from '../../../services/APIDataStore';
import APIDataStoreResources from '../../../config/APIDataStoreResources';
import APIEndpoints from '../../../config/APIEndpoints';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import {SiteLoaderSizes} from '../../../f-components/loaders/site-loader/config';
import LoadingMixin from '../../../mixins/LoadingMixin';
import {ChipVariants} from '../../../f-components/data/chip/config';

export default {
    mixins: [
        LoadingMixin,
    ],

    data() {
        return {
            menuOpen: false,
            loading: false,
        }
    },

    methods: {
        getStatusVariant(status) {
            switch (status) {
                case 'denied':
                    return ChipVariants.DANGER;
                case 'approved':
                    return ChipVariants.SUCCESS;
                default:
                    return ChipVariants.SECONDARY;
            }
        },

        renderDateLabel(date, hrs, format = 'ddd D MMM YYYY') {
            let label = moment(date).format(format);

            if (hrs && parseInt(hrs) !== 8) {
                // label += ' <span>&ndash; ' + hrs + ' hr' + (hrs === 1 ? '' : 's') + '</span>';
                label += ' <span>(' + hrs + 'h)</span>';
            }

            return label;
        },

        getSortedDates(requestedDates) {
            let dates = Object.keys(requestedDates);
            const dateformat = 'YYYY-MM-DD';

            return dates.reduce(function (acc, val) {
                let present, date = moment(val);

                acc.forEach(function (arr) {
                    const prev = date.clone().subtract(1, 'day').format(dateformat);
                    const next = date.clone().add(1, 'day').format(dateformat);

                    if (present) {
                        return;
                    }

                    if (
                        (
                            ((arr.indexOf(prev) > -1) && requestedDates[prev] === 8)
                            || ((arr.indexOf(next) > -1) && requestedDates[next] === 8)
                        )
                        && requestedDates[val] === 8
                    ) {
                        present = true;
                        arr.push(val);
                    }
                });

                if (!present) {
                    acc.push([val]);
                }

                return acc;
            }, []);
        },

        getDateRangeDescription(dates, leaveRequest) {
            if (dates.length === 1) {
                return this.renderDateLabel(dates[0], leaveRequest.requested_dates[dates[0]]);
            } else if (dates.length === 2) {
                return [
                    this.renderDateLabel(dates[0], leaveRequest.requested_dates[dates[0]], 'ddd D MMM'),
                    ' <span class="f-icon f-icon-plus"></span> ',
                    this.renderDateLabel(dates[1], leaveRequest.requested_dates[dates[1]]),
                    ' <span>(2d)</span>'
                ].join('');
            } else {
                return [
                    this.renderDateLabel(dates[0], leaveRequest.requested_dates[dates[0]], 'ddd D MMM'),
                    ' <span class="f-icon f-icon-chevrons-expand-horizontal"></span> ',
                    this.renderDateLabel(dates[dates.length - 1], leaveRequest.requested_dates[dates.length - 1]),
                    ' <span>(' + dates.length + 'd)</span>',
                ].join('');
            }
        },

        onMenuSelect(item, leaveRequest) {
            this.menuOpen = false;

            switch (item.key) {
                case 'delete':
                    this.onDeleteRequest(leaveRequest);
                    break;
                case 'deleteAdmin':
                    this.onDeleteAdminRequest(leaveRequest);
                    break;
                case 'editAdmin':
                    this.onEditAdminRequest(leaveRequest);
                    break;
                case 'approve':
                    this.onRequestApproval(leaveRequest, true);
                    break;
                case 'deny':
                    this.onRequestApproval(leaveRequest, false);
                    break;
                case 'retryAdmin':
                    this.onErrorRetry(leaveRequest);
                    break;
                case 'retryForceAdmin':
                    this.onErrorRetry(leaveRequest, true);
                    break;
                default:
                    console.error(`Action ${item.key} does not exist`);
            }
        },

        onEditAdminRequest(leaveRequest) {
            EventBus.$emit('admin.leave.edit', leaveRequest);
        },

        onDeleteRequest(
            leaveRequest,
            endpoint = APIEndpoints.LEAVE_REQUEST,
            desc = 'Are you sure you want to delete your leave request?',
            onComplete = () => true
        ) {
            const url = `${endpoint}/${leaveRequest.id}`;

            EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                title: 'Delete',
                body: desc,
                height: '200px',
                confirmButtonText: 'Delete',
                confirmButtonVariant: 'danger',
                cancelButton: true,
                onConfirm: () => {
                    this.startLoading();
                    window.axios['delete'](url)
                        .then(() => {
                            this.endLoading();
                            EventBus.$emit(GlobalEvents.PUSH_MESSAGE, 'Successfully deleted leave request');
                            APIDataStore.fetch(APIDataStoreResources.LEAVE);
                            onComplete();
                        })
                        .catch(() => this.endLoading());
                },
            });
        },

        onDeleteAdminRequest(request) {
            this.onDeleteRequest(
                request,
                APIEndpoints.ADMIN_LEAVE_REQUESTS,
                `
                    Are you sure you want to delete this leave request?
                    All requested hours for this request will become available again to the employee.
                `,
                () => APIDataStore.fetch(APIDataStoreResources.ADMIN_LEAVE_REQUESTS),
            );
        },

        onRequestApproval(request, approved) {
            const user = this.userById(request.user_id);
            const body = 'Are you sure you want to ' + (approved ? 'approve' : 'deny') + ' ' + user.name + '\'' + (user.name.endsWith('s') ? '' : 's') + ' leave request?<br><br>';

            EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                title: (approved ? 'Approve' : 'Deny') + ' request',
                body: body,
                height: '200px',
                confirmButtonText: (approved ? 'Approve' : 'Deny'),
                confirmButtonVariant: (approved ? 'primary' : 'danger'),
                cancelButton: true,
                textArea: !approved,
                textAreaPlaceholder: 'Reason',
                onConfirm: (textValue) => {
                    const postData = {
                        id: request.id,
                        approved: approved,
                        reason: textValue,
                    };

                    this.startLoading(SiteLoaderSizes.BIG);

                    window.axios['post'](APIEndpoints.ADMIN_LEAVE_REQUEST_APPROVAL, postData)
                        .then(() => {
                            this.endLoading();
                            EventBus.$emit(GlobalEvents.PUSH_MESSAGE, 'Successfully ' + (approved ? 'approved' : 'denied') + ' leave request');
                            APIDataStore.fetch(APIDataStoreResources.ADMIN_LEAVE_REQUESTS);
                        })
                        .catch(() => this.endLoading());
                },
            });
        },

        onErrorRetry(leaveRequest, force = false) {
            EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                title: 'Retry',
                body: force ? 'Are you sure you want to force retry? This should only be done when the leave request is missing in BCS.' : `
                    This leave request has an error. This usually means that the request has not successfully been sent to BCS,
                    and that Riviera shows more days left than the employee actually has.
                    Please check BCS if the request is (partially) processed. If not you may retry it.
                `,
                height: '200px',
                confirmButtonText: 'Retry',
                confirmButtonVariant: 'danger',
                cancelButton: true,
                onConfirm: () => {
                    this.startLoading();

                    window.axios['post'](force ? APIEndpoints.ADMIN_LEAVE_RETRY_FORCE : APIEndpoints.ADMIN_LEAVE_RETRY, {
                        id: leaveRequest.id,
                    })
                        .then(() => {
                            this.endLoading();
                            EventBus.$emit(GlobalEvents.PUSH_MESSAGE, 'Successfully re-tried leave request');
                            APIDataStore.fetch(APIDataStoreResources.LEAVE);
                        })
                        .catch(() => this.endLoading());
                },
            });
        },
    },
};
