<template>
    <a
        :class="['google-embed-help']"
        href="#embed-help"
        @click.prevent="showModal = true"
    >
        Where can I find the Google {{ type.ucfirst() }} ID?

        <f-modal
            v-if="showModal"
            @close="showModal = false"
            width="600px"
            class-name="google-embed-help__modal"
            :title="`Google ${type.ucfirst()}`"
        >
            <f-content class="google-embed-help__body" v-if="type === 'slides'">
                <ul>
                    <li>Open your Google Slides presentation</li>
                    <li>Click on Share</li>
                    <li>Make sure General Access is set to code d'azur, with viewer rights</li>
                    <li>Click on Copy link</li>
                    <li>
                        You can find the ID in the copied link, e.g.:
                        <code>https://docs.google.com/presentation/d/<strong>1cYIXJ1k_O8AjYfv10mxXKrlzCTDQ9-0TKZuSBVx6AcI</strong>/edit?usp=sharing</code>
                    </li>
                </ul>
                <img src="/img/embed/slides.jpg" alt="Google Slides"/>
            </f-content>

            <div class="google-embed-help__body" v-if="type === 'drive'">
                <f-content>
                    <ul>
                        <li>Open your Google Drive, and right click on your file in the file browser</li>
                        <li>Click on Share</li>
                        <li>Make sure General Access is set to code d'azur, with viewer rights</li>
                        <li>Click on Copy link</li>
                        <li>
                            You can find the ID in the copied link, e.g.:
                            <code>https://drive.google.com/file/d/<strong>1R7O-2jlBksIdlZDkElBn8ENBuFbYjcnk</strong>/view?usp=sharing</code>
                        </li>
                    </ul>
                </f-content>

                <f-carousel>
                    <f-carousel-slide id="1">
                        <img src="/img/embed/drive1.jpg" alt="Google Drive"/>
                    </f-carousel-slide>
                    <f-carousel-slide id="2">
                        <img src="/img/embed/drive2.jpg" alt="Google Drive"/>
                    </f-carousel-slide>
                </f-carousel>
            </div>
        </f-modal>
    </a>
</template>

<script>
import FModal from '../../../f-components/other/modal/FModal';
import FContent from '../../../f-components/layout/content/FContent';
import FCarousel from '../../../f-components/navigation/carousels/FCarousel';
import FCarouselSlide from '../../../f-components/navigation/carousels/FCarouselSlide';

export default {
    name: 'google-embed-help',

    components: {
        FCarouselSlide,
        FCarousel,
        FContent,
        FModal,
    },

    props: {
        type: {
            type: String,
            default: 'drive',
        }
    },

    data() {
        return {
            showModal: false,
            currentTabID: null,
        }
    },
}
</script>

<style lang="scss">
.google-embed-help {
    vertical-align: middle;
    font-size: 12px;

    &:hover {
        color: $ctaOrange !important;
    }

    &__modal {
        img {
            width: 100%;
            border-radius: 5px;
            margin: 10px 0 0;
            max-width: 100%;
        }

        code {
            display: block;
            margin-top: 5px;

            strong {
                color: $ctaOrange;
            }
        }
    }

    &__body {
    }
}
</style>
