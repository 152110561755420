<template>
    <div
        :class="['fika-event', (isSafari ? '--bs' : null)]"
        @click="modalOpen = true"
    >
        <div class="fika-event__bg"/>

        <f-text tag="div" font-weight="400">Fika</f-text>
        <div class="f-text --align-right">
            #{{ number }}
        </div>
        <custom-cursor/>

        <f-modal
            v-if="modalOpen"
            :title="`FIKA #${number}`"
            width="1000px"
            height="auto"
            @close="modalOpen = false"
        >
            <google-slides-embed :id="slide_id"/>
        </f-modal>
    </div>
</template>

<script>
import FText from '../../../../f-components/layout/text/FText';
import FModal from '../../../../f-components/other/modal/FModal';
import CustomCursor from '../../CustomCursor';
import {isSafari} from '../../../../utils/helpers';
import GoogleSlidesEmbed from '../../embeds/GoogleSlidesEmbed';

export default {
    name: 'fika-event',

    components: {
        GoogleSlidesEmbed,
        CustomCursor,
        FModal,
        FText,
    },

    props: {
        number: String,
        slide_id: String,
    },

    data() {
        return {
            modalOpen: false,
            isSafari: isSafari(),
        }
    },
}
</script>

<style lang="scss">
.fika-event {
    position: relative;
    overflow: hidden;
    background: $dark;
    border-radius: 6px;
    height: 320px * .5;
    width: 468px * .5;
    padding: 20px 20px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    cursor: pointer;

    @include media(mobile-down) {
        height: 160px;
        width: 234px;
    }

    .f-text {
        position: relative;
        z-index: 2;
        line-height: 1;
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../../../../img/fikka.jpg);
        background-size: cover;
        transition: transform .2s ease-in-out;
    }

    &:hover:not(.--bs) {
        .fika-event__bg {
            transform: scale(1.1);
            transition: transform 2s ease-in-out;
        }
    }
}
</style>
