<template>
    <div class="secure-document">
        <a
            :class="['secure-document__link']"
            :href="`secure://${relativeUrl}`"
            :style="{ backgroundImage: `url(${cover})`}"
        />
    </div>
</template>

<script>
import SecureLinksMixin from '../../../../mixins/SecureLinksMixin';

export default {
    name: 'secure-document',

    mixins: [
        SecureLinksMixin,
    ],

    props: {
        cover: {
            type: String,
            required: true,
        },
        relativeUrl: {
            type: String,
            required: true,
        },
    },

    mounted() {
        this.parseSecureLinks();
    },
}
</script>

<style lang="scss">
.secure-document {
    &__link {
        display: block;
        width: 100%;
        padding-top: 150%;
        border-radius: 5px;
        background-size: cover;
        transition: opacity .1s;

        &:hover {
            opacity: .85;
        }
    }
}
</style>
