<template>
    <f-abstract-input
        v-bind="boundProps"
        :input-error="inputError"
        :class-name="[
            'f-rating',
            (value === null && readonly ? '--is-null' : null),
            ...classNames,
        ]"
        :error-margin-left="-6"
        show-error
    >
        <ul class="f-rating__container --no-wrap --no-select">
            <f-rating-star
                v-for="(v, n) in ratings"
                :key="n"
                :full="v"
                :read-only="readonly"
                @click="onStarClick(n)"
            />
        </ul>
    </f-abstract-input>
</template>

<script>
import FRatingStar from './FRatingStar';
import FAbstractInput from '../base/FAbstractInput';
import AbstractInputValidationMixin from '../base/AbstractInputValidationMixin';
import AbstractInputMixin from '../base/AbstractInputMixin';
import AbstractInputPropsMixin from '../base/AbstractInputPropsMixin';

export default {
    name: 'f-rating',

    components: {
        FAbstractInput,
        FRatingStar,
    },

    mixins: [
        AbstractInputValidationMixin,
        AbstractInputPropsMixin,
        AbstractInputMixin,
    ],

    computed: {
        ratings() {
            const ratings = [];

            for (let i = 0; i < 5; i++) {
                ratings.push(!(i * 2 >= this.value));
            }

            return ratings;
        },
    },

    methods: {
        onStarClick(n) {
            if (this.readonly) return;

            this.$emit('input', (n + 1) * 2);
        },

        getValue() {
            return this.value;
        },
    },
}
</script>

<style lang="scss">
.f-rating {
    display: inline-block;
    opacity: 1;

    &.f-abstract-input.--readonly {
        opacity: 1;
    }

    &__container {
        margin: 0;
        padding: 0;
    }

    .f-form-row__content & {
        padding: 9px;
    }

    &.--is-null {
        opacity: .5 !important;
    }

    &.--error {
        color: $danger;
    }
}
</style>
